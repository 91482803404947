import { Button, FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup, TextField ,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Chip } from '@material-ui/core';
import { classnames, convertRowsPropToState } from '@material-ui/data-grid';
import { PanToolSharp, TextFields } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { Collapse } from 'bootstrap';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState, useEffect, Fragment , useCallback } from 'react';
import { useHistory  } from "react-router-dom";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { People } from '@material-ui/icons';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import Edit from '@material-ui/icons/Edit';
import CardFooter from 'components/Card/CardFooter';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import HWMessage from 'HWLibrary/HWMessage';
import BlockUi from 'react-block-ui';
import merchantApiService from 'views/Merchant/APIService';
import userSegmentApiService from 'views/UserSegment/APIService';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import InfoDialog  from 'views/Utils/InfoDialog';
import PositionedSnackbar from 'views/Utils/SnackBars';
import StatusDialog from 'views/Utils/StatusDialog';
import { HWInput } from 'HWLibrary/HWInput';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { DropzoneDialog } from 'material-ui-dropzone';
import { DataGrid } from '@material-ui/data-grid';
import memberApiService from 'views/Members/APIService';
import {v4 as uuidv4} from 'uuid';
import notificationAPIService from './APIService';
import AutocompleteTextArea from "../Utils/TextAreaAutoComplete";
import { useRef } from 'react';
import { Checkbox } from '@material-ui/core';
import moment from 'moment';
import SearchBar from "views/Merchant/SearchBar";
import cloudfrontConfig from 'HWLibrary/HWCloudfrontConfig';


const useStyles = makeStyles (theme => ({
    ...styles,
    card : { borderRadius : 10 },
    gridItem : { marginTop : 10 },
    draftButton : { background : '#FF9017', color : 'white', marginRight : 5 },
    proceedButton : { background : '#138DE8', color : 'white' },
    cardFooter : { justifyContent : 'center' },
    autoComplete : { width : 500 },
    select : {
        '& .MuiSelect-outlined': { borderColor: '#138DE8' }
    },
    badgeColor :{
        '& .MuiBadge-colorPrimary': { backgroundColor: "#FF9017"}
    },
    
}));


export function SearchUserDialog (props) {

    const classes = useStyles ();

    const [ selectedList, setSelectedList ] = useState ([]);
    const [ listLoading, setListLoading ] = useState (false);

    const [ rows, setRows ] = useState ([]);
    const [ page, setPage ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ rowCount, setRowCount ] = useState (0);
    const [ searchText, setSearchText ] = useState ('');
    const [ filterModel, setFilterModel ] = useState ('');
    const [ refreshState, setRefreshState ] = useState (false);
    const [ selectionModel, setSelectionModel ] = useState ([]);
    const [ uploadInProgress , setUploadInProgress ] = useState(false);
    const [ open3 , setOpen3] = useState(false);
    const [ open2 , setOpen2] = useState(false);
    const [ selectionModelNew, setSelectionModelNew ] = useState ({ rowIds : [] });
    const prevSelectionModel = useRef(selectionModel);
    const [ selectionModelMap, setSelectionModelMap ] = useState ({});
    const [ firstSortId, setFirstSortId] = useState();
    const [ lastSortId, setLastSortId ] = useState();
    const [ pageDelta, setPageDelta ] = useState(0);
    useEffect (() => {
        if (props && props.userType !== null) {
            var { userType } = props;
           // mnhgfz if (userType != 'Member' && userType != 'Merchant') { return HWMessage.show ("Invalid user type selected"); }

            setListLoading (true);
            var paginationBookmark = (page) * pageSize;
            var fetchLimit = pageSize;

            if (userType === 'Member') {
                memberApiService.fetch ({ paginationBookmark, fetchLimit, searchText, filterModel, pageDelta, firstSortId, lastSortId })
                .then (response => {  
                    if (! response || ! response.data) { HWMessage.show ("List cannot be fetched at momeent" , "error"); }
                    setRowCount (response.data.membersCount);
                    setRows (response.data.memberList);
                    setFirstSortId (response.data.firstSortId);
                    setLastSortId (response.data.lastSortId);
                    setSelectionModelMap ({  ...selectionModelMap,  [page] : selectionModelMap[page] || [] });
                    setTimeout(() => { setSelectionModel( selectionModelMap[ page ] || [] ); });
                    HWMessage.show("Request Successful" , "success");
                }).catch (error => { HWMessage.show (error.response.data.message || "Request could not be resolved", "error"); })
                .finally (() => { setListLoading (false);})
            } 
            else if (userType === 'Merchant') {
                merchantApiService.fetchMerchants (fetchLimit, paginationBookmark, filterModel, null, searchText)
                .then (response => {  
                    if (! response || ! response.data) { return HWMessage.show ("Error while fetching the response" ,"error"); }
                    setRowCount (response.data.merchantCounts);
                    setRows (response.data.merchantList);
                    setSelectionModelMap ({  ...selectionModelMap,  [page] : selectionModelMap[page] || [] });
                    setTimeout(() => { setSelectionModel( selectionModelMap[ page ] || [] ); });
                    HWMessage.show("Request Successful" , "success");
                }).catch (error => { return HWMessage.show (error.response.data.message || "Request could not be resolved", "error"); })
                .finally (() => { setListLoading (false); })
            }
        }
    }, [ props.userType, refreshState, pageSize, searchText, page, pageSize ]);

    const handleExclusionInclusionClick = id => {

        console.log("Selections", selectionModel, selectionModelMap);

        var idx = selectionModel.indexOf (id);
        if (idx !== -1) {
            selectionModel.splice (idx, 1);
            setSelectionModel ([ ...selectionModel ]);
            let currentMap = selectionModelMap [ page ];
            var mapIdx = currentMap.indexOf(id);
            if (mapIdx !== -1) 
                currentMap.splice (mapIdx, 1);
            selectionModelMap [ page ] = currentMap;
            setSelectionModelMap ({ ...selectionModelMap });
        } else {
            selectionModel.push (id);
            setSelectionModel ([ ...selectionModel ]);
            
            let currentMap = selectionModelMap [ page ];
            currentMap.push (id);
            selectionModelMap [ page ] = currentMap;
            setSelectionModelMap ({ ...selectionModelMap });
        }
    };


    const columns = [
        
        { field : 'membershipNumber', headerName : 'Membership Number', filterable : true, sortable : true, width : 200 },
        { field : 'code', headerName : 'Merchant Code', filterable : true, sortable : true, width : 200 },
        { field : props && props.userType === 'Merchant' ? 'entityName' : 'name', headerName : props && props.userType === 'Merchant' ? 'Entity Name' : 'Name', filterable : true, sortable : true, width : 200 },
        { field : 'action', headerName : 'Action', renderCell : cellParams => selectionModel && <Checkbox  style = {{ color : '#138DE8' }} onClick = { e => handleExclusionInclusionClick (cellParams.row.id) } checked = { selectionModel.indexOf (cellParams.row.id) !== -1 } /> }

    ];

    const handleCheck = () => {  };
    const handleClose = event => { setSearchText(''); props.setOpen(prev => !prev); };


    const history = useHistory();
    const handleNext = event => {
        props.setOpen (false);
        console.log(selectionModel);

        // let selectedRows = rows.filter (i => selectionModel.indexOf(i.id) !== -1);
        let selectedRows = [];
        Object.values(selectionModelMap).forEach(i =>  { selectedRows.push(...i) }  ) ; 

        var req = props.currentRequest;
         req.groupIds = selectedRows;
         let temp = props.currentRequest;
         temp.userSegment.selectedUsers = selectedRows;
         console.log(temp);
        props.setCurrentRequest(prev=>({...prev, userSegment:{...prev.userSegment , selectedUsers : selectedRows }}))
        console.log("req", req);
        props.setCurrentRequest (req);

        //alert ( JSON.stringify (props.currentRequest));
        console.log(props.currentRequest, rows);
    };


    return (
    <Dialog open = { props.open } onClose = { handleClose }  >
        <DialogTitle  > Select Members for the Group </DialogTitle>
        <GridItem>  
                    <SearchBar 
                        width = '250px' 
                        handleSearch = { setSearchText }
                        handleRefresh = { setRefreshState }
                        refreshState = { refreshState }
                        placeholder = "Search on all details"
                    /> 
                    <br/>
                </GridItem>
        <DialogContent>  
            <DialogContentText> Select one or more users from the list to award. Please press next button once done </DialogContentText>
            <GridContainer>
                <GridItem xs = {12} md = {12} sm = {12} >  
                <DataGrid className={classes.root}
                    // checkboxSelection
                    loading = { listLoading }
                    page = { page }
                    pageSize = { pageSize }
                    rows = { rows }
                    rowCount = { rowCount }
                    columns = { columns.filter (i => (props.userType === 'Member' ? i.field !== 'code' : i.field !== 'membershipNumber')) }
                    pagination
                    paginationMode = 'server'
                    // onPageChange = { changeParams => {setPage (changeParams); setPageDelta (changeParams-page)} }
                    onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams); } }

                    filterMode = 'server'
                    //onFilterModelChange = { params => { if (params.filterModel.items[0].value) setFilterModel(params.filterModel.items[0]); } }
                    density = 'compact'
                    autoHeight
                    rowsPerPageOptions ={ [25, 50, 100 ]}
                    disableExtendRowFullWidth = { false }
                    // onRowSelected = { gridParam => {
                    //     if (gridParam.isSelected === true) { setRows (prev => [ ...rows, gridParam.data ]) }
                    //     else { setRows (prev => prev.filter(i => i.id !== gridParam.data.id)) }
                    // } }
                    // onSelectionChange = { newSelection => {  
                    //     console.log (newSelection);
                    //     setSelectionModel (newSelection.rowIds);   
                    //     props.setCurrentRequest(prev=>({...prev, userSegment: {...prev.userSegment , selectedUsers : [ ...newSelection.rowIds ] }}));
  
                    //    // props.setCurrentRequest(prev => { prev.groupIds = [ ...newSelection.rowIds ];
                    // //     return {...prev};
                    // //  });
                    // } }
                    // selectionModel = { selectionModel }

                    // onRowSelected = { gridParam => {
                    //     if (gridParam.isSelected === false) {  
                    //         console.log ("GHSDKSADSA", gridParam);
                    //         props.setCurrentRequest (prev => {
                    //             prev.groupIds = prev.groupIds.filter (i => gridParam.data.id !== i);
                    //             return { ...prev };
                    //         })
                    //     }
                    // } }
                    // onSelectionChange = { newSelection => {  
                    //     console.log (newSelection);
                    //     setSelectionModel (newSelection.rowIds);     
                    //     props.setCurrentRequest(prev => { 
                    //         let groupIds = prev.groupIds || [];
                    //         for (var i = 0; i < newSelection.rowIds.length; i++)
                    //             if (groupIds.indexOf(newSelection.rowIds[i]) === -1) 
                    //                 groupIds.push (newSelection.rowIds[i]);
                    //         prev.groupIds = [ ...groupIds ];
                    //         console.log ("Prev", prev.groupIds);
                    //         console.log("New Selection Row IDS:" , newSelection.rowIds )
                    //         props.setCurrentRequest(prev=>({...prev, userSegment: {...prev.userSegment , selectedUsers : [ ...groupIds ] }}));

                    //     return {...prev};
                    //  });
                    // }}
                    // selectionModel = { selectionModel }

                    onPageChange={(newPage) => {
                        console.log(selectionModel, prevSelectionModel);
                        prevSelectionModel.current = selectionModel;
                        var existing = selectionModelMap[ newPage ] || [];
                        setSelectionModel (prev => [ ...existing ]);
                        setTimeout(() => { setSelectionModel (prev => [ ...existing ]); });
                        console.log("Restored", page, selectionModelMap, selectionModel);
                        setSelectionModelNew ({ rowIds : [ ...existing ] });
                        setPage(newPage);
                        setPageDelta (newPage-page)
                    }}
                />
                </GridItem>
            </GridContainer>
        </DialogContent>
        <DialogActions> 
            <center>
                <Button style = {{ background : '#FF9017', color : 'floralwhite', height : 40, width : 100  , textTransform: 'None'}} variant = 'contained' onClick = { handleNext } > Next </Button>
            </center>
        </DialogActions>
    </Dialog>
    );
};



export default function UserType (props) {

    const { currentNotification, setCurrentNotification, steps ,setSteps , createMode } = props;
    const [edit, setEdit] = useState(false);

    const classes = useStyles ();
    const history = useHistory ();
    const [ check, setCheck ] = useState (false);
    const [ page, setPage ] = useState (1);
    const [ pageSize, setPageSize ] = useState (100);
    const [ filterModel, setFilterModel ] = useState (null);
    const [ rowCount, setRowCount ] = useState (0);
    const [ segmentList, setSegmentList ] = useState ([]);
    const [ segmentLoading, setSegmentLoading ] = useState (false);
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [createOpen, setCreateOpen ] = useState (false);
    const [ saveOpen, setSaveOpen ] = useState (false);
   
    const [openSingle, setOpenSingle] = React.useState(false);
    const [openMultiple, setOpenMultiple] = React.useState(false);
    const [ uploadInProgress , setUploadInProgress ] = useState(false);
    const [ didFileUploadFail , setDidFileUploadFail ] = useState( false );
    const [ keyFileName ,setKeyFileName ] = useState("");
    const [ name ,setName ] = useState("");
    const [ open3 , setOpen3] = useState(false);
    const [ open2 , setOpen2] = useState(false);

    // if (props.createMode ===  false){
    //     console.log(props.id , props && props.location && props.location.params && props.location.params.state && props.location.params.state);
    // }
    console.log( props );

    useEffect (() => {
        if(props && props.createMode ===  false)
        {
           setCurrentNotification(props && props.id);
           setName(props && props.id && props.id.imageUrl);
        }    
}, [ ]);
    
    useEffect (() => {
        setSegmentLoading (true);
        userSegmentApiService.fetchSegmentList ()
        .then (response => { 
            if (! response || ! response.data) { return HWMessage.show ("Data cannot be fetched at moement","error"); }
            setSegmentList (response.data.userSegments);     
            console.log(response.data);
            
        }).catch (error => { HWMessage.show (error.response.data.message || "Request could not be resolved", "error"); })
        .finally (() => setSegmentLoading (false));
}, [ ]);

const saveNotification = useCallback (rule => {
    if (rule !== null)  {
        let successStatus = null;
        setRuleLoading (true);
        notificationAPIService.createNotification (rule)
        .then (response => {
            if (! response || ! response.data) { HWMessage.show ("Error while fetchinmg response","error"); }
          //  setCurrentRule (response.data);
            // setRefreshState (prev => !prev);
            successStatus = true;
            setSaveOpen(false);
            setOpen3(true);
            HWMessage.show("Request Successful" , "success");
           
        }).catch (error => { HWMessage.show (error.response.data.message || "Request could not be resolved", "error"); successStatus = false; })
        .finally (() => { 
            setRuleLoading (false);  
        });
    }
});

const updateNotification = useCallback (rule => {

    if (rule !== null)  {
        let successStatus = null;
        setRuleLoading (true);
        notificationAPIService.updateNotification (rule)
        .then (response => {
            if (! response || ! response.data) { HWMessage.show ("Error while fetchinmg response","error"); }
          //  setCurrentRule (response.data);
            // props.setRefreshState (prev => !prev);
            successStatus = true;
            setSaveOpen(false);
            setOpen2(true);

            HWMessage.show("Request Successful" , "success");

        }).catch (error => { HWMessage.show (error.response.data.message || "Request could not be resolved", "error"); successStatus = false; })
        .finally (() => { 
            setRuleLoading (false);
               
        });
    }
});


const checkDisabled = () =>{

    if(currentNotification && (new Date(currentNotification.scheduledTime)< new Date()))
    {
        return true;
    }
    if(currentNotification && currentNotification.recipientType &&  currentNotification.recipientType === "Member" 
     && currentNotification.body &&  currentNotification.body !==''  && currentNotification.title &&  currentNotification.title !=='' 
    && currentNotification.scheduledTime  )
    {
        if(currentNotification && currentNotification.userSegment && currentNotification.userSegment.userType && currentNotification.userSegment.userType === 'All')
            return false;

        else if(currentNotification && currentNotification.userSegment && currentNotification.userSegment.userType && currentNotification.userSegment.userType === "UserSegment" && currentNotification.userSegment.segmentId && currentNotification.userSegment.segmentId!=='' )
             return false;

        else if(currentNotification && currentNotification.userSegment && currentNotification.userSegment.userType && currentNotification.userSegment.userType === 'Group' && currentNotification.userSegment.selectedUsers && currentNotification.userSegment.selectedUsers.length > 0 )
             return false;
        else 
             return true;


    }
    else if(currentNotification && currentNotification.recipientType &&  currentNotification.recipientType === "Merchant" 
    && currentNotification.body &&  currentNotification.body !==''
    && currentNotification.scheduledTime)
    {
        if(currentNotification && currentNotification.userSegment && currentNotification.userSegment.userType && currentNotification.userSegment.userType === 'All')
             return false;
        
        else if(currentNotification && currentNotification.userSegment && currentNotification.userSegment.userType && currentNotification.userSegment.userType === 'Group' && currentNotification.userSegment.selectedUsers && currentNotification.userSegment.selectedUsers.length > 0 )
             return false;
        else 
             return true;
    
    }
    else{
        return true;
    }

}


useEffect (() => {
    if(props.createMode ===  false){
        setSegmentLoading (true);
        notificationAPIService.fetchNotifications ()
        .then (response => { 
            if (! response || ! response.data) { return HWMessage.show ("Data cannot be fetched at moement","error"); }
           // setCurrentNotification (response.data.userSegments);     
            console.log(response.data);

        }).catch (error => { HWMessage.show (error.response.data.message || "Request could not be resolved", "error"); })
        .finally (() => setSegmentLoading (false));
    }
  
}, [ ]);





const  uploadBulkFile = (file , fileName) => {

    setDidFileUploadFail (false);
    notificationAPIService.fetchS3PresignedUrl(fileName).then(response => {
        setDidFileUploadFail (false);
        console.log(response.data.presignedUrl);

        setKeyFileName (response.data.keyName);     
        multibulkPartUpload(file, response.data.presignedUrl);

    }).catch((reason) => {
        setDidFileUploadFail (true);
        setUploadInProgress(true);
        setUploadInProgress(false);
    } )
}

const multibulkPartUpload = (file, preSignedURL) => {
    notificationAPIService.uploadFile(file, preSignedURL).then(response => {

    setDidFileUploadFail (false);
    setUploadInProgress(false);

}).catch((reason) => {
    setDidFileUploadFail (false);
    setUploadInProgress(false);

})
}
 

const saveProceed = event => { 
    props.setOpenSave(true);

};

const handleDraftClick = (event) => {
    history.goBack();
    
}
 {/* <GridItem xs = {12} md = {7} sm = {12} >
                                    <h4>Recommended Placeholders</h4>
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900 , margin: 5}} label =  "<>Member Name<>"  />
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900 ,margin: 5}} label =  "<>Membership Tier<>"  />
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900,margin: 5}} label =  "<>Account ID>"  />
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900,margin: 5}} label =  "<>Points Balance<>"  />
                            </GridItem >   */}


var placeholders = [
    { name: "Member Name", placeholder: "<Member Name>" },
    { name: "Membership Tier", placeholder: "<Membership Tier>" },
    { name: "Membership Number", placeholder: "<Membership Number>" },
    { name: "Points Balance", placeholder: "<Points Balance>" },
    { name: "Merchant Name", placeholder: "<Merchant Name>" },
    { name: "Merchant Code", placeholder: "<Merchant Code>" },
    { name: "Merchant Points Balance", placeholder: "<Merchant Points balance>" }

];
    return (
    <Fragment>
                <Card>
                <BlockUi blocking = { ruleLoading ||  segmentLoading } message = "Loading" tag = "div"  >

                    <CardHeader className = { classes.cardHeader } color = 'rose' icon >
                            <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <NotificationsIcon /> </CardIcon>
{                     props.createMode == true && <h4 className = { classes.cardIconTitle } > User Type  </h4> 
}                             
{  props.createMode ===false && <h4 className = { classes.cardIconTitle } > User Type   &nbsp; <a style={{cursor: "pointer"}}><Edit style={{color: "#138de8"}} onClick={e=>setEdit(!edit)} /></a> </h4> 
}
                    </CardHeader>
                    <br/>
                    <CardBody>
                        <GridContainer>
                                {/* <GridItem xs = {12} md = {12} style = {{ marginTop : 10 }} >  

                                        <FormControl component = "fieldset" >
                                            <GridContainer>
                                                <GridItem> <FormLabel component = "legend" style = {{ marginBottom : 0 }} > User Type </FormLabel> </GridItem> 
                                                <GridItem xs = {12} md = {12} sm = {12} >  
                                                    <RadioGroup  value={currentNotification && currentNotification.recipientType} row aria-label = "user-type" name = "recipientType"  > 
                                                        <FormControlLabel 
                                                            value = "Member" 
                                                            onChange = { event => { 
                                                                if (event.target.checked)  {
                                                                    props.setCurrentNotification(prev => ({ ... prev, recipientType : 'Member'}));                                                                                                                                     
                                                       }    }
 }
                                                            control = { <Radio style={{color :"#138DE8"}} />  } label = { 'Member' } 
                                                        />  
                                                        <FormControlLabel 
                                                            value = "Merchant" 
                                                            onChange = { event => { 
                                                                if (event.target.checked)  {
                                                                    props.setCurrentNotification(prev => ({ ... prev, recipientType : 'Merchant'})); 
                                                                }    
                                                            }
                                                        }
                                                            control = { 
                                                                <Radio style={{color :"#138DE8"}} />   } label = { 'Merchant' } />  
                                                       
                                                    </RadioGroup>    
                                                </GridItem> 
                                            </GridContainer>
                                        </FormControl>  
                            </GridItem> */}
                            
                            <GridItem xs = {12} md = {12} >
                                        <FormControl component = "fieldset" >
                                            <GridContainer>
                                                <br/>
                                                <GridItem xs = {12} md = {12} sm = {12} > <FormLabel component = "legend" style = {{ marginBottom : 0 }}  > User Type </FormLabel> </GridItem> 
                                                <GridItem  >  
                                                    <RadioGroup      required = {true}                                               disabled={props.createMode === false ? !edit : false}
 value={currentNotification && currentNotification.recipientType } style={{color :"#138DE8"}} row aria-label = "recipient-Type" name = "recipientType"  > 
                                                     <FormControlLabel  value="Member"   
                                                                                                             checked={currentNotification && currentNotification.recipientType  === "Member" ? true : false}
                                                                                                             disabled={props.createMode === false ? true : false}
                                                                                                             onChange = { event => { 
                                                                if (event.target.checked)  {
                                                                    props.setCurrentNotification(prev => ({ ... prev, recipientType: "Member"})); 
                                                                }    
                                                            }
                                                        }
                                                        control = { <Radio style={{color :"#138DE8"}} />  } label = { 'Member' } />  

                                                        <FormControlLabel  
                                                        value="Merchant"
                                                        checked={currentNotification && currentNotification.recipientType  === "Merchant" ? true : false}
                                                        disabled={props.createMode === false ? true : false}
                                                        onChange = { event => { 
                                                            if (event.target.checked)  {
                                                                props.setCurrentNotification(prev => ({ ... prev, recipientType: "Merchant"})); 

                                                            }    
                                                        }
                                                    }
                                                        control = { <Radio style={{color :"#138DE8"}}/>  } label = { 'Merchant' } /> 

                                                     
                                                    </RadioGroup>    
                                                </GridItem> 
                                            </GridContainer>
                                        </FormControl>  
                                    </GridItem>


                                { currentNotification && currentNotification.recipientType && currentNotification.recipientType === 'Member' && <GridItem xs = {12} md = {12} className = { classes.gridItem } >  
                                <GridContainer >
                                    <GridItem xs = {12} md = {12} >
                                        <FormControl component = "fieldset" >
                                            <GridContainer>
                                                <GridItem xs = {12} md = {12} sm = {12} > <FormLabel component = "legend" style = {{ marginBottom : 0 }}  > Member Options </FormLabel> </GridItem> 
                                                <GridItem  >  
                                                    <RadioGroup            required = {true}                                                                                                                                                     disabled={props.createMode === false ? true : false}

 value={currentNotification && currentNotification.userSegment && currentNotification.userSegment.userType } style={{color :"#138DE8"}} row aria-label = "user-type" name = "userType"  > 

                                                  
                                                        <FormControlLabel value = "All"   
                                                          checked={currentNotification && currentNotification.userSegment && currentNotification.userSegment.userType === "All" ? true : false}
                                                          disabled={props.createMode === false ? true : false}

                                                          onChange = { event => { 
                                                                if (event.target.checked)  {
                                                                    props.setCurrentNotification(prev => ({ ... prev, userSegment : { userType : "All" }})); 
                                                                }    
                                                            }
                                                        }
                                                        control = { <Radio style={{color :"#138DE8"}} />  } label = { 'All Users' } />  

                                                        <FormControlLabel value = "UserGroup" 
                                                          checked={currentNotification && currentNotification.userSegment && currentNotification.userSegment.userType === "Group" ? true : false}
                                                          disabled={props.createMode === false ? true : false}

                                                          onChange = { event => { 
                                                            if (event.target.checked)  {
                                                                props.setCurrentNotification(prev => ({ ... prev, userSegment : { userType : "Group" }})); 
                                                                setCheck(true);

                                                            }    
                                                        }
                                                    }
                                                        control = { <Radio style={{color :"#138DE8"}}/>  } label = { 'User Group' } /> 

                                                        <FormControlLabel value = "Group" 
                                                        checked={currentNotification && currentNotification.userSegment && currentNotification.userSegment.userType === "UserSegment" ? true : false}
                                                        disabled={props.createMode === false ? true : false}

                                                        onChange = { event => { 
                                                            if (event.target.checked)  {
                                                                props.setCurrentNotification(prev => ({ ... prev, userSegment : { userType : "UserSegment" }})); 
                                                            }    
                                                        }
                                                    }
                                                        control = { <Radio style={{color :"#138DE8"}}/>  } label = { 'Member Segment' } />
                                                    </RadioGroup>    
                                                </GridItem> 
                                            </GridContainer>
                                        </FormControl>  
                                    </GridItem>
                                    </GridContainer >
                                    </GridItem>}
                                    <GridItem xs = {12} md = {12} className = { classes.gridItem } >  
                                <GridContainer >
                                    {currentNotification && currentNotification.recipientType && currentNotification.recipientType === 'Merchant' && <GridItem xs = {12} md = {6} >
                                        <FormControl component = "fieldset" >
                                            <GridContainer>
                                                <GridItem xs = {12} md = {12} sm = {12} > <FormLabel component = "legend" style = {{ marginBottom : 0 }}  > Merchant Options </FormLabel> </GridItem> 
                                                <GridItem  >  
                                                    <RadioGroup required = {true}  style={{color :"#138DE8"}} row aria-label = "user-type" name = "userType"     > 
                                                        <FormControlLabel value = "All" 
                                                             checked={currentNotification && currentNotification.userSegment && currentNotification.userSegment.userType === "All" ? true : false}
                                                             disabled={props.createMode === false ? true : false}
                                                             onChange = { event => { 
                                                            if (event.target.checked)  {
                                                                props.setCurrentNotification(prev => ({ ... prev, userSegment : { userType : "All" }})); 
                                                            }    
                                                        }
                                                    }
                                                        control = { <Radio style={{color :"#138DE8"}} />  } label = { 'All Merchants' } />  
                                                        <FormControlLabel value = "Group" 
                                                             checked={currentNotification && currentNotification.userSegment && currentNotification.userSegment.userType === "Group" ? true : false}
                                                             disabled={props.createMode === false ? true : false}
                                                             onChange = { event => { 
                                                            if (event.target.checked)  {
                                                                props.setCurrentNotification(prev => ({ ... prev, userSegment : { userType : "Group" }})); 
                                                                setCheck(true);
                                                            }    
                                                        }
                                                    }
                                                        control = { <Radio style={{color :"#138DE8"}}/>  } label = { 'Merchant Group' } /> 
                                                    </RadioGroup>    
                                                </GridItem> 
                                            </GridContainer>
                                        </FormControl>  
                                    </GridItem>}
                                    </GridContainer >
                                    </GridItem>
                                   {currentNotification && currentNotification.recipientType && currentNotification.recipientType === 'Member'  && currentNotification.userSegment && currentNotification.userSegment.userType === 'UserSegment' &&  <GridItem xs = {12} md = {12} className = { classes.gridItem } >  
                                <GridContainer >
                                    <GridItem xs = {12} md = {6} >
                                        <FormControl component = "fieldset" >
                                            <GridContainer>
                                                <GridItem  >  
                                                <Autocomplete 
                                                                                                disabled={props.createMode === false ? !edit : false}

                                            className = { classes.autoComplete }
                                            options = { segmentList } 
                                            // onChange = { (event, value, reason) => {
                                            //     if (reason === "select-option") {

                                                    
                                            //         props.setCurrentNotification(prev => ({ ... prev, userSegment : {...prev.userSegment,  SegmentName :  value }})); 

                                            //     }
                                            // } }
                                       
                                            renderInput = { params => <TextField  { ...params } label = {  'Search User Segment' } variant = 'outlined' /> } 
                        
                                            getOptionLabel={(option) => {
                                                
                                                return (option.name && option.name !== null) ? option.name : '' ;
                                            }}
                                            renderOption={(option) => option.name}
                                            onChange = { (event, value, reason) => {
                                                if (reason === "select-option") {
                                                    let list = segmentList;
                                                    let userSegmentId = null;
                                                    if ( list && list.length > 0) { list = list.filter(i => i.name === value.name); }
                                                    if (list.length > 0) { userSegmentId = list[0].id; }

                                                    if (userSegmentId !== null) { props.setCurrentNotification(prev => ({ ... prev, userSegment : {...prev.userSegment,  segmentId :  userSegmentId }}));   
                                                   // setCurrentNotification (prev => ({ ...prev, memberCount : value.memberCount }) );

                                                }
                                                }
                                            } }
                                            style = {{ width : 300, marginTop : 25 }} 
                                            renderInput = { params => <TextField { ...params } label = { 'Segment Selector' } onChange = { event => { segmentList.shift(); segmentList.unshift(event.target.value); setSegmentList (segmentList); } }  variant = 'outlined' /> } 
                                        />
                                        
                                    
                                                </GridItem> 
                                            </GridContainer>
                                        </FormControl>  
                                    </GridItem>
                                    </GridContainer >
                                    </GridItem>}

                                  
                                    <GridItem xs = {12} md = {7} sm = {12} >
                                    {/* <HWInput  /> */}
                            <br/>
                             <GridItem xs = {12} md = {12} sm = {12} >
                                    <h4>Recommended Placeholders</h4>
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900 , margin: 5}} label =  "<Member Name>"  />
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900 ,margin: 5}} label =  "<Membership Tier>"  />
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900,margin: 5}} label =  "<Points Balance>"  />
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900,margin: 5}} label =  "<Merchant Name>"  />
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900,margin: 5}} label =  "<Merchant Code>"  />
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900,margin: 5}} label =  "<Merchant Points balance>"  />

                            </GridItem >  
                            <h4>Title</h4>
                            <AutocompleteTextArea label="Title" required = {true} disabled={props.createMode === true ? false : edit === true && new Date(currentNotification && currentNotification.scheduledTime) > new Date() ? false : true} value={currentNotification && currentNotification.title ? currentNotification.title : ""} name = "title" onChange = {e =>props.setCurrentNotification(prev => ({ ... prev, title : e.target.value }))} placeholders={placeholders} />
                            
                            <h4>Description</h4>
                            <AutocompleteTextArea label="Description" required = {true} disabled={props.createMode === true ? false : edit === true && new Date(currentNotification && currentNotification.scheduledTime) > new Date() ? false : true} value={currentNotification && currentNotification.body ? currentNotification.body : ""} name = "description" onChange = {e =>props.setCurrentNotification(prev => ({ ... prev, body : e.target.value }))} placeholders={placeholders} />
                            
                            </GridItem >
                            <br/>
                            <br/>

                            <GridItem xs = {12} md = {12} sm = {12} >  
                               <center>
                               <h4> Image Upload  </h4>
                                <div>
                                <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }}  disabled={props.createMode === false ? !edit : false}  onClick={() => setOpenSingle(true)}>Add Images </Button>
                                {console.log('hello', [name])}
                                 {name!==""  &&  <DropzoneDialog
                                    clearOnUnmount={ false }
                                    initialFiles = { [name]}
                                    acceptedFiles={['image/*']} 
                                    cancelButtonText={"cancel"}
                                    submitButtonText={"submit"}
                                    maxFileSize={5000000}
                                    dropzoneText = { "Please upload image with aspect-ratio 1.78:1 and dimensions 1920 x 1080. "}
                                    dropzoneParagraphClass = {"abc"}
                                    open={openSingle}
                                    filesLimit={1}
                                    onDelete = {(deletedFile) =>  setCurrentNotification (prev => ({ ...prev, imageUrl : '' })) }
                                    onClose={() => setOpenSingle(false)}
                                    onSave={(bannerImageFile) => {
                                        console.log('Banner Image File:', bannerImageFile);
                                        const img = new Image();
                                        img.src = URL.createObjectURL(bannerImageFile[0]);
                                        img.onload = function () {
                                        //     const resolution = `${img.naturalWidth}x${img.naturalHeight}`;
                                        // console.log("resolution",resolution)
                                            const height = this.height;
                                            const width = this.width;
                                            console.log('Image dimensions:', width, 'x', height);
                                            if(width != 1920 && height != 1080) {
                                            HWMessage.show("Please upload the image of proper Dimensions") 
                                            setOpenSingle(true);
                                            return;
                                            }
                                        };
                                        setUploadInProgress(true);
                                        let myuuid = uuidv4();
                                        let file = bannerImageFile[0];
                                        if (file != null) {
                                            var fileName = file.name
                                            uploadBulkFile(file , myuuid  + fileName );
                                        }
                                        setCurrentNotification (prev => ({ ...prev, imageUrl : `${cloudfrontConfig.cloudfrontBaseUrl}/notification-images/${ myuuid + fileName }`})) ;
                                        setOpenSingle(false);
                                    }}
                                    showPreviews={true}
                                    showFileNamesInPreview={true}
                                    fullWidth ={true}
                                     />
                                    }

                                   {name === "" && <DropzoneDialog
                                        clearOnUnmount={ false }
                                        initialFiles = {[]}
                                        acceptedFiles={['image/*']} 
                                        cancelButtonText={"cancel"}
                                        submitButtonText={"submit"}
                                        maxFileSize={5000000}
                                        dropzoneText = { "Please upload image with aspect-ratio 1.78:1 and dimensions 1920 x 1080. "}
                                        dropzoneParagraphClass = {"abc"}
                                        open={openSingle}
                                        filesLimit={1}
                                        onDelete = {(deletedFile) =>  setCurrentNotification (prev => ({ ...prev, imageUrl : '' })) }
                                        onClose={() => setOpenSingle(false)}
                                        onSave={(bannerImageFile) => {
                                            console.log('Banner Image File:', bannerImageFile);
                                            const img = new Image();
                                            img.src = URL.createObjectURL(bannerImageFile[0]);
                                            img.onload = function () {
                                            //     const resolution = `${img.naturalWidth}x${img.naturalHeight}`;
                                            // console.log("resolution",resolution)
                                              const height = this.height;
                                              const width = this.width;
                                              console.log('Image dimensions:', width, 'x', height);
                                              if(width != 1920 || height != 1080) {
                                                HWMessage.show("Please upload the image of proper Dimensions") 
                                                setOpenSingle(true);
                                                return;
                                              }
                                            };
                                            setUploadInProgress(true);
                                            let myuuid = uuidv4();
                                            let file = bannerImageFile[0];
                                            if (file != null) {
                                            var fileName = file.name
                                            uploadBulkFile(file , myuuid  + fileName );
                                            }                                                  
                                            setCurrentNotification (prev => ({ ...prev, imageUrl : `${cloudfrontConfig.cloudfrontBaseUrl}/notification-images/${ myuuid + fileName }`})) ;
                                            setOpenSingle(false);
                                        }}
                                        showPreviews={true}
                                        showFileNamesInPreview={true}
                                        fullWidth ={true}
                                        />}
                                </div> 

                               </center>
                                

                            
                        </GridItem>

                        <GridItem xs = {12} md = {6} sm = {12} >
                        <FormControl component = "fieldset" >
                                            <GridContainer>
                                                <GridItem xs = {12} md = {12} sm = {12} > <FormLabel component = "legend" style = {{ marginBottom : 0 }}  > Notification Schedule </FormLabel> </GridItem> 
                                                
                                                <GridItem xs = {8} md = {8} sm = {8} > <HWInput disabled={props.createMode === false ? edit === false : new Date(currentNotification && currentNotification.scheduledTime)< new Date()? true : false} type = 'datetime-local' required={true}  value={currentNotification && currentNotification.scheduledTime && currentNotification.scheduledTime.split(":").slice(0,-1).join(":")} inputProps={{min : new Date().toISOString().split(":").slice(0,-1).join(":")}} name = {'notificationTime'}  labelProps = {{ shrink : true }} onBlur={e => props.setCurrentNotification(prev => ({ ... prev, scheduledTime : moment(new Date(e.target.value).toLocaleString("en-IN", { timeZone:"Asia/Singapore"}), 'DD/MM/YYYY, HH:mm:ss a').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z' }) )} /> </GridItem> 

                                            </GridContainer>
                                        </FormControl>  
                            <br/>
                            </GridItem >
                       

                            <GridItem style = {{ marginTop : 10 }} xs = { 12 } md = {12} >
                            
                                    <center> 
                                        <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { handleDraftClick }  > Cancel </Button> 
{                                        props.createMode  === true  &&  <Button variant = "contained" style = {{ backgroundColor : '#ff9017', color : 'floralwhite', textTransform : 'none' }} disabled={checkDisabled()}  onClick = {e=> setCreateOpen(true) } > Create </Button>
}
{                                        props.createMode ===false && edit === true && <Button variant = "contained" style = {{ backgroundColor : '#ff9017', color : 'floralwhite', textTransform : 'none' }} disabled={checkDisabled()}  onClick = {e=> setSaveOpen(true)} > Update </Button>
}
                                        {/* <Button variant = 'contained' className = { classes.proceedButton } onClick = {e=> saveNotification(currentNotification) } > Create </Button> */}
                                       <ConfirmationDialog open = { createOpen } setOpen = { setCreateOpen } dialogMessage = { 'Are you sure you want to create this notification' } dialogTitle = { 'Confirm Rule Creation' } confirmingAction = { () => saveNotification(currentNotification) } />
                                        <ConfirmationDialog open = { saveOpen } setOpen = { setSaveOpen }  dialogMessage = { 'Are you sure want to update the notification. ' } dialogTitle = { 'Confirm Rule Updation' } confirmingAction = { () => updateNotification(currentNotification) } />
                                        <StatusDialog isLoading = {false} open= { open3 } setOpen={setOpen3}  sucessStatus={"success"} confirmingAction={e=> history.push("notifications")} message={"Notification successfully created."} dialogTitle={"Status"}/> 
                                <StatusDialog isLoading = {false} open= { open2 } setOpen={setOpen2}  sucessStatus={"success"} confirmingAction={e=>history.push("notifications")} message={"Notification successfully updated."} dialogTitle={"Status"}/> 

                                    </center>
                            </GridItem>
                        </GridContainer>
                            <SearchUserDialog 
                            open = { check } 
                            userType = {currentNotification && currentNotification.recipientType}
                            setOpen = { setCheck }
                            currentRequest = { currentNotification }
                             setCurrentRequest = { setCurrentNotification }
                    />

                       

                    </CardBody>
                    <CardFooter>

                    </CardFooter>  
                </BlockUi>  
                </Card>
            
        </Fragment>
        
    )
};