import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { DataGrid, GridOverlay, GridToolbar,GridToolbarContainer,GridToolbarColumnsButton,GridToolbarFilterButton } from '@material-ui/data-grid';
import {  LinearProgress, makeStyles, Tooltip, withStyles, withWidth } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import memberApiService from './APIService';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import { People, VerifiedUser,Delete, DriveEtaRounded } from '@material-ui/icons';
import 'reactjs-popup/dist/index.css';
// import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { Link, useHistory } from 'react-router-dom';
import classNames from "classnames";
import SearchBar from 'views/Merchant/SearchBar';
import './MemberStyles.css';
import BorderLinearProgress from './BorderLinearProgress';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import HWMessage from 'HWLibrary/HWMessage';
import BlockUi from 'react-block-ui';


// Manages the members state as a viewModel and a model. Each are lists.
const membersReducer = (state, action) => {
  switch (action.type) {
    case 'set':
      const model = action.payload;
      console.log("model", model)
      const viewModel = model.map(member => {
        return {
          id: member.id,
          membershipNumber: member.membershipNumber,
          credentialType:member.credentialType,
          name: member.name,
          tier: tierViewModel(member.tier),
          icNumber : member && member.icNumber,
          countryCode: member && member.mobile && member.mobile.countryCode,
          mobile: member && member.mobile,
          mobileNumber: member.mobileNumber ? `${member.mobileNumber.countryCode}-${member.mobileNumber.mobileNumber}` : '',
          memberSince: member.memberSince ? new Date(member.memberSince).toLocaleDateString() : null,
          createTime: member.createTime,
          defaultCardNumber : cardViewModel (member.cards),
          email: member.email,
          gender: member.gender,
          state : memberState(member.state),
          address: {
            addressLine1: member && member.address && member.address.addressLine1,
            addressLine2: member && member.address && member.address.addressLine2,
            state: member && member.address && member.address.state,
            city: member && member.address && member.address.city,
            postCode: member && member.address && member.address.postCode
          },
          dateOfBirth: member.dateOfBirth,
          membershipStatus: member.membershipStatus
        }
      });
      return { model, viewModel };
    default: throw new Error('Invalid action type in reducer');
  }
}

const memberState = (state) => {
  switch (state) {
  case "SetupComplete" : return 'Setup Complete'
  case "ProfileSetupComplete" : return 'Profile Setup Complete'
  case "ContinueAsFan" : return 'Continue As Fan'
  case "ContinueAsOwner" : return 'Continue As Owner'
  case "MembershipNumberGenerated" : return 'Membership Number Generated'
  default: return state
  }
}

const tierViewModel = (tier) => {
  if (! tier || !tier.tierName) { return ''; }
  else if (tier.tierName) {  
    let name = tier.tierName;
    console.log("Name", name);
    return name.charAt (0).toUpperCase () + name.substring (1);
  }
};
const cardViewModel = (cards) => {
  if (cards === null || cards.length === 0) { return ''; }
  else if (cards.length > 0) {
    let defaultCard = cards.filter (i => i.defaultStatus === 'DefaultCard');
    console.log("Defgauly", cards, defaultCard);
    if (defaultCard === null || defaultCard.length === 0) { return ''; }
    else return defaultCard[0].cardNumber;
  }
};


export function CustomLoadingOverlay() {
  return (
    <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <BorderLinearProgress />
      </div>
  );
}

const Members = () => {

  if (HWLocalStorage.load ("previousVouchersTab") !== null ) 
  HWLocalStorage.clear("previousVouchersTab");
 if(HWLocalStorage.load ("previousVouchersRuleTab") !== null)
   HWLocalStorage.clear("previousVouchersRuleTab");

  const columns = [
    { field: 'membershipNumber', headerName: 'Membership Number', sortable: true, minWidth:100, flex: 2.2, renderCell : cellParams => <span onClick = { (event) => openMemberDetails(cellParams.row) } > <Link style = {{ color : '#138DE8', fontWeight: 400 }} > { cellParams.row.membershipNumber } </Link>  </span> },
    { field: 'name', headerName: 'Name', sortable: true, minWidth:300, flex: 3.7,  renderCell : cellParams =>  <span onClick = { (event) => openMemberDetails(cellParams.row) } >  <Link style = {{ color : '#138DE8', fontWeight: 400 }} > { cellParams.row.name } </Link> </span> },
    { field: 'tier', headerName: 'Tier', sortable: false,minWidth:100, flex: 1.7,renderCell : cellParams => <span onClick = { (event) => openMemberDetails(cellParams.row) }>{cellParams.row.tier}</span>},
    { field: 'credentialType', headerName: 'Sign Up Type', sortable: false,minWidth:140, flex: 1.8,renderCell : cellParams => <span onClick = { (event) => openMemberDetails(cellParams.row) }>{cellParams.row.credentialType}</span> },
    { field: 'icNumber', headerName: 'IC Number', sortable: false,minWidth:130, flex: 2 },
    { field: 'mobileNumber',filterable :  false ,  headerName: 'Mobile Number',minWidth:140, sortable: false, flex: 2.2 },
    { field: 'defaultCardNumber', filterable :  false ,  headerName: 'Membership Card',minWidth:130, sortable: false, flex: 2 },
    { field: 'state', headerName: 'State', sortable: true,minWidth:130, flex: 2 },
    { field: 'membershipStatus', headerName: 'Membership Status',minWidth:80, sortable: false, flex: 1.7 },
    { field: 'createTime', headerName: 'Create Date',filterable: false , minWidth:100, sortable: true, flex: 2, renderCell : cellParams => <span> { cellParams.value && new Date (cellParams.value).toLocaleDateString() } </span> },
    {field: 'delete',headerName:'',filterable:false,sortable:false,minWidth:50,flex:0.8,renderCell: cellParams => cellParams.row.state != "Setup Complete" ? <span onClick={(e) => { setMemberIdToDelete(cellParams.row.id); setDeleteOpen(true)} } style={{paddingTop:"15px"}}><Delete style = {{ color : '#FF9017' }}/></span> : <></> ,renderHeader: () => {return <></>}}
  ];

  const [membersViewModel, dispatch] = useReducer(membersReducer, { model: [], viewModel: [] });
  
  // Data grid changing states, 
  const [ page, setPage ] = useState (0);
  const [pageDelta, setPageDelta] = useState(0);
  const [ pageSize, setPageSize ] = useState (100);
  const [ filterModel, setFilterModel ] = useState ({});
  const [ sortModel, setSortModel ] = useState ({});

  const [ searchText, setSearchText ] = useState ('');
  const [ refreshState, setRefreshState ] = useState (false);
  const [ rowCount, setRowCount ] = useState (0);
  const [loading, setLoading] = useState(false);
  const [firstSortId, setFirstSortId] = useState();
  const [lastSortId, setLastSortId] = useState();
  const [ deleteOpen, setDeleteOpen ] = useState (false);
  const [memberIdToDelete,setMemberIdToDelete] = useState(null);
  
  useEffect (() => {
    setLoading (true);
    var paginationBookmark = ((page) * pageSize);
    var fetchLimit = pageSize;
    memberApiService.fetch ({fetchLimit, paginationBookmark, filterModel, sortModel, searchText,firstSortId,lastSortId,pageDelta,pageSize})
    .then (response => {
        if (!response || !response.data)
            return console.log("No relevant data can be fetched");
        
        setRowCount (response.data.membersCount); 
        setFirstSortId(response.data.firstSortId)
        setLastSortId(response.data.lastSortId)

        // console.log(response.data)
        //setCurrentPage(page)
        //setNextPage()
        dispatch({ type: 'set', payload: response.data.memberList });
        
    })
    .catch (error => { console.log("Error while fetching data", error); })
    .finally(() =>{ setLoading (false);
      var my_element = document.getElementById("top");
      if(my_element!== null || my_element!== undefined || my_element!== '')
      {
          // my_element.scrollIntoView({ }); 

      } 
    
    });
  }, [ page, pageSize, filterModel, sortModel, searchText, refreshState]);    

  const history = useHistory();

  const openMemberDetails = useCallback((member) => {
    history.push({pathname : "member-tabs", params : {state: {id : member.id}}})
  }, []);


  const deleteMemeberDetails = (id) => {
    setLoading (true);
    memberApiService.deleteMember (id)
    .then (response => {  
        if (! response || ! response.data) { HWMessage.show ("Error while deleting member","error"); }
        HWMessage.show ("Member Deleted Successfully","success");
        dispatch({ type: 'set', payload: membersViewModel.model.filter(i => i.id !== id)});
        setRowCount (rowCount - 1);
    })
    .catch (error => { HWMessage.show ("Error while deleting member" + error.message ,"error"); })
    .finally (() => setLoading (false));
  }

  const useStyles = makeStyles(theme => ({
    ...styles,
    popoverRoot: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    root: {
      '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300, alignItems: 'center' },
      '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
      '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
      '& .MuiCheckbox-root': { borderRadius: '30px', backgroundColor: 'rgba(0, 0, 0, 0, 0)' },
      '& .Mui-checked': {borderRadius: '3px', backgroundColor: 'rgba(0, 0, 0, 0, 0)', color: 'rgba(200, 0, 0, .1)'},
      '& .MuiDataGrid-rowCount' : { fontWeight : '100', value : (loading) ? 0 : rowCount },
      '& .MuiButton-label' : { color : '#138DE8' },
      '& .MuiDataGrid-row:hover' : {cursor:"pointer"}

    }
  }));

  const classes = useStyles();
  const { rtlActive } = true;
  const searchButton =
    classes.top +
    " " +
    classes.searchButton +
    " " +
    classNames({
      [classes.searchRTL]: rtlActive
    });

  const refreshHandler = (refreshState) => {
    setRefreshState (refreshState);
    setSortModel (null);
    setFilterModel (null);
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
      <BlockUi blocking = { loading } message = { 'Please wait while we fetch.' } >
      <Card id="top" style={{margin:"0px"}}>
            <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                <h4 className = { classes.cardIconTitle } > Member List </h4> 
            </CardHeader>
            <CardBody>
              <GridContainer style = {{ justifyContent : 'start',marginBottom:"10px"}} >
              <GridItem>  
                        <SearchBar 
                          handleSearch = { setSearchText }
                          handleRefresh = { refreshHandler }
                          refreshState = { refreshState } 
                          placeholder = { 'Search by name, membership number, IC number, tier, or state' }
                        /> 
              </GridItem>
              </GridContainer>
              <DataGrid 
                className={classes.root} 
                rows={membersViewModel.viewModel} 
                columns={columns} 
                page = {page}
                rowCount = { rowCount }
                pageSize = { pageSize }
                paginationMode = "server"
                onPageChange = { (gridChangeParams) => { setPage (gridChangeParams);setPageDelta (gridChangeParams-page) /*setPageSize (gridChangeParams.pageSize);*/  } }
                onPageSizeChange = { (gridChangeParams) => { setPage (0); setPageSize(gridChangeParams); } }

                sortingMode="server" 
                onSortModelChange = { params => { 
                  // console.log("Sorting model", params);  
                  if (params.length > 0 ) 
                      setSortModel (params[0]);
                } }
              
                filterMode = 'server'
                onFilterModelChange = { (params) => {
                  // console.log("HelloParams",params); 
                  /** Make the changes upon changing filter model */
                  setPage (0); setPageSize(25);
                  if (params.items.length > 0) {
                    var value = params.items[0].value;
                    setFilterModel (prev => ({ ...params.items[0] })); 
                  }
                } }
                disableMultipleSelection={true} 
                density='compact'
                autoHeight 
                
                showToolbar 
                  rowsPerPageOptions ={ [25, 50, 100 ]}

                components={{ Toolbar: CustomToolbar, loadingOverlay: CustomLoadingOverlay }} 
                loading={loading}
                //onRowClick={(rowDetails) => { openMemberDetails(rowDetails.row); }} 
              />
            </CardBody>
            
          </Card>
         <ConfirmationDialog open = { deleteOpen } setOpen = { setDeleteOpen } confirmingAction = { (e) => deleteMemeberDetails(memberIdToDelete)} dialogMessage = { 'Confirm Delete' } dialogTitle = { 'Are you sure want to delete this member as it is irreversible process ?' }  />
         </BlockUi>
      </GridItem>
    </GridContainer>
  );
}

export function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

export default Members;