import { Button,  IconButton, makeStyles, Popover } from '@material-ui/core';
import { DataGrid , GridToolbar} from '@material-ui/data-grid';
import { MoreVert, People } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState, useEffect, Fragment } from 'react';
import { DirectActionDialog } from 'views/Actions/VOCVerification';
import SearchBar from 'views/Merchant/SearchBar';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import BlockUi from 'react-block-ui';
import HWMessage from 'HWLibrary/HWMessage';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import pointsRequestsApiService from './APIService';
import { green, red } from '@material-ui/core/colors';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import { CustomToolbar } from 'views/Members/Member';

 const useStyle = makeStyles (theme => ({
     ...styles,
     rewardButton : { background : '#138DE8', color : 'floralwhite', textTransform : 'initial', fontSize : 22, height : 50, width : 200 },
     deductButton : { background : '#FF9017', color : 'floralwhite',marginLeft:10, textTransform : 'initial', fontSize : 22, height : 50, width : 200 } ,
     bottomGrid : { marginTop : 10},
     actionIcon : { color : '#138DE8' },
     editButton : { background : '#138DE8', color : 'floralwhite' , marginLeft : 10 , textTransform: 'None'},
     deleteButton : { background : '#FF9017', color : 'floralwhite' ,marginRight : 10 , textTransform:'None'}
 }));



export default function PointsConfiguration (props) {
    if(HWLocalStorage.load("previousPointsRuleTab")!== null)
    {
        HWLocalStorage.clear("previousPointsRuleTab");
    }
    HWLocalStorage.save (0, "previousPointsTab");

    var point=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    point= [...obj["Points"]];
    const classes = useStyle ();

     const getTextColor = approvalStatus => {
        if (approvalStatus && approvalStatus !== null) {  
            if (approvalStatus === 'Approved') return green[500];
            else if (approvalStatus === 'Pending') return '#FF9017';
            else if (approvalStatus === 'Rejected') return red[400];
        }
     };

     const columns = [
        { field : 'refNumber',          headerName : 'Reference Number',    filterable : false,  sortable : false, flex : 1.2 , renderCell : cellParams => <span style={{color: "#138DE8"}} onClick = { event =>{ handleEdit (event, cellParams.row); } } > {cellParams.row.refNumber} </span> },
        { field : 'requestType',        headerName : 'Request Type',        filterable : true,  sortable : true, flex : 1.2 },
        { field : 'userCount',          headerName : 'User Count',          filterable : false,  sortable : true, flex : 1 },
        { field : 'pointsPerUser',      headerName : 'Points Per User',     filterable : false,  sortable : true, flex : 1 },
        { field : 'raisedByMerchantName',      headerName : 'Raised By',     filterable : false,  sortable : true, flex : 1 , renderCell : cellParams => <span> { cellParams.row.raisedByMerchantName !== undefined ? cellParams.row.raisedByMerchantName : cellParams.row.resolvedByEmpName } </span> }, 
        { field : 'totalPoints',        headerName : 'Total Points',        filterable : false, sortable : true, flex : 1 },
        { field : 'approvalStatus',     headerName : 'Status',              filterable : true,  sortable : true, flex : 1,   renderCell : cellParams => <span  style = {{ color : getTextColor (cellParams.row.approvalStatus), fontWeight : 600 }}  > { cellParams.row.approvalStatus } </span> },
        { field : 'raisedDate', filterable :  false , headerName : 'Raised Date', flex : 1, renderCell : cellParams => {
            var date = cellParams.row.raisedDate;
            var dateString = date.split('T');
            var d = new Date (dateString).toLocaleDateString();

            return <span> {d} </span>
        } },

        { field : 'resolvedDate', filterable :  false , headerName : 'Resolved Date', flex : 1, renderCell : cellParams => {
            var date = cellParams.row.raisedDate;
            var dateString = date.split('T');
            var d = new Date (dateString).toLocaleDateString();

            return <span> {d} </span>
        } },
    ];
 

    if(point.includes("Action") === true){
        columns.push(        { field : 'Edit',             headerName : '',             filterable : false, sortable : false, flex : 0.7,  renderCell : cellParams => cellParams.row.approvalStatus === 'Pending' && cellParams.row.requestType !== 'Returning' && <IconButton onClick = { event =>{ handleEdit (event, cellParams.row); } } > <Edit  style={{color:"#138de8"}} /> </IconButton>  }
        )
        columns.push(        { field : 'Delete',             headerName : '',             filterable : false, sortable : false, flex : 0.5,  renderCell : cellParams => cellParams.row.approvalStatus === 'Pending' && cellParams.row.requestType !== 'Returning' && <IconButton onClick = { event => {handleDelete (event, cellParams.row) ;  }} > <Delete style={{color:"#FF9017"}} /> </IconButton>  }
        )
    }
    
    const [ requestsLoading, setRequestsLoading ] = useState (false);
    const [ refreshState, setRefreshState ] = useState (false);
    const [ selectedId, setSelectedId ]  = useState("");
    const [ rows, setRows ] = useState ([]);
    const [ page, setPage ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ filterModel, setFilterModel ] = useState ({});
    const [ rowCount, setRowCount ] = useState (0);
    const [ openDirectAction, setOpenDirectAction ] = useState (false);
    const [ selectedRequest, setSelectedRequest ] = useState (null);
    const [ searchText, setSearchText ] = useState ('');
    const [ creatorMode , setCreatorMode ] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState (null);
    const [ actionOpen, setActionOpen ] = useState (false);
    const [ sortModel, setSortModel ] = useState ({});


    useEffect (() => {
        var paginationBookmark = (page) * pageSize;
        var fetchLimit = pageSize;
        setRequestsLoading (true);
        pointsRequestsApiService.fetchRequests ({ paginationBookmark, fetchLimit, filterModel, searchText })
        .then (response => { 
            if (! response || ! response.data) { return HWMessage.show ("Data cannot be fetched","error"); }              
            setRowCount (response.data.requestCounts);
            setRows (response.data.pointsRequests);
            HWMessage.show("Request Successful" , "success");
        }).catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() => { setRequestsLoading (false);  
            var my_element = document.getElementById("top");
            // my_element.scrollIntoView({ }); 

        });
    }, [ refreshState, pageSize, page, filterModel, searchText ]); 

    const deleteRequest = useCallback (requestId => {
        pointsRequestsApiService.deleteRequest (requestId) 
        .then (response => {  
            if (! response || ! response.data) { return HWMessage.show ("Error while fetching the response","error"); }
            HWMessage.show ("Requests updated Successfully");
            setRowCount (prev => prev - 1);
            setRows (prev => prev.filter (i => i.id !== requestId));
            HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    }) 
        .finally (() => { setRequestsLoading (false) });
    });
    
    const history = useHistory ();
    const handleClicks = (event, clickType) => {
        if (clickType !== null)  {
            history.push ({ pathname : 'audience-selection', state : { currentRequest : { requestType : clickType  } } });
        }
    };
    const openRequest = id => {
        if (id !== null) {
            history.push ({ pathname: "reward-points", state: { requestId: id , creatorMode : true, prev : 'points' } });
        }
    };
    const handleDelete = (e,row) => {
        const id = row.id;
        setSelectedId(id);
      //  deleteRequest (id);        // setAnchorEl (null);
        setActionOpen (true);
    };


    const handleRefresh = (refresh) => {
        setFilterModel (prev => {});
        setSortModel (prev => {});
        setRefreshState (prev => refresh);
    };


    const handleEdit = (e,row)  => {
        const id = row.id;
        // setAnchorEl (null);
        // setActionOpen (false);
        openRequest (id);
    };

    function CustomLoadingOverlay() {
        return (
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
      }

      const useDataGridStyles = makeStyles(theme => ({
        root: {
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiButton-label' : { color : '#138DE8' }
        }
      }));
      const dataGridClasses = useDataGridStyles();

    
     return (
         <Fragment>
             <BlockUi>
                 <Card id="top">
                     <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                         <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                         <h4 className = { classes.cardIconTitle } > 
                             Points Configurations
                         </h4> 
                     </CardHeader>
                     <CardBody>  
                         <GridContainer style = {{ justifyContent : 'space-between' }} >
                            <GridItem>
                                <SearchBar  
                                            width = "400px"
                                            handleSearch = { setSearchText }
                                            handleRefresh = { handleRefresh }
                                            refreshState = { refreshState }
                                            placeholder = { 'Search By Reference Number, User Counts etc.' }
                                        />
                            </GridItem>
                             <GridItem> 
{ point.includes("Action") === true && <Button variant = 'contained' className = { classes.rewardButton } onClick = { e => handleClicks (e, 'Accrue') } > Reward Points </Button>}                                        
{ point.includes("Action") === true &&  <Button variant = 'contained' className = { classes.deductButton } onClick = { e => handleClicks (e, 'Deduct') } > Deduct Points </Button>
} 
                             </GridItem>
                             </GridContainer>
                             <GridContainer>
                                <GridItem className = { classes.bottomGrid } xs = {12} md = {12} >
                                     <DataGrid 
                                         className = { dataGridClasses.root }
                                         showToolbar
                                         page = { page }
                                         pageSize = { pageSize }
                                         rows = { rows }
                                         rowCount = { rowCount }
                                         columns = { columns }
                                         pagination
                                         paginationMode = 'server'
                                         onPageChange = { changeParams => setPage (changeParams) }
                                         onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams); } }
                                         sortingMode="server" 
                                        onSortModelChange = { params => { 
                                        if (params.length > 0) 
                                            setSortModel (params[0]);
                                        }}
                                        filterMode = 'server'
                                        onFilterModelChange = { (params) => {
                                        if (params.items.length > 0) {
                                            setFilterModel (prev => ({ ...params.items[0] })); 
                                        }
                                        }}  
                                         density = 'compact'
                                         autoHeight
                                         rowsPerPageOptions ={ [25, 50, 100 ]}
                                         loading = { requestsLoading }
                                         components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 

                                         disableExtendRowFullWidth = { false }
                                     />
                                 </GridItem>
                            </GridContainer>
                     </CardBody>
                
                 </Card>
                 <ConfirmationDialog 
            dialogTitle = { 'Confirm Request Deletion' } 
            dialogMessage = { 'Are you sure you want to delete the request. ' } 
            confirmingAction = { e => deleteRequest (selectedId)  } open = { actionOpen } setOpen = { setActionOpen } 
        /> 
             </BlockUi>
         </Fragment>
     );
 };




