import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import  Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { typography } from '@material-ui/system';
import { HWInput } from 'HWLibrary/HWInput';
import { IconButton } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Fragment } from 'react';

export default function DescriptionPopover (props) {

    const handleClose = () => { props.setDescription(null); props.setOpen(false); props.setAnchorEl (null);}
    const handleTextFieldChanges = event => {props.setDescription (event.target.value); props && props.setReason(event.target.value)}
    const handleSubmit = () => {props.handleSubmit (props.description); props.setOpen(false); }

    return (
        <Fragment>
            <Popover
                open = { props.open }
                anchorEl = { props.anchorEl }
                onClose = { handleClose }
                anchorOrigin = {{ vertical : 'bottom', horizontal : 'center' }}
                transformOrigin = {{ vertical : 'top', horizontal : 'center' }}
                       >
                <HWInput 
                    multiline
                    label = {props.label} 
                    value = { props.description } 
                    onBlur = { handleTextFieldChanges }
                    placeholder = { props.placeholder }  
                />
                <center style ={{width:"31.25rem"}}>
                    <IconButton onClick = { handleSubmit } > <CheckIcon />  </IconButton>
                    <IconButton onClick = { handleClose } > <CloseIcon /> </IconButton>
                </center>
            </Popover>
        </Fragment>
    );
}