import { Button, makeStyles , TextField } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from 'HWLibrary/HWInput';
import pointsApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import { useCallback } from 'react';
import BlockUi from 'react-block-ui';
import { DropDown } from 'views/Merchant/Merchant';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import voucherConfigApiService from './APIService';
import merchantApiService from 'views/Merchant/APIService';
import Edit from '@material-ui/icons/Edit';
import StatusDialog from 'views/Utils/StatusDialog';
const useStyles = makeStyles (theme => ({
    ...styles,
    saveButton :  { color: 'floralwhite', backgroundColor : '#138DE8' , margin: 2.5 , margintop: 5 },
    cancelButton : { color: 'floralwhite', backgroundColor : '#FF9017' , margin: 2.5 , margintop: 5},
    card : { width : 700 }
}));

export default function RuleForm (props) {

    const classes = useStyles();

    const [ currentRule, setCurrentRule ] = useState ({});
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ productCategoryList, setProductCategoryList ] = useState ([]);
    const [ tierList, setTierList ] = useState ([]);
    const { createMode, ruleId } = props;
    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});
    const [ saveOpen, setSaveOpen ] = useState (false);
    const [ createOpen, setCreateOpen ] = useState (false);
    const [ edit , setEdit] = useState(false);
    const [ open3 , setOpen3] = useState(false);
    const [ open2 , setOpen2] = useState(false);
    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    }

    useEffect (() => {
        setRuleLoading (true);
    }, []);


    const isValid = rule => {
        let { validityStart, validityEnd } = rule;
        var startDate = new Date (validityStart);
        var endDate = new Date (validityEnd);
        var currentDate = new Date();
        
        return ! (endDate >= currentDate);
    }; 
    const isDisabled = false;

    const [ productLoading , setProductLoading ] = useState(false);
    
    const [ categoryList , setCategoryList ] = useState([]);

    const [ productList , setProductList ] = useState([]);
    useEffect (() => {
        setProductLoading (true);
        voucherConfigApiService.fetchProductCategories ()
        .then (response => {  
            if (! response || ! response.data) { return HWMessage.show ("Product Categories cannot be fetched" , "error"); }
            HWMessage.show("Request Successful" , "success");
            response.data.productCategoryList = response.data.productCategoryList.filter((i,idx) => i.applyType === undefined);
            setProductList (response.data.productCategoryList); 

        })
        .catch (error => { return HWMessage.show (error.response && error.response.data  && error.response.data.message || "Request could not be resolved", "error"); })
        .finally (() => setProductLoading (false));
    }, [])



    useEffect (() => {
        if(props.createMode == false){
            setProductLoading (true);
            voucherConfigApiService.fetchSpecificVoucherCategory (props.ruleId)
            .then (response => {  
                if (! response || ! response.data) { return HWMessage.show ("Specific Voucher Cannnot be fetched","error"); }
                HWMessage.show("Request Successful" , "success");
                setCurrentRule (response.data); 
            })
            .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error"); })
            .finally (() => setProductLoading (false));
        }
       
    }, [])

    const setValue2 = (field) => {
        switch(field)
        {
            case "Universal" :        setCurrentRule(prev => {  prev.applyType = "UniversalVoucher";  return { ...prev }; });   break;
            case "Private" :        setCurrentRule(prev => {  prev.applyType = "PrivateVoucher";  return { ...prev }; });    break;
    
        }
    }
    
    
    const getValue2 = () => {    
        switch(currentRule && currentRule.applyType)
        {
            case "UniversalVoucher" :           return   "Universal" ; break;
            case "PrivateVoucher" :         return   "Private" ; break;
            
    
        }
    }
    const updateRule = useCallback (rule => {
        if (rule !== null) {
            let successStatus = null;
            setRuleLoading (true);
            voucherConfigApiService.updateSpecificVoucherCategory (rule) 
            .then (response => {  
                if (! response || ! response.data) { HWMessage.show ("Error while fetching response" ,"error"); }
                HWMessage.show("Request Successful" , "success");
                setCurrentRule (response.data);
                setOpen2(!open2);
                props.setRefreshState(prev=>!prev);
                successStatus = true;
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error"); successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);  
                if (successStatus === true)
                   { 
                    props.setDirectActionOpen(false);
                   }
            });
        }
    });
    const createRule = useCallback (rule => {
        if (rule !== null)  {
            let successStatus = null;
            setRuleLoading (true);
            voucherConfigApiService.updateSpecificVoucherCategory (rule)
            .then (response => {
                if (! response || ! response.data) { HWMessage.show ("Error while fetchinmg response" , "error"); }
                HWMessage.show("Request Successful" , "success");
                setCurrentRule (response.data);
                setOpen3(!open3);

                props.setRefreshState (prev => !prev);
                successStatus = true;
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error"); successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);
                if (successStatus === true)
                    {
                    props.setDirectActionOpen(false);}
            });
        }
    });

    const checkDisabled =() =>{
        if(currentRule && currentRule.categoryName && currentRule.categoryName !== '' && currentRule.applyType && currentRule.applyType !== '' && currentRule.description && currentRule.description !== ''  )
            return false;
        else{
            return true;
        }
    }
    // useEffect (() => {
    //     setRuleLoading (true);
    //     merchantApiService.fetchCategories () 
    //     .then (response => {  
    //         if (! response || ! response.data) { return HWMessage.show ("Error while fetching categories"); }
    //         setCategoryList (response.data);
    //     }) 
    //     .catch (error => { HWMessage.show ("Error while fetching categories"); })
    //     .finally (() => setRuleLoading (false));
    // }, []);

    const captureChanges = event => { 
        const field = event.target.name;
        const value = event.target.value;

        console.log(currentRule , field, value);
        
        setCurrentRule (prev => ({ ...prev, [field] : value })); 
    };
    const handleSave = () => {
        if (props.createMode === true) { createRule (currentRule);  } 
        else if (props.createMode === false) { updateRule (currentRule); }
    };
    const handleCancel = () => { props.setOpen (prev => false); props.setDirectActionOpen(false) };

    return (
        <>         <BlockUi blocking = { productLoading } message = { 'Please wait while we fetch.' } >

                <Card className = { classes.card } >
{               props.createMode===true &&      <CardHeader style={{padding :5 , margin : 0}}>  <h3> Create New Category </h3>     </CardHeader>
}                  
{ props.createMode === false && <CardHeader style={{padding :5 , margin : 0}}>  <h3> Update Category  &nbsp; <a style={{cursor: "pointer"}}><Edit style={{color: "#138de8"}} onClick={e=>setEdit(!edit)} /></a>  </h3>     </CardHeader>
}
                  
                    <CardBody>  
                        <GridContainer>
                            
                            <GridItem xs = {12} md = {6} sm = {12} >  
                            <center>
                            {/* <DropDown 
                                    labelCategory = "Voucher Category" 
                                    name = "categoryName"
                                    minWidth = {300}
                                    values = { productList.map(i => i.categoryName) }
                                    minWidth = {300}
                                    targetValue = { currentRule && currentRule.categoryName }
                                    onChange = { e => { setCurrentRule(prev => {  prev.categoryName = e.target.value ; prev.categoryId = productList.filter (i => i.categoryName === e.target.value)[0].id; return { ...prev }; });  } }
                                    onBlur = { captureChanges  }
                                    helperText = { fetchErrorMessage ('VoucherCategory') }
                                    hasError = { checkError ('VoucherCategory') }
                                    disabled = { isDisabled }
                                />  */}
                                {/* <HWInput label = { 'Voucher Category' }  
                                                    name = { 'categoryName' } 
                                                    hasError = { (currentRule && currentRule.categoryName && currentRule.categoryName === '')  } 
                                                    helperText = { (currentRule && currentRule.categoryName && currentRule.categoryName === ''  ) ? "Invalid Value" : "" }
                                                    onBlur = { captureChanges }
                                                    value = {currentRule && currentRule.categoryName }
                                                    minWidth = {300}


                                                />  */}
                                                 <DropDown 
                                    labelCategory = "Voucher Category" 
                                    name = "categoryName"
                                    required = {true}
                                    values = { props.createMode === true ? productList.map(i => i.categoryName ): currentRule && [currentRule.categoryName] }
                                    minWidth = {300}
                                    targetValue = { currentRule && currentRule.categoryName }
                                    onChange = { e => { setCurrentRule(prev => {  prev.categoryName = e.target.value;  prev.id = productList.filter (i => i.categoryName === e.target.value)[0].id; return { ...prev }; });  } }
                                    onBlur = { captureChanges }
                                    helperText = { (currentRule && currentRule.categoryName && currentRule.categoryName === '') ? "Invalid Value" : ""  }
                                    hasError = {  (currentRule && currentRule.categoryName && currentRule.categoryName === '') ? "Invalid Value" : ""   }
                                    disabled = { props.createMode === false ? !edit : false }
                                    />  

                            </center>
                               
                            </GridItem>
                            <GridItem xs = {12} md = {6} sm = {12} >  
                            <center>
                            <DropDown 
                                    labelCategory = "Voucher Type" 
                                    name = "applyType"
                                    required = {true}
                                    values = { ['Universal', 'Private' ] }
                                    minWidth = {300}
                                    targetValue = { getValue2()  }
                                    onChange = { e=> {setValue2(e.target.value); }  }
                                   // onBlur = { captureChanges }
                                    helperText = { (currentRule && currentRule.applyType && currentRule.applyType === '') ? "Invalid Value" : ""  }
                                    hasError = {  (currentRule && currentRule.applyType && currentRule.applyType === '') ? "Invalid Value" : ""   }
                                    disabled = { props.createMode === false ? !edit : false }
                                    />  

                            </center>
                                
                            </GridItem>
                            <GridItem xs = {12} md = {12} sm = {12} >  
                              <center>

                                             <TextField 
                                                                                disabled = { props.createMode === false ? !edit : false }
                                                required={true}
                                               style = {{ marginTop : 20 }}
                                               multiline = { true }
                                               rows = { 10 }
                                               rowsMax = { 20 }
                                               value = { currentRule && currentRule.description }
                                               fullWidth
                                               variant = "outlined"
                                               label = {`Description` }
                                               placeholder = 'Please provide the description'
                                               name = { 'description' }
                                               InputLabelProps = {{ shrink : true }}
                                               inputProps = {{ maxLength : 100 }}
                                              // onBlur = { captureChanges }
                                              hasError = {  (currentRule && currentRule.description && currentRule.description === '' ) ? "Invalid Value" : ""  }
                                               onChange =  { captureChanges  }
                                              helperText = {(currentRule && currentRule.description && currentRule.description === '' ) ? "Invalid Value" : "" }
                                            />
                              </center>
                            </GridItem>
                            
                            <GridItem xs = {12} sm = {12} md = {12} >
                                {  <center>
{                         props.createMode === true &&            <Button disabled={checkDisabled()} onClick = { e => props.createMode === true ? setCreateOpen (true) : setSaveOpen (true) } raised variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} > { props.createMode === true ? 'Create' : 'Update' } </Button>
}      
{                         props.createMode === false &&  edit ===true &&           <Button disabled={checkDisabled()} onClick = { e => props.createMode === true ? setCreateOpen (true) : setSaveOpen (true) } raised variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} > { props.createMode === true ? 'Create' : 'Update' } </Button>
}   
                              <Button onClick = { handleCancel } raised variant = "contained" style = {{ backgroundColor : '#ff9017', color : 'floralwhite', textTransform : 'none' }} > Cancel </Button>
                                    <ConfirmationDialog open = { createOpen } setOpen = { setCreateOpen } confirmingAction = { handleSave } dialogMessage = { 'Are you sure you want to create this rule' } dialogTitle = { 'Confirm Rule Creation' }/>
                                
                                    <ConfirmationDialog open = { saveOpen } setOpen = { setSaveOpen } confirmingAction = { handleSave } dialogMessage = { 'Are you sure want to update the rule.' } dialogTitle = { 'Confirm Rule Updation' } />
                                    <StatusDialog isLoading = {false} open= { open3 } setOpen={setOpen3}  sucessStatus={"success"} confirmingAction={e=>props.setOpen (false)} message={"Voucher Category successfully created."} dialogTitle={"Status"}/> 
                                <StatusDialog isLoading = {false} open= { open2 } setOpen={setOpen2}  sucessStatus={"success"} confirmingAction={e=>props.setOpen (false)} message={"Voucher Category successfully updated."} dialogTitle={"Status"}/> 

                                </center>}
                            </GridItem>
                        </GridContainer>
                    </CardBody> 
                </Card>
                </BlockUi>
        </>
    );

};