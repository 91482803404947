import { AppBar, makeStyles, Tab, Tabs } from '@material-ui/core';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import HWMessage from 'HWLibrary/HWMessage';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import memberApiService from './APIService';
import ManageMembership from './ManageMembership';
import MemberCreate from './MemberCreate';
import MemberRequests from './MemberRequests';
import MemberRewards from './MemberRewards/MemberRewards';
import MemberTransactions from './MemberTransactions';


const useStyles = makeStyles((theme) => ({
    root: {
      overflow: 'hidden',
      minHeight: 48,
      WebkitOverflowScrolling: 'touch', // Add iOS momentum scrolling.
      display: 'flex',
      backgroundColor: "white",
      '& .MuiTabs-indicator' : { backgroundColor : '#FF9017' },
      "& .MuiButtonBase-root": {textTransform: "none"},
      color:"black",
      borderRadius: '6px'
    }
  }));

export default function MemberTabs (props) {

    const classes = useStyles();

    if (HWLocalStorage.load ("previousVouchersTab") !== null ) 
    HWLocalStorage.clear("previousVouchersTab");
if(HWLocalStorage.load ("previousVouchersRuleTab") !== null)
     HWLocalStorage.clear("previousVouchersRuleTab");
     if (HWLocalStorage.load ("previousCardTab") !== null ) 
     HWLocalStorage.clear("previousCardTab");
     if(HWLocalStorage.load("previousComSettingsTab")!== null){
        HWLocalStorage.clear("previousComSettingsTab")
    }
    if(HWLocalStorage.load("previousRolesSettingsTab")!== null){
        HWLocalStorage.clear("previousRolesSettingsTab")
    }

    if(HWLocalStorage.load("previousSettingsTab")!== null){
        HWLocalStorage.clear("previousSettingsTab")
    }
   
    if(HWLocalStorage.load("previousActionsTab")!== null){
        HWLocalStorage.clear("previousActionsTab")
    }

    if(HWLocalStorage.load("previousMerchantDetailsTab")!==null)
    {
        HWLocalStorage.clear("previousMerchantDetailsTab");
    }

    if(HWLocalStorage.load("previousMerchantTab")!==null)
    {
        HWLocalStorage.clear("previousMerchantTab");
    }
    if(HWLocalStorage.load("previousPointsRuleTab")!== null)
    {
        HWLocalStorage.clear("previousPointsRuleTab");
    }
    if(HWLocalStorage.load("previousPointsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsTab");
    }
    if(HWLocalStorage.load("previousActionsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsTab");
    }
    if(HWLocalStorage.load("previousPointsActionsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsActionsTab");
    }

    if (! HWLocalStorage.load ("previousMemberTab") ) HWLocalStorage.save (0, "previousMemberTab");
      
    const [ memberId, setMemberId ] = useState (props && props.location && props.location.params && props.location.params.state && props.location.params.state.id);
    const [selectedTab, setSelectedTab] = useState (JSON.parse(HWLocalStorage.load ("previousMemberTab")) || 0);
    
    const handleChange = (event, value) => {
        console.log("Value", value);
        setSelectedTab(value);
    };

    return (
        <Fragment>
            <Tabs className={classes.root} value={selectedTab} onChange={handleChange} variant = "scrollable"  >
                <Tab label="Summary"  />
                <Tab label="Manage Membership" />
                <Tab label="Transaction History" />
                <Tab label="Rewards" />
            </Tabs>

            <div style={{ padding: "30px 0px 30px 0px" }}>
                    { selectedTab === 0 && <MemberCreate {...props} /> }
                    { selectedTab === 1 && <ManageMembership {...props} />}
                    { selectedTab === 2 && <MemberTransactions {...props} /> }
                    { selectedTab === 3 && <MemberRewards {...props} /> }
            </div>
        </Fragment>
    );
}