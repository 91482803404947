import HWLocalStorage from './HWLocalStorage';
import jwt from 'jsonwebtoken';
import authService from './ApiService';
import LoginService from 'views/LoginService/LoginService';

class HWUser {

  static userInfoStorageKey = () => { return "ops-user"; };

  static user = () => {
      let cachedUser = HWLocalStorage.load(this.userInfoStorageKey());
      if (!cachedUser) { return null; }
      return cachedUser;
  }

  static resetUser = () => {
      HWLocalStorage.clearWithPrefix(this.userInfoStorageKey())
  }

  static updateUserDetails(userDetails) {
      HWLocalStorage.save(userDetails, this.userInfoStorageKey());
  }

  static isLoggedIn =  () => {
    LoginService.permissions();

    var cachedToken =  authService.getCachedRefreshToken ();
    console.log("Decoded Token", cachedToken, jwt.decode (cachedToken), window.location, window.location.href);
    if (cachedToken === null) { 
        return false; 
    }

    var decodedTokenObject = jwt.decode (cachedToken);
    var currentDate = new Date ();
    var tokenExpiryDate = new Date (decodedTokenObject.exp * 1000);

    if (currentDate > tokenExpiryDate) return false;
    return true;
  }

  static userName = () => {
      let userData = this.user(); 
      return userData ? userData.userName: null;
  }
};

export default HWUser;