import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import HWMessage from 'HWLibrary/HWMessage';
import React, { useCallback, useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { memberTransactionApiService } from 'views/Members/APIService';
import { merchantTransactionApiService } from 'views/Merchant/APIService';
import TransactionDetails from './TransactionDetail';

export default function TransactionDescription (props) {

    const [ transactionId, setTransactionId ] = useState(props && props.transactionId);
    const [ memberId, setMemberId ] = useState (props && props.memberId);
    const [ merchantId, setMerchantId ] = useState (props && props.merchantId);

    const [ creator, setCreator ] = useState (props && props.creator);

    const [ transaction, setTransaction ] = useState (null);
    const [ points, setPoints ] = useState (null);
    const [ loading, setLoading ] = useState (false);
    const setOpen = props && props.setOpen;

    const fetchMemberTransactions = useCallback((transactionId, memberId) => {
        if (transactionId) {
            setLoading (true);
            memberTransactionApiService.fetchSpecificMemberTransaction({memberId, transactionId})
            .then (response => {
                if (! response || !response.data) { return HWMessage.show("Transaction cannot be fetched yet","error"); }
                setTransaction (response.data.transaction);
                setPoints (response.data.points);
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => { setLoading (false); });
        }
    }, [  ]);

    const fetchMerchantTransactions = useCallback ((transactionId, merchantId) => {
        if (transactionId) {
            setLoading (true);
            merchantTransactionApiService.fetchSpecificMerchantTransaction({merchantId, transactionId})
            .then (response => {
                if (! response || !response.data) { return HWMessage.show("Transaction cannot be fetched yet","error"); }
                setTransaction (response.data.transaction);
                setPoints (response.data.points);
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => { setLoading (false); });
        }
    }, [ ]);


    useEffect (() => {
        switch (creator) {
            case 'member' : fetchMemberTransactions (transactionId, memberId); break;
            case 'merchant' : fetchMerchantTransactions (transactionId, merchantId); break;
            default : console.log("Error while fetching response");        
        }
    }, []);
    

    const handleClose = () => { setOpen(false); }
    
    return (
        <Dialog open = { props.open } onClose = { handleClose } style = {{ width : '800px', left : 280 }} >
            { loading && <center> <ClipLoader size = {55} /> </center> }
            { !loading && 
                <>
                    <DialogTitle> <center>  Transaction Details </center> </DialogTitle>
                    <DialogContent>
                        <TransactionDetails transaction = { transaction } points = { points } creator = { creator } from={"Transaction"} />
                    </DialogContent>
                    <DialogActions>
                        <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = {handleClose}> Close </Button>
                    </DialogActions>
                </>
            }
            
        </Dialog>
    );
}; 