import { Button,  IconButton, makeStyles, Popover, setRef } from '@material-ui/core';
import { DataGrid , GridToolbar} from '@material-ui/data-grid';
import { MoreVert, People } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState, useEffect, Fragment } from 'react';
import SearchBar from 'views/Merchant/SearchBar';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import DescriptionPopover from 'views/DescriptionPopup/DescriptionPopup';
import voucherConfigApiService from './APIService';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import BlockUi from 'react-block-ui';
import HWMessage from 'HWLibrary/HWMessage';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import pointsRequestsApiService from './APIService';
import { green, red } from '@material-ui/core/colors';

import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import voucherRequestApiService from 'views/Actions/ApiService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import { CustomToolbar } from 'views/Members/Member';

 const useStyle = makeStyles (theme => ({
     ...styles,
     rewardButton : { background : '#138DE8', color : 'floralwhite', textTransform : 'initial', fontSize : 24, height : 50, width : 300 },
     bottomGrid : { marginTop : 10 },
     actionIcon : { color : '#138DE8' },
     editButton : { background : '#138DE8', color : 'floralwhite' },
     deleteButton : { background : '#FF9017', color : 'floralwhite' }
 }));

 function ActionPopover (props) {
    return ( 

                <div>
                <Popover
                    open={ props.open }
                    anchorEl={ props.anchorEl}
                    onClose={  (event) =>  props.handleClose (false)  }
                >
                    <div >
                        <div style = {{ margin : '20px' }} > 
                            <Button variant='contained' style = {{ textTransform:'none' ,backgroundColor : '#138DE8', color : 'floralwhite', right : '5px' }} onClick = {event => props.handleEdit() }  > Edit </Button> 
                            <Button variant='contained' style = {{ textTransform:'none',backgroundColor : '#FF9017', color : 'floralwhite' }} onClick = { event => props.handleDelete() } >  Delete </Button> 
                        </div> 
                    </div>
                </Popover>
            </div>
    
    );
};


export default function VoucherManagement (props) {


    var vouchers=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    vouchers= [...obj["Vouchers"]];
    HWLocalStorage.save (0, "previousVouchersTab");


    const [ deleteItemState , setDeleteItemState ] = useState('');
    const [ ruleOpen, setRuleOpen ] = useState (false);
    const [ createMode, setCreateMode ] = useState (false);
    const [ requestsLoading, setRequestsLoading ] = useState (false);
     const [ refreshState, setRefreshState ] = useState (false);
 
    const [ rows, setRows ] = useState ([]);
     const [ page, setPage ] = useState (0);
     const [ pageSize, setPageSize ] = useState (100);
     const [ filterModel, setFilterModel ] = useState (null);
     const [ rowCount, setRowCount ] = useState (0);
     const [ openDirectAction, setOpenDirectAction ] = useState (false);
     const [ selectedRequest, setSelectedRequest ] = useState (null);
     const [ searchText, setSearchText ] = useState ('');
     const [creatorMode , setCreatorMode ] = useState(false);
     const [ anchorEl, setAnchorEl ] = useState (null);
     const [ actionOpen, setActionOpen ] = useState (false);
     const [loading, setLoading ] = useState(false);
     const [ deleteOpen, setDeleteOpen ] = useState (false);
     const [ deletingRule, setDeletingRule ] = useState (null);
     const [ sortModel, setSortModel ] = useState ({});
     const [ selectedRuleId, setSelectedRuleId ] = useState ({});
     const [ createOpen, setCreateOpen ] = useState (false);
 

     const classes = useStyle ();

     const getTextColor = approvalStatus => {
        if (approvalStatus && approvalStatus !== null) {  
            if (approvalStatus === 'Approved') return green[500];
            else if (approvalStatus === 'Pending') return '#FF9017';
            else if (approvalStatus === 'Rejected') return red[400];
        }
     };


     const setValue = (row) =>{
        setSelectedRuleId(row);;

     }
     const editRule = (event, row) => { setCreateMode (prev => false); 
        setValue(row);
        history.push ({ pathname : 'voucher-reward', params : { state : { createMode : false , id: row } } });

    };
        

    const deleteRule = useCallback (id => {
        if (id != null) {
            setLoading (true);
            voucherRequestApiService.deleteRule (id) 
            .then (response => {
                if (!response || !response.data) { return HWMessage.show ("Error while deleteing the rule" , "error"); }
                setRowCount (rowCount - 1);
                setRows (prev => { prev = prev.filter (i => i.id !== id.id); return [ ...prev ] });
                setRefreshState(prev=>!prev);
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => { setLoading (false) ;setDirectActionOpen(false) ;  } );
        }
    });

    const handleDelete = (id) => {deleteRule (id) }
  
     useEffect (() => {
        setLoading (true);
        var paginationBookmark = ((page) * pageSize);
        var fetchLimit = pageSize;
        voucherRequestApiService.fetch({ paginationBookmark, fetchLimit, filterModel, sortModel, searchText })
        .then (response => {
            if (! response || !response.data) {
                HWMessage.show ("Requests cannot be fetched at moment", "error");
                return console.log("Error while fetching the response");
            }
            HWMessage.show("Request Successful" , "success");
            setRowCount (response.data.totalHits);
            setRows (response.data.modelList);
            
        })
        .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        return console.log("Error while fetching requests", error); })
        .finally (() => { setLoading (false); var my_element = document.getElementById("top");
        if(my_element!== null || my_element!== undefined || my_element!== '')
        {
            // my_element.scrollIntoView({ }); 

        }    
    });
    }, [  page , refreshState , pageSize , searchText , filterModel , sortModel]);

    const columns = [
        { field : 'refNumber',          headerName : 'Reference Number',    filterable : false,  sortable : true, flex : 1 ,minWidth:100, renderCell : cellParams => { return <span style={{color: "#138DE8"}} onClick = { (event) =>{  setDeleteItemState(cellParams.row.id) ;   editRule(event,cellParams.row) } } >  {cellParams.row.refNumber} </span> }},
        { field : 'userCount',          headerName : 'User Count',          filterable : false,  sortable : true,minWidth:100, flex : 1 },
        { field : 'voucherPerUser',      headerName : 'Vouchers Requested Per User',     filterable : false,  sortable : true,minWidth:100, flex :1 },
        { field : 'raisedEmpName',     headerName : 'Requestor name',              filterable : true,  sortable : true,minWidth:100, flex : 1},
        { field : 'approvalStatus',     headerName : 'Status',              filterable : true,  sortable : true, flex : 1, minWidth:100,  renderCell : cellParams => <span style = {{ color : getTextColor (cellParams.row.approvalStatus), fontWeight : 600 }} > { cellParams.row.approvalStatus } </span> },
        { field : 'code',     headerName : 'Voucher Code',              filterable : true,  sortable : true,minWidth:100, flex : 1},
    
        { field : 'raisedTime', headerName : 'Raised Date', filterable: false ,minWidth:100,flex:1, renderCell : cellParams => {
            var date = cellParams.row.raisedTime;
            var dateString = date.split('T');
            var d = new Date (dateString).toLocaleDateString();

            return <span> {d} </span>
        } },
        { field : 'resolvedTime',   headerName : 'Resolved Date',   filterable : false,  sortable : false, flex : 1 ,minWidth:100, renderCell : cellParams => { return <span> {cellParams.row.resolvedTime!==undefined && new Date(cellParams.row.resolvedTime).toLocaleDateString()} </span> }},

     ];

     if(vouchers.includes("Action") === true){
        columns.push(       { field : 'Edit',             headerName : '',             filterable : false, sortable : false, flex : 0.5,minWidth:50,  renderCell : cellParams =>  cellParams.row.approvalStatus === 'Pending' && cellParams.row.requestType !== 'Returning' &&  <IconButton onClick = { (event) =>{  setDeleteItemState(cellParams.row.id) ;   editRule(event,cellParams.row) } } > <Edit style={{color: '#138DE8' }} className = { classes.actionIcon } /> </IconButton>    }
        )
        columns.push(       { field : 'Delete',             headerName : '',             filterable : false, sortable : false, flex : 0.5, minWidth:50, renderCell : cellParams => cellParams.row.approvalStatus === 'Pending' && cellParams.row.requestType !== 'Returning' && <IconButton onClick = { (event) =>{  setDeleteItemState(cellParams.row.id) ;  setSelectedRuleId(prev => { return cellParams.row}); setCreateOpen(true) } } > <Delete style={{color: '#FF9017' }} className = { classes.actionIcon } /> </IconButton>    }
        )
     };

   
    
    const history = useHistory ();
    const handleClicks = (event, clickType) => {
        if (clickType !== null)  {
            history.push ({ pathname : 'voucher-audience-selection', state : { currentRequest : { requestType : clickType , createMode : true } } });
        }
    };

  

    function CustomLoadingOverlay() {
        return (
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
      }
 
      const [ directActionOpen, setDirectActionOpen ] = useState (false);

      const useDataGridStyles = makeStyles(theme => ({
        root: {
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiButton-label' : { color : '#138DE8' }
        }
      }));
      const dataGridClasses = useDataGridStyles();
   
    
     return (
         <Fragment>
            <BlockUi blocking = { loading } message = { 'Please wait while we fetch.' } >
                 <Card id="top">
                     <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                         <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                         <h4 className = { classes.cardIconTitle } > 
                             Voucher Management
                         </h4> 
                         
                     </CardHeader>
                     <CardBody>  
                         <GridContainer style = {{ justifyContent : 'space-between' }} >
                            <GridItem>
                                        <SearchBar  
                                            handleSearch = { setSearchText }
                                            handleRefresh = { setRefreshState }
                                            refreshState = { refreshState }
                                        />
                            </GridItem>
                             <GridItem> 
                                { vouchers.includes("Action") === true &&<Button variant = "contained" className = { classes.rewardButton } onClick = { e => handleClicks (e, 'Accrue') } > Reward Vouchers </Button>
                                }
                             </GridItem>
                             </GridContainer>
                             <GridContainer>
                            <GridItem className = { classes.bottomGrid } xs={12} md = {12}>
                                     <DataGrid 
                                         className = { dataGridClasses.root }
                                         showToolbar
                                         page = { page }
                                         pageSize = { pageSize }
                                         rows = { rows || [] }
                                         rowCount = { rowCount }
                                         columns = { columns }
                                         paginationMode = 'server'
                                       
                                         onPageChange = { changeParams => setPage (changeParams) }
                                         onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams); } }

                                         filterMode = 'server'
                                         onFilterModelChange = { params => { if (params.items.length > 0) setFilterModel(params.items[0]); } }

                                         density = 'compact'
                                         autoHeight
                                         rowsPerPageOptions ={ [25, 50, 100 ]}
                                         loading = { requestsLoading }
                                         components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 

                                         disableExtendRowFullWidth = { false }
                                     />
                             </GridItem>
                         </GridContainer>
                     </CardBody>
                
                 </Card>
                 < ActionPopover
                    open = { directActionOpen } 
                    handleClose = { setDirectActionOpen }
                    anchorEl = { anchorEl }  
                    setAnchorEl = { setAnchorEl }   
                    handleEdit = {e => editRule(e) } 
                    handleDelete = { e => setCreateOpen(true) } 
                    
                    /> 
             </BlockUi>
             <ConfirmationDialog open = { createOpen } setOpen = { setCreateOpen }  dialogMessage = { 'Are you sure you want to delete this request' } dialogTitle = { 'Confirm Request Deletion' } confirmingAction = { e =>  handleDelete(selectedRuleId) } />

         </Fragment>
     );
 };




