import { AppBar, makeStyles, Tab, Tabs } from '@material-ui/core';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import React, { useCallback, useEffect, useState } from 'react';
import { Fragment } from 'react';
import memberApiService from '../APIService';
import voucherApiService from './ApiService';
import AvailableRewards from './AvailableRewards';
import Voucher from './Voucher';
import HWMessage from 'HWLibrary/HWMessage';
import BlockUi from 'react-block-ui';

const useStyles = makeStyles((theme) => ({
    root: {
      overflow: 'hidden',
      minHeight: 48,
      WebkitOverflowScrolling: 'touch', 
      display: 'flex',
      backgroundColor: "white",
      '& .MuiTabs-indicator' : { backgroundColor : '#FF9017' },
      "& .MuiButtonBase-root" : {textTransform: "none"},
      color: 'black',
      borderRadius: '6px'
    }
}));

export default function MemberRewards (props) {

    HWLocalStorage.save (3, "previousMemberTab");

    const classes = useStyles ();
    const [ memberId, setMemberId ] = useState (props && props.location && props.location.params && props.location.params.state && props.location.params.state.id || (JSON.parse(HWLocalStorage.load("memberStore"))));
    const [ currentTab, setCurrentTab ] = useState (0);
    const [ fetchedMember, setFetchedMember ] = useState (props.fetchedMember);
    const [ listLoading, setListLoading] = useState (false)
    const [ voucherList, setVoucherList] = useState (null)
    const [ availableVouchers, setAvailableVouchers] = useState ([])
    const [ redeemedVouchers, setRedeemedVouchers] = useState ([])
    const [ expiredVouchers, setExpiredVouchers] = useState ([])
    const [ coupons, setCoupons] = useState (null)
    const [ vouchers, setVouchers] = useState (null)
    const [ type, setType] = useState('Available')
    

    const changeTab = (event, idx) => {
        setCurrentTab (idx);
       
        if (props && props.location && props.location.params && props.location.params.state && props.location.params.state.id) {
            var value = props && props.location && props.location.params && props.location.params.state && props.location.params.state.id;
            HWLocalStorage.save(JSON.stringify(value), "memberStore");
        }
    } 

    const combineLists = () => {
        let combine = [];
            if (coupons && coupons.length > 0)
                for (var i = 0; i < coupons.length; i++) { 
                        combine.push(coupons[i]); 
                }
            if (vouchers && vouchers.length > 0) 
                for (var j = 0; j < vouchers.length; j++) { 
                    combine.push(vouchers[j]); 
                }
        return combine;
    };

    const getVouchers = (tab) => {
        let combine = [];
        let statuses = ["Available","Redeemed","Expired"]
        if (coupons && coupons.length > 0)
            for (var i = 0; i < coupons.length; i++) { 
                if (coupons[i].status === statuses[tab])
                    combine.push(coupons[i]); 
            }
        if (vouchers && vouchers.length > 0) 
            for (var j = 0; j < vouchers.length; j++) {
                if (vouchers[j].status === statuses[tab])
                combine.push(vouchers[j]); 
            }
        return combine;
    }

    const fetchVouchers=()=>{
        setListLoading (true);
        voucherApiService.fetchCoupons({memberId})
            .then (response => {  
                if (!response || !response.data) { 
                    HWMessage.show("Response connot be fetched at the moment" , "error");
                }
                setCoupons (response.data.eServiceCoupons);
                HWMessage.show("Request Successful" , "success");
                
                
            })
            .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            })
            .finally (() => {  
                voucherApiService.fetch ({ memberId })
                    .then (response => {
                        if (!response || !response.data) { HWMessage.show("Cannot fetch data at the moment" , "error") }                
                            setVouchers (response.data.voucherList);
                            HWMessage.show("Request Successful" , "success");
                    })
                    .catch(error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
                    })
                    .finally(()=>{
                        setVoucherList(combineLists());
                        setListLoading(false); 
                    })
            });
    }

    useEffect(()=>{
        fetchVouchers()
    },memberId)

    return (
        <Fragment> 
            <BlockUi blocking = { listLoading } message = "Loading Voucher List"  >
                <Tabs className = { classes.root } varaint = "scrollable" onChange = {changeTab } value = { currentTab } >
                    <Tab label = "Available"  /> 
                    <Tab label = "Redeemed" />
                    <Tab label = "Expired" />
                </Tabs> 

            {voucherList && <div style={{ padding: "30px 0px 30px 0px" }}>  
                {currentTab == 0 && <AvailableRewards memberId = {memberId} voucherList = {getVouchers(currentTab)} />}
                {currentTab == 1 && <AvailableRewards memberId = {memberId} voucherList = {getVouchers(currentTab)} />}
                {currentTab == 2 && <AvailableRewards memberId = {memberId} voucherList = {getVouchers(currentTab)} />}
            </div>}
            </BlockUi>
        </Fragment>
    );
}