import { Box, Button, Card, CardActions, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import CardHeader from 'components/Card/CardHeader';
import GridItem from 'components/Grid/GridItem';
import React, { useCallback, useEffect, useState } from 'react';
import { Fragment } from 'react';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import GridContainer from 'components/Grid/GridContainer';
import CardBody from 'components/Card/CardBody';
import Alert from '@material-ui/lab/Alert';
import CardIcon from 'components/Card/CardIcon';

import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import voucherRequestApiService from './../Actions/ApiService';
import HWMessage from 'HWLibrary/HWMessage';
import { HWInput } from 'HWLibrary/HWInput';
import BlockUi from 'react-block-ui';
import voucherConfigApiService from './APIService';
import { FadeLoader } from 'react-spinners';
import memberApiService from 'views/Members/APIService';
import { useHistory } from 'react-router-dom';
import CardFooter from 'components/Card/CardFooter';
import Edit from '@material-ui/icons/Edit';
import pointsApiService from 'views/Points/APIService';
import { DataGrid } from '@material-ui/data-grid';
import { convertToObject } from 'typescript';
import userSegmentApiService from 'views/UserSegment/APIService';
import SearchBar from 'views/Merchant/SearchBar';

const useStylesHead = makeStyles (styles);


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));


function StatusDialog (props) {
    const history = useHistory();


    return (
        <React.Fragment>
       
        {
            ! props.raiseLoading &&
            <Dialog open = {props.statusDialogOpen}  >
                <DialogTitle> Raising Request </DialogTitle>
                {
                props.createStatus &&
                <DialogContent>
                    { props.createStatus === true ? 'Request Raised for Vouchers' : 'Request being raised'}
                </DialogContent>
                }
                <DialogActions>
                    <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { () => { props.resetRequest () ; props.setStatusDialogOpen (false); props.setCreateStatus (false);  } } > Reward More </Button>
                    <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} onClick = { () => { props.setStatusDialogOpen(false); history.push ({ pathname : 'vouchers' }) }} > Home </Button>
                </DialogActions>
        </Dialog>
        }
        </React.Fragment>
    );
}


export function SearchUserDialog (props) {

        const classes = useStyles ();

    const [ selectedList, setSelectedList ] = useState ([]);
    const [ listLoading, setListLoading ] = useState (false);
    const [ audienceList , setAudienceList] = useState(props && props.audienceList)
    const [ rows, setRows ] = useState ([]);
    const [ page, setPage ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ rowCount, setRowCount ] = useState (0);
    const [ searchText, setSearchText ] = useState ('');
    const [refreshState , setRefreshState] = useState(false);
    const [ firstSortId, setFirstSortId] = useState();
    const [ lastSortId, setLastSortId ] = useState();
    const [ pageDelta, setPageDelta ] = useState(0);
   
    useEffect (() => {
        var voucherRequestId = null;
        var audienceType     = null;
        var segmentId        = null;
        var userGroupIds     = null; 
        if(props && props.currentRequest){
            voucherRequestId = props && props.currentRequest && props.currentRequest.id;
            audienceType     = props && props.currentRequest && props.currentRequest.voucherRequestType;
            segmentId        = props && props.currentRequest && props.currentRequest.memberSegmentId;
            userGroupIds     = props && props.currentRequest && props.currentRequest.groupIds;}
        else{
            voucherRequestId = props.audienceList.history.location.params.state.id.id;
        }
            var paginationBookmark = ((page) * pageSize);
            var fetchLimit = pageSize;
            userSegmentApiService.fetchAwardedVouchers({voucherRequestId,paginationBookmark,fetchLimit,audienceType,segmentId, userGroupIds, firstSortId, lastSortId, pageDelta})
            .then (response => {
                    setRowCount (response.data.totalMembers); 
                    setFirstSortId (response.data.firstSortId);
                    setLastSortId (response.data.lastSortId);
                    setRows(response.data.members);
            })
            .catch (error => { 
                setRowCount (props.currentRequest && props.currentRequest.groupIds && props.currentRequest.groupIds.length); 
                setRows(props.currentRequest && props.currentRequest.groupIds && props.currentRequest.groupIds|| []) 
             })
            .finally(() => console.log("sadasd"));
        
    }, [ page , pageSize]);

        var columns = [
            { field : 'name', headerName : 'Name', filterable : false, sortable : false, width : 200 },
            { field : 'membershipNumber', headerName : 'Membership Number', filterable : false, sortable : false, width : 200 },
             
        ];
    
    

    const handleClose = event => { props.setOpen(prev => !prev); };


    const history = useHistory();
    
    return (
    <Dialog open = { props.open } onClose = { handleClose }  >
        <DialogTitle  > Selected Users</DialogTitle>
        
        <DialogContent>  
            <GridContainer>
                <GridItem xs = {12} md = {12} sm = {12} >  
                <DataGrid className={classes.root}
                    loading = { listLoading }
                    page = { page }
                    pageSize = { pageSize }
                    rows = { rows }
                    rowCount = { rowCount }
                    columns = { columns }
                    pagination
                    paginationMode = 'server'
                    onPageChange = { changeParams => {setPage (changeParams); setPageDelta (changeParams-page)} }
                    onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams); } }
                    filterMode = 'server'
                    density = 'compact'
                    autoHeight
                    rowsPerPageOptions ={ [25, 50, 100 ]}
                   
                
                />
                </GridItem>
            </GridContainer>
        </DialogContent>
        <DialogActions> 
        </DialogActions>
    </Dialog>
    );
};

export default function VoucherReward (props) {

    const extractLocationParams = () => {
        const locationParams = props && props.location && props.location.state;
        if (locationParams != null) { return locationParams; } 
        else { return {}; }
    };



    const locationParams  = extractLocationParams() ;

    const classes = useStyles ();
    const headClasses = useStylesHead();

    const handleChange = (event) => handleTextFieldChanges (event);

    const [ memberId, setMemberId ] = useState (props.memberId);
    const [edit, setEdit] = useState(false);

    const [ reason, setReason ] = useState ('');
    const [ voucherCode, setVoucherCode ] = useState ('');

    const [ detailedReason, setDetailedReson ] = useState ('');
    const [ amount, setAmount ] = useState (0);

    const [ raiseLoading, setRaiseLoading ] = useState (false);
    const [ createStatus, setCreateStatus ] = useState (false);
    const [ statusDialogOpen, setStatusDialogOpen ] = useState (false);

    const reasons = [ 'None', 'Purchase of Goods', 'Purchase of Bike', 'Campaign Reward', 'Campaign Provision', 'Seasonal Reward', 'Others' ];
    const [voucherCodes, setVoucherCodes] = useState ([  ]);
    const [ loading, setLoading ] = useState (false);
    const [ request, setRequest ] = useState (locationParams && locationParams.request);
    const [open ,setOpen] = useState(false);

    const [ fetchedMember, setFetchedMember ] = useState ({});
    const [allCount , setAllCount] = useState(0);
    const resetRequest = () => setRequest (prev => { prev.voucherPerUser = 0; prev.code = 'None'; prev.reason = 'None'; return { ...prev };  });

    const [ fetchLoading, setFetchLoading ] = useState(false);
    const [ listOpen, setListOpen ] = useState (false);
    const [ audienceList, setAudienceList ] = useState ([]);
    const [check , setCheck ]  = useState(false);
    const [ currentRequest, setCurrentRequest ] = useState (locationParams && locationParams.currentRequest !== null ? locationParams.currentRequest : {});


    var mode;
    useEffect (() => {        
     mode =props && props.location && props.location.params && props.location.params.state && props.location.params.state.createMode ;
    var id = props && props.location && props.location.params && props.location.params.state && props.location.params.state.id  ;
    if(mode === false  ){
    voucherRequestApiService.fetchRequestDetails ( id )
    .then (response => { 
        if (! response || ! response.data) {
            HWMessage.show("This member details cannot be fetched","error");
            return console.log("Error while fetching member");
        }
        HWMessage.show("Request Successful" , "success");
        setCurrentRequest(response.data);

        setRequest(response.data);
     })
     .catch (error => {
        HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");

        return console.log("Error while fetching member", error);
     });


}
        
}, []);


    // useEffect (() => {
    //     if (locationParams &&  locationParams.creatorMode  && locationParams.creatorMode === false &&  locationParams.id) {
    //         setFetchLoading (true);
    //         voucherRequestApiService.fetchRequestDetails ({ id : locationParams.id })
    //         .then (response => { 
    //             if (!response || !response.data) { 
    //                 HWMessage.show("Response cannot be fetched at moment"); 
    //                 return console.log("Response cannot be fetched at moment"); 
    //             }   
    //           // setRequest (response.data);
    //         })
    //         .catch (error => { return console.log("Error while fetching response", error); })
    //         .finally (() => { setFetchLoading (false); });
    //     } 
    // }, [ ]);



    const history = useHistory ();

    useEffect (() => {
        if(request && request.voucherRequestType === 'All')
           { setLoading (true);
            memberApiService.fetchActiveMembers({})
            .then (response => {
                if (!response || !response.data){
                    HWMessage.show("Request not successful" , "error");
                    return console.log("No relevant data can be fetched");
                }
                //setCurrentRequest(response.data);
                HWMessage.show("Request Successful" , "success");
                setAllCount (response.data.membersCount); 
                
            })
            .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally(() => setLoading (false));}
      }, []);    
    
 
    useEffect (() => {
        setLoading (true);
        voucherConfigApiService.fetchCodes()

        .then (response => {
            if (! response || ! response.data) {
                HWMessage.show ("Unable to fetch voucher codes at moment", "error");
                return console.log("Error while fetching voucher codes");
            }
            //setCurrentRequest(response.data);
            var codes = response.data.voucherConfigModelList.map(i => i.code);
            codes.unshift ('None');
            setVoucherCodes (codes);
        })
        .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() => setLoading (false));
    }, []);


    const handleTextFieldChanges = (event) => {
        switch (event.target.name) {
            case 'code' : setRequest (previous => ({ ...previous, 'code' : event.target.value })); break;
            case 'voucherPerUser' :  setRequest (previous => ({ ...previous, 'voucherPerUser' : +event.target.value }));   break;
            case 'reason' : setRequest (previous => ({ ...previous, 'reason' : event.target.value })); break;
            default : HWMessage.show ("Illgeal field", "error"); break;
        }
    }

   

    // const validateRequest = (request) => {
    //     console.log("Request ", request);
    //     var okRequest = true;
    //     if (!request) { HWMessage.show ("Request is empty, it must contain valid parameters"); okRequest = false; }
    //     else if (!request.code || request.code === null ) { HWMessage.show("Request must contain valid voucher code"); okRequest = false; }
    //     else if (!request.voucherPerUser || request.voucherPerUser === null || request.voucherPerUser <= 0) { HWMessage.show ('Vouchers Per User must be specified'); okRequest = false; }
    //     else if (!request.reason || request.reason === null) { HWMessage.show("Select at least one of the reason for the request"); okRequest = false; }

    //     return okRequest;
    // };

  const validateRequest = (request) => {
        if(request && request.voucherPerUser && request.voucherPerUser > 0 && request.code && request.reason)
           return true;
        else
           return false;
           
    };

    const checkDisabled = () =>{
        if(request && request.voucherPerUser && request.voucherPerUser >= 0 && request.code && request.reason)
                return false;
         else
                return true;

    }
  
    const createVoucherRequest = useCallback((request) => {
        var isValidRequest = validateRequest (request);

        if (request != null && isValidRequest) {
            setRaiseLoading (true);
            voucherRequestApiService.createVoucherRequest ({...request, memberId : memberId})
            .then (response => {
                if (! response || !response.data) {
                    HWMessage.show ("Voucher Creation failed","error");
                    return console.log("Error while creating voucher request");
                }
                setCreateStatus (true);
                HWMessage.show("Request Successful" , "success");            })
            .catch (error => {
                HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");

                return console.log("Error while raising request ", error);
            })
            .finally (() => setRaiseLoading (false));
        }
    }, []);


    const updateVoucherRequest = useCallback((request) => {
        var isValidRequest = validateRequest (request);
        var id = props && props.location && props.location.params && props.location.params.state.id  ;

        if (request != null && isValidRequest) {
            setRaiseLoading (true);
            voucherRequestApiService.updateRequestVoucher (request)
            .then (response => {
                if (! response || !response.data) {
                    HWMessage.show ("Voucher Creation failed","error");
                    return console.log("Error while creating voucher request");
                }
                setCreateStatus (true);
  HWMessage.show("Request Successful" , "success");            })
            .catch (error => {
                HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");

                return console.log("Error while raising request ", error);
            })
            .finally (() => setRaiseLoading (false));
        }
    }, []);


    return (
        <Fragment>
            <BlockUi blocking = { loading || raiseLoading } message = { loading === true ? "Loading Categories. Please Wait..." : raiseLoading === true ? "Raising Request for Voucher" : "" } tag = "div"  >

                <Card className='card-header-body'>
                    <CardHeader icon>
                      <CardIcon color="primary" style = {{ marginTop : 0, background : '#138DE8' }} > <TransferWithinAStationIcon /></CardIcon>

                    </CardHeader>

                    <CardBody className='card-header-body'>
                    {props && props.location && props.location.params && props.location.params.state &&  props.location.params.state.createMode === false &&  props.location.params.state.id.approvalStatus !== "Approved" &&  props.location.params.state.id.approvalStatus !== "Rejected" && <Edit onClick={e=>setEdit(!edit)} style={{ color: "#138DE8" }}/> }  

                    </CardBody>
                </Card>

                <Card>
                    <CardHeader color = "primary" icon >

                        <GridContainer>
                            <GridItem xs = {12} >
                                <GridContainer>
                                    <GridItem xs = {12} >
<div style={{display:"flex" }}>

{                               props && props.location && props.location && props.location.params && props.location.params.state && props.location.params.state.createMode === false  &&   <h4 className = { headClasses.cardIconTitle } > Award Vouchers  &nbsp; &nbsp; Number of Members :  {request && request.requestType === 'All' ? request.userCount :request &&  request.requestType === 'Group' ? request && request.userCount :request && request.userCount }        </h4>  
}                                        { props && props.location && props.location && props.location.state && props.location.state.createMode === true   &&  <h4 className = { headClasses.cardIconTitle } > Award Vouchers  &nbsp; &nbsp; Number of Members :  {request && request.voucherRequestType === 'All' ? allCount :request &&  request.voucherRequestType === 'Group' ? request && request.groupIds && request.groupIds.length :request && request.memberCount }       </h4> 
} 
<IconButton  onClick = { (e) => {setListOpen(!listOpen) ; setCheck(!check); } } >  <KeyboardArrowDownIcon /> </IconButton>
</div>

                                    </GridItem>
                                    
                                </GridContainer>
                            </GridItem>
                          
                        </GridContainer>
                    </CardHeader>
                    <Divider />

                    <CardBody>
                    <GridContainer >
                            <GridItem style =  {{ display : 'flex' , justifyContent : 'center' }} xs = {12} >
                    {request && request.audienceSelection && request.audienceSelection === 'all' &&  <center> <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} > {allCount} users Selected </Button>  </center>}       
                    {request && request.audienceSelection && request.audienceSelection === 'usergroup' &&  <center> <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} >{ request && request.groupIds && request.groupIds.length} users Selected </Button>  </center>}       
                    {request && request.audienceSelection && request.audienceSelection === 'Group' &&  <center> <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} >{ request && request.groupIds.length } users Selected </Button>  </center>}       

                    </GridItem>
                    </GridContainer>
                        <GridContainer >
                            <GridItem style =  {{ display : 'flex' , justifyContent : 'center' }} xs = {12} >
                                <center  >
                                    <HWInput
                                        type = { 'number' }
                                        label = { 'Vouchers to be rewarded' }
                                        placeholder = { 'Enter Number of Vouchers' }
                                        name = {'voucherPerUser'}
                                        //value = {(amount >= 0) ? amount : ''}
                                        value = { (request && request.voucherPerUser) }
                                       // onChange = { (event) => handleTextFieldChanges (event) }
                                        onBlur = { event => handleTextFieldChanges(event) }
                                        //onChange = { event => handleTextFieldChanges(event) }
                                        style = {{ width : '400px' }}
                                        disabled = { props && props.location && props.location.params && props.location.params.state &&  props.location.params.state.createMode === false ? !edit : false }
                                        required={true}
                                        hasError={(request &&   request.voucherPerUser < 1) }
                                        helperText = { (request  &&  request.voucherPerUser< 1) ? "Invalid Voucher Per User" : ""  }
                                    />
                                </center>
                            </GridItem>
                            {request && (request.approvalStatus==="Approved"  || request.approvalStatus==="Rejected")  && <GridItem style =  {{ display : 'flex' , justifyContent : 'center' }} xs = {12} >
                                <center  >
                                    <HWInput
                                        type = { 'raisedEmpName' }
                                        label = { 'Raised By' }
                                        name = {'Raised By'}
                                        value = { (request && request.raisedEmpName) }
                                        style = {{ width : '400px' }}
                                        disabled = { true }
                                        required={true}
                                    />
                                </center>
                            </GridItem>}
                          

                            {request &&  <GridItem style =  {{ display : 'flex' , justifyContent : 'center' }} xs = {12} >
                                <center  >
                                    <HWInput
                                        type = { 'raisedDate' }
                                        label = { 'Raised date' }
                                        name = {'Raised Date'}
                                        value = { (request && request.raisedTime ? new Date(request.raisedTime).toLocaleDateString() : "") }
                                        style = {{ width : '400px' }}
                                        disabled = { true }
                                        required={true}
                                    />
                                </center>
                            </GridItem>}


                            {request && (request.approvalStatus==="Approved" || request.approvalStatus==="Rejected") && <GridItem style =  {{ display : 'flex' , justifyContent : 'center' }} xs = {12} >
                                <center  >
                                    <HWInput
                                        type = { 'refNumber' }
                                        label = { 'Reference Number' }
                                        name = {'Reference Number'}
                                        value = { (request && request.refNumber) }
                                        style = {{ width : '400px' }}
                                        disabled = { true }
                                        required={true}
                                    />
                                </center>
                            </GridItem>}
                            {request && (request.approvalStatus==="Approved" || request.approvalStatus==="Rejected") && <GridItem style =  {{ display : 'flex' , justifyContent : 'center' }} xs = {12} >
                                <center  >
                                    <HWInput
                                        type = { 'ResolvedTime' }
                                        label = {request && request.approvalStatus==="Approved" ? "Approved Date" : "Rejected Date" }
                                        name = {'ResolvedTime'}
                                        value = { (request && new Date(request.resolvedTime).toLocaleDateString()) }
                                        style = {{ width : '400px' }}
                                        disabled = { true }
                                        required={true}
                                    />
                                </center>
                            </GridItem>}

                            {request && (request.approvalStatus==="Approved" || request.approvalStatus==="Rejected") && <GridItem style =  {{ display : 'flex' , justifyContent : 'center' }} xs = {12} >
                                <center  >
                                    <HWInput
                                        type = { 'resolverEmpName' }
                                        label = {request && request.approvalStatus==="Approved" ? "Approved By" : "Rejected By"}
                                        name = {'resolverEmpName'}
                                        value = { (request && request.resolverEmpName) }
                                        style = {{ width : '400px' }}
                                        disabled = { true }
                                        required={true}
                                    />
                                </center>
                            </GridItem>}
                            {
                            voucherCodes && voucherCodes.length > 0 &&
                            <GridItem xs = {12} >
                                <center>
                                    <FormControl className = {classes.formControl} style = {{ width : '400px' }} >
                                        <InputLabel  > Voucher Codes </InputLabel>
                                        <Select
                                            name = {'code'}
                                            value = { request && request.code ? request.code : 'None' }
                                            onChange = { (event) => handleTextFieldChanges (event) }
                                            onBlur = { event => handleTextFieldChanges (event) }
                                            disabled = { props && props.location && props.location.params && props.location.params.state &&  props.location.params.state.createMode === false ? !edit : false }
                                            required={true}
                                        >
                                            { voucherCodes.map (code => (<MenuItem value = {code} > { code } </MenuItem>) )  }
                                        </Select>
                                    </FormControl>
                                </center>
                            </GridItem>
                            }

                            <GridItem xs = {12} >
                                <center>
                                    <FormControl className = {classes.formControl} style = {{ width : '400px' }} >
                                        <InputLabel  > { request && request.approvalStatus === "Approved" ? "Approved Reason"  : request && request.approvalStatus==="Rejected" ? "Rejected Reason" : " Reason" }  </InputLabel>
                                        <Select
                                            name = {'reason'}
                                            value = { request && request.reason ? request.reason : 'None' }
                                            onBlur = { event => handleTextFieldChanges (event) }
                                            onChange = { event => handleTextFieldChanges (event) }
                                            disabled = {props && props.location && props.location.params && props.location.params.state &&  props.location.params.state.createMode === false ? !edit : false }

                                        >
                                            { reasons.map (reason => (<MenuItem value = {reason} > { reason } </MenuItem>) )  }
                                        </Select>
                                    </FormControl>
                                </center>
                            </GridItem>
                            <GridItem xs = {12} style = {{ marginLeft : '100px', marginRight : '100px' }} >
                                <TextField
                                    placeholder = { 'Detailed reasoning for rewarding the voucher' }
                                    multiline = { true }
                                    rows = { 10 }
                                    rowsMax = { 20 }
                                    fullWidth
                                    variant = "outlined"
                                    label = { 'Detailed reasoning for rewarding the voucher' }
                                    disabled = { props && props.location && props.location.params && props.location.params.state &&  props.location.params.state.createMode === false? !edit : false }
                                    value={ request && request.reason ? request.reason : 'None' }
                                />
                            </GridItem>

                            <GridItem xs = {12} style = {{ marginTop : '15px' }} >
                                {request && request.voucherPerUser && <Alert  style = {{ marginLeft : '100px', marginRight : '100px' }} fullWidth severity="info">
                                  Total Vouchers to be rewarded : {locationParams && locationParams.createMode === true ? 
                                  ((request && request.voucherRequestType && request.voucherRequestType === 'All') ?( isNaN(request.voucherPerUser) ? 0 : (request.voucherPerUser * allCount ) ) :  (request && request.voucherRequestType && request.voucherRequestType === 'Group')  ? (isNaN(request.voucherPerUser * request.groupIds.length) ? 0 :request.voucherPerUser * request.groupIds.length )  : (isNaN(request.voucherPerUser * request.memberCount) ? 0 : request.voucherPerUser * request.memberCount)) :  (request && request.voucherPerUser && request.userCount) ? (request.voucherPerUser * request.userCount) :  0
                                 }
                                

                                    </Alert>}

                                    {(!request || !request.voucherPerUser) && <Alert  style = {{ marginLeft : '100px', marginRight : '100px' }} fullWidth severity="info">
                                     Total Vouchers to be rewarded : 0 
                                    </Alert>
                                    }

                                    
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    <CardFooter style = {{ justifyContent : 'center' }} >

                       { props && props.location && props.location && props.location.state &&  props.location.state.createMode === true  && <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} raised disabled={checkDisabled()} onClick = { () => {
                            var validRequest = validateRequest (request);
                            if (validRequest && locationParams && locationParams.createMode && locationParams.createMode === true) {
                                createVoucherRequest(request);
                                setStatusDialogOpen(true); }
                            else{
                                updateVoucherRequest(request);
                                setStatusDialogOpen(true); 
                            }
                            }
                            }
                           
                            style = {{ textTransform : 'none', color : 'floralwhite', background : '#138DE8', margin: 2.5 , width : 200, height : 50, fontSize : 20 }}
                        >

                           {props && props.location && props.location && props.location.state &&  props.location.state.createMode === true  ?  "Reward" :  "Update"}
                        </Button>}


                        { props && props.location && props.location.params && props.location.params.state &&  props.location.params.state.createMode === false && edit === true && <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} raised disabled={checkDisabled()} onClick = { () => {
                            var validRequest = validateRequest (request);
                            if (validRequest && locationParams && locationParams.createMode && locationParams.createMode === true) {
                                createVoucherRequest(request);
                                setStatusDialogOpen(true); }
                            else{
                                updateVoucherRequest(request);
                                setStatusDialogOpen(true); 
                            }
                            }
                            }
                           
                            variant = "contained"
                            style = {{ textTransform : 'none', color : 'floralwhite', background : '#138DE8', margin: 2.5 , width : 200, height : 50, fontSize : 20 }}
                        >

                           {props && props.location && props.location && props.location.state &&  props.location.state.createMode === true   ?  "Reward" :  "Update"}
                        </Button>}
                       
                        <Button raised variant = "contained" style = {{ textTransform : 'none', color : 'floralwhite', background : '#FF9017', margin: 2.5 , width : 200, height : 50, fontSize : 20 }} onClick = { () => history.push({ pathname : "vouchers"}) } > Cancel </Button>
                    </CardFooter>
                </Card>
            </BlockUi>

            <StatusDialog
                raiseLoading = {raiseLoading}
                statusDialogOpen = { statusDialogOpen }
                setStatusDialogOpen = {setStatusDialogOpen}
                setDialogOpen = { props.setDialogOpen }
                createStatus = { createStatus }
                setCreateStatus = { setCreateStatus }
                resetRequest = { resetRequest }
            />

            <SearchUserDialog 
                            open = { check } 
                            setOpen = { setCheck }
                            locationParams = {locationParams}
                            audienceList = {props}
                            currentRequest = {request}
                    />
        </Fragment>
    );
};