import axios from 'axios';
import appConfig from '../../HWLibrary/HWConfig';
import authService from 'HWLibrary/ApiService';
import HWMessage from 'HWLibrary/HWMessage';


// There is caching enabled in this module, therefore 
// the service caches the instances for saving time to initialise one.
class MerchantAPIService {

    constructor () {
        if (!MerchantAPIService.instance) 
            MerchantAPIService.instance = this;
        return MerchantAPIService.instance;
    }

    defaultAuthConfig = () => { 
        const accessToken = authService.getCachedAccessToken();
        return { headers : { Authorization : `Bearer ${accessToken}` } };
    };

    fetchMerchants = (fetchLimit, paginationBookmark, filterModel, sortModel, fullSearchText) => { 
        
        var searchText, searchField, searchType = null;
        var sortField, sortOrder  = null;

        if (filterModel != null) {
            searchText = filterModel.value;
            searchField = filterModel.columnField;
            searchType = filterModel.operatorValue;
        }
        if (sortModel != null) {
            console.log("Inside here", sortModel);
            sortField = sortModel.field;
            sortOrder = sortModel.sort === 'asc' ? 'Ascending' : 'Descending';
        }
        if (!fetchLimit) fetchLimit = 100;
        if (!paginationBookmark) paginationBookmark = 0;

        if (fullSearchText) searchText = fullSearchText;

        const url = `${appConfig.serverBaseUrl}/merchants`;
        const config = { ...this.defaultAuthConfig(), params : { fetchLimit, paginationBookmark, searchText, searchField, searchType, sortField, sortOrder } };
        return axios.get(url, config);
    };
    fetchActiveMerchants = (fetchLimit, paginationBookmark, filterModel, sortModel, fullSearchText) => { 
        
      var searchText, searchField, searchType = null;
      var sortField, sortOrder  = null;

      if (filterModel != null) {
          searchText = filterModel.value;
          searchField = filterModel.columnField;
          searchType = filterModel.operatorValue;
      }
      if (sortModel != null) {
          console.log("Inside here", sortModel);
          sortField = sortModel.field;
          sortOrder = sortModel.sort === 'asc' ? 'Ascending' : 'Descending';
      }
      if (!fetchLimit) fetchLimit = 100;
      if (!paginationBookmark) paginationBookmark = 0;

      if (fullSearchText) searchText = fullSearchText;

      const url = `${appConfig.serverBaseUrl}/active-merchants`;
      const config = { ...this.defaultAuthConfig(), params : { fetchLimit, paginationBookmark, searchText, searchField, searchType, sortField, sortOrder } };
      return axios.get(url, config);
  };

    fetchMerchantOnlyCampaigns = ({ merchantId, sortModel, filterModel, paginationBookmark, fetchLimit }) => {

      var searchText = null, searchField = null, searchType = null;
      var sortField = null, sortOrder = null;
      if (! fetchLimit ) fetchLimit = 100;
      if (! paginationBookmark ) paginationBookmark = 0;

      if (filterModel != null && Object.keys(filterModel) > 0) {
          searchText = filterModel.value;
          searchField = filterModel.columnField;
          searchType = filterModel.operatorValue;
      }
      if (sortModel != null && Object.keys(sortModel).length > 0) {
          sortField = sortModel.field;
          sortOrder = sortModel.sort === 'asc' ? 'Ascending' : 'Descending';
      }
      const url = `${ appConfig.serverBaseUrl }/merchants/${ merchantId }/campaigns`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().get ( url, config );
    };

    fetchMerchant = (id) => {  

      const url = `${appConfig.serverBaseUrl}/merchants/${id}`
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().get(url, config);
    };

    toggleMerchant = (ids , action ) => { 
        if (ids != null) {

            const url = `${appConfig.serverBaseUrl}/merchants/${ids.id}/mark-as-inactive`;
            const config = { ...this.defaultAuthConfig() };
            console.log(config , ids ,ids.id);
            return authService.axiosService().post (url, {action} , config);
        } else { HWMessage.show ("Error while deleting merchant please specify ID"); }
    };


    deleteMerchant = (id) => { 
      if (id != null) {
          const url = `${appConfig.serverBaseUrl}/merchants/${id}`;
          const config = { ...this.defaultAuthConfig() };
          console.log(id , config);
          return authService.axiosService().delete (url,config);
      } else { HWMessage.show ("Error while deleting merchant please specify ID"); }
  };

    createMerchant = (merchant) => { 

        console.log("Creating", merchant);
        
        var fetchLimit = 100;
        var paginationBookmark = 0;

        const url = `${appConfig.serverBaseUrl}/merchants`;
        const config = {...this.defaultAuthConfig()};
        const data = {merchant};

        return axios.post (url, data, config);
    };
    updateMerchant = (merchant, s) => {
        merchant.status = s; 
        const url = `${appConfig.serverBaseUrl}/merchants/${merchant.id}`;
        const config = {...this.defaultAuthConfig()};
        const data = {merchant};

        return axios.put (url, data, config);
    }; 
    fetchCityStateByPostCode = (request) => {
      var postCode = request.postCode;
      const url = `${ appConfig.serverBaseUrl }/city-state`;
      const config = { ...this.defaultAuthConfig(), params : { postCode } };
      return authService.axiosService().get(url, config);
    };
    fetchProductCategories = () => {
      const url = `${ appConfig.serverBaseUrl }/product-categories`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().get(url, config);
    }
    fetchBranches = () => {
      const url = `${appConfig.serverBaseUrl}/merchants/config/branches`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().get(url, config);
    };
    fetchCategories = () => {
      const url = `${appConfig.serverBaseUrl}/merchants/config/categories`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().get (url, config);
    };
    fetchProductCategories = () => {
      const url = `${appConfig.serverBaseUrl}/product-categories`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().get (url, config);
    };

    updateBranches = (branches) => {
      const request = { branchList : branches };
      const url = `${ appConfig.serverBaseUrl }/merchants/config/branches`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().put (url, request, config);
    };

    updateCategories = categories => {
      const request = { categoryList : categories };
      const url = `${ appConfig.serverBaseUrl }/merchants/config/categories`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().put (url, request, config);
    };

    updateProductCategories = products => {
      const request = { productCategories : products };
      const url = `${ appConfig.serverBaseUrl }/product-categories`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().put (url, request, config);
    };  

    updateBranchDetails = (request) => {
      const url = `${ appConfig.serverBaseUrl }/merchants/config/branches/${request.id}`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService ().put (url, request, config);
    };

    updateCategoryDetails = (request) => {
      const url = `${ appConfig.serverBaseUrl }/merchants/config/categories/${ request.id }`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().put (url, request, config);
    };

    updateProductCategory = (request) => {
      const url =   `${ appConfig.serverBaseUrl }/product-categories/${request.id}`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().put (url, request, config);
    };
    createBranch = (request) => {
      const url = `${ appConfig.serverBaseUrl }/merchants/config/branches`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().post (url, request, config);
    };
    createCategory = (request) => {
      const url = `${ appConfig.serverBaseUrl }/merchants/config/categories`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().post (url, request, config);
    };
    createProductCategory = (request) => {
      const url = `${ appConfig.serverBaseUrl }/product-categories`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().post (url, request, config);
    };
    fetchMerchantUserList = (id) => {
      const url = `${ appConfig.serverBaseUrl }/merchants/${id}/merchant-users`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().get (url, config);
    }

};  

MerchantAPIService.TransactionApiService = class {
    constructor () {
      if (! MerchantAPIService.TransactionApiService.instance) { MerchantAPIService.TransactionApiService.instance = this; }
      return MerchantAPIService.TransactionApiService.instance;
    }
     
    fetchMerchantTransactions = (request) => {
        var searchField, searchText, searchType = null;
        if (request && request.filterModel) {
          searchField = request.filterModel.columnField;
          searchText = request.filterModel.value;
          searchType = request.filterModel.operatorValue;
        } 
        if (request && request.searchText)  {  
          searchText = request.searchText;
        } 
  
        var sortField, sortOrder = null;
        if (request && request.sortModel) {
          sortField = request.sortModel.field;
          sortOrder = request.sortModel.sort === "asc" ? "Ascending" : "Descending";
        }
  
        var fetchLimit, paginationBookmark, merchantId = null;
        if (request) {  
          if (request.fetchLimit != null) { fetchLimit = request.fetchLimit; }
          if (request.paginationBookmark != null) { paginationBookmark = request.paginationBookmark; }
          if (request.merchantId != null) { merchantId = request.merchantId; } 
        }
  
        const queryParams = (request != null) ? { params : { fetchLimit, paginationBookmark, searchText, searchField, searchType, searchText, sortField, sortOrder, transactionType : "all" } } : {};
        console.log("TXN Query Params ", queryParams); 
  
        const url = `${appConfig.serverBaseUrl}/merchants/${merchantId}/transactions`;
        const config = {...MerchantAPIService.instance.defaultAuthConfig(), params : { fetchLimit, paginationBookmark, searchText, searchField, searchType, searchText, sortField, sortOrder, transactionType : "all" } };
        return authService.axiosService().get(url, config);
     };
  
    fetchSpecificMerchantTransaction = (request) => {
          var transactionId = request && request.transactionId;
          var merchantId = request && request.merchantId;
          console.log("Transaction Fetch ", transactionId);
  
          const url = `${appConfig.serverBaseUrl}/merchants/${merchantId}/transactions/${transactionId}`;
          const config = { ...MerchantAPIService.instance.defaultAuthConfig() };
          return authService.axiosService().get(url, config);
      };
    
      
  };
  
  MerchantAPIService.PointsApiService = class {
     constructor () {
       if (! MerchantAPIService.PointsApiService.instance) { MerchantAPIService.PointsApiService.instance = this; }
       return MerchantAPIService.PointsApiService.instance;
     }
  
     fetchMerchantPoints = (request) => {
        var searchField, searchText, searchType = null;
        if (request && request.filterModel) {
          searchField = request.filterModel.columnField;
          searchText = request.filterModel.value;
          searchType = request.filterModel.operatorValue;
        } 
        if (request && request.searchText)  {  
          searchText = request.searchText;
        } 
  
        var sortField, sortOrder = null;
        if (request && request.sortModel) {
          sortField = request.sortModel.sortField;
          sortOrder = request.sortModel.sortOrder;
        }
  
        var fetchLimit, paginationBookmark, merchantId = null;
        if (request) {  
          if (request.fetchLimit != null) { fetchLimit = request.fetchLimit; }
          if (request.paginationBookmark != null) { paginationBookmark = request.paginationBookmark; }
          if (request.merchantId != null) { merchantId = request.merchantId; } 
        }      
        
        const queryParams = (request != null) ? { params : { fetchLimit, paginationBookmark, searchText, searchField, searchType, searchText, sortField, sortOrder } } : {};
        console.log("TXN Query Params ", queryParams); 
  
        const url = `${appConfig.serverBaseUrl}/merchants/${merchantId}/points-balance`;
        const config = {...MerchantAPIService.instance.defaultAuthConfig(), queryParams};
        return authService.axiosService().get(url, queryParams, config);
     };
  };





// Here singleton pattern being used for creating a merchant service
// The merchantApiService is that singleton object that does this. 
const merchantApiService = new MerchantAPIService();
const merchantTransactionApiService = new MerchantAPIService.TransactionApiService();
const merchantPointsApiService = new MerchantAPIService.PointsApiService();
export default merchantApiService;
export { merchantTransactionApiService, merchantPointsApiService };