import axios from 'axios';
import appConfig from '../../HWLibrary/HWConfig';
import authService from 'HWLibrary/ApiService';
import HWMessage from 'HWLibrary/HWMessage';

class PendingTransactionsApiService {

    constructor () {
        if (!PendingTransactionsApiService.instance)  PendingTransactionsApiService.instance = this;
        return PendingTransactionsApiService.instance;
    }
    defaultAuthConfig = () => { 
        const accessToken = authService.getCachedAccessToken();
        return { headers : { Authorization : `Bearer ${accessToken}` } };
    };
    
    fetchRequests = ( searchText,paginationBookmark, fetchLimit) => { 
        const url = `${appConfig.serverBaseUrl}/transaction-logs/pending-transactions`
        const config = { ...this.defaultAuthConfig(), params : {searchText : searchText.searchText, paginationBookmark:paginationBookmark, fetchLimit : fetchLimit} };
        return authService.axiosService().get(url, config);
    };

    updateRequestForAE = (request) => { 
        const url = `${appConfig.serverBaseUrl}/transaction-logs/pending-transactions`;
        const config = {...this.defaultAuthConfig()};
        const data = { ... request};
        return axios.post (url, data, config);
    };

    

    fetchRequest = (id) => {  
        const url = `${appConfig.serverBaseUrl}/transaction-logs/pending-transactions/${id}`
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
    };
    deleteRequest = (id) => { 
        if (id != null) {
            const url = `${appConfig.serverBaseUrl}/points/requests/${id}/delete`;
            const config = { ...this.defaultAuthConfig() };
            return authService.axiosService().put (url, {}, config);
        } else { HWMessage.show ("Error while deleting merchant please specify ID"); }
    };
    createRequest = (request) => { 
        const url = `${appConfig.serverBaseUrl}/points/requests`;
        const config = {...this.defaultAuthConfig()};
        const data = { ... request};

        return axios.post (url, data, config);
    };
    updateRequest = (request) => { 
        const url = `${appConfig.serverBaseUrl}/points/requests/${request.id}`;
        const config = {...this.defaultAuthConfig()};
        const data = { ...request };

        return axios.put (url, data, config);
    }; 
};

const pendingTransactionsApiService = new PendingTransactionsApiService();
export default pendingTransactionsApiService;