
import { ButtonGroup, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, makeStyles, TextField } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import roleApiService from './Roles/APIService';
import BlockUi from 'react-block-ui';
import Loader from 'react-loaders';

import EditIcon from '@material-ui/icons/Edit';
import HWMessage from 'HWLibrary/HWMessage';
import { HWInput } from 'HWLibrary/HWInput';
import { useHistory } from 'react-router';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: { paddingLeft: theme.spacing(4), },
}));

const ConfirmationDialog = (props) => {
    const history = useHistory();

    console.log("Confirmation Dialog", props.currentObject);
    const handleClose = () => props.setDialogOpen (false);


    const validateNewRole = roleObject => {
        console.log("Create object", roleObject);
        return (roleObject.roleName !== null && roleObject.roleName !== ''); 
    };

    const handleUpdate = () => {         
        if (props.createNewRole) {
            var okToCreate = validateNewRole (props.currentObject);
            if (okToCreate) {
                props.setIsLoading (true);
                roleApiService.createRole (props.currentObject) 
                .then (response => {
                    props.setIsLoading (false);
                    if (!response || !response.data){
                        HWMessage.show("Error while saving role" , "error")
                        return console.log("Error while saving role");
                    }
                    props.setCreateNewRole (false);
                    props.setCurrentObject (response.data);
                    HWMessage.show("Record saved successfully","success");
                })
                .catch (error => { props.setIsLoading (false); HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error"); });
            } else {
                HWMessage.show ("There's  something wrong with the request ","error");
                return;
            }
        } else {
            props.setIsLoading (true);
            roleApiService.updateRole (props.currentObject) 
            .then (response => { 
                if (!response || !response.data)
                    return HWMessage.show("Error while saving role","error");
                console.log("Record saved successfully","success");
                history.goBack();

             })
            .catch (error =>  { 
                console.log ("kjhdasdsa", error);
                HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error"); 
            }).finally(() => props.setIsLoading (false));
        }
        props.setDialogOpen (false);
    }

    return (
        <Fragment>
            <Dialog open = {props.dialogOpen} onClose = {handleClose}  >
                <DialogTitle> {props.dialogTitle} </DialogTitle>
                <DialogContent>  
                    <DialogContentText> 
                        {props.dialogMessage}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick = { handleClose } varaint = "contained" color = "primary" > Cancel </Button>
                    <Button onClick = { handleUpdate } variant = "contained" color = "primary" > { props.buttonText } </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default function MerchantPermissions (props) {

    const classes = useStyles ();

    const [createNewRole, setCreateNewRole] = useState ((props && props.location && props.location.params && props.location.params.state && props.location.params.state.createNewRole) ? true : false);
    const [ roleNameErrorMessage, setRoleNameErrorMessage ] = useState (null);
    const validateNewRole = createObject => { console.log("Validations", createObject); return (createObject.roleName !== null && createObject.roleName !== '');  };
    const roleHirerachy = [ 'View', 'Action' ];
    const createRoleList = (permitName) => {
        var result = [];
        for (var i = 0; i < roleHirerachy.length; i++) {
            result.push (roleHirerachy[i]);
            if (roleHirerachy[i] === permitName) break;
        }
        return result;
    }; 

    const history = useHistory();
    const roleId = props && props.location && props.location.params && props.location.params.state && props.location.params.state.roleId;
    if (!createNewRole && !roleId) history.goBack();

    useEffect (() => {
        if (!createNewRole) {
            setIsLoading (true);
            const roleId = props && props.location && props.location.params && props.location.params.state && props.location.params.state.roleId;
            
            roleApiService.fetchRole (roleId) 
            .then (response => {
                if (!response || !response.data) {
                    HWMessage.show("Error while fetching data" , "error");
                    return console.log ("Error while fetching data");}

                var result = response.data.permissionList.map ( item => {
                    var { permitName, permission } = item;
                    var newPermission = permission.map(subItem => { 
                        var { subPermitName, permissionsGranted, permissionAllowed } = subItem;
                        var newPermissionTypes = [];
                        permissionAllowed = createRoleList(permissionAllowed);

                        console.log (permissionAllowed);
                        for (var i = 0; i < permissionsGranted.length; i++)
                            if (permissionAllowed.indexOf(permissionsGranted[i]) !== -1)
                                newPermissionTypes.push (permissionsGranted[i]);
                        permissionsGranted = newPermissionTypes;

                        return { subPermitName, permissionsGranted, permissionAllowed };
                    });
                    return { permitName, permission : newPermission };
                } );
                
                setPermissions (result);
                setCurrentObject (response.data);
                setCurrentObject (prev => ({ ...prev, permissionList : result }));
            })
            .catch (error => {HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error"); })
            .finally (() => setIsLoading (false));
        }
    }, []);

    const defaultState = [ 
        { 
            permitName : "Home Screen", permission : [ 
             { 
                subPermitName : "Show ongoing live Events and Campaign", 
                permissionsGranted : ["View"] 
             }, 
             { 
                 subPermitName : "Show upcoming Events and Campaign", 
                 permissionsGranted : ["View"] 
             }, 
             { 
                 subPermitName : "Show in depth details of the Event and Campaign on click", 
                 permissionsGranted : ["View", "Action"]
             }, 
             { 
                 subPermitName : "Show Points rule linked to the event and campaign", 
                 permissionsGranted : ["View", "Action"]
             }, 
             { 
                 subPermitName : "Show Analytics linked to the event and campaign", 
                 permissionsGranted : ["View", "Action"]
             }, 
             { 
                 subPermitName : "Show the Points balance", 
                 permissionsGranted : ["View", "Action"]
             }, ] 
         },
         {
         permitName : "Events", permission : [ 
             { 
                subPermitName : "Show details of Advertisement", 
                permissionsGranted : ["View", "Action"]
             }, 
             { 
                 subPermitName : "Categorisation based on Ongoing, Upcoming and Past advertisement", 
                 permissionsGranted : ["View", "Action"]
             }, 
             { 
                 subPermitName : "List of Ongoing, Upcoming and Past advertisement", 
                 permissionsGranted : ["View", "Action"]
             }, 
             { 
                 subPermitName : "Detailed description of the advertisement that is being clicked from the list", 
                 permissionsGranted : ["View", "Action"]
             }, 
             { 
                 subPermitName : "Show details of A&P events", 
                 permissionsGranted : ["View", "Action"]
             }, 
             { 
                 subPermitName : "Categorisation based on Ongoing, Upcoming and Past A&P events", 
                 permissionsGranted : ["View", "Action"]
             }, 
             { 
                 subPermitName : "List of Ongoing, Upcoming and Past A&P events", 
                 permissionsGranted : ["View", "Action"]
             }, 
             { 
                 subPermitName : "Bifurication of All A&P events and My A&P events", 
                 permissionsGranted : ["View", "Action"]
             }, 
             { 
                 subPermitName : "Detailed description of the A&P events that is being clicked from the list", 
                 permissionsGranted : ["View", "Action"]
             }, 
             { 
                 subPermitName : "Show details of Merchant events", 
                 permissionsGranted : ["View", "Action"]
             }, 
             { 
                 subPermitName : "Categorisation based on Ongoing, Upcoming and Past merchant events", 
                 permissionsGranted : ["View", "Action"]
             }, 
             { 
                 subPermitName : "List of Ongoing, Upcoming and Past merchant events", 
                 permissionsGranted : ["View", "Action"]
             }, 
             { 
                 subPermitName : "Bifurication of All merchant events and My merchant events", 
                 permissionsGranted : ["View", "Action"]
             },
             { 
                 subPermitName : "Detailed description of the merchant events that is being clicked from the list", 
                 permissionsGranted : ["View", "Action"]
             }, 
         ] 
         },
         {
             permitName : "Scan", permission : [ 
                 { 
                    subPermitName : "Open up QR code scanner", 
                    permissionsGranted : ["View", "Action"]
                 }, 
                 { 
                     subPermitName : "Option to enter Account ID", 
                     permissionsGranted : ["View", "Action"]
                 }, 
                 { 
                     subPermitName : "Show member details on scanning QR code or entering account ID", 
                     permissionsGranted : ["View", "Action"]
                 }, 
                 { 
                     subPermitName : "Option to make checkin on behalf of the Member", 
                     permissionsGranted : ["View", "Action"]
                 }, 
                 { 
                     subPermitName : "Option to register a purchase linked to event ", 
                     permissionsGranted : ["View", "Action"]
                 }, 
                 { 
                     subPermitName : "Purchase detail page", 
                     permissionsGranted : ["View", "Action"]
                 }, 
                 { 
                     subPermitName : "Option to register a regular purchase ", 
                     permissionsGranted : ["View", "Action"]
                 },
                 { 
                     subPermitName : "Purchase detail page", 
                     permissionsGranted : ["View", "Action"]
                 },
             ] 
             },
             {
                 permitName : "Points", permission : [ 
                     { 
                        subPermitName : "Show the Points balance with Purchase more functionality", 
                        permissionsGranted : ["View", "Action"]
                     }, 
                     { 
                         subPermitName : "Show the Points awarded count", 
                         permissionsGranted : ["View", "Action"]
                     }, 
                     { 
                         subPermitName : "Show the Points purchased count", 
                         permissionsGranted : ["View", "Action"]
                     }, 
                     { 
                         subPermitName : "Show the Points return balance with points return functionality", 
                         permissionsGranted : ["View", "Action"]
                     }, 
                     { 
                         subPermitName : "Transaction History", 
                         permissionsGranted : ["View", "Action"]
                     }, 
                     { 
                         subPermitName : "Detail screen of the Transaction", 
                         permissionsGranted : ["View", "Action"]
                     }, 
                     { 
                         subPermitName : "Invoice performa and option to download", 
                         permissionsGranted : ["View", "Action"]
                     },
                 ] 
                 },
                 {
                     permitName : "Merchant Profile", permission : [ 
                         { 
                            subPermitName : "Access to profile details", 
                            permissionsGranted : [  ] 
                         }, 
                         { 
                             subPermitName : "Notification Tab", 
                             permissionsGranted : [  ] 
                         }, 
                         { 
                             subPermitName : "Account settings and notification settings", 
                             permissionsGranted : ["View", "Action"]
                         }, 
                         { 
                             subPermitName : "Payment Gateway", 
                             permissionsGranted : ["View", "Action"]
                         }, 
                         { 
                             subPermitName : "Customer Support - Integration with zendesk or freshdesk", 
                             permissionsGranted : ["View", "Action"]
                         }, 
                         { 
                             subPermitName : "Transaction History", 
                             permissionsGranted : ["View", "Action"]
                         },
                         { 
                             subPermitName : "User Management", 
                             permissionsGranted : ["View", "Action"]
                         },
                         { 
                             subPermitName : "Logout", 
                             permissionsGranted : ["View", "Action"]
                         }, 
                     ] 
                     },
    ];

    const [ permissions, setPermissions ] = useState ( [ 
       { 
           permitName : "Home Screen", permission : [ 
            { 
               subPermitName : "Show ongoing live Events and Campaign", 
               permissionsGranted : [  ],
               permissionAllowed : ["View", "Action" ]    
            }, 
            { 
                subPermitName : "Show upcoming Events and Campaign", 
                permissionsGranted : [  ],
                permissionAllowed : ["View", "Action" ]    
            }, 
            { 
                subPermitName : "Show in depth details of the Event and Campaign on click", 
                permissionsGranted : [  ],
                permissionAllowed : ["View", "Action" ]  
            }, 
            { 
                subPermitName : "Show Points rule linked to the event and campaign", 
                permissionsGranted : [  ],
                permissionAllowed : ["View", "Action" ]    
            }, 
            { 
                subPermitName : "Show Analytics linked to the event and campaign", 
                permissionsGranted : [  ],
                permissionAllowed : ["View", "Action" ]   
            }, 
            { 
                subPermitName : "Show the Points balance", 
                permissionsGranted : [  ],
                permissionAllowed : ["View", "Action" ]    
            }, ] 
        },
        {
        permitName : "Events", permission : [ 
            { 
               subPermitName : "Show details of Advertisement", 
               permissionsGranted : [  ],
               permissionAllowed : ["View" ] 
            }, 
            { 
                subPermitName : "Categorisation based on Ongoing, Upcoming and Past advertisement", 
                permissionsGranted : [  ],
                permissionAllowed : ["View"]   
            }, 
            { 
                subPermitName : "List of Ongoing, Upcoming and Past advertisement", 
                permissionsGranted : [  ],
                permissionAllowed : ["View"]   
            }, 
            { 
                subPermitName : "Detailed description of the advertisement that is being clicked from the list", 
                permissionsGranted : [  ],
                permissionAllowed : ["View"]  
            }, 
            { 
                subPermitName : "Show details of A&P events", 
                permissionsGranted : [  ], 
                permissionAllowed : ["View"]   
            }, 
            { 
                subPermitName : "Categorisation based on Ongoing, Upcoming and Past A&P events", 
                permissionsGranted : [  ], 
                permissionAllowed : [ "View"]
            }, 
            { 
                subPermitName : "List of Ongoing, Upcoming and Past A&P events", 
                permissionsGranted : [  ],
                permissionAllowed : ["View", "Action" ]     
            }, 
            { 
                subPermitName : "Bifurication of All A&P events and My A&P events", 
                permissionsGranted : [  ],
                permissionAllowed : ["View" ]
            }, 
            { 
                subPermitName : "Detailed description of the A&P events that is being clicked from the list", 
                permissionsGranted : [  ],
                permissionAllowed : ["View"]   
            }, 
            { 
                subPermitName : "Show details of Merchant events", 
                permissionsGranted : [  ],
                permissionAllowed : ["View"]   
            }, 
            { 
                subPermitName : "Categorisation based on Ongoing, Upcoming and Past merchant events", 
                permissionsGranted : [  ],
                permissionAllowed : ["View"  ]
            }, 
            { 
                subPermitName : "List of Ongoing, Upcoming and Past merchant events", 
                permissionsGranted : [  ],
                permissionAllowed : ["View", "Action" ]    
            }, 
            { 
                subPermitName : "Bifurication of All merchant events and My merchant events", 
                permissionsGranted : [  ],
                permissionAllowed : ["View"]   
            },
            { 
                subPermitName : "Detailed description of the merchant events that is being clicked from the list", 
                permissionsGranted : [  ],
                permissionAllowed : ["View"]   
            }, 
        ] 
        },
        {
            permitName : "Scan", permission : [ 
                { 
                   subPermitName : "Open up QR code scanner", 
                   permissionsGranted : [  ],
                   permissionAllowed : ["View", "Action" ]  
                }, 
                { 
                    subPermitName : "Option to enter Account ID", 
                    permissionsGranted : [  ],
                    permissionAllowed : ["View", "Action" ]   
                }, 
                { 
                    subPermitName : "Show member details on scanning QR code or entering account ID", 
                    permissionsGranted : [  ],
                    permissionAllowed : ["View"  ]
                }, 
                { 
                    subPermitName : "Option to make checkin on behalf of the Member", 
                    permissionsGranted : [  ],
                    permissionAllowed : ["View", "Action" ]   
                }, 
                { 
                    subPermitName : "Option to register a purchase linked to event ", 
                    permissionsGranted : [  ],
                    permissionAllowed : ["View", "Action" ]  
                }, 
                { 
                    subPermitName : "Purchase detail page", 
                    permissionsGranted : [  ],
                    permissionAllowed : ["View", "Action" ]  
                }, 
                { 
                    subPermitName : "Option to register a regular purchase ", 
                    permissionsGranted : [  ],
                    permissionAllowed : ["View", "Action" ]    
                },
                { 
                    subPermitName : "Purchase detail page", 
                    permissionsGranted : [  ],
                    permissionAllowed : ["View", "Action" ]  
                },
            ] 
            },
            {
                permitName : "Points", permission : [ 
                    { 
                       subPermitName : "Show the Points balance with Purchase more functionality", 
                       permissionsGranted : [  ],
                       permissionAllowed : ["View", "Action" ]   
                    }, 
                    { 
                        subPermitName : "Show the Points awarded count", 
                        permissionsGranted : [  ],
                        permissionAllowed : ["View"  ] 
                    }, 
                    { 
                        subPermitName : "Show the Points purchased count", 
                        permissionsGranted : [  ],
                        permissionAllowed : ["View"  ] 
                    }, 
                    { 
                        subPermitName : "Show the Points return balance with points return functionality", 
                        permissionsGranted : [  ],
                        permissionAllowed : ["View", "Action" ]  
                    }, 
                    { 
                        subPermitName : "Transaction History", 
                        permissionsGranted : [  ],
                        permissionAllowed : ["View", "Action" ]  
                    }, 
                    { 
                        subPermitName : "Detail screen of the Transaction", 
                        permissionsGranted : [  ],
                        permissionAllowed : ["View" ]  
                    }, 
                    { 
                        subPermitName : "Invoice performa and option to download", 
                        permissionsGranted : [  ],
                        permissionAllowed : ["View", "Action" ]  
                    },
                ] 
                },
                {
                    permitName : "Merchant Profile", permission : [ 
                        { 
                           subPermitName : "Access to profile details", 
                           permissionsGranted : [  ],
                           permissionAllowed : ["View", "Action" ]  
                        }, 
                        { 
                            subPermitName : "Notification Tab", 
                            permissionsGranted : [  ],
                            permissionAllowed : ["View"]
                        }, 
                        { 
                            subPermitName : "Account settings and notification settings", 
                            permissionsGranted : [  ],
                            permissionAllowed : ["View", "Action" ]  
                        }, 
                        { 
                            subPermitName : "Payment Gateway", 
                            permissionsGranted : [  ],
                            permissionAllowed : ["View", "Action" ]  
                        }, 
                        { 
                            subPermitName : "Customer Support - Integration with zendesk or freshdesk", 
                            permissionsGranted : [  ],
                            permissionAllowed :["View", "Action" ]  
                        }, 
                        { 
                            subPermitName : "Transaction History", 
                            permissionsGranted : [  ],
                            permissionAllowed : ["View", "Action" ]  
                        },
                        { 
                            subPermitName : "User Management", 
                            permissionsGranted : [  ],
                            permissionAllowed : ["View"  ]
                        },
                        { 
                            subPermitName : "Logout", 
                            permissionsGranted : [ ],
                            permissionAllowed : ["View", "Action" ]  
                        }, 
                    ] 
            },
     ] );



     // This object, currentObject would be loaded from DB and would be available for 
     // updating and reupoloading to DB, coming from DB it would only render what values would be 
     // assigned to it. 
     
    const [ expandSubItems, setExpandSubItems ] = useState ( Array(permissions.length).fill(false));

    const [ dialogOpen, setDialogOpen ] = useState (false);
    const accessLevels = ['View', 'Action'];
    const [ access, setAccess ] = useState ([]);
    const [ newRoleName, setNewRoleName ] = useState ('');
    const [ currentObject, setCurrentObject ] = useState ( {});
    const [ isLoading, setIsLoading ] = useState (false);
    const [ canEdit, setCanEdit ] = useState (false);
    const [ showEdit, setShowEdit ] = useState (true);

     const handleUpdateOpen = () => {
        if ( validateNewRole(currentObject)) {
            var permissionList = permissions.map((permitItem, idx) => {  
                var { permitName } = permitItem;
                var actualPermission = permitItem.permission.map ( permissionItem => { 
                    var {subPermitName, permissionsGranted} = permissionItem; 
                    return { subPermitName, permissionsGranted };
                });
                return { permitName, permission : actualPermission };
            });
            setCurrentObject ({...currentObject, permissionList});
            console.log("Permitting", currentObject, permissionList);
            setDialogOpen (true); setShowEdit (true); setCanEdit (false);
        } else {
            setRoleNameErrorMessage ("Please enter role name before saving");
            HWMessage.show ("Error while processing request", "error");
            return;
        }
    } ;
    const [ itemLevelAccess, setItemLevelAccess ] = useState (Array(permissions.length).fill([]));
    const toggleEdit = event => {setCanEdit (! canEdit); setShowEdit (false); }
    const handleItemLevelAccess = (event, newAccess, idx) => {

        var currentItemLevelAccess = [];
        if (newAccess.length > 0) {
            for (var i = 0; i < accessLevels.length; i++) {
                currentItemLevelAccess.push (accessLevels[i]);
                if (newAccess[newAccess.length - 1] == accessLevels[i]) break;
            }
            itemLevelAccess[idx] = currentItemLevelAccess;
            setItemLevelAccess ( itemLevelAccess );

            for (var i = 0; i < permissions[idx].permission.length; i++) {
                var currentAccess = [];
                for (var j = 0; j < accessLevels.length; j++) {
                    if (permissions[idx].permission[i].permissionAllowed.indexOf(accessLevels[j]) !== -1)
                        currentAccess.push (accessLevels[j]);  
                    if (accessLevels[j] === currentItemLevelAccess[currentItemLevelAccess.length - 1]) break;
                }
                permissions[idx].permission[i].permissionsGranted = currentAccess;
            }
            setPermissions(permissions); setAccess (newAccess);
        }
        else {
            for (var i = 0; i < accessLevels.length; i++) {
                if (newAccess.indexOf(accessLevels[i]) !== -1)
                    currentItemLevelAccess.push (accessLevels[i]);
                if (newAccess[newAccess.length - 1] == accessLevels[i]) break;
            }
            itemLevelAccess[idx] = currentItemLevelAccess;
            setItemLevelAccess ( itemLevelAccess );

            for (var i = 0; i < permissions[idx].permission.length; i++) {
                var currentAccess = [];
                permissions[idx].permission[i].permissionsGranted = currentAccess;
            }
            setPermissions(permissions); setAccess (newAccess);
        }
        setAccess (newAccess);
        setPermissions (permissions);
    };

    const handleAccess = (event, newAccess, idx, subidx) => {
        var currentAccess = [];
        if (newAccess.length) 
            for (var i = 0; i < accessLevels.length; i++ ) {
                if (permissions[idx].permission[subidx].permissionAllowed.indexOf(accessLevels[i]) !== -1) 
                    currentAccess.push (accessLevels[i]);
                if (newAccess[newAccess.length - 1] == accessLevels[i]) break;
            }       
        newAccess = currentAccess;
        setAccess (newAccess);
        permissions[idx].permission[subidx].permissionsGranted = currentAccess;
        setPermissions (permissions);
    };
    
    const handleClick = (idx) => { setExpandSubItems ({ [idx] : !expandSubItems[idx] }); };
    


    const getBgColor = (subpermit, permitName) => {
        if (subpermit && subpermit.permissionsGranted && subpermit.permissionsGranted.indexOf(permitName) !== -1) {  return '#138DE8'; }
        else {  return '#D0CFCF'};
    };
    const getFgColor = (subpermit, permitName) => {
        if (subpermit.permissionAllowed.indexOf(permitName) == -1) { return '#717171'; }
        if (subpermit.permissionsGranted.indexOf(permitName) !== -1 ) { return 'floralwhite'; }
        else return 'black';
    };

    const getItemLevelBgColor = (item, permitName) => {
        var permissions = item.permission;
        var selected = true;
        for (var i = 0; i < permissions.length; i++) {
            selected = selected & (permissions[i] && permissions[i].permissionsGranted && permissions[i].permissionsGranted.indexOf (permitName) !== -1); 
        } 
        if (selected) { return '#138DE8' }
        else { return '#D0CFCF'; }
    };
    const getItemLevelFgColor = (item, permitName) => {
        var permissions = item.permission;
        var selected = true;
        for (var i = 0; i < permissions.length; i++) {
            selected = selected & (permissions[i] && permissions[i].permissionsGranted && permissions[i].permissionsGranted.indexOf (permitName) !== -1); 
        } 
        if (selected) { return 'floralwhite' }
        else { return 'black'; }
    };

    return (
        <Fragment >
            <BlockUi 
                 blocking = {isLoading} 
                 message = "Loading Merchant Permission"                  
                 loader = { <Loader active type = 'line-scale-pulse-out-rapid' color="#02a17c" /> }
            >
                <List  style={{overflow:'hidden'}}
                    component = "nav"
                    subheader = {  (!createNewRole && 
                        <ListSubheader component = "div"  > 
                                <h3> 
                                    <b> {`${ currentObject && currentObject.roleName } Permissions`} </b> 
                                    { showEdit && <IconButton onClick = { toggleEdit } style = {{ color : '#138DE8' }}  > <EditIcon color = '#138DE8' /> </IconButton> }
                                </h3> 
                        </ListSubheader>) || 
                        ( createNewRole ) && <>
                            <GridContainer>
                                <GridItem display = "inline" >  
                                <h3 > <b> Job Role </b> </h3>
                                </GridItem>
                                <GridItem style = {{ marginTop : '11px' }}>
                                    <HWInput 
                                        autoFocus 
                                        margin = "dense" 
                                        id = "roleName"
                                        placeholder = "Enter Job Role"
                                        onBlur = { (event) => { 
                                                setCurrentObject({...currentObject, roleName : event.target.value});
                                                if (validateNewRole(currentObject)) { setRoleNameErrorMessage (null);  }    
                                                else {  setRoleNameErrorMessage ('Role name is required'); } 
                                            }  
                                        }
                                        helperText = { roleNameErrorMessage }
                                        hasError = { roleNameErrorMessage !== null }
                                    /> 
                                </GridItem>
                            </GridContainer>                            
                            <br />
                        </>
                    }
                >
                    { permissions.map ((item, idx) => {  
                        return (
                        <>
                                <ListItem key = {idx} button onClick = { () =>  handleClick (idx) }  >  
                                    <ListItemText secondary = { `Permits for ${item.permitName} ` } > <b> <h5> { item.permitName } </h5> </b> </ListItemText> 
                                    <ToggleButtonGroup  variant = "contained" value = { itemLevelAccess[idx] } 
                                        onChange = { (e, newAccess) => { 
                                                if (canEdit || createNewRole) { handleItemLevelAccess(e, newAccess, idx); }
                                                else { HWMessage.show ("Please enable editing before editing permits","error"); } 
                                            } 
                                        } 
                                    >
                                        <ToggleButton  value = "View" variant = "contained" style = {{ backgroundColor : getItemLevelBgColor(item,  "View"), color : getItemLevelFgColor (item, "View"), borderColor : 'black' }}  > View </ToggleButton>
                                        <ToggleButton value = "Action" variant = "contained" style = {{ backgroundColor : getItemLevelBgColor(item,  "Action"), color : getItemLevelFgColor(item, "Action"), borderColor : 'black' }}  > Action </ToggleButton>
                                        <IconButton onClick = {  () =>  handleClick (idx) } > <KeyboardArrowDownIcon /> </IconButton>
                                    </ToggleButtonGroup>
                                </ListItem> 
                                <Collapse in = { expandSubItems[idx] } unmountOnExit > 
                                    <List component = "div" disablePadding >  
                                        { item.permission.map ( (subpermit, subidx) =>   
                                        <ListItem key = {subidx}  className={classes.nested} > 
                                            <ListItemText primary = {`${ subpermit.subPermitName }`} /> 
                                            
                                            <ToggleButtonGroup size = 'small' variant = "outlined" value = { subpermit.permissionsGranted  } onChange = {(e, newAccess) => { if(canEdit || createNewRole) handleAccess(e, newAccess, idx, subidx); else HWMessage.show("Please enable editing  before editing permits","error"); }} >
                                                <ToggleButton value = "View" disabled = { subpermit.permissionAllowed.indexOf("View") == -1  } style = {{ backgroundColor : getBgColor (subpermit, "View"), borderColor : 'black', color : getFgColor (subpermit,  "View")  }} > View </ToggleButton>
                                                <ToggleButton value = "Action" disabled ={ subpermit.permissionAllowed.indexOf("Action") == -1 } style = {{ backgroundColor :getBgColor (subpermit, "Action"), borderColor : 'black',  color : getFgColor (subpermit,  "Action") }} > Action </ToggleButton> 
                                            </ToggleButtonGroup>
                                        </ListItem> ) }
                                    </List>
                                </Collapse>
                                <Divider />
                        </>
                        
                        );
                    }) 
                    }
                </List>

                <br/>
                <GridContainer justify = "center" >
                    <GridItem>  { (canEdit || createNewRole) && <Button  variant = "contained" size = "large" onClick = { handleUpdateOpen } style = {{ color : 'floralwhite', backgroundColor : '#138DE8' , textTransform:'None' }} > { (createNewRole && `Create Role`) ||  ((!createNewRole) && `Update Permissions`)} </Button>} </GridItem>
                    <GridItem> { (canEdit) && <Button variant = "contained" size = "large" onClick = { event => { setCanEdit(false); setShowEdit (true); } } style = {{ color : 'floralwhite', backgroundColor : '#FF9017' , textTransform:'None' }} > Cancel </Button> } </GridItem>
                </GridContainer>
                <ConfirmationDialog 
                    dialogOpen = {dialogOpen} 
                    setDialogOpen = {setDialogOpen} 
                    createNewRole = { createNewRole }
                    setCreateNewRole = {setCreateNewRole}
                    dialogTitle = { createNewRole ? 'Confirm Creating New Role' : 'Confirm Updating Permissions' } 
                    dialogMessage = { createNewRole ? `Do you want to create new role ${ newRoleName } with given Permissions ? ( Role permission can be changed but takes effect immediately )` : `Are you sure about updating permissions for ${props.roleName} ? This would take effect immediately across all the devices and relevant users.` } 
                    buttonText = { createNewRole ? "Create" : "Update" } 
                    currentObject = {currentObject}
                    setCurrentObject = {setCurrentObject}
                    isLoading = { isLoading }
                    setIsLoading = { setIsLoading }
                />
        </BlockUi>
        </Fragment>
    );
};