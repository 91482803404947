import React from 'react';
import { Button, TextField, Dialog, DialogContent, DialogActions, DialogContentText , DialogTitle } from '@material-ui/core';
import { useState } from 'react';
import memberApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import { sha512 } from 'js-sha512';
import BlockUi from 'react-block-ui';


export default function FormDialog4(props) {
  const [open, setOpen] = useState(true);
  const [psd , setPsd] = useState("");
  const [confirmPsd , setConfirmPsd] = useState("");
  const [isLoading ,setIsLoading ] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleChange = () => {
    setIsLoading(true);
    memberApiService.forgotPassword (sha512(psd) , sha512(confirmPsd) , props.mail)
    .then (response => {
      HWMessage.show("Password Change Successful" , "success");
      setOpen(false);

    })
    .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
  })
    .finally (() => setIsLoading (false));
}
   
const check = () => {

    if(psd.length < 3 || confirmPsd.length < 3 || psd !== confirmPsd )
    {
        return true;
    }
    else{
        return false;
    }
}


  return (
    <div >
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Forgot Password            
      </Button> */}
    <BlockUi blocking = { isLoading } message = { 'Please wait while we fetch.' } >
      <Dialog open={open} onClose={handleClose}   fullWidth={true}      maxWidth={"md"}>
        <center><DialogTitle style={{width : "400px"}}>Forgot Password</DialogTitle> </center>
        <DialogContent>
          <DialogContentText>
            <TextField
            margin="dense"
            id="name"
            label="Enter new password"
            type="password"
            helperText = {"Password should be more than 3 characters."}
            fullWidth
            variant="standard"
            onBlur ={ e=> setPsd(e.target.value)}
          />
          </DialogContentText>
         
          <DialogContentText>
            <TextField
            margin="dense"
            id="name"
            label="Confirm new password"
            type="password"
            fullWidth
            variant="standard"
            onBlur ={ e=> setConfirmPsd(e.target.value)}
            error={confirmPsd!=="" && psd !== confirmPsd }
            helperText={confirmPsd!=="" && psd != confirmPsd ? "Passwords are not matching" : ""}
          />
          </DialogContentText>
         
        </DialogContent>
        <DialogActions>
          <Button  style={{ background: "#FF9017" , textTransform: "none" , color: "floralwhite" }} onClick={handleClose}>Cancel</Button>
          <Button   style={{ background: check() ?  "#CFCFCF" : "#138DE8" , textTransform: "none" , color: check() ? "black" : "floralwhite" }}  disabled={check()} onClick={e=>handleChange()}>Change Password</Button>
        </DialogActions>
      </Dialog>
      </BlockUi>

    </div>
  );
}