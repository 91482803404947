import { Button,  IconButton, makeStyles, Popover } from '@material-ui/core';
import { DataGrid , GridToolbar} from '@material-ui/data-grid';
import { Delete, Edit, MoreVert, People } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState, useEffect, Fragment } from 'react';
import { DirectActionDialog } from 'views/Actions/VOCVerification';
import SearchBar from 'views/Merchant/SearchBar';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import NotificationsIcon from '@material-ui/icons/Notifications';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import BlockUi from 'react-block-ui';
import HWMessage from 'HWLibrary/HWMessage';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { green, red } from '@material-ui/core/colors';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import notificationAPIService from './APIService';
import settingsApiService from 'views/Settings/APIService';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Fab } from '@material-ui/core';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import moment from 'moment';
import { CustomToolbar } from 'views/Members/Member';

 const useStyle = makeStyles (theme => ({
     ...styles,
     bottomGrid : { marginTop : 10},
     createButton : { background : '#138DE8', color : 'floralwhite', textTransform : 'initial', fontSize : 24, height : 50, width : 300 },
     actionIcon : { color : '#138DE8' },
     editButton : { background : '#138DE8', color : 'floralwhite' , marginleft : 4 },
     deleteButton : { background : '#FF9017', color : 'floralwhite' }, 
     textContainer : {  whitespace: "nowrap" , overflow: "hidden",   textoverflow: "ellipsis" },
     createNotification : {background : '#138DE8', color : 'floralwhite', textTransform : 'initial', fontSize : 34, height : 75, width : 400},
 }));


 



 function ActionPopover (props) {
    return ( 

                <div>
                <Popover
                    open={ props.open }
                    anchorEl={ props.anchorEl}
                    onClose={  (event) => { props.handleClose (false)}  }
                >
                    <div >
                        <div style = {{ margin : '20px' }} > 
                            <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }}onClick = {event => props.handleEdit() }  > Edit </Button> 
                            <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} onClick = { event => props.setDeleteOpen(true) } >  Delete </Button> 
                        </div> 
                    </div>
                </Popover>
            </div>
    
    );
};

export default function Notification (props) {
    if (HWLocalStorage.load ("previousVouchersTab") !== null ) 
    HWLocalStorage.clear("previousVouchersTab");
   if(HWLocalStorage.load ("previousVouchersRuleTab") !== null)
     HWLocalStorage.clear("previousVouchersRuleTab");
     if (HWLocalStorage.load ("previousCardTab") !== null ) 
     HWLocalStorage.clear("previousCardTab");
     if(HWLocalStorage.load("previousComSettingsTab")!== null){
        HWLocalStorage.clear("previousComSettingsTab")
    }
    if(HWLocalStorage.load("previousRolesSettingsTab")!== null){
        HWLocalStorage.clear("previousRolesSettingsTab")
    }

    if(HWLocalStorage.load("previousSettingsTab")!== null){
        HWLocalStorage.clear("previousSettingsTab")
    }
    if(HWLocalStorage.load("previousMemberTab")!== null){
        HWLocalStorage.clear("previousMemberTab")
    }
    if(HWLocalStorage.load("previousActionsTab")!== null){
        HWLocalStorage.clear("previousActionsTab")
    }
    if(HWLocalStorage.load("previousMerchantDetailsTab")!==null)
    {
        HWLocalStorage.clear("previousMerchantDetailsTab");
    }

    if(HWLocalStorage.load("previousMerchantTab")!==null)
    {
        HWLocalStorage.clear("previousMerchantTab");
    }
     const classes = useStyle ();
     const history = useHistory ();

   var notifications=[]
   var obj = JSON.parse(HWLocalStorage.load("Permissions"))
   notifications= [...obj["Notifications"]];



    const [ selectedRuleId, setSelectedRuleId ] = useState ();

    const [ requestsLoading, setRequestsLoading ] = useState (false);
    const [ refreshState, setRefreshState ] = useState (false);
    const [ loading, setLoading ] = useState (false);
    const [ rulesLoading, setRulesLoading ] = useState (false);
    const [ deleteOpen, setDeleteOpen ] = useState (false);

    
    const [ rows, setRows ] = useState ([]);
    const [ page, setPage ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ filterModel, setFilterModel ] = useState ({});
    const [ sortModel, setSortModel ] = useState ({});
      const [ rowCount, setRowCount ] = useState (0);
    const [ openDirectAction, setOpenDirectAction ] = useState (false);
    const [ selectedRequest, setSelectedRequest ] = useState (null);
    const [ searchText, setSearchText ] = useState ('');
    const [creatorMode , setCreatorMode ] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState (null);
    const [ directActionOpen, setDirectActionOpen ] = useState (false);
    const [ deleteItemState , setDeleteItemState ] = useState({});
     const columns = [
        { field : 'creatorName', headerName : 'Creator Name', filterable : true,  sortable : true, flex : 2 },
        { field : 'recipientType', headerName : 'Recipient Type',filterable : true,  sortable : true, flex : 2},
        { field : 'title', headerName : 'Title',filterable : false,  sortable : true, flex : 6 ,  renderCell : (cellParams) => <span style={{  textOverflow :"ellipsis",overflow :"hidden" }} className={classes.textContainer}> { cellParams.row.title } </span> },
        { field : 'scheduledTime',headerName : 'Scheduled Date,Time', filterable : false,  sortable : true, flex : 3 ,  renderCell : (cellParams) => <span style={{whiteSpace:'pre-wrap',lineHeight:'1'  }} className={classes.textContainer}> { cellParams.row.scheduledTime &&  cellParams.row.scheduledTime.split("T")[0] + " " + cellParams.row.scheduledTime.split("T")[1].split("Z")[0]
         }  </span> },
        
    ];

    if (notifications.includes("Action") === true) {
        columns.push ({ field : 'Edit',             headerName : '',             filterable : false, sortable : false, flex : 1,  renderCell : cellParams => <> <IconButton  onClick = { (event) =>{  handleEdit(cellParams.row)} } > <Edit style={{color:"#138de8"}}  /> </IconButton>   </>  });

        columns.push ({ field : 'Delete',             headerName : '',             filterable : false, sortable : false, flex : 1,  renderCell : cellParams => <> <IconButton  onClick = { (event) =>{ handleDelete(cellParams.row) } } > <Delete style={{color:"#FF9017"}} /> </IconButton>   </>  });
    }

    const handleEdit = (id) =>{
         history.push ({ pathname : 'create-notification', params : { state : { createMode : false , id } } });
    } 

    useEffect (() => {
        setLoading (true);
        var paginationBookmark = ((page) * pageSize);
        var fetchLimit = pageSize;
        
        notificationAPIService.fetchNotifications ({fetchLimit, paginationBookmark , searchText , filterModel, sortModel })
        .then (response => {
            if (!response || !response.data){

                HWMessage.show("Can't fetch notifications at the moment" , "error");
                return console.log("No relevant data can be fetched");
            }
            response.data.notifications=response.data.notifications.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.scheduledTime) - new Date(a.scheduledTime);
              });
              response.data.notifications = response.data.notifications.filter(i=>{return i.type == "Generic"})
              setRows (response.data.notifications);
              if (searchText && searchText != null && searchText != "") {  
                    let notifs = response.data.notifications;
                    notifs = notifs.filter(i => { 
                        var ri = ! i.recipientType ? "" : i.recipientType; 
                        var ci = ! i.creatorName ? "" : i.creatorName;

                        return ri.search(searchText) >= 0 || ci.search(searchText) >= 0;
                    });
                    setRows (prev => notifs);
                    setRowCount (prev => notifs.length);
              } 
        
        
        setRowCount(response.data.notifications.length);
        HWMessage.show("Request Successful" , "success");
        })
        .catch (error => {HWMessage.show (error.response.data.message || "Request could not be resolved", "error"); })
        .finally (() => { setLoading (false); setDirectActionOpen(false) ;
        
            var my_element = document.getElementById("top");
            if(my_element!== null || my_element!== undefined || my_element!== '')
            {
                // my_element.scrollIntoView({ }); 

            }
        
        });


}, [ page, pageSize , searchText ,  refreshState , filterModel , sortModel ]);

        const deleteRule = useCallback (ids => {
            if (ids != null) {
                setRulesLoading (true);
                notificationAPIService.deleteNotification (ids) 
                .then (response => {
                    if (!response || !response.data) { return HWMessage.show ("Error while deleteing the rule","error"); }
                    
                    setRowCount (rowCount - 1);
                    setRows (prev => { prev = prev.filter (i => i.id !== ids.id); return [ ...prev ] });
                    setDirectActionOpen(false);
                    setRefreshState(prev => !prev);
                    HWMessage.show("Request Successful" , "success");
                })
                .catch (error => { return HWMessage.show (error.response.data.message || "Request could not be resolved", "error"); })
                .finally (() => { setRulesLoading (false) ;setDirectActionOpen(false); });
            }
        });

   const handleDelete = (id) => {
    setSelectedRuleId(id);
    setDeleteOpen(true);
   }

     function CustomLoadingOverlay() {
        return (
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
      }

      const handleCreateClick = event => { history.push ({ pathname : 'create-notification', params : { state : { createMode : true } } }); };  
      const useDataGridStyles = makeStyles(theme => ({
        root: {
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiButton-label' : { color : '#138DE8' }
        }
      }));
      const dataGridClasses = useDataGridStyles();
   
    
     return (
         <Fragment>
                <BlockUi blocking = { loading } message = "Loading" tag = "div"  >
                 <Card id="top"> 
                     <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                         <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <NotificationsIcon /> </CardIcon>
                         <h4 className = { classes.cardIconTitle } > 
                        Notification  </h4> 
                     </CardHeader>
                     <CardBody>  
                         <GridContainer style = {{ justifyContent : 'space-between' }}>
                            <GridItem >
                                    <SearchBar 
                                                handleSearch = { setSearchText }
                                                handleRefresh = { setRefreshState }
                                                refreshState = { refreshState }
                                            />
                             </GridItem>
                             <GridItem> 
                                {   notifications.includes("Action") === true  && <Button variant = "contained" className = { classes.createButton } onClick = { event => handleCreateClick() }  > Create Notification </Button> 
                                }          
                             </GridItem>
                            </GridContainer>
                            <GridContainer>
                             <GridItem className = { classes.bottomGrid } xs = {12} md = {12}>
                                     <DataGrid 
                                        className = { dataGridClasses.root }
                                        showToolbar
                                         page = { page }
                                         pageSize = { pageSize }
                                         rows = { rows }
                                         rowCount = { rowCount }
                                         columns = { columns }
                                        
                                         pagination
                                         paginationMode = 'server'
                                         onPageChange = { changeParams => setPage (changeParams) }
                                         onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams); } }
                                         sortingMode="server" 
                                         onSortModelChange = { params => { 
                                            if (params.length > 0 ) 
                                                setSortModel (params[0]);
                                          }}
                                         filterMode = 'client'
                                         onFilterModelChange = { (params) => {
                                           if (params.items.length > 0) {
                                             setFilterModel (prev => ({ ...params.items[0] })); 
                                           }
                                         } }

                                         density = 'compact'
                                         autoHeight
                                         rowsPerPageOptions ={ [25, 50, 100 ]}
                                         loading = { requestsLoading }
                                         components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 
                                         disableExtendRowFullWidth = { false }
                                     />
                                     </GridItem>
                         </GridContainer>
                     </CardBody>
                 </Card>
                 <ConfirmationDialog    
            dialogTitle = { 'Confirm Notification Deletion' } 
            dialogMessage = { 'Are you sure you want to delete this notification. ' } 
            confirmingAction = { e => deleteRule(selectedRuleId) } open = { deleteOpen } setOpen = { setDeleteOpen } 
        />   
        <ActionPopover 
        open = { directActionOpen } 
        handleClose = { setDirectActionOpen } 
        anchorEl = { anchorEl } 
        setDeleteOpen={setDeleteOpen}
         setAnchorEl = { setAnchorEl } 
         handleEdit = { e => handleEdit(deleteItemState) }   
          handleDelete = { e => handleDelete(deleteItemState) } 
          setDirectActionOpen ={ setDirectActionOpen}  />
             
             </BlockUi>
            
         </Fragment>
     );
 };




