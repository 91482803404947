import { useCallback, useEffect } from 'react';
import { AppBar, Button, Card, CardActions, CircularProgress, IconButton, makeStyles, Tab, Tabs , Tooltip } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState } from 'react';
import { Fragment } from 'react';
import SearchBar from 'views/Merchant/SearchBar';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 

import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import { HWInput } from 'HWLibrary/HWInput';
import { Link, useHistory } from 'react-router-dom';
import {PeriodDialog} from './VOCVerification';
import { DirectActionDialog } from './VOCVerification';
import voucherRequestApiService from './ApiService';
import HWMessage from 'HWLibrary/HWMessage';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import BlockUi from 'react-block-ui';
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { pointsRequestApiService } from './ApiService';
import pointsRequestsApiService from 'views/PointsConfiguration/APIService';
import {CustomToolbar} from '../Members/Member.js'


const useStyles = makeStyles (theme => ({ 
    ...styles,  
    cardFooter : { justifyContent : 'center' },
    cardHeader : { height : '50px' },   
    formControl: { margin: theme.spacing(1), minWidth: 120 }, 
    root : { 
      '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
      '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
      '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
      '& .MuiButton-label' : { color : '#138DE8' }
    }
}));

export default function PointAction (props) {

    var action=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    action= [...obj["Actions"]];

    if(props.requestType === "Accrue")
    {
        HWLocalStorage.save (0, "previousPointsActionsTab");

    }
    if(props.requestType === "Deduct")
    {
        HWLocalStorage.save (1, "previousPointsActionsTab");

    }
    if(props.requestType === "Returning")
    {
        HWLocalStorage.save (2, "previousPointsActionsTab");

    }
    
    HWLocalStorage.save (1, "previousActionsTab");

    const [ dataList, setDataList ] = useState ([
        { index : 0,  title : 'REQUESTS RAISED', type : 'All' },
        { index : 1, title : 'REQUESTS APPROVED', type : 'Approved' }, 
        { index : 2, title : 'REQUESTS REJECTED', type : 'Rejected' }
    ]);
    const selectDataCard = () => {
        var cachedData = HWLocalStorage.load ("voucherActionDataCategory");
        var pendingObject  = {index : 0, title : 'REQUESTS PENDING', type : 'Pending' };
        if (!cachedData || cachedData === null) { return pendingObject; }
        else if (cachedData.type === 'Pending') { return pendingObject; }
        else if (cachedData.type !== 'Pending') { 
            var temp = dataList[cachedData.index]; 
            dataList[cachedData.index] = pendingObject;
            pendingObject = temp;

            HWLocalStorage.clear ("voucherActionDataCategory");
            return cachedData; 
        }
    };
    
    const classes = useStyles ();
    const [ fetchRequestType, setFetchRequestType ] = useState (props && props.requestType);
    const [rows,setRows] = useState([]);
    const [ isLoading, setIsLoading ] = useState (false);
    const [ countMaps, setCountMaps ] = useState ({ 'Pending' : 0, 'All' : 0, 'Approved' : 0, 'Rejected' : 0 }); 
    const [ periodDialog, openPeriodDialog ] = useState (false);
    const [ currentDataCard, setCurrentDataCard ] = useState (selectDataCard());

    const [ page, setPage ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ filterModel, setFilterModel ] = useState ({});
    const [ sortModel, setSortModel ] = useState ({});
    const [ selectedRequest , setSelectedRequest ] = useState({});
    const [ searchText, setSearchText ] = useState ('');
    const [ refreshState, setRefreshState ] = useState (false);
    const [ rowCount, setRowCount ] = useState (0);
    const [ requestViewModels, setRequestViewModels ] = useState ({});
    const [ loading, setLoading] = useState(false);
    const [ countLoading, setCountLoading ] = useState (false);
    const [ startDate, setStartDate ] = useState ();
    const [ endDate, setEndDate ] = useState ();
    const [reason , setReason ] = useState("");
    const openPointReward = pointDetail => { history.push ({pathname : "action-points", params : {state: { pointDetail }} }); };

    useEffect (() => {
        setLoading(true);
        setIsLoading (true);
        pointsRequestApiService.fetchStats(props.requestType).
        then (response => {
            if (!response || !response.data) {
                HWMessage.show ("Requests cannot be fetched at moment" , "error");
                return console.log("Responses cannot be fetched at moment");
            }
            HWMessage.show ("Requests fetched successfully","success");
            setCountMaps ({ 'Pending' : response.data.pendingRequests, 'Approved' : response.data.approvedRequests, 'Rejected' : response.data.rejectedRequests, 'All' : response.data.rejectedRequests+response.data.pendingRequests+response.data.approvedRequests  });
        }).catch (error => { 
            HWMessage.show ("Error while fetching requests","error"); 
            return console.log("Error while fetching requests"); 
        }).finally (() => { setIsLoading (false);         setLoading(false);        });
    }, [ props.requestType ]);

    useEffect (() => {
        setIsLoading (true);
        setLoading(true);

        var requestType = currentDataCard.type; 
        var paginationBookmark = (page) * pageSize;
        var fetchLimit = pageSize;

        pointsRequestApiService.fetchRequest ({ requestType: props && props.requestType, fetchType: requestType,fetchLimit, paginationBookmark, searchText, sortModel, filterModel }).
        then (response => {
            if (!response || !response.data) {
                HWMessage.show ("Requests cannot be fetched at moment","error");
                return console.log("Responses cannot be fetched at moment");
            }
            HWMessage.show ("Requests fetched successfully","success");
            setRowCount (response.data.requestCounts);
            setRows(response.data.pointsRequests);
        }).catch (error => { 
            HWMessage.show ("Error while fetching requests","error"); 
            return console.log("Error while fetching requests"); 
        }).finally (() => { setIsLoading (false);         setLoading(false); 
            var my_element = document.getElementById("top");
            if(my_element!== null || my_element!== undefined || my_element!== '')
            {
                // my_element.scrollIntoView({ }); 

            } });
    }, [ currentDataCard, page, pageSize, filterModel, sortModel, searchText, refreshState, startDate, endDate ]);    
    
    
    const changeData = (idx) => {  
        var temp = currentDataCard;
        setCurrentDataCard(prev => dataList [idx]);
        dataList [idx] = dataList [temp.index];
        dataList [temp.index] = temp;
        setDataList (dataList);
        setRefreshState (! refreshState);
    };
    
    const [ directActionOpen, setDirectActionOpen ] = useState (false);
    const [ anchorEl, setAnchorEl ] = useState (null);
    const [ selectedVoucher, setSelectedVoucher ] = useState ({});
    const [ actionLoading, setActionLoading ] = useState (false);
    const [ savedLoading, setSavedLoading ] = useState (false);

    const getApprovalStatusColor = approvalStatus => {
        switch (approvalStatus) {
            case 'Pending' : return '#FF9017';
            case 'Approved' : return '#1f7a1f';
            case 'Rejected' : return '#cc0000';
        }
    };
    const columns = [
        { field: 'refNumber', headerName: 'Reference No.', flex: 1.5,minWidth:150, renderCell : (cellParams) => ( <span style= {{color : '#138de8' , }} onClick = { (event) => openPointsRequests(cellParams.row) } > <Link style = {{ color : '#138DE8', fontWeight : 400  }} > { cellParams.row.refNumber } </Link>  </span> ) },
        { field : 'approvalStatus', headerName : 'Request Status', flex: 1.25, minWidth:150,renderCell : (cellParams) => ( <span style = {{ color : ( getApprovalStatusColor (cellParams.row.approvalStatus)), fontWeight :  400 }} > { cellParams.row.approvalStatus.charAt(0).toUpperCase() + cellParams.row.approvalStatus.slice(1) } </span> ) }, 
        {field : 'userCount', headerName : 'User Count', filterable: false ,flex: 1, minWidth:150,renderCell : (cellParams) => (<span> { props.requestType === 'Returning' ? 1 : cellParams.row.userCount } </span>) },
        {field : 'pointsPerUser', filterable :  false , headerName : 'Points Per User', flex: 1.25,minWidth:150, renderCell : (cellParams) => (<span> { props.requestType === 'Returning' ? cellParams.row.pointsReturned : cellParams.row.pointsPerUser } </span>) },
        {field : 'totalPoints', filterable :  false , headerName : 'Total Points', flex: 1.25,minWidth:150, renderCell : (cellParams) => (<span> { props.requestType === 'Returning' ? cellParams.row.pointsReturned : cellParams.row.totalPoints } </span>) },
        {field : 'description', filterable :  false , headerName : 'Description', flex: 2.25, minWidth:150,renderCell : (cellParams) => (<span> { cellParams.row.description } </span>) },

        { field : 'raisedByEmpName', headerName : props.requestType === 'Returning' ? 'Raised By' : 'Raised By', flex: 2,minWidth:150, renderCell : cellParams => (<span  > {(props.requestType === 'Returning') ? cellParams.row.raisedByMerchantName : cellParams.row.raisedByEmpName } </span>) },
        { field : 'resolvedDate', headerName : 'Updated Date', flex: 1,minWidth:150, renderCell : cellParams => (<span  > { cellParams.row.resolvedDate != null  ? new Date(cellParams.row.resolvedDate).toLocaleDateString(): "" } </span>) },
        { field : 'resolvedByEmpName', headerName : 'Updated By', flex: 1,minWidth:150, renderCell : cellParams => (<span  > { cellParams.row.resolvedByEmpName } </span>) },
        { field : 'rejectionReason', headerName : 'Rejected Reason', flex: 1,minWidth:150, renderCell : cellParams => (<span  > { cellParams.row.rejectionReason != undefined ? cellParams.row.rejectionReason  : ''} </span>) },

    ];

    if(currentDataCard.type === "Pending"){
        columns.push(        { field : 'raisedDate', filterable: false ,  headerName : 'Raised Date', flex : 2, renderCell: cellParams => (<span  > { cellParams.value ? new Date(cellParams.value).toLocaleDateString() : null } </span>) });
    }

    if(currentDataCard.type !== "Pending"){
        columns.push({ field : 'resolvedDate', filterable: false , headerName : 'Resolved Date', flex : 2, renderCell: cellParams => (<span  > { cellParams.value ? new Date(cellParams.value).toLocaleDateString() : null } </span>) },
        );
    }

    if(action.includes("Action") === true && currentDataCard.type === "Pending"){ columns.push({ field: 'Action', sortable:false,filterable: false ,headerName : 'Action', flex: 1, renderCell : (cellParams) => ( <> { cellParams.row.approvalStatus === 'Pending' &&  <IconButton onClick = { (event) => { console.log("Current Popover target ", event.currentTarget , cellParams.row ); setSelectedRequest(cellParams.row) ; setAnchorEl (event.currentTarget);setDirectActionOpen(true);  } } > <MoreVertIcon style = {{ color : '#138DE8' }} /> </IconButton> } </>)} ) }
    function CustomLoadingOverlay() {
        return (
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
      }

    const updateRequest = useCallback ((selectedRequest, action, reason) => {
        console.log(selectedRequest);
        if (selectedRequest === null || selectedRequest && selectedRequest.approvalStatus && selectedRequest.approvalStatus !== 'Pending') { return HWMessage.show ("This request is already been resolved"); }

        if (action === 'approve') 
        selectedRequest.requestStatus = 'Approved' ;     
        else if (action === 'reject'){
            selectedRequest.rejectionReason = reason;
            selectedRequest.requestStatus = 'Rejected' ;

        }
        setIsLoading (true);
        pointsRequestsApiService.updateRequest (selectedRequest) 
        .then (response => {  
            if (! response || ! response.data) { return HWMessage.show ("Error while fetching response","error"); }
            setRows (prev => {   
                if (prev === null && prev.length === 0) { return []; }
                else {
                    for (var idx = 0; idx < prev.length; idx++) 
                        if (selectedRequest.id === prev[idx].id) 
                            prev[idx].approvalStatus = (action === 'approve') ? 'Approved' : 'Rejected';
                    const t = [ ...prev ]; return t;
                }
            });
        })
        .catch (error => { return HWMessage.show ("Error while updating requests","error"); })
        .finally (() => { setIsLoading (false) ;  setAnchorEl(null);  setDirectActionOpen(false);});
    }, [  ]);


    // Functions for further meta actions. Which may be imporant worthwhile
    const approvalAction = (selectedRequest) => { return updateRequest (selectedRequest, 'approve'); };
    const rejectAction = (selectedRequest, reason) => { return updateRequest (selectedRequest, 'reject' , reason); };  

    const history = useHistory ();
    const openPointsRequests = pointsRequest => { history.push ({ pathname: "reward-points", state : { currentRequest: pointsRequest , creatorMode : false  } }) };

    return (
        <Fragment> 
        <BlockUi blocking = { loading || savedLoading || countLoading } message = { 'Loading Request' } > 
            <GridContainer id="top" >
                { dataList.map ((dataType, idx) =>   (
                    <GridItem xs = {3} key = { idx } >
                    <Tooltip title={ dataType.title}>
                    <Card > 
                        <CardBody> 
                            <GridContainer> 
                                <GridItem xs = {8} > <span style = {{ fontWeight : '500' }} > { dataType.title } </span> </GridItem>
                                <GridItem xs = {4} >
                                    {! isLoading && <Link onClick = { (event) => changeData (idx) } style = {{ fontSize : '1.5em', fontWeight : '500', color : '#138DE8' }}  >  { countMaps[dataType.type] }  </Link> }
                                    { isLoading && <CircularProgress/> }
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                    </Tooltip>
                    </GridItem>
                )) 
                }
                <GridItem xs ={3} style={{display:'flex',flexDirection:"row-reverse",alignItems:"center"}}>
                    <div style = {{ display : 'flex', flexDirection:"row-reverse" }}> <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { openPeriodDialog } >  <FilterListOutlinedIcon /> Filter Request Results   </Button> </div>
                </GridItem>
            </GridContainer>
            <br />

            <GridContainer justify = "space-between" alignItems = "center" > 
            <GridItem> <b> <span style = {{ fontWeight : '500px', fontSize : '2em' }} > { currentDataCard.title } : { ! isLoading && <a href = "#top" style = {{ color : '#138DE8', textDecoration : 'underline' }} >  { countMaps[currentDataCard.type] } </a> } { isLoading && <CircularProgress size = {25} /> } </span> </b> </GridItem>
                <GridItem style={{margin: "0px 10px 10px 0px"}}>  
                    <SearchBar 
                        width = '300px' 
                        handleSearch = { setSearchText }
                        handleRefresh = { setRefreshState }
                        refreshState = { refreshState }
                        placeholder = "Search"
                    /> 
                </GridItem>
            </GridContainer>
            
            <Card>
                <CardBody>
                <DataGrid
                    className = { classes.root }
                    autoHeight
                    showToolbar 
                    density="compact" 
                    loading = { isLoading } 
                    columns={columns} 
                    rows={rows} 
                    rowCount = { rowCount }
                    page = { page }
                    pageSize = { pageSize }
                    onPageChange = { (gridChangeParams) => { setPage (gridChangeParams);} }
                    onPageSizeChange = { (gridChangeParams) => { setPage (0); setPageSize(gridChangeParams); } }
                    rowsPerPageOptions ={ [25, 50, 100 ]}
                    components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 

                    pagination = { true }
                    paginationMode = 'server'
                    sortingMode = 'server'
                    onSortModelChange = { params => { if (params.length > 0)  {setSortModel (params[0]);}} } 
                    filterMode = 'server'
                    onFilterModelChange = { (params) => { if (params.items.length > 0) { setFilterModel (prev => ({ ...params.items[0] }));  } } }
                />
                </CardBody>
            </Card>    
            

            <PeriodDialog 
                periodDialog = { periodDialog } 
                openPeriodDialog = { openPeriodDialog } 
                startDate = { startDate } 
                endDate = { endDate } 
                setStartDate = { setStartDate }
                setEndDate = { setEndDate }
            />

            <DirectActionDialog 
                open = { directActionOpen }
                dialogTitle = { 'Direct Voucher Approval' }
                dialogContent = { 'Do you want to approve or reject the Voucher Request' }
                approvalAction = { e => approvalAction(selectedRequest) }
                rejectAction = { e => rejectAction(selectedRequest,reason) }
                selectedRequest = {selectedRequest}
                reason = {reason}
                setReason= {setReason}
                setDirectActionOpen = { setDirectActionOpen }
                anchorEl = { anchorEl }
            />   
         </BlockUi>
        </Fragment> 
    );   
};