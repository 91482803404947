import { Divider, makeStyles, Paper } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { displayName } from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';

import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';

export default function Voucher (props) {
    const useStyles = makeStyles (theme => ({
        ticket : {
            display : 'inline-block', 
            boxSizing : 'content-box',
            filter : 'drop-shadow(2px 2px 5px rgba(black,.5))', 
            height : '5em', 
            width : '32em',
            padding : '1em', 
            color : 'black',
            fontSize : '16px', 
            backgroundSize : '51% 100%', 
            borderRadius : '5px',
            backgroundRepeat : 'no-repeat',
            backgroundImage : `radial-gradient(circle at 0 50%, rgba(255,0,0,0) 1em, ${voucherColor} 0.5em), radial-gradient(circle at 100% 50%, rgba(255,0,0,0) 1em, ${voucherColor} 0.5em)`,
            backgroundPosition : 'top left, top right',
        }
    }));

    
    console.log("Voucher", props.voucher, props.voucher.chassisNumber === undefined, props.voucher.chassisNumber === null);
    const [ voucher, setVoucher ] = useState(props && props.voucher);
    const voucherType = voucher.chassisNumber  ? 'coupon' : 'generic';
    const [ selected, setSelected ] = useState ( props.selected === true ? true : false );
    //const [ voucherColor, setVoucherColor ] = useState (null);
    let voucherColor = null;
    let voucherPrefix = null;
    
    
    if ( voucherType === 'coupon') {
        
        if ( props.selected && props.selected === true) { voucherColor = ('#F76958') }
        if (!props.selected || ! (props.selected === true)) { voucherColor = ('#FFEAE8'); }
        else { voucherColor = ('#F76958'); }
        console.log("zzzzzz", props, voucherColor);
        voucherPrefix = 'E-Service';
    } 
    else if (voucherType === 'generic') {
        if (props.selected && props.selected === true) { voucherColor = ('#717171'); } 
        else  { voucherColor = ('#D6DBDF'); }
        console.log("Printig voucher", voucher);
        if (voucher && voucher.voucherPrefix !== null) { voucherPrefix = voucher.voucherPrefix }
        else if (voucher && voucher.voucherCode !== null) { voucherPrefix = voucher.voucherCode; }
    }    
    
    const classes = useStyles ();
    console.log("Voucher Type", voucherType);

    return (
        <Fragment>
            <span  className = {classes.ticket} style = {{ boxShadow : '5px 10px #FFFFFF', backgroundImage : `radial-gradient(circle at 0 50%, rgba(255,0,0,0) 1em, ${voucherColor} 0.5em), radial-gradient(circle at 100% 50%, rgba(255,0,0,0) 1em, ${voucherColor} 0.5em)`,  }} >
                <GridContainer >
                    <GridItem xs = {4} > 
                        <GridContainer style = {{ borderRight : '2px', borderRightStyle : 'dotted', height : '86px' }} >
                            <GridItem style = {{ marginLeft : '22px' }} >  <img src={ (voucherType === 'generic') ? voucher.imageFilename : '/yamaha12.png'} style = {{ height : '45px', width : '100px', backgroundColor : 'transparent' }}  />  </GridItem>
                            { (voucherType === 'coupon') && <GridItem> <center> <span style = {{ fontSize : '10px' }} > Yamaha E-Service Coupon </span> </center> </GridItem> }
                            { (voucherType === 'generic') && <GridItem> <center> <span style = {{ fontSize : '10px' }} > Generic Voucher for purchases </span> </center> </GridItem> }
                        </GridContainer>
                    </GridItem>
                    <GridItem xs = {8} >  
                        <GridContainer>
                            <GridItem xs = {6} > 
                                <GridContainer>  
                                    <GridItem xs = {12} > <span style = {{ fontSize : '10px' }} > Items </span>  </GridItem>
                                    <GridItem xs = {12} > <span style = {{ fontSize: 15 }}> 1 <img src={ "/multiplier1.svg" } style = {{ height : '20px', width : '20px', backgroundColor : 'transparent', color : 'blue', paddingTop : 1,  paddingBottom : 2 }} />  { /* <ConfirmationNumberIcon style = {{ height : '12px' }} /> */ }  { voucherPrefix  } </span>    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs = {6} > 
                                { voucher.status === 'Available' &&  
                                    <>
                                        <GridItem xs = {12} > <span style = {{ fontSize : '10px' }} > Valid till </span>  </GridItem>
                                        <GridItem xs = {12} > <h5> {voucher.validTill ? new Date(voucher.validTill).toLocaleDateString("en-US",{  day: 'numeric' }) +" " + new Date(voucher.validTill).toLocaleDateString("en-US",{   month: 'short', year: 'numeric'  }) : new Date(voucher.expiryDate).toLocaleDateString("en-US",{  day: 'numeric' }) +" " + new Date(voucher.expiryDate).toLocaleDateString("en-US",{   month: 'short', year: 'numeric'  }) } </h5>  </GridItem>
                                    </>
                                }
                                { voucher.status === 'Redeemed'  && 
                                    <>
                                        <GridItem xs = {12} > <span style = {{ fontSize : '10px' }} > Redeemed On </span>  </GridItem> 
                                        <GridItem xs = {12} > <h5> {voucher.redeemedDate ? new Date(voucher.redeemedDate).toLocaleDateString("en-US",{  day: 'numeric' }) +" " + new Date(voucher.redeemedDate).toLocaleDateString("en-US",{   month: 'short', year: 'numeric'  }) : new Date(voucher.redeemedDate).toLocaleDateString("en-US",{  day: 'numeric' }) +" " + new Date(voucher.redeemedDate).toLocaleDateString("en-US",{   month: 'short', year: 'numeric'  })} </h5>  </GridItem>
                                    </>
                                }
                                { voucher.status === 'Expired'  && voucher.expiryDate &&
                                <>
                                    <GridItem xs = {12} > <span style = {{ fontSize : '10px' }} > Expired On </span>  </GridItem> 
                                    <GridItem xs = {12} > <h5> {voucher.expiryDate ? new Date(voucher.expiryDate).toLocaleDateString("en-US",{  day: 'numeric' }) +" " + new Date(voucher.expiryDate).toLocaleDateString("en-US",{   month: 'short', year: 'numeric'  }) :new Date(voucher.expiryDate).toLocaleDateString("en-US",{  day: 'numeric' }) +" " + new Date(voucher.expiryDate).toLocaleDateString("en-US",{   month: 'short', year: 'numeric'  }) } </h5>  </GridItem>
                                </>
                                }
                                
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </span>
        </Fragment>
    );
};
