import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from "@material-ui/core";
import React, { useEffect, useState }  from "react";
import LockIcon from '@material-ui/icons/Lock';
import AddIcon from '@material-ui/icons/Add';
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";
import { Link } from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import { Fragment } from "react";
import roleApiService from "./Roles/APIService";

import ClipLoader from 'react-spinners';
import BlockUi from "react-block-ui";
import HWLocalStorage from "HWLibrary/HWLocalStorage";
import HWMessage from "HWLibrary/HWMessage";

const StyledTableRow = withStyles ((theme) => ({ 
    root : { '&:nth-of-type(odd)' : { backgroundColor : theme.palette.action.hover } }, 
 })) (TableRow);

 const StyledTableCell = withStyles ((theme) => ({
    head : { 
        backgroundColor : theme.palette.common.black, 
        color : theme.palette.common.white 
    }, 
    body : { fontSize : 14 }
 })) (TableCell);


 const ConfirmationDialog = (props) => {

    const handleClose = () => props.setDialogOpen (false);
    const handleDelete = () => {
        props.setIsLoading (true);
        roleApiService.deleteRole(props.role.id) 
        .then (response => {
            props.setIsLoading (false);  
            if (!response || !response.data)
               {    HWMessage.show("Error while deleting data");
                    return console.log ("Error while deleting the data");}
               HWMessage.show("Deletion Successful", "success")
            console.log("Deletion successful");
            props.setRoles (props.roles.filter(i => i.id !== props.role.id));
        })
        .catch (error => { props.setIsLoading(false); HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");  });

        handleClose();
    };

    return (
        <Fragment>
            <Dialog open = {props.dialogOpen} onClose = {handleClose}  >
                <DialogTitle> {props.dialogTitle} </DialogTitle>
                <DialogContent>  
                    <DialogContentText> 
                        {props.dialogMessage}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick = { handleClose } style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' , margin: 5 }} > Cancel </Button>
                    <Button onClick = { handleDelete } variant="outlined"  style = {{  color : 'Grey' }} > { props.buttonText } </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};
 

export default function MerchantRoles (props) {
    HWLocalStorage.save (1, "previousMerchantTab")

    if(HWLocalStorage.load("previousMerchantDetailsTab")!==null)
    {
        HWLocalStorage.clear("previousMerchantDetailsTab");
    }

    var merchantarray=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    merchantarray= [...obj["Merchants"]];

    const [ isLoading, setIsLoading ] = useState (false);
    const [ role, setRole ] = useState (false);
    const [dialogOpen, setDialogOpen] = useState (false);
    
    const [ roles, setRoles ] = useState ([]);

    useEffect (() => {
        setIsLoading (true);
        roleApiService.fetchRoles()
            .then (response => { 
                if (!response || !response.data) 
                   { return HWMessage.show("Error while fetching roles","error"); }
                setIsLoading (false);
                setRoles (response.data);
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { setIsLoading (false);  HWMessage.show("Error while fetching roles", "error")});
    }, []);
    
    const handleDelete = (role, idx) => {
        console.log ("Here", role);
        setRole (role); 
        setDialogOpen (true);
    };

    
    const handleClick = () => { 
        // Add new role here on the database, and update the table as well using state
    };

    return (
        <Fragment>
            <BlockUi blocking = { isLoading } message = "Loading Roles" tag = "div"  >
            <TableContainer component = {Paper} >
            <Link to = {{ pathname :  "merchant-permissions",  params : { state : { createNewRole : true } } }} >
            {merchantarray.includes("Action")===true && <Fab className="AddButton" variant = "extended" style={{ color: 'white' , marginTop: 10,marginBottom:10 }} backgroundColor={[roseColor[0]]} onClick = { handleClick } >  
                <AddIcon/> 
                <span>New Role</span>
            </Fab>}
            </Link>
                <Table size = "small" >  
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align = "center" > Job Role </StyledTableCell>
                            <StyledTableCell align = "center" > Permission </StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        { roles.map((role, idx) => (
                             <StyledTableRow key = {idx} > 
                                 <StyledTableCell align = "center" > {role.roleName} </StyledTableCell>
                                 <StyledTableCell align = "center" > 
                                    <ButtonGroup>
                                        <Link to = {{ pathname : '/ops/merchant-permissions', params : { state : { roleName : role.roleName, roleId : role.id } } }} > <IconButton > <LockIcon style = {{ color : '#138DE8' }} /> </IconButton> </Link> 
                                       {merchantarray.includes("Action")===true && <IconButton onClick = { () => handleDelete (role, idx) } disabled = { role.status === 'Erasable' ? false : true } > 
                                            {
                                                (role.status === 'Erasable') && 
                                                    <DeleteIcon style = {{ color : '#FF9017' }} /> || 
                                                ( role.roleStatus === 'NotErasable' && 
                                                    < div style = {{ width : '26px' }}></ div>) 
                                            } 
                                        </IconButton>}
                                    </ButtonGroup>
                                </StyledTableCell>
                             </StyledTableRow>   
                        )) 
                        }
                    </TableBody>
                   
                </Table>
            </TableContainer>
            
           
            </BlockUi>
            
            <ConfirmationDialog 
                dialogOpen = {dialogOpen} 
                setDialogOpen = {setDialogOpen} 
                
                dialogTitle = {'Confirm Deleting Role'} 
                dialogMessage = { ' Do you want delete role ? This would stop the relevant users from accessing resources.' } 
                buttonText = { 'Delete' } 
                
                role = { role }
                roles = { roles }
                setRoles = { setRoles }
                isLoading = {isLoading}
                setIsLoading = { setIsLoading }
            />
        </Fragment>
    );
};