import axios from 'axios';
import appConfig from '../../HWLibrary/HWConfig';
import authService from 'HWLibrary/ApiService';


// There is caching enabled in this module, therefore 
// the service caches the instances for saving time to initialise one.
class VoucherConfigApiService {

    constructor () {
        if (!VoucherConfigApiService.instance) 
        VoucherConfigApiService.instance = this;
        return VoucherConfigApiService.instance;
    }

    defaultAuthConfig = () => { 
        const accessToken = authService.getCachedAccessToken();
        return { headers : { Authorization : `Bearer ${accessToken}` } };
    };

    fetchCodes = () => { 
        const url = `${appConfig.serverBaseUrl}/vouchers/config`;
        const config = { ...this.defaultAuthConfig() };
        return axios.get(url, config);
    };

    fetchVoucherHistory = (request) => { 
      var searchField, searchType, searchText = null;
        if (request && request.filterModel) {
          searchField = request.filterModel.columnField;
         
          searchType = request.filterModel.operatorValue;
          searchText = request.filterModel.value;

          if (searchField === 'description') searchField = 'voucherRequest.reason';
          else if (searchField === 'voucherCode') searchField = 'voucherRequest.code';
        }  
    
        if (request && request.searchText)
          searchText = request.searchText;
    
        var sortField, sortOrder = null;
        if (request && request.sortModel) {
            console.log("Current sort model",  request.sortModel);
            if (request.sortModel.field && request.sortModel.sort) {
              sortField = request.sortModel.field;
              sortOrder = request.sortModel.sort === "asc" ? "Ascending" : "Descending";
            } 
        }  
        const queryParams = request !== undefined ? { params: { searchText, searchField, searchType, sortField, sortOrder, fetchLimit: request.fetchLimit, paginationBookmark: request.paginationBookmark  } } : {};
      const url = `${appConfig.serverBaseUrl}/vouchers/history`;
      const config = { ...this.defaultAuthConfig() , ...queryParams};
      return axios.get(url, config);
  };

    fetchVoucherCreationList = (request) => {
      var searchField, searchType, searchText = null;
        if (request && request.filterModel) {
          searchField = request.filterModel.columnField;
         
          searchType = request.filterModel.operatorValue;
          searchText = request.filterModel.value;

          if (searchField === 'applyType') searchField = 'productCategory.applyType';
        }  
    
        if (request && request.searchText)
          searchText = request.searchText;
    
        var sortField, sortOrder = null;
        if (request && request.sortModel) {
            console.log("Current sort model",  request.sortModel);
            if (request.sortModel.field && request.sortModel.sort) {
              sortField = request.sortModel.field;
              sortOrder = request.sortModel.sort === "asc" ? "Ascending" : "Descending";
            } 
        }  
        const queryParams = request !== undefined ? { params: { searchText, searchField, searchType, sortField, sortOrder, fetchLimit: request.fetchLimit, paginationBookmark: request.paginationBookmark  } } : {};
        const url = `${ appConfig.serverBaseUrl }/vouchers/config`;
        const config = { ...this.defaultAuthConfig(), ...queryParams };
        
        return authService.axiosService ().get (url, config);
      };

      deleteVoucherCreation = rule => {
        if (rule !== null) { rule.status = 'Inactive' };
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/vouchers/config/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };
     
      createVoucher = (rule) => {
        const url = `${ appConfig.serverBaseUrl }/vouchers/config`;
        const config = { ...this.defaultAuthConfig() };
        const request = { ...rule };
  
        return authService.axiosService ().post (url, request, config);
      };

      fetchProductCategories = (request) => {
        var searchField, searchType, searchText = null;
        if (request && request.filterModel) {
          searchField = request.filterModel.columnField;
         
          searchType = request.filterModel.operatorValue;
          searchText = request.filterModel.value;          
        }  
        
        if (request && request.searchText)
          searchText = request.searchText;
    
        var sortField, sortOrder = null;
        if (request && request.sortModel) {
            console.log("Current sort model",  request.sortModel);
            if (request.sortModel.field && request.sortModel.sort) {
              sortField = request.sortModel.field;
              sortOrder = request.sortModel.sort === "asc" ? "Ascending" : "Descending";
            } 
        }  
        const queryParams = request !== undefined ? { params: { searchText, searchField, searchType, sortField, sortOrder, fetchLimit: request.fetchLimit, paginationBookmark: request.paginationBookmark  } } : {};


        const url = `${ appConfig.serverBaseUrl }/product-categories`;
        const config = { ...this.defaultAuthConfig()  , ...queryParams};
        return authService.axiosService().get(url, config);
      }



      fetchSpecificVoucher = (id) => {
        const url = `${ appConfig.serverBaseUrl }/vouchers/config/${ id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
       
      }

      createVoucherCategory = (rule) => {
        const url = `${ appConfig.serverBaseUrl }/product-categories`;
        const config = { ...this.defaultAuthConfig() };
        const request = { ...rule };
  
        return authService.axiosService ().post (url, request, config);
      };


      updateSpecificVoucher = rule => {
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/vouchers/config/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

      fetchSpecificVoucherCategory = (id) => {
        const url = `${ appConfig.serverBaseUrl }/product-categories/${ id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
       
      }

      updateSpecificVoucherCategory = rule => {
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/product-categories/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };


    deleteSpecificVoucherCategory = rule => {
        if (rule !== null) { rule.status = 'Inactive' };
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/vouchers/config/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

      createAwardRule = (rule) => {
        const url = `${ appConfig.serverBaseUrl }/vouchers/rules`;
        const config = { ...this.defaultAuthConfig() };
        const request = { ...rule };
  
        return authService.axiosService ().post (url, request, config);
      };


      updateAwardRule = rule => {
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/vouchers/rules/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

      fetchAwardRuleList = (request) => {
        var searchField, searchType, searchText = null;
        if (request && request.filterModel) {
          searchField = request.filterModel.columnField;
          searchType = request.filterModel.operatorValue;
          searchText = request.filterModel.value;

          
        }  
        if (request && request.searchText)
          searchText = request.searchText;
    
        var sortField, sortOrder = null;
        if (request && request.sortModel) {
            console.log("Current sort model",  request.sortModel);
            if (request.sortModel.field && request.sortModel.sort) {
              sortField = request.sortModel.field;
              sortOrder = request.sortModel.sort === "asc" ? "Ascending" : "Descending";
            } 
        }  
        const queryParams = request !== undefined ? { params: { searchText, searchField, searchType, sortField, sortOrder, fetchLimit: request.fetchLimit, paginationBookmark: request.paginationBookmark  } } : {};
        const url = `${ appConfig.serverBaseUrl }/vouchers/rules`;
        const config = { ...this.defaultAuthConfig(), ...queryParams };
        
        return authService.axiosService ().get (url, config);
      };

      fetchSpecificAwardRule = (id) => {
        const url = `${ appConfig.serverBaseUrl }/vouchers/rules/${ id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
       
      }


      deleteAwardRule = rule => {
        if (rule !== null) { rule.status = 'Inactive' };
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/vouchers/rules/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

      deleteVoucherConfig = rule => {
        if (rule !== null) { rule.status = 'Inactive' };
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/vouchers/config/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };
      
      fetchS3PresignedUrl = (name) => {
        const url = `${ appConfig.serverBaseUrl }/presigned-url/voucher-images`;
        const config = { ...this.defaultAuthConfig() , params : { fileName : name } };
        return authService.axiosService().get (url, config);
    
      }
    
      uploadFile(fileToUpload, presignedUrl) {
        let config = { headers: { "content-type": fileToUpload.type } }
        return axios.put(presignedUrl, fileToUpload, config);
      }
    
    
      uploadImage = (fileName) => {
        const url = `${ appConfig.serverBaseUrl }/presigned-url/voucher-images`;
        const config = { params : { fileName: fileName } };
        return authService.axiosService().get (url, config);
      };
};

// The voucherConfigApiService is that singleton object that does this. 
const voucherConfigApiService = new VoucherConfigApiService();
export default voucherConfigApiService;