import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles,makeStyles } from "@material-ui/core";
import React, { useEffect, useState }  from "react";
import LockIcon from '@material-ui/icons/Lock';
import AddIcon from '@material-ui/icons/Add';
import { roseColor } from "assets/jss/material-dashboard-pro-react.js";
import { Link } from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import { People } from '@material-ui/icons';
import { Fragment } from "react";
import roleApiService from "views/Merchant/Roles/APIService";
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import BlockUi from "react-block-ui";
import HWLocalStorage from "HWLibrary/HWLocalStorage";
import RuleForm from "./PermissionRuleForm";
import settingsApiService from "./APIService";
import HWMessage from "HWLibrary/HWMessage";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";


const StyledTableRow = withStyles ((theme) => ({ 
    root : { '&:nth-of-type(odd)' : { backgroundColor : theme.palette.action.hover } }, 
 })) (TableRow);

 const StyledTableCell = withStyles ((theme) => ({
    head : { 
        backgroundColor : theme.palette.common.black, 
        color : theme.palette.common.white 
    }, 
    body : { fontSize : 14 }
 })) (TableCell);

 function RuleDialog (props) {
    
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ ruleDescription, setRuleDescription ] = useState ({});

    console.log (props);

    const handleClose = event => {props.setOpen (false);  props.setDirectActionOpen(false) ;}
    return (
        <>
            <Dialog open = { props.open } onClose = { handleClose }  >
                <RuleForm refreshState = { props.refreshState } setRefreshState = { props.setRefreshState } rules = { props.rules } setRules = { props.setRules } createMode = { props.createMode } ruleId = { props.ruleId } open = { props.open } setOpen = { props.setOpen }  setDirectActionOpen = {props.setDirectActionOpen} role={props.roleName}
  />
            </Dialog>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    ...styles,
    cardHeader : { background : '#138DE8'  },
    cardIcon : { background : '#138DE8' },
    bottomGrid : { marginTop : 10 },
    '& .MuiFormLabel-root' : { marginBottom : 0 },
}));



export default function Roles (props) {
    var settings=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    settings= [...obj["Settings"]];

    if(HWLocalStorage.load("previousComSettingsTab")!== null){
        HWLocalStorage.clear("previousComSettingsTab")
    }
    HWLocalStorage.save (0, "previousRolesSettingsTab")

    const [needed ,setNeeded ] = useState("");
     const [ isLoading, setIsLoading ] = useState (false);
    const [ role, setRole ] = useState (false);
    const [dialogOpen, setDialogOpen] = useState (false);
    const [ directActionOpen , setDirectActionOpen ] = useState(false);
    const [ selectedUserList , setSelectedUserList ] = useState(false);

    const [ roles, setRoles ] = useState ([]);
    const classes = useStyles();

    useEffect (() => {
        setIsLoading (true);
        settingsApiService.fetchRoles()
            .then (response => { 
                if (!response || !response.data) {
                    HWMessage.show("Can't fetch roles at the moment"  , "error")
                    return console.log("Error while fetching roles");
                }
                setIsLoading (false);
                setRoles (response.data.responseList);
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { setIsLoading (false);  HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        });
    }, []);
    
    const handleDelete = () => {
        setIsLoading (true);
        settingsApiService.deleteRole(deletingRule) 
        .then (response => {
            props.setIsLoading (false);  
            if (!response || !response.data){
                HWMessage.show("Request Unsuccessful", "error");
                return console.log ("Error while deleting the data");
            }
            HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
           .finally (() => { setIsLoading (false);setCreateOpen(false);
         });
       
        }
    
    const handleClick = () => { 
        // Add new role here on the database, and update the table as well using state
    };

    const addPermission = (id) => {
        setSelectedRuleId(role.id);
        setSelectedUserList(id)
        setcreateMode (false);
        setRuleOpen (prev => true);

    }
    const [ selectedRuleId, setSelectedRuleId ] = useState ();
    const [ ruleOpen, setRuleOpen ] = useState (false);
      const [ rows, setRows ] = useState ([]);
      const [ refreshState, setRefreshState ] = useState (false);
      const [ createMode, setcreateMode ] = useState (false);
      const [deletingRule, setDeletingRule] = useState({});
      const [deletingRuleIndex, setDeletingRuleIndex] = useState(-1);
      const [ createOpen, setCreateOpen ] = useState (false); 
      const [searchText, setSearchText] = useState("");
     
    return (
        <Fragment>
            <BlockUi blocking = { isLoading } message = "Loading Roles" tag = "div"  >
                <Card id="top">
                    <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                        <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        <h4 className = { classes.cardIconTitle } > 
                           Ops User Roles
                        </h4> 
                    </CardHeader>
                    <CardBody>
                        <GridContainer style={{justifyContent:'end'}}>
                            <GridItem>
                                { settings.includes("Action") === true  && <Link to = {{ pathname :  "ops-permissions",  params : { state : { createNewRole : true } } }} >
                                    <Fab className="AddButton" variant = "extended" style={{ color: 'white' }} backgroundColor={[roseColor[0]]} onClick = { handleClick } >  
                                        <AddIcon/> 
                                        <span>New Role</span>
                                    </Fab>
                                </Link>}
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem className = { classes.bottomGrid } xs = {12} md = {12}>
                            <TableContainer component = {Paper} >
                                <Table size = "small" >  
                                    <TableHead>
                                        <TableRow>               
                                            <StyledTableCell align = "center" > Job Function </StyledTableCell>
                                            <StyledTableCell align = "center" > Employees </StyledTableCell>
                                            <StyledTableCell align = "center" > Permission </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { roles.map((role, idx) => (
                                            <StyledTableRow key = {idx} > 
                                                <StyledTableCell align = "center" > <span >  {role.roleName} </span> </StyledTableCell>
                                                <StyledTableCell align = "center" ><Link> <h5> <span style={{ color: "#FF9017" , fontWeight: "900" }} onClick={ e => {setNeeded(role.roleName); addPermission(role) ; }  }>  {role && role.userList && role.userList.length  ?role.userList.length : 0 } </span></h5> </Link> </StyledTableCell>

                                                <StyledTableCell align = "center" > 
                                                    <ButtonGroup>
                                                        <Link to = {{ pathname : '/ops/ops-permissions', params : { state : { createMode : false ,roleName : role.roleName, roleId : role.id } } }} > <IconButton > <LockIcon style = {{ color : '#138DE8' }} /> </IconButton> </Link> 
                {settings.includes("Action") === true && role.roleName !=="Admin" &&  <IconButton onClick = { () => {setDeletingRule(role);setRole (role); setDeletingRuleIndex(idx); setCreateOpen(true);}  }  > {<DeleteIcon style = {{ color : '#FF9017' }} />   } </IconButton>
                }                                    </ButtonGroup>
                                                </StyledTableCell>
                                            </StyledTableRow>   
                                        )) 
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
            <RuleDialog     
                ruleId = { selectedRuleId }
                open = { ruleOpen }     
                setOpen = { setRuleOpen } 
                rules = { selectedUserList }
                setRules = { setSelectedUserList }
                refreshState = { refreshState } 
                createMode = { createMode}
                setRefreshState = { setRefreshState }
                setDirectActionOpen = {setDirectActionOpen}
                roleName={needed}

            />
            </Card>
            </BlockUi>
            <ConfirmationDialog 
                open = {createOpen} 
                setOpen = {setCreateOpen} 
                
                dialogTitle = {'Confirm Deleting Role'} 
                dialogMessage = { ' Do you want delete role ? This would stop the relevant users from accessing resources.' } 
                confirmingAction={e=>handleDelete()}
                
            />
        </Fragment>
    );
};