import React, { useState } from "react";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import { TextArea } from "semantic-ui-react";
import { TextField } from "@material-ui/core";

export default function AutocompleteTextArea2 (props) {

    const Item = ({ entity: { name, placeholder } }) => ( <div style={{width: 300}}>{`${name}: ${placeholder}`}</div> );
    const Loading = ({ data }) => <div>Loading</div>;
      
    // var placeholders = [
    //     { name: "Member Name", placeholder: "<Member_Name>" },
    //     { name: "Member Class", placeholder: "<Member_Class>" },
    //     { name: "Membership Number", placeholder: "<Membership_Number>" },
    //     { name: "Transaction Name", placeholder: "<Transaction_Name>" },
    //     { name: "Transaction Number", placeholder: "<Transaction_Number>" },
    //     { name: "Transaction ID", placeholder: "<Transaction_ID>" }
    // ];


    const [ state, setState ] = useState ('');
    var placeholders = props && props.placeholders;

    return (
        <div>
          <ReactTextareaAutocomplete
            loadingComponent={Loading}
            style={{
              fontSize: "12px",
              lineHeight: "20px",
              padding: 5,
              width: "120%",
              position: "absolute",
              left: "-100px",
              backgroundColor: "white"
          
            }}
            containerStyle={{
              marginTop: 20,
              width: 400,
              height: 100,
              margin: "20px auto",
              zIndex: 999,

            }}

            name =  "Places"
            disabled = { props.disabled }
            onChange = { props.onChange }
            value={props.value}
            minChar={0}
            trigger={{
              "<": {
                dataProvider: (token) => {
                  return placeholders
                    .slice(0, 100)
                    .map((a) => ({ name : a.slice(1,a.length-1), placeholder: a }));
                },
                component: Item,
                output: (item, trigger) => item.placeholder
              }
            }}
          />
          { state }
        </div>
    );
};




