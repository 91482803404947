
// import axios from 'axios';
// import HWUser from './HWUser';
class HWLog {

    static LogLevel = { DEBUG:"DEBUG", INFO:"INFO", ERROR:"ERROR", WARN:"WARN", NONE:"NONE" }
    static logLevel = HWLog.LogLevel.DEBUG;

    // Log Connection Config:
    // static config = () => { return { headers: { "Authorization": "Bearer " + this.accessToken } } };

    static error = (error, message) => {
        if ([HWLog.LogLevel.DEBUG, HWLog.LogLevel.ERROR].indexOf(this.logLevel) !== -1) this.log("ERROR", message, error);
    }

    static info = (message, logParams) => {
        if ([HWLog.LogLevel.DEBUG, HWLog.LogLevel.INFO].indexOf(this.logLevel) !== -1) this.log("INFO", message, logParams);
    }

    static warn = (message, logParams) => {
        if ([HWLog.LogLevel.DEBUG, HWLog.LogLevel.WARN].indexOf(this.logLevel) !== -1) this.log("WARN", message, logParams);
    }

    static log(logType, message, logParams) {
        try {
            let data = { logType: logType, message: message, time: new Date().toLocaleTimeString() }
            if (logParams) { data.logParams = JSON.stringify(logParams); }

            // Log Server: 
            // let url  = domainName + "/logs";
            // axios.post(url, data, this.config());

            if (this.logLevel === HWLog.LogLevel.DEBUG) {
                console.log(data);
                // window.postMessage(JSON.stringify(data), window.origin);
            }
        } catch (error) { console.log(" Log Error: " + error); }
    }

}

export default HWLog;