import { Button, FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup, TextField } from '@material-ui/core';
import { classnames, convertRowsPropToState } from '@material-ui/data-grid';
import { PanToolSharp, TextFields } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { Collapse } from 'bootstrap';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState, useEffect, Fragment , useCallback } from 'react';
import { useHistory  } from "react-router-dom";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { People } from '@material-ui/icons';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import HWMessage from 'HWLibrary/HWMessage';
import BlockUi from 'react-block-ui';
import merchantApiService from 'views/Merchant/APIService';
import userSegmentApiService from 'views/UserSegment/APIService';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import InfoDialog  from 'views/Utils/InfoDialog';
import PositionedSnackbar from 'views/Utils/SnackBars';
import StatusDialog from 'views/Utils/StatusDialog';
import { HWInput } from 'HWLibrary/HWInput';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';

const useStyles = makeStyles (theme => ({
    ...styles,
    card : { borderRadius : 10 },
    gridItem : { marginTop : 10 },
    draftButton : { background : '#FF9017', color : 'white', marginRight : 5  },
    proceedButton : { background : '#138DE8', color : 'white' },
    cardFooter : { justifyContent : 'center' },
    autoComplete : { width : 500 },
    select : {
        '& .MuiSelect-outlined': { borderColor: '#138DE8' }
    },
    badgeColor :{
        '& .MuiBadge-colorPrimary': { backgroundColor: "#FF9017"}
    },
    
}));

export default function CampaignSelection (props) {

    var campaignarray=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    campaignarray= [...obj["Campaigns"]];

    const classes = useStyles ();
    const history = useHistory ();
    HWLocalStorage.save (JSON.stringify(props.currentCampaign), "currentCampaign");

    const { currentCampaign, setCurrentCampaign, steps } = props;

//     useEffect (() => {
//         if (HWLocalStorage.load('currentCampaign') !== null)
//     {
//         setCurrentCampaign(JSON.parse(HWLocalStorage.load('currentCampaign')));
//     }
//  }, [ ]);
    
    const [ type, setType ] = useState (currentCampaign.type || 'Advertisement');
    const [ userType, setUserType ] = useState (props && props.currentCampaign.userSegment && props.currentCampaign.userSegment.userType);
    const [ segmentLoading, setSegmentLoading ] = useState (false);
    const [ segmentList, setSegmentList ] = useState ([]);
    const [ check, setCheck ] = useState (false);
    const [ openMerchantSelection, setOpenMerchantSelection ] = useState (false);
    const [ searchType, setSearchType ] = useState (null);
    const [ merchantList, setMerchantList ] = useState ([]);
    const [ listLoading, setListLoading ] = useState (false);
    const [ valid, setValid ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const [ merchantInfo, setMerchantInfo ] = useState ('');

     console.log("Current Campaign", currentCampaign);
    console.log("Propsing", props, props.location);

    const checkDisabled = () => {
  

      //  alert (JSON.stringify(props.currentCampaign));
        if (props.campaignMode === false) {
            if (props.currentCampaign && props.currentCampaign.status && props.currentCampaign.status === 'Draft') {
                return false;
            } else if (props.currentCampaign && props.currentCampaign.status && props.currentCampaign.status === 'Enabled') {
                if (props.campaignType === 'Live' ) 
                    return true;
            } 
        } else {
            return false;
        }
    }

    useEffect (() => {
        setSegmentLoading (true);
        userSegmentApiService.fetchSegmentList ()
        .then (response => { 
            if (! response || ! response.data) { return HWMessage.show ("Data cannot be fetched at moement", "error"); }
            setSegmentList (response.data.userSegments);     
            console.log(response.data);
        }).catch (error => { HWMessage.show ("Error while fetching the data","error"); })
        .finally (() => setSegmentLoading (false));
}, [ ]);




    useEffect (() => {
        
        var fetchLimit = 1000;
        var paginationBookmark = 0;
        setListLoading (true);
        merchantApiService.fetchMerchants (fetchLimit, paginationBookmark, null, null, null)
        .then(response => { 
            if (! response || ! response.data) { return HWMessage.show ("Merchant List cannot be fetched. Please refresh again for events and merchant events", "error"); }  
            setMerchantList (response.data.merchantList);
         }).catch (error => { } )
        .finally (() => setListLoading (false));
    }, []); 

    // useEffect (() => {
    //     props.setLoading (true);
    //     userSegmentApiService.fetchUserSegments ().then (response => {
    //         if (! response || ! response.data) { return HWMessage.show ("Segments unavaiable, please try again"); }
    //         setSegmentList (response.data.userSegments.map(i => i.segmentName));
    //         console.log("Response", response.data.userSegments);
    //     }).catch (error => { 
    //         return HWMessage.show ("Unable te fetch the user segment list now.");
    //      }).finally (() => { props.setLoading(false); });

    //     segmentList.unshift('None'); segmentList.unshift(''); setSegmentList(segmentList); 
    // }, []);

    const changeCampaignType = event => {  
        console.log("Campaign Type", event, event.target, currentCampaign.type);
        // salert (JSON.stringify(currentCampaign));
    };
    const changeUserType = event => { 
        setUserType (event.target.value); 
        if (event.target.value === 'UserSegment') { 
            setCheck (true);  
            props.setCurrentCampaign (prev => ({ ...prev, userSegment : { ...prev.userSegment, userType : 'UserSegment' } }));
        }
        else {
            setCheck (false); 
            props.setCurrentCampaign (prev => ({ ...prev, userSegment : { ...prev.userSegment, userType : 'All', userSegmentId : null } }));
        }
    };

    const saveDraft = event => { 
        if (!currentCampaign || currentCampaign === null) { return HWMessage.show ("Please enter one or more detail to save as campaign", "warning"); }
       // props.saveDraft ();
        history.push('/ops/campaigns');
    };

    const handleDraftClick = (event) => {
        props.setOpen(true);
        //saveDraft (event); 
    }

    
    const validateConfiguration = (currentCampaign) => { 
        console.log(currentCampaign);
        if(props.campaignMode === true)
        {
            if(currentCampaign && currentCampaign.campaignCode && currentCampaign.campaignCode!==''  && currentCampaign.campaignCode!== null  && currentCampaign.userSegment && (currentCampaign.userSegment.userType && currentCampaign.userSegment.userType==="All"|| (currentCampaign.userSegment.userType && currentCampaign.userSegment.userType==="UserSegment" && currentCampaign.userSegment.segmentId && (currentCampaign.userSegment.segmentId!== null || currentCampaign.userSegment.segmentId!== undefined) )))
            { 
                 return true;  
            } 
            else{
                return false;
            }
        }
        switch(currentCampaign.type)
        {
            case 'Advertisement' : 
            if (  (props.campaignMode === false && currentCampaign && currentCampaign.campaignCode && currentCampaign.campaignCode!=='' && currentCampaign.campaignCode!==null  ) ||  (currentCampaign && currentCampaign.campaignCode  && currentCampaign.userSegment &&  currentCampaign.userSegment.userType === 'All' || currentCampaign.userSegment && currentCampaign.userSegment.userType === 'UserSegment') )
            {
                 return true ;
            } 
            break;
            case 'GenBluStore' : 
            if ((props.campaignMode === false  && currentCampaign && currentCampaign.campaignCode && currentCampaign.campaignCode!=='' && currentCampaign.campaignCode!==null ) || currentCampaign.userSegment && currentCampaign.userSegment.userType === 'All' ||currentCampaign && currentCampaign.campaignCode!=='' && currentCampaign.campaignCode!==null  && currentCampaign.userSegment && currentCampaign.userSegment.userType === 'UserSegment') 
            {
                 return true ;
            } 
            break;
            case 'Event':
            if ((props.campaignMode === false  && currentCampaign && currentCampaign.campaignCode!=='' && currentCampaign.campaignCode!==null   && props.currentCampaign.eventSchedule && props.currentCampaign.eventSchedule.merchantId ) || (currentCampaign && currentCampaign.campaignCode!==''   && currentCampaign.campaignCode!==null && currentCampaign.userSegment && currentCampaign.userSegment.userType === 'All' || currentCampaign && currentCampaign.campaignCode!=='' && currentCampaign.campaignCode!==null && currentCampaign.userSegment && currentCampaign.userSegment.userType=== 'UserSegment' && currentCampaign.userSegment.segmentId && currentCampaign.userSegment.segmentId!== null ) && currentCampaign.campaignEventConfig && currentCampaign.campaignEventConfig.merchantId  )
            {
                return true;
            }   
            break;
            case 'MerchantEvent' : 
            if ( (props.campaignMode === false  && currentCampaign && currentCampaign.campaignCode &&  currentCampaign.campaignCode!=='' && currentCampaign.campaignCode!==null &&  props.currentCampaign.eventSchedule && props.currentCampaign.eventSchedule.merchantId ) || (currentCampaign && currentCampaign.campaignCode!=='' && currentCampaign.campaignCode!==null && currentCampaign.userSegment && currentCampaign.userSegment.userType === 'All' || currentCampaign && currentCampaign.campaignCode!=='' && currentCampaign.campaignCode!==null && currentCampaign.userSegment && currentCampaign.userSegment.userType === 'UserSegment'  && currentCampaign.userSegment.segmentId && currentCampaign.userSegment.segmentId!== null) && currentCampaign.campaignMerchantEventConfig && currentCampaign.campaignMerchantEventConfig.merchantId  )
            {
                return true;
            }   

             break;
        }
        return false; 
    }

    useEffect (() => {
        if (merchantList.length > 0 && currentCampaign && currentCampaign.eventSchedule && currentCampaign.eventSchedule.merchantId )
        {
           var info = merchantList.filter(i => i.id === currentCampaign.eventSchedule.merchantId )[0];
           console.log(info); 
           if((info!=='' || info!==undefined || info!==null) && (info.code!=='' || info.code!==undefined || info.code!==null) && (info.entityName!=='' || info.entityName!==undefined || info.entityName!==null) )
           {
            setMerchantInfo (`${ info.code} ( ${info.entityName} )`);
           }
        }
    }, [ merchantList, currentCampaign ]);

    const saveProceed = event => { 
        let validation = validateConfiguration (currentCampaign);
        console.log("Curerent state of campaign ", props, props.currentCampaign );
        if(props.campaignMode === true){
            if (props.campaignCodes.includes(currentCampaign.campaignCode)) { return HWMessage.show ("Campaign Code is already taken" ,"warning"); }
            else { 
                if(validation === true)
            {
               props.advanceStep();
            }
            else{
                 HWMessage.show ("One or more fields contain errors please rectify" , "error");
            }
            }

        }
        else{
            if (validation === false) { return HWMessage.show ("One or more fields contain errors please rectify" , "error"); }
       
            else { props.advanceStep(); }
        }
        
    };
    const handleBack = event => { props.retreatStep (); }

    const changeSearchType = event => { setSearchType (event.target.value); };
    const fetchMerchantList = () => { return searchType === 'code' ? merchantList.filter (i => i.code != null).map(i => i.code) : merchantList.filter(i => i.entityName != null).map(i => i.entityName); };

    console.log("Current campaign status", currentCampaign, currentCampaign.type);
    console.log("Props...." , history );
    return (
    <Fragment>
            <BlockUi blocking = { props.loading || segmentLoading  } message = "Please wait" >
                <Card>
                    <CardHeader className = { classes.cardHeader } color = 'rose' icon >
                            <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                                <h4 className = { classes.cardIconTitle } > Campaign Selection  </h4> 
                    </CardHeader>

                    <CardBody>
                        <GridContainer>
                            {  ((props.currentCampaign && props.currentCampaign.type)  || props.campaignMode === true ) &&
                                <GridItem xs = {12} md = {12} style = {{ marginTop : 10 }} >  

                                        <FormControl component = "fieldset" >
                                            <GridContainer>
                                                <GridItem> <FormLabel component = "legend" style = {{ marginBottom : 0 }} > Campaign Type </FormLabel> </GridItem> 
                                                <GridItem xs = {12} md = {12} sm = {12} >  
                                                    <RadioGroup  row aria-label = "campaign-type" name = "type" value = { props.currentCampaign.type } > 
                                                        <FormControlLabel 
                                                            value = "Advertisement" 
                                                            control = { 
                                                                <Radio 
                                                                disabled= {props.campaignMode === true  ? false : true}

                                                                    name = "Advertisement" 
                                                                    style={{color :"#138DE8"}}
                                                                    onChange = { event => { 
                                                                        if (event.target.checked)  {
                                                                            props.setCurrentCampaign(campaign => ({ ... campaign, type : 'Advertisement', adConfig : {} })); 
                                                                            props.setSteps (prev => prev.filter(i => i !== 'Points Rule'));
                                                                            setValid(true);
                                                                        }    
                                                                    } } 
                                                                />  
                                                            } label = { 'Advertisement' } 
                                                        />  
                                                        <FormControlLabel 
                                                            value = "GenBluStore" 
                                                            control = { 
                                                                <Radio 
                                                                disabled= {props.campaignMode === true ? false : true}

                                                                style={{color :"#138DE8"}}
                                                                    name = "GenBluStore" 
                                                                    onChange = { event => { 
                                                                        if (event.target.checked) {
                                                                            props.setCurrentCampaign (campaign => ({ ... campaign, type : 'GenBluStore', genbluEcommerceConfig : {} }));  
                                                                            props.setSteps (steps);
                                                                            setValid(true);
                                                                        }     
                                                                    } 
                                                                    } 
                                                                />  
                                                            } 
                                                            label = { 'Genblu Online Shop Campaigns' } 
                                                        />  
                                                        <FormControlLabel 
                                                            value = "Event" 
                                                            
                                                            control = { 
                                                                <Radio 
                                                                disabled= {props.campaignMode === true ? false : true}

                                                                    name = "Event" 
                                                                    style={{color :"#138DE8"}}
                                                                    onChange = { 
                                                                        event => { 
                                                                            props.setCurrentCampaign (campaign => ({ ... campaign, type : 'Event', campaignEventConfig : {}})); 
                                                                            setOpenMerchantSelection(true); 
                                                                            props.setSteps (steps);
                                                                            setValid(true);
                                                                        }
                                                                    } 
                                                                />  
                                                            } 
                                                            label = { 'A & P Events' } 
                                                        />  
                                                        <FormControlLabel 
                                                            value = "MerchantEvent" 
                                                            control = { 
                                                                <Radio 
                                                                    disabled= {props.campaignMode === true ? false : true}
                                                                    name = "MerchantEvent" 
                                                                    style={{color :"#138DE8"}}
                                                                    onChange = { event => { 
                                                                        props.setCurrentCampaign (campaign => ({ ...campaign, type : "MerchantEvent", campaignMerchantEventConfig : {}})); 
                                                                        setOpenMerchantSelection (true); 
                                                                        props.setSteps (steps);
                                                                        setValid(true);
                                                                    } 
                                                                } 
                                                                />  
                                                            } 
                                                            label = { 'Merchant Events' } 
                                                        />  
                                                    </RadioGroup>    
                                                </GridItem> 
                                            </GridContainer>
                                        </FormControl>  
                            </GridItem>
                            }
                            {
                                ((props && props.currentCampaign && props.currentCampaign.userSegment && props.currentCampaign.userSegment.userType) || props.campaignMode === true ) &&
                                <GridItem xs = {12} md = {12} className = { classes.gridItem } >  
                                <GridContainer >
                                    <GridItem xs = {12} md = {6} >
                                        <FormControl component = "fieldset" >
                                            <GridContainer>
                                                <GridItem xs = {12} md = {12} sm = {12} > <FormLabel component = "legend" style = {{ marginBottom : 0 }}  > User Type </FormLabel> </GridItem> 
                                                <GridItem  >  
                                                    <RadioGroup style={{color :"#138DE8"}} row aria-label = "user-type" name = "userType" value = { (props.currentCampaign && props.currentCampaign.userSegment && props.currentCampaign.userSegment.userType) ? props.currentCampaign.userSegment.userType : "" } onChange = { changeUserType }  > 
                                                        <FormControlLabel value = "All" control = { <Radio style={{color :"#138DE8"}} />  } label = { 'All Users' } />  
                                                        <FormControlLabel value = "UserSegment" control = { <Radio style={{color :"#138DE8"}}/>  } label = { 'User Segment' } />  
                                                    </RadioGroup>    
                                                </GridItem> 
                                            </GridContainer>
                                        </FormControl>  
                                    </GridItem>
                                    { userType === 'UserSegment' && <GridItem xs = {12} md = {6} >
                                        <Autocomplete 
                                        
                                            className = { classes.autoComplete }
                                            options = { segmentList } 
                                            getOptionLabel={(option) => {
                                                return (option && option.name && option.name !== null) ? option.name : '' ;
                                            }}
                                            value={currentCampaign && currentCampaign.eventSchedule && currentCampaign.eventSchedule.merchantId }
                                            onChange = { (event, value, reason) => {
                                                // alert(JSON.stringify(value) + " " +reason);
                                                if (reason === "select-option") {
                                                    let list = segmentList;
                                                    let userSegmentId = value.id;

                                                    
                                                    if (userSegmentId !== null) {  setCurrentCampaign (prev => ({ ...prev, userSegment : { ...prev.userSegment, segmentId : userSegmentId  , userType : 'UserSegment' }  })) }
                                                }
                                            } }
                                            style = {{ width : 300, marginTop : 25 }} 
                                            renderInput = { params => <TextField { ...params } label = { 'Segment Selector' } onChange = { event => { segmentList.shift(); segmentList.unshift(event.target.value); setSegmentList (segmentList); } }  variant = 'outlined' /> } 
                                        />
                                        </GridItem>}
                                    </GridContainer>    
                                </GridItem>
                            
                            }
                            <GridItem xs = {12} md = {12} sm = {12} style = {{ marginTop : 10 }} >
                                { props.campaignMode === true  &&  props.currentCampaign && props.currentCampaign.type && (props.currentCampaign.type === 'MerchantEvent' || props.currentCampaign.type === 'Event')  && 
                                <>
                                    {/* <span style = {{ color : 'red' }} > Merchant selection is required </span>  */}
                                    <FormControl component = "fieldset" >
                                        <GridContainer>
                                            <GridItem xs = {12} md = {12} sm = {12} > <FormLabel component = "legend" style = {{ marginBottom : 0 }}  > Search Using </FormLabel> </GridItem> 
                                            <GridItem  >  
                                                <RadioGroup style={{color :"#138DE8"}} row aria-label = "search-type" name = "searchType" value = { searchType } onChange = { changeSearchType }  > 
                                                    <FormControlLabel value = "code" control = { <Radio style={{color :"#138DE8"}}  />  } label = { 'Select using Merchant Code' } />  
                                                    <FormControlLabel value = "name" control = { <Radio  style={{color :"#138DE8"}}  />  } label = { 'Select using Merchant Name' } />  
                                                </RadioGroup>    
                                            </GridItem> 
                                        </GridContainer>
                                    </FormControl>  
                                    { searchType && searchType !== null && merchantList != null &&
                                        <Autocomplete 
                                            
                                            className = { classes.autoComplete }
                                            options = { fetchMerchantList() } 
                                            onChange = { (event, value, reason) => { 
                                                    if (reason === "select-option") {
                                                        let list = [];
                                                        if (searchType === "name") { list = merchantList.filter(i => i.entityName === value); } 
                                                        if (searchType === "code") { list = merchantList.filter(i => i.code === value); }
                                                        var merchantId = null;
                                                        if (list && list.length > 0) { merchantId = list[0].id;  }
                                                        if (props.currentCampaign && props.currentCampaign.type === 'Event') 
                                                                props.setCurrentCampaign(prev => ({ ...prev, eventSchedule : { ...prev.eventSchedule && prev.eventSchedule, merchantId }, campaignEventConfig : { ...prev.campaignEventConfig, merchantId } }));
                                                        else if (props.currentCampaign && props.currentCampaign.type === 'MerchantEvent') 
                                                                props.setCurrentCampaign (prev => ({ ...prev, eventSchedule : { ...prev.eventSchedule && prev.eventSchedule, merchantId }, campaignMerchantEventConfig : { ...prev.campaignMerchantEventConfig, merchantId } }));    
                                                    } 
                                                } 
                                            }
                                            renderInput = { params => <TextField { ...params } label = { searchType === 'name' ? 'Search Merchant By Name' : 'Search Merchant By Code' }   variant = 'outlined' /> } 
                                        />  
                                    }
                                </>
                            }
                            </GridItem>
                            {props.currentCampaign && props.currentCampaign.type && props.currentCampaign.type!=='Advertisement' &&  props.currentCampaign.type!=='GenBluStore' &&  <GridItem xs = {12} md = {12} sm = {12} >
                                <h4> Selected Merchant : { merchantInfo }  </h4>
                            </GridItem>}
                            <GridItem xs = {12} md = {6} sm = {12} >
                            <HWInput label="Campaign Code" name = "campaignCode" disabled={props.campaignMode === true ? false: true} value={(props.currentCampaign && props.currentCampaign.campaignCode) ? props.currentCampaign.campaignCode : "" } onBlur = { e => props.setCurrentCampaign(prev => ({ ...prev, campaignCode :  e.target.value  } ))}    />
                            <br/>
                            </GridItem >
                             {/* <ConfirmationDialog open= { open } setOpen={setOpen} confirmingAction={saveDraft} dialogTitle={"sadasdas"} dialogMessage= {"asdasdasd"} /> */}
                             {/* <InfoDialog open= { open } setOpen={setOpen} confirmingAction={saveDraft} dialogTitle={"sadasdas"} dialogMessage= {"asdasdasd"}  /> */}
                             {/* <PositionedSnackbar open= { open } setOpen={setOpen} confirmingAction={saveDraft} message={"Nobody does it better than Shivam Sir"} status={"success"} /> */}
                             {/* <StatusDialog isLoading = {true} open= { open } setOpen={setOpen}  sucessStatus={"success"} message={"sadasa"} dialogTitle={"asdasdasd"}/> */}

                            <GridItem style = {{ marginTop : 10 }} xs = { 12 } md = {12} >
                            
                                    <center> 
{ campaignarray.includes("Action") === true && props.campaignType !== 'Expired' &&  <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} disabled={  checkDisabled()  }  onClick = { handleDraftClick }  > Save as Draft </Button> 

}
{ props.campaignType === 'Expired' &&  <Button variant = "contained" style = {{ backgroundColor : '#138de8', color : 'floralwhite', textTransform : 'none' }}  onClick = { e => history.push('/ops/campaigns')  } > Close </Button>}

                                        <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none',marginLeft:10 }} disabled={ props.campaignMode === false ? false : props.currentStep < props.prevStep &&  !valid ? false : !valid}  onClick = { saveProceed } > Proceed </Button>
                                    </center>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    <CardFooter>

                    </CardFooter>    
                </Card>
            
            </BlockUi>
        </Fragment>
        
    )
};