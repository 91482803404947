import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import CustomInput from 'components/CustomInput/CustomInput';
import { makeStyles } from '@material-ui/core';

const HWInput = (props) => {
    const [value, setValue] = useState(props.value || '');
    const onChange = (event) => { setValue(event.target.value); };
    
    useEffect(() => { setValue(props.value); }, [props]);

    return (
        <CustomInput labelText={props.label} inputProps={{...props, ...props.inputProps, value, onChange}} helperText={props.helperText}
            formControlProps={{ fullWidth: true, error: props.hasError , required: props.required}} labelProps = {{...props.labelProps}}  required = {props.required}  />
    );
}

export { HWInput };