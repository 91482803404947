import { Button, makeStyles , TextField } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from 'HWLibrary/HWInput';
import pointsApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import { useCallback } from 'react';
import BlockUi from 'react-block-ui';
import { DropDown } from 'views/Merchant/Merchant';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import { DropzoneDialog } from 'material-ui-dropzone';
import settingsApiService from './APIService';
import { Autocomplete } from '@material-ui/lab';
import MultiSelectDropDown from 'views/Merchant/MultiSelectDropDown';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import Edit from '@material-ui/icons/Edit';
import StatusDialog from 'views/Utils/StatusDialog';

const useStyles = makeStyles (theme => ({
    ...styles,
    saveButton :  { color: 'floralwhite', backgroundColor : '#138DE8' , margin: 2.5 },
    cancelButton : { color: 'floralwhite', backgroundColor : '#FF9017' , margin: 2.5},
    card : { width : 700 }
}));

export default function RuleForm (props) {

    var settings=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    settings= [...obj["Settings"]];

    const classes = useStyles();
    const [openSingle, setOpenSingle] = React.useState(false);

    const [ currentRule, setCurrentRule ] = useState ({});
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ productCategoryList, setProductCategoryList ] = useState ([]);
    const [ tierList, setTierList ] = useState ([]);
    const { createMode, ruleId } = props;
    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});
    const [ saveOpen, setSaveOpen ] = useState (false);
    const [ createOpen, setCreateOpen ] = useState (false);
    const [ rulesLoading, setRulesLoading ] = useState (false);
    const [ isLoading , setIsLoading ] = useState (false);
    const [ edit , setEdit] = useState(false);
    const [ open2 , setOpen2] = useState(false);
    const [ open3 , setOpen3] = useState(false);

    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    };

    const checkErrorS = field => {
      
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(field).toLowerCase());
    
        
};

    console.log(currentRule);

   


    const isValid = rule => {
        let { validityStart, validityEnd } = rule;
        var startDate = new Date (validityStart);
        var endDate = new Date (validityEnd);
        var currentDate = new Date();
        
        return ! (endDate >= currentDate);
    }; 
    const isDisabled = false;

    useEffect (() => {
        if(props.createMode == false){
            setRuleLoading (true);
            settingsApiService.fetchSpecificUser (props.ruleId)
            .then (response => {  
                if (! response || ! response.data) { return HWMessage.show ("Specific Voucher Cannnot be fetched","error"); }
                setCurrentRule (response.data); 

                setProductCategories(response.data.roles);
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => setRuleLoading (false));
        }
       
    }, [])

  


    const updateRule = useCallback (rule => {
        if (rule !== null) {
            let successStatus = null;
            setRuleLoading (true);
            var x= [];
            for(var i = 0; i < productCategories.length ; i++)
            {  for(var j=0;j< props.List.length ;j++){
                    if(props.List[j].roleName ===productCategories[i] )  { 
                        if(x.indexOf(props.List[j].roleName ) === -1)
                             x.push(props.List[j]);
                             } }}
            rule = {...rule , roleIds : x.map(i => i.id) };
            settingsApiService.updateUser (rule) 
            .then (response => {  
                if (! response || ! response.data) { HWMessage.show ("Error while fetching response","error"); }
                setCurrentRule (response.data);
                setOpen3(true);
                successStatus = true;
                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);  
                if (successStatus === true)
                    {
                        props.setDirectActionOpen(false);
                        props.setRefreshState(prev=>!prev)
                    }
            });
        }
    });
    const createRule = useCallback (rule => {
        if (rule !== null)  {
            let successStatus = null;
            setRuleLoading (true);
            var x= [];
            for(var i = 0; i < productCategories.length ; i++)
            {  for(var j=0;j< props.List.length ;j++){
                    if(props.List[j].roleName ===productCategories[i] )  { 
                        if(x.indexOf(props.List[j].roleName ) === -1)
                             x.push(props.List[j]);
                             } }}
            rule = {...rule , roleIds : x.map(i => i.id) };
            settingsApiService.createUser (rule)
            .then (response => {
                if (! response || ! response.data) { HWMessage.show ("Error while fetchinmg response","error"); }
                setCurrentRule (response.data);
                props.setRefreshState (prev => !prev);
                successStatus = true;
                setOpen2(true);
                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);
                if (successStatus === true)
                    {
                        props.setDirectActionOpen(false) }
            });
        }
    });
    const [ defaultCategory, setDefaultCategory ] = useState ("");
    const [ productCategories, setProductCategories ] = useState ( props.createMode === true ? [] : currentRule && [currentRule.roles]);

    
    const checkDisabled = () => {
      if(props.createMode === true) 
      {
        if (currentRule && currentRule.name &&  currentRule.name!=='' &&  currentRule.emailAddress &&  currentRule.emailAddress!==''  && 
        productCategories.length > 0 && checkErrorS (currentRule && currentRule.emailAddress) )
            return false;
        else
            return true ; 
      }

      else{
        if (currentRule && currentRule.name &&  currentRule.name!=='' &&  currentRule.emailAddress &&  currentRule.emailAddress!==''  && 
        productCategories.length > 0 &&  currentRule.status &&  checkErrorS (currentRule && currentRule.emailAddress) )
            return false;
        else
            return true ; 
      }

    };

    const captureChanges = event => { 
        const field = event.target.name;
        const value = event.target.value;
        console.log(field,value);

        setCurrentRule (prev => ({ ...prev, [field] : value }));

    };
    const handleSave = () => {
        if (props.createMode === true) { createRule (currentRule); } 
        else if (props.createMode === false) { updateRule (currentRule); }
    };
    const handleCancel = () => { props.setOpen (prev => false); props.setDirectActionOpen(false) };

    return (
        <>         <BlockUi blocking = { ruleLoading } message = { 'Please wait while we fetch.' } >

                <Card className = { classes.card } >
{                props.createMode === true  &&      <CardHeader style={{ margin : 0 , padding : 5}}> <center> <h3> User Configuration  </h3>   </center>   </CardHeader>
}        
{                props.createMode === false  &&      <CardHeader style={{ margin : 0 , padding : 5}}> <h3> User Configuration  &nbsp; <a style={{cursor: "pointer"}}><Edit style={{color: "#138de8"}} onClick={e=>setEdit(!edit)} /></a>  </h3>      </CardHeader>
}  
            <CardBody>  
                        <GridContainer>
                        <GridItem xs = {6} md = {12} sm = {6} >  
                            <center>
                            <HWInput label = { 'Name' }  
                                                    name = { 'name' } 
                                                     hasError = { checkError ('name') } 
                                                    helperText = { fetchErrorMessage ('name') }
                                                    onBlur = {captureChanges }
                                                    value={currentRule && currentRule.name}
                                                    disabled = {props.createMode === false ? !edit : false}
                                                    required = {true}
                                                /> 

                            </center>
                               
                            </GridItem>

                            <GridItem xs = {6} md = {12} sm = {6} >  
                            <center>
                            <HWInput label = { 'Email Address' }  
                                                    name = { 'emailAddress' } 
                                                     hasError = { currentRule && currentRule.emailAddress && !checkErrorS (currentRule && currentRule.emailAddress) ? true :  false } 
                                                    helperText = { currentRule && currentRule.emailAddress && !checkErrorS (currentRule && currentRule.emailAddress) ?  "Invalid Email" :  "" }
                                                    onBlur = { captureChanges }
                                                    value={currentRule && currentRule.emailAddress}
                                                    disabled = {props.createMode === false ? !edit : false}
                                                    required = {true}
                                                /> 

                            </center>
                               
                            </GridItem>
                            

                            <GridItem xs = {6} md = {12} sm = {6} >  
                             <br/>
                            </GridItem>

                            <GridItem xs = {6} md = {6} sm = {6} >  
                            
                       <MultiSelectDropDown
                                        names = { props.List.map(i => i.roleName) }
                                       // defaultCategory = { productCategories }
                                        setDefaultCategory = { e => { console.log("Hello"); } }
                                        values = { productCategories }
                                        setValues = { setProductCategories }
                                        hasError = {(props && props.createMode === false &&  productCategories && productCategories.length === 0) || (props && props.createMode === true && productCategories && productCategories.length === 0 && currentRule && currentRule.name) }
                                        errorMessage = {props && props.createMode === false && productCategories && productCategories.length === 0 && "Please select one or more role" }
                                        label = { 'Roles' }
                                         disabled = {props.createMode === false ? !edit : false}

                                         required = {true}
                                        />

                                
                            </GridItem>
                        
                           { props.createMode === false && <GridItem xs = {6} md = {6} sm = {6} >  
                           
                           <DropDown 
                                   labelCategory = "Status" 
                                   name = "status"
                                   values = { ['Active' , 'Inactive' ] }
                                   minWidth = {300}
                                   targetValue = { currentRule && currentRule.status }
                                   onChange = { e => { setCurrentRule(prev => {  prev.status = e.target.value;  return { ...prev }; });  } }
                                   onBlur = { captureChanges }
                                   helperText = { fetchErrorMessage ('status') }
                                   hasError = { checkError ('status') }
                                   disabled = {props.createMode === false ? !edit : false}
                                   required = {true}
                                   />  

                           </GridItem>}
                           <GridItem xs = {6} md = {12} sm = {6} >  
                             <br/>
                            </GridItem>
                            
                            <GridItem xs = {12} sm = {12} md = {12} >
                                {  <center>
{ settings.includes("Action") === true &&  props.createMode === false && edit === true &&  <Button disabled={ checkDisabled()} onClick = { e => props.createMode === true ? setCreateOpen (true) : setSaveOpen (true) } raised variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} > { props.createMode === true ? 'Create User' : 'Update User' } </Button>
}         
{ settings.includes("Action") === true &&  props.createMode === true && <Button disabled={ checkDisabled()} onClick = { e => props.createMode === true ? setCreateOpen (true) : setSaveOpen (true) } raised variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} > { props.createMode === true ? 'Create User' : 'Update User' } </Button>
}  

                           <Button onClick = { handleCancel } raised variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} > Cancel </Button>
                                    <ConfirmationDialog open = { createOpen } setOpen = { setCreateOpen } confirmingAction = { handleSave } dialogMessage = { 'Are you sure you want to create this user' } dialogTitle = { 'Confirm Rule Creation' } confirmingAction = { handleSave } />
                                    <ConfirmationDialog open = { saveOpen } setOpen = { setSaveOpen } confirmingAction = { handleSave } dialogMessage = { 'Are you sure want to update the user.' } dialogTitle = { 'Confirm Rule Updation' } confirmingAction = { handleSave } />
                                    <StatusDialog isLoading = {false} open= { open3 } setOpen={setOpen3}  sucessStatus={"success"} confirmingAction={e=>props.setOpen (false)} message={"User successfully updated."} dialogTitle={"Status"}/> 
                                    <StatusDialog isLoading = {false} open= { open2 } setOpen={setOpen2}  sucessStatus={"success"} confirmingAction={e=>props.setOpen (false)} message={"User successfully created."} dialogTitle={"Status"}/> 

                                </center>}
                            </GridItem>
                        </GridContainer>
                    </CardBody> 
                </Card>
                </BlockUi>
        </>
    );

};