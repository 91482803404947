import HWLocalStorage from "HWLibrary/HWLocalStorage";
import HWMessage from "HWLibrary/HWMessage";
import { useState } from "react";
import settingsApiService from "views/Settings/APIService";



class LoginService {
  overallPermission={};
  x=1

 
  static  fetchPermissions = (id) => {
        settingsApiService.fetchSpecificRoles(id)
            .then (response => {  
                if (! response || ! response.data) { return HWMessage.show ("Specific Role Cannnot be fetched","error"); }
                console.log(response.data);
                     var roleName = response.data.roleName;
                     var Permissions = response.data.permissions;
                     console.log(Permissions);
                     for(var i=0;i<Permissions.length;i++)
                     {
                        
                         var name=Permissions[i].permitName;
            
                         if( Permissions[i].permissionAllowed.includes("Action") || (this.overallPermission[name].includes("Action")))
                         {
                            this.overallPermission[name] = ["View","Action"]
                            console.log(this.overallPermission[name]);
                          }
                         else if( Permissions[i].permissionAllowed.includes("View") || (!(this.overallPermission&&this.overallPermission[name]) || this.overallPermission[name]!==null?this.overallPermission[name].includes("View") :true))
                         {
                            this.overallPermission[name]= ["View"];
                            console.log(this.overallPermission[name]);
                         }

                     }
                     const namWe = localStorage.getItem('Permissions');
                            if(namWe){
                              localStorage.removeItem("Permissions")
                                HWLocalStorage.save(  JSON.stringify(this.overallPermission) , "Permissions");
                            }else{
                              HWLocalStorage.save(  JSON.stringify(this.overallPermission) , "Permissions"); 
                            }
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
          })
            .finally (() => {});
        }
       
  
  static userInfoStorageKey = () => { return "ops-user"; };

  static permissions = () => {
    var currentPermissions = localStorage.getItem('Permissions');
    if(currentPermissions){
       this.overallPermission=JSON.parse(JSON.parse(currentPermissions));
      console.log(currentPermissions)
    }
    else{
      this.overallPermission={Members:[],Actions:[],Campaigns:[],Points:[],Notifications:[],Merchants:[],UserSegments:[],Vouchers:[],Settings:[],Cards:[] };
    }
    let cachedUser = HWLocalStorage.load(this.userInfoStorageKey());
      if (!cachedUser) { return null; }
      console.log(cachedUser);
      console.log(this.overallPermission) 
          for(var i=0;i<cachedUser.roleIds.length;i++){
            console.log(cachedUser.roleIds[i]);
            LoginService.fetchPermissions(cachedUser.roleIds[i]);
            console.log(this.overallPermission)
          }
     }
    }
 
export default LoginService;