import { Button, InputAdornment, makeStyles , TextField } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from 'HWLibrary/HWInput';
import pointsApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import { useCallback } from 'react';
import BlockUi from 'react-block-ui';
import { DropDown } from 'views/Merchant/Merchant';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import { DropzoneDialog } from 'material-ui-dropzone';
import settingsApiService from './APIService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import Edit from '@material-ui/icons/Edit';
import StatusDialog from 'views/Utils/StatusDialog';
const useStyles = makeStyles (theme => ({
    ...styles,
    saveButton :  { color: 'floralwhite', backgroundColor : '#138DE8' , margin: 2.5 },
    cancelButton : { color: 'floralwhite', backgroundColor : '#FF9017' , margin: 2.5},
    card : { width : 700 }
}));

export default function RuleForm (props) {


    var settings=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    settings= [...obj["Settings"]];


    const classes = useStyles();
    const [openSingle, setOpenSingle] = React.useState(false);

    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ productCategoryList, setProductCategoryList ] = useState ([]);
    const { createMode, ruleId } = props;
    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});
    const [ saveOpen, setSaveOpen ] = useState (false);
    const [ createOpen, setCreateOpen ] = useState (false);
    const [ refreshState , setRefreshState] = useState (false);
    const [ page, setPage ] = useState (1);
    const [ currentRule, setCurrentRule ] = useState ({});
    const [ pageSize, setPageSize ] = useState (100);
    const [tiers, setTiers] = useState([])
    const [ edit , setEdit] = useState(false);
    const [ open3 , setOpen3] = useState(false);
    const [ open2 , setOpen2] = useState(false);
    const [ open , setOpen] = useState(false);

    var tierList=[];
    useEffect (() => {
        setRuleLoading (true);
        var paginationBookmark = ((page - 1) * pageSize);
        var fetchLimit = pageSize;
        settingsApiService.fetchMembershipCategory (fetchLimit, paginationBookmark)
        .then (response => {
            if (!response || !response.data){
                HWMessage.show("Data can't be fetched at the moment" , "error");
                return console.log("No relevant data can be fetched");
            }
            console.log("Printing ::", response);
           
            HWMessage.show("Request Successful" , "success");
            
            tierList = response.data.tiers.map(i => ({ id : i.id, tierName : i.tierName}));

            console.log(tierList);
            setTiers(tierList);
        })
        .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() => { setRuleLoading (false); });
    }, [ page, pageSize ,  refreshState ]);
    
    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    }

    useEffect (() => {
        if(props.createMode == false){
            setRuleLoading (true);
            settingsApiService.fetchSpecificSubscription (props.ruleId)
            .then (response => {  
                if (! response || ! response.data) { return HWMessage.show ("Specific Subscription Cannnot be fetched","error"); }
                setCurrentRule (response.data); 
                HWMessage.show("Request Successful" , "success");

            })
            .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => setRuleLoading (false));
        }
       
    }, [])


    const setValue2 = (field) => {
        switch(field)
        {
            case "Sign Up" :        setCurrentRule(prev => {  prev.processingCondition = "SignUp";  return { ...prev }; });   break;
            case "Add Bike Process" :        setCurrentRule(prev => {  prev.processingCondition = "AddBikeProcess";  return { ...prev }; });    break;
            case "Renewal" :        setCurrentRule(prev => {  prev.processingCondition = "Renewal" ;  return { ...prev }; });    break;

        }
    }
    
    console.log(currentRule);
   
    const getValue2 = () => {
        console.log(currentRule && currentRule.processingCondition);

        switch(currentRule && currentRule.processingCondition)
        {
            case "SignUp" :           return   "Sign Up" ; break;
            case "AddBikeProcess" :         return   "Add Bike Process" ; break;
            case "Renewal":    return "Renewal" ; break;
            

        }
    }



   
    const isDisabled = false;

    const updateRule = useCallback (rule => {
        if (rule !== null) {
            let successStatus = null;
            setRuleLoading (true);
            rule.name ="Hello"

            settingsApiService.updateSubscription(rule) 
            .then (response => {  
                if (! response || ! response.data) { HWMessage.show ("Error while fetching response","error"); }
                setCurrentRule (response.data);
                successStatus = true;
                setOpen2(!open2);
                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);  
                if (successStatus === true)
                   { 
                    props.setDirectActionOpen(false);
                    props.setRefreshState(prev=>!prev)
                }
            });
        }
    });
    const createRule = useCallback (rule => {
        if (rule !== null)  {
            let successStatus = null;
            setRuleLoading (true);
            rule.name ="Hello"
            settingsApiService.createSubscription (rule)
            .then (response => {
                if (! response || ! response.data) { HWMessage.show ("Error while fetchinmg response" ,"error"); }
                setCurrentRule (response.data);
                props.setRefreshState (prev => !prev);
                successStatus = true;
                setOpen3(!open3);
                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);
                if (successStatus === true)
                    {
                        props.setDirectActionOpen(false) }
            });
        }
    });

    

    const captureChanges = event => { 
        const field = event.target.name;
        const value = event.target.value;

         switch(field){
             case 'fee' : setCurrentRule (prev => ({ ...prev, [field] : +value })); break;
            //  case 'afterReactivationFee' : setCurrentRule (prev => ({ ...prev, [field] : +value })); break;
            //  case 'afterExpiryDurationInMonths' : setCurrentRule (prev => ({ ...prev, [field] : +value })); break;
             case 'durationInMonths' : setCurrentRule (prev => ({ ...prev, [field] : +value })); break;

             default : setCurrentRule (prev => ({ ...prev, [field] : value }));
         }
               
       
    };
    const handleSave = () => {
        if (props.createMode === true) { createRule (currentRule); } 
        else if (props.createMode === false) { updateRule (currentRule); }
    };
    const handleCancel = () => { props.setOpen (prev => false); props.setDirectActionOpen(false) };

    const checkDisabled = () => {
        if(currentRule.processingCondition === "SignUp" || currentRule.processingCondition === "Renewal" )
        {
            if(currentRule  &&  (currentRule.fee !=='' ) && currentRule.processingCondition && currentRule.processingCondition!=="" && currentRule.tierId!==''
            && currentRule.validityStartDate && currentRule.validityStartDate!=="" && currentRule.validityEndDate && currentRule.validityEndDate!=="" && currentRule.description && currentRule.description!=="" && currentRule.durationInMonths && currentRule.durationInMonths!==""
            && currentRule.durationInMonths>=0){
                            return false;
            }
            else{
                return true;
            }
        }
        else if(currentRule.processingCondition === "AddBikeProcess")
        {
            if(currentRule  &&   currentRule.processingCondition && currentRule.processingCondition!=="" && currentRule.tierId!==''
            && currentRule.validityStartDate && currentRule.validityStartDate!=="" && currentRule.validityEndDate && currentRule.validityEndDate!=="" && currentRule.description && currentRule.description!=="" && currentRule.durationInMonths && currentRule.durationInMonths!==""
            && currentRule.durationInMonths>=0)
            {
                return false;
            }
            else{
                return true;
            }
        }
    
        // else{
        //     if(currentRule &&  currentRule.afterReactivationFee && (currentRule.afterReactivationFee!=='' || currentRule.afterReactivationFee>=0) && currentRule.afterExpiryDurationInMonths &&  (currentRule.afterExpiryDurationInMonths!=='' || currentRule.afterExpiryDurationInMonths>=0) )
        //     return false;
        //     else{
        //      return true;
        //  }
        // }
               
    }

    return (
        <>       <BlockUi blocking = { ruleLoading } message = { 'Please wait while we fetch.' } >

                <Card className = { classes.card } >
{                props.createMode === false  &&   <CardHeader style={{ margin : 0 , padding : 5}}>  <h3> Subscription Configuration  &nbsp; <a style={{cursor: "pointer"}}><Edit style={{color: "#138de8"}} onClick={e=>setEdit(!edit)} /></a>   </h3>     </CardHeader>
}         
{                props.createMode === true  &&   <CardHeader style={{ margin : 0 , padding : 5}}> <center><h3> Subscription Configuration  </h3></center>      </CardHeader>
} 
           <CardBody>  
                        <GridContainer>
                        
                        <GridItem xs = {12} md = {6} sm = {12} >  
                        {/* <HWInput label = { 'Name' }  
                                                    name = { 'name' }
                                                   
                                                    onBlur = { captureChanges }
                                                    value = { currentRule && currentRule.name }
                                                    helperText = { currentRule && currentRule.name!=='' ?" " : "Please Enter Name"}
                                                    hasError = { currentRule && currentRule.name!=='' ? false : true } 
                                                    disabled = {props.createMode === false ? !edit : false}
                                                />  */}
                       </GridItem>

                            <GridItem xs = {12} md = {12} sm = {12} >  
                           
                           <DropDown 
                                  labelCategory = "Tier" 
                                  name = "tier"
                                  values = { tiers.map(i => i.tierName) }
                                  minWidth = {300}
                                //  values = {['HWLOAD' , 'HLOPA']}

                                  targetValue = { currentRule && currentRule.tierName }
                                 // onChange = { e => { setCurrentRule(prev => {  prev.VoucherCode = e.target.value;  return { ...prev }; });  } }
                                  onChange = { e => { setCurrentRule(prev => {  prev.tierName = e.target.value ; prev.tierId = tiers.filter (i => i.tierName === e.target.value)[0].id;  return { ...prev }; });  } }

                                  onBlur = { captureChanges }
                                  helperText = { fetchErrorMessage ('tier') }
                                  hasError = { checkError ('tier') }
                                  disabled = { props.createMode === false ? !edit : false }
                                  required={true}
                               />  

                           </GridItem>
                           
                           <br/>


                           <GridItem xs = {6} md = {6} sm = {6} >  
                           
                           <DropDown 
                                   labelCategory = "Process Flag" 
                                   name = "Processing Condition"
                                   values = { ["Sign Up", "Add Bike Process", "Renewal"] }
                                   minWidth = {300}
                                 //  onBlur = { captureChanges }
                                   helperText = { fetchErrorMessage ('processingCondition') }
                                   hasError = { checkError ('processingCondition') }
                                   disabled = { props.createMode === false ? !edit : false }
                                   targetValue = { getValue2() }
                                   onChange = { e=> setValue2(e.target.value)  } 
                                   required ={true}
                               />  

                           </GridItem>
                           {currentRule &&  (currentRule.processingCondition === "SignUp" || currentRule.processingCondition === "Renewal"  ) &&
                           <GridItem xs = {6} md = {6} sm = {6} >  
                           
                           <HWInput label = { 'Subscription Fees ' } type = 'number'  
                                                    name = { 'fee' }
                                                    helperText = { currentRule && (currentRule.fee === '' || currentRule.fee < 0  ) ? "Invalid value" : "" }
                                                    hasError = { currentRule && (currentRule.fee === '' || currentRule.fee < 0 ) ? true : false  } 
                                                    onBlur = { captureChanges }
                                                    value = { currentRule && currentRule.fee }
                                                    endAdornment={<InputAdornment position="end">RM</InputAdornment>}
                                                    disabled={props.createMode === false ? !edit : false}
                                                    required = {true}
                                                /> 

                           </GridItem>
                           }
                           {currentRule && <GridItem xs = {6} md = {6} sm = {6} >  
                           
                                                    <HWInput label = { 'Validity ' }  
                                                    type = 'number'  
                                                    name = { 'durationInMonths' } 
                                                    helperText = { currentRule && (currentRule.durationInMonths === '' || currentRule.durationInMonths < 0 || currentRule.durationInMonths ===0 ) ? "Invalid value" : "" }
                                                    hasError = { currentRule && (currentRule.durationInMonths === '' || currentRule.durationInMonths < 0 || currentRule.durationInMonths ===0 ) ? true : false  } 
                                                    onBlur = { captureChanges }
                                                    value = { currentRule && currentRule.durationInMonths }
                                                    endAdornment={<InputAdornment position="end">Months</InputAdornment>}
                                                    disabled={props.createMode === false ? !edit : false}
                                                    required = {true}
                                                /> 

                           </GridItem>}


                          <GridItem xs = {12} md = {12} sm = {12} > 
                          {/* {currentRule &&  currentRule.processingCondition ==="Renewal" && <h4> Reactivation Fees</h4> } */}

                       <GridContainer >
                         

                       {/* <br/> */}
                         {/* {currentRule &&  currentRule.processingCondition ==="Renewal" &&  <GridItem xs = { 6 } md = {6} sm = {6} > 
                           <HWInput label = { 'Reactivates before expiry date' } type = 'number'  
                                                    name = { 'fee' }
                                                    helperText = { currentRule && (currentRule.fee === '' || currentRule.fee < 0) ? "Invalid value" : "" }
                                                    hasError = { currentRule && (currentRule.fee === '' || currentRule.fee < 0 ) ? true : false  } 
                                                    disabled={props.createMode === false ? !edit : false}
                                                    onBlur = { captureChanges }
                                                    value = { currentRule && currentRule.fee }
                                                    endAdornment={<InputAdornment position="end">RM</InputAdornment>}
                                                    required = {true}
                                                /> 
                           </GridItem> }
                          {currentRule && currentRule.processingCondition  ==="Renewal" && <GridItem xs = { 6 } md = {6} sm = {6} >
                           <HWInput label = { 'Reactivates after expiry date' } type = 'number'  
                                                    name = { 'afterReactivationFee' }
                                                    helperText = { currentRule && (currentRule.afterReactivationFee === '' || currentRule.afterReactivationFee < 0 ) ? "Invalid value" : "" }
                                                    hasError = { currentRule && (currentRule.afterReactivationFee === '' || currentRule.afterReactivationFee < 0 ) ? true : false  } 
                                                   disabled={props.createMode === false ? !edit : false}
                                                    onBlur = { captureChanges }
                                                    value = { currentRule && currentRule.afterReactivationFee }
                                                    endAdornment={<InputAdornment position="end">RM</InputAdornment>}
                                                    required = {true}
                                                /> 
                           </GridItem> } */}
                       </GridContainer>
                       </GridItem>
                           
                            <GridItem xs = {12} md = {12} sm = {12} > 
                       
                            {/* {currentRule &&  currentRule.processingCondition ==="Renewal" && <h4> Validity</h4>  } */}

                       <GridContainer >
                         
                       {/* {currentRule &&  currentRule.processingCondition ==="Renewal" && <GridItem xs = { 6 } md = {6} sm = {6} > 
                          <HWInput label = { 'Reactivates before expiry date' } type = 'number'  
                                                    name = { 'durationInMonths' }
                                                    helperText = { currentRule && (currentRule.durationInMonths === '' || currentRule.durationInMonths < 0 || currentRule.durationInMonths ===0 ) ? "Invalid value" : "" }
                                                    hasError = { currentRule && (currentRule.durationInMonths === '' || currentRule.durationInMonths < 0 || currentRule.durationInMonths ===0 ) ? true : false  } 
                                                    disabled={props.createMode === false ? !edit : false}
                                                    onBlur = { captureChanges }
                                                    value = { currentRule && currentRule.durationInMonths }
                                                    endAdornment={<InputAdornment position="end">Months</InputAdornment>}
                                                    required = {true}
                                                /> 
                           </GridItem> }
                           {currentRule &&  currentRule.processingCondition ==="Renewal" && <GridItem xs = { 6 } md = {6} sm = {6} >
                           <HWInput label = { 'Reactivates after expiry date' } type = 'number'  
                                                    name = { 'afterExpiryDurationInMonths' }
                                                    helperText = { currentRule && (currentRule.afterExpiryDurationInMonths === '' || currentRule.afterExpiryDurationInMonths < 0 || currentRule.afterExpiryDurationInMonths ===0 ) ? "Invalid value" : "" }
                                                    hasError = { currentRule && (currentRule.afterExpiryDurationInMonths === '' || currentRule.afterExpiryDurationInMonths < 0 || currentRule.afterExpiryDurationInMonths ===0 ) ? true : false  } 
                                                    disabled={props.createMode === false ? !edit : false}
                                                    onBlur = { captureChanges }
                                                    value = { currentRule && currentRule.afterExpiryDurationInMonths }
                                                    endAdornment={<InputAdornment position="end">Months</InputAdornment>}
                                                    required = {true}
                                                /> 
                           </GridItem> } */}
                       </GridContainer>
                       </GridItem>
                           

                           <GridItem xs = {12} md = {12} sm = {12} > 
                       
                                <GridContainer >
                                    <GridItem xs = { 6 } md = {6} sm = {6} > 
                                        <HWInput  type = {'date'} name = { 'validityStartDate' } 
                                            value = { currentRule && currentRule.validityStartDate } onBlur = { captureChanges } 
                                            hasError = { checkError ('validityStartDate') } 
                                            helperText = { fetchErrorMessage ('validityStartDate') } 
                                            label = { 'Validity Start Date' } 
                                            labelProps = {{ shrink: true }} 
                                           // inputProps={{min : new Date().toISOString().split('T')[0]}} 
                                            disabled = { props.createMode === true ? false  : edit === false ? true : new Date(currentRule.validityStartDate) <=  new Date() ? true : false }
                                            required = {true}
                                        /> 
                                    </GridItem> 
                                    <GridItem xs = { 6 } md = {6} sm = {6} > <HWInput 
                                       disabled = {  props.createMode === true && currentRule.validityStartDate  ? false   : edit === false ? true :   props.createMode === false && new Date(currentRule.validityEndDate) <=  new Date() ? true : false  } 
                                        type = { 'date' } 
                                        name = { 'validityEndDate' }  
                                        value = { currentRule && currentRule.validityEndDate } 
                                        onBlur = { captureChanges } 
                                        hasError = { checkError ('validityEndDate') } 
                                        helperText = { fetchErrorMessage ('validityEndDate') } 
                                        label = { 'Validity End Date' } 
                                        labelProps = {{ shrink: true }}
                                        inputProps={{min : currentRule && currentRule.validityStartDate }}
                                        required = {true}
                                    /> 
                                    </GridItem> 
                                </GridContainer>
                                </GridItem>
                            
                       <GridItem xs = {12} md = {12} sm = {12} > 
                       
                                <GridContainer >

                                    <GridItem xs = {12} md = {12} sm = {12} >  
                              <center>

                                             <TextField 
                                               style = {{ marginTop : 20 }}
                                               multiline = { true }
                                               rows = { 10 }
                                               rowsMax = { 20 }
                                               value = { currentRule && currentRule.description }
                                               fullWidth
                                               variant = "outlined"
                                               label = {`Description` }
                                               placeholder = 'Specify the description.'
                                               name = { 'description' }
                                               InputLabelProps = {{ shrink : true }}
                                               inputProps = {{ maxLength : 100 }}
                                              // onBlur = { captureChanges }
                                               error = { currentRule && currentRule.description==="" ? true :  false}
                                               onChange =  { captureChanges  }
                                              helperText = {  currentRule && currentRule.description==="" ? "Enter description" :  "" }
                                              disabled={props.createMode === false ? !edit : false}
                                              required={true}
                                            />
                              </center>
                            </GridItem>
                                </GridContainer> 
                            </GridItem>
                            <GridItem xs = {12} sm = {12} md = {12} >
                                {  <center>
{ settings.includes("Action") === true && props.createMode === false && edit === true &&  <Button disabled={checkDisabled()} onClick = { e => props.createMode === true ? setCreateOpen (true) : setSaveOpen (true) } raised variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} > { props.createMode === true ? 'Create Subscription' : 'Update Subscription' } </Button>
}         
{ settings.includes("Action") === true &&  props.createMode === true && <Button disabled={checkDisabled()} onClick = { e => props.createMode === true ? setCreateOpen (true) : setSaveOpen (true) } raised variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} > { props.createMode === true ? 'Create Subscription' : 'Update Subscription' } </Button>
}   

                           <Button onClick = { handleCancel } raised variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} > Cancel </Button>
                                    <ConfirmationDialog open = { createOpen } setOpen = { setCreateOpen } confirmingAction = { handleSave } dialogMessage = { 'Are you sure you want to create this subscription' } dialogTitle = { 'Confirm Subscription Creation' } confirmingAction = { handleSave } />
                                    <ConfirmationDialog open = { saveOpen } setOpen = { setSaveOpen } confirmingAction = { handleSave } dialogMessage = { 'Are you sure want to update the Subscription. This will affect multiple wards systems and validity' } dialogTitle = { 'Confirm Rule Updation' } confirmingAction = { handleSave } />
                                </center>}
                                <StatusDialog isLoading = {false} open= { open3 } setOpen={setOpen3}  sucessStatus={"success"} confirmingAction={e=>props.setOpen (false)} message={"Subscription successfully created."} dialogTitle={"Status"}/> 
                                <StatusDialog isLoading = {false} open= { open2 } setOpen={setOpen2}  sucessStatus={"success"} confirmingAction={e=>props.setOpen (false)} message={"Subscription successfully updated."} dialogTitle={"Status"}/> 

                            </GridItem>
                        </GridContainer>
                    </CardBody> 
                </Card>
            </BlockUi>
        </>
    );

};