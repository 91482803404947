import { makeStyles,Button,FormControl,MenuItem, Select, TextField ,InputAdornment, IconButton } from '@material-ui/core';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import { Edit, People } from '@material-ui/icons';
import { buildCellParams, DataGrid , GridToolbar } from '@material-ui/data-grid';
import pointsApiService from './APIService';
import { teal } from '@material-ui/core/colors';
import { positions } from '@material-ui/system';
import { HWInput } from 'HWLibrary/HWInput';
import InputLabel from "@material-ui/core/InputLabel";
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import HWMessage from "HWLibrary/HWMessage";
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import BlockUi from 'react-block-ui';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import SearchBar from 'views/Merchant/SearchBar';
import { CustomToolbar } from 'views/Members/Member';


const useStyles = makeStyles(theme => ({
    ...styles,
    cardFooter : { justifyContent : 'center', justifyItems : 'center' },
    applyButton : { textTransform : 'none', color : 'floralwhite', background : '#138DE8', width : 300, height : 50, fontSize : 20 },
    cardHeader : { background : '#138DE8'  },
    cardIcon : { background : '#138DE8' },
    bottomGrid : { marginTop : 10 },
    '& .MuiFormLabel-root' : { marginBottom : 0 },
    redirectButton : { color : 'floralwhite', beckground : '#FF9017' },
    editButton : {float: 'right' , textTransform : 'none', color : 'floralwhite', background : '#138DE8', width : 100, height : 35, fontSize : 15, marginright : 20 },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    cancelButton : { textTransform : 'none', color : 'floralwhite', background : '#FF9017', height : 45, width : 180, fontSize : 18 },
    editIcon : { color : '#138DE8' }
}));

export default function PointsExpiryType (props) {

    HWLocalStorage.save(0,"previousPointsRuleTab");

    var point=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    point= [...obj["Points"]];

    const classes = useStyles();

    const [ rows, setRows ] = useState ([]);
    const [ edit, setEdit ] = useState (false);
    const [ loading, setLoading ] = useState (false);
    const [ updateLoading, setUpdateLoading ] = useState (false);
    const [ previousRows, setPreviousRows ] = useState ([]);
    const [ editCell, setEditCell ] = useState (-1);
    const [ saveOpen, setSaveOpen ] = useState (false);
    const [ searchText, setSearchText] = useState("");
    const [ pageSize, setPageSize ] = useState (100);
    const [ page, setPage ] = useState (0);
    const [ filterModel, setFilterModel ] = useState ({});
    const [ sortModel, setSortModel ] = useState ({});
    const [refreshState ,setRefreshState] = useState(false);

    const formatValuesSuffix = (maginitude, unit) => {
        if (maginitude=== null) return "NA";
        if (maginitude === 0) { return '' } 
        else if (maginitude === 1) { return `${maginitude} ${unit}` }
        else { return `${ maginitude } ${ unit }s` };
    };

    // Converts to JSON
    const periodConverter = periodString => {
        if (! periodString || periodString === '' || periodString === null) 
            return ''; 
        var yidx = periodString.indexOf("Y");
        var midx = periodString.indexOf ("M");
        var didx = periodString.indexOf ("D");
        let result = {};
        
        if (yidx !== -1)  result.years = parseInt(periodString.slice (1,yidx));
        if (yidx !== -1 && midx !== -1) result.months = parseInt (periodString.slice (yidx+1, midx));
        if (yidx === -1 && midx !== -1) result.months = parseInt (periodString.slice (1, midx));
        if (midx!== -1 && didx !== -1) result.days = parseInt (periodString.slice(midx+1 ,didx));
        if (midx=== -1 && didx !== -1) result.days = parseInt (periodString.slice(1 ,didx));

        return  result;
    };

    // COnverts to string
    const periodDeconverter = periodObject => {
        if (!periodObject || periodObject === null || periodObject === undefined) 
            return "";
        let str = "P";
        Object.keys(periodObject).map (key => {  
            switch (key) {
                case 'years': str += `${periodObject.years}Y`; break;
                case 'months': str += `${ periodObject.months }M`; break;
                case 'days': str += `${ periodObject.days }D`; break;
            }
        });
        return str;
    };

    function formatObject(value){
        if (value === null) return '';
        let result = '';
        let valuesList = Object.keys (value).map (key => {
            var unitString = key.slice (0, key.length - 1);
            result += `${formatValuesSuffix (value[key], unitString)} `;
        });
        return Object.keys(result).length===0 ? "NA" : result;
    };
  
    const handleCellEdit = idx => { setEditCell (idx);};
    const handleEdit = () => { setEdit(!edit); }

    const handleValidityTypeChange = (event, idx) => {
        const name = event.target.name;
        const value = event.target.value;
         
        if (name === 'pointsValidityType')
            setRows(prev => {
             return   prev.map ((i, index) => {  
                    if (idx === i.id) {
                      
                        i.pointsValidityType = value; 
                        i.pointsValidity = { years : 0, months : 0 };
                    }
                    return i;
                });
            });    
    };

    const captureTextFieldChange = (e, idx) => {
        const field = e.target.name;
        const value = e.target.value;
        switch (field) {
            case 'pointsValidityType': setRows (prev => { 
                return   prev.map ((i, index) => {  
                    if (idx === i.id) {
                     i.pointsValidityType = value
                    }
                    return i;
                });
            })
            case 'pointsValidity': setRows (prev => { 
                return   prev.map ((i, index) => {  
                    if (idx === i.id) {
                      i.pointsValidity = i.pointsValidityType === 'Fixed' ? {  years : value } : { months : value };
                    }
                    return i;
                });
            });
        }
    }

    const columns = [
        { field : 'tierName', headerName : 'Membership Tier', minWidth : 200,flex:2, type : 'string' },
        { field : 'pointsValidityType', headerName : 'Type', minWidth : 200,flex:2, editable: true, type : 'text' ,renderCell: (params) => {
            if(editCell === params.id){
              return <> 
              <FormControl variant = "outlined" className = { classes.formControl } >
                    <Select
                        name = 'pointsValidityType'
                        style = {{ width : 150 }}
                        value = { params.row.pointsValidityType }
                        onChange = { (event) => handleValidityTypeChange(event,params.row.id)}
                        label = "Validity Type"
                        inputProps = {{ name : 'pointsValidityType', id : "params.row.id", style: {textAlign: 'center',height: 14} }} 
                    >
                        <MenuItem value = { 'Fixed' } > Fixed  </MenuItem>
                        <MenuItem value = { 'Rolling' } > Rolling </MenuItem>
                        <MenuItem value = { 'Never' } > Never </MenuItem>
                    </Select> 
                </FormControl>
            </>
            }
          },     
        },
        { field : 'pointsValidity', filterable: false ,  headerName : 'Validity', minWidth : 200,flex:2, type : 'text', valueFormatter: ({ value }) => formatObject(value) , editable: true, renderCell: (params) => {
            if(editCell === params.id) {  return <span> { 
                params.row.pointsValidityType === "Fixed" ? 
                    <TextField variant = 'outlined'  value = { params.row.pointsValidity.years }  name = "pointsValidity"  onChange = { e => captureTextFieldChange (e,params.row.id) } style = {{ width : 170, top: 5 }} inputProps={{ style: {textAlign: 'center',height: 14} }} InputProps={{ endAdornment : <InputAdornment position="end"> year(s) </InputAdornment> }} /> 
                    :  params.row.pointsValidityType === "Rolling"  ?  <TextField  variant = 'outlined' value = { params.row.pointsValidity.months }  name = "pointsValidity"  onChange = { e => captureTextFieldChange (e, params.row.id) } style = {{ width : 170,top: 5 }} inputProps={{ style: {textAlign: 'center' , height: 14} }} InputProps={{ endAdornment : <InputAdornment position="end"> month(s) </InputAdornment> }} /> 
                    :  "NA"  
                } 
                </span> ;} } 
        },
        { field : 'createTime', filterable: false , headerName : 'Raised Date', minWidth : 300,flex:2, renderCell : cellParams => {
            var date = cellParams.row.createTime;
            var dateString = date.split('T');
            var d = new Date (dateString).toLocaleDateString();

            return <span> {d} </span>
        } },
       
    ];

    if(point.includes("Action") === true){
        columns.push( { field : 'action', filterable: false, sortable:false, headerName: 'Action', flex: 2, renderCell : (cellParams) => { return editCell === cellParams.id ? <>
            <IconButton onClick = { e => setSaveOpen (true) } > 
                    <SaveIcon style = {{ color: '#138DE8' }} /> 
            </IconButton>
            <ConfirmationDialog dialogTitle = { 'Confirm Tier Updates' } dialogMessage = { 'Are you sure you want to update the tier lists. This would affect the points of all the members existing' } open = { saveOpen } setOpen = { setSaveOpen } confirmingAction = { e => handleSave(cellParams.id,cellParams.row) } />
            </> : 
            <>
            <IconButton onClick = { e => handleCellEdit(cellParams.id) } > 
                    <EditIcon className = { classes.editIcon } /> 
            </IconButton>
            </>
        } }          )
    }


    useEffect (() => {
        setLoading (true);
        var paginationBookmark = ((page) * pageSize);
        var fetchLimit = pageSize;
        pointsApiService.fetchTiers({fetchLimit, paginationBookmark, filterModel, sortModel, searchText}).then (response => {
            if (!response || !response.data)
                return console.log("No relevant data can be fetched");
             response.data.tiers.map(i => (i.pointsValidity) = (i.pointsValidity === undefined) ?  {} : periodConverter (i.pointsValidity ));
             setRows(response.data.tiers);
             setPreviousRows (response.data.tiers);      
             HWMessage.show("Request Successful" , "success");
            HWLocalStorage.save (JSON.stringify(response.data.tiers), "tiers");
          })
        .catch (error => { console.log("Error while fetching data", error); })
        .finally (() => { setLoading (false);  var my_element = document.getElementById("top");
        if(my_element!== null || my_element!== undefined || my_element!== '')
        {
            // my_element.scrollIntoView({ }); 

        }
    
    });
    }, [ page , pageSize , filterModel, sortModel, searchText]);

    const saveSingleRule = useCallback ((idx,rowData) => { 
        setUpdateLoading (true);
        let saveRule = rowData;
        saveRule.validity = periodDeconverter (rowData.pointsValidity);
        saveRule.validityType = rowData.pointsValidityType;

        pointsApiService.updateTier(saveRule)
        .then (response => { 
            if (!response || !response.data) {
                HWMessage.show ("CurrentRule cannot be updated","error");
                return console.log("Error while updating cuurentRule");
            }
            HWMessage.show("CurrentRule updated successfully","success");
            setRows (prev => {  
                prev[idx].pointsValidity = periodConverter (response.data.validity);
                HWMessage.show("Request Successful" , "success");
                return [ ...prev ]; 
            });
            }).catch (error => {  console.log("error",error);return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Error while updating currentRule", "error");
            })
        .finally (() => { setUpdateLoading (false);});});


    function CustomLoadingOverlay() {
        return (
        <div style={{ position: 'absolute', top: 0, width: '100%' }}>
            <BorderLinearProgress />
            </div>
        );
    }

    const handleSave = (idx,rowData) => { saveSingleRule(idx,rowData); setEditCell (-1); };
    const handleCancel = (event) => {  setEditCell (-1);  setRows(JSON.parse(HWLocalStorage.load("tiers"))); };

    const useDataGridStyles = makeStyles(theme => ({
        root: {
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiButton-label' : { color : '#138DE8' }
        }
      }));

    const dataGridClasses = useDataGridStyles();

    return (
        <Fragment>
            <BlockUi blocking = { updateLoading } message = { 'Please Wait while we save the changes.' } >
                <GridContainer id="top">
                    <GridItem xs = {12} md = {12} sm = {12} >
                        <Card >
                            <CardHeader className = { classes.cardHeader } color = 'rose' icon >
                                <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                                <h4 className = { classes.cardIconTitle } > Points Expiry Type </h4>
                            </CardHeader>                  
                            <CardBody>
                                <GridContainer style = {{ justifyContent : 'space-between' }} >
                                    <GridItem>
                                            <SearchBar 
                                                width = '400px' 
                                                handleSearch = { setSearchText }
                                                handleRefresh = { setRefreshState }
                                                refreshState = { refreshState }
                                              /> 
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem className = { classes.bottomGrid } xs = {12} md = {12} >
                                        <DataGrid 
                                            className = { dataGridClasses.root }
                                            sortingMode="server" 
                                            onSortModelChange = { params => { 
                                            if (params.length > 0) 
                                                setSortModel (params[0]);
                                            }}
                                        
                                            filterMode = 'server'
                                            onFilterModelChange = { (params) => {
                                            if (params.items.length > 0) {
                                                setFilterModel (prev => ({ ...params.items[0] })); 
                                            }
                                            }}          
                                            autoHeight
                                            rows = { rows }
                                            columns = { columns }
                                            editable = { edit }
                                            showToolbar = { false }
                                            autoPageSize = { true }
                                            checkboxSelection = { false }
                                            disableColumnMenu
                                            disableDensitySelector
                                            hideFooterPagination
                                            hideFooterRowCount
                                            loading = { loading }
                                            components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 
                                            
                                        />
                                    </GridItem>   
                                </GridContainer>
                            </CardBody>
                            <CardFooter className = { classes.cardFooter } >
                                <GridContainer > 
                                    <GridItem xs = {12} md = {12} sm = {12} >  
                                        <center> 
                                            {editCell !== -1 && <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { handleCancel } > Cancel </Button> }
                                        </center>
                                    </GridItem>
                                </GridContainer>
                            </CardFooter>        
                        </Card>
                    </GridItem> 
                    
                </GridContainer>
            </BlockUi>
        </Fragment>
    );
};