import { makeStyles } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import HWMessage from 'HWLibrary/HWMessage';
import { useCallback } from 'react';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import { useHistory  } from "react-router-dom";
import HWLocalStorage from 'HWLibrary/HWLocalStorage';

import UserType from './UserType';
import notificationAPIService from './APIService';
const useStyles = makeStyles (theme => ({
    root: {
        width: '100%',
      },
      button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      actionsContainer: {
        marginBottom: theme.spacing(2),
      },
      resetContainer: {
        padding: theme.spacing(3),
      },
}));

export default function CreateNotification (props) {
    const history = useHistory ();

    const extractLocationParams = () => {
        const locationParams = props && props.location && props.location.params;
        if (locationParams != null) {
          HWLocalStorage.save(JSON.stringify(locationParams.state), "createEditNotiffication");
          return locationParams.state; 
        } 
    };

    const locationParams  = extractLocationParams() ;
    const [ loading, setLoading ] = useState (false);
    const [ notificationDetail, setNotificationDetail ] = useState ({});
    const [ draftConfirmation , setDraftConfirmation ] = useState(false);
    const [ saveConfirmation , setSaveConfirmation ] = useState(false);
    const [ ruleLoading, setRuleLoading ] = useState(false);
    const [config,setConfig] = useState(locationParams || JSON.parse(HWLocalStorage.load("createEditNotiffication"))) 

    const saveAsDraft = (rule) => {

      if (rule !== null)  {
        let successStatus = null;
        setRuleLoading (true);
        notificationAPIService.createDraft (rule)
        .then (response => {
            if (! response || ! response.data) { HWMessage.show ("Error while fetchinmg response"); }
          //  setCurrentRule (response.data);
            props.setRefreshState (prev => !prev);
            successStatus = true;
            HWMessage.show("Request Successful" , "success");
        }).catch (error => { HWMessage.show ("Error while creating rule"); successStatus = false; })
        .finally (() => { 
            setRuleLoading (false);
            if (successStatus === true)
                props.setOpen (false);
        });
    }


    };
    

    const saveNotification = useCallback (rule => {
      if (rule !== null)  {
          let successStatus = null;
          setRuleLoading (true);
          notificationAPIService.createNotification (rule)
          .then (response => {
              if (! response || ! response.data) { HWMessage.show ("Error while fetchinmg response"); }
            //  setCurrentRule (response.data);
              props.setRefreshState (prev => !prev);
              successStatus = true;
              HWMessage.show("Request Successful" , "success");
          }).catch (error => { HWMessage.show ("Error while creating rule"); successStatus = false; })
          .finally (() => { 
              setRuleLoading (false);
              if (successStatus === true)
                  props.setOpen (false);
          });
      }
  });

    return (
           <>
         <UserType  setOpenSave = { setSaveConfirmation } setOpen = { setDraftConfirmation } id= {config.id ? config.id : ''} createMode = { config.createMode ? config.createMode : false }  currentNotification= { notificationDetail } setCurrentNotification = { setNotificationDetail }  loading = { loading } setLoading = {setLoading}  />              
         {/* <ConfirmationDialog open = { draftConfirmation } setOpen = { setDraftConfirmation } confirmingAction = { saveAsDraft } dialogMessage = { 'Are you sure you want to save as Draft' } dialogTitle = { 'Save As Draft' } /> */}
         <ConfirmationDialog open = { saveConfirmation } setOpen = { setSaveConfirmation } confirmingAction = { saveNotification } dialogMessage = { 'Are you sure you want to create the notification' } dialogTitle = { 'Create the notification' } />
        </>
                
    );
};