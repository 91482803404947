import React, { useState, useEffect, Fragment, useRef } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import memberApiService from 'views/Members/APIService';
import { Button, Checkbox } from '@material-ui/core';



export default function DataGridSelectionTester (props) {
    const {mode} = props;
    const [ currentRequest, setCurrentRequest ] = useState ({});

    const [ selectedList, setSelectedList ] = useState ([]);
    const [ listLoading, setListLoading ] = useState (false);
    const [ selectionModelNew, setSelectionModelNew ] = useState ({ rowIds : [] });

    const [selectionModel, setSelectionModel] = useState([]);
    const prevSelectionModel = useRef(selectionModel);
    const [ selectionModelMap, setSelectionModelMap ] = useState ({});


    const [ rows, setRows ] = useState ([]);
    const [ page, setPage ] = useState (1);
    const [ pageSize, setPageSize ] = useState (100);
    const [ rowCount, setRowCount ] = useState (0);
    const [ searchText, setSearchText ] = useState ('');
    const [ filterModel, setFilterModel ] = useState ('');
    const [ refreshState, setRefreshState ] = useState (false);
    const [ open3 , setOpen3] = useState(false);
    const [ open2 , setOpen2] = useState(false);

    const handleExclusionInclusionClick = id => {

        console.log("Selections", selectionModel, selectionModelMap);

        var idx = selectionModel.indexOf (id);
        if (idx !== -1) {
            selectionModel.splice (idx, 1);
            setSelectionModel ([ ...selectionModel ]);
            var currentMap = selectionModelMap [ page ];
            var mapIdx = currentMap.indexOf(id);
            if (mapIdx !== -1) 
                currentMap.splice (mapIdx, 1);
            selectionModelMap [ page ] = currentMap;
            setSelectionModelMap ({ ...selectionModelMap });
        } else {
            selectionModel.push (id);
            setSelectionModel ([ ...selectionModel ]);
            
            var currentMap = selectionModelMap [ page ];
            currentMap.push (id);
            selectionModelMap [ page ] = currentMap;
            setSelectionModelMap ({ ...selectionModelMap });
        }
    };

    const columns = [
        
        { field : 'membershipNumber', headerName : 'Membership Number', filterable : true, sortable : true, flex: 1 },
        { field : 'emailAddress', headerName : 'Email',filterable : true, sortable : true, flex: 1, renderCell : cellParams => { return cellParams.row.email !== null ? cellParams.row.email : cellParams.row.emailAddress } },
        { field : 'tier', headerName : 'Tier',filterable : true, sortable : true, flex: 1, renderCell: cellParams => { return cellParams.row.tier ? cellParams.row.tier.tierName : null }},
        { field : 'gender', headerName : 'Gender',filterable : true, sortable : true, flex: 1 },
        { field : 'action', headerName : 'Action', renderCell : cellParams => selectionModel && <Checkbox  style = {{ color : '#138DE8' }} onClick = { e => handleExclusionInclusionClick (cellParams.row.id) } checked = { selectionModel.indexOf (cellParams.row.id) !== -1 } /> }
    ];

    useEffect (() => {
        
        setListLoading (true);
        var paginationBookmark = (page - 1) * pageSize;
        var fetchLimit = pageSize;
        memberApiService.fetch ({ paginationBookmark, fetchLimit })
            .then (response => {  
                setRowCount (response.data.membersCount);
                setRows (response.data.memberList);

                setSelectionModelMap ({  ...selectionModelMap,  [page] : selectionModelMap[page] || [] });
                setTimeout(() => { setSelectionModel( selectionModelMap[ page ] || [] ); });
            }).catch (error => {  })
            .finally (() => { setListLoading (false); });
         
    }, [  page, pageSize, searchText ]);

    return (
        <>
            <DataGrid 
                    // checkboxSelection
                    loading = { listLoading }
                    page = { page }
                    pageSize = { pageSize }
                    rows = { rows }
                    rowCount = { rowCount }
                    columns = { columns }

                    pagination
                    paginationMode = 'server'
                    // onPageChange = { changeParams => { console.log (selectionModel); setPage (changeParams.page); } }
                    onPageSizeChange = { changeParams => { console.log (selectionModel); setPage (1); setPageSize (changeParams.pageSize); } }

                    filterMode = 'server'
                    density = 'compact'
                    autoHeight
                    rowsPerPageOptions = { [ 25, 50, 100 ] }                    
                    onPageChange={(newPage) => {
                        console.log(selectionModel, prevSelectionModel);
                        prevSelectionModel.current = selectionModel;
                        var existing = selectionModelMap[ newPage.page ] || [];
                        setSelectionModel (prev => [ ...existing ]);
                        setTimeout(() => { setSelectionModel (prev => [ ...existing ]); });
                        console.log("Restored", page, selectionModelMap, selectionModel);
                        setSelectionModelNew ({ rowIds : [ ...existing ] });
                        setPage(newPage.page);
                    }}
                    // onSelectionChange={(newSelectionModel) => {
                    //     console.log("Selection", selectionModel, newSelectionModel, selectionModelMap);

                    //     var existing = selectionModelMap [ page ] || [];
                    //     var fullList = [ ...newSelectionModel.rowIds ];

                    //     fullList = fullList.filter ((i, idx) => fullList.indexOf(i) === idx) || [ ];
                    //     setSelectionModelMap (prev => ({ ...prev, [page] : fullList }));
                    //     setSelectionModel (fullList);
                    //     setTimeout(() => { setSelectionModel ( [ ...fullList ] )});
                    //     setSelectionModelNew (newSelectionModel);
                    // }}
                    // selectionModel = {  selectionModel  }
                    // disableMultipleSelection = {false}
                />
                { JSON.stringify (selectionModel) }
                {/* <DataGrid 
                checkboxSelection
                loading = { listLoading }
                page = { page }
                pageSize = { pageSize }
                rows = { rows }
                rowCount = { rowCount }
                columns = { columns }

                    pagination
                    paginationMode = 'server'
                    onPageChange = { changeParams => setPage (changeParams.page) }
                    onPageSizeChange = { changeParams => { setPage (1); setPageSize (changeParams.pageSize); } }

                    filterMode = 'server'
                    //onFilterModelChange = { params => { if (params.filterModel.items[0].value) setFilterModel(params.filterModel.items[0]); } }
                    density = 'compact'
                    autoHeight
                    rowsPerPageOptions = { [  25, 50, 100 ] }
                    disableExtendRowFullWidth = { false }
                    onRowSelected = { gridParam => {
                        if (gridParam.isSelected === false) {  
                            console.log ("GHSDKSADSA", gridParam);
                            setCurrentRequest (prev => {
                                prev.groupIds = prev.groupIds.filter (i => gridParam.data.id !== i);
                                return { ...prev };
                            })
                        }
                    } }
                    onSelectionChange = { newSelection => {  
                        console.log (newSelection);
                        setCurrentRequest(prev => { 
                            let groupIds = prev.groupIds || [];
                            for (var i = 0; i < newSelection.rowIds.length; i++)
                                if (groupIds.indexOf(newSelection.rowIds[i]) === -1) 
                                    groupIds.push (newSelection.rowIds[i]);
                            prev.groupIds = [ ...groupIds ];
                            console.log ("Prev", prev.groupIds);
                            setSelectionModel (prev.groupIds);     

                            console.log("New Selection Row IDS:" , newSelection.rowIds )
                        return {...prev};
                     });
                    }}
                    selectionModel = { selectionModel }
                /> */}
                
        </>
    );
};