import React, { useState } from "react"
import Merchant from "./Merchant";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MerchantTransactions from "./MerchantTransactions";
import { TabPane } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import HWLocalStorage from "HWLibrary/HWLocalStorage";
import Dashboard from "layouts/Ops";
import CampaignParticipation from "./CampaignParticipation/CampaignParticipation";

const useStyles = makeStyles((theme) => ({
    root: {
      overflow: 'hidden',
      minHeight: 48,
      WebkitOverflowScrolling: 'touch', 
      display: 'flex',
      backgroundColor: "white",
      '& .MuiTabs-indicator' : { backgroundColor : '#FF9017' },
      "& .MuiButtonBase-root": {textTransform: "none"},
      color: "black",
      borderRadius: '6px'
    }
}));
  
const MerchantDetails = (props) => {
    if (! HWLocalStorage.load ("previousMerchantDetailsTab") ) HWLocalStorage.save (0, "previousMerchantDetailsTab");

    const [selectedTab, setSelectedTab] = useState (JSON.parse(HWLocalStorage.load ("previousMerchantDetailsTab")) || 0);
    const changeTab = (event, index) => setSelectedTab(index);
    const classes = useStyles();

    console.debug ("Props", props);

    return (
        <>
                <Tabs variant = "scrollable" className = {classes.root} value={selectedTab} onChange={changeTab} aria-label="simple tabs example">
                    <Tab label="Summary" />
                    <Tab label="Transaction  History" />
                    <Tab label="Campaign Participation" /> 
                </Tabs>
            <div style={{ padding: "30px 0px 30px 0px" }}>
                { selectedTab == 0 && <Merchant {...props} /> }
                { selectedTab == 1 && <MerchantTransactions {...props} /> } 
                { selectedTab === 2 && <CampaignParticipation { ...props } /> }
            </div>
        </>
    )
}

export default MerchantDetails;