import { Button, Fab, IconButton, InputAdornment, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';

import { Delete, Edit } from '@material-ui/icons';
import HWMessage from 'HWLibrary/HWMessage';
import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { People } from '@material-ui/icons';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import AutocompleteComponent from './AutocompleteComponent';
import pointsApiService from './APIService';
import { createNew } from 'typescript';
import SaveIcon from '@material-ui/icons/Save';
import { useCallback } from 'react';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
const useStyles = makeStyles({
    ...styles,
    table: { minWidth: 650 },
    textField : { textAlign : 'center' },
    fabButton : {
        backgroundColor: '#ff9017',
    },
    cancelButton : { backgroundColor : '#FF9017', color : 'floralwhite', height: 45, width: 180, fontSize: 18 }
});

export default function BikeModels (props) {

    var point=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    point= [...obj["Points"]];
    
    const classes = useStyles ();

    const [ createNewRow, setCreateNewRow ] = useState (false);
    const [ newModel, setNewModel ] = useState ({});
    const [ isEdit, setIsEdit ] = useState (false);
    const [ loading, setLoading ] = useState (false);
    const [ rows, setRows ] = useState ([]);
    const [ categories, setCategories ] = useState ([]);
    const [ deleteOpen, setDeleteOpen ] = useState (false);
    const [ deletingRule, setDeletingRule ] = useState ({});
    const [ deletingIndex, setDeletingIndex ] = useState ({});

    
    
    
    const columns = [ 
        { headerName : "Bike Model", field : "modelName" }, 
        { headerName : "Category", field : "category" }
    ];

    useEffect (() => {
        setLoading (true);
        pointsApiService.fetchBikeModels ()
        .then (response => {  
            if (! response || ! response.data) { return HWMessage.show ("Error while fetching response" ,"error"); }
            setRows (response.data.bikeModels);
            HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() => { setLoading (false); });
    }, []);

    useEffect (() => {
        setLoading (true);
        pointsApiService.fetchBonusPoints ()
        .then (response => {  
            if (!response || ! response.data) { return HWMessage.show ("Error while fetching categories","error"); } 
            setCategories (response.data);
            HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() => setLoading (false));
    }, []);

    const captureTextFieldChange = (e, idx) => {
        const field = e.target.field;
        const value = e.target.value;

        var check = validateValues (field, value);
        if (check === false) { HWMessage.show ("One or more field conatin errors, please rectify before saving","error"); }
        else { 
            // Set the appropriate values 
        }
    };
    const validateValues = (field, value) => {
        switch (field) {
            case 'modelName' : break;
            case 'category' : break;
        }
    };
    const deleteModel = useCallback ((idx, row) => {
        console.log ("Index", idx, rows, row);
        if (idx !== null && row != null) {
            setLoading (true);
            row.modelName = row.bikeModelName;
            pointsApiService.deleteBikeModelRule (row)
            .then (response => {
                if (! response || ! response.data) { return HWMessage.show ("Error while updating the model","error"); }
                setRows (prev => { prev.splice (idx, 1); return [ ...prev ]; });
                HWMessage.show("Request Successful" , "success");
            }).catch (error => {
                return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            }).finally (() => {
                {setLoading (false);
                    setDeleteOpen(false);}
            });
        }
    }, [])

    const handleDelete = (e, idx, row) => { console.log ("Index", e, idx, row);setDeletingRule(row) ; setDeletingIndex(idx);  setDeleteOpen(true);   };  
    const handleEdit = () => { setCreateNewRow (false); setIsEdit(!isEdit); };  
    const addNewModel = () => {
        if (createNewRow === true) { return HWMessage.show ("Please save existing models to be added before adding another.","error") }
        else { setCreateNewRow(true); setNewModel ({}); rows.push (newModel); setRows (rows); }        
    };

    const updateRule = idx => {
        if (rows[idx] !== null) {
            rows[idx].modelName = rows[idx].bikeModelName;
            setLoading (true);
            pointsApiService.updateBikeModelRule (rows[idx]) 
            .then (response => {  
                if (! response || ! response.data) { return HWMessage.show ("Error while updating the rule","error"); }
                setRows (prev => { prev[idx].categoryId = response.data.categoryId; prev[idx].bikeModelName = response.data.modelName;  return [ ...prev ]; });
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => { 
                setLoading (false); 
            });
        }
    };

    const createRule = rule => {
        let successState = null;
        console.log (rule);
        if (rule !== null) {
            setLoading (true);
            rule.modelName = rule.bikeModelName;
            pointsApiService.createBikeModelRule (rule)
            .then (response => {
                if (! response || ! response.data) { return HWMessage.show ("Erorr while updating the rules"); }
                setRows(prev => { prev[prev.length - 1] = response.data; prev[prev.length - 1].bikeModelName = response.data.modelName; return prev; });
                successState = true;
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { HWMessage.show (error.response.data.message || "Error while creating the rule"); successState = false; })
            .finally (() => { 
                setLoading (false); 
                if (successState === true)
                    setCreateNewRow (false);
                    if (isEdit === true)
                        setIsEdit (false);
                });
        }
    };

    const handleSave = idx => {
        if (createNewRow === true) {  createRule (rows[rows.length - 1]); }
        else if (isEdit === true) {  
            var model = rows[idx];
            if (model.bikeModelName === undefined || model.bikeModelName == null) { return HWMessage.show ("Please fill all the details before saving."); }
            if (model.categoryId === undefined || model.categoryId == null || model.categoryName == null || model.categoryName === undefined) { return HWMessage.show ("Please fill the category name before updating"); }
            updateRule (idx);  setIsEdit(!isEdit); 
        }

    };
    const handleCancel = () => {
        if (createNewRow === true) { rows.pop(); setCreateNewRow (false); setRows (rows); }
        if(isEdit === true){ setIsEdit(!isEdit); return ; }
    };

    const getCategoryName = (categoryId) => {
        if (categoryId === null) return '';
        console.log (categoryId, categories, categories.filter (i => i.id === categoryId)[0]);
        let matchingCategory = categories.filter (i => i.id === categoryId)[0];
        if (! matchingCategory || matchingCategory === null) return '';
        return matchingCategory.categoryName;
    };

    // Abhijeet Change over this.

    return (
        <>
            <Card>
                <CardHeader className = { classes.cardHeader } color = 'rose' icon >
                    <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        <h4 className = { classes.cardIconTitle } > Bike Categories   <IconButton disabled={ point.includes("Action") === true ? false : true} onClick = {handleEdit} style = {{ float:"right" , marginRight:8 ,padding: 0 , fontsize: 5 }}  > { isEdit === false && <Edit style = {{ color : '#138DE8' , size : 6}} />} </IconButton> </h4> 
                        { (createNewRow === false) && point.includes("Action") === true &&  <Fab variant = 'extended' color="primary" aria-label="add" onClick = { addNewModel } style = {{ float: 'right', top: -20, zIndex : 3, backgroundColor: '#FF9017' , marginRight: "30px"}} > <AddIcon /> Add Model </Fab>}

                </CardHeader>
                <CardBody>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow style={{height: 7}} >
                                    { columns.map(i => ( <TableCell size="small" align="center"> <h4 style = {{ color : '#FF9017', fontWeight : 500 }} > { i.headerName } </h4>   </TableCell> ))}
                                </TableRow>
                            </TableHead>

                            { rows.length === 0 && <center> <h4> No models available </h4> </center>   }
                            <TableBody>
                                {rows.map((row, idx) => (
                                    <TableRow  style={{height: 7}} key={row.id}>
                                        <TableCell align = 'center' size="small" > 
                                            <center> 
                                                { (createNewRow === true && idx === rows.length - 1)  && <AutocompleteComponent options = { rows } fieldName = { 'bikeModelName' } name = "bikeModelName" label = "Model Name" value = { row.bikeModelName } setValue = { value => { setRows (prev => { prev[idx].bikeModelName = value.bikeModelName; return [ ...prev ] }); } } /> ||
                                                (isEdit === true) && <AutocompleteComponent disableClearable = { true } options = { rows } fieldName = { 'bikeModelName' } name = "bikeModelName" label = "Model Name" value = { row.bikeModelName } setValue = { value => {  rows[idx].bikeModelName = value; setRows([ ...rows ]) } } /> ||
                                                <span> { row.bikeModelName } </span> }
                                            </center> 
                                        </TableCell>
                                        <TableCell align = 'center' size="small" >  
                                            <center> 
                                               { (createNewRow === true && idx === rows.length - 1)  && <AutocompleteComponent name = "categoryName" options = { categories } fieldName = { 'categoryName' } label = "Category Name" value = { row.categoryName } setValue = { value => { rows[idx].categoryName = value.categoryName; rows[idx].categoryId = value.id; setRows ([ ...rows ]) }} /> ||
                                                 isEdit === true && <AutocompleteComponent name = "categoryName" options = { categories } label = "Category Name" value = { getCategoryName (row.categoryId) } fieldName = { 'categoryName' } setValue = { value => { rows[idx].categoryName = value.categoryName; rows[idx].categoryId = value.id; setRows ([ ...rows ]); }}  />  ||
                                                 <span> { getCategoryName (row.categoryId) } </span> }
                                            </center> 
                                        </TableCell>
                                        <TableCell align = 'center' size="small"> 
                                            { (isEdit == true || (createNewRow === true && idx === rows.length - 1)) && <IconButton onClick = { e => handleSave (idx) } > <SaveIcon style = {{ color: '#138DE8' }} /> </IconButton> }
                                            {   isEdit === true && <IconButton onClick = { e => { handleDelete (e, idx, row); } } > <Delete style = {{ color : '#FF9017' }} /> </IconButton>  }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardBody>

                <CardFooter>
                    {  <GridContainer > 
                        <GridItem xs = {12} md = {12} sm = {12} >  
                            <center> 
                               {(createNewRow === true || isEdit === true ) && <Button raised variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { handleCancel }  > Cancel </Button> } 
                            </center>
                        </GridItem>
                    </GridContainer>
                    }
                </CardFooter>
            </Card>
            <ConfirmationDialog
            dialogTitle = { 'Confirm Rule Deletion' } 
            dialogMessage = { 'Are you sure you want to delete the rule.' } 
            confirmingAction = { e => deleteModel(deletingIndex,deletingRule) } open = { deleteOpen } setOpen = { setDeleteOpen } 
        /> 
        </>
    );

}