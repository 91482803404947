import { Link, makeStyles} from '@material-ui/core';
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { Button,  IconButton , Popover } from '@material-ui/core';
import { DataGrid , GridToolbar} from '@material-ui/data-grid'
import { MoreVert, People } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import BlockUi from 'react-block-ui';
import HWMessage from 'HWLibrary/HWMessage';
import { useHistory } from 'react-router-dom';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 
import { GridAddIcon } from '@material-ui/data-grid';
import { Fab } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import RuleForm from './AwardRuleForm';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import HWGoogleMaps from 'views/Google Maps/HWGoogleMaps';
import voucherConfigApiService from './APIService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import Edit from '@material-ui/icons/Edit';
import SearchBar from 'views/Merchant/SearchBar';
import { CustomToolbar } from 'views/Members/Member';


const useStyles = makeStyles(theme => ({
    ...styles,
    cardFooter : { justifyContent : 'center', justifyItems : 'center' },
    cardHeader : { background : '#138DE8'  },
    cardIcon : { background : '#138DE8' },
    bottomGrid : { marginTop : 5 },
    '& .MuiFormLabel-root' : { marginBottom : 0 },
    editIcon : { color : '#138DE8' },
    deleteButton : { color : '#FF9017' },

}));


function RuleDialog (props) {
    
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ ruleDescription, setRuleDescription ] = useState ({});

    const handleClose = event => props.setOpen (false);
    return (
        <>
            <Dialog open = { props.open } onClose = { handleClose }  >
                <RuleForm refreshState = { props.refreshState } setRefreshState = { props.setRefreshState } rules = { props.rules } setRules = { props.setRules } createMode = { props.createMode } ruleId = { props.ruleId } open = { props.open } setOpen = { props.setOpen }  />
            </Dialog>
        </>
    );
}

function ActionPopover (props) {
    return ( 

                <div>
                <Popover
                    open={ props.open }
                    anchorEl={ props.anchorEl}
                    onClose={  (event) =>  props.handleClose (false)  }
                >
                    <div >
                        <div style = {{ margin : '20px' }} > 
                            <Button variant='contained' style = {{ backgroundColor : '#138DE8', color : 'floralwhite', right : '5px' , textTransform:'none' }} onClick = {event => props.handleEdit() }  > Edit </Button> 
                            <Button variant='contained' style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform:'none' }} onClick = { event => props.handleDelete() } >  Delete </Button> 
                        </div> 
                    </div>
                </Popover>
            </div>
    
    );
};


export default function AwardRule (props) {
    var vouchers=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    vouchers= [...obj["Vouchers"]];
    HWLocalStorage.save (2, "previousVouchersRuleTab")

   const classes = useStyles();
   const [ rows, setRows ] = useState ([]);
   const [ page, setPage ] = useState (0);
   const [ pageSize, setPageSize ] = useState (100);
   const [ filterModel, setFilterModel ] = useState (null);
   const [ rowCount, setRowCount ] = useState (0);
   const [ openDirectAction, setOpenDirectAction ] = useState (false);
   const [ selectedRequest, setSelectedRequest ] = useState (null);
   const [ searchText, setSearchText ] = useState ('');
   const [creatorMode , setCreatorMode ] = useState(false);
   const [ anchorEl, setAnchorEl ] = useState (null);
   const [ actionOpen, setActionOpen ] = useState (false);
   const [loading, setLoading ] = useState(false);
   const [ directActionOpen , setDirectActionOpen ] = useState(false);
   const [ deleteOpen, setDeleteOpen ] = useState (false);
   const [ deletingRule, setDeletingRule ] = useState (null);
   const [ refreshState, setRefreshState ] = useState (false);

   const [ selectedRuleId, setSelectedRuleId ] = useState ();
   const [ ruleOpen, setRuleOpen ] = useState (false);
   const [ createMode, setCreateMode ] = useState (false);
   const [ rulesLoading , setRulesLoading] = useState(false);
   const [ isLoading , setIsLoading] = useState(false);
   const [ deleteItemState , setDeleteItemState ] = useState('');
   const [ sortModel, setSortModel ] = useState ({});


   const openRule = () => { setRuleOpen (true); };
   const addRule = (event) => { setCreateMode (prev => true); setRuleOpen (prev => true); };
   const handleClick = (event,id) => {setCreateMode (prev => false); openRule(); };
   const handleEdit = (id) =>{
    history.push ({ pathname: "voucher-reward", state: { id: id , creatorMode : false } });
   } 


    useEffect (() => {
    setIsLoading (true);
    var paginationBookmark = ((page) * pageSize);
    var fetchLimit = pageSize;
    
    voucherConfigApiService.fetchAwardRuleList ({fetchLimit, paginationBookmark,sortModel , filterModel , searchText})
    .then (response => {
        if (!response || !response.data)
            {return HWMessage.show("No relevant data can be fetched" , "error");}
        HWMessage.show("Request Successful" , "success");
       setRows (response.data.awardRuleList);
       setRowCount(response.data.ruleCounts);
    })
    .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
})
    .finally (() => { setIsLoading (false); 
        var my_element = document.getElementById("top");
        if(my_element!== null || my_element!== undefined || my_element!== '')
        {
            // my_element.scrollIntoView({ }); 

        }   
    
    });
  }, [ page, pageSize , refreshState , sortModel , filterModel , searchText ]);

   const deleteRule = useCallback (id => {
       if (id != null) {
           setRulesLoading (true);
           voucherConfigApiService.deleteAwardRule (id) 
           .then (response => {
               if (!response || !response.data) { return HWMessage.show ("Error while deleteing the rule", "error"); }
               
               HWMessage.show("Delete Request Successful" , "success");
               setRowCount (rowCount - 1);
               setRows (prev => { prev = prev.filter (i => i.id !== id); return [ ...prev ] });
           })
           .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
           .finally (() => { setRulesLoading (false) });
       }
   });

    const handleDelete = (e,id) => {deleteRule (id)}

    const columns = [
       { field : 'voucherCode',          headerName : 'Voucher Category',    filterable : true,  sortable : true, flex : 2.5},
        { field : 'type',          headerName : 'Award Id',          filterable : true,  sortable : true, flex : 2.5 },

       { field : 'description',    filterable :  false ,      headerName : 'Description',           sortable : true, flex : 2.5 },
       { field : 'quantity',   filterable :  false ,   headerName : 'QTY',      sortable : true, flex :1.5 },
       { field : 'status',      headerName : 'Process Flag',     filterable : false,  sortable : true, flex :1.5},
       { field : 'validity',      headerName : 'Validity',     filterable: false,  sortable : true, flex : 3.7,
       renderCell : cellParams => {  
        var { startDate, endDate } = cellParams.row;
        var formattedStart = new Date (startDate).toLocaleDateString();
        var formattedEnd = new Date (endDate).toLocaleDateString();

        return <span  > { `${ formattedStart } - ${ formattedEnd }` } </span>;
    } },

    { field : 'createTime', headerName : 'Create Date', filterable: false ,  flex:1.5, renderCell : cellParams => {
        var date = cellParams.row.createTime;
        var dateString = date.split('T');
        var d = new Date (dateString).toLocaleDateString();

        return <span> {d} </span>
    } },
        ];
  
  if(vouchers.includes("Action") === true ){
    columns.push({ field : 'Edit',headerName : '',filterable : false, sortable : false, flex : 1,  renderCell : cellParams => <> <IconButton onClick= { e => { setSelectedRuleId(prev => { return cellParams.row.id});  handleClick (); } }  > <Edit style={{color: '#138DE8' }} className = { classes.actionIcon } /> </IconButton>  </>  }
    )
     columns.push( { field : 'action',headerName : 'Actions', flex : 1, filterable: false ,  renderCell : cellParams => {
        return (<> 
        <IconButton className = { classes.deleteButton } onClick = { e => {  setDeleteOpen (true); setDeletingRule(cellParams.row)} } > <Delete /> </IconButton> 
        </> );}  }  )
  }


    function CustomLoadingOverlay() {
        return (
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
      }
      const useDataGridStyles = makeStyles(theme => ({
        root: {
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiButton-label' : { color : '#138DE8' }
        }
      }));

      const dataGridClasses = useDataGridStyles();

      const history = useHistory ();

    return (
        <Fragment>
            <BlockUi blocking = { isLoading } message = { 'Please wait while we fetch.' } >
                <Card id="top">
                    <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                        <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        <h4 className = { classes.cardIconTitle } > 
                            Award Rule
                        </h4> 
                    </CardHeader>
                    <CardBody>  
                        <GridContainer style = {{ justifyContent : 'space-between' }}>
                            <GridItem>   
                                <SearchBar
                                    width = '300px' 
                                    handleSearch = { setSearchText }
                                    handleRefresh = { setRefreshState }
                                    refreshState = { refreshState }
                                /> 
                            </GridItem>
                            <GridItem>
                            { vouchers.includes("Action") === true &&   <Fab onClick = { addRule } variant = 'extended' aria-label="add" style = {{ background : '#FF9017', color : 'white' }}  > <GridAddIcon   /> Add Rule</Fab>}
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem className = { classes.bottomGrid } xs = {12} md = {12} >
                                    <DataGrid 
                                        className = { dataGridClasses.root }
                                        showToolbar
                                        page = { page }
                                        pageSize = { pageSize }
                                        rows = { rows }
                                        rowCount = { rowCount }
                                        columns = { columns }
                                        pagination
                                        paginationMode = 'server'
                                        onPageChange = { changeParams => setPage (changeParams) }
                                        onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams); } }

                                        sortingMode="server" 
                                        onSortModelChange = { params => { 
                                          
                                          if (params.length > 0) 
                                              setSortModel (params[0]);
                                        }}
                                      
                                        filterMode = 'server'
                                        onFilterModelChange = { (params) => {
                                          if (params.items.length > 0) {
                                            setFilterModel (prev => ({ ...params.items[0] })); 
                                          }
                                        }}
                                        density = 'compact'
                                        autoHeight
                                        rowsPerPageOptions ={ [25, 50, 100 ]}
                                        //   loading = { requestsLoading }
                                        components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 
                                        disableExtendRowFullWidth = { false }
                                    />
                            </GridItem>
                        </GridContainer>

                    </CardBody>
               
                </Card>
                <RuleDialog     
                ruleId = { selectedRuleId }
                open = { ruleOpen }     
                setOpen = { setRuleOpen } 
                createMode = { createMode } 
                rules = { rows }
                setRules = { setRows }
                refreshState = { refreshState } 
                setRefreshState = { setRefreshState }
            />
             <ConfirmationDialog 
            dialogTitle = { 'Confirm Award Rule Deletion' } 
            dialogMessage = { 'Are you sure you want to delete the award voucher rule.' } 
            confirmingAction = { e => handleDelete(e, deletingRule) } open = { deleteOpen } setOpen = { setDeleteOpen } 
        /> 
            </BlockUi>
            
        </Fragment>
    );
};