import { Button, CardActions, IconButton, makeStyles,Typography } from '@material-ui/core';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState, useEffect, Fragment } from 'react';
import { ArrowForward, Delete, People } from '@material-ui/icons';
import Edit from '@material-ui/icons/Edit';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import CardIcon from 'components/Card/CardIcon';
import HWMessage from 'HWLibrary/HWMessage';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import userSegmentApiService from './APIService';
import { Dialog,DialogContent,DialogTitle } from '@material-ui/core';
import { useHistory ,Link } from "react-router-dom";
import BlockUi from 'react-block-ui';
import ForwardIcon from '@material-ui/icons/Forward';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import SearchBar from 'views/Merchant/SearchBar';
import { CustomToolbar } from 'views/Members/Member';
import { CustomLoadingOverlay } from 'views/Members/Member';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles (theme => ({
    ...styles,
    root: {
        '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300, alignItems: 'center' },
        '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
        '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
        '& .MuiCheckbox-root': { borderRadius: '30px', backgroundColor: 'rgba(0, 0, 0, 0, 0)' },
        '& .Mui-checked': {borderRadius: '3px', backgroundColor: 'rgba(0, 0, 0, 0, 0)', color: 'rgba(200, 0, 0, .1)'},
        '& .MuiDataGrid-rowCount' : { fontWeight : '100'},
        '& .MuiButton-label' : { color : '#138DE8' },
        '& .MuiDataGrid-row:hover' : {cursor:"pointer"}
  
      },
    segmentButton : { background : '#138DE8', color : 'floralwhite', textTransform : 'initial', fontSize : 18, height : 50, width : 250 },
    segmentCSVButton : { background : '#138DE8', color : 'floralwhite',marginLeft:10, textTransform : 'initial', fontSize : 18, height : 50, width : 250 } ,
    bottomGrid : { marginTop : 10},
    dialogContent : { width : 1200 },
    dialogTitle : { width : 1200 }
}));

 
function UserSegmentListDialog (props) {

    const {open, segmentId , setOpen } = props;
    const handleClose = e => { props.setOpen (false); };

    const [ rows, setRows ] = useState ([]);
    const [ page, setPage ] = useState (0);
    const [ loading, setLoading ] = useState (false);
    const [ rowCount, setRowCount ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [ firstSortId, setFirstSortId] = useState();
    const [ lastSortId, setLastSortId ] = useState();
    const [ pageDelta, setPageDelta ] = useState(0);
    
    const classes = useStyles ();
    const columns = [
        { field : "membershipNumber", headerName : 'Membership Number', flex : 2 },
        { field : "name", headerName : 'Name', flex : 2.5 },
        { field : "email", headerName : 'Email Address', flex : 3 },
        { field : "tier", headerName : ' Tier', flex : 1 },
        { field : "gender", headerName : 'Gender', flex : 1 }
    ];

    useEffect (() => {
        if (props.segmentId !== null) {
            var paginationBookmark = (page) * pageSize;
            var fetchLimit = pageSize;
            setLoading (true);
            userSegmentApiService.fetchSegmentMembers (props.segmentId, paginationBookmark, fetchLimit, firstSortId, lastSortId, pageDelta)
            .then (response => { 
                if (! response || ! response.data) { return HWMessage.show ("Data cannot be fetched at moement","error"); }
                setFirstSortId (response.data.firstSortId);
                setLastSortId (response.data.lastSortId);
                setRowCount (response.data.totalMembers);
                setRows (response.data.members);
                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => setLoading (false));
        }
    }, [ pageSize, page,props.refreshState]);

    const useDataGridStyles = makeStyles(theme => ({
        root: {
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiButton-label' : { color : '#138DE8' }
        }
      }));
      const dataGridClasses = useDataGridStyles();


    return (
        <Dialog fullScreen open = { props.open } onClose = { handleClose } maxWidth={ maxWidth }  >
            <center>
                <Button raised onClick={handleClose} variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} aria-label="close">
                    Close Segment List
                </Button>
            </center>
            <DialogTitle  > Member Segment List </DialogTitle>
            <DialogContent >
                <DataGrid 
                        className = { dataGridClasses.root }
                        showToolbar
                        components={{ Toolbar: CustomToolbar }} 
                        density='compact'
                        rows={ rows }
                        pageSize= { pageSize } 
                        page={ page }
                        fullWidth
                        loading = { loading }
                        paginationMode = 'server'
                        rowCount = { rowCount }
                        onPageChange = { params => { setPage (params); setPageDelta (params-page) } }
                        onPageSizeChange = { params => { setPage (0); setPageSize (params); } }
                        columns= { columns }
                        autoHeight
                        rowsPerPageOptions ={ [25, 50, 100 ]}
                    />
            </DialogContent>
            
        </Dialog>
    );
}

export default function UserSegments (props) {

    if (HWLocalStorage.load ("previousVouchersTab") !== null ) 
    HWLocalStorage.clear("previousVouchersTab");
    if(HWLocalStorage.load ("previousVouchersRuleTab") !== null)
     HWLocalStorage.clear("previousVouchersRuleTab");

    var seg=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    seg= [...obj["UserSegments"]];


    const classes = useStyles ();

    const [ loading, setLoading ] = useState (false);
    const [ open, setOpen ] = useState (false);
    const [ selectedSegment, setSelectedSegment  ] = useState (null);
    const [ editSegment, setEditSegment ] = useState (null);
    const [ userSegmentList, setUserSegmentList ] = useState ([]);
    const [ deleteOpen, setDeleteOpen ] = useState (false);
    const [ refreshState, setRefreshState ] = useState (false);
    const [ deleteIdx, setDeleteIdx ] = useState (null);
    const [ searchText, setSearchText ] = useState ('');
    const [ page, setPage ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ rows, setRows ] = React.useState ([]);
    const [ rowCount, setRowCount ] = useState (0);
   
    useEffect (() => {
            setLoading (true);
            var paginationBookmark = ((page) * pageSize);
            var fetchLimit = pageSize;
            userSegmentApiService.fetchSegmentList (searchText,paginationBookmark,fetchLimit)
            .then (response => { 
                if (! response || ! response.data) { return HWMessage.show ("Data cannot be fetched at moement","error"); }
                setUserSegmentList (response.data.userSegments);
                setRows(response.data.userSegments);
                setRowCount(response.data.userSegmentCount);
                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => setLoading (false));
    }, [ searchText , refreshState,page,pageSize]);


    useEffect (() => {
        setLoading (true);
        userSegmentApiService.fetchBikeModels ()
        .then (response => { 
            if (! response || ! response.data) { return HWMessage.show ("Data cannot be fetched at moement","error"); }
            localStorage.setItem("bikeModels" , JSON.stringify(response.data.bikeModels) );            
        }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() => setLoading (false));
}, [ ]);


    const columns = [
        { field: 'name', headerName: 'Segment Name', minWidth:300, flex: 3,filterable :  false,sortable:false,renderCell : cellParams => <span onClick = { (e) => seg.includes("Action")  ===true  ? handleEditClick (e, cellParams.row.id) : {} } > <Link style = {{ color : '#138DE8', fontWeight: 400 }} > { cellParams.row.name } </Link>  </span> },
        { field: 'createTime', headerName: 'Created Date', minWidth:300, flex: 2,filterable :  false,sortable:false,renderCell: (cellparams) => <span>{ new Date(cellparams.row.createTime).toLocaleDateString() }</span> },
        { field: 'memberCount', headerName: 'Total Users', minWidth:130,flex : 1,filterable :  false,sortable:false,renderCell : cellParams => <span onClick = { (e) => handleOpenClick(e, cellParams.row.id)} > <Link style = {{ color : '#138DE8', fontWeight: 400 }} > { cellParams.row.memberCount } </Link>  </span> }    
    ];  

    if(seg.includes("Action")  ===true ){ 
        columns.push({field: 'Edit', headerName : 'Edit', filterable: false,sortable:false,minWidth:30,flex:0.5, renderCell : (cellParams) => <><IconButton onClick = { e => handleEditClick (e, cellParams.row.id) /*<Link to={"/user-segment/"}> </Link>*/ }  > <Edit style = {{ color : '#138DE8' }} /> </IconButton> </>})
        columns.push ({ field : 'Delete',headerName : 'Delete',filterable : false, sortable : false, minWidth:30,flex:0.5,renderCell : (cellParams) =><> <IconButton onClick = { e => { {handleDeleteClick (cellParams.row.id); setDeleteOpen (true);} } } > <Delete style = {{ color : '#FF9017' }} /> </IconButton> </>});
    }

    const handleOpenClick = (e, segmentId) => {
        setSelectedSegment (segmentId);
        setOpen (true);
        setRefreshState (! refreshState);
    };

    const handleSegmentDelete = (e,segmentId) => {
            var selected = userSegmentList.filter((item) => item.id == segmentId)[0];
            setSelectedSegment (selected.id);
            let updatedSegment = selected;
            updatedSegment.status = "Inactive";
            userSegmentApiService.updateUserSegment(updatedSegment)
            .then (response => { 
                if (! response || ! response.data) { return HWMessage.show ("Data cannot be fetched at moement","error"); }
                setUserSegmentList (userSegmentList.filter((item) => item.id !== segmentId ));   
                setRows(userSegmentList.filter((item) => item.id !== segmentId ) )
                setRowCount(userSegmentList.filter((item) => item.id !== segmentId)?.length)  
                setDeleteIdx (null);
                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => setLoading (false)); 
    };


    const history = useHistory ();
    const handleEditClick = (e,segmentId) => {
        setEditSegment (segmentId);
        history.push({pathname : "create-user-segment", params : {state: { segmentId : segmentId }}});
    };
    const handleDeleteClick = (segmentId) => {
        setDeleteIdx (segmentId); 
        
    }
    
    return (
        <Fragment>
            <BlockUi blocking = { loading } message = { 'Please Wait. Fetching Segments' } >
                <Card id="top">
                     <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                         <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                         <h4 className = { classes.cardIconTitle } > 
                             Member Segments
                         </h4> 
                     </CardHeader>
                     <CardBody>  
                        <GridContainer style = {{ justifyContent : 'space-between' }} >
                            <GridItem>
                                <SearchBar  
                                            width = '300px' 
                                            handleSearch = { setSearchText }
                                            handleRefresh = { setRefreshState }
                                            refreshState = { refreshState }
                                        />
                            </GridItem>
                            <GridItem> 
                            {seg.includes("Action")  ===true && <Button onClick= { () =>  history.push({pathname : "create-user-segment", params : {state: { createMode : true}}}) } variant='contained'  className = { classes.segmentButton } > Create Member Segment </Button>}                                         
                            { seg.includes("Action")  ===true  && <Button onClick= { () => history.push ("/ops/create-segment-csv") } variant='contained'  className = { classes.segmentCSVButton }  > Create Segment using CSV </Button>}  
                            </GridItem>
                        </GridContainer>
                            <GridContainer>
                            <GridItem className = { classes.bottomGrid } xs = {12} md = {12} >
                                { userSegmentList.length > 0 && 
                                            <DataGrid 
                                                className={classes.root} 
                                                autoHeight
                                                showToolbar 
                                                density="compact" 
                                                loading = {loading} 
                                                fullWidth
                                                columns={columns} 
                                                rows={rows} 
                                                rowCount = { rowCount }
                                                paginationMode = 'server'
                                                page = { page }
                                                pageSize = { pageSize }
                                                components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 
                                                onPageChange = { (gridChangeParams) => { setPage (gridChangeParams); } }
                                                onPageSizeChange = { (gridChangeParams) => { setPage (0); setPageSize(gridChangeParams); } }
                                                rowsPerPageOptions ={ [25, 50, 100 ]}
                                                filterMode = 'server'
                                                sortingMode = 'server'
                                            /> 
                                            } 
                                            {
                                                (userSegmentList.length === 0 || userSegmentList === null) &&
                                                <center> <Typography variant = 'h5' color = 'textSecondary' > No Segments Available</Typography></center>
                                            }
                                </GridItem>
                            </GridContainer>
                     </CardBody>
                 </Card>
            </BlockUi>
            {  <ConfirmationDialog open = { deleteOpen } setOpen = { setDeleteOpen } confirmingAction = { e =>  handleSegmentDelete(e, deleteIdx)}  dialogTitle = { 'Confirm Deleting Member Segment' } dialogMessage = { 'Are you sure you want to delete Member Segment. This would affect multiple modules in which this segment had been used.' } /> }
            <UserSegmentListDialog  open = { open } segmentId = { selectedSegment } setSelectedSegment = { setSelectedSegment } setOpen = { setOpen } refreshState = { refreshState } setRefreshState = { setRefreshState } />
        </Fragment>
    );

}