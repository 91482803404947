import axios from 'axios';
import authService from 'HWLibrary/ApiService';
import appConfig from '../../HWLibrary/HWConfig';

class PaymentApiService {

    constructor() {
      if (! PaymentApiService.instance) { PaymentApiService.instance = this }
      return PaymentApiService.instance
    }
  
    defaultAuthConfig = () => {
      const accessToken =  authService.getCachedAccessToken();
      return { headers: { Authorization: { 'x-api-key': '4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45' } } };
    };

    fetchTransaction = (transactionId) => {
      const url = `${appConfig.serverBaseUrl}/payment/transactions/${transactionId}`;
      const config = { ...this.defaultAuthConfig()};
      return axios.get(url, config);
    };

    fetchBankList = ( transactionModel ) => {
      const url = `${appConfig.serverBaseUrl}/payment/bank-list-enquiry`;
      const config = { ...this.defaultAuthConfig(), params : { "transaction-model": transactionModel } };
      return axios.get(url, config);
    };
    raisePaymentRequest = (request) => {
        const url = "https://uat.mepsfpx.com.my/FPXMain/seller2DReceiver.jsp";
        const config = { headers: {"Content-Type": "application/x-www-form-urlencoded"} };
        return axios.post (url, {...request.fields}, config);
    };
    signARRequestFromLMS = signRequest => {
      const url = `${ appConfig.serverBaseUrl }/payment/fpx-sign`;

      const accessToken =  authService.getCachedAccessToken();
      const config = { Authorization : `Bearer ${ accessToken }` };
      const request = { ...signRequest };
      return axios.post (url, request, config);
    };  

};

const paymentApiService = new PaymentApiService();
export default paymentApiService;