// import uuid from 'react-uuid'
import HWLog from './HWLog';

class HWLocalStorage {

    static load = (keyString) => {
        try { return JSON.parse(localStorage.getItem(keyString)); }
        catch (error) { HWLog.error(error); return null; }
    }

    static save = (state, keyString) => {
        try { localStorage.setItem(keyString, JSON.stringify(state)); }
        catch (error) { HWLog.error(error); }
    }

    static clear = (keyString) => {
      try { localStorage.removeItem(keyString); }
      catch (error) { HWLog.error(error); }
    }

    static clearWithPrefix = (keyPrefix) => {
        if (!keyPrefix) return;
        Object.keys(localStorage).forEach(itemKey => {
            if (itemKey.startsWith(keyPrefix)) {  window.localStorage.removeItem(itemKey); }
        })
    }

    // static deviceId = () => {
    //     let deviceIdStorageKey = "hw-ma-device-id";
    //     let deviceId = this.load(deviceIdStorageKey);
    //     if (!deviceId) { deviceId = uuid(); this.save(deviceId, deviceIdStorageKey); }
    //     return deviceId;
    // }

}

export default HWLocalStorage;
