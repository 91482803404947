import React, { useState, Fragment, useEffect } from 'react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { People } from '@material-ui/icons';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import CardFooter from 'components/Card/CardFooter';
import { Button, Checkbox, makeStyles, TextField, Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from 'HWLibrary/HWInput';
import WeekDaySelector from './WeekDaySelector';
import HWMessage from 'HWLibrary/HWMessage';
import { useHistory  } from "react-router-dom";
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import moment from 'moment';

const useStyles = makeStyles (theme => ({
    ...styles,
    card : { borderRadius : 10 },
    gridItem : { marginTop : 5 },
    draftButton : { background : '#FF9017', color : 'white', marginRight : 5 },
    proceedButton : { background : '#138DE8', color : 'white' },
    cardFooter : { justifyContent : 'center' }
}));


function PointsMultiplierElement (props) {
    const { element, setElement, currentCampaign ,setCurrentCampaign, index, setPointsMultiplierList, pointsMultiplierList, campaignMode } = props;
    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});
    
    console.log(campaignMode);
    const addPointRule = event => {
        let newLowerLimit = 0, prevMaxLimit = -1;
        
        if (pointsMultiplierList.length > 0) {
            for (var i = 0; i < pointsMultiplierList.length; i++) {
                prevMaxLimit = Math.max(prevMaxLimit, pointsMultiplierList[i].upperLimit);
            }
        }
        newLowerLimit = prevMaxLimit + 1;
       // pointsMultiplierList.push ();
        setPointsMultiplierList (prev => [ ...prev, { lowerLimit : newLowerLimit, upperLimit : 99999999 } ]);
    };

    const addPointRule2 = event => {
        let newLowerLimit = 0, prevMaxLimit = -1;
        
        if (currentCampaign.eventSchedule.pointTiers.length > 0) {
            for (var i = 0; i < currentCampaign.eventSchedule.pointTiers.length; i++) {
                prevMaxLimit = Math.max(prevMaxLimit,currentCampaign.eventSchedule.pointTiers[i].upperLimit);
            }
        }
        newLowerLimit = prevMaxLimit + 1;
        
        setPointsMultiplierList (prev => [ ...prev, { lowerLimit : newLowerLimit, upperLimit : 99999999 } ]); };
        // setCurrentCampaign(prev => )

    const validateValues2 = () => {
        const fieldNames= ['lowerLimit' , 'upperLimit' , 'pointsMultiplierFromMerchant' , 'pointsMultiplierFromYamaha' ];
        for (var i = 0; i < fieldNames.length; i++) {
         var field = fieldNames[i];
         let value = fetchValue(field);
         console.log(field,value);
         var res2 = validateValues(field, value);
         console.log(res2);
         if(res2 === false)
         {
             return false;
         }
     }
     return true;
    }

    const savePointsRule = event => {  
        var res = validateValues2();
        if (res === false) { return HWMessage.show ("Errors in one or more fields, please rectify before proceeding","error");    }
        else { 
            console.log(res);
        }
    };

    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    };
   const fetchValue = fieldName => {
      var index = props.index ;
       var configObject = currentCampaign.campaignEventConfig;  
       if( configObject && configObject.pointTiers)
       {
       return configObject.pointTiers[index][fieldName];
       }
       else
       {
           return configObject.pointTiers ;
       }
    }  

    const validateValues = (field, value) => {  
        switch (field) {
            case 'lowerLimit' : 
                if (value === '' || value < 0 || isNaN(value)) {
                    setHasError ({ ...hasError, [ field ] : true });
                    setErrorMessage ({ ...errorMessage, [ field ] : 'Invalid values provided' });
                    return false;
                } 
                case 'upperLimit' : 
                if (value === '' || value < 0 || isNaN(value)) {
                    setHasError ({ ...hasError, [ field ] : true });
                    setErrorMessage ({ ...errorMessage, [ field ] : 'Invalid values provided' });
                    return false;
                } 
                case 'pointsMultiplierFromMerchant' : 
                if (value === '' || value < 0 || isNaN(value)) {
                    setHasError ({ ...hasError, [ field ] : true });
                    setErrorMessage ({ ...errorMessage, [ field ] : 'Invalid values provided' });
                    return false;
                } 
                case 'pointsMultiplierFromYamaha' : 
                if (value === '' || value < 0 || isNaN(value)) {
                    setHasError ({ ...hasError, [ field ] : true });
                    setErrorMessage ({ ...errorMessage, [ field ] : 'Invalid values provided' });
                    return false;
                } 

            
        }
        setHasError ({ ...hasError, [ field ] : false });
        setErrorMessage ({ ...errorMessage, [ field ] : null });
        return true;
    };

    const captureInput = event => {
        const { name, value } = event.target;
        let field = name;
        var res = validateValues (field, value);
        console.log("Validation result", event.target, res, field, value);
      pointsMultiplierList[index][name] = +value;
      setPointsMultiplierList (pointsMultiplierList);
      console.log(pointsMultiplierList);
      if(currentCampaign.type === 'Event')
      {
          if(props.campaignMode === true){
            console.log("hello1")

            setCurrentCampaign (prev => ({ ...prev, campaignEventConfig : { ...prev.campaignEventConfig, pointTiers : pointsMultiplierList } }));

          }
          else{
              console.log("hello2");
              setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, pointTiers : pointsMultiplierList } }));

          }

      }
      else if (currentCampaign.type === 'MerchantEvent')
      {
        if(props.campaignMode === true){
            setCurrentCampaign (prev => ({ ...prev, campaignMerchantEventConfig : { ...prev.campaignMerchantEventConfig, pointTiers : pointsMultiplierList } }));

        }
else{
    console.log("hello2");
    setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, pointTiers : pointsMultiplierList } }));

}
      }
      else if(currentCampaign.type === 'GenBluStore')
      {

        if(props.campaignMode === true)
        {
            setCurrentCampaign (prev => ({ ...prev, genbluEcommerceConfig : { ...prev.genbluEcommerceConfig, pointTiers : pointsMultiplierList } }));

        }
else{
    console.log("hello2");
    setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, pointTiers : pointsMultiplierList } }));

}
      }
    };
    
    return (
        
            <GridContainer >
                <GridItem xs = {6} md = {6} sm = {6} > 
                    <GridContainer >
                        <GridItem xs = {12} sm = {12} md = {12} >   
                            <h3> Points Tier </h3>
                            <HWInput label = {'Lower Limit'} onBlur = {captureInput} type = 'number' value={ pointsMultiplierList[index].lowerLimit } helperText = { fetchErrorMessage ('lowerLimit') }  hasError = { checkError ('lowerLimit') } name = { 'lowerLimit' } /> to <HWInput onBlur = {captureInput} type = 'number' value={ pointsMultiplierList[index].upperLimit  } helperText = { fetchErrorMessage ('upperLimit') } hasError = { checkError ('upperLimit') } label = { 'Upper Limit' } name = { 'upperLimit' }  />
                        </GridItem>
                        <GridItem xs = {12} sm = {12} md = {12} > 
{   currentCampaign &&  currentCampaign.type !== 'GenBluStore' && <HWInput required = {true} name = { 'pointsMultiplierFromMerchant' } value={  pointsMultiplierList[index].pointsMultiplierFromMerchant } label = { 'Points By Merchant' } type = 'number'  onBlur = {captureInput} helperText = { fetchErrorMessage ('pointsMultiplierFromMerchant') } hasError = { checkError ('pointsMultiplierFromMerchant')} />
}                            
{  currentCampaign &&  currentCampaign.type !== 'MerchantEvent' && <HWInput required = {true} name = { 'pointsMultiplierFromYamaha' } value={  pointsMultiplierList[index].pointsMultiplierFromYamaha } label = { 'Points By Yamaha' } type = 'number' helperText = { fetchErrorMessage ('pointsMultiplierFromYamaha') } hasError = { checkError ('pointsMultiplierFromYamaha') } onBlur = {captureInput} />
}                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs = {6} md = {6} sm = {6} > 
                    <h3> Points To Be Accrued </h3>
{           currentCampaign &&  currentCampaign.type === 'GenBluStore' && <HWInput required = {true} value = { isNaN(pointsMultiplierList[index].pointsMultiplierFromMerchant + pointsMultiplierList[index].pointsMultiplierFromYamaha) ? (pointsMultiplierList[index].pointsMultiplierFromYamaha) : pointsMultiplierList[index].pointsMultiplierFromYamaha + pointsMultiplierList[index].pointsMultiplierFromMerchant } onBlur = {captureInput}  label = { 'Points to be Accrued' } />
}                    

{  currentCampaign &&  currentCampaign.type === 'MerchantEvent' &&  <HWInput required = {true} value = { isNaN(pointsMultiplierList[index].pointsMultiplierFromMerchant + pointsMultiplierList[index].pointsMultiplierFromYamaha) ? (pointsMultiplierList[index].pointsMultiplierFromMerchant) : pointsMultiplierList[index].pointsMultiplierFromYamaha + pointsMultiplierList[index].pointsMultiplierFromMerchant } onBlur = {captureInput}  label = { 'Points to be Accrued' } />
}
{  currentCampaign &&  currentCampaign.type === 'Event' &&  <HWInput required = {true} value = { isNaN(pointsMultiplierList[index].pointsMultiplierFromMerchant + pointsMultiplierList[index].pointsMultiplierFromYamaha) ? (pointsMultiplierList[index].pointsMultiplierFromMerchant) : pointsMultiplierList[index].pointsMultiplierFromYamaha + pointsMultiplierList[index].pointsMultiplierFromMerchant } onBlur = {captureInput}  label = { 'Points to be Accrued' } />
}
                </GridItem>
                <Button variant = "contained" style ={{color:"floralwhite" , backgroundColor: "#138DE8" , margin : 5 , textTransform:'none'}} onClick = { savePointsRule } > Save Points Rules </Button> 
                <Button variant = "contained" style ={{color:"floralwhite" , backgroundColor: "#FF9017",margin : 5, textTransform: 'None'}} onClick = {props.campaignMode === true ? addPointRule : addPointRule2 } > Add Point Rule </Button> 
            </GridContainer>
    );
};

export default function PointsRule (props) {
    var campaignarray=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    campaignarray= [...obj["Campaigns"]];
    const classes = useStyles ();
    const history = useHistory ();

    const [ advancedPointsRule, setAdvancedPointsRule ] = useState ((props.currentCampaign && props.currentCampaign.eventSchedule && props.currentCampaign.eventSchedule.pointTiers) ? true : false);
    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});
    const [ loading, setLoading ] = useState (false);
    const [ valid, setValid ] = useState(false);
    const { currentCampaign, setCurrentCampaign ,campaignMode  } = props;
    console.log(props.campaignMode);
    console.log("advancedPointsRule",advancedPointsRule);

    // Stop user from accessing this page if current campaign is not present.

    const fetchValueByType = fieldName => {
        var configObject = null
        switch (currentCampaign.type) {
            case 'Advertisement' : configObject = currentCampaign.adConfig; break;
            case 'GenBluStore'  : configObject = currentCampaign.genbluEcommerceConfig; break;
            case 'Event' : configObject = currentCampaign.campaignEventConfig; break;
            case 'MerchantEvent' : configObject = currentCampaign.campaignMerchantEventConfig; break;
        }
        return configObject [ fieldName ];
    };  
    const [ pointTiers, setPointsTiers ] = useState ([]);
    const [ pointsMultiplierList, setPointsMultiplierList ] = useState ((props.currentCampaign && props.currentCampaign.eventSchedule && props.currentCampaign.eventSchedule.pointTiers)  || []);
    const [ canCompressPointsRule, setCanCompressPointsRule ] = useState ((props.currentCampaign && props.currentCampaign.eventSchedule && props.currentCampaign.eventSchedule.pointTiers) ? true : false);
    console.log("pointsMultiplierList",pointsMultiplierList);
    console.log("canCompressPointsRule",canCompressPointsRule);
    let tierList;
    useEffect (() => {
        if (currentCampaign && currentCampaign.type !== 'Advertisement') {
            console.log("Inside logging");
            tierList = pointsMultiplierList ;
            console.log(tierList);
            if (!tierList) { setCanCompressPointsRule (false); }
            else if (tierList && tierList.length > 1) { setCanCompressPointsRule (false);  }
            else if (tierList && tierList.length === 1) { setCanCompressPointsRule (false); }
        }
    }, [ pointsMultiplierList ]);

    const getCompressedPointsRule = () => {
        
       if (canCompressPointsRule === false && pointsMultiplierList.length >= 1) {
            let tierList = pointsMultiplierList;
            console.log(tierList);
            return (isNaN(tierList[0].pointsMultiplierFromMerchant + tierList[0].pointsMultiplierFromYamaha )? 0 :tierList[0].pointsMultiplierFromMerchant + tierList[0].pointsMultiplierFromYamaha);
       }
       else{
         return currentCampaign && currentCampaign.eventSchedule && currentCampaign.eventSchedule.pointsToBeAccrued;   
       }
    };
    

  
    
    const assignValueByType = (field, value) => {
        console.log("Assigning", currentCampaign.type);
        switch (currentCampaign.type) {
            case 'Advertisement' : setCurrentCampaign (prev => ({ ...prev, adConfig : { ...prev.adConfig, [ field ] : value } })); break;
            case 'GenBluStore'  :  setCurrentCampaign (prev => ({ ...prev, genbluEcommerceConfig : { ...prev.genbluEcommerceConfig, [ field ] : value } })); break;
            case 'Event' : setCurrentCampaign (prev => ({ ...prev, campaignEventConfig : { ...prev.campaignEventConfig, [ field ] : value } })); break;
            case 'MerchantEvent' : setCurrentCampaign (prev => ({ ...prev, campaignMerchantEventConfig : { ...prev.campaignMerchantEventConfig, [ field ] : value } })); break;
        }
        console.log("Current Camapign", currentCampaign);
    };
    const validateValues = (field, value) => {  
        switch (field) {
            case 'pointsAccruedPerCheckIn' : 
                if (value === '' || value < 0 || isNaN(value)) {
                    setHasError ({ ...hasError, [ field ] : true });
                    setErrorMessage ({ ...errorMessage, [ field ] : 'Invalid values provided' });
                    return false;
                } 
                break;

            case 'pointsCeilingForCheckIn' :  
                if (value === '' || value < 0 || isNaN(value)) {
                    setHasError ({ ...hasError, [ field ] : true });
                    setErrorMessage ({ ...errorMessage, [ field ] : 'Invalid values provided' });
                    return false;
                } 
                else {
                    setValid(true);

                }
                break;

            case 'checkInCountCeiling' : 
                if (value === '' || value < 0 || isNaN(value)) {
                    setHasError ({ ...hasError, [ field ] : true });
                    setErrorMessage ({ ...errorMessage, [ field ] : 'Invalid values provided' });
                    return false;
                } 
                else {
                    setValid(true);

                }
                break;
            case 'pointsCeilingPerUserForCheckIn' : 
                    // if(value === 0){
                    //     setValid(true);
                    //     console.log("value is  0 ")
                    //     return true;
                    // }
                    if (value === '' || value  < 0  || isNaN(value)) {
                        console.log("YHA NI AANA THA")
                        setHasError ({ ...hasError, [ field ] : true });
                        setErrorMessage ({ ...errorMessage, [ field ] : 'Invalid values provided' });
                        console.log("value is", value)
                        return false;
                } 
                else {
                    console.log("value is valid")
                    setValid(true);

                }
                return;
            case 'pointsCeilingPerTransaction' : 
                if (value === '' || value < 0 || isNaN(value)) {
                    setHasError ({ ...hasError, [ field ] : true });
                    setErrorMessage ({ ...errorMessage, [ field ] : 'Invalid values provided' });
                    return false;
                } 
                 else {
                    setValid(true);

                }
                break;
                

            case 'pointsCeilingPerUser' : 
            if (value === '' || value < 0 || isNaN(value)) {
                setHasError ({ ...hasError, [ field ] : true });
                setErrorMessage ({ ...errorMessage, [ field ] : 'Invalid values provided' });
                return false;
            } 
            else {
                setValid(true);

            }
            break;

            case 'transactionCountCeilingPerUser' : 
                if (value === '' || value < 0 || isNaN(value)) {
                    setHasError ({ ...hasError, [ field ] : true });
                    setErrorMessage ({ ...errorMessage, [ field ] : 'Invalid values provided' });
                    return false;
                } 
                else {
                    setValid(true);

                }
            case 'pointsCeilingForCampaign' : 
                if (value === '' || value < 0 || isNaN(value)) {
                    setHasError ({ ...hasError, [ field ] : true });
                    setErrorMessage ({ ...errorMessage, [ field ] : 'Invalid values provided' });
                    return false;
                } 
                else {
                    setValid(true);

                }
                break;

            case 'eventStartTime', 'eventEndTime' : 
                if (value === '' || value === null || value === undefined) {
                    setHasError ({ ...hasError, [ field ] : true });
                    setErrorMessage ({ ...errorMessage, [ field ] : 'Invalid values provided' });
                    return false;
                }
                else {
                    setValid(true);

                }
                break;

                case 'pointsToBeAccrued' : 
                if (value === '' || value === null || value <= 0) {
                    setHasError ({ ...hasError, [ field ] : true });
                    setErrorMessage ({ ...errorMessage, [ field ] : 'Invalid values provided' });
                    return false;
                }
                else {
                    setValid(true);

                }
                break;

        }
        setHasError ({ ...hasError, [ field ] : false });
        setErrorMessage ({ ...errorMessage, [ field ] : null });
        return true;
    };

    const captureInput = event => {
        const { name, value } = event.target;
        let field = name;
        var res = validateValues (field, value);
        console.log("Validation result", event.target, res, field, value);
        switch (field) {
            case 'pointsAccruedPerCheckIn': 
            if (props.campaignMode ===  false){ 
                setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, [ field ] : value } })) 
                setCurrentCampaign (prev => ({ ...prev, eventSchedule : { ...prev.eventSchedule, [ field ] : value } })) 

            }
            else{
                assignValueByType(field,+value);
            }
            break;
            case 'pointsCeilingPerUserForCheckIn' :
                if(props.campaignMode ===  false){
                    setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, [ field ] : value } })) 
                    setCurrentCampaign (prev => ({ ...prev, eventSchedule : { ...prev.eventSchedule, [ field ] : value } })) 

                }
                else{
                    assignValueByType(field,+value); 
                }
                
            break ;
            case 'pointsCeilingForCheckIn':
                if(props.campaignMode ===  false){
                    setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, [ field ] : value } })) 
                    setCurrentCampaign (prev => ({ ...prev, eventSchedule : { ...prev.eventSchedule, [ field ] : value } })) 

                }
                else{
                    assignValueByType(field,+value);
                }    
             break; 
            case 'checkInCountCeiling' :
                if(props.campaignMode ===  false){
                    setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, [ field ] : value } })) 
                    setCurrentCampaign (prev => ({ ...prev, eventSchedule : { ...prev.eventSchedule, [ field ] : value } })) 

                }
                else{
                    assignValueByType(field,+value);
                }  break ;
            case 'pointsCeilingPerTransaction' :
                if(props.campaignMode ===  false){
                    setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, [ field ] : value } })) 
                    setCurrentCampaign (prev => ({ ...prev, eventSchedule : { ...prev.eventSchedule, [ field ] : value } })) 

                }
                else{
                    assignValueByType(field,+value);
                }    
            
            break ;
            
            case 'pointsCeilingPerUser' :
                if(props.campaignMode ===  false){
                    setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, [ field ] : value } })) 
                    setCurrentCampaign (prev => ({ ...prev, eventSchedule : { ...prev.eventSchedule, [ field ] : value } })) 

                }
                else{   
            assignValueByType(field,+value); }
            break ;
            case 'transactionCountCeilingPerUser' :
                if(props.campaignMode ===  false){
                    setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, [ field ] : value } })) 
                    setCurrentCampaign (prev => ({ ...prev, eventSchedule : { ...prev.eventSchedule, [ field ] : value } })) 

                }
                else{    
                assignValueByType(field,+value);
                 }
                  break;
            case 'pointsCeilingForCampaign' :
                if(props.campaignMode ===  false){
                    setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, [ field ] : value } })) 
                    setCurrentCampaign (prev => ({ ...prev, eventSchedule : { ...prev.eventSchedule, [ field ] : value } })) 

                }
                else{assignValueByType(field,+value);} break;
            case 'eventStartTime' : 
            if(props.campaignMode ===  false && value !== undefined && value !== null){
                 
        //        alert(new Date(value));
          //      alert(new Date(value).toLocaleDateString( 'myt', { timeZone: 'Asia/Kuala_Lumpur' } ));
                setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, [ field ] : moment(new Date(value).toLocaleString("en-IN", { timeZone:"Asia/Singapore"}), 'DD/MM/YYYY, HH:mm:ss a').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'  } })) 
                setCurrentCampaign (prev => ({ ...prev, eventSchedule : { ...prev.eventSchedule, [ field ] : moment(new Date(value).toLocaleString("en-IN", { timeZone:"Asia/Singapore"}), 'DD/MM/YYYY, HH:mm:ss a').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'  } })) 

            }
            else{
               
                    assignValueByType(field, moment(new Date(value).toLocaleString("en-IN", { timeZone:"Asia/Singapore"}), 'DD/MM/YYYY, HH:mm:ss a').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z' );}break;
            case 'eventEndTime' :
                if(props.campaignMode ===  false && value !== undefined && value !== null){
                 
                    setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, [ field ] :   moment(new Date(value).toLocaleString("en-IN", { timeZone:"Asia/Singapore"}), 'DD/MM/YYYY, HH:mm:ss a').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'  } })) 
                    setCurrentCampaign (prev => ({ ...prev, eventSchedule : { ...prev.eventSchedule, [ field ] :  moment(new Date(value).toLocaleString("en-IN", { timeZone:"Asia/Singapore"}), 'DD/MM/YYYY, HH:mm:ss a').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'  } })) 

                }
                else{
                  
                    assignValueByType(field, moment(new Date(value).toLocaleString("en-IN", { timeZone:"Asia/Singapore"}), 'DD/MM/YYYY, HH:mm:ss a').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z' );}break;


            case 'pointsToBeAccrued' :
                if (props.campaignMode === false)
                {
                    setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, [ field ] : +value } })) 
                    setCurrentCampaign (prev => ({ ...prev, eventSchedule : { ...prev.eventSchedule, [ field ] : +value } })) 

         if(currentCampaign.type === 'Event')
        {
          setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, pointTiers : [{ lowerLimit : 0, upperLimit : 99999999 , pointsMultiplierFromYamaha :((+value) / 2.0 ), pointsMultiplierFromMerchant: ((+value) / 2.0 ) }] } }));
          setCurrentCampaign (prev => ({ ...prev, eventSchedule : { ...prev.eventSchedule, pointTiers : [{ lowerLimit : 0, upperLimit : 99999999 , pointsMultiplierFromYamaha :((+value) / 2.0 ), pointsMultiplierFromMerchant: ((+value) / 2.0 ) }] } }));

        }
        else if (currentCampaign.type === 'MerchantEvent')
        {
            setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, pointTiers : [{ lowerLimit : 0, upperLimit : 99999999,  pointsMultiplierFromMerchant: ((+value) / 2.0 ) }]  } })) 
            setCurrentCampaign (prev => ({ ...prev, eventSchedule : { ...prev.eventSchedule, pointTiers : [{ lowerLimit : 0, upperLimit : 99999999,  pointsMultiplierFromMerchant: ((+value) / 2.0 ) }]  } })) 

        }
        else if(currentCampaign.type === 'GenBluStore')
        {
          setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, pointTiers :[{ lowerLimit : 0, upperLimit : 99999999 , pointsMultiplierFromYamaha : ((+value) / 2.0 ) }] } }));
          setCurrentCampaign (prev => ({ ...prev, eventSchedule : { ...prev.eventSchedule, pointTiers :[{ lowerLimit : 0, upperLimit : 99999999 , pointsMultiplierFromYamaha : ((+value) / 2.0 ) }] } }));

        }
                } 
                else{
                    assignValueByType(field, +value);
                }
                break;
            
        
     
        }
        console.log("Here are the vals", currentCampaign);
    };

    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    };

    var res2 ;
    const validateAllValues = () => {
  
      var fieldNames=[];
       if (currentCampaign && currentCampaign.type && (currentCampaign.type === "Event" || currentCampaign.type === "MerchantEvent") && pointsMultiplierList.length === 0)
                 fieldNames= ['pointsAccruedPerCheckIn' , 'pointsCeilingForCheckIn' , 'checkInCountCeiling' , 'pointsCeilingPerUserForCheckIn' , 'pointsCeilingPerTransaction' , 'pointsCeilingPerUser' , 'transactionCountCeilingPerUser' , 'pointsCeilingForCampaign' , 'eventStartTime' , 'eventEndTime' , 'pointsToBeAccrued'];
       if (currentCampaign && currentCampaign.type && (currentCampaign.type === "Event" || currentCampaign.type === "MerchantEvent") && pointsMultiplierList.length !== 0)
                 fieldNames= ['pointsAccruedPerCheckIn' , 'pointsCeilingForCheckIn' , 'checkInCountCeiling' , 'pointsCeilingPerUserForCheckIn' , 'pointsCeilingPerTransaction' , 'pointsCeilingPerUser' , 'transactionCountCeilingPerUser' , 'pointsCeilingForCampaign' , 'eventStartTime' , 'eventEndTime' ];
       if (currentCampaign && currentCampaign.type === "GenBluStore")
       { 

         if(currentCampaign && currentCampaign.genbluEcommerceConfig ){
             fieldNames= [ 'pointsCeilingPerTransaction' , 'pointsCeilingPerUser' , 'transactionCountCeilingPerUser' , 'pointsCeilingForCampaign' , 'eventStartTime' , 'eventEndTime' , 'pointsToBeAccrued' ];
        } else {
             console.log("asdas");
            fieldNames= [ 'pointsCeilingPerTransaction' , 'pointsCeilingPerUser' , 'transactionCountCeilingPerUser' , 'pointsCeilingForCampaign' , 'eventStartTime' , 'eventEndTime' ];

         }
    }
    
       for (var i = 0; i < fieldNames.length; i++) {
        var field = fieldNames[i];
        let value = fetchValueByType(field);
        console.log("heeeeeeeee",field,value);
        res2 = validateValues(field, value);
        console.log(res2);
        if(res2 === false)
        {
            return false;
        }
    }
    if (props.campaignMode === true  && pointsMultiplierList.length === 0)
    {
     
        if(currentCampaign.type === 'Event')
      {
        setCurrentCampaign (prev => ({ ...prev, campaignEventConfig : { ...prev.campaignEventConfig, pointTiers : [{ lowerLimit : 0, upperLimit : 99999999 , pointsMultiplierFromYamaha :currentCampaign && currentCampaign.campaignEventConfig && (currentCampaign.campaignEventConfig.pointsToBeAccrued / 2.0 ) , pointsMultiplierFromMerchant: currentCampaign && currentCampaign.campaignEventConfig && (currentCampaign.campaignEventConfig.pointsToBeAccrued / 2.0 ) }] } }));

      }
      else if (currentCampaign.type === 'MerchantEvent')
      {
        setCurrentCampaign (prev => ({ ...prev, campaignMerchantEventConfig : { ...prev.campaignMerchantEventConfig, pointTiers : [{ lowerLimit : 0, upperLimit : 99999999,  pointsMultiplierFromMerchant:currentCampaign && currentCampaign.campaignMerchantEventConfig && (currentCampaign.campaignMerchantEventConfig.pointsToBeAccrued  ) }] } }));

      }
      else if(currentCampaign.type === 'GenBluStore')
      {
        setCurrentCampaign (prev => ({ ...prev, genbluEcommerceConfig : { ...prev.genbluEcommerceConfig, pointTiers :[{ lowerLimit : 0, upperLimit : 99999999 , pointsMultiplierFromYamaha : currentCampaign && currentCampaign.genbluEcommerceConfig && (currentCampaign.genbluEcommerceConfig.pointsToBeAccrued  ) }] } }));

      }
    }
    
    return true;
}

const saveDraft = event => { 
    if (!currentCampaign || currentCampaign === null) { return HWMessage.show ("Please enter one or more detail to save as campaign","error"); }
    props.saveDraft ();
    history.push('/ops/campaigns');
};

const handleDraftClick = (event) => {
    props.setOpen(true);

   // saveDraft (event);
}



const saveProceed = event => { 
         var res = validateAllValues();

  //   props.advanceStep();

       
         if(res === false ) { return HWMessage.show ("Errors in one or more fields, please rectify before proceeding","error");
        }
         else {props.advanceStep();}
        };

    const handleDaysChange = (daysList) => {
        console.log(daysList) ;
        if(props.campaignMode === true )
          assignValueByType ('dayOfWeekSet', daysList);
        else
          setCurrentCampaign (prev => ({ ...prev, eventConfig : { ...prev.eventConfig, dayOfWeekSet : daysList } }));

        };
    
    const handleAdvancedClick = event => {
        if(pointsMultiplierList.length === 0) {
            addPointRule();
        } 
        setAdvancedPointsRule (!advancedPointsRule);
    };

    const handleCheckboxChange = event => {
      console.log(props.campaignMode, event.target.name , event.target.checked);
      if (props.campaignMode === true && event.target.checked === true)
      {
        setCurrentCampaign (prev => ({ ...prev, campaignEventConfig : { ...prev.campaignEventConfig, qrCodeScanPermission : "Member" } }));
      }
      if (props.campaignMode === true && event.target.checked === false)
      {
        setCurrentCampaign (prev => ({ ...prev, campaignEventConfig : { ...prev.campaignEventConfig, qrCodeScanPermission : "MerchantAndMember" } }));

      }
      if(props.campaignMode === false &&  event.target.checked === false )
      {
    
        setCurrentCampaign (prev => ({ ...prev , eventSchedule  :{...prev.eventSchedule , qrCodeScanPermission : "MerchantAndMember"} } ) ) ;      
        setCurrentCampaign (prev => ({ ...prev , eventConfig  :{...prev.eventConfig , qrCodeScanPermission : "MerchantAndMember"} } ) ) ; 

        console.log(currentCampaign);
      }
      if(props.campaignMode === false &&  event.target.checked === true ) {
      setCurrentCampaign (prev => ({ ...prev , eventSchedule  :{...prev.eventSchedule , qrCodeScanPermission : "Member"} } ) ) ;
      setCurrentCampaign (prev => ({ ...prev , eventConfig  :{...prev.eventConfig , qrCodeScanPermission : "Member"} } ) ) ;

      console.log(currentCampaign);

      }
    };

    const handleBackClick = (event) => {
        props.backStep();
    }

    const  handleSave = () => {
         if( pointsMultiplierList.length === 0 ){
            if (currentCampaign && currentCampaign.eventSchedule   && currentCampaign.eventSchedule.pointsCeilingForCampaign && currentCampaign.eventSchedule.pointsCeilingPerUser && currentCampaign.eventSchedule.pointsCeilingPerTransaction && currentCampaign.eventSchedule.transactionCountCeilingPerUser)
                props.advanceStep();
            else  
                return HWMessage.show ("Errors in one or more fields, please rectify before proceeding","error") }

        
        if (pointsMultiplierList.length > 0){
            if (currentCampaign && currentCampaign.eventSchedule   && currentCampaign.eventSchedule.pointsCeilingForCampaign && currentCampaign.eventSchedule.pointsCeilingPerUser && currentCampaign.eventSchedule.pointsCeilingPerTransaction && currentCampaign.eventSchedule.transactionCountCeilingPerUser)
                 props.advanceStep();
            else { return HWMessage.show ("Errors in one or more fields, please rectify before proceeding","error") }
        }
    };


    const checkDisabled = () => {

       // alert (JSON.stringify(props.currentCampaign));
        if (props.campaignMode === false) {
            if (props.currentCampaign && props.currentCampaign.status && props.currentCampaign.status === 'Draft') {
                return false;
            } else if (props.currentCampaign && props.currentCampaign.status && props.currentCampaign.status === 'Enabled') {
                if (props.campaignType === 'Live' ) 
                    return true;
            } 
        } else {
            return false;
        }
    }


    const addPointRule = event => {
        let newLowerLimit = 0, prevMaxLimit = -1;
        
        if (pointsMultiplierList.length > 0) {
            for (var i = 0; i < pointsMultiplierList.length; i++) {
                prevMaxLimit = Math.max(prevMaxLimit, pointsMultiplierList[i].upperLimit);
            }
        }
        newLowerLimit = prevMaxLimit + 1;
        setPointsMultiplierList (prev => [ ...prev, { lowerLimit : newLowerLimit, upperLimit : 99999999 } ]);
        
    };

    const checkEventStart = event => {
        console.log("come in come in new first" )

       if( props.campaignMode === true)
       {
           return false;
       }
       else {
        console.log("come in come in else first" )

           var CampStartDate = currentCampaign && currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.startDate;
           var CampStartTime = currentCampaign && currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.timeOfDayStart;
           var CampStartString = CampStartDate+ 'T' + CampStartTime; 
          if(new Date().toISOString().split(":").slice(0,-1).join(":") < CampStartString) {
            console.log("come in come in elsasasa first" )

               //upcoming
               return false;
           }

           else if(new Date().toISOString() < (currentCampaign &&  currentCampaign.eventSchedule &&  currentCampaign.eventSchedule.eventStartTime ) &&
           new Date().toISOString().split(":").slice(0,-1).join(":") > CampStartString  ){
               //ongoing
               console.log("come in come in elsasasa sadasdsa" )

               return  false ; 
           }

           else{
            console.log("come in come in elsasasa sadasdsssssa" )

               return true;
           }
       }
        
    };
  
    const checkEventEnd = event => {

        if(props.campaignMode === true && currentCampaign && currentCampaign.type==="MerchantEvent" && currentCampaign.campaignMerchantEventConfig && currentCampaign.campaignMerchantEventConfig.eventStartTime)
             return false;
        if(props.campaignMode === true && currentCampaign && currentCampaign.type==="GenBluStore" && currentCampaign.genbluEcommerceConfig && currentCampaign.genbluEcommerceConfig.eventStartTime)
             return false;
        if(props.campaignMode === true && currentCampaign && currentCampaign.type==="Event" && currentCampaign.campaignEventConfig && currentCampaign.campaignEventConfig.eventStartTime)
             return false;
        if( props.campaignMode === true && currentCampaign && currentCampaign.eventSchedule && currentCampaign.eventSchedule.eventStartTime )
        {
            return false;
        }
        else {
            console.log("come in come in")
            var CampStartDate = currentCampaign && currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.endDate;
            var CampStartTime = currentCampaign && currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.timeOfDayEnd;
            var CampEndString = CampStartDate+ 'T' + CampStartTime; 
            
            if(new Date().toISOString() > (currentCampaign &&  currentCampaign.eventSchedule &&  currentCampaign.eventSchedule.eventStartTime ) 
            && (new Date().toISOString().split(":").slice(0,-1).join(":") < CampEndString) )
            {
                console.log("come in come in first" )

                //upcoming
                return false;
            }
 
            else if(new Date().toISOString() < (currentCampaign &&  currentCampaign.eventSchedule &&  currentCampaign.eventSchedule.eventStartTime )  ){
                //ongoing 
                console.log("come in come in second" )

                return  false ; 
            }
 
            else{
                console.log("come in come in last" )

                return true;
            }
        }
         
     };

     const getNewDate = event => {
        var CampStartDate = currentCampaign && currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.startDate;
        var CampStartTime = currentCampaign && currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.timeOfDayStart;
        var CampStartString = CampStartDate+ 'T' + CampStartTime; 
        if (new Date().toISOString().split(":").slice(0,-1).join(":") < CampStartString) 
        {
          return CampStartString;
        }
        else{
         return new Date().toISOString().split(":").slice(0,-1).join(":");
        }
        
          
      }

   
     const getEndDate = () => {
        if(props.campaignMode === false)
             return currentCampaign && currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.timeOfDayEnd && currentCampaign.campaignSchedule.endDate  && (currentCampaign.campaignSchedule.endDate + "T" + currentCampaign.campaignSchedule.timeOfDayEnd );
    };
    
    const getStartDate = () => {
        if(props.campaignMode === false)
             return currentCampaign && currentCampaign &&  currentCampaign.eventSchedule &&  currentCampaign.eventSchedule.eventEndTime && (currentCampaign.eventSchedule.eventEndTime.split(":").slice(0,-1).join(":"));
    };

    const getDate = () => {
        if(props.campaignMode === false)
        {
            return currentCampaign && currentCampaign.eventSchedule && currentCampaign.eventSchedule.eventStartTime && (currentCampaign.eventSchedule.eventStartTime.split(":").slice(0,-1).join(":") > new Date().toISOString().split(":").slice(0,-1).join(":") ? currentCampaign && currentCampaign.eventSchedule &&  currentCampaign.eventSchedule.eventStartTime &&  currentCampaign.eventSchedule.eventStartTime.split(":").slice(0,-1).join(":") : new Date().toISOString().split(":").slice(0,-1).join(":")) ;
        }
        else{
            return (fetchValueByType('eventStartTime') === undefined) ? undefined : fetchValueByType('eventStartTime').split(":").slice(0,-1).join(":") ;

        }
    }
    return (
        <Fragment>
            <Card>
                <CardHeader className = { classes.cardHeader } color = 'rose' icon >
                    <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        <h4 className = { classes.cardIconTitle } > Points Rule  </h4> 
                </CardHeader>     
                <CardBody>
                    <GridContainer >
                        {
                            currentCampaign.type && currentCampaign.type !== 'GenBluStore' &&      
                            <GridItem xs = {12} md = {12} sm = {12} >
                                <Typography gutterBottom variant="h5" component="h2">  
                                    Customer Scans the QR Code
                                    <Checkbox name="checkQRPermission" style = {{ color : '#138DE8' }}  checked={ ( currentCampaign.eventSchedule  && currentCampaign.eventSchedule.qrCodeScanPermission && currentCampaign.eventSchedule.qrCodeScanPermission=== "Member") || (currentCampaign.campaignEventConfig && currentCampaign.campaignEventConfig.qrCodeScanPermission && currentCampaign.campaignEventConfig.qrCodeScanPermission === "Member") ? true : false}  onChange ={handleCheckboxChange}/>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p" >
                                    If selected, Customer would be able to Check In on scanning the QR code and points will be accrued directly on Check In.
                                </Typography>
                            </GridItem>
                        }

                        {   currentCampaign.type && currentCampaign.type !== 'GenBluStore' &&
                            <GridItem xs = {12} md = {12} sm = {12}  >
                                <Card>
                                    <CardHeader> <h3> Check-In Points Rule Configurations </h3>  </CardHeader>
                                    <CardBody>  
                                        <GridContainer> 
                                            <GridItem xs = {6} md = {6} sm = {6} > 
                                                <HWInput  required = {true} label = { 'Points to be accrued on each Check In' } type = 'number' 
                                                   name = { 'pointsAccruedPerCheckIn' }  value = { props.campaignMode === true ? fetchValueByType ('pointsAccruedPerCheckIn') :currentCampaign.eventSchedule.pointsAccruedPerCheckIn ? currentCampaign.eventSchedule.pointsAccruedPerCheckIn : ""  }
                                                    onBlur = { captureInput } helperText = { fetchErrorMessage ('pointsAccruedPerCheckIn') }
                                                    hasError = { checkError ('pointsAccruedPerCheckIn') } 
                                                /> 
                                            </GridItem>
                                            <GridItem xs = {6} md = {6} sm = {6} > 
                                                <HWInput label = { 'Points cap for total Check Ins' } type = 'number'  
                                                  required = {true}  name = { 'pointsCeilingForCheckIn' } value = { props.campaignMode === true ? fetchValueByType ('pointsCeilingForCheckIn') : currentCampaign.eventSchedule.pointsCeilingForCheckIn ? currentCampaign.eventSchedule.pointsCeilingForCheckIn : ""  }
                                                    onBlur = { captureInput } helperText = { fetchErrorMessage ('pointsCeilingForCheckIn') }
                                                    hasError = { checkError ('pointsCeilingForCheckIn') } 
                                                /> 
                                            </GridItem>
                                            <GridItem xs = {6} md = {6} sm = {6} > 
                                                <HWInput label = { 'Check In cap per user' } type = 'number'  
                                                   required = {true} name = { 'checkInCountCeiling' } value = { props.campaignMode === true ? fetchValueByType('checkInCountCeiling'): currentCampaign.eventSchedule.checkInCountCeiling ? currentCampaign.eventSchedule.checkInCountCeiling : "" }
                                                    onBlur = { captureInput } helperText = { fetchErrorMessage ('checkInCountCeiling') }
                                                    hasError = { checkError ('checkInCountCeiling') } 
                                                /> 
                                            </GridItem>
                                            <GridItem xs = {6} md = {6} sm = {6} > 
                                                <HWInput label = { 'Points Ceiling Per User For Check In' } type = 'number'  
                                                  required = {true}  name = { 'pointsCeilingPerUserForCheckIn' } value = { props.campaignMode === true ? fetchValueByType('pointsCeilingPerUserForCheckIn') : currentCampaign.eventSchedule.pointsCeilingPerUserForCheckIn ? currentCampaign.eventSchedule.pointsCeilingPerUserForCheckIn : "" }
                                                    onBlur = { captureInput } hasError = {  checkError ('pointsCeilingPerUserForCheckIn') } 
                                                     helperText = { fetchErrorMessage ('pointsCeilingPerUserForCheckIn') }
                                                /> 
                                            </GridItem>
                                            <GridItem xs = {12} md = {12} sm = {12}  >  
                                                <GridContainer justify = "space-between" style = {{ alignItems : 'center' }} >  
                                                        <GridItem xs = {6} > <Typography variant="body1" color="textSecondary" component="h4" > Check In Allowed Days </Typography> </GridItem>
                                                        <GridItem xs = {6} > 
                                                            <WeekDaySelector 
                                                                name = { 'dayOfWeekSet' }
                                                                days = { props.campaignMode === true ? fetchValueByType ('dayOfWeekSet') : currentCampaign.eventSchedule.dayOfWeekSet }
                                                                handleDaysChange = { handleDaysChange }
                                                                required = {true}
                                                            /> 
                                                        </GridItem>
                                                </GridContainer>
                                            </GridItem> 
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridItem> 
                        }
                        <GridItem xs = {12} md = {12} sm = {12} >
                            <Card>
                                <CardHeader> <h3> Points to be accrued on Purchase </h3>  </CardHeader>
                                <CardBody>  
                                   {                                  
                                        <GridContainer > 
{                                       pointsMultiplierList.length <1 &&     <GridItem xs = {12} md = {12} sm = {12} > <HWInput required = {true} label = { 'Points to be accrued' } name = {'pointsToBeAccrued'} value = { props.campaignMode === true  ? fetchValueByType("pointsToBeAccrued") :getCompressedPointsRule() === undefined || null ? 0 : getCompressedPointsRule()  } onBlur = { captureInput } helperText = { fetchErrorMessage ('pointsToBeAccrued') }
                                                    hasError = { checkError ('pointsToBeAccrued') }  /> x Points </GridItem>
}                                            <GridItem xs = {12} md = {12} sm = {12} > <center>  <Button variant = "contained" style ={{color:"floralwhite" , backgroundColor: "#138DE8", textTransform:'None'}} onClick =  { handleAdvancedClick }  > Advanced Points Rule </Button>  </center> </GridItem>                                            
                                        </GridContainer>
                                    }
                                    { (props.campaignMode === true) && (advancedPointsRule === true || canCompressPointsRule === true) && <GridContainer> { pointsMultiplierList && pointsMultiplierList.map ((i, idx) => <GridItem xs = {12} md = {12} sm = {12} > <PointsMultiplierElement   index = { idx } element = { i }  campaignMode= {props.campaignMode} currentCampaign ={currentCampaign} setCurrentCampaign={setCurrentCampaign} pointsMultiplierList = { pointsMultiplierList } setPointsMultiplierList = {setPointsMultiplierList } /> </GridItem> ) } </GridContainer> }
                                    {  (props.campaignMode === false) && (advancedPointsRule === true || canCompressPointsRule === true ) &&  <GridContainer> { pointsMultiplierList && pointsMultiplierList.map ((i, idx) => <GridItem xs = {12} md = {12} sm = {12} > <PointsMultiplierElement   index = { idx } element = { i } campaignMode= {props.campaignMode} currentCampaign ={currentCampaign} setCurrentCampaign={setCurrentCampaign} setPointsMultiplierList = {setPointsMultiplierList } pointsMultiplierList = { pointsMultiplierList }  /> </GridItem> ) } </GridContainer> }

                                </CardBody>
                                
                            </Card>    
                        </GridItem> 
                        <GridItem >
                        <Card rasied >
                            <CardHeader className = { classes.cardHeader } color = 'rose' icon >
                                <h3 className = { classes.cardIconTitle } > Purchase Points Rule Configurations </h3> 
                            </CardHeader>  
                            <CardBody>
                                <GridContainer >
                                    <GridItem xs = {12} md = {6} sm = {12} > 
                                        <HWInput label = { 'Points cap for the purchase in campaign' }  type = 'number'
                                           required = {true} name = { 'pointsCeilingForCampaign' } value = { props.campaignMode === true ? fetchValueByType('pointsCeilingForCampaign') : currentCampaign.eventConfig.pointsCeilingForCampaign ? currentCampaign.eventSchedule.pointsCeilingForCampaign : ""  }
                                            onBlur = { captureInput } hasError = { checkError ('pointsCeilingForCampaign') } 
                                            helperText = { fetchErrorMessage ('pointsCeilingForCampaign') }
                                        /> 
                                    </GridItem>
                                    <GridItem xs = {12} md = {6} sm = {12} > 
                                        <HWInput label = { 'Points cap per user for Purchase' } type = 'number'
                                          required = {true}  name = { 'pointsCeilingPerUser' } value = { props.campaignMode === true ? fetchValueByType('pointsCeilingPerUser') : currentCampaign.eventConfig.pointsCeilingPerUser ?currentCampaign.eventSchedule.pointsCeilingPerUser : ""  }
                                            onBlur = { captureInput } hasError = { checkError ('pointsCeilingPerUser') } 
                                            helperText = { fetchErrorMessage ('pointsCeilingPerUser') }
                                        /> 
                                    </GridItem>
                                    <GridItem xs = {12} md = {6} sm = {12} > 
                                        <HWInput label = {  'Points cap per Transaction' } type = 'number'
                                          required = {true}  name = { 'pointsCeilingPerTransaction' } value = { props.campaignMode === true ?  fetchValueByType('pointsCeilingPerTransaction') : currentCampaign.eventConfig.pointsCeilingPerTransaction ? currentCampaign.eventSchedule.pointsCeilingPerTransaction : ""  }
                                            onBlur = { captureInput } hasError = { checkError ('pointsCeilingPerTransaction') } 
                                            helperText = { fetchErrorMessage ('pointsCeilingPerTransaction') }
                                        /> 
                                    </GridItem>
                                    <GridItem xs = {12} md = {6} sm = {12} > 
                                        <HWInput label = { 'Transaction cap per User' } type = 'number'
                                          required = {true}  name = { 'transactionCountCeilingPerUser' } value = { props.campaignMode === true ? fetchValueByType('transactionCountCeilingPerUser') : currentCampaign.eventConfig.transactionCountCeilingPerUser ? currentCampaign.eventSchedule.transactionCountCeilingPerUser : " " }
                                            onBlur = { captureInput } hasError = { checkError ('transactionCountCeilingPerUser') } 
                                            helperText = { fetchErrorMessage ('transactionCountCeilingPerUser') }
                                        />  
                                    </GridItem>
                                    
                                    <GridItem> 
                                        <center> 
                                            <GridContainer justify = "space-between" > 
                                            
                                                <GridItem xs = {6} md = {6} sm = {6} > <HWInput required = {true} type = 'datetime-local' disabled={ checkEventStart() } inputProps={{min : (props.campaignMode === true ? new Date().toISOString().split(":").slice(0,-1).join(":")  : getNewDate() ), max : getStartDate() === undefined ? undefined : getStartDate() } } name = {'eventStartTime'} label = { 'Event Start Date / Time' } labelProps = {{ shrink : true }} onBlur = { captureInput } value = {props.campaignMode === true ? fetchValueByType('eventStartTime') &&  fetchValueByType('eventStartTime').split(":").slice(0,-1).join(":"): currentCampaign && currentCampaign.eventSchedule && currentCampaign.eventSchedule.eventStartTime && (currentCampaign.eventSchedule.eventStartTime.split(":").slice(0,-1).join(":")) } onBlur = { captureInput } /> </GridItem> 
                                                <GridItem xs = {6} md = {6} sm = {6} > <HWInput required = {true} type = 'datetime-local' disabled={ checkEventEnd() } inputProps={{ min : getDate() , max : getEndDate() === undefined ? undefined : getEndDate() } } name = {'eventEndTime'} label = { 'Event End Date / Time' } labelProps = {{ shrink : true }} onBlur = { captureInput } value = { props.campaignMode === true ?  fetchValueByType('eventEndTime') && fetchValueByType('eventEndTime').split(":").slice(0,-1).join(":") :  currentCampaign && currentCampaign.eventSchedule && currentCampaign.eventSchedule.eventEndTime && currentCampaign.eventSchedule.eventEndTime.split(":").slice(0,-1).join(":")  } onBlur = { captureInput } /> </GridItem> 
                                            </GridContainer>
                                        </center>
                                    </GridItem>
                                            
                                </GridContainer>
                            </CardBody>
                        </Card>    
                        </GridItem>   
                    </GridContainer>
                </CardBody>
                <CardFooter className = { classes.cardFooter } >
                        <center> 
                        <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }}   onClick = { e=>handleBackClick(e) } > Back </Button> 

                        { props.campaignType === 'Expired' &&  <Button variant = "contained" style = {{ backgroundColor : '#138de8', color : 'floralwhite', textTransform : 'none' }}  onClick = { e => history.push('/ops/campaigns')  } > Close </Button>}

{                    campaignarray.includes("Action") === true &&    props.campaignType !== 'Expired' &&   <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} disabled = {checkDisabled()} onClick = { handleDraftClick } > Save as Draft </Button> 

}                            <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} disabled = {props.campaignMode === false ? false : !valid  } onClick = {  props.campaignMode === false ? handleSave : saveProceed } > Proceed </Button>
                        </center>
                </CardFooter>
            </Card>     
        </Fragment>
    );
}