import axios from 'axios';
import appConfig from '../../HWLibrary/HWConfig';
import authService from 'HWLibrary/ApiService';


// There is caching enabled in this module, therefore 
// the service caches the instances for saving time to initialise one.
class SettingsApiService {

    constructor () {
        if (!SettingsApiService.instance) 
        SettingsApiService.instance = this;
        return SettingsApiService.instance;
    }

    defaultAuthConfig = () => { 
        const accessToken = authService.getCachedAccessToken();
        return { headers : { Authorization : `Bearer ${accessToken}` } };
    };

    fetchSubscriptionList = (request) => {
      var searchField, searchType, searchText = null;
    if (request && request.filterModel) {
      searchField = request.filterModel.columnField;
      

      searchType = request.filterModel.operatorValue;
      searchText = request.filterModel.value;
    }  

    if (request && request.searchText)
      searchText = request.searchText;

    var sortField, sortOrder = null;
    if (request && request.sortModel) {
        console.log("Current sort model",  request.sortModel);
        if (request.sortModel.field && request.sortModel.sort) {
          sortField = request.sortModel.field;
          sortOrder = request.sortModel.sort === "asc" ? "Ascending" : "Descending";
        } 
    }  

    const queryParams = request !== undefined ? { params: { searchText, searchField, searchType, sortField, sortOrder, fetchLimit: request.fetchLimit, paginationBookmark: request.paginationBookmark } } : {};

        const url = `${ appConfig.serverBaseUrl }/subscriptions/rules`;
        const config = { ...this.defaultAuthConfig() , ...queryParams };
        return authService.axiosService().get(url, config);
       
      }

    getControlDate = () => {
      const url = `${ appConfig.serverBaseUrl }/controldate`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().get(url, config);
    }


    updateControlDate = (date) => {
      console.log(date);
      const rule = {"controlDate" : date }
      const request = { ...rule };
      const url = `${ appConfig.serverBaseUrl }/controldate`;
      const config = { ...this.defaultAuthConfig() };
     return authService.axiosService().put (url, request, config);
    };



    createSubscription = (rule) => {
        const url = `${ appConfig.serverBaseUrl }/subscriptions/rules`;
        const config = { ...this.defaultAuthConfig() };
        const request = { ...rule };
  
        return authService.axiosService ().post (url, request, config);
      };


      updateSubscription = rule => {
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/subscriptions/rules/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

      fetchSpecificSubscription = (id) => {
        const url = `${ appConfig.serverBaseUrl }/subscriptions/rules/${ id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
       
      }

    deleteSpecificSubscription = rule => {
        if (rule !== null) { rule.status = 'Inactive' };
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/subscriptions/rules/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

       
      fetchMembershipCategory = (request) => {
        var searchField, searchType, searchText = null;
        if (request && request.filterModel) {
          searchField = request.filterModel.columnField;
          
    
          searchType = request.filterModel.operatorValue;
          searchText = request.filterModel.value;

          if (searchField === 'lowerTier') searchField = 'lowerTier.tierName';
          if (searchField === 'upperTier') searchField = 'upperTier.tierName';
        }  
    
        if (request && request.searchText)
          searchText = request.searchText;
    
        var sortField, sortOrder = null;
        if (request && request.sortModel) {
            console.log("Current sort model",  request.sortModel);
            if (request.sortModel.field && request.sortModel.sort) {
              sortField = request.sortModel.field;
              sortOrder = request.sortModel.sort === "asc" ? "Ascending" : "Descending";
            } 
        }  
    
        const queryParams = request !== undefined ? { params: { searchText, searchField, searchType, sortField, sortOrder, fetchLimit: request.fetchLimit, paginationBookmark: request.paginationBookmark } } : {};
        const url = `${ appConfig.serverBaseUrl }/members/tiers`;
        const config = { ...this.defaultAuthConfig() , ...queryParams};
        return authService.axiosService().get(url, config);
       
      }


      updateMembershipCategory = rule => {
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/members/tiers/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

      createMembershipCategory = (rule) => {
        const url = `${ appConfig.serverBaseUrl }/members/tiers`;
        const config = { ...this.defaultAuthConfig() };
        const request = { ...rule };
  
        return authService.axiosService ().post (url, request, config);
      };


      getControlTier = () => {
        const url = `${ appConfig.serverBaseUrl }/members/tiers/control-tier`;
        const config = { ...this.defaultAuthConfig()};
        return authService.axiosService().get(url, config);
      }


      updateControlTier = (updatedTierId) => {
        console.log("New id" ,updatedTierId );
        const request = {updatedTierId : updatedTierId }
        const url = `${ appConfig.serverBaseUrl }/members/tiers/control-tier`;
        const config = { ...this.defaultAuthConfig()};
        return authService.axiosService().put (url, request ,config);
      }

      fetchAppNotification = (request ) => {
        var searchField, searchType, searchText = null;
        if (request && request.filterModel) {
          searchField = request.filterModel.columnField;
          
    
          searchType = request.filterModel.operatorValue;
          searchText = request.filterModel.value;
        }  
    
        if (request && request.searchText)
          searchText = request.searchText;
    
        var sortField, sortOrder = null;
        if (request && request.sortModel) {
            if (request.sortModel.field && request.sortModel.sort) {
              sortField = request.sortModel.field;
              sortOrder = request.sortModel.sort === "asc" ? "Ascending" : "Descending";
            } 
        }  
    
        const queryParams = request !== undefined ? { params: { searchText, searchField, searchType, sortField, sortOrder, fetchLimit: request.fetchLimit, paginationBookmark: request.paginationBookmark , actionType : request.actionType} } : {};

        const url = `${ appConfig.serverBaseUrl }/ops/action-notifications`;
        const config = { ...this.defaultAuthConfig(), ...queryParams};
        return authService.axiosService().get(url, config);
       
      }

      fetchSpecificMembershipCategorry = (id) =>{
        const url = `${ appConfig.serverBaseUrl }/members/tiers/${ id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);

      }

      deleteSpecificMembershipCategorry = rule => {
        if (rule !== null) { rule.status = 'Inactive' };
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/members/tiers/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

      updateAppNotification = rule => {
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/ops/action-notifications/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

      createAppNotification = (rule) => {
        const url = `${ appConfig.serverBaseUrl }/ops/action-notifications`;
        const config = { ...this.defaultAuthConfig() };
        const request = { ...rule };
  
        return authService.axiosService ().post (url, request, config);
      };


      fetchSpecificAppNotification = (id) => {
        const url = `${ appConfig.serverBaseUrl }/vouchers/config/${ id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
       
      }

    deleteSpecificAppNotification = rule => {
      //  if (rule !== null) { rule.status = 'Inactive' };
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/ops/action-notifications/${ rule.id }/remove`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };


      fetchAppNotification = (rule) => {
        console.log(rule);
        const url = `${ appConfig.serverBaseUrl }/ops/action-notifications`;
        const config = { ...this.defaultAuthConfig(), params: { actionType: rule.actionType , searchText: rule.searchText} };
        return authService.axiosService().get(url, config);
       
      }



      updateEmailNotification = rule => {
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/ops/action-notifications/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

      createEmailNotification = (rule) => {
        const url = `${ appConfig.serverBaseUrl }/ops/action-notifications`;
        const config = { ...this.defaultAuthConfig() };
        const request = { ...rule };
  
        return authService.axiosService ().post (url, request, config);
      };


      fetchSpecificEmailNotification = (id) => {
        const url = `${ appConfig.serverBaseUrl }/vouchers/config/${ id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
       
      }

     deleteSpecificEmailNotification = rule => {
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/ops/action-notifications/${ rule.id }/remove`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

      // fetchSMSNotification = (id) => {
      //   const url = `${ appConfig.serverBaseUrl }/vouchers/config/${ id }`;
      //   const config = { ...this.defaultAuthConfig() };
      //   return authService.axiosService().get(url, config);
       
      // }



      updateSMSNotification = rule => {
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/ops/action-notifications/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

      createSMSNotification = (rule) => {
        const url = `${ appConfig.serverBaseUrl }/ops/action-notifications`;
        const config = { ...this.defaultAuthConfig() };
        const request = { ...rule };
  
        return authService.axiosService ().post (url, request, config);
      };


      fetchSpecificSMSNotification = (id) => {
        const url = `${ appConfig.serverBaseUrl }/vouchers/config/${ id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
       
      }

    deleteSpecificSMSNotification = rule => {
      const request = { ...rule };
      const url = `${ appConfig.serverBaseUrl }/ops/action-notifications/${ rule.id }/remove`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().put (url, request, config);
      };

      updateUser = rule => {
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/ops/users/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

      createUser = (rule) => {
        const url = `${ appConfig.serverBaseUrl }/ops/users`;
        const config = { ...this.defaultAuthConfig() };
        const request = { ...rule };
  
        return authService.axiosService ().post (url, request, config);
      };


      fetchUsers = (request) => {
        var searchField, searchType, searchText = null;
        if (request && request.filterModel) {
          searchField = request.filterModel.columnField;
          
    
          searchType = request.filterModel.operatorValue;
          searchText = request.filterModel.value;
        }  
    
        if (request && request.searchText)
          searchText = request.searchText;
    
        var sortField, sortOrder = null;
        if (request && request.sortModel) {
            console.log("Current sort model",  request.sortModel);
            if (request.sortModel.field && request.sortModel.sort) {
              sortField = request.sortModel.field;
              sortOrder = request.sortModel.sort === "asc" ? "Ascending" : "Descending";
            } 
        }  
    
        const queryParams = request !== undefined ? { params: { searchText, searchField, searchType, sortField, sortOrder, fetchLimit: request.fetchLimit, paginationBookmark: request.paginationBookmark } } : {};
        const url = `${ appConfig.serverBaseUrl }/ops/users`;
        const config = { ...this.defaultAuthConfig() , ...queryParams};
        return authService.axiosService().get(url, config);
       
      }

      fetchSpecificUser = (id) => {
        const url = `${ appConfig.serverBaseUrl }/ops/users/${ id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
       
      }

      deleteUser = (rule , s) => {
        if (rule !== null) { rule.status = s };
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/ops/users/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

      
      createSpecificPermission = (rule) => {
        const url = `${ appConfig.serverBaseUrl }/ops/roles/`;
        const config = { ...this.defaultAuthConfig() };
        const request = { ...rule };
  
        return authService.axiosService ().post (url, request, config);
      };


      fetchSpecificPermission = (id) => {
        const url = `${ appConfig.serverBaseUrl }/ops/roles/${ id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
       
      }

      updateSpecificPermission = rule => {
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/ops/roles/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };


      createRole = (rule) => {
        const url = `${ appConfig.serverBaseUrl }/ops/roles`;
        const config = { ...this.defaultAuthConfig() };
        const request = { ...rule };
  
        return authService.axiosService ().post (url, request, config);
      };

      fetchRoles = () => {
        const url = `${ appConfig.serverBaseUrl }/ops/roles`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
       
      }

      fetchSpecificRoles = (id) => {
        const url = `${ appConfig.serverBaseUrl }/ops/roles/${id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
       
      }

      deleteRole = rule => {
        if(rule!==null)
        {
          rule.roleStatus = "Inactive"
        }
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/ops/roles/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };
      

     
      updateRole = rule => {
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/ops/roles/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };
      

      deleteSpecificUser = (userId , roleId) => {
        if(userId!==null && roleId!==null)
        {
        const request = { userId ,  roleId };
        const url = `${ appConfig.serverBaseUrl }/ops/roles/${ roleId }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      }
    }

      fetchAllMembers = () => {
        const url = `${ appConfig.serverBaseUrl }/ops/users`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
       
      }

      includeUsers = (roleId , userIds) => {
        const request = { roleId , userIds };
        const url = `${ appConfig.serverBaseUrl }/ops/roles/include`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      }

      exludeUser = (roleId , opsUserId) => {
        const request = { roleId , opsUserId };
        const url = `${ appConfig.serverBaseUrl }/ops/roles/exclude`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      }
};

// Here singleton pattern being used for creating a merchant service
// The merchantApiService is that singleton object that does this. 
const settingsApiService = new SettingsApiService();
export default settingsApiService;