import { makeStyles} from '@material-ui/core';
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { Button,  IconButton , Popover } from '@material-ui/core';
import { DataGrid , GridToolbar} from '@material-ui/data-grid'
import { MoreVert, People } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import BlockUi from 'react-block-ui';
import HWMessage from 'HWLibrary/HWMessage';
import { useHistory } from 'react-router-dom';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 
import { GridAddIcon } from '@material-ui/data-grid';
import { Fab } from '@material-ui/core';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import Delete from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import RuleForm from './VoucherCreationRuleForm';
import voucherConfigApiService from './APIService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import Edit from '@material-ui/icons/Edit';
import SearchBar from 'views/Merchant/SearchBar';
import { CustomToolbar } from 'views/Members/Member';
const useStyles = makeStyles(theme => ({
    ...styles,
    cardFooter : { justifyContent : 'center', justifyItems : 'center' },
    cardHeader : { background : '#138DE8'  },
    cardIcon : { background : '#138DE8' },
    bottomGrid : { marginTop : 5 },
    '& .MuiFormLabel-root' : { marginBottom : 0 },
    editIcon : { color : '#138DE8' },
    deleteButton : { color : '#FF9017' },

}));

function RuleDialog (props) {
    
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ ruleDescription, setRuleDescription ] = useState ({});


    const handleClose = event => props.setOpen (false);
    return (
        <>
            <Dialog open = { props.open } onClose = { handleClose }  >
                <RuleForm refreshState = { props.refreshState } setRefreshState = { props.setRefreshState } rules = { props.rules } setRules = { props.setRules } createMode = { props.createMode } ruleId = { props.ruleId } open = { props.open } setOpen = { props.setOpen }  />
            </Dialog>
        </>
    );
}

function ActionPopover (props) {
    return ( 

                <div>
                <Popover
                    open={ props.open }
                    anchorEl={ props.anchorEl}
                    onClose={  (event) =>  props.handleClose (false)  }
                >
                    <div >
                        <div style = {{ margin : '20px' }} > 
                            <Button variant='contained' style = {{ textTransform:'none', backgroundColor : '#138DE8', color : 'floralwhite', right : '5px' }} onClick = {event => props.handleEdit() }  > Edit </Button> 
                            <Button variant='contained'style = {{ textTransform:"None", backgroundColor : '#FF9017', color : 'floralwhite' }} onClick = { event => props.handleDelete() } >  Delete </Button> 
                        </div> 
                    </div>
                </Popover>
            </div>
    
    );
};



export default function VoucherCreation (props) {
    var vouchers=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    vouchers= [...obj["Vouchers"]];

    HWLocalStorage.save (1, "previousVouchersRuleTab")
   const classes = useStyles();
   const [ rows, setRows ] = useState ([]);
   const [ page, setPage ] = useState (0);
   const [ pageSize, setPageSize ] = useState (100);
   const [ filterModel, setFilterModel ] = useState (null);
   const [ rowCount, setRowCount ] = useState (0);
   const [ openDirectAction, setOpenDirectAction ] = useState (false);
   const [ selectedRequest, setSelectedRequest ] = useState (null);
   const [ searchText, setSearchText ] = useState ('');
   const [creatorMode , setCreatorMode ] = useState(false);
   const [ anchorEl, setAnchorEl ] = useState (null);
   const [ actionOpen, setActionOpen ] = useState (false);
   const [loading, setLoading ] = useState(false);
   const [ directActionOpen , setDirectActionOpen ] = useState(false);
   const [ deleteOpen, setDeleteOpen ] = useState (false);
   const [ deletingRule, setDeletingRule ] = useState (null);
   const [ refreshState, setRefreshState ] = useState (false);
   const [ ruleOpen, setRuleOpen ] = useState (false);
   const [ createMode, setCreateMode ] = useState (false);
   const [ selectedRuleId, setSelectedRuleId ] = useState ();
   const [ isLoading, setIsLoading ] = useState (false);
   const [ deleteItemState , setDeleteItemState ] = useState('');
   const [ sortModel, setSortModel ] = useState ({});
   
   const openRule = () => { setRuleOpen (true); };
   const addRule = (event) => { setCreateMode (prev => true); setRuleOpen (prev => true); };
   const handleClick = (event,id) => { openRule(); };
   const handleEdit = (id) =>{
    history.push ({ pathname: "voucher-reward", state: { id: id , creatorMode : false } });
   } 
   
   const deleteRule = useCallback (id => {
       if (id != null) {
        setIsLoading (true);
        voucherConfigApiService.deleteVoucherCreation (id) 
           .then (response => {
               if (!response || !response.data) { return HWMessage.show ("Error while deleteing the rule" ,"error"); }
               HWMessage.show("Request Successful" , "success");
               setRowCount (rowCount - 1);
               setRows (prev => { prev = prev.filter (i => i.id !== id); return [ ...prev ] });
               setRefreshState (! refreshState);
           })
           .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
           .finally (() => { setIsLoading (false); });
       }
   });

   const periodConverter = periodString => {
    if (! periodString || periodString === '' || periodString === null) 
        return {}; 
    var yidx = periodString.indexOf("Y");
    var midx = periodString.indexOf ("M");
    var didx = periodString.indexOf ("D");

    let result = {};
    if (yidx !== -1)  result.years = parseInt(periodString.charAt (yidx - 1));
    if (midx !== -1) result.months = parseInt (periodString.slice (1, midx));
    if (didx !== -1) result.days = parseInt (periodString.charAt(didx - 1));
    return  result.months;
};

   useEffect (() => {
    setIsLoading (true);
    var paginationBookmark = ((page) * pageSize);
    var fetchLimit = pageSize;
    
    voucherConfigApiService.fetchVoucherCreationList ({fetchLimit, paginationBookmark, filterModel , sortModel , searchText})
    .then (response => {
        if (!response || !response.data){
            HWMessage.show("Request could not be fetched at the moment" , "error");
            return console.log("No relevant data can be fetched");
        }
        HWMessage.show("Request Successful" , "success");
        setRowCount(response.data.configCounts);
       setRows (response.data.voucherConfigModelList);
    })
    .catch (error => { console.log("Error while fetching data", error); HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
  })
    .finally (() => { setIsLoading (false);
        var my_element = document.getElementById("top");
        if(my_element!== null || my_element!== undefined || my_element!== '')
            {
                // my_element.scrollIntoView({ }); 

            }
      
     });
  }, [ page, pageSize , refreshState , filterModel , sortModel , searchText]);


    const handleDelete = (id) => {
            deleteRule (id)
    }

    const columns = [
       { field : 'code',          headerName : 'Voucher Code',    filterable : true,  sortable : true, flex : 2.5 },
       { field : 'categoryName',          headerName : 'Category',          filterable : true,  sortable : true, flex : 2.5 },
       { field : 'applyType',      headerName : 'Type',     filterable : true,  sortable : true, flex :2.5 , valueFormatter: ({ value }) => value === "UniversalVoucher" ? "Universal" : value === "PrivateVoucher" ? "Private": value},
       { field : 'maxUsageCeiling',  filterable :  false ,     headerName : 'Max Value',     sortable : true, flex : 2},
       { field : 'validity',      headerName : 'Validity',     filterable : false,  sortable : true, flex : 4,
    renderCell : cellParams => {  
     var { validityStart, validityEnd } = cellParams.row;
     if(cellParams.row.validityType === "Fixed")
     {var formattedStart = new Date (validityStart).toLocaleDateString();
     var formattedEnd = new Date (validityEnd).toLocaleDateString();
   
     return  <span  >  {  `${ formattedStart } - ${ formattedEnd }` } </span>;
    }
     else{
        return  <span  >  {  periodConverter(cellParams.row.openValidityPeriod) + " Months" } </span>;}

     }},
    { field : 'runningBalance',  filterable :  false ,     headerName : 'Utilized Value',     sortable : true, flex : 2 , renderCell:  cellParams => {
        return <span> {cellParams.row.maxUsageCeiling - cellParams.row.runningBalance }</span>
    }},
    { field : 'createTime',  headerName : 'Create Date', flex:2, filterable: false ,  renderCell : cellParams => {
        var date =  new Date(cellParams.row.createTime);
            
        var d = date.toLocaleDateString();

        return <span> {d} </span>
    } }
     ];


    if(vouchers.includes("Action") === true) {
        columns.push(       { field : 'Edit', headerName : '', filterable : false, sortable : false, flex : 1,  renderCell : cellParams => <> <IconButton   onClick= { e => { setSelectedRuleId(prev => { return cellParams.row.id});  handleClick (); } }  > <Edit style={{color: '#138DE8' }} className = { classes.actionIcon } /> </IconButton>  </>  }
        )
        columns.push( { field : 'Delete',headerName : '', flex : 1, filterable: false ,  renderCell : cellParams => {
            return (<> 
            <IconButton className = { classes.deleteButton } onClick = { e => {setDeleteOpen (true); setDeletingRule(cellParams.row); } } > <Delete /> </IconButton> 
             </>
             );}})
    }

    function CustomLoadingOverlay() {
        return (
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
      }

      const useDataGridStyles = makeStyles(theme => ({
        root: {
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiButton-label' : { color : '#138DE8' }
        }
      }));
      const dataGridClasses = useDataGridStyles();
   
   const history = useHistory ();

    return (
        <Fragment>
            <BlockUi blocking = { isLoading } message = { 'Please wait while we fetch.' } >
                <Card id="top">
                    <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                        <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        <h4 className = { classes.cardIconTitle } > 
                            Voucher Creation
                        </h4> 
                    </CardHeader>
                    <CardBody>  
                        <GridContainer style = {{ justifyContent : 'space-between' }} >
                            <GridItem>  
                                <SearchBar 
                                    width = '300px' 
                                    handleSearch = { setSearchText }
                                    handleRefresh = { setRefreshState }
                                    refreshState = { refreshState }
                                /> 
                            </GridItem>
                            <GridItem>
                              { vouchers.includes("Action") === true &&  <Fab  onClick = { addRule }  variant = 'extended' aria-label="add" style = {{ background : '#FF9017', color : 'white' }}  > <GridAddIcon /> Add Voucher</Fab>}
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem className = { classes.bottomGrid } xs = {12} md = {12} >
                                    <DataGrid 
                                        className = { dataGridClasses.root }
                                        showToolbar
                                        page = { page }
                                        pageSize = { pageSize }
                                        rows = { rows }
                                        columns = { columns }
                                        rowCount = {rowCount}
                                        pagination
                                        paginationMode = 'server'
                                        onPageChange = { changeParams => setPage (changeParams) }
                                        onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams); } }
                                        sortingMode="server" 
                                        onSortModelChange = { params => { 
                                          if (params.length > 0) 
                                              setSortModel (params[0]);
                                        } }
                                        filterMode = 'server'
                                        onFilterModelChange = { (params) => {
                                          if (params.items.length > 0) {
                                            setFilterModel (prev => ({ ...params.items[0] })); 
                                          }
                                        } }
                                        density = 'compact'
                                        autoHeight
                                        rowsPerPageOptions ={ [25, 50, 100 ]}
                                        components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 
                                        disableExtendRowFullWidth = { false }
                                    />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
                <RuleDialog     
                ruleId = { selectedRuleId }
                open = { ruleOpen }     
                setOpen = { setRuleOpen } 
                createMode = { createMode } 
                rules = { rows }
              //  setRules = { setRows }
                refreshState = { refreshState } 
                setRefreshState = { setRefreshState }
                />

               <ConfirmationDialog 
                    dialogTitle = { 'Confirm Voucher Deletion' } 
                    dialogMessage = { 'Are you sure you want to delete the voucher. ' } 
                    confirmingAction = { e => handleDelete(deletingRule) } open = { deleteOpen } setOpen = { setDeleteOpen } 
                />   
            </BlockUi>
            
        </Fragment>
    );
};