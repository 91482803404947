import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import GridItem from 'components/Grid/GridItem';
import React, { useState, useEffect, Fragment } from 'react';
import { useCallback } from 'react';
import GavelIcon from '@material-ui/icons/Gavel';
import { Button, FormControl, FormControlLabel, FormHelperText, IconButton, Input, InputAdornment, InputLabel, makeStyles } from '@material-ui/core';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import GridContainer from 'components/Grid/GridContainer';
import { Edit, People } from '@material-ui/icons';
import CardBody from 'components/Card/CardBody';
import { HWInput } from 'HWLibrary/HWInput';
import CardFooter from 'components/Card/CardFooter';
import pointsApiService from './APIService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import HWMessage from "HWLibrary/HWMessage";
import BlockUi from 'react-block-ui';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';


const useStyles = makeStyles(theme => ({
    ...styles,
    cardFooter : { justifyContent : 'center', justifyItems : 'center' },
    purchaseButtonLeft : { textTransform : 'none', color : 'floralwhite', background : '#138DE8', width : 200, height : 50, fontSize : 15, margin: 5},
    purchaseButtonRight : { textTransform : 'none', color : 'floralwhite', background : '#FF9017', width : 200, height : 50, fontSize : 15, margin: 5},

    returnButtonLeft : { textTransform : 'none', color : 'floralwhite', background : '#138DE8', width : 200, height : 50, fontSize : 15, margin: 5 },
    returnButtonRight : { textTransform : 'none', color : 'floralwhite', background : '#FF9017', width : 200, height : 50, fontSize : 15, margin: 5 },

    cardHeader : { background : '#138DE8' },
    cardIcon : { background : '#138DE8' },
    bottomGrid : { marginTop : 20 },
    '& .MuiFormLabel-root' : { marginBottom : 0 },
    redirectButton : { color : 'floralwhite', beckground : '#FF9017' },


}));

export function SideInput (props) {
    return (
        <>
            <div>
                {/* <FormControl  >
                    <InputLabel shrink> Here </InputLabel>
                    <Input
                        name = { props.label }
                        fullWidth
                        value = { props.value }
                        onChange = { props.onChange }
                        onBlur = { props.onBlur }
                        startAdornment = { <InputAdornment position = { 'start' } > { props.startAdornment } </InputAdornment> }
                        endAdornment = { <InputAdornment position = { 'end' } > { props.endAdornment } </InputAdornment> }
                    />
                </FormControl> */}
                
            </div>
        </>
    );
};

export default function PurchaseReturnPoints (props) {
    HWLocalStorage.save(3,"previousPointsRuleTab");

    var point=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    point= [...obj["Points"]];


    const classes = useStyles ();
     
    const [ canEdit, setCanEdit ] = useState (false);
    const [ currentRule, setCurrentRule ] = useState ({});
    const [ editPurchaseRule, setEditPurchaseRule ] = useState (false);
    const [ editReturnRule, setEditReturnRule ] = useState (false);
    const [ editInvoiceFormat, setEditInvoiceFormat ] = useState (false);
    const [refreshState , setRefreshState] = useState(false);
    const [ initialValues, setInitialValues ] = useState (null);
    const [ isLoading, setIsLoading ] = useState (false);
    const [ showEdit, setShowEdit ] = useState (true);
    const [ initialState, setInitialState ] = useState ({});
    const [ dialogOpen, setDialogOpen ] = useState (false);
    const [ updateLoading, setUpdateLoading ] = useState (false);
    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});
    const [ saveOpen, setSaveOpen ] = useState (false);
    const [ saveReturnOpen, setSaveReturnOpen ] = useState (false);
    const [ invoiceRule , setInvoiceRule ] = useState({})

    const fetchCurrentRule = useCallback (() => {
        // Fetch current doc
    }, []);
   

    

    useEffect (() => {
        setIsLoading (true);
        pointsApiService.fetchCurrentRule().then (response => {
            if (!response || !response.data)
                return console.log("No relevant data can be fetched");
            setCurrentRule(response.data);
            HWLocalStorage.save (JSON.stringify(response.data), "currentRule");
            HWLocalStorage.save (JSON.stringify(response.data.purchaseRule), "initialPurchaseRule");
            HWLocalStorage.save (JSON.stringify(response.data.returnRule), "initialReturnRule");        
          })
        .catch (error => {console.log("Error while fetching data", error); })
        .finally (() => setIsLoading (false));
    }, [ ]);

    const handleUpdate = () => {};
    const validateSavePurchase = purchaseRule => { return true };
    const validateSaveReturn = returnRule => { return true; };
    const validateInvoiceFormat = invoiceRule => {return true ;};

    const saveRule = useCallback (currentRule => { 
        setUpdateLoading (true);
        pointsApiService.updateCurrentRule(currentRule)
        .then (response => { 
            if (!response || !response.data) {
                HWMessage.show ("CurrentRule cannot be updated","error");
                return console.log("Error while updating cuurentRule");
            }
            HWMessage.show("CurrentRule updated successfully","success");
            setCurrentRule(response.data);
     })
     .catch (error => { 
        HWMessage.show ("Error while updating currentRule","error");
        return console.log("Error while updating currentRule");
     }).finally (() => { setUpdateLoading (false); setDialogOpen (false);  });
     setShowEdit (true);  


    });

    useEffect (() => {
        setIsLoading (true);
        pointsApiService.fetchInvoiceRule().then (response => {
            if (!response || !response.data)
                return console.log("No relevant data can be fetched");
            setInvoiceRule(response.data);
            HWLocalStorage.save (JSON.stringify(response.data), "invoiceRule");
            
          })
        .catch (error => {console.log("Error while fetching data", error); })
        .finally (() => setIsLoading (false));
    }, [ refreshState]);

    const saveInvoiceRule = useCallback (invoiceRule => { 
        setUpdateLoading (true);
        pointsApiService.updateInvoiceRule(invoiceRule)
        .then (response => { 
            if (!response || !response.data) {
                HWMessage.show ("Invoice Rule cannot be updated","error");
                return console.log("Error while updating invoice rule");
            }
            HWMessage.show("Invoice Rule updated successfully","success");
            //setInvoiceRule(response.data);
     })
     .catch (error => { 
        HWMessage.show ("Error while updating invoice Rule","error");
        return console.log("Error while updating invoice Rule");
     }).finally (() => { setUpdateLoading (false); setDialogOpen (false);  });
     setShowEdit (true);  


    });


    const getInvoiceValue = (invoice) => {
        if(invoice !== null || invoice !== undefined)
        {
            var prefix  =  invoice.invoicePrefix;
            var suffix = invoice.invoiceSuffix;
            var count = invoice.count;
            var length = String(count).length;
            var zeroes = suffix -  length;
            var y ="";
            for(var t = 0 ; t < zeroes ; t++)
            {
                    y = y + "0";
            }
            return prefix + y + count ; 
        }
       else{
           return "";
       }
    }


    const handleSavePurchaseRule = () => {
        var check = validateSavePurchase (currentRule.PurchaseRule);
        if (check === true) {saveRule (currentRule);setEditPurchaseRule (! editPurchaseRule); ;setCanEdit (!canEdit); setShowEdit (!showEdit); }
        else  { return HWInput.show("Purchase Rule contains invalid values, please fix them before saving","error"); }
    };
    const handleSaveReturnRule = () => {
        var check = validateSaveReturn (currentRule.returnRule);
        if (check === true) { saveRule (currentRule);setEditReturnRule (! editReturnRule); ;setCanEdit (!canEdit); setShowEdit (!showEdit);}
        else { return HWInput.show ("Return rule contains invalid values, please fix them before saving","error"); }
    };
    const handleSaveInvoiceRule = () => {
        var check = validateInvoiceFormat (invoiceRule.invoice);
        if (check === true) { saveInvoiceRule (invoiceRule);setEditInvoiceFormat (! editInvoiceFormat); ;setCanEdit (!canEdit); setShowEdit (!showEdit);}
        else { return HWInput.show ("Invoice Format has invalid rules","error"); }
    };

    const captureInputChanges = event  => {
        const field = event.target.name;
        const value = event.target.value;

        handleTextFieldChanges (field, value);
    
        switch (event.target.name) {
            case 'minimumPurchaseAllowed':
                if(event.target.value > 0)
        { 
            currentRule.purchaseRule.minimumPurchaseAllowed = event.target.value;
            setCurrentRule(currentRule);
        }
              
                break;

            case 'lotSizeMultiplier1':
                if(event.target.value > 0)
                {
                    currentRule.purchaseRule.lotSizeMultiplier = event.target.value;
                    
                    setCurrentRule(currentRule);
                }
                
                break;
            case 'lotSizeMultiplier2':
                if(event.target.value > 0)
{
    currentRule.returnRule.lotSizeMultiplier = event.target.value;
    
    setCurrentRule(currentRule);
}
                  
                    break;

            case 'serviceTaxPercentage':
                if(event.target.value >= 0)

              {  currentRule.purchaseRule.serviceTaxPercentage = event.target.value;
                setCurrentRule(currentRule);}
                break;

            case 'minReturnAllowed' :      
            if(event.target.value > 0)

               { currentRule.returnRule.minReturnAllowed = event.target.value;
                setCurrentRule(currentRule);}
                break;

            case 'purchaseWindow' : 
            if(event.target.value > 0)

               { currentRule.returnRule.purchaseWindow = event.target.value;
                setCurrentRule(currentRule);}
                break;

            case 'pointDepletionPercent' : 
            if(event.target.value > 0)
            {currentRule.returnRule.pointDepletionPercent = event.target.value;
                setCurrentRule(currentRule);}
                break;

            case 'pointDepletionWindow' :
                if(event.target.value > 0)
                { currentRule.returnRule.pointDepletionWindow = event.target.value;
                setCurrentRule(currentRule);}
                break;

            case 'purchaseConversionRate' : 
            if(event.target.value > 0)
            {currentRule.purchaseConversionRate = event.target.value;
                setCurrentRule(currentRule);}
                break;  
            case 'invoiceSuffix' : 
                invoiceRule.invoiceSuffix = event.target.value;
                setInvoiceRule(invoiceRule);
                break; 
            case 'invoicePrefix' : 
                invoiceRule.invoicePrefix = event.target.value;
                setInvoiceRule(invoiceRule);
                break;          
        }
    }

    const handleTextFieldChanges = (field, value) => {
        switch (field) {
            case 'minimumPurchaseAllowed' : 
           
                if (!value || value <= 0){ 
                   
                    setHasError ({ ...hasError, 'minimumPurchaseAllowed' : true }); 
                    setErrorMessage ({ ...errorMessage, 'minimumPurchaseAllowed' : 'Invalid Minimum Purchase' });
                    return true;
                }
                else{
                    setHasError({ ...hasError, 'minimumPurchaseAllowed' : false }); 
                    setErrorMessage ({ ...errorMessage, 'minimumPurchaseAllowed' : null });
                    return false;
                }
            break;
            case 'lotSizeMultiplier1' :
            
                if (!value || value <=0) { 
                    setHasError ({ ...hasError, 'lotSizeMultiplier1' : true }); 
                    setErrorMessage ({ ...errorMessage, 'lotSizeMultiplier1' : 'Invalid Lot Size Multiplier' });
                    return true;
                    
                }
                else { 
                    setHasError({ ...hasError, 'lotSizeMultiplier1' : false }); 
                    setErrorMessage ({ ...errorMessage, 'lotSizeMultiplier1' : null });
                    return false;
                   
                }
                
                case 'lotSizeMultiplier2' :
            
                if (!value || value <=0 ) { 
                    setHasError ({ ...hasError, 'lotSizeMultiplier2' : true }); 
                    setErrorMessage ({ ...errorMessage, 'lotSizeMultiplier2' : 'Invalid Lot Size Multiplier' });
                    return true;
                    
                }
                else { 
                    setHasError({ ...hasError, 'lotSizeMultiplier2' : false }); 
                    setErrorMessage ({ ...errorMessage, 'lotSizeMultiplier2' : null });
                    return false;
                   
                }
            break;
            case 'serviceTaxPercentage' : 
          
                if (!value || value < 0) { 
                    setHasError ({ ...hasError, 'serviceTaxPercentage' : true }); 
                    setErrorMessage ({ ...errorMessage, 'serviceTaxPercentage' : 'Invalid Service Tax Percentage' });
                    return true;
                    
                }
                else { 
                   
                    setHasError({ ...hasError, 'serviceTaxPercentage' : false }); 
                    setErrorMessage ({ ...errorMessage, 'serviceTaxPercentage' : null });
                    return false;
                }
            break;
            case 'minReturnAllowed' : 
           
                if (!value || value <= 0) { 
                    setHasError ({ ...hasError, 'minReturnAllowed' : true }); 
                    setErrorMessage ({ ...errorMessage, 'minReturnAllowed' : 'Invalid Min Return Allowed' });
                    return true;
                    
                }
                else { 
                    setHasError({ ...hasError, 'minReturnAllowed' : false }); 
                    setErrorMessage ({ ...errorMessage, 'minReturnAllowed' : null });
                    return false;
                   
                }
            break;
            case 'purchaseWindow' : 
            
            if (!value || value <= 0) { 
                setHasError ({ ...hasError, 'purchaseWindow' : true }); 
                setErrorMessage ({ ...errorMessage, 'purchaseWindow' : 'Invalid Purchase Window' });
                return true;
                
            }
            else { 
                setHasError({ ...hasError, 'purchaseWindow' : false }); 
                setErrorMessage ({ ...errorMessage, 'purchaseWindow' : null });
                return false;
                
            }
            
        break
        ;
            case 'pointDepletionPercent' : 
           
                if (!value || value <= 0) { 
                    setHasError ({ ...hasError, 'pointDepletionPercent' : true }); 
                    setErrorMessage ({ ...errorMessage, 'pointDepletionPercent' : 'Invalid Point Depletion Percent' });
                    return true;
                    
                }
                else { 
                    setHasError({ ...hasError, 'pointDepletionPercent' : false }); 
                    setErrorMessage ({ ...errorMessage, 'pointDepletionPercent' : null });
                    return false;
                   
                }
            break;

            case 'purchaseConversionRate' : 
           
            if (!value || value <= 0) { 
                setHasError ({ ...hasError, 'purchaseConversionRate' : true }); 
                setErrorMessage ({ ...errorMessage, 'purchaseConversionRate' : 'Invalid Purchase conversion rate' });
                return true;
                
            }
            else { 
                setHasError({ ...hasError, 'purchaseConversionRate' : false }); 
                setErrorMessage ({ ...errorMessage, 'purchaseConversionRate' : null });
                return false;
               
            }
        break;



            
            case 'invoiceSuffix' : 
           
                if (!value || value < 5 || value> 10) { 
                    setHasError ({ ...hasError, 'invoiceSuffix' : true }); 
                    setErrorMessage ({ ...errorMessage, 'invoiceSuffix' : 'Enter value between 5 to 10' });
                    return true;
                    
                }
                else { 
                    setHasError({ ...hasError, 'invoiceSuffix' : false }); 
                    setErrorMessage ({ ...errorMessage, 'invoiceSuffix' : null });
                    return false;
                   
                }
            break;

            case 'invoicePrefix' : 
           
            if (!value ) { 
                setHasError ({ ...hasError, 'invoicePrefix' : true }); 
                setErrorMessage ({ ...errorMessage, 'invoicePrefix' : 'Value cannot be null' });
                return true;
                
            }
            else { 
                setHasError({ ...hasError, 'invoicePrefix' : false }); 
                setErrorMessage ({ ...errorMessage, 'invoicePrefix' : null });
                return false;
               
            }
        break;


            case 'pointDepletionWindow' :
                var reg = new RegExp ("^\\+\\d{1,3}-\\d{4,}$");
                if (!value || value <= 0) { 
                    
                    setHasError ({ ...hasError, 'pointDepletionWindow' : true }); 
                    setErrorMessage ({ ...errorMessage, 'pointDepletionWindow' : 'Invalid Point Depletion Window' });
                    return true;
                   
                }
                else { 
                    setHasError({ ...hasError, 'pointDepletionWindow' : false }); 
                    setErrorMessage ({ ...errorMessage, 'pointDepletionWindow' : null });
                    return false;
                }    
            break;
           

            
          
        }
    };


    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    }
    const handleCancelPurchase = (event) => {setEditPurchaseRule (! editPurchaseRule); setHasError(false) ; setErrorMessage("");;setCanEdit (!canEdit); setShowEdit (!showEdit); setCurrentRule({...currentRule, purchaseRule: JSON.parse(HWLocalStorage.load("initialPurchaseRule") ) } ) };
    const handleCancelReturn = (event) => {setCurrentRule(initialState);setHasError(false) ; setErrorMessage(""); setEditReturnRule (! editReturnRule); setCanEdit (!canEdit); setShowEdit (!showEdit); setCurrentRule({...currentRule, returnRule: JSON.parse(HWLocalStorage.load("initialReturnRule") ) } )};
    const handleCancelInvoiceRule = (event) => {setInvoiceRule(initialState);setHasError(false) ; setErrorMessage("");setEditInvoiceFormat (! editInvoiceFormat);setRefreshState(!refreshState); setCanEdit (!canEdit); setShowEdit (!showEdit); setInvoiceRule({...currentRule, invoiceFormat: JSON.parse(HWLocalStorage.load("invoiceRule") ) } )};

    return (    
        <Fragment>
            <BlockUi blocking = { isLoading } message = { 'Please wait. Feteching Configuration' } >
                <GridContainer >
                    <GridItem xs = {6} md = {6} sm = {6} >  
                        <Card>
                            <CardHeader className = { classes.cardHeader } color = 'rose' icon >
                                <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                                    <h4 className = { classes.cardIconTitle } > Rules For Purchasing Points By Merchant { !editPurchaseRule && point.includes("Action")===true && <IconButton onClick = { e => setEditPurchaseRule (! editPurchaseRule) } > <Edit style={{color:"#138de8"}}/> </IconButton>} </h4> 
                            </CardHeader>     
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs = {12} md = {12} sm = {12} > <HWInput required={true} type = 'number' label = { 'Minimum Points to Purchase' } value = { currentRule && currentRule.purchaseRule && currentRule.purchaseRule.minimumPurchaseAllowed  } name = "minimumPurchaseAllowed" disabled = { ! editPurchaseRule } hasError = { checkError ('minimumPurchaseAllowed') }  helperText = { fetchErrorMessage ('minimumPurchaseAllowed') } onBlur = { captureInputChanges } /> </GridItem>
                                    <GridItem xs = {12} md = {12} sm = {12} > <HWInput  required={true} type = 'number' label = { 'Lot Size should be Multiple of' } value = { currentRule && currentRule.purchaseRule && currentRule.purchaseRule.lotSizeMultiplier } name = "lotSizeMultiplier1" disabled = { ! editPurchaseRule } hasError = { checkError ('lotSizeMultiplier1') }  helperText = { fetchErrorMessage ('lotSizeMultiplier1') } onBlur = { captureInputChanges } /> </GridItem>
                                    <GridItem xs = {12} md = {12} sm = {12} > <HWInput required={true} type = 'number' label = { 'Service Tax Rate' } value = { currentRule && currentRule.purchaseRule && currentRule.purchaseRule.serviceTaxPercentage } name = "serviceTaxPercentage" disabled = { ! editPurchaseRule } hasError = { checkError ('serviceTaxPercentage') }  helperText = { fetchErrorMessage ('serviceTaxPercentage') } onBlur = { captureInputChanges } /> </GridItem>
                                    <GridItem xs = {12} md = {12} sm = {12} > <HWInput required={true} type = 'number' label = { 'Purchase Conversion Rate' } value = { currentRule && currentRule.purchaseConversionRate  } name = "purchaseConversionRate" disabled = { ! editPurchaseRule } hasError = { checkError ('purchaseConversionRate') }  helperText = { fetchErrorMessage ('purchaseConversionRate') } onBlur = { captureInputChanges } /> </GridItem>
                                </GridContainer>

                            </CardBody>     
                            <CardFooter className = { classes.cardFooter } >
                                { editPurchaseRule && <Button className = { classes.purchaseButtonLeft } onClick = { e => setSaveOpen (true) } variant = 'contained' > Save Purchase Rule </Button>}
                                { editPurchaseRule && <Button className = { classes.purchaseButtonRight } onClick = { handleCancelPurchase } variant = 'contained' > Cancel </Button>}
                                <ConfirmationDialog open = { saveOpen } setOpen = { setSaveOpen } dialogTitle = { 'Confirm Saving Purchase Rule' } dialogMessage = { 'Are you sure you want to update the purchase rule' } confirmingAction = { handleSavePurchaseRule } /> 
                            </CardFooter>              
                        </Card>
                    </GridItem>
                    <GridItem xs = {6} md = {6} sm = {6} >  
                        <Card>
                            <CardHeader className = { classes.cardHeader } color = 'rose' icon >
                                <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                                <h4 className = { classes.cardIconTitle }   > Rules For Returning Points By Merchant { !editReturnRule && point.includes("Action")===true && <IconButton onClick = { e => setEditReturnRule (! editReturnRule) } > <Edit style={{color:"#138de8"}} /> </IconButton>} </h4>
                            </CardHeader>     
                            <CardBody>
                                
                                <GridContainer >
                                    <GridItem xs = {12} md = {12} sm = {12} > <HWInput required={true} type = 'number' label = { 'Minimum Points to Returned' } value = { currentRule && currentRule.returnRule && currentRule.returnRule.minReturnAllowed  } name = "minReturnAllowed" disabled = { ! editReturnRule }  hasError = { checkError ('minReturnAllowed') }  helperText = { fetchErrorMessage ('minReturnAllowed') }   onBlur = { captureInputChanges }  /> </GridItem>
                                    <GridItem xs = {12} md = {12} sm = {12} > <HWInput required={true} type = 'number' label = { 'Lot Size should be Multiple of' } value = { currentRule && currentRule.returnRule && currentRule.returnRule.lotSizeMultiplier } name = "lotSizeMultiplier2" disabled = { ! editReturnRule } onBlur = { captureInputChanges } hasError = { checkError ('lotSizeMultiplier2') }  helperText = { fetchErrorMessage ('lotSizeMultiplier2') }  /> </GridItem>
                                    <GridItem xs = {12} md = {12} sm = {12} > <HWInput required={true} type = 'tnumber' label = { 'Point Depletion Rate' } value = { currentRule && currentRule.returnRule && currentRule.returnRule.pointDepletionPercent } name = "pointDepletionPercent" disabled = { ! editReturnRule }  hasError = { checkError ('pointDepletionPercent') }  helperText = { fetchErrorMessage ('pointDepletionPercent') } onBlur = { captureInputChanges } /> </GridItem>
                                    <GridItem xs = {12} md = {12} sm = {12} > <HWInput required={true} type = 'number' label = { 'Points Depletion Window' } value = { currentRule && currentRule.returnRule && currentRule.returnRule.pointDepletionWindow } name = "pointDepletionWindow" disabled = { ! editReturnRule } hasError = { checkError ('pointDepletionWindow') }  helperText = { fetchErrorMessage ('pointDepletionWindow') } onBlur = { captureInputChanges } /> </GridItem>
                                    <GridItem xs = {12} md = {12} sm = {12} > <HWInput required={true} type = 'number' label = { 'Points Purchase Window' } value = { currentRule && currentRule.returnRule && currentRule.returnRule.purchaseWindow } name = "purchaseWindow" disabled = { ! editReturnRule } hasError = { checkError ('purchaseWindow') }  helperText = { fetchErrorMessage ('purchaseWindow') } onBlur = { captureInputChanges } /> </GridItem>
                                </GridContainer>
                            </CardBody>     
                            <CardFooter  className = { classes.cardFooter } >
                                { editReturnRule && <Button className = { classes.returnButtonLeft } onClick = { handleSaveReturnRule } variant = 'contained' > Save Return Rule </Button> }
                                { editReturnRule && <Button className = { classes.returnButtonRight } onClick = { handleCancelReturn } variant = 'contained' > Cancel </Button> }
                                <ConfirmationDialog open = { saveReturnOpen } setOpen = { setSaveReturnOpen } dialogTitle = { 'Confirm Saving Purchase Rule' } dialogMessage = { 'Are you sure you want to update the purchase rule' } confirmingAction = { handleSaveReturnRule } /> 
                            </CardFooter>              
                        </Card>
                    </GridItem>
                    <GridItem xs = {6} md = {6} sm = {6} >  
                        <Card>
                            <CardHeader className = { classes.cardHeader } color = 'rose' icon >
                                <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                                <h4 className = { classes.cardIconTitle }   > Invoice Format { !editReturnRule && point.includes("Action")===true &&  <IconButton onClick = { e => setEditInvoiceFormat (! editInvoiceFormat) } > <Edit style={{color:"#138de8"}}/> </IconButton>} </h4>
                            </CardHeader>     
                            <CardBody>
                                
                                <GridContainer >
                                    <GridItem xs = {12} md = {12} sm = {12} > <HWInput required={true} label = { 'Starts With' } value = { invoiceRule  && invoiceRule.invoicePrefix   } name = "invoicePrefix" disabled = { ! editInvoiceFormat }  hasError = { checkError ('invoicePrefix') }  helperText = { fetchErrorMessage ('invoicePrefix') }   onBlur = { captureInputChanges }  /> </GridItem>
                                    <GridItem xs = {12} md = {6} sm = {12} > <HWInput  required={true} label = { 'Followed by unique' } name = "invoiceSuffix" endAdornment={<InputAdornment position="end">digits code</InputAdornment>}
                                    
                                    value = { invoiceRule  && invoiceRule.invoiceSuffix } name = "invoiceSuffix" disabled = { ! editInvoiceFormat } onBlur = { captureInputChanges } hasError = { checkError ('invoiceSuffix') }  helperText = { fetchErrorMessage ('invoiceSuffix') }  /> </GridItem>
                                    <GridItem xs = {12} md = {12} sm = {12} > <HWInput required={true} label = { 'Latest Invoice' } value = {  getInvoiceValue(invoiceRule) === undefined ? "" :  getInvoiceValue(invoiceRule) } name = "count" disabled = { true }    /> </GridItem>
                                </GridContainer>
                            </CardBody>     
                            <CardFooter  className = { classes.cardFooter } >
                                { editInvoiceFormat  && <Button  className = { classes.returnButtonLeft } disabled={(checkError ('invoicePrefix') || checkError("invoiceSuffix")) ? true : false} onClick = { handleSaveInvoiceRule } variant = 'contained' > Save Format</Button> }
                                { editInvoiceFormat && <Button   className = { classes.returnButtonRight } onClick = { handleCancelInvoiceRule } variant = 'contained' > Cancel </Button> }
                                <ConfirmationDialog open = { saveReturnOpen } setOpen = { setSaveReturnOpen } dialogTitle = { 'Confirm Saving Invoice Format' } dialogMessage = { 'Are you sure you want to update the Invoice Format' } confirmingAction = { handleSaveInvoiceRule } /> 
                            </CardFooter>              
                        </Card>
                    </GridItem>
                </GridContainer>
            </BlockUi>
        </Fragment>
    );
};