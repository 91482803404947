import React, {useEffect, useState} from "react"
import Merchant from "./Merchant";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from "@material-ui/core";
import Merchants from "./Merchants";
import MerchantRoles from './MerchantRoles';
import HWLocalStorage from "HWLibrary/HWLocalStorage";
import { useRef } from "react";
import MerchantConfiguration from "./MerchantConfiguration";

const useStyles = makeStyles(theme => ({
    root: {
      overflow: 'hidden',
      minHeight: 48,
      WebkitOverflowScrolling: 'touch', 
      display: 'flex',
      backgroundColor: "white",
      '& .MuiTabs-indicator' : { backgroundColor : '#FF9017' },
      "& .MuiButtonBase-root": {textTransform: "none"},
      color: "black",
      borderRadius: '6px'
    }
  }));
  
const MerchantTabs = (props) => {
    if (HWLocalStorage.load ("previousVouchersTab") !== null ) 
    HWLocalStorage.clear("previousVouchersTab");
  if(HWLocalStorage.load ("previousVouchersRuleTab") !== null)
     HWLocalStorage.clear("previousVouchersRuleTab");
     if (HWLocalStorage.load ("previousCardTab") !== null ) 
     HWLocalStorage.clear("previousCardTab");
     if(HWLocalStorage.load("previousComSettingsTab")!== null){
        HWLocalStorage.clear("previousComSettingsTab")
    }
    if(HWLocalStorage.load("previousRolesSettingsTab")!== null){
        HWLocalStorage.clear("previousRolesSettingsTab")
    }

    if(HWLocalStorage.load("previousSettingsTab")!== null){
        HWLocalStorage.clear("previousSettingsTab")
    }
     if(HWLocalStorage.load("previousMemberTab")!== null){
        HWLocalStorage.clear("previousMemberTab")
    }
    if(HWLocalStorage.load("previousActionsTab")!== null){
        HWLocalStorage.clear("previousActionsTab")
    }
    if(HWLocalStorage.load("previousPointsRuleTab")!== null)
    {
        HWLocalStorage.clear("previousPointsRuleTab");
    }
    if(HWLocalStorage.load("previousPointsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsTab");
    }
    if(HWLocalStorage.load("previousActionsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsTab");
    }
    if(HWLocalStorage.load("previousPointsActionsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsActionsTab");
    }
    if (! HWLocalStorage.load ("previousMerchantTab") ) HWLocalStorage.save (0, "previousMerchantTab");


    const [selectedTab, setSelectedTab] = useState (JSON.parse(HWLocalStorage.load ("previousMerchantTab")) || 0);
    const classes = useStyles();



    const changeTab = (event, index) => { setSelectedTab(index) };

    return (
        <>
                <Tabs 
                    className = {classes.root} 
                    variant = "scrollable" 
                    value={selectedTab} 
                    onChange={changeTab} 
                    style = {{ borderBottomColor : 'blue' }}
                >
                    <Tab label="Merchant Details"  />
                    <Tab label="Merchant User Role" />
                    <Tab label="Merchant Configuration" />
                </Tabs>

            <div style={{ padding: "30px 0px 30px 0px" }}>
                {selectedTab == 0 && <Merchants />}
                {selectedTab == 1 && <MerchantRoles />}
                { selectedTab === 2 && <MerchantConfiguration /> }
            </div>
        </>
    );
}

export default MerchantTabs;