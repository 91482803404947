import { Cancel, CheckCircle, Error, Help, PriorityHigh } from '@material-ui/icons';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from 'HWLibrary/HWInput';
import HWMessage from 'HWLibrary/HWMessage';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import paymentApiService from './APIService';


function PaymentStatusIcon (props) {
    var status = props ?. status;
    if (! status)  status = "Unknown";
    return (<>
        { status === "Success" && <CheckCircle style = {{ color : props.color }} /> }
        { status === "Failed" && <Error /> }
        { status === "Cancelled" && <Cancel /> }
        { (status === "TransactionPending" || status === "AuthorizationPending") && <CheckCircle /> }
        { status === "Unknown" && <Help style = {{ color : props.color }} /> }
        
    </>)
};

export default function PaymentRedirectInterceptor (props) {

    const queryStringParams = new URLSearchParams(props.location.search);
    const transactionId =  queryStringParams.get("transactionId") ;
    const [ loading, setLoading ] = useState (false);
    const [ transaction, setTransaction ] = useState ({});

    console.log ("Transaction ID", transactionId);
    
    const paymentStatus = transaction => {
        switch (transaction.transactionStatus) {
            case "Success": return "Payment Successful"; 
            case "Failed" : return "Payment Unsuccessful";
            case "TransactionPending" : return "Pending";
            case "AuthorizationPending" : return "Pending for Authorization";
            case "Cancelled" : return "Payment Cancelled By Customer";
            default : return "Unknown Status";
        }
    };
    const paymentStatusColor = transaction => {
        const status = transaction.transactionStatus;
        switch (transaction.transactionStatus) {
            case "Success" : return "#266616";
            case "Failed" : return "#D6432F";
            case "TransactionPending" : return "#657CF1";
            case "AuthorizationPending" : return "#E8AD46";
            case "Cancelled" : return "#E86146";
            default : return "#4666E8";
        }
        return "#4666E8";
    };

    
    useEffect (() => {
        setLoading (true);
        paymentApiService.fetchTransaction (transactionId)
        .then (response => { 
            
            if (! response || ! response.data) { return HWMessage.show ("Transaction cannot be fetched", "error"); }
            setTransaction (response.data); 
        })
        .catch (error => { HWMessage.show ("Transaction Not found", "error"); })
        .finally (() => { setLoading (false); })
    }, []);

    return (<>
    <BlockUi blocking = { loading } message = { "Loading Transaction Details. Please Wait" } >
        <GridContainer justify = "center"   >
                <GridItem xs = {12} md = {6} lg = {6} >
                    <Card style = {{ borderRadius : 5 }} >
                        <CardBody>
                            <GridContainer>
                                <GridItem xs = {12} md = {12} sm = {12} > <center>  <span style = {{ fontWeight : 500, fontSize: 26 }} > Pay with <img height = { 45 } src = { '/payment-fpx.jpeg' } /> ( Current and Savings Account ) </span>  </center> </GridItem>
                                <GridItem xs = {12} md = {12} lg = {12} style = {{ marginBottom : 30 }} > 
                                    <GridContainer justify = "center" >
                                        <GridItem> 
                                            <span color = "floralwhite"  style = {{ 
                                                color : paymentStatusColor (transaction),
                                                fontWeight : 500,
                                                fontSize : 26,
                                                background : "url(https://www.google.com/imgres?imgurl=https%3A%2F%2Fi.pinimg.com%2Foriginals%2F59%2F33%2F7e%2F59337e57b11a4765505eeebcacacc19e.jpg&imgrefurl=https%3A%2F%2Fwww.pinterest.com%2Fpin%2F216524694558166381%2F&tbnid=pfSO-mZpZ6krzM&vet=12ahUKEwjhkrvL7drzAhWUk9gFHYfgBy4QMygBegUIARDIAQ..i&docid=o03r1nV5mlGmsM&w=2560&h=1600&q=background%20images%20yamaha&ved=2ahUKEwjhkrvL7drzAhWUk9gFHYfgBy4QMygBegUIARDIAQ)" }} 
                                            > 
                                            <PaymentStatusIcon  color = { paymentStatusColor (transaction) } status = { transaction.transactionStatus } />
                                            { paymentStatus (transaction) } 
                                            </span>  
                                        </GridItem> 
                                    </GridContainer>
                                </GridItem>
                                {  
                                    <> 
                                        <GridItem xs = {12} md = {12} lg = {12} > 
                                            <GridContainer justify = "space-between" >  
                                                <GridItem xs = {12} sm = {12} lg = {12}  > 
                                                    <GridContainer>
                                                        <GridItem xs = {6} md = {5} lg = {5} style = {{ fontWeight : 600 }} > Date {"&"} Time </GridItem> : 
                                                        {                 transaction.transactionStatus === "Success"  && <GridItem  > { new Date( transaction?.fpxTransactionTime.split("T")[0]).toLocaleDateString() + "," + transaction.fpxTransactionTime.split("T")[1].split("Z")[0]} </GridItem> }
                                                        {                 transaction.transactionStatus === "Failed" && transaction.debitAuthCode  &&  transaction.debitAuthCode === "51" && <GridItem  > { new Date( transaction?.fpxTransactionTime.split("T")[0]).toLocaleDateString() + "," +  transaction.fpxTransactionTime.split("T")[1].split("Z")[0]} </GridItem> }   
                                                        </GridContainer>
                                                </GridItem>
                                                <GridItem xs = {12} sm = {12} lg = {12}  > 
                                                    <GridContainer>
                                                        <GridItem xs = {6} md = {5} lg = {5} style = {{ fontWeight : 600 }} > Pay To </GridItem> : 
                                                        <GridItem> Yamaha LMS </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                                <GridItem xs = {12} sm = {12} lg = {12}  > 
                                                    <GridContainer>
                                                    <GridItem xs = {6} md = {5} lg = {5} style = {{ fontWeight : 600 }} > Bank </GridItem> : 
                                                    <GridItem> { transaction?.buyerBankBranch } </GridItem> 
                                                    </GridContainer>
                                                </GridItem>
                                                <GridItem xs = {12} sm = {12} lg = {12}  > 
                                                    <GridContainer>
                                                    <GridItem xs = {6} md = {5} lg = {5} style = {{ fontWeight : 600 }} > Amount </GridItem> : 
                                                    <GridItem> RM { transaction?.transactionAmount } </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                            
                                                <GridItem xs = {12} sm = {12} lg = {12} > 
                                                    <GridContainer>
                                                        <GridItem xs = {6} md = {5} lg = {5} style = {{ fontWeight : 600 }} > FPX Transaction ID </GridItem> : 
                                                        <GridItem> { transaction?.fpxTransactionId } </GridItem>
                                                    </GridContainer>
                                                </GridItem>

                                                <GridItem xs = {12} sm = {12} lg = {12}  > 
                                                    <GridContainer>
                                                    <GridItem xs = {6} md = {5} lg = {5} style = {{ fontWeight : 600 }} > Reference Number </GridItem> : 
                                                    <GridItem> { transaction?.sellerOrderNumber } </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                                {transaction.debitAuthCode  &&  transaction.debitAuthCode === "48" && <GridItem xs = {12} sm = {12} lg = {12}  > 
                                                    <GridContainer>
                                                    <GridItem xs = {6} md = {5} lg = {5} style = {{ fontWeight : 600 }} > Reason </GridItem> : 
                                                    <GridItem> { "Maximum Transaction Limit Exceeded RM 30000.00" } </GridItem>
                                                    </GridContainer>
                                                </GridItem>}
                                                {transaction.debitAuthCode  &&  transaction.debitAuthCode === "51" && <GridItem xs = {12} sm = {12} lg = {12}  > 
                                                    <GridContainer>
                                                    <GridItem xs = {6} md = {5} lg = {5} style = {{ fontWeight : 600 }} > Reason </GridItem> : 
                                                    <GridItem> { "Insufficient Fund" } </GridItem>
                                                    </GridContainer>
                                                </GridItem>}
                                                {transaction.debitAuthCode  &&  transaction.debitAuthCode === "2A" && <GridItem xs = {12} sm = {12} lg = {12}  > 
                                                    <GridContainer>
                                                    <GridItem xs = {6} md = {5} lg = {5} style = {{ fontWeight : 600 }} > Reason </GridItem> : 
                                                    <GridItem> { "Transaction Amount lower than  minimum limit RM 1.00" } </GridItem>
                                                    </GridContainer>
                                                </GridItem>}

                                            </GridContainer>
                                        </GridItem> 
                                    </>
                                }
                            </GridContainer>
                        </CardBody>
                        <CardFooter style = {{ justifyContent : 'end' }}>
                            <span style = {{ fontWeight : 600  }} > Powered by <img height = { 25 } width={68} src = { '/payment-fpx.jpeg' } /> </span>
                        </CardFooter>
                    </Card>
                </GridItem>
            
            </GridContainer>
        </BlockUi>
        </>
    );
}

