import { AppBar, makeStyles, Tab, Tabs } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import Roles from "./Roles";
import Users from "./Users";

const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'hidden',
        minHeight: 48,
        WebkitOverflowScrolling: 'touch', 
        display: 'flex',
        backgroundColor: "white",
        '& .MuiTabs-indicator' : { backgroundColor : '#FF9017' },
        "& .MuiButtonBase-root": {textTransform: "none"},
        color: 'black',
        borderRadius: '6px'
      }
}));

export default function RolesPermission (props) {

    HWLocalStorage.save(1, "previousSettingsTab");


    const classes = useStyles ();
    const [ selectedTab, setSelectedTab ] =useState (JSON.parse(HWLocalStorage.load ("previousRolesSettingsTab")) || 0);;
    const changeTab = (event, idx) =>  { setSelectedTab (idx); }


    return (
        <Fragment>
                <Tabs variant  = 'fullWidth' className = { classes.root } value = { selectedTab } onChange = { changeTab }  >
                    <Tab label = "Ops User Roles" />
                    <Tab label = "Users" />
                    

                </Tabs>
            {
                {
                    0 : <Roles />,
                    1 : <Users />,
                } [selectedTab]
            }

        </Fragment>
    );
};

