import { Button, Chip, FormControlLabel, makeStyles, Switch, TextField, withStyles } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from 'HWLibrary/HWInput';
import React, { useState, Fragment } from 'react';
import HWMessage from 'HWLibrary/HWMessage';

import Card from 'components/Card/Card';
import CardFooter from 'components/Card/CardFooter';
import CardHeader from 'components/Card/CardHeader';
import { NotificationImportant, People } from '@material-ui/icons';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { CKEditor } from 'ckeditor4-react';
import { useHistory  } from "react-router-dom";
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import AutocompleteTextArea from 'views/Utils/TextAreaAutoComplete';
const useStyles = makeStyles (theme => ({
    ...styles,
    card : { borderRadius : 10 },
    gridItem : { marginTop : 5 },
    draftButton : { background : '#FF9017', color : 'white', marginRight : 5 },
    proceedButton : { background : '#138DE8', color : 'white' },
    cardFooter : { justifyContent : 'center' },
    subjectText : { marginBottom : 4 }
}));

const PurpleSwitch = withStyles({
    switchBase: {
      color: '#138DE8',
      '&$checked': {
        color: '#138DE8',
      },
      '&$checked + $track': {
        backgroundColor: '#138DE8',
      },
    },
    checked: {},
    track: {},
})(Switch);

export default function Notification (props) {

    var placeholders = [
        { name: "Member Name", placeholder: "<Member Name>" },
        { name: "Membership Tier", placeholder: "<Membership Tier>" },
        { name: "Membership Number", placeholder: "<Membership Number>" },
        { name: "Points Balance", placeholder: "<Points Balance>" },
    
    ];


    var campaignarray=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    campaignarray= [...obj["Campaigns"]];

    const classes = useStyles ();

    const [ emailEnabled, setEmailEnabled ] = useState ( props.currentCampaign && props.currentCampaign.campaignNotificationConfig && props.currentCampaign.campaignNotificationConfig.emailNotificationStatus === "Enabled" || props.currentCampaign && props.currentCampaign.campaignNotificationConfig &&  props.currentCampaign.campaignNotificationConfig.emailNotificationStatus === true ? true : false);
    const [ smsEnabled, setSmsEnabled ] = useState ( props.currentCampaign && props.currentCampaign.campaignNotificationConfig && props.currentCampaign.campaignNotificationConfig.smsNotificationStatus === "Enabled" ||  props.currentCampaign && props.currentCampaign.campaignNotificationConfig &&  props.currentCampaign.campaignNotificationConfig.smsNotificationStatus === true ? true :false);
    const [ notificationEnabled, setNotificationEnabled ] = useState ( props.currentCampaign && props.currentCampaign.campaignNotificationConfig && props.currentCampaign.campaignNotificationConfig.pushNotificationStatus === "Enabled" ||  props.currentCampaign && props.currentCampaign.campaignNotificationConfig &&  props.currentCampaign.campaignNotificationConfig.pushNotificationStatus === true ? true :false);
    const maxLength = 160;
    const history = useHistory ();

    console.log(props.currentCampaign);
    console.log(emailEnabled , smsEnabled , notificationEnabled)
    console.log(props.currentCampaign && props.currentCampaign.campaignNotificationConfig && props.currentCampaign.campaignNotificationConfig.pushNotificationStatus);
    console.log(props.currentCampaign && props.currentCampaign.campaignNotificationConfig && props.currentCampaign.campaignNotificationConfig.emailNotificationStatus);
    console.log(props.currentCampaign && props.currentCampaign.campaignNotificationConfig && props.currentCampaign.campaignNotificationConfig.smsNotificationStatus);

    const [ loading, setLoading ] = useState (false);
    const [ smsMessage, setSmsMessage ] = useState ("");
    const [ emailMessage, setEmailMessage ] = useState ('');
    const [ emailSubject, setEmailSubject ] = useState ('');
    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});
    const { currentCampaign, setCurrentCampaign } = props;
    // Error based on this.
    
    const handleChange = (event) => {  

        console.log(event.target.name ,event.target.value);
        const fieldName = event.target.name;
        switch (fieldName) {
            case 'emailNotificationStatus' : 
            setCurrentCampaign ( prev => ({...prev , campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, emailNotificationStatus : !fetchValueFromNotification ('emailNotificationStatus') } }));
            setEmailEnabled(!emailEnabled);
            break;
            case 'smsNotificationStatus' :
                setCurrentCampaign ( prev => ({...prev ,campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, smsNotificationStatus : !fetchValueFromNotification ('smsNotificationStatus') } }));
                setSmsEnabled(!smsEnabled);
                 break;
            case 'pushNotificationStatus' :
                setCurrentCampaign ( prev => ({...prev , campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, pushNotificationStatus : !fetchValueFromNotification ('pushNotificationStatus') }  }));
                setNotificationEnabled(!notificationEnabled);
                  break;
        }
    };

    const fetchValueFromNotification = fieldName => {
        if (!currentCampaign || !currentCampaign.campaignNotificationConfig) { return false; }
        let notificationConfig = currentCampaign.campaignNotificationConfig;
        switch (fieldName) {
            case 'emailNotificationStatus' : return notificationConfig.emailNotificationStatus;
            case 'smsNotificationStatus' : return notificationConfig.smsNotificationStatus;
            case 'pushNotificationStatus' : return  notificationConfig.pushNotificationStatus;
            case 'subject' : return notificationConfig.emailNotificationConfig && notificationConfig.emailNotificationConfig.subject;
            case 'htmlBody' : return notificationConfig.emailNotificationConfig &&  notificationConfig.emailNotificationConfig.htmlBody;
            case 'smsText' : return notificationConfig.smsNotificationConfig && notificationConfig.smsNotificationConfig.message;
            case 'pushNotificationText' : return notificationConfig.pushNotificationConfig &&  notificationConfig.pushNotificationConfig.message;
        }
    };

    const validateInput = (name, value) => {
        console.log("Validating", name, value);
        switch (name) {
            case 'subject' : 
                if (value === '' || value < 0 || isNaN(value)) {
                    setHasError ({ ...hasError, [ name ] : true });
                    setErrorMessage ({ ...errorMessage, [ name ] : 'Invalid values provided' });
                    return false;
                } 
        }
        setHasError ({ ...hasError, [ name ] : false });
        setErrorMessage ({ ...errorMessage, [ name ] : null });
        return true;
    };

    const assignValueForNotification = (name, value) => {
        console.log("Assigning", name, value);
        switch (name) {
            
            case 'emailNotificationStatus' : 
            if(props.campaignMode === true){
                setCurrentCampaign (prev => ({ ...prev, campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, emailNotificationStatus : value } })); }
            else{
                setCurrentCampaign ( prev => ({...prev , campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, emailNotificationStatus : !fetchValueFromNotification ('emailNotificationStatus') } }));
            } 
                break;
            case 'smsNotificationStatus' :  
            if(props.campaignMode === true){
                setCurrentCampaign (prev => ({ ...prev, campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, smsNotificationStatus : value } })); }
            else {
                setCurrentCampaign ( prev => ({...prev ,campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, smsNotificationStatus : !fetchValueFromNotification ('smsNotificationStatus') } }));
            }
                break;
            case 'pushNotificationStatus' :   
            if(props.campaignMode === true){
                setCurrentCampaign (prev => ({ ...prev, campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, pushNotificationStatus : value } })); } 
             else {
                setCurrentCampaign ( prev => ({...prev , campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, pushNotificationStatus : !fetchValueFromNotification ('pushNotificationStatus') }  }));
            } 
                break;
            case 'subject':           
                if(props.campaignMode === true) { setCurrentCampaign (prev => ({ ...prev, campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, emailNotificationConfig : { ...(prev.campaignNotificationConfig && prev.campaignNotificationConfig.emailNotificationConfig) && prev.campaignNotificationConfig.emailNotificationConfig, subject : value } } }));   }   
                else { setCurrentCampaign (prev => ({ ...prev, campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, emailNotificationConfig : { ...(prev.campaignNotificationConfig && prev.campaignNotificationConfig.emailNotificationConfig) && prev.campaignNotificationConfig.emailNotificationConfig, subject : value } } }));   }
                break;
            case 'htmlText' : 
               if(props.campaignMode === true){
                   setCurrentCampaign (prev => ({ ...prev, campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, emailNotificationConfig : { ...(prev.campaignNotificationConfig && prev.campaignNotificationConfig.emailNotificationConfig) && prev.campaignNotificationConfig.emailNotificationConfig, htmlBody : value } } }));  
                }
                else{
                    setCurrentCampaign (prev => ({ ...prev, campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, emailNotificationConfig : { ...(prev.campaignNotificationConfig && prev.campaignNotificationConfig.emailNotificationConfig) && prev.campaignNotificationConfig.emailNotificationConfig, htmlBody : value } } }));  
                } 
                    break;
            case 'smsText' :          
                 if(props.campaignMode === true){    
                setCurrentCampaign (prev => ({ ...prev, campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, smsNotificationConfig : { ...prev.campaignNotificationConfig.smsNotificationConfig, message : value } } })); }
             else {
                setCurrentCampaign ( prev => ({...prev , campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, smsNotificationConfig : { ...prev.campaignNotificationConfig.smsNotificationConfig, message : value } } }));
            } 
                 break;
            case 'pushNotificationText' : 
            if(props.campaignMode === true){

                setCurrentCampaign (prev => ({ ...prev, campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, pushNotificationConfig : { ...(prev.campaignNotificationConfig && prev.campaignNotificationConfig.pushNotificationConfig) && prev.campaignNotificationConfig.pushNotificationConfig, message : value } } }));  }
            else {
                setCurrentCampaign ( prev => ({...prev , campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, pushNotificationConfig : { ...(prev.campaignNotificationConfig && prev.campaignNotificationConfig.pushNotificationConfig) && prev.campaignNotificationConfig.pushNotificationConfig, message : value } } }));
            }
                break;
        }
        return currentCampaign;
    };

    const captureInput = event => {
        const { name, value, checked } = event.target;
        validateInput (name, value);
        console.log("Capturing", event ,name, value, event.target.name, event.target.value);
        assignValueForNotification (name, value); 
        console.log("Current", currentCampaign);
    };  

    const checkDisabled = () => {

  //alert (JSON.stringify(props.currentCampaign));
        if (props.campaignMode === false) {
            if (props.currentCampaign && props.currentCampaign.status && props.currentCampaign.status === 'Draft') {
                return false;
            } else if (props.currentCampaign && props.currentCampaign.status && props.currentCampaign.status === 'Enabled') {
                if (props.campaignType === 'Live' ) 
                    return true;
            } 
        } else {
            return false;
        }
    }

    const handleBackClick = (event) => {
        props.backStep();
    }


    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    };
 
  //  if (    ( currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationStatus &&  currentCampaign.campaignNotificationConfig.emailNotificationStatus === true &&  currentCampaign.campaignNotificationConfig.emailNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject === '' && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject === null && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlBody === '' && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlBody === null)  ||     ( currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.smsNotificationConfig &&  currentCampaign.campaignNotificationConfig.smsNotificationStatus === true &&  currentCampaign.campaignNotificationConfig.smsNotificationConfig && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message === '' && currentCampaign.campaignNotificationConfig.emailNotificationConfig.message === null ) ||     ( currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationConfig &&  currentCampaign.campaignNotificationConfig.pushNotificationStatus === true &&  currentCampaign.campaignNotificationConfig.pushNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message === '' && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message === null ) )


    const validateConfiguration = (currentCampaign) => { 
        console.log(".....validtaion ",currentCampaign);
        if (currentCampaign && currentCampaign.campaignNotificationConfig && (currentCampaign.campaignNotificationConfig.emailNotificationStatus === false && currentCampaign.campaignNotificationConfig.smsNotificationStatus === false && currentCampaign.campaignNotificationConfig.pushNotificationStatus === false  ))
        {
            return true;
        }
        switch(currentCampaign.type)
        {
            case 'Advertisement' : 
         //   if ((currentCampaign.campaignNotificationConfig && ((currentCampaign.campaignNotificationConfig.emailNotificationStatus === true) || currentCampaign.campaignNotificationConfig.smsNotificationStatus === true || currentCampaign.campaignNotificationConfig.pushNotificationStatus === true )) ||  ((currentCampaign.campaignNotificationConfig ) && (currentCampaign.campaignNotificationConfig.emailNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject  && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject !== null && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject!== '' &&  currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlText && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlText !== null && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlText!== '')) && ((currentCampaign.campaignNotificationConfig) && (currentCampaign.campaignNotificationConfig.smsNotificationConfig && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message  && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message !== null && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message!== '')) && ((currentCampaign.campaignNotificationConfig) && (currentCampaign.campaignNotificationConfig.pushNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message  && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message !== null && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message!== ''))  ) 
            if (( currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationStatus &&  currentCampaign.campaignNotificationConfig.emailNotificationStatus === true &&  currentCampaign.campaignNotificationConfig.emailNotificationConfig && ((currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject === '' ||  currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject === null ) || ( currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlBody === '' || currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlBody === null))) 
             || ( currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.smsNotificationStatus  && currentCampaign.campaignNotificationConfig.smsNotificationStatus === true  &&  currentCampaign.campaignNotificationConfig.smsNotificationConfig && (currentCampaign.campaignNotificationConfig.smsNotificationConfig &&  currentCampaign.campaignNotificationConfig.smsNotificationConfig.message === null) ) 
             ||( currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationStatus  && currentCampaign.campaignNotificationConfig.pushNotificationStatus === true && currentCampaign.campaignNotificationConfig.pushNotificationConfig && (currentCampaign.campaignNotificationConfig.pushNotificationConfig.message === '' || currentCampaign.campaignNotificationConfig.pushNotificationConfig.message === null) ) )
             {
                 return false ; 
            } 
            break;
            case 'GenBluStore' : 
         //   if ((currentCampaign.campaignNotificationConfig && (currentCampaign.campaignNotificationConfig.emailNotificationStatus === true || currentCampaign.campaignNotificationConfig.smsNotificationStatus === true || currentCampaign.campaignNotificationConfig.pushNotificationStatus === true )) || ((currentCampaign.campaignNotificationConfig) && (currentCampaign.campaignNotificationConfig.emailNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject  && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject !== null && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject!== '' &&  currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlText && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlText !== null && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlText!== '')) && ((currentCampaign.campaignNotificationConfig) && (currentCampaign.campaignNotificationConfig.smsNotificationConfig && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message  && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message !== null && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message!== '')) && ((currentCampaign.campaignNotificationConfig) && (currentCampaign.campaignNotificationConfig.pushNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message  && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message !== null && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message!== ''))  ) 
         if (( currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationStatus &&  currentCampaign.campaignNotificationConfig.emailNotificationStatus === true &&  currentCampaign.campaignNotificationConfig.emailNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject === '' && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject === null && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlBody === '' && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlBody === null) 
         || ( currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.smsNotificationStatus  && currentCampaign.campaignNotificationConfig.smsNotificationStatus === true  &&  currentCampaign.campaignNotificationConfig.smsNotificationConfig && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message === '' && currentCampaign.campaignNotificationConfig.emailNotificationConfig.message === null ) 
         || ( currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationStatus  && currentCampaign.campaignNotificationConfig.pushNotificationStatus === true && currentCampaign.campaignNotificationConfig.pushNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message === '' && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message === null ) )
         {
                 return false ;
            } 
            break;
            case 'Event':
          //  if ((currentCampaign.campaignNotificationConfig && (currentCampaign.campaignNotificationConfig.emailNotificationStatus === true || currentCampaign.campaignNotificationConfig.smsNotificationStatus === true || currentCampaign.campaignNotificationConfig.pushNotificationStatus === true )) || ((currentCampaign.campaignNotificationConfig) && (currentCampaign.campaignNotificationConfig.emailNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject  && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject !== null && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject!== '' && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlText && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlText !== null && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlText!== '')) && ((currentCampaign.campaignNotificationConfig) && (currentCampaign.campaignNotificationConfig.smsNotificationConfig && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message  && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message !== null && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message!== '')) && ((currentCampaign.campaignNotificationConfig) && (currentCampaign.campaignNotificationConfig.pushNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message  && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message !== null && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message!== ''))  ) 
          if (( currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationStatus &&  currentCampaign.campaignNotificationConfig.emailNotificationStatus === true &&  currentCampaign.campaignNotificationConfig.emailNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject === '' && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject === null && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlBody === '' && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlBody === null) 
          || ( currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.smsNotificationStatus  && currentCampaign.campaignNotificationConfig.smsNotificationStatus === true  &&  currentCampaign.campaignNotificationConfig.smsNotificationConfig && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message === '' && currentCampaign.campaignNotificationConfig.emailNotificationConfig.message === null ) 
          || ( currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationStatus  && currentCampaign.campaignNotificationConfig.pushNotificationStatus === true && currentCampaign.campaignNotificationConfig.pushNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message === '' && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message === null ) )
          {
                return false;
            }   
            break;
            case 'MerchantEvent' : 

           // if ((currentCampaign.campaignNotificationConfig && (currentCampaign.campaignNotificationConfig.emailNotificationStatus === true || currentCampaign.campaignNotificationConfig.smsNotificationStatus === true || currentCampaign.campaignNotificationConfig.pushNotificationStatus === true )) || ( (currentCampaign.campaignNotificationConfig) && (currentCampaign.campaignNotificationConfig.emailNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject  && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject !== null && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject!== '' &&  currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlText && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlText !== null && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlText!== '')) && ((currentCampaign.campaignNotificationConfig) && (currentCampaign.campaignNotificationConfig.smsNotificationConfig && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message  && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message !== null && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message!== '')) && ((currentCampaign.campaignNotificationConfig) && (currentCampaign.campaignNotificationConfig.pushNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message  && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message !== null && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message!== ''))  ) 
           if (( currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationStatus &&  currentCampaign.campaignNotificationConfig.emailNotificationStatus === true &&  currentCampaign.campaignNotificationConfig.emailNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject === '' && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject === null && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlBody === '' && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlBody === null) 
           || ( currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.smsNotificationStatus  && currentCampaign.campaignNotificationConfig.smsNotificationStatus === true  &&  currentCampaign.campaignNotificationConfig.smsNotificationConfig && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message === '' && currentCampaign.campaignNotificationConfig.emailNotificationConfig.message === null ) 
           || ( currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationStatus  && currentCampaign.campaignNotificationConfig.pushNotificationStatus === true && currentCampaign.campaignNotificationConfig.pushNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message === '' && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message === null ) )
           {
                return false;
            }   

             break;
        }
        return true; }

        const saveDraft = event => { 
            if (!currentCampaign || currentCampaign === null) { return HWMessage.show ("Please enter one or more detail to save as campaign","error"); }
            props.saveDraft ();
            history.push('/ops/campaigns');
        };
    
        const handleDraftClick = (event) => {
            props.setOpen(true);

          //  saveDraft (event);
        }

        const saveProceed = event => {
        let validation = validateConfiguration (currentCampaign); 
       if (validation === false) 
            return HWMessage.show ("Errors in one or more fields, please rectify before proceeding","error");
        else {
            setCurrentCampaign (prev => ({ ...prev , campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig , 
                emailNotificationStatus : (prev.campaignNotificationConfig && prev.campaignNotificationConfig.emailNotificationStatus === true || prev.campaignNotificationConfig && prev.campaignNotificationConfig.emailNotificationStatus === "Enabled") ? "Enabled" : "Disabled",
                smsNotificationStatus : (prev.campaignNotificationConfig && prev.campaignNotificationConfig.smsNotificationStatus === true || prev.campaignNotificationConfig && prev.campaignNotificationConfig.smsNotificationStatus === "Enabled") ? "Enabled" : "Disabled",
                pushNotificationStatus : (prev.campaignNotificationConfig && prev.campaignNotificationConfig.pushNotificationStatus === true || prev.campaignNotificationConfig && prev.campaignNotificationConfig.pushNotificationStatus === "Enabled") ? "Enabled" : "Disabled",  } } )
            );
            props.advanceStep();    }     
     };
    const handleEditorChange = event => { 
       // console.log("Event",  event.editor, event.editor.getData()); 
      //  setEmailMessage(event.editor.getData());  
        setEmailMessage(event.target.value);
        const name = 'htmlText';
        // value = event.editor.getData();
        //assignValueForNotification (name, value);
        assignValueForNotification(name,event.target.value);
    };
    const handleSubject = event => setEmailSubject (event.target.value);

    return (
        <Fragment>
            <Card>
                <CardHeader className = { classes.cardHeader } color = 'rose' icon >
                    <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        <h4 className = { classes.cardIconTitle } > Notification  </h4> 
                </CardHeader>     
                <CardBody>
                    <GridContainer >
                        <GridItem xs = {12} sm = {12} md = {12} >
                            <GridContainer>
                                <GridItem xs = {12} md = {12} sm = {12} >    
                                    <FormControlLabel
                                        control={ <PurpleSwitch checked={ emailEnabled } onChange={handleChange} name="emailNotificationStatus" />}
                                        label = "Email Notification"
                                    />    
                                </GridItem  > 
                               { emailEnabled && <GridItem xs = {12} md = {12} sm = {12} >
                                    <h4>Recommended Placeholders</h4>
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900 , margin: 5}} label =  "<Member Name>"  />
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900 ,margin: 5}} label =  "<Membership Tier>"  />
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900,margin: 5}} label =  "<Membership Number>"  />
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900,margin: 5}} label =  "<Points Balance>"  />
                            </GridItem >  }
                            
                                {/* {  fetchValueFromNotification ('emailNotificationStatus') && <GridItem xs = {12} md = {12} sm = {12}  > 
                                    <TextField fullWidth label = "Subject" value = { props.createMode === true ? fetchValueFromNotification('subject') : (currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject ) ? currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject : fetchValueFromNotification('subject') } name = 'subject' className = { classes.subjectText } onChange = { e =>  { console.log ("hare ram hare ram", e.target.name, e.target.value); captureInput(e); } } /> 
                                    <CKEditor     initData={props.campaignMode === true ? fetchValueFromNotification('htmlBody')  :  (currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlBody ) ? currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlBody : fetchValueFromNotification('htmlBody')} name='htmlBody' onChange = { handleEditorChange }  />
                                    `1
                                </GridItem>  } */}

                                        { emailEnabled  && <GridItem xs = {12}  md = {12} sm = {12} > 
                                             {/* <TextField fullWidth label = "Subject" value = { props.createMode === true ? fetchValueFromNotification('subject') : (currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject ) ? currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject : fetchValueFromNotification('subject') } name = 'subject' className = { classes.subjectText } onChange = { e =>  { console.log ("hare ram hare ram", e.target.name, e.target.value); captureInput(e); } } />  */}
                                             <h4>Subject</h4>
                                             <AutocompleteTextArea label="Subject" disabled={false}   value = {props.createMode === true ? fetchValueFromNotification('subject') : (currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject ) ? currentCampaign.campaignNotificationConfig.emailNotificationConfig.subject : fetchValueFromNotification('subject')}
                                            name = "subject" onChange = { e => assignValueForNotification("subject", e.target.value) } placeholders={placeholders} />
                                            <h4>Notification Body</h4>
                                            <AutocompleteTextArea label="pushNotificationText" disabled={false}   value = {props.campaignMode === true ? fetchValueFromNotification('htmlBody')  :  (currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig && currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlBody ) ? currentCampaign.campaignNotificationConfig.emailNotificationConfig.htmlBody : fetchValueFromNotification('htmlBody')}
                                            name = "htmlBody" onChange = { handleEditorChange } placeholders={placeholders} />

                                </GridItem> }

                            </GridContainer>
                        </GridItem>
                        <GridItem xs = {12} sm = {12} md = {12} >
                            <GridContainer>
                                <GridItem> <FormControlLabel control={ <PurpleSwitch checked={  smsEnabled  } onChange={ handleChange } name="smsNotificationStatus" />} label = "SMS Notification" />  </GridItem> 
                                { smsEnabled && <GridItem xs = {12} md = {12} sm = {12} >
                                    <h4>Recommended Placeholders</h4>
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900 , margin: 5}} label =  "<Member Name<>"  />
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900 ,margin: 5}} label =  "<Membership Tier>"  />
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900,margin: 5}} label =  "<Membership Number>"  />
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900,margin: 5}} label =  "<Points Balance>"  />
                            </GridItem >  }
                                {  smsEnabled && <GridItem xs = {12} sm = {12}  > 
                                        <h4>Notification Body</h4>

                                        <AutocompleteTextArea label="smsText" disabled={false}  value = { props.createMode === true ? fetchValueFromNotification('smsText') : (currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.smsNotificationConfig && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message ) ? currentCampaign.campaignNotificationConfig.smsNotificationConfig.message: fetchValueFromNotification('smsText')  }

                                            name = "smsText"  onChange = {e => assignValueForNotification ("smsText" , e.target.value)} placeholders={placeholders} />
                                            {/* <TextField 
                                                style = {{ marginTop : 20 }}
                                                multiline = { true }
                                                rows = { 10 }
                                                onChange = {e => assignValueForNotification ("smsText" , e.target.value)}

                                                rowsMax = { 20 }
                                                value = { props.createMode === true ? fetchValueFromNotification('smsText') : (currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.smsNotificationConfig && currentCampaign.campaignNotificationConfig.smsNotificationConfig.message ) ? currentCampaign.campaignNotificationConfig.smsNotificationConfig.message: fetchValueFromNotification('smsText')  }
                                                fullWidth
                                                variant = "outlined"
                                                label = {`SMS Message : Length ${ smsMessage.length } / ${ maxLength }` }
                                                //placeholder = 'Specify the text message. Please use templates and placeholders to address any relevant details'
                                                defaultValue = { 'Dear <Member Name>,' }
                                                InputLabelProps = {{ shrink : true }}
                                                inputProps = {{ maxLength : maxLength }}
                                                onBlur = { e => captureInput(e) }
                                                name = 'smsText'
                                                error = { checkError ('smsText') }
                                                helperText = { fetchErrorMessage ('smsText') }
                                            /> */}

{/* hecked={  props.createMode === true ?  fetchValueFromNotification('pushNotificationStatus') : (currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationStatus) ? !(currentCampaign.campaignNotificationConfig.pushNotificationStatus) : false */}
                                    </GridItem> 
                                }
                            </GridContainer>
                        </GridItem>
                        <GridItem xs = {12} sm = {12} md = {12} >
                            <GridContainer>
                                <GridItem> <FormControlLabel control={ <PurpleSwitch checked={  notificationEnabled } onChange={e => handleChange(e) } name="pushNotificationStatus" />} label = "Push Notification" />  </GridItem> 
                                { notificationEnabled && <GridItem xs = {12} md = {12} sm = {12} >
                                    <h4>Recommended Placeholders</h4>
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900 , margin: 5}} label =  "<Member Name>"  />
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900 ,margin: 5}} label =  "<Membership Tier>"  />
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900,margin: 5}} label =  "<Membership Number>"  />
                                   <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900,margin: 5}} label =  "<Points Balance>"  /> 
                             </GridItem >  }
                                 { notificationEnabled   && <GridItem xs = {12} sm = {12} > 
                                            {/* <TextField 
                                                style = {{ marginTop : 20 }}
                                                multiline = { true }
                                                rows = { 10 }
                                                rowsMax = { 20 }
                                                value = { props.createMode === true ? fetchValueFromNotification('notificationText') : (currentCampaign  && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message) ? currentCampaign.campaignNotificationConfig.pushNotificationConfig.message: fetchValueFromNotification('notificationText') }
                                                fullWidth
                                                variant = "outlined"
                                                label = {`Notification Message` }
                                                //placeholder = 'Specify the text message. Please use templates and placeholders to address any relevant details'
                                                defaultValue = { 'Dear <Member Name>,' }
                                                name = { 'pushNotificationText' }
                                                InputLabelProps = {{ shrink : true }}
                                                inputProps = {{ maxLength : maxLength }}
                                                onBlur = { e => captureInput(e) }
                                                error = { checkError ('notificationText') }
                                                onChange = {e => assignValueForNotification ("pushNotificationText" , e.target.value)}
                                                helperText = { fetchErrorMessage ('notificationText') }
                                            /> */}
                                            <h4>Notification Body</h4>
                                            <AutocompleteTextArea label="pushNotificationText" disabled={false}   value = { props.createMode === true ? fetchValueFromNotification('notificationText') : (currentCampaign  && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationConfig && currentCampaign.campaignNotificationConfig.pushNotificationConfig.message) ? currentCampaign.campaignNotificationConfig.pushNotificationConfig.message: fetchValueFromNotification('notificationText') }
                                            name = "pushNotificationText" onChange = {e => assignValueForNotification ("pushNotificationText" , e.target.value)}
                                            placeholders={placeholders} />

                                </GridItem> }
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </CardBody>
                <CardFooter className = { classes.cardFooter } >
                        <center> 
                        <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }}   onClick = { e=>handleBackClick(e) } > Back </Button> 
                        { props.campaignType === 'Expired' &&  <Button variant = "contained" style = {{ backgroundColor : '#138de8', color : 'floralwhite', textTransform : 'none' }}  onClick = { e => history.push('/ops/campaigns')  } > Close </Button>}

{   campaignarray.includes("Action")  ===true &&  props.campaignType !== 'Expired' &&  <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} disabled = {checkDisabled()}  onClick = { handleDraftClick } > Save as Draft </Button> 
}                            <Button variant = "contained" style = {{ backgroundColor : '#ff9017', color : 'floralwhite', textTransform : 'none' }} onClick = { saveProceed } > Proceed </Button>
                        </center>
                </CardFooter>
            </Card>       
        </Fragment>
    );
}