import {  ListItem, ListItemIcon,  ListItemText, IconButton, List, Checkbox, Radio, ButtonBase, CardMedia, RadioGroup, FormControlLabel, FormLabel, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import HWMessage from 'HWLibrary/HWMessage';
import paymentApiService from './APIService';
import {DropDown} from '../Merchant/Merchant';
import { HWInput } from 'HWLibrary/HWInput';
import CardHeader from 'components/Card/CardHeader';
import BlockUi from 'react-block-ui';
import * as uuid from "uuid";
import HWLocalStorage from 'HWLibrary/HWLocalStorage';


const sellerOrderNumber = "199229ec-24f3-495b-a1c1-d00c49b60b10";
const sellerExOrderNumber = uuid.v4();

export default function BanksList (props) {
    
    const [checked, setChecked] = useState(null);
    const [ bankDetailList, setBankDetailList ] = useState ([]);
    const [ bankList, setBankList ] = useState ([ 'Bank Of Baroda', 'State Bank Of India' ]);
    const [ amount, setAmount ] = useState (props?.location?.params?.state?.amount);
    const [ token, setToken ] = useState ('01');
    const [ bankListDetail, setBankListDetail ] = useState ([]);
    const [ loading, setLoading ] = useState(false);
    const [ loadingMessage, setLoadingMessage ] = useState ("Fetching Relevant Bank List. Please Wait...");
    const [ buyerEmail , setBuyerEmail ] = useState(HWLocalStorage.load("ops-user").emailAddress);
    const [ name , setName ] = useState(HWLocalStorage.load("ops-user").name);

    const sellerOrderNumber = uuid.v4();
   const sellerExOrderNumber = uuid.v4();

  

    useEffect (() => {

        setLoading (true);
        var transactionModel = token === "01" ? "B2C" : "B2B1";
        paymentApiService.fetchBankList ( transactionModel )
        .then (response => {
            if (! response || ! response.data) { HWMessage.show("Response cannot be fetched", "error"); }
            HWMessage.show ("Bank List fetched Successfully", "success");
            setBankDetailList (response.data.banks);
            setBankList (response.data.banks.map (i => i.displayName));
            setBankListDetail ( response.data.banks );

        })
        .catch (error => { HWMessage.show ("Bank List cannot be fetched", "error"); })
        .finally (() => { setLoading (false) });
    }, [ token ]);

    const handleChange = event => setChecked (event.target.value);

    async function postData(url = '', data = {}) {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key':'4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45'
            },
            body: JSON.stringify(data) 
        });
        return response.json().catch(error=> { console.log(error); }); 
    }

    async function updateSignature() {

        if (! checked) {
            HWMessage.show ("Please select bank to continue", "error"); return;
        } if ( ! amount) {
            HWMessage.show ("Please enter the amount", "error"); return;
        }
        console.log ("Checked bank ", checked, bankDetailList, bankDetailList.filter (i => i.displayName === checked));

        var matchingBank = bankDetailList.filter (i => i.displayName === checked.displayName);
        var bankCode = matchingBank [0].bankCode;
        var amountStringified = amount + ".00";

        var count = (amountStringified.split('').filter(i => i === ".") || []).length;
        if (count > 1) {
            var lastIndex = amountStringified.lastIndexOf (".");
            amountStringified = amountStringified.substring (0, lastIndex);
        }
        let data = {
            "fields": {
                "fpx_msgType": "AR",
                "fpx_msgToken": `${ token }`,
                "fpx_sellerExId": "EX00011375",
                "fpx_sellerExOrderNo": `${ sellerExOrderNumber }`,
                "fpx_sellerTxnTime": "20211012095905",
                "fpx_sellerOrderNo": `${ sellerOrderNumber }`,
                "fpx_sellerId": "SE00012887",
                "fpx_sellerBankCode": "01", 
                "fpx_txnCurrency": "MYR",
                "fpx_txnAmount": `${ amountStringified }`,
                "fpx_buyerEmail": `${buyerEmail}`,
                "fpx_buyerName": `${name}`,
                "fpx_buyerBankId": `${ bankCode }`,
                "fpx_buyerBankBranch": "",
                "fpx_buyerAccNo": "",
                "fpx_buyerId": "",
                "fpx_makerName": "",
                "fpx_buyerIban": "",
                "fpx_productDesc": "Yamaha UAT Testing",
                "fpx_version": "7.0"
            }
        };
        console.log ("Prepared Data", data);
        setLoadingMessage ("Redirecting to Banking Page. Please Wait");
        setLoading (true);
        
        await postData('https://api-dev.yamaha-lms.hummingwave.com/payment/fpx-sign', data).then(response => {
        
            document.getElementById("fpx_sellerExOrderNo").value = sellerExOrderNumber;
            document.getElementById("fpx_sellerOrderNo").value = sellerOrderNumber;
            document.getElementById("fpx_txnAmount").value = amountStringified;
            document.getElementById("fpx_buyerBankId").value = bankCode; //"TEST0021";
            document.getElementById("fpx_buyerEmail").value = buyerEmail;
            document.getElementById("fpx_buyerName").value = name;

             document.getElementById("fpx_checkSum").value = response.message;
             document.getElementById("fpx_msgToken").value = token; 
             document.getElementById("payment_form").submit();
            console.log(response); 
        }).finally (() => setLoading (false));
    };
    
    return (
        <BlockUi blocking = { loading } message = { loadingMessage } >
            <GridContainer >
                <GridItem xs = {12} md = {7} lg = {7}>
                    <Card>
                        
                        <CardHeader> 
                        <center>  <span style = {{ fontWeight : 500, fontSize: 26 }} > Pay with <img height = { 32 } src = { '/payment-fpx.jpeg' } /> ( Current and Savings Account ) </span>  </center>
                        <center>  <span style = {{ fontWeight : 500, fontSize: 13 , fontColor: "#D4D0CF"}} > Amount should be between RM 1 and RM 30,000 . </span>  </center>
                        </CardHeader>
                        <CardBody>
                            <GridContainer  >
                                <GridItem xs = { 12 } >
                                    <div style={{display : "flex"}}> 
                                    <FormLabel  component = "legend" style = {{ marginTop : 20 }} > Payment Type &nbsp;  &nbsp; &nbsp; &nbsp;</FormLabel>
                                    <RadioGroup  row aria-label = "campaign-type" name = "type" label = { 'Type' } defaultValue = { "01" } value = { token } > 
                                        {/* <FormControlLabel 
                                            value = {"02" }
                                            control = { <Radio style = {{ color : '#138DE8' }} 
                                            onChange = { event => { 
                                                if (event.target.checked)  {
                                                    setToken("02");
                                                }    
                                            } }
                                            /> } 
                                            label = { 'Corporate Internet Banking' } 
                                        
                                        />   */}
                                        <FormControlLabel 
                                            value = {"01" }
                                            control = {  <Radio style = {{ color : '#138DE8' }} 
                                            onChange = { event => { 
                                                if (event.target.checked)  {
                                                    setToken("01");
                                                }    
                                            } }
                                            />} 
                                            label = { 'Retail Internet Banking' } 
                                        />  
                                    </RadioGroup> 
                                    </div>
                                    
                                </GridItem>

                                <GridItem xs = { 12 } md = {4} lg = {4} >
                                    <HWInput 
                                        type = "number"
                                        name = "amount" 
                                        label = { 'Amount' } 
                                        onBlur = { e =>  { console.log (e); setAmount (e.target.value);  }  } 
                                        value = { amount == 0 ? null : amount } 
                                        InputProps = {{ endAdornment : <span position = "end" >  RM </span> }} 
                                        placeholder = "Enter Amount in RM"
                                    /> 
                                </GridItem>
                                <GridItem xs = { 12 } md = {4} lg = {4} >
                                    {/* <DropDown 
                                        minWidth = { 330 }
                                        labelCategory = "Select Bank" 
                                        name = 'bank'
                                        values = { bankList } 
                                        disabled = { false }
                                        targetValue = { checked }
                                        onChange = { (event) => { setChecked (event.target.value) } }
                                    /> */}

                                    <FormControl fullWidth >
                                        <InputLabel id={`label-${props.labelCategory}-category`}  disabled = { props.disabled } > Select Bank List </InputLabel>
                                        <Select
                                            name = { "banks" }
                                            fullWidth
                                            style = {{ width : 320, borderColor : '#138DE8' }}
                                            value = {  checked }
                                            disabled = { false }
                                            onChange =  { (e) => { console.log("Checking", e.target.value); setChecked (e.target.value); } }
                                        >
                                            <MenuItem  disabled value = { undefined } > <b> { (token &&  token === '01' ? "Retail Banking" : "Corporate Banking") } </b>  </MenuItem>
                                            { bankDetailList.map ((item, idx) => <MenuItem value = {item} key = {idx} disabled = { item.bankActiveStatus === "Blocked" } > { item.displayName } { item.bankActiveStatus === "Blocked" ? "(Offline)" : "" } </MenuItem>) }
                                        </Select>
                                    </FormControl>
                                </GridItem>

                                {/* <GridItem xs = { 12 } md = {4} lg = {4} >
                                    <HWInput 
                                        disabled
                                        name = "referenceNumber" 
                                        label = { 'Reference Number' } 
                                        value = { sellerOrderNumber } 
                                    /> 
                                </GridItem> */}

                                <GridItem xs = {12} lg = {12} md = {12} style = {{ marginTop : 10 }} >
                                    <span style = {{ fontWeight : 500 }} > <sup>*</sup> By Clicking on &ldquo; Proceed &rdquo; Button, you hereby agree with <a style = {{ color : '#138DE8', fontWeight : 500 }} href = "https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp" target = "_blank" > FPX Terms &amp; Conditions </a>   </span>
                                </GridItem>
                            </GridContainer>
                            <GridItem xs = {12} style = {{ marginTop : 20 }} > <center> <Button variant = "contained" raised disabled = { ! (amount && amount > 0) } style = {{ width : 260, height : 45, background : ! (amount && amount >= 0) ? "#CFCFCF" : "#138DE8", color : ! (amount && amount >= 0) ? 'black' : 'floralwhite' }}  onClick = { e => updateSignature() } >  Proceed </Button> </center> </GridItem>
                    </CardBody>
                    <CardFooter style = {{ justifyContent : 'end' }}>
                            <span style = {{ fontWeight : 600  }} > Powered by <img height = { 25 } width={68} src = { '/payment-fpx.jpeg' } /> </span>
                    </CardFooter>
                    </Card>
            </GridItem>
            <GridItem>
                <form method="post" id="payment_form"  action="https://uat.mepsfpx.com.my/FPXMain/seller2DReceiver.jsp">
                    <input type="hidden"  name="fpx_msgType" value="AR" />
                    <input type="hidden"  id = "fpx_msgToken" name="fpx_msgToken" value="01"/>
                    <input type="hidden"  name="fpx_sellerExId" value="EX00011375"/>
                    <input type="hidden"  id="fpx_sellerExOrderNo" name="fpx_sellerExOrderNo" value=""/>
                    <input type="hidden"  name="fpx_sellerTxnTime" value="20211012095905"/>
                    <input type="hidden"  id="fpx_sellerOrderNo" name="fpx_sellerOrderNo" value=""/>
                    <input type="hidden"  name="fpx_sellerId" value="SE00012887"/>
                    <input type="hidden"  name="fpx_sellerBankCode" value="01" />
                    <input type="hidden"  name="fpx_txnCurrency" value="MYR"/>
                    <input type = "hidden" id="fpx_txnAmount" name="fpx_txnAmount" value="150.00"/>
                    <input type="hidden"  name="fpx_buyerEmail" id="fpx_buyerEmail" value="trinetra.ks@hummingwave.com"/>
                    <input  id="fpx_checkSum" type="hidden"  name="fpx_checkSum" value=""/>
                    <input type="hidden"  name="fpx_buyerName" id="fpx_buyerName" value="Trientra"/>
                    <input type="hidden" id = "fpx_buyerBankId"  name="fpx_buyerBankId" value="TEST0021"/>
                    <input type="hidden"  name="fpx_buyerBankBranch" value=""/>
                    <input type="hidden"  name="fpx_buyerAccNo" value=""/>
                    <input type="hidden"  name="fpx_buyerId" value=""/>
                    <input type="hidden"  name="fpx_makerName" value=""/>
                    <input type="hidden"  name="fpx_buyerIban" value=""/>
                    <input type="hidden"  name="fpx_productDesc" value="Yamaha UAT Testing"/>
                    <input type="hidden"  name="fpx_version" value="7.0"/>
                    <input type="hidden" value="Click To Pay" onclick="updateSignature()"/>
                </form>


            {/* <form method="post" id = "payment_form" action="https://uat.mepsfpx.com.my/FPXMain/seller2DReceiver.jsp">
            <input id = "fpx_msgType" type="hidden"  name="fpx_msgType" value="AR"/>
            <input id = "fpx_msgToken" type="hidden"  name="fpx_msgToken" value="01"/>
            <input id = "fpx_sellerExId" type="hidden"  name="fpx_sellerExId" value="EX00011375"/>
            <input id = "fpx_sellerExOrderNo" type="hidden"  name="fpx_sellerExOrderNo" value=""/>
            <input id = "fpx_sellerTxnTime" type="hidden"  name="fpx_sellerTxnTime" value="20211012015905"/>
            <input id = "fpx_sellerOrderNo" type="hidden"  name="fpx_sellerOrderNo" value=""/>
            <input id = "fpx_sellerId" type="hidden"  name="fpx_sellerId" value="SE00012887"/>
            <input id = "fpx_sellerBankCode" type="hidden"  name="fpx_sellerBankCode" value="01"/>
            <input id = "fpx_txnCurrency" type="hidden"  name="fpx_txnCurrency" value="MYR"/>
            <input id = "fpx_txnAmount" type="hidden"  name="fpx_txnAmount" value=""/>
            <input id = "fpx_buyerEmail" type="hidden"  name="fpx_buyerEmail" value="trinetra.ks@hummingwave.com"/>
            <input id = "fpx_checkSum" type="hidden"  name="fpx_checkSum" value=""/>
            <input id = "fpx_buyerName" type="hidden"  name="fpx_buyerName" value="Trientra"/>
            <input id = "fpx_buyerBankId" type="hidden"  name="fpx_buyerBankId" value=""/>
            <input id = "fpx_buyerBankBranch" type="hidden"  name="fpx_buyerBankBranch" value=""/>
            <input id = "fpx_buyerAccNo" type="hidden"  name="fpx_buyerAccNo" value=""/>
            <input id = "fpx_buyerId" type="hidden"  name="fpx_buyerId" value=""/>
            <input id = "fpx_makerName" type="hidden"  name="fpx_makerName" value=""/>
            <input id = "fpx_buyerIban" type="hidden"  name="fpx_buyerIban" value=""/>
            <input id = "fpx_productDesc" type="hidden"  name="fpx_productDesc" value="Yamaha UAT Testing"/>
            <input id = "fpx_version" type="hidden"  name="fpx_version" value="7.0"/>
            <input id = "fpx_button" type="submit" value="Click To Pay"  />
        </form> */}
                
            </GridItem>
        </GridContainer>
    </BlockUi>
      
    );
  }