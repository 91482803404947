import axios from 'axios';
import authService from 'HWLibrary/ApiService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import appConfig from './../../../HWLibrary/HWConfig';

class VoucherApiService {

  constructor() {
    if (! VoucherApiService.instance) { VoucherApiService.instance = this }
    return VoucherApiService.instance;
  }
  defaultAuthConfig = () => {
    const accessToken =  authService.getCachedAccessToken();
    return { headers: { Authorization: `Bearer ${accessToken}` } };
  };

  fetch = (request) => {
    var fetchType = null, memberId = null;
    fetchType = 'All';

    if (request) { memberId = request.memberId; };
    const queryParams = request !== undefined ? { params: { voucherFetchType : fetchType } } : {};
    console.log("Querying Vouchers :: ", queryParams);

    const url = `${ appConfig.serverBaseUrl }/members/${ memberId }/vouchers`;
    const config = {...this.defaultAuthConfig(), ...queryParams };
    return authService.axiosService().get(url, config);
  };

  fetchCoupons = (request) => {
    var memberId = null;
    if (request && request.memberId) { memberId = request.memberId; }

    const url = `${appConfig.serverBaseUrl}/members/${memberId}/e-service-coupons`;
    const config = { ...this.defaultAuthConfig()};
    return authService.axiosService().get(url, config); 
  };

  fetchVoucherDetails = (voucherId) => {
    if (voucherId) {
      const url = `${appConfig.serverBaseUrl}/vouchers/${voucherId}`;
      console.log("URL", url);
      const config = {...this.defaultAuthConfig()};
      return authService.axiosService().get(url, config);
    }
    else 
      console.log("Voucher ID must be provided for feteching details");
  };
  fetchCouponDetails = (voucherCode) => {
    if (voucherCode) {
      const url = `${appConfig.serverBaseUrl}/merchants/e-service-coupons/${voucherCode}`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().get(url, config);
    }
  };
};

const voucherApiService = new VoucherApiService();
export default voucherApiService;
