import { Button, makeStyles , TextField } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from 'HWLibrary/HWInput';
import pointsApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import { useCallback } from 'react';
import BlockUi from 'react-block-ui';
import { DropDown } from 'views/Merchant/Merchant';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import voucherConfigApiService from './APIService';
import { setConstantValue } from 'typescript';
import { getMultiValue } from 'chartist';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import Edit from '@material-ui/icons/Edit';
import StatusDialog from 'views/Utils/StatusDialog';
const useStyles = makeStyles (theme => ({
    ...styles,
    saveButton :  { color: 'floralwhite', backgroundColor : '#138DE8' , margin: 2.5 },
    cancelButton : { color: 'floralwhite', backgroundColor : '#FF9017' , margin: 2.5},
    card : { width : 700 }
}));

export default function RuleForm (props) {
    var vouchers=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    vouchers= [...obj["Vouchers"]];

    const classes = useStyles();

    const [ currentRule, setCurrentRule ] = useState ({});
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ productCategoryList, setProductCategoryList ] = useState ([]);
    const [ tierList, setTierList ] = useState ([]);
    const { createMode, ruleId } = props;
    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});
    const [ saveOpen, setSaveOpen ] = useState (false);
    const [ createOpen, setCreateOpen ] = useState (false);
    const [ voucher, setVoucher ] = useState([]);
    const [ isLoading, setIsLoading ] = useState (false);
    const [ refreshState, setRefreshState ] = useState (false);
    const [edit, setEdit] = useState(false);
    const [ page, setPage ] = useState (1);
    const [ pageSize, setPageSize ] = useState (100);

    const [ open3 , setOpen3] = useState(false);
    const [ open2 , setOpen2] = useState(false);
    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    }

   

    const checkDisabled = () => {
       if(currentRule && currentRule.type &&  currentRule.type!== '' && currentRule.voucherCode && currentRule.voucherCode!==''
       && currentRule.quantity && currentRule.quantity > 0 && currentRule.endDate && currentRule.endDate!=='' &&  currentRule.startDate &&  currentRule.startDate!=='' && currentRule.status && currentRule.status!==''
       && currentRule.description && currentRule.description!==''){
           return false;
       }
       else{
           return true ; 
       }
    }; 


    const isValid = rule => {
        let { validityStart, validityEnd } = rule;
        var startDate = new Date (validityStart);
        var endDate = new Date (validityEnd);
        var currentDate = new Date();
        
        return ! (endDate >= currentDate);
    }; 
    const isDisabled = false;

    const updateRule = useCallback (rule => {
        if (rule !== null) {
            let successStatus = null;
            setRuleLoading (true);
            voucherConfigApiService.updateAwardRule (rule) 
            .then (response => {  
                if (! response || ! response.data) { HWMessage.show ("Error while fetching response" , "error"); }

                HWMessage.show("Update Request Successful" , "success");
                setCurrentRule (response.data);
                setOpen2(!open2);
                successStatus = true;
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);  
                if (successStatus === true)
                console.log("as");
                props.setRefreshState(prev=>!prev);

            });
        }
    });
    const createRule = useCallback (rule => {
        if (rule !== null)  {
            let successStatus = null;
            setRuleLoading (true);
            voucherConfigApiService.createAwardRule (rule)
            .then (response => {
                if (! response || ! response.data) { HWMessage.show ("Error while fetchinmg response" , "error"); }
                HWMessage.show("Create Request Successful" , "success");
                setCurrentRule (response.data);
                props.setRefreshState (prev => !prev);
                setOpen3(!open3);

                successStatus = true;
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);
                if (successStatus === true)
                console.log("as");
            });
        }
    });

    
    useEffect (() => {
        if(props.createMode == false){
            setRuleLoading (true);
            voucherConfigApiService.fetchSpecificAwardRule (props.ruleId)
            .then (response => {  
                if (! response || ! response.data) { return HWMessage.show ("Specific Voucher Cannnot be fetched" , "error"); }
                HWMessage.show("Request Successful" , "success");
                setCurrentRule (response.data); 
            })
            .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => setRuleLoading (false));
        }
       
    }, [])



    useEffect (() => {
        setIsLoading (true);
        var paginationBookmark = ((page - 1) * pageSize);
        var fetchLimit = pageSize;
        var a= [];
        voucherConfigApiService.fetchVoucherCreationList (fetchLimit, paginationBookmark)
        .then (response => {
            if (!response || !response.data)
                return console.log("No relevant data can be fetched");
                HWMessage.show("Request Successful" , "success");
            console.log("Printing ::", response);
        //    for(var i =0 ; i < response.data.configCounts ; i++)
        //    {
        //      a.push(response.data.voucherConfigModelList[i].categoryName);
        //    } 
        //    console.log(a);
           setVoucher (response.data.voucherConfigModelList);
        })
        .catch (error => { console.log("Error while fetching data", error); HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() => { setIsLoading (false); });
    }, [ page, pageSize ,  refreshState ]);
    
    const setValue = (field) => {
        switch(field)
        {
            case "Bike Approval" :        setCurrentRule(prev => {  prev.type = "BikeApproval";  return { ...prev }; });   break;
            case "New User" :        setCurrentRule(prev => {  prev.type = "NewUser";  return { ...prev }; });    break;
            case "Event Purchase" :        setCurrentRule(prev => {  prev.type = "EventPurchase";  return { ...prev }; });  break;
            case "Sign Up" :        setCurrentRule(prev => {  prev.type = "SignUp";  return { ...prev }; });   break;
            case "Order Punch" :        setCurrentRule(prev => {  prev.type = "OrderPunch";  return { ...prev }; });  break;
            case "Regular Purchase" :        setCurrentRule(prev => {  prev.type = "RegularPurchase";  return { ...prev }; });  break;
            default :  setCurrentRule(prev => {  prev.type = field ;  return { ...prev }; }); 

        }
    }
    
   
    const getValue = () => {
        console.log(currentRule && currentRule.type);

        switch(currentRule && currentRule.type)
        {
            case "BikeApproval" :           return   "Bike Approval" ; break;
            case "NewUser" :         return   "New User" ; break;
            case "EventPurchase" :         return   "Event Purchase" ; break;
            case "SignUp" :         return   "Sign Up" ; break;
            case "OrderPunch" :        return   "Order Punch" ; break;
            case "RegularPurchase" :         return   "Regular Purchase" ; break;
            default :   return currentRule.type; 

        }
    }


    const setValue2 = (field) => {
        switch(field)
        {
            case "Yes" :        setCurrentRule(prev => {  prev.status = "Active";  return { ...prev }; });   break;
            case "No" :        setCurrentRule(prev => {  prev.status = "Draft";  return { ...prev }; });    break;

        }
    }
    
   
    const getValue2 = () => {
        console.log(currentRule && currentRule.status);

        switch(currentRule && currentRule.status)
        {
            case "Active" :           return   "Yes" ; break;
            case "Draft" :         return   "No" ; break;
            

        }
    }


    const captureChanges = event => { 
        const field = event.target.name;
        const value = event.target.value;

         console.log(currentRule , value);
         switch(field){
           case 'quantity' :         setCurrentRule (prev => ({ ...prev, [field] : +value })); break;
           default :  setCurrentRule (prev => ({ ...prev, [field] : value })); 
         }
    
    
    };
    const handleSave = () => {
        if (props.createMode === true) { createRule (currentRule); } 
        else if (props.createMode === false) { updateRule (currentRule); }
    };
    const handleCancel = () => { props.setOpen (prev => false); };

    return (
        <>       <BlockUi blocking = { ruleLoading } message = { 'Please wait while we fetch.' } >

                 <Card className = { classes.card } >
{          props.createMode ===false &&           <CardHeader style={{ margin : 0 , padding : 5}}> <h3> Update Award Rule &nbsp; <a style={{cursor: "pointer"}}><Edit style={{color: "#138de8"}} onClick={e=>setEdit(!edit)} /></a> </h3>   </CardHeader>
}                   
{ props.createMode === true && <CardHeader style={{ margin : 0 , padding : 5}}> <center> <h3> Create Award Rule </h3>   </center>   </CardHeader>
}
                    <CardBody>  
                        <GridContainer>
                        <GridItem xs = {6} md = {12} sm = {6} >  
                            
                            <DropDown 
                                    labelCategory = "Award Type" 
                                    name = "type"
                                  
                                    minWidth = {300}
                                    values = {['Birthday', 'Bike Approval',  'Sign Up', 'Event Purchase', 'Order Punch', 'Regular Purchase', 'CheckIn']}

                                    targetValue = { getValue() }
                                    onChange = { e=> setValue(e.target.value)  }
                                  //  onBlur = { captureChanges }
                                    helperText = { fetchErrorMessage ('type') }
                                    hasError = { checkError ('type') }
                                    disabled={props.createMode === false ? !edit : false}
                                    required = {true}
                                    />  
                          
                               
                            </GridItem>
                            <GridItem xs = {6} md = {12} sm = {6} >  
                             <br/>
                            </GridItem>

                            <GridItem xs = {6} md = {6} sm = {6} >  
                            <DropDown 
                                    labelCategory = "Voucher Code" 
                                    name = "VoucherCode"
                                    values = { voucher.map(i => i.code) }
                                    minWidth = {300}
                                  //  values = {['HWLOAD' , 'HLOPA']}

                                    targetValue = { currentRule && currentRule.voucherCode }
                                   // onChange = { e => { setCurrentRule(prev => {  prev.VoucherCode = e.target.value;  return { ...prev }; });  } }
                                    onChange = { e => { setCurrentRule(prev => {  prev.voucherCode = e.target.value ; prev.configId = voucher.filter (i => i.code === e.target.value)[0].id; prev.minDate = voucher.filter (i => i.code === e.target.value)[0].validityStart;  prev.maxDate = voucher.filter (i => i.code === e.target.value)[0].validityEnd; return { ...prev }; });  } }

                                    onBlur = { captureChanges }
                                    helperText = { fetchErrorMessage ('VoucherCode') }
                                    hasError = { checkError ('VoucherCode') }
                                    disabled={props.createMode === false ? !edit : false}
                                    required = {true}
                                    />  

                        
                                
                            </GridItem>
                            <GridItem xs = {6} md = {6} sm = {6} >  
                           
                            <HWInput label = { 'QTY. ' } type = 'number'  
                                                    name = { 'quantity' }
                                                    helperText = { currentRule  && currentRule.quantity <=0 ? "Invalid Value" : "" }
                                                    hasError = { currentRule  && currentRule.quantity <=0 } 
                                                    onBlur = { captureChanges }
                                                    value = { currentRule && currentRule.quantity }
                                                    disabled={props.createMode === false ? !edit : false}
                                                    required = {true}
                                                /> 
                            </GridItem>
                            
                            
                       <GridItem xs = {12} md = {12} sm = {12} > 
                       
                                <GridContainer >
                                    <GridItem xs = { 6 } md = {6} sm = {6} > 
                                        <HWInput  type = {'date'} name = { 'startDate' } 
                                            value = { currentRule && currentRule.startDate } onBlur = { captureChanges } 
                                            hasError = { checkError ('startDate') } 
                                            helperText = { fetchErrorMessage ('startDate') } 
                                            label = { 'Validity Start' } 
                                            labelProps = {{ shrink: true }} 
                                            inputProps={{min : new Date().toISOString().split('T')[0] > currentRule && currentRule.minDate ? new Date().toISOString().split('T')[0] : currentRule && currentRule.minDate , max : currentRule && currentRule.maxDate}} 
                                            disabled={props.createMode === false ? edit === false ?  true : (currentRule && (currentRule.startDate < new Date().toISOString().split('T')[0]) ? true : false)   : false }
                                            required = {true}
                                        /> 
                                    </GridItem> 
                                    <GridItem xs = { 6 } md = {6} sm = {6} > <HWInput 
                                        disabled={props.createMode === false ? edit === false ?  true : (currentRule && (currentRule.endDate < new Date().toISOString().split('T')[0]) ? true : false)   : false }
                                        type = { 'date' } 
                                        name = { 'endDate' }  
                                        value = { currentRule && currentRule.endDate } 
                                        onBlur = { captureChanges } 
                                        hasError = { checkError ('endDate') } 
                                        helperText = { fetchErrorMessage ('endDate') } 
                                        label = { 'Validity End' } 
                                        labelProps = {{ shrink: true }}
                                        inputProps={{min :  (new Date().toISOString().split('T')[0] > currentRule.startDate ? new Date().toISOString().split('T')[0] : currentRule.startDate) , max : currentRule && currentRule.maxDate}}
                                        required = {true}
                                    /> 
                                    </GridItem> 
                                    <GridItem xs = {6} md = {12} sm = {6} >  
                             <br/>
                            </GridItem>
                                    <GridItem xs = {6} md = {12} sm = {6} >  
                                       
                                        <DropDown 
                                    labelCategory = "Process Flag" 
                                    name = "status"
                                    minWidth = {300}
                                    values = {['Yes' , 'No']}
                                    targetValue = { getValue2() }
                                    onChange = { e=> setValue2(e.target.value)  }
                                    //onBlur = { captureChanges }
                                    helperText = { fetchErrorMessage ('status') }
                                    hasError = { checkError ('status') }
                                    disabled={props.createMode === false ? !edit : false}
                                    required = {true}
                                    />  
                            
                            </GridItem>
                           

                                    <GridItem xs = {12} md = {12} sm = {12} >  
                              <center>

                                             <TextField 
                                                 style = {{ marginTop : 20 }}
                                                 multiline = { true }
                                                 rows = { 10 }
                                                 rowsMax = { 20 }
                                                 value = { currentRule && currentRule.description }
                                                 fullWidth
                                                 variant = "outlined"
                                                 label = {`Description` }
                                                 placeholder = 'Please provide the description'
                                                 name = { 'description' }
                                                 InputLabelProps = {{ shrink : true }}
                                                 inputProps = {{ maxLength : 100 }}
                                                // onBlur = { captureChanges }
                                                 error = { checkError ('description') }
                                                 onChange =  { captureChanges  }
                                                helperText = { fetchErrorMessage ('description') }
                                                disabled={props.createMode === false ? !edit : false}
                                                required={true}
                                            />
                              </center>
                            </GridItem>
                                </GridContainer> 
                            </GridItem>
                            <GridItem xs = {12} sm = {12} md = {12} >
                                {  <center>
{                      vouchers.includes("Action") === true &&       props.createMode ===true &&       <Button disabled={checkDisabled()} onClick = { e => props.createMode === true ? setCreateOpen (true) : setSaveOpen (true) } raised variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} > { props.createMode === true ? 'Create Rule' : 'Update Rule' } </Button>
}        
{                      vouchers.includes("Action") === true &&       props.createMode ===false &&  edit ===true &&  <Button disabled={checkDisabled()} onClick = { e => props.createMode === true ? setCreateOpen (true) : setSaveOpen (true) } raised variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} > { props.createMode === true ? 'Create Rule' : 'Update Rule' } </Button>
} 
                            <Button onClick = { handleCancel } raised variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} > Cancel </Button>
                                    <ConfirmationDialog open = { createOpen } setOpen = { setCreateOpen } confirmingAction = { handleSave } dialogMessage = { 'Are you sure you want to create this rule' } dialogTitle = { 'Confirm Rule Creation' } confirmingAction = { handleSave } />
                                    <ConfirmationDialog open = { saveOpen } setOpen = { setSaveOpen } confirmingAction = { handleSave } dialogMessage = { 'Are you sure want to update the rule.' } dialogTitle = { 'Confirm Rule Updation' } confirmingAction = { handleSave } />
                                    <StatusDialog isLoading = {false} open= { open3 } setOpen={setOpen3}  sucessStatus={"success"} confirmingAction={e=>props.setOpen (false)} message={"Award Rule successfully created."} dialogTitle={"Status"}/> 
                                 <StatusDialog isLoading = {false} open= { open2 } setOpen={setOpen2}  sucessStatus={"success"} confirmingAction={e=>props.setOpen (false)} message={"Award Rule successfully updated."} dialogTitle={"Status"}/> 

                              
                                </center>}
                            </GridItem>
                        </GridContainer>
                    </CardBody> 
                </Card>
                </BlockUi>
        </>
    );

};