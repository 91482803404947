import { AppBar, makeStyles, Tab, Tabs } from '@material-ui/core';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import React, { useState } from 'react';
import { Fragment } from 'react';
import BikeAssignment from './BikeAssignment';
import MembershipApproval from './MembershipApproval';
import PointsApproval from './PointsApproval';
import VOCVerification from './VOCVerification';
import VoucherApproval from './VoucherApproval';



const useStyles = makeStyles(theme => ({
    root: {
      overflow: 'hidden',
      minHeight: 48,
      WebkitOverflowScrolling: 'touch', 
      display: 'flex',
      borderRadius: '6px',
      backgroundColor: "white",
      '& .MuiTabs-indicator' : { backgroundColor : '#FF9017'},
      "& .MuiButtonBase-root" : {textTransform: "none"},
      color: 'black',
      '&$selected' : {
        backgroundColor: 'blue',
        color: 'green'
      }
    }
}));

export default function Actions (props) {


    const [ selectedTab, setSelectedTab ] = useState (JSON.parse(HWLocalStorage.load ("previousActionsTab")) || 0);
    const changeTab = (event, index) => setSelectedTab (index);

    if (HWLocalStorage.load ("previousVouchersTab") !== null ) 
    HWLocalStorage.clear("previousVouchersTab");
if(HWLocalStorage.load ("previousVouchersRuleTab") !== null)
     HWLocalStorage.clear("previousVouchersRuleTab");
     if (HWLocalStorage.load ("previousCardTab") !== null ) 
     HWLocalStorage.clear("previousCardTab");
     if(HWLocalStorage.load("previousComSettingsTab")!== null){
        HWLocalStorage.clear("previousComSettingsTab")
    }
    if(HWLocalStorage.load("previousRolesSettingsTab")!== null){
        HWLocalStorage.clear("previousRolesSettingsTab")
    }

    if(HWLocalStorage.load("previousSettingsTab")!== null){
        HWLocalStorage.clear("previousSettingsTab")
    }
    if(HWLocalStorage.load("previousMemberTab")!== null){
        HWLocalStorage.clear("previousMemberTab")
    }
    if(HWLocalStorage.load("previousMerchantDetailsTab")!==null)
    {
        HWLocalStorage.clear("previousMerchantDetailsTab");
    }

    if(HWLocalStorage.load("previousMerchantTab")!==null)
    {
        HWLocalStorage.clear("previousMerchantTab");
    }
    if(HWLocalStorage.load("previousPointsRuleTab")!== null)
    {
        HWLocalStorage.clear("previousPointsRuleTab");
    }
    if(HWLocalStorage.load("previousPointsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsTab");
    }
      

    var actions=[]
 var obj = JSON.parse(HWLocalStorage.load("Permissions"))
 console.log(obj);
 actions= [...obj["Actions"]];
 

    const classes = useStyles ();

    return (
        <Fragment>
                <Tabs className = {classes.root} variant = "scrollable" value={selectedTab} onChange={changeTab} >
                    <Tab disabled={actions.includes("View")=== false ? true : false} label = "VOC Verification" > VOC Verification </Tab>

                    {/* <Tab label = "Bike Assignment" > Bike Assignment </Tab> */}
                    <Tab  label = "Points Approval" > Points Approval </Tab>
                    <Tab  label = "Voucher Approval" > Voucher Approval  </Tab>
                    {/* <Tab label = "Membership Approval" > Membership Approval </Tab> */}
                </Tabs>

            <div style={{ padding: "15px 0px 30px 0px" }}>
                {selectedTab == 0 && <VOCVerification />}
                {selectedTab == 1 && <PointsApproval />}
                {selectedTab == 2 && <VoucherApproval />}
            </div>

        </Fragment>
    );
}