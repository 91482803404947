import { AppBar, makeStyles, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { Fragment } from 'react';
import Cards from "./Cards.jsx";
import GenerateCard from "./GenerateCard.jsx"
import HWLocalStorage from 'HWLibrary/HWLocalStorage.jsx';
const useStyles = makeStyles (theme => ({
    root : {
        overflow: 'hidden',
        minHeight: 48,
        WebkitOverflowScrolling: 'touch', 
        display: 'flex',
        backgroundColor: "white",
        '& .MuiTabs-indicator' : { backgroundColor : '#FF9017' },
        "& .MuiButtonBase-root": {textTransform: "none"},
        color: "black",
        borderRadius: '6px'
    }
}));

export default function MembershipCard (props) {

    //  if (HWLocalStorage.load ("previousCardTab") !== null ) 
    //  HWLocalStorage.clear("previousCardTab");
    // if(HWLocalStorage.load ("previousVouchersRuleTab") !== null)
    //  HWLocalStorage.clear("previousVouchersRuleTab");
    if (HWLocalStorage.load ("previousVouchersTab") !== null ) 
    HWLocalStorage.clear("previousVouchersTab");
   if(HWLocalStorage.load ("previousVouchersRuleTab") !== null)
     HWLocalStorage.clear("previousVouchersRuleTab");
    
     if(HWLocalStorage.load("previousComSettingsTab")!== null){
        HWLocalStorage.clear("previousComSettingsTab")
    }
    if(HWLocalStorage.load("previousRolesSettingsTab")!== null){
        HWLocalStorage.clear("previousRolesSettingsTab")
    }

    if(HWLocalStorage.load("previousSettingsTab")!== null){
        HWLocalStorage.clear("previousSettingsTab")
    }
    if(HWLocalStorage.load("previousPointsRuleTab")!== null)
    {
        HWLocalStorage.clear("previousPointsRuleTab");
    }
    if(HWLocalStorage.load("previousPointsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsTab");
    }
    if(HWLocalStorage.load("previousActionsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsTab");
    }
    if(HWLocalStorage.load("previousPointsActionsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsActionsTab");
    }
     if (! HWLocalStorage.load ("previousCardTab") ) HWLocalStorage.save (0, "previousCardTab");


    const classes = useStyles();
  

    const [currentTab, setCurrentTab ]= useState (JSON.parse(HWLocalStorage.load ("previousCardTab")) || 0);
    const changeTab = (event, idx) => { setCurrentTab (idx); }

    return (
        <Fragment>
                <Tabs variant = 'scrollable' className = { classes.root } value = { currentTab } onChange = { changeTab } >
                    <Tab label = "Membership Card" />
                    <Tab label = "Generate Membership Card" />
                    
                </Tabs>
            {
                {
                    0 : <Cards />,
                    1 : <GenerateCard />,
                    
                } [ currentTab ]
            }
         </Fragment>
    );
    
};