import { AppBar, makeStyles, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { Fragment } from 'react';
import PointAction from './PointAction';
import VOCVerification from './VOCVerification';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
const useStyles = makeStyles(theme => ({
    root: {
      overflow: 'hidden',
      minHeight: 48,
      WebkitOverflowScrolling: 'touch', 
      display: 'flex',
      backgroundColor: "white",
      '& .MuiTabs-indicator' : { backgroundColor : '#FF9017' },
      "& .MuiButtonBase-root": {textTransform: "none"},
      color: 'black',
      borderRadius: '6px'
    }
}));

export default function PointsApproval (props) {

    const classes = useStyles ();

    const [ isLoading, setIsLoading ] = useState (false);
    const [ selectedTab, setSelectedTab ] = useState (JSON.parse(HWLocalStorage.load ("previousPointsActionsTab")) || 0);;  
    const [ rows,setRows ] = useState([]);



   
   const changeTab = (event, index) => setSelectedTab (index); 
   
  

    return (
        <Fragment> 
                <Tabs className = {classes.root} variant = "fullWidth" value={selectedTab} onChange={changeTab} >
                    <Tab label = "Accrue Points" >Accrue Points</Tab>
                    <Tab label = "Deduct Points" >Deduct Points</Tab>
                    <Tab label = "Points Returned">Points Returned</Tab>
                </Tabs>


            <div style={{ padding: "15px 0px 30px 0px" }}>
                {selectedTab == 0 && HWLocalStorage.save && <PointAction requestType = 'Accrue' />}
                {selectedTab == 1 && <PointAction requestType = 'Deduct' />}
                {selectedTab == 2 && <PointAction requestType = 'Returning' />}
            </div>

        </Fragment>
    );
}