import { makeStyles} from '@material-ui/core';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Fab } from '@material-ui/core';
import RuleForm from './VoucherCategoryRuleForm';
import voucherConfigApiService from './APIService';
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { Button,  IconButton , Popover } from '@material-ui/core';
import { DataGrid , GridToolbar} from '@material-ui/data-grid'
import { Edit, MoreVert, People } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import BlockUi from 'react-block-ui';
import HWMessage from 'HWLibrary/HWMessage';
import { useHistory } from 'react-router-dom';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 
import { GridAddIcon } from '@material-ui/data-grid';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import SearchBar from 'views/Merchant/SearchBar';
import { CustomToolbar } from 'views/Members/Member';



const useStyles = makeStyles(theme => ({
    ...styles,
    cardFooter : { justifyContent : 'center', justifyItems : 'center' },
    cardHeader : { background : '#138DE8'  },
    cardIcon : { background : '#138DE8' },
    bottomGrid : { marginTop : 5 },
    '& .MuiFormLabel-root' : { marginBottom : 0 },
    editIcon : { color : '#138DE8' }
}));

function RuleDialog (props) {
    
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ ruleDescription, setRuleDescription ] = useState ({});

    const handleClose = event => {props.setOpen (false); props.setDirectActionOpen(false)}
    return (
        <>
            <Dialog open = { props.open } onClose = { handleClose }  >
                <RuleForm refreshState = { props.refreshState } setRefreshState = { props.setRefreshState } rules = { props.rules } setRules = { props.setRules } createMode = { props.createMode } ruleId = { props.ruleId } open = { props.open } setOpen = { props.setOpen }  setDirectActionOpen ={ props.setDirectActionOpen } />
            </Dialog>
        </>
    );
}



function ActionPopover (props) {
    return ( 

                <div>
                <Popover
                    open={ props.open }
                    anchorEl={ props.anchorEl}
                    onClose={  (event) =>  props.handleClose (false)  }
                >
                    <div >
                        <div style = {{ margin : '20px' }} > 
                            <Button variant='contained' style = {{ textTransform:'none',  backgroundColor : '#138DE8', color : 'floralwhite', right : '5px' , width: 150}} onClick = {event => props.handleEdit() }  > Edit </Button> 
                        </div> 
                    </div>
                </Popover>
            </div>
    
    );
};



export default function VoucherCategory (props) {
    var vouchers=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    vouchers= [...obj["Vouchers"]];
    HWLocalStorage.save (0, "previousVouchersRuleTab")

   const classes = useStyles();
//   const [ rows, setRows ] = useState ([]);
   const [ page, setPage ] = useState (1);
   const [ pageSize, setPageSize ] = useState (100);
   const [ filterModel, setFilterModel ] = useState (null);
   const [ rowCount, setRowCount ] = useState (0);
   const [ openDirectAction, setOpenDirectAction ] = useState (false);
   const [ selectedRequest, setSelectedRequest ] = useState (null);
   const [ searchText, setSearchText ] = useState ('');
   const [creatorMode , setCreatorMode ] = useState(false);
   const [ anchorEl, setAnchorEl ] = useState (null);
   const [ actionOpen, setActionOpen ] = useState (false);
   const [loading, setLoading ] = useState(false);
   const [ directActionOpen , setDirectActionOpen ] = useState(false);
   const [ refreshState, setRefreshState ] = useState (false);
   const [ selectedRuleId, setSelectedRuleId ] = useState ();
   const [ ruleOpen, setRuleOpen ] = useState (false);
   const [ createMode, setCreateMode ] = useState (false);
   const [ productLoading , setProductLoading ] = useState(false);
   const [ productList , setProductList ] = useState([]);
   const [ rulesLoading, setRulesLoading ] = useState (false);

   const openRule = () => { setRuleOpen (true); };
   const addRule = (event) => { setCreateMode (prev => true); setRuleOpen (prev => true); };
   const editRule = (event) => { setCreateMode (prev => false); setRuleOpen (prev => true); };

   const handleClick = (event,id) => { openRule(); };


const checkPriorDelete = deleteObject => {
    // Check for any existing rules which may be applicable for the time being
    return true;
};

  
const [ deleteItemState , setDeleteItemState ] = useState('');


useEffect (() => {
    setProductLoading (true);
    var paginationBookmark = ((page) * pageSize);
    var fetchLimit = pageSize;

    voucherConfigApiService.fetchProductCategories ({ paginationBookmark, fetchLimit, filterModel, sortModel, searchText })
    .then (response => {  
        if (! response || ! response.data) { return HWMessage.show ("Product Categories cannot be fetched" ,"error"); }
    //    response.data.productCategoryList = response.data.productCategoryList.filter((i,idx) => i.applyType !== undefined);
        HWMessage.show("Request Successful" , "success");
        setRowCount(response.data.totalHits);
        setProductList (response.data.productCategoryList); 
    })
    .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
})
    .finally (() => setProductLoading (false));
}, [page , refreshState , pageSize , searchText , sortModel , filterModel])




   const deleteRule = useCallback (id => {
       if (id != null) {
           setRulesLoading (true);
           voucherConfigApiService.deleteSpecificVoucherCategory (id) 
           .then (response => {
               if (!response || !response.data) { return HWMessage.show ("Error while deleteing the rule" , "error");  }
               HWMessage.show("Request Successful" , "success");
               setRowCount (rowCount - 1);
               setProductList(prev => { prev = prev.filter (i => i.id !== id); return [ ...prev ] });
           })
           .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
           .finally (() => { setRulesLoading (false) ;
            var my_element = document.getElementById("top");
            if(my_element!== null || my_element!== undefined || my_element!== '')
            {
                // my_element.scrollIntoView({ }); 

            }   
        });
       }
   });

const handleDelete = (id) => {

        deleteRule (id)
}



   const [ sortModel, setSortModel ] = useState ({});

    const columns = [
        
       { field : 'categoryName',          headerName : 'Voucher Category',    filterable : true,  sortable : true, flex : 2.5  ,} ,
       { field : 'description',    filterable :  false ,      headerName : 'Description',          filterable : true,  sortable : true, flex : 3.5 },
       { field : 'applyType',      headerName : 'Type',     filterable : true,  sortable : true, flex :1.5 , valueFormatter: ({ value }) => value === "UniversalVoucher" ? "Universal" : value === "PrivateVoucher" ? "Private": value },
       { field : 'createTime', filterable: false ,  headerName : 'Raised Date', flex:1.5, renderCell : cellParams => {
        var date = cellParams.row.createTime;
        var dateString = date.split('T');
        var d = new Date (dateString).toLocaleDateString();

        return <span> {d} </span>
    } },
    ];

    if(vouchers.includes("Action") === true) {
        columns.push({ field: 'Action', headerName : '', flex : 0.8, filterable :  false ,  renderCell : (cellParams) => ( <>{ <IconButton style={{color : "#138de8"}} onClick = { (event)  => { setCreateMode (prev => false); setRuleOpen(true); setDeleteItemState(cellParams.row.id); setSelectedRuleId(prev => { return cellParams.row.id}); } } > <Edit /> </IconButton> } </> )}        )
    }
  

    function CustomLoadingOverlay() {
        return (
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
      }
 const useDataGridStyles = makeStyles(theme => ({
        root: {
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiButton-label' : { color : '#138DE8' }
        }
      }));
      const dataGridClasses = useDataGridStyles();
   
   const history = useHistory ();

    return (
        <Fragment>
            <BlockUi blocking = { productLoading } message = { 'Please wait while we fetch.' } >
                <Card id="top">
                    <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                        <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        <h4 className = { classes.cardIconTitle } > 
                            Voucher Category
                        </h4> 
                    </CardHeader>   
                    <CardBody>  
                    <GridContainer style = {{ justifyContent : 'space-between' }} >
                        <GridItem>  
                            <SearchBar 
                                width = '300px' 
                                handleSearch = { setSearchText }
                                handleRefresh = { setRefreshState }
                                refreshState = { refreshState }
                            /> 
                        </GridItem>
                        <GridItem>
                        { vouchers.includes("Action") === true &&  <Fab onClick = { addRule } variant = 'extended' aria-label="add" style = {{background : '#FF9017', color : 'white' }}  > <GridAddIcon  /> Add Category</Fab>}
                        </GridItem>

                    </GridContainer>
                    <GridContainer>
                      <GridItem className = { classes.bottomGrid } xs = {12} md = {12} >
                                    <DataGrid 
                                        className = { dataGridClasses.root }
                                        showToolbar
                                        page = { page }
                                        pageSize = { pageSize }
                                        rows = { productList }
                                        rowCount = { rowCount }
                                        columns = { columns }
                                       
                                        pagination
                                        paginationMode = 'server'
                                        onPageChange = { changeParams => setPage (changeParams) }
                                        onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams); } }

                                        sortingMode="server" 
                                        onSortModelChange = { params => { 
                                          if (params.length > 0) 
                                              setSortModel (params[0]);
                                        } }
                                      
                                        filterMode = 'server'
                                        onFilterModelChange = { (params) => {
                                          if (params.items.length > 0) {
                                            setFilterModel (prev => ({ ...params.items[0] })); 
                                          }
                                        } }
                                        density = 'compact'
                                        autoHeight
                                        rowsPerPageOptions ={ [25, 50, 100 ]}
                                        // loading = { requestsLoading }
                                        components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 

                                       disableExtendRowFullWidth = { false }
                                    />
                      </GridItem>
                    </GridContainer>               
                    </CardBody>
                </Card>
                <RuleDialog     
                ruleId = { selectedRuleId }
                open = { ruleOpen }     
                setOpen = { setRuleOpen } 
                createMode = { createMode } 
                rules = { productList }
                setRules = { setProductList }
                refreshState = { refreshState } 
                setRefreshState = { setRefreshState }
                setDirectActionOpen = { setDirectActionOpen }
            />

            < ActionPopover
            open = { directActionOpen } 
            handleClose = { setDirectActionOpen }
            anchorEl = { anchorEl }  
            setAnchorEl = { setAnchorEl }   
            handleEdit = {e => editRule(e) } 
            handleDelete = { e => handleDelete(deleteItemState) } 
            
            /> 
            </BlockUi>
            
        </Fragment>
    );
};