import { Box, Button, Card, CardActions, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import CardHeader from 'components/Card/CardHeader';
import GridItem from 'components/Grid/GridItem';
import React, { useState } from 'react';
import { Fragment } from 'react';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import GridContainer from 'components/Grid/GridContainer';
import CardBody from 'components/Card/CardBody';
import Alert from '@material-ui/lab/Alert';
import CardIcon from 'components/Card/CardIcon';

import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";


const useStylesHead = makeStyles (styles);


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));


function StatusDialog (props) {

    return (
        <Dialog open = {props.statusDialogOpen}  >
            <DialogTitle> Raising Request </DialogTitle>
            <DialogContent>
                Raising Request for Points
            </DialogContent>
            <DialogActions>  
                <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { () => { props.setStatusDialogOpen (false);  } } > Reward More </Button>
                <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} onClick = { () => { props.setStatusDialogOpen(false); props.setDialogOpen (false); } } > Home </Button>
            </DialogActions>
        </Dialog>
    );
}


export default function PointTransactions (props) {

    const classes = useStyles ();
    const headClasses = useStylesHead();
    
    const handleChange = (event) => setReason (event.target.value);

    const [ transactionType, setTransactionType ] = useState (props && props.transactionType || 'single');
    const [ transactionList, setTransactionList ] = useState ( props && props.transactionList ? props.transactionList : ['12002'] )
    const [ transactionName, setTransactionName ] = useState (props && props.transactionName);

    const [ reason, setReason ] = useState ('');
    const [ detailedReason, setDetailedReson ] = useState ('');
    const [ amount, setAmount ] = useState (-1);

    const [ raiseLoading, setRaiseLoading ] = useState (false);
    const [ statusDialogOpen, setStatusDialogOpen ] = useState (false);

    const reasons = [ 'None', 'Purchase of Goods', 'Purchase of Bike', 'Campaign Reward', 'Campaign Provision', 'Seasonal Reward', 'Others' ];


    return (
        <Fragment>
            <Card>
                <CardHeader color = "primary" icon > 
                    <GridContainer> 
                        <GridItem xs = {12} > 
                            <GridContainer>
                                <GridItem xs = {12} >
                                    <CardIcon color = "primary" style = {{ marginTop : '0px' }} >  <TransferWithinAStationIcon /> </CardIcon>  
                                    <h4 className = { headClasses.cardIconTitle } > { props.transactionName === 'accrue' ? 'Accrue' : 'Deduct' } Points </h4>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs = {12}>
                            <GridContainer>
                                <GridItem xs = {2} > <IconButton onClick = { () => {
                                    if (props.dialogVariant)
                                        props.setDialogOpen (false);
                                } } > <KeyboardBackspaceIcon /> </IconButton> </GridItem>
                                <GridItem xs = {8}  > <h4 className = { headClasses.cardIconTitle } > { transactionType === 'single' ? `Merchant / Member Account ID : ${ transactionList[0] }` : `See List of selected merchants : ${ transactionList.length }` } </h4> </GridItem>
                                { transactionType !== 'single' && <GridItem xs = {2} > <IconButton> <KeyboardArrowDownIcon /> </IconButton> </GridItem>}
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </CardHeader>
                <Divider />

                <CardBody>
                    <GridContainer >
                        <GridItem xs = {12} > 
                            <center>     
                                <TextField
                                    label = {`${ transactionName === 'accrue' ? 'Accrue' : 'Deduct' } Points`}
                                    placeholder = {`Enter amount of points to be ${transactionName}d`}
                                    value = {(amount >= 0) ? amount : ''}
                                    onChange = { (event) => setAmount (event.target.value) }
                                /> 
                            </center>   
                        </GridItem>
                        <GridItem xs = {12} > 
                            <center> 
                                <FormControl className = {classes.formControl} >
                                    <InputLabel  > Reason </InputLabel>
                                    <Select
                                        value = {reason}
                                        onChange = { handleChange }
                                    >
                                        { reasons.map (reason => (<MenuItem value = {reason} > { reason } </MenuItem>) )  }
                                    </Select>
                                </FormControl>
                            </center>   
                        </GridItem>
                        <GridItem xs = {12} style = {{ marginLeft : '100px', marginRight : '100px' }} >  
                            <TextField 
                                placeholder = {`Summary of ${ transactionName === 'accrue' ? 'Rewards' : 'Deductions'  } with detailed reasoning`}
                                multiline = { true }
                                rows = { 10 }
                                rowsMax = { 20 }
                                fullWidth
                                variant = "outlined"
                                label = {`Detailed Reasoning for ${ transactionName === 'accrue' ? 'Rewards' : 'Deductions'}`}
                                
                            />
                        </GridItem>

                        <GridItem xs = {12} style = {{ marginTop : '15px' }} >
                            <Collapse in = { amount > 0 }>
                                <Alert  style = {{ marginLeft : '100px', marginRight : '100px' }} fullWidth severity="info">
                                    Total Points to be { transactionName === 'accrue' ?  'Rewarded' : 'Deducted'}  : { amount }
                                </Alert>
                            </Collapse>
                        </GridItem>
                    </GridContainer>
                </CardBody>                
                <CardActions style = {{ justifyContent : 'center' }} >
                    <Button onClick = { () => { setStatusDialogOpen(true); } } size = "large" variant = "outlined" style = {{ backgroundColor : 'green', color :'floralwhite' , textTransform:'None'}} > { transactionName === 'accrue' ? 'Reward' : 'Deduct'  } </Button>
                    <Button size = "large" variant = "outlined" style = {{ backgroundColor : 'red',  color : 'floralwhite' , textTransform:'None' }} onClick = { () => {
                        if (props.dialogVariant) 
                            props.setDialogOpen (false);
                     } } > Cancel </Button>
                </CardActions>
            </Card>

            <StatusDialog 
                raiseLoading = {raiseLoading} 
                statusDialogOpen = { statusDialogOpen } 
                setStatusDialogOpen = {setStatusDialogOpen} 
                setDialogOpen = { props.setDialogOpen }
            />

        </Fragment>
    );
};