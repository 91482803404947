import { useCallback, useEffect } from 'react';
import { AppBar, Button, Card, CardActions, CircularProgress, IconButton, makeStyles, Tab, Tabs , Tooltip } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState } from 'react';
import { Fragment } from 'react';
import SearchBar from 'views/Merchant/SearchBar';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import { HWInput } from 'HWLibrary/HWInput';
import { Link, useHistory } from 'react-router-dom';
import {PeriodDialog} from 'views/Actions/VOCVerification';
import { DirectActionDialog } from 'views/Actions//VOCVerification';
import HWMessage from 'HWLibrary/HWMessage';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import BlockUi from 'react-block-ui';
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { DropDown } from 'views/Merchant/Merchant';
import RuleForm from './GenerateCardRuleForm';
import { Dialog } from '@material-ui/core';
import membershipCardApiService from './APIService';
import jsPDF from 'jspdf';
import { createTrue } from 'typescript';
const useStyles = makeStyles (theme => ({ 
    ...styles,  
    saveButton :  { color: 'floralwhite', backgroundColor : '#138DE8' , margin: 2.5 },

    cardFooter : { justifyContent : 'center' },
    cardHeader : { height : '50px' },   
    formControl: { margin: theme.spacing(1), minWidth: 120 }, 
    root : { 
      '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
      '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
      '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
      '& .MuiButton-label' : { color : '#138DE8' }
    }
}));


function RuleDialog (props) {
    
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ ruleDescription, setRuleDescription ] = useState ({});

    console.log (props);

    const handleClose = event => props.setOpen (false);
    return (
        <>
            <Dialog open = { props.open } onClose = { handleClose }  >
                <RuleForm refreshState = { props.refreshState } setRefreshState = { props.setRefreshState } rules = { props.rules } setRules = { props.setRules } createMode = { props.createMode } ruleId = { props.ruleId } open = { props.open } setOpen = { props.setOpen } usingCSV={props.usingCSV} />
            </Dialog>
        </>
    );
}

export default function GenerateCard (props) {

    var cards=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    cards= [...obj["Cards"]];

    HWLocalStorage.save (1, "previousCardTab");

    const [ dataList, setDataList ] = useState ([
        { index : 0,  title : 'CARDS CREATED', type : 'Created' },
        { index : 1, title : 'CARDS ACTIVATED', type : 'Activated' }, 
    ]);

    const [ activatedList , setActivatedList] = useState ([]);
    const [ count , setCount] = useState (0);
    const [totalCards, setTotalCards] = useState(0);
    const classes = useStyles ();
    const [ fetchRequestType, setFetchRequestType ] = useState (props && props.requestType);
    const [rows,setRows] = useState([]);
    const [ isLoading, setIsLoading ] = useState (false);
    const [ countMaps, setCountMaps ] = useState ({ 'Created' : 0, 'Activated' : 0 }); 
    const [ periodDialog, openPeriodDialog ] = useState (false);
    const [ currentDataCard, setCurrentDataCard ] = useState (dataList[0]);
    const [ ruleOpen, setRuleOpen ] = useState (false);
    const [ createMode, setCreateMode ] = useState (false);
    const [ page, setPage ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ filterModel, setFilterModel ] = useState ({});
    const [ sortModel, setSortModel ] = useState ({});
    const [ selectedRequest , setSelectedRequest ] = useState({});
    const [ searchText, setSearchText ] = useState ('');
    const [ refreshState, setRefreshState ] = useState (false);
    const [ rowCount, setRowCount ] = useState (0);
    const [ requestViewModels, setRequestViewModels ] = useState ({});
    const [ loading, setLoading] = useState(false);
    const [ countLoading, setCountLoading ] = useState (false);

    const [ startDate, setStartDate ] = useState ();
    const [ endDate, setEndDate ] = useState ();
    const [ usingCSV, setUsingCSV] = useState(false);
    const [firstSortId, setFirstSortId] = useState();
    const [lastSortId, setLastSortId] = useState();

  const [pageDelta, setPageDelta] = useState(0);
    var x=[];
    useEffect (() => {
        setIsLoading (true);
        var paginationBookmark = ((page) * pageSize);
        var fetchLimit = pageSize;
        
        membershipCardApiService.fetchCardGenRule ({fetchLimit, paginationBookmark, searchText , filterModel , sortModel})
        .then (response => {
            if (!response || !response.data)
                {return HWMessage.show("No relevant data can be fetched" , "error");}
            HWMessage.show("Request Successful" , "success");
           //setRows (response.data);
           response.data =  response.data.sort(function(a,b){
          
            return new Date(b.createDate) - new Date(a.createDate);
          });
        setRows (response.data);

          setRowCount(response.data.length);
          for(var o = 0 ; o <response.data.length; o++)
            {
                x.push(response.data[o].cardCount);
            }
            console.log(x);
            var y = (x.reduce((a, b) => a + b, 0));
            setTotalCards(y);
            setCountMaps(prev => ({...prev , Created : y}))
        })
        .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() => { setIsLoading (false); var my_element = document.getElementById("top");
        if(my_element!== null || my_element!== undefined || my_element!== '')
        {
            // my_element.scrollIntoView({ }); 

        }
     });
    }, [ page, pageSize ,  refreshState , searchText , filterModel , sortModel  ]);

    useEffect (() => {
        setIsLoading (true);
        var paginationBookmark = ((page) * pageSize);
        var fetchLimit = pageSize;
        membershipCardApiService.fetchCardsList (fetchLimit, paginationBookmark, firstSortId, lastSortId, pageDelta)
        .then (response => {  
            if (! response || ! response.data) { return HWMessage.show ("Cards Cannnot be fetched","error"); }
            setActivatedList (response.data.responseList); 
            setFirstSortId(response.data.firstSortId)
            setLastSortId(response.data.lastSortId)
            setCount(response.data.matchCount);
            setCountMaps(prev => ({...prev , Activated : response.data.matchCount}))
            HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        ; })
        .finally (() =>{ setIsLoading (false) ; 
        var my_element = document.getElementById("top");
        if(my_element!== null || my_element!== undefined || my_element!== '')
        {
            // my_element.scrollIntoView({ }); 

        }
    }) ;
    }, [page, pageSize ,refreshState  ]);

    const addRule = (event) => { setCreateMode (prev => true); setRuleOpen (prev => true); setUsingCSV(prev=>false) };

    const addRuleUsingCSV = (event) => { setCreateMode (prev => true); setRuleOpen (prev => true);setUsingCSV(prev=>true)  };

    const changeData = (idx) => {  
         var temp = currentDataCard;
        //  setCurrentDataCard(prev => dataList [idx]);
        // dataList [idx] = dataList [temp.index];
        // dataList [temp.index] = temp;
       //  setDataList (dataList);
         setCurrentDataCard(prev => dataList [idx]);
        //  setLastSortId();
        //  setFirstSortId();
        //  setPage(0);
        //  setPageDelta(0)
        //  setRefreshState (! refreshState);
    };
    

    const DownloadExcel = async (url,designImageUrl) => {
         window.open(url);

    }

    const useDataGridStyles = makeStyles(theme => ({
        root: {
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiButton-label' : { color : '#138DE8' }
        }
      }));
      const dataGridClasses = useDataGridStyles();

    const generatedColumns = [
        { field : 'physicalPrefix', headerName : 'Number Series', flex: 1.25}, 
        {field : 'cardCount', headerName : 'Card Count', flex: 1.5},
        {field : 'cardDesign', filterable :  false , headerName : 'Card Design', flex: 1.25},
        { field : 'createDate', filterable :  false , headerName : 'Create Date', width : 170, renderCell : cellParams => {
            var date =  new Date(cellParams.row.createDate);
         
            var d = date.toLocaleDateString();
    
            return <span> {d} </span>
        } },
      { field: '', headerName : 'Action', flex: 1, sortable:false,renderCell : (cellParams) => ( <> {  <IconButton onClick = { (event) => { DownloadExcel(cellParams.row.documentUrl , cellParams.row.designImageUrl) } } > <GetAppIcon style = {{ color : '#138DE8' }} /> </IconButton> } </>)}
    ];

    const activatedColumns = [
        { field : 'membershipNumber', headerName : 'Membership Number', flex: 1.25}, 
        {field : 'memberName', headerName : 'Member Name', flex: 1.5},
        {field : 'cardNumber', headerName : 'Card Number', flex: 1.25},
    ];
    
    function CustomLoadingOverlay() {
        return (
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
      }




  
    const history = useHistory ();

    return (
        <Fragment> 
         <BlockUi blocking = { isLoading } message = { 'Please wait while we fetch.' } >
         
            
            <GridContainer id = "top" style = {{ marginTop : '15px' }} >
                { dataList.map ((dataType, idx) =>   (
                    <GridItem xs = {3} key = { idx } >
                        <Tooltip title={ dataType.title}>
                            <Card >   
                                <CardBody> 
                                    <GridContainer> 
                                        <GridItem xs = {8} > <center> <span style = {{ fontWeight : '500' }} > { dataType.title } </span> </center>  </GridItem>
                                        <GridItem xs = {4} style={{display:'flex',flexDirection:"row-reverse",alignItems:"center"}}>
                                            {! isLoading &&  <Link onClick = { (event) => changeData (idx) } style = {{ fontSize : '1.5em', fontWeight : '500',color : '#138DE8' }}  >  { countMaps[dataType.type] }  </Link> }
                                            { isLoading && <CircularProgress /> }
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </Tooltip>
                    </GridItem>
                )) 
                }
                <GridItem xs = {6} style={{display:'flex',flexDirection:"row-reverse"}} >
               { cards.includes("Action") === true &&   <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} disabled={cards.includes("Action") === true ? false : true} onClick = { addRule} raised  > {'Create new block' } </Button>}
               { cards.includes("Action") === true &&   <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none',marginRight:'20px' }} disabled={cards.includes("Action") === true ? false : true} onClick = { addRuleUsingCSV} raised  > {'Create new block using CSV' } </Button>}
                </GridItem>
            </GridContainer>
            <br />

           
            <GridContainer justify = "space-between" alignItems = "center" style={{marginBottom:10}} > 
            <GridItem> <b> <span style = {{ fontWeight : '500px', fontSize : '2em' }} > Count : { ! isLoading && <a href = "#top" style = {{ color : '#138DE8', textDecoration : 'underline' }} >  {  currentDataCard.type === "Created" ? rowCount : count } </a> } { isLoading && <CircularProgress size = {25} /> } </span> </b> </GridItem>
            </GridContainer>
            
            
            <Card >
                <CardBody>
                <DataGrid
                    className = { dataGridClasses.root }
                    autoHeight
                    showToolbar 
                    density="compact" 
                    loading = { isLoading } 
                    columns={ currentDataCard.type === "Created" ?   generatedColumns  : activatedColumns  } 
                    rows={currentDataCard.type === "Created" ? rows : activatedList } 
                    rowCount = { currentDataCard.type === "Created" ? rowCount : count }                 
                    page = { page }
                    pageSize = { pageSize }
                    onPageChange =  {currentDataCard.type === "Created" ? (gridChangeParams) => {setPage (gridChangeParams); } : (gridChangeParams) => {setPage (gridChangeParams); setPageDelta (gridChangeParams-page)} } 
                    onPageSizeChange = { (gridChangeParams) => {setPage (0); setPageSize(gridChangeParams); } }
                    pagination = { true }
                    paginationMode = 'server'
                    sortingMode = 'server'
                    onSortModelChange = { params => {  
                        if (params.length > 0) 
                            setSortModel (params[0]);
                    } } 
                />
                </CardBody>
            </Card>    

            <RuleDialog     
                open = { ruleOpen }  
                usingCSV = {usingCSV}   
                setOpen = { setRuleOpen } 
                createMode = { createMode } 
                rules = { rows }
                refreshState = { refreshState } 
                setRefreshState = { setRefreshState }
            />
            
         </BlockUi>
        </Fragment> 
    );   
};