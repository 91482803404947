import { AppBar, makeStyles, Tab, Tabs } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import VoucherCategory from './VoucherCategory';
import AwardRule from './AwardRule';
import VoucherCreation from './VoucherCreation';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'hidden',
        minHeight: 48,
        WebkitOverflowScrolling: 'touch', 
        display: 'flex',
        backgroundColor: "white",
        '& .MuiTabs-indicator' : { backgroundColor : '#FF9017' },
        "& .MuiButtonBase-root": {textTransform: "none"},
        color: "black",
        borderRadius: '6px'
      }
}));

export default function VoucherRule (props) {

    const classes = useStyles ();
    if (! HWLocalStorage.load ("previousVouchersRuleTab") ) HWLocalStorage.save (0, "previousVouchersRuleTab");

    const [ selectedTab, setSelectedTab ] = useState (JSON.parse(HWLocalStorage.load ("previousVouchersRuleTab")) || 0);
    const changeTab = (event, idx) =>  { setSelectedTab (idx); }


    return (
        <Fragment>
                <Tabs variant  = 'scrollable' className = { classes.root } value = { selectedTab } onChange = { changeTab }  >
                    <Tab label = "Voucher Category" />
                    <Tab label = "Voucher Creation" />
                    <Tab label = "Award Rule" />
                </Tabs>
            {
                {
                    0 : <VoucherCategory />,
                    1 : <VoucherCreation />,
                    2 : <AwardRule />,
                } [selectedTab]
            }

        </Fragment>
    );
};

