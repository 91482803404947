import React, { useEffect, useReducer, useState } from 'react';
import { ButtonGroup, Card, makeStyles, Button, Avatar, Toolbar, Typography } from '@material-ui/core';
import { Delete, Edit, Save, ThumbDown, ThumbUp } from '@material-ui/icons';
import InputLabel from "@material-ui/core/InputLabel"
import { IconButton, Tooltip } from "@material-ui/core";
import { HWInput } from "HWLibrary/HWInput";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import memberApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import DescriptionPopover from 'views/DescriptionPopup/DescriptionPopup';
import { EnlargedImageDialog } from 'views/Actions/VOCVerification';
import BlockUi from 'react-block-ui';
import ConfirmationDialog from '../Utils/ConfirmationDialog';
import cloudfrontConfig from 'HWLibrary/HWCloudfrontConfig';
import LoginService from 'views/LoginService/LoginService';

const BikeDetailsCard = (props) => {
  var members=[]
  var obj = JSON.parse(HWLocalStorage.load("Permissions"))
  console.log(obj);
  members= [...obj["Members"]];
  console.log("Props in bike details:", props);

  const statusColor = (status) => {
    console.log("In here", status);
    switch (status) {
      case 'Assigned' :
      case 'Approved' : return '#33971F'; 
      case 'Rejected' : return '#E82D44';
      case 'Pending': 
      case 'Pre Approved' :
      case 'Manual Check' :
      case 'Pre Owned' : return '#F6B81E';
    }
  };

  const backgroundStatusColor = (status) => {
    switch (status) {
      case 'Assigned' :
      case 'Approved' : return '#E9F1E6'; 
      case 'Rejected' : return '#F1E7E6';
      case 'Pending': 
      case 'Pre Approved' : 
      case 'Manual Check' :
      case 'Pre Owned' : return '#FFFAEE';
    }
  };

  const useStyles = makeStyles((theme) => ({
        bike_details_card: {
            margin: '10px 10px 10px 10px',
            padding: '0px 10px 10px 10px',
            borderWidth : '2px', 
            borderStyle : 'groove', 
            borderColor : 'black', 
            backgroundColor : bgColor
        },
        image: {
            padding: '20px 20px',
            width: 100,
            height: 100,
            margin: '0px 0px 20px 0px',
            width: theme.spacing(15),
            height: theme.spacing(15)
        },
        bike_detail_toolbar: {
            margin: '10px 10px 10px 10px',
            backgroundColor: 'secondary',
            color: 'secondary'
        },
        save_bike_details_button: {
          width: '30%',
          height: '30%',
          margin: '10px 10px 10px 10px',
          padding: '10px 10px 10px 10px',
          backgroundColor: 'secondary',
          justifyContent: 'center',
          alignItems: 'center'
       }, 
       root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
      }
    }));

    const classes = useStyles();
    const [memberName, setMemberName] = useState(props.memberName);
    const [disabled, setDisabled] = useState(true);
    const [bikeDetails, setBikeDetails] = useState(props.bikeDetails);
    const {dispatchErrorHandler, hasError, errorMessage} = props;
    const [loading, setLoading] = useState(false);

    const [ description, setDescription ] = useState (null);
    const [ open, setOpen ] = useState (false);
    const [ anchorEl, setAnchorEl ] = useState (null);
    const [ openedImage, setOpenedImage ] = useState (null);
    const [ imageOpen, setImageOpen ] = useState (null);
    const [ imageName, setImageName ] = useState (null);
    const [ forcefulPrompt, setForcefulPrompt ] = useState (false);
    
    const [ showEdit, setShowEdit ] = useState (true);
     const [ reason, setReason ] = useState ("");

    const handleSubmit = () => {
      setBikeDetails (prev => { prev.reason = description; return { ...prev } });
      updateBikeDetails('reject', ); setOpen (false);
    };

    const handleRejectClick = event => {
      setOpen (true);
      setAnchorEl (event.currentTarget);
    };
 
    const forcefulUpdate = bikeDetail => {
      bikeDetails.updateType = 'Forceful';
      setBikeDetails (bikeDetails);
      updateBikeDetails ('approve');
    };


    const updateBikeDetails = (action) => {
      console.log("approvedBy",HWLocalStorage.load("ops-user").id);
      var prevStatus = bikeDetails.status;
      setShowEdit (true);

      console.log("Updating action", action);
      if (action && action === 'approve' && (checkValid (bikeDetails) === false)) { HWMessage.show("Cannot approve bike with these details" , "error"); return; }
      
      if (action !== undefined && action === 'approve' || action == 'reject') {
         if (action === 'reject') { clearValidations (); }
         bikeDetails.status = (action === 'approve') ? 'Verified' : 'Rejected';
         bikeDetails.rejectedReason =  description ;
         bikeDetails.approvedOrRejectedBy = HWLocalStorage.load("ops-user").id;
         setBikeDetails(bikeDetails);
       }
       console.log("Bike being updatyed", bikeDetails);

      if (bikeDetails) {
        setLoading (true);
        memberApiService.saveBikeDetails(bikeDetails)
        .then(response => {
          if (!response || !response.data) { 
            HWMessage.show("Error while updating bike.","error"); 
            return console.log("Error while updating bike"); 
          }
          
          setBikeDetails({...bikeDetails, ...response.data});
          props.setRefreshState (previous => !previous);
          
          HWMessage.show ("Bike Details updated successfully","success");
          return console.log("Bike details updated sucessfully");
          })
          .catch(error => {            
            setBikeDetails (prev => {  
              prev.status = prevStatus;
              return prev;
            });
            if (error.response.status === 406)  {
              setForcefulPrompt (true);
            }
            
            HWMessage.show(error.response.data.message || "Error while updating bike details" ,"error");
            return console.log("Error while fetching details", error);
          }).finally(() => {setLoading(false);});
        }  
        setDisabled (! disabled);
    }      
  
  const handleOpen = (open) => {
    setOpen (open);
    setDisabled (!disabled);
  };

  const checkValid = (bikeDetails) => {
    let fields = [ 'plateNumber', 'engineNumber', 'chassisNumber', 'icNumber', 'modelNumber', 'registrationDate']; 
    for (var i = 0; i < fields.length; i++) {    
        if (bikeDetails[fields[i]] === null || bikeDetails[fields[i]] === undefined || bikeDetails[fields[i]] === '' ) { return false; }
    }
    return true;
  };
 
  const handleNewBikeDetails = (event) => {
    let value = event.target.value;
    if (value === undefined) { return; }
    if (typeof value === 'string') { value = value.trim(); }

    switch (event.target.name) {
      case 'plateNumber': 
        if(value === '') { dispatchErrorHandler({ type: 'setError', field: 'plateNumber', error: "Please enter the plate number" }); } 
        else { 
          setBikeDetails ((previous) => ({ ...previous, [ event.target.name ] : event.target.value })); 
          dispatchErrorHandler({ type: 'resetError', field: 'plateNumber'});
      }
        break;
      case 'engineNumber': 
        if(value === '') { 
          setBikeDetails ((previous) => ({ ...previous, [ event.target.name ] : event.target.value })); 
          dispatchErrorHandler({ type: 'setError', field: 'engineNumber', error: "Please enter the engine number" }); 
        } 
        else { 
          setBikeDetails ((previous) => ({ ...previous, [ event.target.name ] : event.target.value })); 
          dispatchErrorHandler({ type: 'resetError', field: 'engineNumber'});
        }
        break;
      case 'chassisNumber': 
        if(value === '') { 
          setBikeDetails ((previous) => ({ ...previous, [ event.target.name ] : event.target.value })); 
          dispatchErrorHandler({ type: 'setError', field: 'chassisNumber', error: "Please enter the chassis number" }); 
        } 
        else { 
          setBikeDetails ((previous) => ({ ...previous, [ event.target.name ] : event.target.value })); 
          dispatchErrorHandler({ type: 'resetError', field: 'chassisNumber'});
        }
        break;
      case 'icNumber': 
        if(value === '') { 
          setBikeDetails ((previous) => { previous.icNumber = event.target.value; return { ...previous }; }); 
          dispatchErrorHandler({ type: 'setError', field: 'icNumber', error: "Please enter the ic number" }); 
        } 
        else { 
          setBikeDetails ((previous) => { previous.icNumber = event.target.value; return { ...previous }; }); 
          dispatchErrorHandler({ type: 'resetError', field: 'icNumber'});
        }
        break;
      case 'modelNumber': 
        if(value === '') { 
          setBikeDetails ((previous) => ({ ...previous, [ event.target.name ] : event.target.value })); 
          dispatchErrorHandler({ type: 'setError', field: 'modelNumber', error: "Please enter the model number" }); 
        } 
        else { 
          setBikeDetails ((previous) => ({ ...previous, [ event.target.name ] : event.target.value })); 
          dispatchErrorHandler({ type: 'resetError', field: 'modelNumber'});
        }
        break;
      case 'registrationDate': 
        if(value === '') { 
          setBikeDetails ((previous) => ({ ...previous, [ event.target.name ] : event.target.value })); 
          dispatchErrorHandler({ type: 'setError', field: 'registrationDate', error: "Please enter the registration date" });
        }
        else {
          setBikeDetails ((previous) => { previous.registrationDate = event.target.value; return { ...previous }; });
          dispatchErrorHandler({ type: 'resetError', field: 'registrationDate'});
        }
        break;
    }
  };

  const clearValidations = () => {
    let fields = [ 'plateNumber', 'engineNumber', 'chassisNumber', 'icNumber', 'modelNumber', 'registrationDate'];
    for (var i = 0; i < fields.length; i++) {
      dispatchErrorHandler({ type: 'resetError', field: fields[i]});
    }
  };
  
  let color = statusColor(bikeDetails.status);
  let bgColor = backgroundStatusColor (bikeDetails.status);

  return (
    <BlockUi blocking = { loading } message = { 'Updating. Please wait.' } >
      <Card className={classes.bike_details_card} style = {{ backgroundColor : bgColor }} >
        
          <GridContainer justify = "space-between"  >
              <GridItem>
                <h4> <b style = {{ color : color }} > { props.bikeDetails.status ? (props.bikeDetails.status ) : 'Unknown Status' } </b> </h4>
              </GridItem>
              <GridItem>
                { 
                  (bikeDetails.status === 'Pre Approved' || bikeDetails.status === 'Pre Owned' || bikeDetails.status === 'Manual Check') && 
                  <>
                    { members.includes("Action") === true && showEdit && <Tooltip title="Edit bike details"><IconButton aria-label="new media" color = 'primary'  onClick={() => {setDisabled(!disabled); setShowEdit (false); }} > <Edit width="20" height='20'  /> </IconButton></Tooltip>}
                    {/* {<Tooltip title="Delete bike details" ><IconButton aria-label="new media" color = 'primary' style = {{ padding : '0px' }} onClick={() => setDisabled(false)}> <Delete width="20" height='20' style = {{ color : '#e60000' }} /></IconButton></Tooltip>} */}
                  </>
                }
              </GridItem>
            </GridContainer>
          
          <GridContainer>
            <GridItem xs={12} md={6}>
              <HWInput 
                   required={true} name='plateNumber' label="Plate No." 
                  value={bikeDetails && bikeDetails.plateNumber || ''} disabled={true} 
                  onBlur={(event) => handleNewBikeDetails(event)} hasError={hasError('plateNumber')} helperText={errorMessage('plateNumber')} 
              />
              <HWInput required={true}  name='engineNumber' label="Engine Number" value={bikeDetails && bikeDetails.engineNumber} disabled={true} onBlur={(event) => handleNewBikeDetails(event) } hasError={hasError('engineNumber')} 
                  helperText={errorMessage('engineNumber')}  />
              { bikeDetails.status !== 'Rejected' && <HWInput required={true} name='icNumber' label="IC Number" value={bikeDetails && bikeDetails.icNumber} disabled={ disabled || (bikeDetails.bikeStatusAdd === 'PreApproved' && bikeDetails.vocVerificationMode === 'Manual' )  } onBlur={(event) => handleNewBikeDetails(event) } hasError={hasError('icNumber')} 
                  helperText={errorMessage('icNumber')}  /> }   
              <HWInput required={true} color='secondary' name='chassisNumber' label="Chassis Number" value={bikeDetails && bikeDetails.chassisNumber}  disabled={true} onBlur={(event) => handleNewBikeDetails(event)} hasError={hasError('chassisNumber')} 
                  helperText={errorMessage('chassisNumber')}  />
              <HWInput  required={true} name='registrationDate' labelProps = {{ shrink : true }} label="Registration Date" type='date' value={bikeDetails && bikeDetails.registrationDate} disabled={disabled} onBlur={(event) => handleNewBikeDetails(event)} hasError={hasError('registrationDate')} 
                  helperText={errorMessage('registrationDate')}  />
              <HWInput  required={true} name='modelNumber' value={ bikeDetails && bikeDetails.modelNumber } label="Model Number" labelProps = {{ shrink : true }} disabled={true} hasError={hasError('modelNumber')} onBlur={(event) => handleNewBikeDetails(event)}
                  helperText={errorMessage('modelNumber')}  />    
              <HWInput required={true} name='user' value={ bikeDetails && bikeDetails.user ? bikeDetails.user : memberName } label="User" disabled={true} hasError={hasError('user')} 
                  helperText={errorMessage('user')}  />
              {   bikeDetails.status !== 'Rejected' && <HWInput required={true} name='icNumber' value={ bikeDetails.icNumber } label="IC Number" disabled={ disabled || (bikeDetails.bikeStatusAdd === 'PreApproved' && bikeDetails.vocVerificationMode === 'Manual' )  } hasError={hasError('icNumber')} 
                  helperText={errorMessage('icNumber')}  />  }
               { bikeDetails.status === 'Rejected' && <HWInput  name='Rejected By' value={ bikeDetails.approvedOrRejectedBy!==undefined ? bikeDetails.approvedOrRejectedBy : "" } label="Rejected By" disabled={ true  } 
                   />  }
                {  bikeDetails.status === 'Approved' && <HWInput  name='Approved By' value={ bikeDetails.approvedOrRejectedBy!==undefined  ? bikeDetails.approvedOrRejectedBy : "" } label="Approved By" disabled={ true } 
                    />  }
                 {  bikeDetails.status === 'Rejected' && <HWInput  name='Rejected Reason' value={ bikeDetails.rejectedReason!==undefined  ? bikeDetails.rejectedReason : "" } label="Rejection Reason" disabled={ true  } 
                   />  }
                {  bikeDetails.status === 'Approved' && <HWInput  name='Approved at' value={bikeDetails.approvedDate && bikeDetails.approvedDate.seconds!==undefined  ? new Date(bikeDetails.approvedDate.seconds * 1000).toLocaleDateString() : "" } label="Approved at" disabled={ true } 
                    />  }
                {  bikeDetails.status === 'Rejected' && <HWInput  name='Rejected at' value={ bikeDetails.approvedDate && bikeDetails.approvedDate.seconds!==undefined  ? new Date(bikeDetails.approvedDate.seconds * 1000).toLocaleDateString() : ""} label="Rejected at" disabled={ true } 
                    />  }
            </GridItem>
            { 
              <GridItem xs={12} md={6}>
                <GridContainer className="memberDetails_card" >
                  <h4>VOC Copy</h4>
                  {/* <Tooltip title="Edit VOC">
                    <IconButton aria-label="new media" color = 'primary' onClick={() => setDisabled(false)}> <Edit width="20" height='20' /></IconButton>
                  </Tooltip> */}
                </GridContainer>
                
                { console.log("hererererrer", props.bikeDetails.vocCopyFileNames) }
                {
                  (!props.bikeDetails.vocCopyFileNames || (props.bikeDetails.vocCopyFileNames && props.bikeDetails.vocCopyFileNames.length === 0)) &&
                  <Typography variant="body2" component="h2" > No VOC copies uploaded </Typography>
                }  
                { 
                  props.bikeDetails.vocCopyFileNames && props.bikeDetails.vocCopyFileNames.length > 0 &&
                  <div className={classes.root} >
                    <GridList cellHeight = { 70 } cols={2} rows = {2} >
                      {
                        props.bikeDetails.vocCopyFileNames &&  props.bikeDetails.vocCopyFileNames.map (voc => {   
                          console.log("Printing voc", voc);     
                          return  <GridListTile>  
                            <img onClick = { event => { setOpenedImage (`${cloudfrontConfig.cloudfrontBaseUrl}/VOC-images/${voc}`); setImageName (voc); setImageOpen (true); } } src= { `${cloudfrontConfig.cloudfrontBaseUrl}/VOC-images/${voc}` } /> 
                          </GridListTile>; })
                      }
                    </GridList>
                    <EnlargedImageDialog open = {imageOpen} setOpen = { setImageOpen } imageName = { imageName } openedImage = { openedImage } />
                  </div>
                }
                { ! disabled &&
                <GridItem style = {{ marginTop : 10 }} >
                  <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite' , textTransform:'None' }} startIcon={<ThumbUp></ThumbUp>} onClick={() => { updateBikeDetails('approve'); setShowEdit (true); } }> Approve </Button>
                  <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform:'None',marginLeft:10}} startIcon={<ThumbDown></ThumbDown>} onClick={ event => { handleRejectClick (event); setShowEdit (true);  } }> Reject </Button>
                  <DescriptionPopover 
                    label = { 'Reason for Rejection' }
                    description = { description }
                    handleSubmit = { handleSubmit }
                    setDescription = { setDescription }
                    open = { open }
                    anchorEl = { anchorEl }
                    setAnchorEl = { setAnchorEl }
                    placeholder = { 'Enter Reason' }
                    setOpen = { handleOpen }
                    reason = {reason}
                    setReason={setReason}
                  />
                </GridItem>
                }
              </GridItem>
            }
            {/* <Button className={classes.save_bike_details_button} color='secondary' variant='contained' startIcon={<Save></Save>}>Save Bike Details</Button> */}
          </GridContainer>
          </Card>

          <ConfirmationDialog 
            confirmingAction = { forcefulUpdate }
            open = { forcefulPrompt } 
            setOpen = { setForcefulPrompt }
            dialogMessage = { 'This bike is already verified for other members do you still want to approve for this member ?' }
            dialogTitle = { 'Conflict while updating this bike for this member.' }
          />  
           
        </BlockUi>
    );
}

export default BikeDetailsCard;