import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
export default function ConfirmationDialog (props) {

    const handleClose = event => { props.setOpen (false)  };
    const handleCancel = event => { props.setOpen (false) };
    const handleAgree = event => { props.confirmingAction && props.confirmingAction(); props.setOpen (false)  };

    return (
        <Dialog open = { props.open } onClose = { handleClose }  >
            <DialogTitle> { props.dialogTitle } </DialogTitle>
            <DialogContent> 
                <DialogContentText> { props.dialogMessage } </DialogContentText>
                <DialogActions>  
                    <Button   style = {{ backgroundColor : '#138DE8', color : 'floralwhite' }}  onClick = { handleCancel }  > Cancel </Button>
                    {props.confirmingAction && <Button variant="outlined"  style = {{  color : 'Grey' }} onClick = { handleAgree } > Proceed </Button>}
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};