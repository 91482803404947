import { Box, Button, Card, CardActions, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import CardHeader from 'components/Card/CardHeader';
import GridItem from 'components/Grid/GridItem';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import GridContainer from 'components/Grid/GridContainer';
import CardBody from 'components/Card/CardBody';
import Alert from '@material-ui/lab/Alert';
import CardIcon from 'components/Card/CardIcon';

import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router';
import voucherRequestApiService from './ApiService';
import HWMessage from 'HWLibrary/HWMessage';
import { FadeLoader } from 'react-spinners';
import { AlertTitle } from '@material-ui/lab';
import { useCallback } from 'react';
import DescriptionPopover from 'views/DescriptionPopup/DescriptionPopup';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import memberApiService from 'views/Members/APIService';
import { DataGrid } from '@material-ui/data-grid';
import userSegmentApiService from 'views/UserSegment/APIService';

const useStylesHead = makeStyles (styles);


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));

const helperTextStyles = makeStyles(theme => ({
    root: {
      margin: 4,
      '&$error': {
        color: 'white'
      }
    },
    error: {} //<--this is required to make it work
  }));

  export function SearchUserDialog (props) {

    const classes = useStyles ();
    const [ selectedList, setSelectedList ] = useState ([]);
    const [ listLoading, setListLoading ] = useState (false);
    const [ rows, setRows ] = useState ([]);
    const [ page, setPage ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ rowCount, setRowCount ] = useState (0);
    const [ searchText, setSearchText ] = useState ('');
    const [ filterModel, setFilterModel ] = useState ('');
    const [ refreshState, setRefreshState ] = useState (false);
    const [ selectionModel, setSelectionModel ] = useState ([]);
    const [ uploadInProgress , setUploadInProgress ] = useState(false);
    const [ firstSortId, setFirstSortId] = useState();
    const [ lastSortId, setLastSortId ] = useState();
    const [ pageDelta, setPageDelta ] = useState(0);
    useEffect (() => {
        if (props && props.userType !== null) {
            var { userType } = props;
            setListLoading (true);
            var paginationBookmark = (page) * pageSize;
            var fetchLimit = pageSize;
            var voucherRequestId = props.voucherDetail?.id;
            var audienceType = props.voucherDetail?.requestType;
            var segmentId = props.voucherDetail?.userSegmentId;
            var userGroupIds  = null;

            // if (userType === 'Member') {
            //     if(props.voucherDetail.voucherDetail.requestType === "All")
            //     {
            //         console.log("propsvouchers",props);
            //         memberApiService.fetchActiveMembers ({ paginationBookmark, fetchLimit, searchText, filterModel })
            //         .then (response => {  
            //             if (! response || ! response.data) { HWMessage.show ("List cannot be fetched at moment" , "error"); }
            //             setRowCount (response.data.membersCount);
            //             setRows (response.data.memberList);
            //         }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            //     })
            //         .finally (() => { setListLoading (false);})
            //     } 

            //     else if(props.voucherDetail.voucherDetail.requestType === "Group"){
            //         var list =  props.voucherDetail.voucherDetail.memberGroup ;
            //         memberApiService.fetchActiveMembers ({ paginationBookmark, fetchLimit, searchText, filterModel , list  })
            //         .then (response => {  
            //             if (! response || ! response.data) { HWMessage.show ("List cannot be fetched at moment" , "error"); }
            //             setRowCount (response.data.membersCount);
            //             setRows (response.data.memberList);
            //         }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            //     })
            //         .finally (() => { setListLoading (false);})
                        
            //     }
            //     else if(props.voucherDetail.voucherDetail.requestType === "Segment"){
            //             userSegmentApiService.fetchSegmentMembers( props.voucherDetail.voucherDetail.userSegmentId  , paginationBookmark, fetchLimit )
            //             .then (response => {  
            //                 if (! response || ! response.data) { HWMessage.show ("List cannot be fetched at moment" , "error"); }
            //                 setRowCount (response.data.membersCount);
            //                 setRows (response.data.members);
            //             }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            //         })
            //             .finally (() => { setListLoading (false);})
            //     }
            //     else{

            //     }

            //    }

            if(props.voucherDetail && props.voucherDetail.id){
                userSegmentApiService.fetchAwardedVouchers ({ voucherRequestId, paginationBookmark, fetchLimit,audienceType, segmentId, userGroupIds})
                        .then (response => {  
                            if (! response || ! response.data) { HWMessage.show ("List cannot be fetched at moment" , "error"); }
                            setRowCount (response.data.totalMembers);
                            setFirstSortId (response.data.firstSortId);
                            setLastSortId (response.data.lastSortId);
                            setRows (response.data.members);
                        }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
                    })
                        .finally (() => { setListLoading (false);})
            }
                
               
        }
    }, [ props.userType, refreshState, pageSize, searchText, page, pageSize ]);

    const columns = [
        { field : 'name', headerName : 'Name', filterable : false, sortable : false, width : 200 },
        { field : 'membershipNumber', headerName : 'Membership Number', filterable : false, sortable : false, width : 200 },
    ];

    const handleClose = event => { props.setOpen(prev => !prev); };


    const history = useHistory();
   


    return (
    <Dialog open = { props.open } onClose = { handleClose }  >
        <DialogTitle  >  Members  </DialogTitle>
        <DialogContent>  
            <GridContainer>
                <GridItem xs = {12} md = {12} sm = {12} >  
                <DataGrid className={classes.root}
                    loading = { listLoading }
                    page = { page }
                    pageSize = { pageSize }
                    rows = { rows }
                    rowCount = { rowCount }
                    columns = { columns }
                    pagination
                    paginationMode = 'server'
                    onPageChange = { changeParams => {setPage (changeParams); setPageDelta (changeParams-page) } }
                    onPageSizeChange = { changeParams => { setPage (0);setPageSize (changeParams);}}
                    filterMode = 'server'
                    density = 'compact'
                    autoHeight
                    rowsPerPageOptions ={ [25, 50, 100 ]}
                />
                </GridItem>
            </GridContainer>
        </DialogContent>
        <DialogActions> 
        </DialogActions>
    </Dialog>
    );
};



function StatusDialog (props) {
    return (
        <Dialog open = {props.statusDialogOpen}  >
            <DialogTitle> Raising Request </DialogTitle>
            <DialogContent>
                Raising Request for Points
            </DialogContent>
            <DialogActions>  
                <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { () => { props.setStatusDialogOpen (false);  } } > Reward More </Button>
                <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} onClick = { () => { props.setStatusDialogOpen(false); props.setDialogOpen (false); } } > Home </Button>
            </DialogActions>
        </Dialog>
    );
}


export default function RewardVouchers (props) {

    var action=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    action= [...obj["Actions"]];

    const classes = useStyles ();
    const headClasses = useStylesHead();

    const [ voucherRequestType, setVoucherRequestType ] = useState (props && props.voucherRequestType);
    const [ memberList, setMemberList ] = useState ([]);
    const [ detailedReason, setDetailedReson ] = useState ('');
    const [ amount, setAmount ] = useState (0);

    const [ raiseLoading, setRaiseLoading ] = useState (false);
    const [ fetchLoading, setFetchLoading ] = useState (false);
    const [ statusDialogOpen, setStatusDialogOpen ] = useState (false);
    const [ canEdit, setCanEdit ] = useState (false);
    const [ userCount, setUserCount ] = useState (0);

    const [voucherDetail, setVoucherDetail] = useState (props && props.location && props.location.params?.state && props.location.params?.state?.voucherDetail || JSON.parse(HWLocalStorage.load("voucherStore")));
    const [ requestDetail, setRequestDetail ] = useState ({});
    const [membershipNumber, setMembershipNumber ] = useState (null);

    const [ selectedVoucher, setSelectedVoucher ] = useState ({});
    const [ savedLoading, setSavedLoading ] = useState (false);
    const [ voucher, setVoucher ] = useState(props && props.location && props.location.params?.state && props.location.params?.state?.voucherDetail && props.location.params?.state?.voucherDetail?.voucherPerUser || JSON.parse(HWLocalStorage.load("voucherStore")).voucherPerUser); 
    const [ rejectionDescription, setRejectionDescription ] = useState ();
    const [ descriptionAnchorEl, setDescriptionAnchorEl ] = useState (null);
    const [ descriptionOpen, setDescriptionOpen ] = useState (false);
    const [check , setCheck] = useState(false);
    const handleRejectionSubmit = event => rejectAction ();
    const [reason , setReason ] = useState(null);
    const [ listOpen, setListOpen ] = useState (false);

    const history = useHistory();
    const handleBack = () => {  
        var objectToBeCached = null;
        if (requestDetail.approvalStatus === 'Pending') { objectToBeCached = {index : 0, title : 'REQUESTS PENDING', type : 'Pending' } }
        else if (requestDetail.approvalStatus === 'Approved') { objectToBeCached = { index : 1, title : 'REQUESTS APPROVED', type : 'Approved' } }
        else if (requestDetail.approvalStatus === 'Rejected') { objectToBeCached = { index : 2, title : 'REQUESTS REJECTED', type : 'Rejected' } }

        HWLocalStorage.save (objectToBeCached, "voucherActionDataCategory");
        history.goBack(); 
    }


    const updateRequest = useCallback ((requestDetail) => {
        
        setSavedLoading (true);
        voucherRequestApiService.updateRequest(requestDetail.id, requestDetail)
        .then (response => { 
            if (!response || !response.data) {
                HWMessage.show("Error while saving the request ", "error");
                return  console.log("Error while fetching the response for saving.");
            }
            setRequestDetail (response.data);
            HWMessage.show("Request Successful" , "success");
         })
        .catch (error => { 
            HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");

            requestDetail.approvalStatus = 'Pending';
            return console.log("Error while fetching response");
         })
        .finally (() => { setSavedLoading (false); })
    }, [ requestDetail ]);

    const approvalAction = () => {
        console.log(requestDetail); 
        requestDetail.voucherPerUser = +voucher;
        if (requestDetail.approvalStatus !== 'Pending') {  
            HWMessage.show ("Request is already resolved","info");
        } else {
            requestDetail.approvalStatus = 'Approved';
            updateRequest (requestDetail);
            history.push ({ pathname : '/ops/actions' })
        }
    };
    const rejectAction = () => {
        requestDetail.voucherPerUser = +voucher;
        console.log(requestDetail); 
        if (requestDetail.approvalStatus !== 'Pending') {  
            HWMessage.show ("Request is already resolved","info");
            return;
        }
        requestDetail.reason = reason;
        requestDetail.approvalStatus = 'Rejected';
        updateRequest (requestDetail);
        history.push ({ pathname : '/ops/actions' })
    };  
    

    if (props && props.location && props.location.params && props.location.params.state && props.location.params.state.voucherDetail) {
        var value = props && props.location && props.location.params && props.location.params.state && props.location.params.state.voucherDetail;
        HWLocalStorage.save(JSON.stringify(value), "voucherStore");
      } 


    useEffect (() => {
        if (voucherDetail && voucherDetail.id) {
            setFetchLoading (true);
            voucherRequestApiService.fetchRequestDetails ({ id : voucherDetail.id })
            .then (response => { 
                if (!response || !response.data) { 
                    HWMessage.show("Response cannot be fetched at moment","error"); 
                    return console.log("Response cannot be fetched at moment"); 
                }   
                setRequestDetail (response.data);
                setMembershipNumber(response.data.memberName);
                setUserCount(response.data.userCount);
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { return HWMessage.show (error.response.data.message || "Request could not be resolved", "error");        })
            .finally (() => { setFetchLoading (false); });
        } 
    }, [ ]);

    const handleRejectClick = event => {  setDescriptionOpen (true); setDescriptionAnchorEl (event.currentTarget); };
    const totalVouchers =requestDetail.userCount * voucher;
    console.log("total vouchers",totalVouchers);
    return (
        <Fragment>
            { fetchLoading && <center style = {{ marginTop : '100px' }} > <FadeLoader margin = {20} radius = {20} width = {20} height = {20}  /> </center> }
            { !fetchLoading && <>
                <Card className='card-header-body'>
                <CardHeader icon>
                    <CardIcon color="primary" style = {{ marginTop : 0,  background : '#138DE8' }} ><AddCircleIcon /></CardIcon>
                </CardHeader>
                <CardBody className='card-header-body'></CardBody>
                </Card>
                <Card>
                <CardHeader color = "primary" icon > 
                    <GridContainer> 
                        <GridItem xs = {12} > 
                            <GridContainer>
                                <GridItem xs = {12} >
                                    <h4 className = { headClasses.cardIconTitle } > Reward Vouchers </h4>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs = {12}>
                            <GridContainer>
                                <GridItem xs = {2} > <IconButton onClick = { () => {
                                    if (props.dialogVariant)
                                        props.setDialogOpen (false);
                                    else 
                                        handleBack ()   
                                } } > <KeyboardBackspaceIcon /> </IconButton>
                                 </GridItem>
                                <GridItem xs = {8}  > <h4 className = { headClasses.cardIconTitle } >
                                     {  requestDetail.requestType === 'Single' ? `Member Name : ${ membershipNumber }` : `See List of selected members : ${ userCount }` } 
                                     { requestDetail.requestType !== 'Single' && <IconButton  onClick = { (e) => setCheck (!check) } >  <KeyboardArrowDownIcon /> </IconButton> }

                                     </h4> 

                                     </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </CardHeader>
                <Divider />

                { 
                requestDetail &&
                <CardBody>
                    <GridContainer justify = "space-between" >
                        <GridItem  > <h4> <b> Request Raised By : </b> { requestDetail.raisedEmpName }  </h4>    </GridItem>
                        <GridItem > <h4> <b> Time : </b>   { new Date(requestDetail.raisedTime).toLocaleString() } </h4>  </GridItem>
                    </GridContainer>

                    <Divider />

                    <GridContainer style = {{ marginTop : '15px' }} >
                        <GridItem xs = {12} > 
                            <GridContainer justify = "center" > 
                                    <GridItem style = {{ marginTop : '10px' }} > <InputLabel> Vouchers Per User </InputLabel> </GridItem>
                                    <GridItem> 
                                        <TextField
                                            style = {{ width : '500px' }}
                                            disabled = { !canEdit }
                                            value = {voucher}
                                            error={voucher < 0 ? true : false }
                                            helperText={voucher < 0 ? "Voucher count cannot be negative" : ""}
                                            type='number'
                                            onChange = { (event) => {setVoucher (event.target.value )  ; console.log(event.target.value)  } }
                                        /> 
                                     </GridItem>
                                    { requestDetail.approvalStatus === 'Pending' && action.includes("Action") === true && <GridItem > <IconButton style={{color:"#138DE8"}} onClick = { () => setCanEdit (!canEdit) } > <EditIcon /> </IconButton> </GridItem>}
                            </GridContainer>
                        </GridItem>
                        
                        <GridItem xs = {12} style = {{ marginTop : '15px' }} > 
                            <GridContainer justify = "center" > 
                                    <GridItem style = {{ marginTop : '15px' }} > <InputLabel> Voucher Code </InputLabel> </GridItem>
                                    <GridItem> 
                                        <TextField  
                                            style = {{ width : '500px' }}
                                            value = { requestDetail.code }
                                            disabled
                                        /> 
                                    </GridItem>
                            </GridContainer>
                        </GridItem>
                        {/* <GridItem xs = {12} style = {{ marginTop : '15px' }} > 
                            <GridContainer justify = "center" > 
                                 <GridItem style = {{ marginTop : '10px' }} > <InputLabel> Detailed Reasoning for rewards </InputLabel> </GridItem>
                                 <GridItem> 
                                     <TextField  
                                            style = {{ width : '500px' }}
                                            //label = {`Detailed Reasoning for rewards`}  
                                            value = ""
                                    /> 
                                </GridItem>
                            </GridContainer>
                        </GridItem> */}

                        <GridItem xs = {12} style = {{ marginLeft : '100px', marginRight : '100px', marginTop : '15px' }} >  
                            <TextField 
                                multiline = { true }
                                rows = { 10 }
                                rowsMax = { 20 }
                                value = { (requestDetail && requestDetail.reason) }
                                fullWidth
                                variant = "outlined"
                                label = 'Detailed Reasoning for rewards'
                                defaultValue = "Summary of rewards"
                                InputLabelProps = {{ shrink : true }}
                            />
                        </GridItem>

                        { !isNaN(voucher) && !isNaN(voucher * requestDetail.userCount) && voucher >= 0 &&  <GridItem xs = {12} style = {{ marginTop : '15px' }} >
                            <Collapse in = { voucher > 0 || isNaN(voucher)} >
                                <Alert  style = {{ marginLeft : '100px', marginRight : '100px' }} icon={false} fullWidth severity="info">
                                    
                                    <GridContainer style = {{ marginLeft : '125px' }} >  
                                        
                                          <GridItem xs = {12}  >  
                                                    <GridContainer justify = "space-between"  > 
                                                        <GridItem> <b> Total Voucher Count </b>  </GridItem>
                                                        <GridItem> {  (requestDetail.requestType === 'Single' ? voucher : voucher * requestDetail.initialUserCount) } </GridItem>
                                                    </GridContainer>
                                            </GridItem>
                                    </GridContainer>
                                   
                                </Alert>
                            </Collapse>
                        </GridItem>
}
                        
                        
                    </GridContainer>
                </CardBody> 
                }

                { 
                requestDetail && requestDetail.approvalStatus === 'Pending' &&
                <CardActions style = {{ justifyContent : 'center' }} >
                    {/* <Button onClick = { () => { setStatusDialogOpen(true); } } size = "large" variant = "outlined" style = {{ backgroundColor : 'green', color :'floralwhite' }} >  </Button>
                    <Button size = "large" variant = "outlined" style = {{ backgroundColor : 'red',  color : 'floralwhite' }} onClick = { () => {
                        if (props.dialogVariant) 
                            props.setDialogOpen (false);
                    } } > Cancel </Button> */}

                   {action.includes("Action") === true && <Button  disabled={voucher<0} variant = "contained" onClick = { () => { 
                        console.log("Voucher Detail", voucherDetail, selectedVoucher);                    
                        approvalAction(); } } 
                        style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform: 'None'}}  
                    > 
                        Approve 
                    </Button>}

                   {action.includes("Action") === true && <Button disabled={voucher<0} variant = "contained" onClick = { handleRejectClick }
                        style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform: 'None'}} 
                    > 
                        Reject 
                    </Button>}
                </CardActions>
                }

                {
                requestDetail && requestDetail.approvalStatus === 'Approved' &&
                    <Alert severity = { requestDetail.approvalStatus === 'Approved' ? 'success' : 'error' } >  
                        <AlertTitle> <h5> <b>  Request { requestDetail.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } </b> </h5>  </AlertTitle> 
                        <h4> { requestDetail.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } By : { requestDetail.resolverEmpName || requestDetail.resolvedBy  } </h4>
                        <h4> { requestDetail.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } At : { new Date(requestDetail.resolvedTime).toLocaleDateString() } </h4>
                        <h4> { requestDetail.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } Reason : { requestDetail.reason} </h4>
                        <h4> Actual Vouchers Awarded : {totalVouchers}</h4>                     
                    </Alert>

                }  

       {  requestDetail && requestDetail.approvalStatus === 'Rejected' &&
                    <Alert severity = { requestDetail.approvalStatus === 'Approved' ? 'success' : 'error' } >  
                        <AlertTitle> <h5> <b>  Request { requestDetail.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } </b> </h5>  </AlertTitle> 
                        <h4> { requestDetail.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } By : { requestDetail.resolverEmpName || requestDetail.resolvedBy  } </h4>
                        <h4> { requestDetail.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } At : { new Date(requestDetail.resolvedTime).toLocaleDateString() } </h4>
                        <h4> { requestDetail.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } Reason : { requestDetail.reason} </h4>
                    </Alert>

                }  
                 
            </Card>

            <StatusDialog 
                raiseLoading = {raiseLoading} 
                statusDialogOpen = { statusDialogOpen } 
                setStatusDialogOpen = {setStatusDialogOpen} 
                setDialogOpen = { props.setDialogOpen }
            />

            <DescriptionPopover
                label = { 'Reason for Rejection' }
                description = { rejectionDescription }
                handleSubmit = { handleRejectionSubmit }
                setDescription = { setRejectionDescription }
                open = { descriptionOpen }
                anchorEl = { descriptionAnchorEl }
                setAnchorEl = { setDescriptionAnchorEl }
                placeholder = { 'Enter Reason' }
                setOpen = { setDescriptionOpen }
                reason= {reason}
                setReason={setReason}
            />   

            <SearchUserDialog 
                            open = { check } 
                            userType = {"Member"}
                            setOpen = { setCheck }
                            voucherDetail = {props.location.params?.state?.voucherDetail || JSON.parse(HWLocalStorage.load("voucherStore")) }
                    />
            </>
            }           
        </Fragment>
    );
};





