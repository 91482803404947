import React, { useState, Fragment } from 'react';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { People, SubdirectoryArrowRightSharp } from '@material-ui/icons';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import CardFooter from 'components/Card/CardFooter';
import { Button, Checkbox, makeStyles, TextField, Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from 'HWLibrary/HWInput';
import WeekDaySelector from './WeekDaySelector';
import HWMessage from 'HWLibrary/HWMessage';
import { useHistory  } from "react-router-dom";
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import moment from 'moment';

const useStyles = makeStyles (theme => ({
    ...styles,
    card : { borderRadius : 10 },
    gridItem : { marginTop : 5 },
    draftButton : { background : '#FF9017', color : 'white', marginRight : 5 },
    proceedButton : { background : '#138DE8', color : 'white' },
    cardFooter : { justifyContent : 'center' }
}));

export default function Scheduling (props) {


    var campaignarray=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    campaignarray= [...obj["Campaigns"]];



    const classes = useStyles ();
    const [ valid, setValid ] = useState(false);
    const history = useHistory ();

    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});

    const { currentCampaign, setCurrentCampaign , createMode } = props;
    // If not present thr error.


    const handleBackClick = (event) => {
        props.backStep();
    }
    const validateConfiguration = (currentCampaign) => { 
        console.log(".....validtaion ",currentCampaign);
        switch(currentCampaign.type)
        {
            case 'Advertisement' : 
            if ((props.campaignMode === false &&  (currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.endDate !== null && currentCampaign.campaignSchedule.endDate !== '') && (currentCampaign.campaignSchedule.startDate &&  currentCampaign.campaignSchedule.startDate !== null && currentCampaign.campaignSchedule.startDate !== '') && (currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.scheduledTime && currentCampaign.campaignNotificationConfig.scheduledTime !== null && currentCampaign.campaignNotificationConfig.scheduledTime !== '')) || (currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.endDate !== null && currentCampaign.campaignSchedule.endDate !== '') && (currentCampaign.campaignSchedule.startDate &&  currentCampaign.campaignSchedule.startDate !== null && currentCampaign.campaignSchedule.startDate !== '') && (currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.scheduledTime && currentCampaign.campaignNotificationConfig.scheduledTime !== null && currentCampaign.campaignNotificationConfig.scheduledTime !== '') ) 
            {                

                 return true ;
            } 
            break;
            case 'GenBluStore' : 
            if ( (props.campaignMode === false  && ( currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.endDate !== null && currentCampaign.campaignSchedule.endDate !== '') && (currentCampaign.campaignSchedule.startDate &&  currentCampaign.campaignSchedule.startDate !== null && currentCampaign.campaignSchedule.startDate !== '') && (currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.scheduledTime && currentCampaign.campaignNotificationConfig.scheduledTime !== null && currentCampaign.campaignNotificationConfig.scheduledTime !== '')) || (currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.endDate !== null && currentCampaign.campaignSchedule.endDate !== '') && (currentCampaign.campaignSchedule.startDate &&  currentCampaign.campaignSchedule.startDate !== null && currentCampaign.campaignSchedule.startDate !== '') && (currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.scheduledTime && currentCampaign.campaignNotificationConfig.scheduledTime !== null && currentCampaign.campaignNotificationConfig.scheduledTime !== '') ) 
            {
                 return true ;
            } 
            break;
            case 'Event':
                if ((props.campaignMode === false && (currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.endDate !== null && currentCampaign.campaignSchedule.endDate !== '') && (currentCampaign.campaignSchedule.startDate &&  currentCampaign.campaignSchedule.startDate !== null && currentCampaign.campaignSchedule.startDate !== '') && (currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.scheduledTime && currentCampaign.campaignNotificationConfig.scheduledTime !== null && currentCampaign.campaignNotificationConfig.scheduledTime !== ''))  ||  (currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.endDate !== null && currentCampaign.campaignSchedule.endDate !== '') && (currentCampaign.campaignSchedule.startDate &&  currentCampaign.campaignSchedule.startDate !== null && currentCampaign.campaignSchedule.startDate !== '') && (currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.scheduledTime && currentCampaign.campaignNotificationConfig.scheduledTime !== null && currentCampaign.campaignNotificationConfig.scheduledTime !== '') ) 
                { return true; }   
            break;
            case 'MerchantEvent' : 
            if ((props.campaignMode === false && (currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.endDate !== null && currentCampaign.campaignSchedule.endDate !== '') && (currentCampaign.campaignSchedule.startDate &&  currentCampaign.campaignSchedule.startDate !== null && currentCampaign.campaignSchedule.startDate !== '') && (currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.scheduledTime && currentCampaign.campaignNotificationConfig.scheduledTime !== null && currentCampaign.campaignNotificationConfig.scheduledTime !== '')) ||  (currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.endDate !== null && currentCampaign.campaignSchedule.endDate !== '') && (currentCampaign.campaignSchedule.startDate &&  currentCampaign.campaignSchedule.startDate !== null && currentCampaign.campaignSchedule.startDate !== '') && (currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.scheduledTime && currentCampaign.campaignNotificationConfig.scheduledTime !== null && currentCampaign.campaignNotificationConfig.scheduledTime !== '') ) 
            {
                return true;
            }   

             break;
        }
        return false; }

     console.log("HeloArpit",currentCampaign);
    const saveCampaign = event => { 
        let validation = validateConfiguration (currentCampaign); 
   // alert(validation);
        if (validation === true) 
        {
            props.setSaveConfirmation(true);
           
        }
        else {
     //       alert(validation);
          props.setSaveConfirmation(false);
            return HWMessage.show ("Errors in one or more fields, please rectify before proceeding","error");
            //props.saveCampaign()
         }
        };
       
        // const saveDraft = event => { 
        //     if (!currentCampaign || currentCampaign === null) { return HWMessage.show ("Please enter one or more detail to save as campaign"); }
        //     props.saveDraft ();
        //     history.push('/ops/campaigns');
        // };
    
        const handleDraftClick = (event) => {
            props.setOpen(true);

           // saveDraft (event);
        }
    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    };

    const checkDisabled = () => {

        // alert (JSON.stringify(props.currentCampaign));
        if (props.campaignMode === false) {
            if (props.currentCampaign && props.currentCampaign.status && props.currentCampaign.status === 'Draft') {
                return false;
            } else if (props.currentCampaign && props.currentCampaign.status && props.currentCampaign.status === 'Enabled') {
                if (props.campaignType === 'Live' ) 
                    return true;
            } 
        } else {
            return false;
        }
    }

    const validateValues = (name, value) => {
        console.log (name, value);
        switch (name) {
            case 'startDate', 'endDate', 'timeOfDayStart', 'timeOfDayEnd', 'scheduledTime' : 
                if (value === null || value === '') {  
                    setHasError ({ ...hasError, [ name ] : true });
                    setErrorMessage ({ ...errorMessage, [ name ] : 'Invalid values' });
                    return true;
                }
        }
        setHasError ({ ...hasError, [ name ] : false });
        setErrorMessage ({ ...errorMessage, [ name ] : null });
        return true;
    };

    const joinDateTimeString = (date, time) => {
        if (date === undefined || time === undefined)
        {
            return undefined;
        }
        return `${date}T${time}`;
    };
    const splitDateTimeString = (dateTime) => {
        console.log("Time to split : " , dateTime)
        let split = dateTime.split ('T');
        return { date : split[0], time : split[1] };
    };

    const fetchValueByType = fieldName => {
        var configObject = null
        switch (currentCampaign.type) {
            case 'Advertisement' : configObject = currentCampaign.adConfig; break;
            case 'GenBluStore'  : configObject = currentCampaign.genbluEcommerceConfig; break;
            case 'Event' : configObject = currentCampaign.campaignEventConfig; break;
            case 'MerchantEvent' : configObject = currentCampaign.campaignMerchantEventConfig; break;
        }
        return configObject [ fieldName ];
    };  

    const fetchValueFromSchedule = fieldName => {
        switch (fieldName) {
            case 'startDate' :  return currentCampaign.campaignSchedule && currentCampaign.campaignSchedule ? joinDateTimeString(currentCampaign.campaignSchedule.startDate, currentCampaign.campaignSchedule.timeOfDayStart) : '';
            case 'endDate' :  return currentCampaign.campaignSchedule && currentCampaign.campaignSchedule ? joinDateTimeString(currentCampaign.campaignSchedule.endDate, currentCampaign.campaignSchedule.timeOfDayEnd) : '';
            case 'scheduledTime' : return currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.scheduledTime;
            case 'daysOfCampaign' : return currentCampaign && currentCampaign.campaignNotificationConfig && currentCampaign.campaignNotificationConfig.daysOfCampaign;
        };
    };

    const assignValueForSchedule = (name, value) => {
        console.log("Assigning", name, value);
        switch (name) {
            case 'startDate': 
                var splitted = splitDateTimeString ( moment(new Date(value).toLocaleString("en-IN", { timeZone:"Asia/Singapore"}), 'DD/MM/YYYY, HH:mm:ss a').format('YYYY-MM-DDTHH:mm:ss.SSS')  );
                console.log(splitted);
                setCurrentCampaign (prev => ({ ...prev, campaignSchedule : { ...prev.campaignSchedule, startDate: splitted.date, timeOfDayStart: splitted.time } })); setValid(true);
                break;
            case 'endDate': 
                var splitted = splitDateTimeString ( moment(new Date(value).toLocaleString("en-IN", { timeZone:"Asia/Singapore"}), 'DD/MM/YYYY, HH:mm:ss a').format('YYYY-MM-DDTHH:mm:ss.SSS') );
                console.log(splitted);
                setCurrentCampaign (prev => ({ ...prev, campaignSchedule : { ...prev.campaignSchedule, endDate: splitted.date, timeOfDayEnd: splitted.time } }));setValid(true); break;
            case 'scheduledTime' : console.log ("Here", name, value); setCurrentCampaign (prev => ({ ...prev, campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, scheduledTime : moment(new Date(value).toLocaleString("en-IN", { timeZone:"Asia/Singapore"}), 'DD/MM/YYYY, HH:mm:ss a').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'  } }));setValid(true); break;
        }
        console.log("Here we go", name, value, currentCampaign);
    }

    const captureInput = event => {
        const { name, value } = event.target;
        validateValues (name, value);
        console.log("Capturing", name, value);
        switch (name) {
            case 'startDate':  
            if(props.campaignMode === true){
                assignValueForSchedule (name, value);
             //   alert(typeof value);
             console.log(value, typeof value);
            }
              
            else {
                var splitted = splitDateTimeString (moment(new Date(value).toLocaleString("en-IN", { timeZone:"Asia/Singapore"}), 'DD/MM/YYYY, HH:mm:ss a').format('YYYY-MM-DDTHH:mm:ss.SSS') );
                setCurrentCampaign (prev => ({ ...prev, campaignSchedule : { ...prev.campaignSchedule , startDate: splitted.date, timeOfDayStart: splitted.time  } })) 

            }
             break;
            case 'endDate': 
            if(props.campaignMode === true)
                 assignValueForSchedule (name, value);
            else
            {
                var splitted = splitDateTimeString (moment(new Date(value).toLocaleString("en-IN", { timeZone:"Asia/Singapore"}), 'DD/MM/YYYY, HH:mm:ss a').format('YYYY-MM-DDTHH:mm:ss.SSS'));
                setCurrentCampaign (prev => ({ ...prev, campaignSchedule : { ...prev.campaignSchedule, endDate: splitted.date, timeOfDayEnd: splitted.time  } })) 

            }
             break;
            case 'scheduledTime': 
            if(props.campaignMode === true)
                assignValueForSchedule (name, value); 
            else{
                // var splitted = splitDateTimeString (value);
                // console.log("time vbeing set",value);
                setCurrentCampaign (prev => ({ ...prev, campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, scheduledTime : moment(new Date(value).toLocaleString("en-IN", { timeZone:"Asia/Singapore"}), 'DD/MM/YYYY, HH:mm:ss a').format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'  } })) 

            }
            break;
        }  
    };
    const assignValueByType = (field, value) => {
        console.log("Assigning", currentCampaign.type);
        switch (currentCampaign.type) {
            case 'Advertisement' : setCurrentCampaign (prev => ({ ...prev, adConfig : { ...prev.adConfig, [ field ] : value } })); break;
            case 'GenBluStore'  :  setCurrentCampaign (prev => ({ ...prev, genbluEcommerceConfig : { ...prev.genbluEcommerceConfig, [ field ] : value } })); break;
            case 'Event' : setCurrentCampaign (prev => ({ ...prev, campaignEventConfig : { ...prev.campaignEventConfig, [ field ] : value } })); break;
            case 'MerchantEvent' : setCurrentCampaign (prev => ({ ...prev, campaignMerchantEventConfig : { ...prev.campaignMerchantEventConfig, [ field ] : value } })); break;
        }
        console.log("Current Camapign", currentCampaign);
    };

    const handleDaysChange = (daysList) => {
        console.log(daysList) ;
        if(props.campaignMode === true )

        assignValueByType ('daysOfCampaign', daysList);
        else
            setCurrentCampaign (prev => ({ ...prev, campaignNotificationConfig : { ...(prev.campaignNotificationConfig) && prev.campaignNotificationConfig, daysOfCampaign : daysList } })) 
        };

        const fetchValueByTypes = fieldName => {
            var configObject = null
            switch (currentCampaign.type) {
                case 'Advertisement' : return undefined;
                case 'GenBluStore'  : configObject = currentCampaign.genbluEcommerceConfig; break;
                case 'Event' : configObject = currentCampaign.campaignEventConfig; break;
                case 'MerchantEvent' : configObject = currentCampaign.campaignMerchantEventConfig; break;
            }
            return configObject [ fieldName ];
        };  

    const getDate = () =>{
        if(currentCampaign.type === 'Advertisement') { return new Date(); }
        else{
            var currentDate = new Date() ;
            if(props.campaignMode === true)
                var startDate = new Date(fetchValueByTypes('eventStartTime')) ;
            else
                var startDate =  new Date();
         //   console.log(currentDate , startDate);
           // var maxDate = (currentDate > startDate ? currentDate : startDate  ); 
           // console.log(maxDate);
            return currentDate;
        }
    }
    const getEndDate = () => {
        if(props.campaignMode === true && currentCampaign.type !== 'Advertisement')
         {return undefined ;}
    };

 

    const getStartDate = () => {
        if(props.campaignMode === false)
        {
            if(currentCampaign &&   currentCampaign.eventSchedule &&  currentCampaign.eventSchedule.eventStartTime && (currentCampaign.eventSchedule.eventStartTime > new Date().toISOString())){
                return currentCampaign.eventSchedule.eventStartTime.split(":").slice(0,-1).join(":");
            }
            else{
              return new Date().toISOString().split(":").slice(0,-1).join(":");
            }
        }
        else{
            return fetchValueByTypes("eventStartTime") ? fetchValueByTypes("eventStartTime").split(":").slice(0,-1).join(":"): null ;
        }
    };

    const getEndDates = () => {
        if(props.campaignMode === false)
        {
            
            return currentCampaign && currentCampaign.eventSchedule.eventEndTime && currentCampaign.eventSchedule.eventEndTime.split(":").slice(0,-1).join(":") ;
        }
       else{         
  return fetchValueByTypes("eventEndTime")?fetchValueByTypes("eventEndTime").split(":").slice(0,-1).join(":"):null ;
    //     var CampStartDate = currentCampaign && currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.startDate;
    //     var CampStartTime = currentCampaign && currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.timeOfDayStart;
    //     var CampStartString = CampStartDate+ 'T' + CampStartTime; 

    //   return CampStartString;

       }
        }

    const getMinDate = () => {
        if(props.campaignMode === false)
             return currentCampaign  &&  currentCampaign.eventSchedule &&  currentCampaign.eventSchedule.eventStartTime && (currentCampaign.eventSchedule.eventStartTime.split(":").slice(0,-1).join(":"));
        else{
            return fetchValueFromSchedule ('startDate') ; 
        }
    };


    const checkCampaignStart = event => {
        var CampStartDate = currentCampaign && currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.startDate;
        var CampStartTime = currentCampaign && currentCampaign.campaignSchedule && currentCampaign.campaignSchedule.timeOfDayStart;
        var CampStartString = CampStartDate+ 'T' + CampStartTime; 

        if( props.campaignMode === true)
        {
            return false;
        }
        else {
            if(new Date().toISOString().split(":").slice(0,-1).join(":") < CampStartString) 

            {
                //upcoming
                return false;
            }
 
            else{
                return true;
            }
        }
         
     };


 
    return (
        <Fragment>
            <Card>
                <CardHeader className = { classes.cardHeader } color = 'rose' icon >
                    <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        <h4 className = { classes.cardIconTitle } > Scheduling  </h4> 
                </CardHeader>     
                <CardBody>
                    <GridContainer>
                        
                             <h3> Campaign Schedule </h3>   
                             
                                <GridContainer>  
                                    <GridItem xs = {6} md = {6} sm = {6}> 
                                        <HWInput label = { 'Start Date' } value = { fetchValueFromSchedule ('startDate')  } 
                                          required = {true}  inputProps={{min : getDate().toISOString().split(":").slice(0,-1).join(":"),  max : getStartDate() === undefined ? undefined : getStartDate() }}     type = 'datetime-local' name = 'startDate' hasError = { checkError ('startDate') } onBlur = { captureInput }
                                            disabled ={ checkCampaignStart() }  helperText = { fetchErrorMessage ('startDate') } labelProps = {{ shrink: true }}
                                        />
                                    </GridItem>
                                    <GridItem xs = {6} md = {6} sm = {6} > 
                                        <HWInput label = { 'End Date' } value = { fetchValueFromSchedule ('endDate')  } 
                                          required = {true}  disabled = { (fetchValueFromSchedule ('startDate') ===  '' || fetchValueFromSchedule ('startDate') ===  null || fetchValueFromSchedule ('startDate') ===  undefined) || new Date().toISOString() > fetchValueFromSchedule ('endDate')  ? true  : false}  type = 'datetime-local' inputProps={{min :  getEndDates() , max : getEndDate() === undefined ? undefined : getEndDate().toISOString().split(":").slice(0,-1).join(":") }}  name = 'endDate' hasError = { checkError ('endDate') } onBlur = { captureInput }
                                            helperText = { fetchErrorMessage ('endDate') } labelProps = {{ shrink: true }}
                                        />
                                    </GridItem> 
                                    </GridContainer>
                                    <GridContainer>  
                                    <GridItem xs = {12} md = {12} sm = {12}> 
                                    
                                    <h3> Notification Schedule </h3> 
                                    
                                    <HWInput label = { 'Scheduled Time' } value = {  props.campaignMode === true ? fetchValueFromSchedule ('scheduledTime')!== undefined ? fetchValueFromSchedule ('scheduledTime').split(":").slice(0,-1).join(":") :  new Date().toISOString().split(":").slice(0,-1).join(":")  : fetchValueFromSchedule ('scheduledTime') === undefined ? "" : fetchValueFromSchedule ('scheduledTime').slice(0,fetchValueFromSchedule ('scheduledTime').length -1) }  
                                        required = {true}   inputProps={{min : new Date().toISOString().split(":").slice(0,-1).join(":")}}  onBlur = { captureInput } type = 'datetime-local' name = 'scheduledTime' hasError = { checkError ('scheduledTime') }
                                            helperText = { fetchErrorMessage ('scheduledTime') } labelProps = {{ shrink: true }}
                                    />
                            
                        </GridItem> 
                                    </GridContainer>   
                                    <GridItem xs = {6} md = {6} sm = {6} >        
                                       <GridContainer justify = "space-between"  style = {{ alignItems : 'center' }} >  
                                       <div>
                                       <h3> Campaign Weekdays </h3>      
                                       <div style={{fontSize: 12}}> * Days not to display</div>  
                                       </div>
                                                <br/>    
                                       
                                                   <WeekDaySelector 
                                        name = { 'daysOfCampaign' }
                                        days = { props.campaignMode === true ? fetchValueByType ('daysOfCampaign') : currentCampaign.campaignNotificationConfig.daysOfCampaign }
                                        handleDaysChange = { handleDaysChange }

                                        /> 
                                    </GridContainer>
                                     </GridItem> 
                                
                           
                    </GridContainer>
                   
                </CardBody>
               {campaignarray.includes('Action') === true &&  <CardFooter className = { classes.cardFooter } >
                        <center> 
                        <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }}   onClick = { e=>handleBackClick(e) } > Back </Button> 
{  props.campaignType !== 'Expired' &&                           <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} disabled = { checkDisabled() }  onClick = { handleDraftClick } > Save as Draft </Button> 
}{                 props.campaignType != 'Expired'     &&        <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} disabled = { props.campaignMode === false ? false :!valid  } onClick = { saveCampaign } > {props.campaignMode === true ? "Create Campaign" : "Update"} </Button>

}
{ props.campaignType === 'Expired' &&  <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }}  onClick = { e => history.push('/ops/campaigns')  } > Close </Button>}
                        </center>
                </CardFooter>}
            </Card>     
        </Fragment>
    );
}