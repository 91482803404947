import React, { Component } from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import { Fab, IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';


export default class FileUploaderSingle extends Component {

    constructor(props) {
        super(props)
        this.state = {
            file: null,
            currentCampaign : {}, 
        }
        this.uploadSingleFile = this.uploadSingleFile.bind(this);
        this.upload = this.upload.bind(this);

    }

    uploadSingleFile(e) {
        this.setState({
            file: URL.createObjectURL(e.target.files[0])
        });
    }

    async  upload(e) {
        e.preventDefault();
        console.log(this.state.file);
        var url= this.state.file;
       // console.log("asdasda" , this.state.currentCampaign  );
      //  this.setState({currentCampaign : {...this.state.currentCampaign, bannerImageUrl : url }});
       
    await this.setState({currentCampaign : {...this.props.currentCampaign , bannerImageUrl : url } });
    console.log("asdasda" , this.state.currentCampaign  );

       this.props.setCurrentCampaign (this.state.currentCampaign);
   
    }


    render() {
        let imgPreview2;
        if (this.state.file) { 
            imgPreview2 = <img style = {{ height: 100 ,  width:100 , margin:10 }} src={this.state.file} alt='' />;
        }
        return (
            <form>
                <div >
                    {imgPreview2}
                </div>

                <div >
                <input type="file" style = {{display:"none"}} id="upload-pic2" accept="image/*"  onChange={this.uploadSingleFile} multiple />
                <label htmlFor = "upload-pic2" >
                <Fab  component="span" aria-label="add">
                    <PublishIcon />
                </Fab>
               </label>
               <br/>
               <br/>
               <Button variant="contained" style={{backgroundColor :"#138DE8" , color : "floralwhite"}} component="span" onClick={this.upload}>
               Upload
               </Button>
            
                </div>
            </form >
        )
    }
}