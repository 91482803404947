import axios from 'axios';
import authService from 'HWLibrary/ApiService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import HWMessage from 'HWLibrary/HWMessage';
import appConfig from '../../HWLibrary/HWConfig';
class MemberApiService {
  // Singleton
  constructor() {
    if (! MemberApiService.instance) { MemberApiService.instance = this }
    return MemberApiService.instance
  }

 

  defaultAuthConfig = () => {
    const accessToken =  authService.getCachedAccessToken();
    return { headers : { Authorization : `Bearer ${accessToken}` } };
  }

  fetch = (request) => {
    var searchField, searchType, searchText = null;
    if (request && request.filterModel) {
      searchField = request.filterModel.columnField;
      if (searchField === "tier") 
          searchField = "tier.tierName";

      searchType = request.filterModel.operatorValue;
      searchText = request.filterModel.value;
    }  

    if (request && request.searchText)
      searchText = request.searchText;

    var sortField, sortOrder = null;
    if (request && request.sortModel) {
        if (request.sortModel.field && request.sortModel.sort) {
          sortField = request.sortModel.field;
          sortOrder = request.sortModel.sort === "asc" ? "Ascending" : "Descending";
        } 
    }  
    var memberIds = null ;
    if(request  && request.list)
    {
       memberIds = JSON.stringify(request.list) ;
    }

    const queryParams = request !== undefined ? { params: { searchText, searchField, searchType, sortField, sortOrder, fetchLimit: request.fetchLimit, paginationBookmark: request.paginationBookmark , memberIds: memberIds, firstSortId:request.firstSortId,lastSortId:request.lastSortId,pageDelta:request.pageDelta,pageSize:request.pageSize} } : {};

    console.log("Querying Memebers :: ", queryParams);

    const url = appConfig.serverBaseUrl + "/members";
    const config = {...this.defaultAuthConfig(), params: { searchText, searchField, searchType, sortField, sortOrder, fetchLimit: request.fetchLimit, paginationBookmark: request.paginationBookmark ,  memberIds: memberIds, firstSortId:request.firstSortId,lastSortId:request.lastSortId,pageDelta:request.pageDelta,pageSize:request.pageSize } };
    return authService.axiosService().get(url, config);
  };


  fetchMemberDetails = (memberId) => {
    if (memberId) {
      const url = `${appConfig.serverBaseUrl}/members/${memberId}/profile`;
      console.log("URL", url);
      const config = {...this.defaultAuthConfig()};
      return authService.axiosService().get(url, config);
    }
    else 
      console.log("Member ID must be provided for feteching details");
  };

  fetchBikeDetails = (memberId) => {
    const queryParams =  {params: { memberId : memberId}}
    const url = appConfig.serverBaseUrl + "/bike";
    const config = { ...this.defaultAuthConfig(), params : { memberId : memberId} };
    return axios.get(url, config);
  }
  fetchActiveMembers = (request) => {
    var searchField, searchType, searchText = null;
    if (request && request.filterModel) {
      searchField = request.filterModel.columnField;
      if (searchField === "tier") 
          searchField = "tier.tierName";

      searchType = request.filterModel.operatorValue;
      searchText = request.filterModel.value;
    }  

    if (request && request.searchText)
      searchText = request.searchText;

    var sortField, sortOrder = null;
    if (request && request.sortModel) {
        console.log("Current sort model",  request.sortModel);
        if (request.sortModel.field && request.sortModel.sort) {
          sortField = request.sortModel.field;
          sortOrder = request.sortModel.sort === "asc" ? "Ascending" : "Descending";
        } 
    }  
    var memberIds = null ;
    if(request  && request.list)
    {
       memberIds = JSON.stringify(request.list) ;
    }

    const queryParams = request !== undefined ? { params: { searchText, searchField, searchType, sortField, sortOrder, fetchLimit: request.fetchLimit, paginationBookmark: request.paginationBookmark , memberIds: memberIds, firstSortId:request.firstSortId,lastSortId:request.lastSortId,pageDelta:request.pageDelta,pageSize:request.pageSize } } : {};

    console.log("Querying Memebers :: ", queryParams);

    const url = appConfig.serverBaseUrl + "/active-members";
    const config = {...this.defaultAuthConfig(), params: { searchText, searchField, searchType, sortField, sortOrder, fetchLimit: request.fetchLimit, paginationBookmark: request.paginationBookmark ,  memberIds: memberIds, firstSortId:request.firstSortId,lastSortId:request.lastSortId,pageDelta:request.pageDelta,pageSize:request.pageSize } };
    return authService.axiosService().get(url, config);
  };

  saveBikeDetails = (request) => {
    if (request) request.bikeId = request.id;
    const url = `${appConfig.serverBaseUrl}/bike`;
    const config = { ...this.defaultAuthConfig() };
    return axios.put(url, request, config);
  }

  reverifyBikeDetails = (request) => {
    console.log("request: ", request);
    const url = `${appConfig.serverBaseUrl}/bike/reverify`;
    const config = { ...this.defaultAuthConfig() };
    return axios.put(url, request, config);
  }

  saveMemberDetails = (request) => {
    console.log("Request", request);
    const url = appConfig.serverBaseUrl + "/members";
    const config = {...this.defaultAuthConfig()};
    return axios.put(url, request, config);
  }


  changePassword = (request) => {
    console.log("Request", request);
    const url = appConfig.serverBaseUrl + "/ops/password/change";
    const config = {...this.defaultAuthConfig()};
    return axios.post(url, request, config);
  }


  sendOtp = (mail) => {
   
    var emailOrMobile = mail;
    var verificationTypeorbypassCode = "EmailAddress" ;
    const url = appConfig.serverBaseUrl + "/ops/otp/send";
    const config = { headers: { 
      "Content-Type": "application/xml",
      "x-api-key": "4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45"
    
    }  , params: {emailOrMobile :  emailOrMobile, verificationType : verificationTypeorbypassCode }};
    return axios.get(url  , config );
  }

  checkOtp = (otp , emailOrMobile) => {
    const url = appConfig.serverBaseUrl + "/ops/otp/verify";
    const config = { headers: { 
      "Content-Type": "application/xml",
      "x-api-key": "4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45"
    
    } };
    return axios.post(url , {otp : otp , emailOrMobile : emailOrMobile , verificationType: "EmailAddress"} ,config);
  }

  toggleMembershipDetails = (id,status) => {
    const url = appConfig.serverBaseUrl + "/updatemembershipdetails";
    const config = { ...this.defaultAuthConfig() };
    return axios.put(url , {memberId : id , status : status } ,config);

  }

  activateMember = (memberId) => {
    const url = `${ appConfig.serverBaseUrl }/activate-member/${ memberId }`;
    const config = { ...this.defaultAuthConfig() }; 
    return axios.post(url ,config);
  }

  updateMembershipDetails = (id,currentRule) => {
    const url = appConfig.serverBaseUrl + "/updatemembershipdetails";
    const config = { ...this.defaultAuthConfig() };
    return axios.put(url , {memberId : id , validityEndDate : currentRule.date , tierName : currentRule.tierName } ,config);
  }

  forgotPassword = (psd , confirmPsd , emailAddress) => {

    const url = appConfig.serverBaseUrl + "/ops/password/forgot";
    const config = { headers: { 
      "Content-Type": "application/xml",
      "x-api-key": "4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45"
    
    } };
    return axios.post(url, {newPassword : psd , newPasswordReEntered : confirmPsd , emailAddress: emailAddress } ,config);
  }

  deleteMember = (id) => {
    const url = `${ appConfig.serverBaseUrl }/members/${ id }/delete-account `;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().put(url, {memberId:id,deletedBy:"ops"}, config);
  };

  
};

MemberApiService.TransactionApiService = class {
  constructor () {
    if (! MemberApiService.TransactionApiService.instance) { MemberApiService.TransactionApiService.instance = this; }
    return MemberApiService.TransactionApiService.instance;
  }
   
  fetchMemberTransactions = (request) => {

      console.log("Requesting", request);

      var searchField, searchText, searchType = null;
      if (request && request.filterModel) {
        searchField = request.filterModel.columnField;
        searchText = request.filterModel.value;
        searchType = request.filterModel.operatorValue;
      } 
      if (request && request.searchText)  {  
        searchText = request.searchText;
      } 

      var sortField, sortOrder = null;
      if (request && request.sortModel != null) {
        console.log("Request sorting model", request, request.sortModel);
        sortField = request.sortModel.field;
        sortOrder = request.sortModel.sort === "asc" ? "ascending" : "descending";
        console.log("Assinged", sortField, sortOrder);
      }

      var fetchLimit, paginationBookmark, memberId = null;
      if (request) {  
        if (request.fetchLimit != null) { fetchLimit = request.fetchLimit; }
        if (request.paginationBookmark != null) { paginationBookmark = request.paginationBookmark; }
        if (request.memberId != null) { memberId = request.memberId; } 
      }

      const queryParams = (request != null) ? { params : { fetchLimit, paginationBookmark, searchText, searchField, searchType, searchText, sortField, sortOrder, transactionType : "all" } } : {};
      console.log("TXN Query Params ", queryParams); 

      const url = `${appConfig.serverBaseUrl}/members/${memberId}/transactions`;
      console.log("Printing URL", url);
      const config = {...MemberApiService.instance.defaultAuthConfig(), params : { fetchLimit, paginationBookmark, searchText, searchField, searchType, searchText, sortField, sortOrder, transactionType : "all" }};
      return authService.axiosService().get(url, config);
   };

  fetchSpecificMemberTransaction = (request) => {
        var transactionId = request && request.transactionId;
        var memberId = request && request.memberId;
        console.log("Transaction Fetch ", transactionId);

        const url = `${appConfig.serverBaseUrl}/members/${memberId}/transactions/${transactionId}`;
        const config = { ...MemberApiService.instance.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
    };
};

MemberApiService.PointsApiService = class {
   constructor () {
     if (! MemberApiService.PointsApiService.instance) { MemberApiService.PointsApiService.instance = this; }
     return MemberApiService.PointsApiService.instance;
   }

   defaultAuthConfig = () => {
    const accessToken =  authService.getCachedAccessToken();
    return { headers: { Authorization: `Bearer ${accessToken}` } }
  }

   fetchMemberPoints = (request) => {
      var searchField, searchText, searchType = null;
      if (request && request.filterModel) {
        searchField = request.filterModel.columnField;

        
        searchText = request.filterModel.value;
        searchType = request.filterModel.operatorValue;
      } 
      if (request && request.searchText)  {  
        searchText = request.searchText;
      } 

      var sortField, sortOrder = null;
      if (request && request.sortModel) {
        sortField = request.sortModel.field;
        sortOrder = request.sortModel.sort === "asc" ? "ascending" : "descending";
      }

      var fetchLimit, paginationBookmark, memberId = null;
      if (request) {  
        if (request.fetchLimit != null) { fetchLimit = request.fetchLimit; }
        if (request.paginationBookmark != null) { paginationBookmark = request.paginationBookmark; }
        if (request.memberId != null) { memberId = request.memberId; } 
      }      
      
      const queryParams = (request != null) ? { params : { fetchLimit, paginationBookmark, searchText, searchField, searchType, searchText, sortField, sortOrder } } : {};
      console.log("TXN Query Params ", queryParams); 

      const url = `${appConfig.serverBaseUrl}/members/${memberId}/points-balance`;
      const config = {...this.defaultAuthConfig(), ...queryParams};
      return authService.axiosService().get(url, config);
   };
};

const memberApiService = new MemberApiService();
const memberTransactionApiService = new MemberApiService.TransactionApiService();
const memberPointsApiService = new MemberApiService.PointsApiService();
export default memberApiService;
export { memberTransactionApiService, memberPointsApiService };