import { Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles, CardActions , Dialog, DialogActions, DialogContent, DialogTitle , IconButton , Divider} from '@material-ui/core';

import { People, PeopleAlt, TripOriginRounded } from '@material-ui/icons';
import GridContainer from 'components/Grid/GridContainer';
import React, { useState, useEffect, Fragment } from 'react';
import { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardFooter from 'components/Card/CardFooter';
import CardBody from 'components/Card/CardBody';
import { Button } from '@material-ui/core';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { HWInput } from 'HWLibrary/HWInput';
import HWMessage from 'HWLibrary/HWMessage';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import Edit from '@material-ui/icons/Edit';
import StatusDialog from 'views/Utils/StatusDialog';
import merchantApiService from 'views/Merchant/APIService';
import memberApiService from 'views/Members/APIService';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { DataGrid } from '@material-ui/data-grid';
import pointsApiService from 'views/Points/APIService';
import SearchBar from 'views/Merchant/SearchBar';
import pendingTransactionsApiService from 'views/Transactions/ApiService';

const useStylesHead = makeStyles (styles);


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    
}));

export default function PendingTransactionDetails (props) {

    var action = [];
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    action= [...obj["Actions"]]
    const classes = useStyles ();
    
    const getFromLocation = () => {
        if (props && props.location && props.location && props.location.state) {
            return props.location.state;
        } else return {};
    };
    let locationParams = getFromLocation();
    
    const { memberList, merchantList, userType,  } = locationParams;
    const incomingRequest = props && props.location && props.location.params && props.location.params.state && props.location.params.state.pointsRequest;

    const [ currentRequest, setCurrentRequest ] = useState (locationParams && locationParams.currentRequest !== null ? locationParams.currentRequest : {});
    console.log("locationParamsneww" , locationParams);

    const getSelectedList = () => {
        console.log("currentRequestneww",currentRequest);

        return (currentRequest && currentRequest.groupIds && currentRequest.groupIds.length > 0   ? currentRequest.groupIds : [] );
    };


    console.log("sadasd",audienceList)

    const [ pointsRequest, setPointsRequest ] = useState ({ });
    const [ requestLoading, setRequestLoading ] = useState (false);
    const [ creatorMode, setCreatorMode ] = useState ( locationParams && (locationParams.creatorMode !== undefined ? locationParams.creatorMode : locationParams.createMode) );
    const [ previous, setPreviousPage ] = useState (locationParams && locationParams.prev);
    const [ requestId, setRequestId ] = useState (creatorMode === true ? null : locationParams && locationParams.requestId);
    const [ statusOpen, setStatusOpen ] = useState (false);
    const [ listOpen, setListOpen ] = useState (false);
    const [ audienceList, setAudienceList ] = useState ([]);
    const [ rewardConfirmationOpen, setRewardConfirmationOpen ] = useState (false);
    const [ open3 , setOpen3] = useState(false);
    const [ open2 , setOpen2] = useState(false);
    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});
    const [ approveConfirmation, setApproveConfirmation ] = useState (false);
    const [ rejectConfirmation, setRejectConfirmation ] = useState (false);
    const [ updateConfirmation, setUpdateConfirmation ] = useState (false);
    const [edit, setEdit] = useState(false);
    const [totalMembersCount, setTotalMembersCount] = useState(0);
    const [totalMerchantCount, setTotalMerchantCount] = useState(0);
    const [check , setCheck ]  = useState(false);
    const headClasses = useStylesHead();
    const history = useHistory ();

    console.log ("History", history);

    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    };


    const handleBack = () => {  
        history.push ({ pathname: "transactions"})
    }

    useEffect(() => {
          if(creatorMode  === false)
            if(locationParams && locationParams.pointsRequest){
            HWLocalStorage.save(locationParams.pointsRequest.id, 'RequestId');
            }
            if(locationParams && locationParams.currentRequest){
                HWLocalStorage.save(locationParams.currentRequest.id, 'RequestId');
            }
    }, [])


    
    console.log("locationParamsnew2" , locationParams);
    useEffect (() => {
        if (locationParams.creatorMode === true ) {

            setRequestLoading (true);
            pendingTransactionsApiService.fetchRequest (locationParams.requestId)
            .then (response => { 
                if (! response || ! response.data) { return HWMessage.show ("Data cannot be fetched at moement"); }
                setCurrentRequest (response.data);     
                console.log("response new",response.data);
                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show ("Error while fetching the data"); })
            .finally (() => setRequestLoading (false));
        }
    }, [ ]);
    
    console.log("487 reward points",currentRequest);
    
    console.log(creatorMode);

    console.log("461",currentRequest);
    return (
        <Fragment>
            <GridContainer>
                <GridItem xs = {12} md = {12} sm = {12} >  
                          <Card>  
                          <CardHeader className = { classes.cardHeader } color = 'rose' icon >
                        <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        <h4 className = { classes.cardIconTitle } > Audience Selection </h4>
                    </CardHeader>
{          props.createMode === true &&                  <h4 className = { classes.cardIconTitle } style = {{ marginLeft : 10 }} > Reward Points Summary </h4>
}         
{props.createMode === false &&   edit ===true && <h4 className = { classes.cardIconTitle } style = {{ marginLeft : 10 }} > Reward Points Summary  &nbsp; <a style={{cursor: "pointer"}}><Edit style={{color: "#138de8"}} onClick={e=>setEdit(!edit)} /></a> </h4>
}


                   <CardBody>
                        <GridContainer>
                            <GridItem xs = {12} md = {12} sm = {12} >
                            <GridContainer> 
                        <GridItem xs = {12}>
                            <GridContainer>
                                <GridItem xs = {2} > <IconButton onClick = { () => {
                                    if (props.dialogVariant)
                                        props.setDialogOpen (false);
                                    else 
                                        handleBack ()   
                                } } > <KeyboardBackspaceIcon /> </IconButton>
                                 </GridItem>
                                <GridItem xs = {8}  > <h4 className = { headClasses.cardIconTitle } >
                                     </h4> 

                                     </GridItem>
                            </GridContainer>
                                                        <Divider/>       

                        </GridItem>
                    </GridContainer>
                    
                            </GridItem >

                            <GridItem  xs = {12} sm = {12} md = {12} >   
                            </GridItem>
                            <br/>
                            <br/>
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.transactionDetails && currentRequest.transactionDetails.fpxTransactionId } label = { 'Buyer Bank Branch' } name = 'fpxTransactionId'  />
                        </GridItem>
                         <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.transactionDetails && currentRequest.transactionDetails.messageToken } label = { 'Message Token' } name = 'messageToken'  />
                        </GridItem>
                         <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.transactionDetails && currentRequest.transactionDetails.messageType } label = { 'Message Type' } name = 'messageType'  />
                        </GridItem>                       
                         <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.transactionDetails && currentRequest.transactionDetails.sellerExchangeId } label = { 'Seller Exchange Id' } name = 'sellerExchangeId'  />
                        </GridItem>
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.transactionDetails && currentRequest.transactionDetails.sellerExchangeOrderNumber} label = { 'Seller Exchange Order Number' } name = 'sellerExchangeOrderNumber'  />
                        </GridItem> 
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.transactionDetails && currentRequest.transactionDetails.sellerOrderNumber} label = { 'Seller Order Number' } name = 'sellerOrderNumber'  />
                        </GridItem>
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.transactionDetails && currentRequest.transactionDetails.sellerId } label = { 'Seller Id' } name = 'sellerId'  />
                        </GridItem>
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.transactionDetails && currentRequest.transactionDetails.sellerBankCode } label = { 'Seller Bank Code' } name = 'sellerBankCode'  />
                        </GridItem>
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.transactionDetails && currentRequest.transactionDetails.transactionAmount } label = { 'Transaction Amount' } name = 'transactionAmount'  />
                        </GridItem>
                         <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.transactionDetails && currentRequest.transactionDetails.buyerEmail } label = { 'Buyer Email' } name = 'buyerEmail'  />
                        </GridItem>
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.transactionDetails && currentRequest.transactionDetails.buyerName } label = { 'Buyer Name' } name = 'buyerName'  />
                        </GridItem>
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.transactionDetails && currentRequest.transactionDetails.buyerId } label = { 'Buyer Id' } name = 'buyerId'  />
                        </GridItem>
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.transactionDetails && currentRequest.transactionDetails.makerName } label = { 'Maker Name' } name = 'makerName'  />
                        </GridItem>
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.transactionDetails && currentRequest.transactionDetails.creditAuthCode } label = { 'Credit Auth Code' } name = 'creditAuthCode'  />
                        </GridItem>
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.transactionDetails && currentRequest.transactionDetails.debitAuthCode } label = { 'Debit Auth Code' } name = 'debitAuthCode'  />
                        </GridItem>
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.transactionDetails && currentRequest.transactionDetails.reason } label = { 'Reason' } name = 'reason'  />
                        </GridItem>
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.transactionDetails && currentRequest.transactionDetails.transactionStatus } label = { 'Transaction Status' } name = 'transactionStatus'  />
                        </GridItem>
                       
                      {  locationParams &&  locationParams.creatorMode === false && currentRequest && currentRequest.approvalStatus === "Rejected" && <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.rejectionReason } label = { 'Reject Reason' } name = 'reason'  />
                        </GridItem>}
                        


                        
                        {locationParams &&  locationParams.creatorMode === false && currentRequest && currentRequest.rejectionReason && <GridItem xs = {12} md = {6} sm = {12} > 
                       { locationParams &&  locationParams.creatorMode === true && <HWInput disabled required={true} value = { currentRequest && currentRequest.rejectionReason } label = { 'Rejection Reason' } name = 'reason' 
                         
                         />}
                        </GridItem>}

                      { creatorMode === false &&  currentRequest && currentRequest.approvalStatus === 'Pending'  &&  <GridItem xs = {12} md = {12} sm = {12} >  
                                <HWInput   value = { currentRequest && currentRequest.rejectionReason } label = { 'Rejection Reason' } name = 'rejectionReason'  />
                                </GridItem>
                                }

                 
                     
                        </GridContainer>

                        {currentRequest && currentRequest.approvalStatus === 'Approved' && locationParams &&  locationParams.creatorMode === false &&
                    <Alert severity = { currentRequest.approvalStatus === 'Approved' ? 'success' : 'error' } >  
                        <AlertTitle> <h5> <b>  Request { currentRequest.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } </b> </h5>  </AlertTitle> 
                        <h4> { currentRequest.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } By : { currentRequest.resolverEmpName } </h4>
                        <h4> { currentRequest.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } At : { new Date(currentRequest.resolvedDate).toLocaleDateString() } </h4>
                        {currentRequest.approvalStatus === 'Rejected' && <h4> {  'Rejected' } Reason : { currentRequest.rejectionReason} </h4>}

                        
                    </Alert>
                }   

{currentRequest && currentRequest.approvalStatus === 'Rejected' && locationParams &&  locationParams.creatorMode === false &&
                    <Alert severity = { currentRequest.approvalStatus === 'Approved' ? 'success' : 'error' } >  
                        <AlertTitle> <h5> <b>  Request { currentRequest.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } </b> </h5>  </AlertTitle> 
                        <h4> { currentRequest.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } By : { currentRequest.resolverEmpName } </h4>
                        <h4> { currentRequest.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } At : { new Date(currentRequest.resolvedDate).toLocaleDateString() } </h4>
                        {currentRequest.approvalStatus === 'Rejected' && <h4> {  'Rejected' } Reason : { currentRequest.rejectionReason} </h4>}
                        
                    </Alert>
                }   


                    </CardBody>
                     <CardActions style = {{ justifyContent : 'center' }} color = "primary">
                    
                     <CardFooter className = { classes.cardFooter } >  
                        <GridContainer >
                         

                            { 
                                creatorMode === false &&  currentRequest && currentRequest.approvalStatus === 'Pending'  && <GridItem > 
                                    
                                    <center> 
                                { action.includes('Action')  === true &&  <Button raised variant = "outlined" style = {{ background : '#138DE8' , color: 'floralwhite' , margin: 2.5 , textTransform:'None' }} onClick = { e => setApproveConfirmation (true) } > Approve </Button>  }                                  
                                { action.includes('Action')  === true &&  <Button raised variant = "outlined" style = {{ background : '#FF9017' ,color: 'floralwhite' , margin: 2.5, textTransform:'None'}} onClick = { e => setRejectConfirmation(true) } > Reject </Button> }                                    
                                        
                                        <StatusDialog isLoading = {false} open= { open3 } setOpen={setOpen3}  sucessStatus={"success"} confirmingAction={e=> history.push ({ pathname : '/ops/actions' })} message={"Request successful."} dialogTitle={"Status"}/> 
                                    <StatusDialog isLoading = {false} open= { open2 } setOpen={setOpen2}  sucessStatus={"success"} confirmingAction={e=> history.push ({ pathname : '/ops/actions' })} message={"Request successful."} dialogTitle={"Status"}/> 

                                    </center>
                                </GridItem> 
                            } 
                            
                            {
                              creatorMode === true && previous !== 'points' &&  !incomingRequest && <GridItem > 
                                    <center>                                                                              
                                <StatusDialog isLoading = {false} open= { open3 } setOpen={setOpen3}  sucessStatus={"success"} confirmingAction={e=> history.push ({ pathname : '/ops/actions' })} message={"Request successful."} dialogTitle={"Status"}/> 
                                <StatusDialog isLoading = {false} open= { open2 } setOpen={setOpen2}  sucessStatus={"success"} confirmingAction={e=> history.push ({ pathname : '/ops/actions' })} message={"Request successful."} dialogTitle={"Status"}/> 

                                    </center>
                                </GridItem>
                            }

                            
                            { creatorMode === true  && (currentRequest && currentRequest.approvalStatus === 'Pending') && <GridItem > 
                           </GridItem> 
                            } 

                  </GridContainer>
                    </CardFooter>
                    </CardActions>
                    </Card>  
                </GridItem>
            </GridContainer>
        </Fragment>
    );

};
