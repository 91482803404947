import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Search from "@material-ui/icons/Search";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import React, { useState } from "react";

import RefreshIcon from '@material-ui/icons/Refresh';
import { TextField, Tooltip } from "@material-ui/core";

//const useStyles = makeStyles(styles);

const useStyles = makeStyles(themes => ({
    ...styles,

    root: {
        '& .MuiDataGrid-colCell': { color: 'primary' },
        '& .MuiInput-underline:after': { borderBottomColor: '#138DE8' },
    }
}));

export default function SearchBar(props) {

    const classes = useStyles();
    const placeholderText = props.placeholderText ? props.placeholderText : 'Search'
    const [searchText, setSearchText] = useState("");

    const searchButton =
    classes.top +
    " " +
    classes.searchButton +
    " " +
    classNames({
      [classes.searchRTL]: false, 
     
    });

    return (
        <div>
            <TextField
                id="standard-basic" 
                label = {  props.placeholder ||  "Search using any details" }
                style = {{ width : props.width || '500px' }}
                placeholder = {  props.placeholder ||  "Search using any details" }
                value = { searchText } 
                className = { classes.root }
                
                onChange = { (event) =>{ console.log("event", event); setSearchText (event.target.value);} }  
                onKeyDown = { event => {  if (event.key === 'Enter') props.handleSearch(searchText); }  }
            />
            
            <Tooltip title = "Click to Search" >
            <Button
                aria-label="edit"
                round
                className={searchButton}
                onClick = {(e) => { props.handleSearch(searchText) }}
                style={{marginRight:'-2px', backgroundColor : '#138DE8' , height: 40 }}
            >
                <Search className={classes.headerLinksSvg + " " + classes.searchIcon} />
            </Button>
            </Tooltip>

            <Tooltip title = "Click to Refresh - Removes Filter and Sorts" >
                <Button
                    color="#138DE8"
                    aria-label="edit"
                    variant = "outlined"
                    
                    round
                    className={searchButton}
                    onClick = {(e) => {  setSearchText(''); props.handleSearch (''); props.handleRefresh(!props.refreshState); }}
                    style={{marginRight:'-2px', left : '6px', backgroundColor : '#138DE8' ,  height: 40  }}
                >
                    <RefreshIcon  />
            </Button>
            </Tooltip>
      </div>
    )
}