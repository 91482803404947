import { useCallback, useEffect } from 'react';
import { AppBar, Button, Card, CardActions, CircularProgress, IconButton, makeStyles, Tab, Tabs ,Tooltip } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState } from 'react';
import { Fragment } from 'react';
import SearchBar from 'views/Merchant/SearchBar';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 

import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import { HWInput } from 'HWLibrary/HWInput';
import { Link, useHistory } from 'react-router-dom';
import {PeriodDialog} from './VOCVerification';
import { DirectActionDialog } from './VOCVerification';

import voucherRequestApiService from './ApiService';
import HWMessage from 'HWLibrary/HWMessage';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import BlockUi from 'react-block-ui';
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import {CustomToolbar} from '../Members/Member.js'

const useStyles = makeStyles (theme => ({ 
    ...styles,  
    cardFooter : { justifyContent : 'center' },
    cardHeader : { height : '50px' },   
    formControl: { margin: theme.spacing(1), minWidth: 120 }, 
    root : { 
      '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
      '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
      '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
      '& .MuiButton-label' : { color : '#138DE8' }
    }
}));


export default function VoucherTypeApproval (props) {
    var action=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    action= [...obj["Actions"]];
    if(HWLocalStorage.load("previousPointsActionsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsActionsTab");
    }
    HWLocalStorage.save (2, "previousActionsTab");
    

    const [ dataList, setDataList ] = useState ([
        { index : 0,  title: 'REQUESTS RAISED', type : 'All' },
        { index : 1, title : 'REQUESTS APPROVED', type : 'Approved' }, 
        { index : 2, title : 'REQUESTS REJECTED', type : 'Rejected' }
    ]);
    const selectDataCard = () => {
        var cachedData = HWLocalStorage.load ("voucherActionDataCategory");
        var pendingObject  = {index : 0, title : 'REQUESTS PENDING', type : 'Pending' };
        if (!cachedData || cachedData === null) { return pendingObject; }
        else if (cachedData.type === 'Pending') { return pendingObject; }
        else if (cachedData.type !== 'Pending') { 

            var temp = dataList[cachedData.index]; 
            dataList[cachedData.index] = pendingObject;
            pendingObject = temp;

            HWLocalStorage.clear ("voucherActionDataCategory");
            return cachedData; 
        }
    };
    function CustomLoadingOverlay() {
        return (
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
      }

    const classes = useStyles ();

    const [ countMaps, setCountMaps ] = useState ({ 'Pending' : 0, 'All' : 0, 'Approved' : 0, 'Rejected' : 0 }); 
    const [ periodDialog, openPeriodDialog ] = useState (false);
    const [ currentDataCard, setCurrentDataCard ] = useState (selectDataCard());
    

    const [rows, setRows] = useState ([]);
    const [ page, setPage ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ filterModel, setFilterModel ] = useState ({});
    const [ sortModel, setSortModel ] = useState ({});

    const [ searchText, setSearchText ] = useState ('');
    const [ refreshState, setRefreshState ] = useState (false);
    const [ rowCount, setRowCount ] = useState (0);
    const [ requestViewModels, setRequestViewModels ] = useState ({});
    const [loading, setLoading] = useState(false);
    const [ countLoading, setCountLoading ] = useState (false);
    const [ isLoading ,setIsLoading ] = useState(false);
    const [ startDate, setStartDate ] = useState ();
    const [ endDate, setEndDate ] = useState ();
    const [reason,setReason] = useState(null);
    useEffect (() => {
        setIsLoading(true);
        setLoading (true);
        var paginationBookmark = ((page) * pageSize);
        var fetchLimit = pageSize;
        voucherRequestApiService.fetch({ paginationBookmark, fetchLimit, filterModel, sortModel, searchText, approvalStatus : (currentDataCard.type === 'All' ? null : currentDataCard.type), startDate, endDate })
        .then (response => {
            if (! response || !response.data) {
                HWMessage.show ("Requests cannot be fetched at moment","error");
                return console.log("Error while fetching the response");
            }
            console.log("Voucher request response : ", response);
            setRowCount (response.data.totalHits);
            setRows (response.data.modelList);
            setRequestViewModels (createViewModelList(response.data.modelList));
            HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");

    })
        .finally (() => { setLoading (false); setIsLoading(false);var my_element = document.getElementById("top");
        var my_element = document.getElementById("top");
        if(my_element!== null || my_element!== undefined || my_element!== '')
        {
            // my_element.scrollIntoView({ }); 

        } });
    }, [ currentDataCard, page, pageSize, filterModel, sortModel, searchText, refreshState, startDate, endDate ]);


    const createViewModelList = modelList => {
        return modelList.filter(i => i != null) 
                .map (i => { 
                    var { id, approvalStatus, code, raisedByEmpId, raisedTime, requestType, 
                        resolvedTime, voucherPerUser, memberGroup, userSegmentId, memberId  } = i;

                    approvalStatus = approvalStatus.charAt(0).toUpperCase() + approvalStatus.slice(1);
                    resolvedTime = new Date(resolvedTime).toLocaleDateString();
                    raisedTime = new Date (raisedTime).toLocaleDateString();
                    
                    return { id, approvalStatus, code, 
                        raisedByEmpId, raisedTime, requestType, 
                        resolvedTime, voucherPerUser, memberGroup, userSegmentId,  memberId };
                });
    };

    const changeData = (idx) => {  
        var temp = currentDataCard;
        setCurrentDataCard(dataList [idx]);
        dataList [idx] = dataList [temp.index];
        dataList [temp.index] = temp;
        setDataList (dataList);
    };
    const [ directActionOpen, setDirectActionOpen ] = useState (false);
    const [ anchorEl, setAnchorEl ] = useState (null);
    const [ selectedVoucher, setSelectedVoucher ] = useState ({});
    const [ actionLoading, setActionLoading ] = useState (false);
    const [ voucherId, setVoucherId ] = useState ('');
    const [ savedLoading, setSavedLoading ] = useState (false);

    const getApprovalStatusColor = approvalStatus => {
        switch (approvalStatus) {
            case 'Pending' : return '#FF9017';
            case 'Approved' : return '#1f7a1f';
            case 'Rejected' : return '#cc0000';
        }
    };
    const columns = [
        { field: 'refNumber', headerName: 'Reference Number', flex: 1,minWidth:150, renderCell : (cellParams) => ( <span onClick = { (event) => openVoucherReward(cellParams.row) } > <Link style = {{ color : '#138DE8',  fontWeight : 400 }} > { cellParams.row.refNumber } </Link>  </span> ) },
        { field: 'code', headerName: 'Voucher Code', flex: 1,minWidth:150, renderCell : (cellParams) => ( <span onClick = { (event) => openVoucherReward(cellParams.row) } > <Link style = {{ color : '#138DE8',  fontWeight : 400 }} > { cellParams.row.code } </Link>  </span> ) },
        { field : 'approvalStatus', headerName : 'Request Status', flex: 1,minWidth:150, renderCell : (cellParams) => ( <span style = {{ color : ( getApprovalStatusColor (cellParams.row.approvalStatus)), fontWeight :  400 }} > { cellParams.row.approvalStatus.charAt(0).toUpperCase() + cellParams.row.approvalStatus.slice(1) } </span> ) }, 
        { field : 'userCount',filterable :  false ,  headerName : 'User Count',minWidth:150, flex: 1 },
        { field : 'voucherPerUser' , filterable :  false , headerName : 'Vouchers Per User',minWidth:150, flex: 1 }, 
        { field : 'raisedEmpName', headerName : 'Raised By', minWidth:150,flex: 1 },
        { field : 'raisedTime', filterable :  false ,  headerName : 'Raised Date', flex: 1,minWidth:150, renderCell : cellParams => {
            var date = cellParams.row.raisedTime;
            var dateString = date.split('T');
            var d = new Date (dateString).toLocaleDateString();

            return <span> {d} </span>
        } },
        { field : 'resolverEmpName', filterable :  false ,  headerName : 'Updated By', flex: 1,minWidth:150, renderCell : cellParams => {
            
            return <span> {cellParams.row.resolverEmpName != undefined ? cellParams.row.resolverEmpName  : null }  </span>
        } },

        { field : 'resolvedTime', filterable :  false ,  headerName : 'Updated Date', flex: 1,minWidth:150, renderCell : cellParams => {
            var date = cellParams.row.resolvedTime;
            if(date!== undefined)
            {
                var dateString = date.split('T');
                var d = new Date (dateString).toLocaleDateString();
    
                return <span> {d} </span>
            }
            else{
                return <span>{""}</span>
            }
           
        } },
        { field : 'reason', headerName : 'Reason', minWidth:150,flex: 1 },

        
    ];

    if(action.includes("Action") ===true){
        columns.push({ field: 'Action', headerName : '', width : 50, filterable :  false ,sortable:false, renderCell : (cellParams) => ( <> { cellParams.row.approvalStatus === 'Pending' &&  <IconButton onClick = { (event) => { console.log("Current Popover target ", event.currentTarget); setVoucherId (cellParams.row.id); setDirectActionOpen(true); setAnchorEl (event.currentTarget); } } > <MoreVertIcon style={{color:"#138de8"}}/> </IconButton> } </>)}
        )
    }

    useEffect (() => {
        setIsLoading(true);
        setLoading (true);
        voucherRequestApiService.fetchStats().
        then (response => {
            if (!response || !response.data) {
                HWMessage.show ("Requests cannot be fetched at moment","error");
                return console.log("Responses cannot be fetched at moment");
            }
            HWMessage.show ("Requests fetched successfully");
            setCountMaps ({ 'Pending' : response.data.pendingRequests, 'Approved' : response.data.approvedRequests, 'Rejected' : response.data.rejectedRequests, 'All' : response.data.rejectedRequests+response.data.pendingRequests+response.data.approvedRequests  });
        }).catch (error => { 
            HWMessage.show ("Error while fetching requests","error"); 
            return console.log("Error while fetching requests"); 
        }).finally (() => { setLoading(false);         setIsLoading(false);        });
    }, [ refreshState ]);


    useEffect (() => {
        if (voucherId) {
            setActionLoading (true);
            voucherRequestApiService.fetchRequestDetails ({ id : voucherId }) 
            .then (response => {
                if (!response || !response.data) {
                    HWMessage.show ("Response cannot be fetched at moment","error");
                    return console.log("Response cannot be fetched at moment");
                }
                setSelectedVoucher (response.data);
            }).catch (error => { 
                HWMessage.show ("Error while fetching request","error")
                return console.log("Error while fetching request");
             })
            .finally (() => { setActionLoading (false); });
        }
    }, [ voucherId ]);

    const updateRequest = useCallback ((selectedRequest) => {
        setSavedLoading (true);
        voucherRequestApiService.updateRequest(selectedRequest.id, selectedRequest)
        .then (response => { 
            if (!response || !response.data) {
                HWMessage.show("Error while saving the request " ,"error");
                return  console.log("Error while fetching the response for saving.");
            }
            setSelectedVoucher (response.data);
            HWMessage.show("Request Successful" , "success");
            
         })
        .catch (error => { 
            HWMessage.show ("Request could not be resolved","info");
            return console.log("Error while fetching response");
         })
        .finally (() => { setSavedLoading (false); })
    }, [ selectedVoucher ]);

    const approvalAction = () => {
        if (selectedVoucher.approvalStatus !== 'Pending') {  
            HWMessage.show ("Request is already resolved","info");
            return;
        }
        selectedVoucher.approvalStatus = 'Approved';
        updateRequest (selectedVoucher);
        setRows (rows.filter(i => i.id !== selectedVoucher.id));
        setRowCount (rowCount - 1);
        setDirectActionOpen (false);
        HWLocalStorage.clear ("voucherActionDataCategory");

    };
    const rejectAction = () => {
        if (selectedVoucher.approvalStatus !== 'Pending') {  
            HWMessage.show ("Request is already resolved","error");
            return;
        }
        selectedVoucher.approvalStatus = 'Rejected';
        selectedVoucher.reason = reason;
        updateRequest (selectedVoucher);
        setRows (rows.filter(i => i.id !== selectedVoucher.id));
        setRowCount (rowCount - 1);
        setDirectActionOpen (false); 
        HWLocalStorage.clear ("voucherActionDataCategory");

    };  

    const history = useHistory ();
    const openVoucherReward = voucherDetail => {
        history.push ({pathname : "reward-vouchers", params : { state : { voucherDetail, approvalAction, rejectAction, setSelectedVoucher, selectedVoucher } }});
    };

    return (
        <Fragment> 
        <BlockUi blocking = { loading || savedLoading || countLoading } message = { 'Loading Request' } > 
            <GridContainer id="top">
                { dataList.map ((dataType, idx) =>   (
                    <GridItem xs = {3} key = { idx } >
                     <Tooltip title = { dataType.title } >
                    <Card> 
                        <CardBody> 
                            <GridContainer> 
                                <GridItem xs = {8} > <center> <span style = {{ fontWeight : '500' }} > { dataType.title } </span> </center>  </GridItem>
                                <GridItem xs = {4} >
                                    {! isLoading && <Link onClick = { (event) => changeData (idx) } style = {{ fontSize : '1.5em', fontWeight : '500',color : '#138DE8' }}  >  { countMaps[dataType.type] }  </Link> }
                                    { isLoading && <CircularProgress /> }
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                    </Tooltip>
                    </GridItem>
                )) 
                }
                <GridItem xs ={3} style={{display:'flex',flexDirection:"row-reverse",alignItems:"center"}}>
                <div style = {{display : 'flex', flexDirection:"row-reverse" }}> <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { openPeriodDialog } >  <FilterListOutlinedIcon /> Filter Request Results   </Button> </div>
                </GridItem>
            </GridContainer>
            <br />
            <GridContainer justify = "space-between" alignItems = "center" > 
            <GridItem> <b> <span style = {{ fontWeight : '500px', fontSize : '2em' }} > { currentDataCard.title } : { ! isLoading && <a href = "#top" style = {{ color : '#138DE8', textDecoration : 'underline' }} >  { countMaps[currentDataCard.type] } </a> } { isLoading && <CircularProgress size = {25} /> } </span> </b> </GridItem>
                <GridItem style={{margin: "0px 10px 10px 0px"}}>  
                    <SearchBar 
                        width = '300px' 
                        handleSearch = { setSearchText }
                        handleRefresh = { setRefreshState }
                        refreshState = { refreshState }
                        placeholder="Search"
                    /> 
                </GridItem>
            </GridContainer>
            
            <Card>
                <CardBody>
                <DataGrid
                    className = { classes.root }
                    autoHeight
                    showToolbar 
                    density="compact" 
                    loading = { loading } 
                    columns={columns} 
                    rows={rows} 
                    rowCount = { rowCount }
                    paginationMode = 'server'
                    page = { page }
                    pageSize = { pageSize }
                    components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 

                    onPageChange = { (gridChangeParams) => { setPage (gridChangeParams); } }
                    onPageSizeChange = { (gridChangeParams) => { setPage (0); setPageSize(gridChangeParams); } }
                    rowsPerPageOptions ={ [25, 50, 100 ]}
                    
                    sortingMode = 'server'
                    onSortModelChange = { params => { if (params.length > 0)  {setSortModel (params[0]);}} } 
                    filterMode = 'server'
                    onFilterModelChange = { (params) => { if (params.items.length > 0) { setFilterModel (prev => ({ ...params.items[0] }));  } } }
                />
                </CardBody>
            </Card>    
            

            <PeriodDialog 
                periodDialog = { periodDialog } 
                openPeriodDialog = { openPeriodDialog } 
                startDate = { startDate } 
                endDate = { endDate } 
                setStartDate = { setStartDate }
                setEndDate = { setEndDate }
            />

            <DirectActionDialog 
                open = { directActionOpen }
                dialogTitle = { 'Direct Voucher Approval' }
                dialogContent = { 'Do you want to approve or reject the Voucher Request' }
                approvalAction = { approvalAction }
                rejectAction = { rejectAction }
                setDirectActionOpen = { setDirectActionOpen }
                anchorEl = { anchorEl }
                reason = {reason }
                setReason = {setReason}
            />   
         </BlockUi>
        </Fragment> 
    );   
};