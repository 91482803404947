import { FormControlLabel, makeStyles, Switch, withStyles } from '@material-ui/core';
import HWMessage from 'HWLibrary/HWMessage';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import campaignApiService from './APIService';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 47,
      height: 26,
      padding: 0,
      margin: theme.spacing(2),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#52d869',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#ffffff',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 30,
      height: 24,
      backgroundColor: '#ffffff'
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: "#e72517",
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });


const useStyles = makeStyles (theme => ({
  dangerColor : { color: '#EF516E' },
  safeColor : { color: '#51EF69' },
  labelRoot : { '& .MuiFormControlLabel-root' : {color : '#138DE8'} }
}));

export default function CampaignSwitch (props) {

  const classes = useStyles();

  const { campaign, setCampaign } = props;
  const [ checked, setChecked ] = useState ( campaign.status === 'Enabled' ? true : false );
  const [ priorityStatus, setPriorityStatus] = useState (campaign.priorityStatus === 'priority' ? true : false)
  const [visibilityStatus, setVisibilityStatus] = useState (campaign.campaignVisibility === 'show' ? true : false)
  const [ label, setLabel ] = useState (props && props.label);

  const isExpired = campaign => { return props.isExpired (campaign); };
  const isDraft = campaign => { return props.isDraft (campaign) };
  const isLive = campaign => { return props.isLive (campaign); };

  const showText = (campaign) => {
    if (isDraft (campaign)) { return 'Drafted'; }
    else if (isExpired (campaign)) { return 'Expired'; }
    else if (isLive(campaign)) { return 'Live'; }
  };

  useEffect (() => {
    if (campaign !== null) 
      if (campaign.status === 'Enabled') { setChecked (true); }
      else if (campaign.status === 'Disabled') { setChecked (false); }
  }, []);

  const enableCampaign = useCallback (campaignId => {
    campaignApiService.enableCampaign (campaignId)
    .then (response => {
      if (!response || !response.data) { HWMessage.show ("Campaign Cannot be enabled" ,"error"); }
      HWMessage.show ("Campaign Enabled Successfully" , "success");
      setChecked (true);
      props.handleStatusChange (campaignId, 'Enabled');
      setLabel ('Enabled');    
    }).catch (error => {
      HWMessage.show ("Campaign cannot be enabled" ,"error");
    });
  }, []); 
  
  const disableCampaign = useCallback (campaignId => {
    campaignApiService.disableCampaign (campaignId) 
    .then (response => {
      if (! response || ! response.data) { HWMessage.show ("Campaign cannot be disabled at moment","error"); }
      HWMessage.show ("Campaign Disabled Successfully","success");
      setChecked (false);
      setLabel ('Disabled');
      props.handleStatusChange (campaignId, 'Disabled');
    }).catch (error => {
      HWMessage.show ("Campaign Cannot be disabled","error");
    });
  }, []);

  const changeCampaignPriorityStatus = useCallback ((campaignId,priorityStatus) => {
    campaignApiService.changeCampaignPriorityStatus (campaignId, priorityStatus) 
    .then (response => {
      if (! response || ! response.data) { HWMessage.show ("Campaign priority cannot be changed at moment","error"); }
      HWMessage.show ("Campaign priority changed successfully","success");
      setPriorityStatus(priorityStatus === 'priority' ? true : false)
      setLabel (priorityStatus);
      props.handleStatusChange (campaignId, priorityStatus);
    }).catch (error => {
      HWMessage.show ("Campaign priority cannot be changed","error");
    });
  }, []);

  const changeCampaignVisibilityStatus = useCallback ((campaignId,visibilityStatus) => {
    campaignApiService.changeCampaignVisibilityStatus (campaignId, visibilityStatus) 
    .then (response => {
      if (! response || ! response.data) { HWMessage.show ("Campaign visibility cannot be changed at moment","error"); }
      HWMessage.show ("Campaign visibility changed successfully","success");
      setVisibilityStatus(visibilityStatus === 'show' ? true : false)
      props.handleStatusChange (campaignId, visibilityStatus);
    }).catch (error => {
      HWMessage.show ("Campaign visibility cannot be changed","error");
    });
  }, []);



  const handleStatusCheck = event => { 
      let campaignId = campaign.id;
      if (!campaignId || campaignId === null) { return HWMessage.show ("Campaign ID is not provided or empty"); }
      if (event.target.checked === true) {
        HWMessage.show ("Enabling Campaign");
        return enableCampaign(campaignId);
      } else if (event.target.checked === false) {
        HWMessage.show ("Disabling Campaign");
        return disableCampaign (campaignId);  
      }
      setChecked (event.target.checked); 
  };

  const handlePriorityStatusCheck = event => { 
    let campaignId = campaign.id;
    if (!campaignId || campaignId === null) { return HWMessage.show ("Campaign ID is not provided or empty"); }
    HWMessage.show ("Changing campaign priority");
      return changeCampaignPriorityStatus(campaignId, event.target.checked?"priority":"notPriority");
  }

  const handleVisibilityStatusCheck = (event) => { 
    let campaignId = campaign.id;
    if (!campaignId || campaignId === null) { return HWMessage.show ("Campaign ID is not provided or empty"); }
    HWMessage.show ("Changing campaign Visibility");
      return changeCampaignVisibilityStatus(campaignId, event.target.checked?"show":"hide");
  }


  return (
    <>
      { props.buttonType=="status" &&
        <FormControlLabel
          control = { isDraft (campaign) || isExpired(campaign) ? <></> : <IOSSwitch checked={ checked } onChange={ handleStatusCheck } name = 'check'  /> }
          label = { isDraft (campaign) ? 'Drafted' : isExpired(campaign) ? 'Expired' : ''  }
          labelPlacement = 'right'
          className = { classes.labelRoot }
      />}

      { props.buttonType != "status" &&
        <FormControlLabel
          control = { isDraft (campaign) || isExpired(campaign) ? 
                        <></> :  <IOSSwitch checked={ props.buttonType=="priorityStatus" ? priorityStatus : visibilityStatus} onChange={ props.buttonType=="priorityStatus" ? handlePriorityStatusCheck : handleVisibilityStatusCheck} name = 'check'  /> }
          label = ''
          labelPlacement = 'right'
          className = { classes.labelRoot }
      />}
    </>
      
  );
};  