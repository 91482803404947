import Card from 'components/Card/Card';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Button, ButtonBase, CardActions, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import BlockUi from 'react-block-ui';
import { DataGrid , GridToolbar} from '@material-ui/data-grid';
import { makeStyles, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardIcon from 'components/Card/CardIcon';
import { People } from '@material-ui/icons';
import pointsApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import SearchBar from 'views/Merchant/SearchBar';
import { ClipLoader } from 'react-spinners';
import { memberTransactionApiService } from 'views/Members/APIService';
import TransactionDetails from 'views/Transaction/TransactionDetail';
import { CustomToolbar } from 'views/Members/Member';


function TransactionDescription (props) {
     
    HWLocalStorage.save (2, "previousMemberTab");

    const [ transactionId, setTransactionId ] = useState(props && props.transactionId);
    const [ memberId, setMemberId ] = useState (props && props.memberId);
    const [ merchantId, setMerchantId ] = useState (props && props.merchantId);

    const [ transaction, setTransaction ] = useState (null);
    const [ points, setPoints ] = useState (null);
    const [ loading, setLoading ] = useState (false);
    const setOpen = props && props.setOpen;

    useEffect (() => {
        if (transactionId) {
            setLoading (true);
            memberTransactionApiService.fetchSpecificMemberTransaction({memberId, transactionId})
            .then (response => {
                if (! response || !response.data) { return HWMessage.show("Transaction cannot be fetched yet","error"); }
                setTransaction (response.data.transaction);
                setPoints (response.data.points);
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => { setLoading (false); });
        }
    }, [transactionId, memberId]);

    const handleClose = () => { setOpen(false); }
    
    return (
        <Dialog open = { props.open } onClose = { handleClose } style = {{ width : '800px', marginLeft : 400 }} >
            { loading && <center> <ClipLoader size = {55} /> </center> }
            { !loading && 
                <>
                    <DialogTitle> <center>  Transaction Details </center> </DialogTitle>
                    <DialogContent>''
                        <TransactionDetails transaction = { transaction } points = { points } from={"Points"} />
                    </DialogContent>
            
            <DialogActions>
                <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = {handleClose}> Close </Button>
            </DialogActions>
            </>
            }
        </Dialog>
    );
}; 


export default function PointsHistory (props) {
    HWLocalStorage.save (2, "previousPointsTab");
    if(HWLocalStorage.load("previousPointsRuleTab")!== null)
    {
        HWLocalStorage.clear("previousPointsRuleTab");
    }

    const [ openTransaction, setOpenTransaction ] = useState (null);
    const [ txnOpen, setTxnOpen ] = useState (false);
    const [ rows, setRows ] = useState ([]);
    const [ rowCount, setRowCount ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ page, setPage ] = useState (0);
    const [ filterModel, setFilterModel ] = useState ({});
    const [ sortModel, setSortModel ] = useState ({});
    const [ searchText, setSearchText ] = useState ('');
    const [ loading, setIsLoading ] = useState (false);
    const [ refreshState, setRefreshState ] = useState (false);
    const getTextColor = action => (action === 'minus' ? '#21781C' : '#D83653');

    const useStyles = makeStyles (theme => ({
        ...styles,
        bottomGrid : { marginTop : 10},
        root : {
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiCheckbox-root': { borderRadius: '30px', backgroundColor: 'rgba(0, 0, 0, 0, 0)' },
            '& .Mui-checked': {borderRadius: '3px', backgroundColor: 'rgba(0, 0, 0, 0, 0)', color: 'rgba(200, 0, 0, .1)'},
            '& .MuiDataGrid-rowCount' : { fontWeight : '100', value : (loading) ? 0 : rowCount },
            '& .MuiButton-label' : { color : '#138DE8' }    
        }
    }));
    
    const classes = useStyles();

    const columns = [
        { field: 'date', filterable: false ,  headerName: 'Date',minWidth:120,flex:1.5, renderCell : cellParams => { 
            var d = new Date(cellParams.row.transactionTime); 
            return (
            <Link>
                <Paper style = {{ backgroundColor : '#138DE8' }}> 
                    <GridContainer > 
                        <GridItem xs = {12} style = {{ marginBottom : '-32px', color : 'floralwhite' }} > <center> <b> { d.toLocaleDateString('default', { day : 'numeric' }) } </b>  </center>  </GridItem> 
                        <GridItem xs = {12} style = {{ color : 'floralwhite' }} > <center> <b>   { d.toLocaleDateString('default', { month : 'long' }) } </b> </center>  </GridItem> 
                    </GridContainer> 
                </Paper>
            </Link>); 
            }}, 
        { field: 'description', headerName: 'Description', minWidth:200,flex:3, renderCell : cellParams => {
            return <Link style = {{ color : '#138DE8' }} >  {  cellParams.row.description }  </Link>
        } },
        { field: 'pointsRequestRefNumber', headerName: 'Reference Number', minWidth:150,flex:2, renderCell : cellParams => {
            return <Link style = {{ color : '#138DE8' }} >  {  cellParams.row.pointsRequestRefNumber }  </Link>
        } },
        { field: 'amount', filterable :  false ,  headerName: 'Amount', minWidth:120,flex:1.5, renderCell : cellParams => {
            return cellParams.row.pointAction && <span style = {{ color : getTextColor (cellParams.row.pointAction) }} > <b> { cellParams.row.pointAction === 'plus' ? '-' : '+' } { cellParams.row.pointsRewarded || cellParams.row.pointsDeducted } </b> </span>
        } }
    ];

    useEffect (() => {
        setIsLoading (true);
        var paginationBookmark = ((page) * pageSize);
        var fetchLimit = pageSize;
        pointsApiService.fetchOpsTransactions ({fetchLimit, paginationBookmark, filterModel, sortModel, searchText})
        .then (response => {
            if (!response || !response.data)
                return console.log("No relevant data can be fetched");
           setRowCount (response.data.countTransactions);
           setRows (response.data.transactionList);
           HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() => { setIsLoading (false); 
            var my_element = document.getElementById("top");
            if(my_element!== null || my_element!== undefined || my_element!== '')
            {
                // my_element.scrollIntoView({ }); 

            }
        });
    }, [ page, pageSize, filterModel, sortModel, searchText, refreshState ]);

    function CustomLoadingOverlay() {
        return (
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
      }

    return (
        <>
            <BlockUi>
                <Card id="top">
                    <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                        <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        <h4 className = { classes.cardIconTitle } > 
                            Points History
                        </h4> 
                    </CardHeader>
                    <CardBody> 
                        <GridContainer style = {{ justifyContent : 'space-between' }} >
                                <GridItem>
                                    <SearchBar 
                                        width = '400px' 
                                        handleSearch = { setSearchText }
                                        handleRefresh = { setRefreshState }
                                        refreshState = { refreshState }
                                    /> 
                                </GridItem>
                        </GridContainer>
                        <GridContainer>
                        <GridItem className = { classes.bottomGrid } xs = {12} md = {12} >
                        <DataGrid 
                            className = { classes.root }
                            rows = { rows }
                            columns = { columns }
                            page = { page }
                            rowCount = { rowCount }
                            pageSize= { pageSize }
                            autoHeight
                            showToolbar
                            pagination
                            paginationMode = 'server'
                            onPageChange = { gridParams => setPage (gridParams) }
                            onPageSizeChange = { gridParams => { setPage (0); setPageSize (gridParams); } }
                            sortingMode="server" 
                            onSortModelChange = { params => { 
                              if (params.length > 0) 
                                  setSortModel (params[0]);
                            } }
                          
                            filterMode = 'server'
                            onFilterModelChange = { (params) => {
                              if (params.items.length > 0) {
                                setFilterModel (prev => ({ ...params.items[0] })); 
                              }
                            } }          
                            onRowClick = { rowDetails => {setOpenTransaction (rowDetails.row.id); setTxnOpen(true); } }
                            rowsPerPageOptions ={ [25, 50, 100 ]}
                            loading = { loading }
                            components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 

                        />
                        </GridItem>
                        </GridContainer>
                    </CardBody>
                    { txnOpen && openTransaction && <TransactionDescription open = { txnOpen } setOpen = { setTxnOpen } transactionId = { openTransaction } memberId = { "1231312" } creator = {'member'}  />} 
                </Card>
            </BlockUi>
        </>
    );
}