import React, { Component } from 'react'
import { IconButton, Snackbar } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import HWAlert from './HWAlert';
import Close from '@material-ui/icons/Close';
 

class HWMessage extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false }
    }

    static autoHideDuration = 6000; //milliseconds
    
    static show = (message, severity, timeInterval) => {
        HWMessage.autoHideDuration = timeInterval || HWMessage.autoHideDuration;
        window.postMessage(JSON.stringify({appInfoMessageToShow: message, severity}), window.origin);
    }

    componentDidMount() {
        window.addEventListener("message", this.onNewMessage, false);
    }

    onNewMessage = (messageEvent) => {
        if (messageEvent.origin !== window.origin) return;
        try {
            let platformMessage = JSON.parse(messageEvent.data);
            if (platformMessage.appInfoMessageToShow) {
                this.setState({ show: true, message: platformMessage.appInfoMessageToShow, severity : platformMessage.severity });
            }
        } catch (error) { }
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.onNewMessage, false);
    };
    onClose = () => { this.setState({show: false , message: "" }); }

    render() {
        return(
            <Snackbar anchorOrigin = {{ vertical: 'top', horizontal: 'center', }} open = {this.state.show}
             autoHideDuration = {HWMessage.autoHideDuration || 6000} onClose={this.onClose}
             TransitionComponent = {Grow}
             message = {this.state.message}
             action = {
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={this.onClose}>
                  <Close fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          >
              <HWAlert severity = { this.state.severity || "info"} onClose = { this.onClose } > { this.state.message } </HWAlert>
          </Snackbar>)
    }

    
}

export default HWMessage;