import React from "react"
import TransactionCell from "./TransactionCell"
import TransactionHeaderCell from "./TransactionHeaderCell"

const TransactionDetails = (props) => {
    let aTransactionDetails = props.aTransactionDetails ? props.aTransactionDetails : null
    return (
        <>
       {aTransactionDetails && <div style = {{width: "30rem"}}>
       <TransactionCell amount = {aTransactionDetails.amount} transactionType = {aTransactionDetails.transactionType}  isHeaderCell = {true}/>
        <TransactionCell title = {"Purchased On"} data = {aTransactionDetails.date}/>
        <TransactionCell title = {"Summary"} data = {aTransactionDetails.description}/>
        <TransactionCell title = {"Payment"} data = {"RM " + aTransactionDetails.amount}/>
        <TransactionCell title = {"Card Used"} data = {aTransactionDetails.cardNumber}/>
        <TransactionCell title = {"Account Id"} data = {aTransactionDetails.accountId}/>
        </div>}
        </>
    )
}

export default TransactionDetails;