import axios from 'axios';
import appConfig from '../../HWLibrary/HWConfig';
import authService from 'HWLibrary/ApiService';
import HWMessage from 'HWLibrary/HWMessage';



class NotificationAPIService {

    constructor () {
        if (!NotificationAPIService.instance) 
        NotificationAPIService.instance = this;
        return NotificationAPIService.instance;
    }

    defaultAuthConfig = () => { 
        const accessToken = authService.getCachedAccessToken();
        return { headers : { Authorization : `Bearer ${accessToken}` } };
    };


    fetchS3PresignedUrl = (name) => {
        const url = `${ appConfig.serverBaseUrl }/presigned-url/notification-images`;
        const config = { ...this.defaultAuthConfig() , params : { fileName : name } };
        return authService.axiosService().get (url, config);
    
      }
      uploadImage = (fileName) => {
        const url = `${ appConfig.serverBaseUrl }/presigned-url/notification-images`;
        const config = { params : { fileName: fileName } };
        return authService.axiosService().get (url, config);
      };
    
    uploadFile(fileToUpload, presignedUrl) {
        let config = { headers: { "content-type": fileToUpload.type } }
        return axios.put(presignedUrl, fileToUpload, config);
      }

      deleteNotification = rule => {
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/ops/generic-notifications/${ rule.id }/remove `;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().post (url, request, config);
      };

      createNotification = (rule) => {
        const url = `${ appConfig.serverBaseUrl }/ops/generic-notifications`;
        const config = { ...this.defaultAuthConfig() };
        const request = { ...rule };
  
        return authService.axiosService ().post (url, request, config);
      };

     

      updateNotification = rule => {
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/ops/generic-notifications/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

      fetchNotifications = (request) => {

        var fetchLimit, paginationBookmark, fetchType, requestType;
        if (request) {  
            if (request.fetchType != null) { fetchType = request.fetchType }
            if (request.requestType != null) { requestType = request.requestType; }
            if (request.fetchLimit != null) { fetchLimit = request.fetchLimit; }
            if (request.paginationBookmark != null) { paginationBookmark = request.paginationBookmark; }
        }

        var searchField, searchText, searchType;
        if (request && request.filterModel) {
            searchField = request.filterModel.columnField;
            searchText = request.filterModel.value;
            searchType = request.filterModel.operatorValue;
           
        } 
        if (request && request.searchText)  { searchText = request.searchText;} 
        
        var sortField, sortOrder = null;
        if (request && request.sortModel) {
            sortField = request.sortModel.field;
            sortOrder = request.sortModel.sort;
            sortOrder = (sortOrder === 'asc') ? 'Ascending' : 'Descending';
        }
    
        const queryParams = (request) ? { params : { fetchType, requestType, fetchLimit, paginationBookmark, searchText, searchField, sortField, sortOrder, searchType } } : {};
        const url = `${ appConfig.serverBaseUrl }/ops/notifications`;
        const config = { ...this.defaultAuthConfig(), ...queryParams };
        
        return authService.axiosService ().get (url, config);
      };

      fetchSpecificNotification = (rule) => {
        console.log("asdasdasd",rule.id);
        const url = `${ appConfig.serverBaseUrl }/ops/generic-notifications/${ rule.id}`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
       
      }
  
  };


const notificationAPIService = new NotificationAPIService();
export default notificationAPIService;
