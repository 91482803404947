import { AppBar, makeStyles, Tab, Tabs } from '@material-ui/core';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import React, { useState } from 'react';
import { Fragment } from 'react';
import VoucherManagement from './VoucherManagement';
import VoucherRule from './VoucherRule';
import VoucherHistory from './VoucherHistory';


const useStyles = makeStyles (theme => ({
    root : {
        overflow: 'hidden',
        minHeight: 48,
        WebkitOverflowScrolling: 'touch', 
        display: 'flex',
        backgroundColor: "white",
        '& .MuiTabs-indicator' : { backgroundColor : '#FF9017' },
        "& .MuiButtonBase-root": {textTransform: "none"},
        color: 'black',
        borderRadius: '6px'
    }
}));

export default function Vouchers (props) {

    const classes = useStyles();
    if (HWLocalStorage.load ("previousCardTab") !== null ) 
      HWLocalStorage.clear("previousCardTab");
    if(HWLocalStorage.load ("previousVouchersRuleTab") !== null)
      HWLocalStorage.clear("previousVouchersRuleTab");
    if (! HWLocalStorage.load ("previousVouchersTab") ) HWLocalStorage.save (0, "previousVouchersTab");
    if(HWLocalStorage.load("previousComSettingsTab")!== null){
        HWLocalStorage.clear("previousComSettingsTab")
    }
    if(HWLocalStorage.load("previousRolesSettingsTab")!== null){
        HWLocalStorage.clear("previousRolesSettingsTab")
    }

    if(HWLocalStorage.load("previousSettingsTab")!== null){
        HWLocalStorage.clear("previousSettingsTab")
    }
    if(HWLocalStorage.load("previousMemberTab")!== null){
        HWLocalStorage.clear("previousMemberTab")
    }
    if(HWLocalStorage.load("previousActionsTab")!== null){
        HWLocalStorage.clear("previousActionsTab")
    }
    if(HWLocalStorage.load("previousMerchantDetailsTab")!==null)
    {
        HWLocalStorage.clear("previousMerchantDetailsTab");
    }

    if(HWLocalStorage.load("previousMerchantTab")!==null)
    {
        HWLocalStorage.clear("previousMerchantTab");
    }
    if(HWLocalStorage.load("previousPointsRuleTab")!== null)
    {
        HWLocalStorage.clear("previousPointsRuleTab");
    }
    if(HWLocalStorage.load("previousPointsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsTab");
    }
    if(HWLocalStorage.load("previousActionsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsTab");
    }


   
    if(HWLocalStorage.load("previousActionsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsTab");
    }
    if(HWLocalStorage.load("previousPointsActionsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsActionsTab");
    }

    
    const [currentTab, setCurrentTab ]= useState (JSON.parse(HWLocalStorage.load ("previousVouchersTab")) || 0);;
    const changeTab = (event, idx) => { setCurrentTab (idx); }

    return (
        <Fragment>
                <Tabs variant = 'scrollable' className = { classes.root } value = { currentTab } onChange = { changeTab } >
                    <Tab label = "Voucher Management" />
                    <Tab label = "Voucher Rule" />
                    <Tab label = "Voucher History" />
                </Tabs>
            <div style={{ padding: "15px 0px 30px 0px" }}>{
                {
                    0 : <VoucherManagement />,
                    1 : <VoucherRule />,
                    2 : <VoucherHistory />
                } [ currentTab ]
            }
            </div>
         </Fragment>
    );
    
};