import { Table, TableContainer, TableHead, TableRow, Paper, TableCell, 
    TableBody, makeStyles, TextField, Fab, Button, IconButton, InputAdornment,
    Dialog, DialogContent } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import GridContainer from 'components/Grid/GridContainer';
import HWMessage from 'HWLibrary/HWMessage';
import React, { useState, useEffect, Fragment , useCallback } from 'react';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { Delete, Edit, People } from '@material-ui/icons';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import BikeModels from './BikeModels';
import pointsApiService from './APIService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import BlockUi from 'react-block-ui';
import { createNew } from 'typescript';
import './Points.css';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';


const useStyles = makeStyles({
    ...styles,
    table: { minWidth: 650 },
    textField : { textAlign : 'center' },
    editIcon :  { color : '#138DE8' },
    bikeModelLink : { textDecoration : 'underline', fontWeight : 500 },
    saveButton : { color : 'floralwhite', backgroundColor : '#138DE8' },
    cancelButton: { color: 'floralwhite', backgroundColor: '#FF9017', height: 45, width : 250 , margin : 2.5 },
});

function ModelName (props) {

    const handleClose = event => {
        props.setOpen (false);
    }

    return (
        <Dialog open = { props.open } onClose = { handleClose } >
            <DialogContent>
                <BikeModels />
            </DialogContent>
        </Dialog>
    );
}

export default function BonusPoints (props) {
    HWLocalStorage.save(2,"previousPointsRuleTab");


    var point=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    point= [...obj["Points"]];


    const classes = useStyles();

    const [ rows, setRows ] = useState ([]);
    const [ createNewRow, setCreateNewRow ] = useState (false);
    const [ newRow, setNewRow  ] = useState ({  });
    const [ modelOpen, setModelOpen ] = useState (false);
    const [ edit, setEdit ] = useState (false);
    const [ isLoading, setIsLoading ] = useState (false);
    const [ updateLoading,setUpdateLoading ]  = useState(false);
    const [ prevRows, setPrevRows ] = useState ([]);
    const [ editOpen, setEditOpen ] = useState (false);
    const [ refreshState, setRefreshState ] = useState (false);
    const [ deleteOpen, setDeleteOpen ] = useState (false);
    const [ selectedRuleId, setSelectedRuleId ] = useState (null);

    
    const columns = [
        { headerName : "Category", editable : true, field : 'category' },
        { headerName : "CC Range", editable : true, field : 'ccRange'},
        { headerName : "Points",   editable : true, field : 'points' },
        { headerName : "Register Within", editable : true, field : 'registerWithin' }
    ]; 


    const [ currentRule , setCurrentRule] = useState();
    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});
    const [ isValid, setIsValid ] = useState (true);
    const [ dialogOpen,setDialogOpen ] = useState (false);
    const [ editCell, setEditCell ] = useState (-1);

    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    };
    

   useEffect (() => {
        setIsLoading (true);
        pointsApiService.fetchBonusPoints().then (response => {
            if (!response || !response.data)
             {      HWMessage.show("Data can;'t be fetched" ,"error");
                    return console.log("No relevant data can be fetched");}
            setRows(response.data);     
            setRows (prev => { prev.sort ((a, b) => (a.ccLowerLimit <= b.ccLowerLimit) ? -1 : 1); return [ ...prev ] });
            setPrevRows (response.data);
            HWLocalStorage.save (JSON.stringify(response.data), "bonusPoints");
            HWMessage.show("Request Successful" , "success");
          })
        .catch (error => { console.log("Error while fetching data", error); })
        .finally (() => { setIsLoading (false); });
       
    }, [ refreshState ]);

    const validateFieldValue = (field, value, index) => {
        const fieldWithIndex = `${ field }${ index }`;
        switch (field) {
            case 'category' :
                let arrayBycategory = rows.filter(i => i.category === value);
                if (!value ||  value && value === '' || arrayBycategory.length > 0 ) { 
                    setHasError ({ ...hasError, [fieldWithIndex] : true }); 
                    setErrorMessage ({ ...errorMessage, [fieldWithIndex] : 'Invalid Category' });
                    return false;                 
                }
            case 'ccLowerLimit' : 
                if (value === undefined || value && value === null || value < 0 ) { 
                    setHasError ({ ...hasError,  [fieldWithIndex] : true }); 
                    setErrorMessage ({ ...errorMessage,  [fieldWithIndex] : 'Invalid Lower Limit' });
                    return false;                 
                }    
                break;        
            case 'ccUpperLimit' : 
                if (!value ||  value && value === '' || value <= 0 ) { 
                    setHasError ({ ...hasError, [fieldWithIndex] : true }); 
                    setErrorMessage ({ ...errorMessage,  [fieldWithIndex] : 'Invalid Upper Limit' });
                    return false;                 
                }
            case 'points' : 
                if (!value ||  value && value === '' || value<=0 ) { 
                    setHasError ({ ...hasError,  [fieldWithIndex] : true }); 
                    setErrorMessage ({ ...errorMessage,  [fieldWithIndex] : 'Invalid Points' });
                    return false;                 
                }
            case 'registerWithinPeriod' : 
                if (!value ||  value && value === '' || value <= 0 ) { 
                    setHasError ({ ...hasError,  [fieldWithIndex] : true }); 
                    setErrorMessage ({ ...errorMessage,  [fieldWithIndex] : 'Invalid Reigstration Period' });
                    return false;                 
                }
        }
        setHasError({ ...hasError,  [fieldWithIndex] : false }); 
        setErrorMessage ({ ...errorMessage,  [fieldWithIndex] : null });
        return true;
    };

    const captureTextFieldChange = (e, idx) => {
        const field = e.target.name;
        const value = e.target.value;
        let check = validateFieldValue (field, value, idx);
        setIsValid (check);

        const getMonthValue = value => {
            var parsed = parseInt (value);
            if (isNaN (parsed)) 
                return 0;
            else 
                return parsed;
        }


        switch (field) {
            case 'category' :       setRows(prev => { prev[idx].categoryName = value; return [ ...prev ]; }) ; break;
            case 'ccLowerLimit' :  setRows(prev => { prev[idx].ccLowerLimit = parseInt(value); return [ ...prev ]; }); break;
            case 'ccUpperLimit' :   setRows(prev => { prev[idx].ccUpperLimit = parseInt(value); return [ ...prev ]; }); break;
            case 'points' :  setRows(prev => { let t = [ ...prev ]; let object = {  ...t[idx] }; object.pointsRewarded = parseInt(value); t[idx] = object; return [ ...t ]; }); break;
            case 'registerWithinPeriod' : setRows(prev => { prev[idx].registerWithinPeriod = { ...prev[idx].registerWithinPeriod, months: getMonthValue(value) }; return [ ...prev ]; }); break;
        }
    };

    const preiodSerializer = period => {
        // Converting period to String
        if (period === null) { return ""; }
        let result = "P";
        if (period.years && period.years !== null) { result += `${ period.years }Y` };
        if (period.months && period.months !== null) { result += `${ period.months }M` };
        if (period.days && period.days !== null) { result += `${ period.days }D` };

        return result;
    };
    const callDeleteRule = useCallback (idx => { 
        setUpdateLoading (true);
        let successState = null;
        
        pointsApiService.callDeleteRule(idx)
        .then (response => { 
            if (!response || !response.data) {
                HWMessage.show ("Bonus Points cannot be deleted","error");
                return console.log("Error while updating Bonus Points");
            }
            HWMessage.show("CurrentRule deleted successfully","success");
            successState = true;
            setRefreshState(!refreshState);
        }).catch (error => { 
            
            HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            successState = false;
        }).finally (() => { 
            setUpdateLoading (false); 
            setDialogOpen (false);  
            if (successState === true) { setEdit(false); setEditCell (-1); }
        });
    });

    

    const saveRule = useCallback (idx => { 
        setUpdateLoading (true);
        let successState = null;
        
        let updateRule = { ...rows[idx] };
        updateRule.registerWithinPeriod = preiodSerializer (rows[idx].registerWithinPeriod);
        pointsApiService.updateBonusPointsRule(updateRule)
        .then (response => { 
            if (!response || !response.data) {
                HWMessage.show ("Bonus Points cannot be updated","error");
                return console.log("Error while updating Bonus Points");
            }
            HWMessage.show("CurrentRule updated successfully","success");
            setRows (prev => { prev[idx] = { ...response.data}; return [ ...prev ] });
            setPrevRows (prev => { prev[idx] = { ...response.data }; return [ ...prev ];  });
            successState = true;
        }).catch (error => { 
            
            HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            successState = false;
            HWMessage.show("Request Successful" , "success");
        }).finally (() => { 
            setUpdateLoading (false); 
            setDialogOpen (false);  
            if (successState === true) { setEdit(false); setEditCell (-1); }
        });
    });

    const createRule = useCallback (rule => {
        setUpdateLoading (true);
        let successStatus = null;

        let savingRule= { ...rule };

        savingRule.registerWithinPeriod = preiodSerializer (rule.registerWithinPeriod);
        pointsApiService.createBonusPointsRule (savingRule)
        .then (response => { 
            if (! response || ! response.data) { return HWMessage.show ("Error while fetching the creation status","error"); }
            HWMessage.show ("Created Successfully","success");
            setRows (prev => { prev[ rows.length - 1 ] = response.data; return [ ...prev ] });
            successStatus = true;
            HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        successStatus = false; })
        .finally (() => {
            setUpdateLoading (false);
            if (successStatus === true) {
                setCreateNewRow (false);
                setEditCell (-1);
            }
                
        });
    });

    const addNewCategory = () => {
        if (createNewRow === true) { HWMessage.show ("Please save one or more categories which are pending","error"); }
        else {   setCreateNewRow (true); setNewRow ({}); rows.push (newRow); setEditCell (rows.length - 1); }
    };

    const handleCellEdit = idx => { setEditCell (idx);};


    const validateBeforeSave = (idx) => {
        
        const fieldNames = [ 'category' , 'ccLowerLimit' , 'ccUpperLimit' , 'points', 'registerWithinPeriod' ];

        for (let i = 0; i < fieldNames.length; i++) {
            let field = fieldNames[i], parsedFieldName;
            parsedFieldName = (field === 'category') ? 'categoryName' : (field === 'points') ? 'pointsRewarded' : field;
            let value = rows[idx][parsedFieldName];
           // alert(value);
           
            var res = validateFieldValue(field , value, idx);
            if (res === false) { return false; }
        }
        return true;
    }

    const handleSave = (idx) => { 
         var res = validateBeforeSave(idx);
        if (res === true) {
            if (createNewRow === true) { if (editCell === rows.length - 1)  createRule (rows[ rows.length - 1 ]); } 
            else if (editCell !== -1) { saveRule(idx); setEditCell (-1); };
        }
        else{
            HWMessage.show("Please Fill all the entries.","error");
        }
        
    }
        
    const handleCreate = () => {  };
    const handleCancel = () => { 
        if (createNewRow  === true) { 
            setRows (prev => { prev.pop(); return [ ...prev ] }); 
            setCreateNewRow (false); 
            setEditCell (-1); 


            // Error Clearning
            const fieldNames = [ 'category' , 'ccLowerLimit' , 'ccUpperLimit' , 'points' , 'registerWithinPeriod']
            for (let i = 0; i < fieldNames.length; i++) {
                let field = fieldNames[i];
                let fieldWithIndex = `${ field }${ rows.length - 1 }`;

                setHasError({ ...hasError,  [fieldWithIndex] : false }); 
                setErrorMessage ({ ...errorMessage,  [fieldWithIndex] : null });
            }    

        } 
        else  { setRows (prev => prevRows); setEditCell (-1);  }
    };
    const handleEdit = e => { setEdit (!edit); };
    const handleBikeModelClick = e => { setModelOpen (true) };

    return (
        <Fragment>
            <BlockUi blocking = { isLoading } message = { 'Please wait while we fetch.' } >
                <Card>
                    <CardHeader className = { classes.cardHeader } color = 'rose' icon >s
                        <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                            <h4 className = { classes.cardIconTitle } > 
                                Bike Categories   
                                {/* <IconButton onClick = { handleEdit } style = {{ paddingTop : 0, paddingBottom : 0,  }} > <Edit className = { classes.editIcon } /> </IconButton> */}
                                <span className = { classes.bikeModelLink } style = {{ color : '#138DE8', position: "absolute",
    left: 600 , cursor : 'pointer' }} onClick = { handleBikeModelClick } >  Maintain Bike Models </span >  
                                { !createNewRow &&  point.includes("Action") === true && <Fab variant = 'extended' aria-label="add" style = {{ float : 'right', marginTop : -20, background : '#FF9017', color : 'white' }} onClick = { addNewCategory }  > <AddIcon /> Add Rule </Fab>}

                            </h4> 
                    </CardHeader>     
                    <CardBody>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((i) => ( <TableCell align="center" style = {{ color : '#138DE8', fontWeight : 500, fontSize : 18 }} > { i.headerName } </TableCell> ))}
                                        <TableCell align="center" style = {{ color : '#138DE8', fontWeight : 500, fontSize : 18 }} > Edit </TableCell>
                                        <TableCell align="center" style = {{ color : '#138DE8', fontWeight : 500, fontSize : 18 }} > Delete </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, idx) => (
                                        <TableRow key={row.id}>
                                            <TableCell align = 'center'> 
                                                { (createNewRow === true && idx === rows.length - 1) && <TextField variant = 'outlined' value = { row.categoryName } error={ checkError (`category${ idx }`) } helperText = { fetchErrorMessage (`category${ idx }`) }  onChange = { e => captureTextFieldChange(e, idx) } name = "category"  inputProps={{ style: {textAlign: 'center', height: 7, width : 65 } }} variant = 'outlined' /> || 
                                                <span> { row.categoryName } </span> }   
                                            </TableCell>
                                            <TableCell align = 'center'> 
                                                {( (createNewRow === true && idx === rows.length - 1) || (editCell === idx)) && <TextField variant = 'outlined' type='number' value = { row.ccLowerLimit } onChange = { e => captureTextFieldChange (e, idx) } name = "ccLowerLimit" error={ checkError (`ccLowerLimit${ idx }`) } helperText = { fetchErrorMessage (`ccLowerLimit${ idx }`) } style = {{ width : 90 }} inputProps={{ style: {textAlign: 'center',height: 7 } }} /> || 
                                                <span> { row.ccLowerLimit } </span> }      - 
                                                { ((createNewRow === true && idx === rows.length - 1) || (editCell === idx )) && <TextField variant = 'outlined' type='number' value = { row.ccUpperLimit } onChange = { e => captureTextFieldChange (e, idx) } name = "ccUpperLimit" error={ checkError (`ccUpperLimit${ idx }`) } helperText = { fetchErrorMessage (`ccUpperLimit${ idx }`) } style = {{ width : 90 }} inputProps={{ style: {textAlign: 'center',height: 7 } }} /> || 
                                                <span> { row.ccUpperLimit } </span> }    
                                            </TableCell>
                                            <TableCell align = 'center'> 
                                                { (createNewRow === true && idx === rows.length - 1) && <TextField variant = 'outlined' type='number' value = { rows[idx].pointsRewarded } name = "points" error={ checkError (`points${ idx }`) } helperText = { fetchErrorMessage (`points${ idx }`) } onChange = { e => captureTextFieldChange (e, idx) } inputProps={{ style: {textAlign: 'center',height: 7 } }} /> || 
                                                (editCell === idx && <TextField type='number' value = { row.pointsRewarded } variant = 'outlined' name = "points" error={ checkError (`points${ idx }`) } helperText = { fetchErrorMessage (`points${ idx }`) } onChange = { e => captureTextFieldChange (e, idx) } inputProps={{ style: {textAlign: 'center',height: 7 } }} /> ) || 
                                                (edit === false && <span> { row.pointsRewarded } </span>) }   </TableCell>
                                            
                                            <TableCell align = 'center'> 
                                                { (createNewRow === true && idx === rows.length - 1) && <TextField variant = 'outlined' type='number' value = { row.registerWithinPeriod && row.registerWithinPeriod.months } name = "registerWithinPeriod" error={ checkError (`registerWithinPeriod${ idx }`) } helperText = { fetchErrorMessage (`registerWithinPeriod${ idx }`) } onChange = { e => captureTextFieldChange (e, idx) } style = {{ width : 150 }} inputProps={{ style: {textAlign: 'center',height: 7 } }} InputProps={{ endAdornment : <InputAdornment position="end"> month(s) </InputAdornment> }} /> || 
                                                (editCell === idx && <TextField value = { row.registerWithinPeriod && row.registerWithinPeriod.months } name = "registerWithinPeriod" error={ checkError (`registerWithinPeriod${ idx }`) } helperText = { fetchErrorMessage (`registerWithinPeriod${ idx }`) } onChange = { e => captureTextFieldChange (e, idx) } style = {{ width : 150 }} inputProps={{ style: {textAlign: 'center',height: 7 } }} InputProps={{ endAdornment : <InputAdornment position="end"> month(s) </InputAdornment> }} /> ) || 
                                                (edit === false && <span> {  row.registerWithinPeriod && row.registerWithinPeriod.months } month(s) </span>) }    
                                            </TableCell> 
                                            { <TableCell align = "center">
                                                {/* { (createNewRow === true && idx === rows.length - 1) && <Button variant = 'contained' raised className = { classes.saveButton } onClick = { handleCreate } > Add Rule </Button>  }
                                                { edit === true && <Button variant = 'contained' raised className = { classes.saveButton } onClick = { e => handleSave(idx) } > Save Rule </Button> }  */}
                                               { (editCell === idx)   ? <> <IconButton  onClick = { e => setEditOpen (true) }  > <SaveIcon style = {{ color: '#138DE8' }} /> </IconButton > <ConfirmationDialog confirmingAction = { e => handleSave(idx) } dialogTitle = { 'Confirm Save' } dialogMessage = { 'Are you sure you want to update the rule' } open = { editOpen } setOpen = { setEditOpen } /> </> :  <IconButton disabled={point.includes("Action") === true ? false : true} onClick = {  e => handleCellEdit(idx)  } > <EditIcon  className = { classes.editIcon } /> </IconButton> } 
                                                
                                            </TableCell>}
                                            
                                            <TableCell align = 'center'> 
                                                <IconButton onClick = {e => {setDeleteOpen(true) ; setSelectedRuleId(row.id)}}> <Delete style={{color: '#FF9017' }} className = { classes.actionIcon } />  </IconButton>
                                            </TableCell> 
                                        </TableRow>
                                    ))}
                                </TableBody>
                                
                            </Table>
                        </TableContainer>
                    </CardBody>     
                    <CardFooter >
                        <GridContainer > 
                            <GridItem xs = {12} md = {12} sm = {12} >  
                                <center> 
                                 { (editCell !== -1 || createNewRow === true)   && <Button  raised variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { handleCancel }> Cancel </Button>  }
                                 {/*  editCell !== -1 && createNewRow === true && <Button variant = 'contained' raised className = { classes.cancelButton } onClick = { handleCreate }> Add </Button> */ }

                                </center>
                            </GridItem>
                        </GridContainer>
                    </CardFooter>              
                </Card>
                </BlockUi>
                <ConfirmationDialog 
                    dialogTitle = { 'Confirm Bonus Point Rule Deletion' } 
                    dialogMessage = { 'Are you sure you want to delete the bonus point rule.' } 
                    confirmingAction = { e => callDeleteRule(selectedRuleId) } open = { deleteOpen } setOpen = { setDeleteOpen } 
                /> 
            <ModelName open = { modelOpen }  setOpen = { setModelOpen } />

        </Fragment>
    );
};