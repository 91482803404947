import GridItem from 'components/Grid/GridItem';
import React, { useState, useEffect, useCallback } from 'react';
import { Fragment } from 'react';

import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import GridContainer from 'components/Grid/GridContainer';
import { Button, ButtonBase, Card, CardActions, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Paper, TextField } from '@material-ui/core';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';

import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { DataGrid } from '@material-ui/data-grid';
import CardBody from 'components/Card/CardBody';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import PointTransactions from 'views/Points/PointTransactions';
import memberApiService, { memberPointsApiService, memberTransactionApiService } from './APIService';
import { ClipLoader } from 'react-spinners';
import { HWInput } from 'HWLibrary/HWInput';
import TransactionDetails from 'views/Transaction/TransactionDetail';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import { Link, useHistory } from 'react-router-dom';
import HWMessage from 'HWLibrary/HWMessage';




const useStyles = makeStyles( theme =>  ({
    ...styles,
    root : {  
        '& .MuiDataGrid-columnHeaderTitle': {  color : 'primary', fontFamily : 'roboto', fontWeight : 200 }
    }})
);

function TransactionDescription (props) {
     
    HWLocalStorage.save (2, "previousMemberTab");

    const [ transactionId, setTransactionId ] = useState(props && props.transactionId);
    const [ memberId, setMemberId ] = useState (props && props.memberId);
    const [ merchantId, setMerchantId ] = useState (props && props.merchantId);

    const [ transaction, setTransaction ] = useState (null);
    const [ points, setPoints ] = useState (null);
    const [ loading, setLoading ] = useState (false);
    const setOpen = props && props.setOpen;

    useEffect (() => {
        if (transactionId) {
            setLoading (true);
            memberTransactionApiService.fetchSpecificMemberTransaction({memberId, transactionId})
            .then (response => {
                if (! response || !response.data) { return HWMessage.show("Transaction cannot be fetched yet","error"); }
                setTransaction (response.data.transaction);
                setPoints (response.data.points);
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => { setLoading (false); });
        }
    }, [transactionId, memberId]);

    const handleClose = () => { setOpen(false); }
    
    return (
        <Dialog open = { props.open } onClose = { handleClose } style = {{ width : '800px', marginLeft : 400 }} >
            { loading && <center> <ClipLoader size = {55} /> </center> }
            { !loading && 
                <>
                    <DialogTitle> <center>  Transaction Details </center> </DialogTitle>
                    <DialogContent>
                        <TransactionDetails transaction = { transaction } points = { points } from={"Transactions"}/>
                    </DialogContent>
            
            <DialogActions>
                <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = {handleClose}> Close </Button>
            </DialogActions>
            </>
            }
        </Dialog>
    );
}; 

export default function MemberTransactions (props) {
    var members=[]
  var obj = JSON.parse(HWLocalStorage.load("Permissions"))
  members= [...obj["Members"]];


    const [ memberId, setMemberId ] = useState (props && props.location && props.location.params && props.location.params.state && props.location.params.state.id || JSON.parse(HWLocalStorage.load("memberStore")));
    const [ txnSortModel, setTxnSortModel ] = useState (null);
    const [ txnFilterModel, setTxnFilterModel ] = useState (null);
    const [ txnPageSize, setTxnPageSize ] = useState (100);
    const [ txnPage, setTxnPage ] = useState (0);
    const [ txnRefreshState, setTxnRefreshState ] = useState(0);
    const [ txnLoading, setTxnLoading ] = useState (false);
    const [ txnList, setTxnList ] = useState ([]);
    const [ txnRowCount, setTxnRowCount ] = useState (0);


    const [ ptsSortModel, setPtsSortModel ] = useState (null);
    const [ ptsFilterModel, setPtsFilterModel ] = useState (null);
    const [ ptsPageSize, setPtsPageSize ] = useState (100);
    const [ ptsPage, setPtsPage ] = useState (0);
    const [ ptsRefreshState, setPtsRefreshState ] = useState(0);
    const [ ptsLoading, setPtsLoading ] = useState (false);
    const [ ptsList, setPtsList ] = useState ([]);
    const [ ptsRowCount, setPtsRowCount ] = useState (0);
    const [ totalPoints, setTotalPoints ] = useState (0) ;

    const [ openTransaction, setOpenTransaction ] = useState (null);
    const [ txnOpen, setTxnOpen ] = useState (false);
    const [ member, setMember ] = useState (null);
    const [ loading, setLoading ] = useState (false);

    if (props && props.location && props.location.params && props.location.params.state && props.location.params.state.id) {
        var value = props && props.location && props.location.params && props.location.params.state && props.location.params.state.id;
        HWLocalStorage.save(JSON.stringify(value), "memberStore");
    } 

    useEffect (() => {
        if (memberId) {
            setLoading (true);
            memberApiService.fetchMemberDetails (memberId) 
            .then (response => {  
                if (! response || ! response.data) { return HWMessage.show ("Member Details cannot not be fetched"); }
                setMember (response.data);
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { 
                const message = error && error.response && error.response.data && error.response.data.message || "Error while fetching details";
                return HWMessage.show (message);
            })
            .finally (() => { setLoading (false) });
        }
    }, [ memberId ]);

    useEffect (() => {
        if (memberId) {
            setTxnLoading (true);
            var paginationBookmark = (txnPage) * ptsPageSize;
            memberTransactionApiService.fetchMemberTransactions({ memberId : memberId, sortModel : txnSortModel, filterModel : txnFilterModel, fetchLimit : txnPageSize, paginationBookmark : paginationBookmark })
            .then(response => {  
                if (! response || ! response.data) { return console.log("Error while fetching the transactions for this member"); }
                console.log("Transaction List", response);
                setTxnRowCount (response.data.countTransactions);
                setTxnList(response.data.transactionList);
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { console.log("Error while fetching transactions ", error); })
            .finally (() => { setTxnLoading (false); })
        }
    }, [ memberId, txnSortModel, txnFilterModel, txnPageSize, txnPage, txnRefreshState ]);


    useEffect (() => {
        if (memberId) {
            setPtsLoading (true);
            var paginationBookmark = (ptsPage) * ptsPageSize;
            memberPointsApiService.fetchMemberPoints({ memberId : memberId, sortModel : ptsSortModel, filterModel : ptsFilterModel, fetchLimit : ptsPageSize, paginationBookmark  } )
            .then (response => { 
                if ( !response || !response.data ) { return console.log("Error while fetching the points "); }
                setPtsRowCount (response.data.pointCount);
                setTotalPoints (response.data.totalPoints);
                setPtsList (response.data.points);
                HWMessage.show("Request Successful" , "success");
             })
            .catch (error => { console.log("Error while fetching points", error); })
            .finally (() => { setPtsLoading (false); });
        }
    }, [ memberId, ptsSortModel, ptsFilterModel, ptsPageSize, ptsPage, ptsRefreshState ]);

    const fetchPoints = cellParams => {
        switch (cellParams.row.transactionType) {
            case 'RegularSale': return cellParams.row.pointsRewarded;
            case 'CampaignSale': return cellParams.row.pointsRewarded;
            case 'CheckIn': return cellParams.row.pointsRewarded;
            case 'OpsAward': return cellParams.row.pointsRewarded;
            case 'OpsDeduct': return cellParams.row.pointsDeducted || cellParams.row.pointsRewarded;
            case 'BonusPoints': return cellParams.row.pointsRewarded;
            case 'GenBluStore': return cellParams.row.pointsRewared;
            case 'MerchantAward':
            case 'POSAward': return cellParams.row.pointsRewarded;
            case 'POSDiscount':
            case 'WooCommerceSale': return cellParams.row.pointsSpent;
            case 'PointsExpired': return cellParams.row.pointsDeducted;
        }
    };

    const columns = [ 
        { field : 'transactionTime', headerName : 'Date', minWidth:120,flex:0.8,
        renderCell : cellParams => { 
            
            var d = new Date(cellParams.value); 
            return (
            <Link>
                <Paper style = {{ backgroundColor : '#138DE8' }}> 
                    <GridContainer > 
                        <GridItem xs = {12} style = {{ marginBottom : '-32px', color : 'floralwhite' }} > <center> <b> { d.toLocaleDateString('default', { day : 'numeric' }) } </b>  </center>  </GridItem> 
                        <GridItem xs = {12} style = {{ color : 'floralwhite' }} > <center> <b>   { d.toLocaleDateString('default', { month : 'long' }) } </b> </center>  </GridItem> 
                    </GridContainer> 
                </Paper>
            </Link>); 
            }}, 
        { field : 'description', headerName : 'Description', minWidth : 200,flex:1.5, filterable : false, renderCell : cellParams => {
            return (
            <div >
               <Link style = {{ width : '220px', textOverflow : 'ellipsis', color : '#138DE8',  fontWeight : 200}} > {cellParams.value} </Link> 
            </div>
            )
        } }, 
        { field : 'points', headerName : 'Points',minWidth : 80,flex:1, renderCell : (cellParams) => ( <div style = {{ marginLeft : '45px', fontSize : '15px' }}>  <b> <center>   <span > <Link style = {{ color : cellParams.row.pointAction === 'plus' ? 'green' : 'red' }}  >  { `${(cellParams.row.pointAction === 'plus' ? '+' : '-') } ${ fetchPoints (cellParams) }` } </Link> </span> </center> </b>  </div> ) }
    ];

    const pointsColumns = [ 
        { field : 'allocationDate', headerName : 'Allocation Date', minWidth:120,flex:1, renderCell : cellParams => {  
            var d = new Date(cellParams.value); 
            return (
                <span  >{ `${ d.toLocaleDateString ('default', { day : 'numeric', month : 'short', year : 'numeric' }) }` }  </span>); 
        }}, 
        { field : 'expiryDate', headerName : 'Valid Till', minWidth:120,flex:0.8,renderCell : cellParams => { 
                if (cellParams.value) {
                    var d = new Date(cellParams.value); 
                    return ( <span> {  `${ d.toLocaleDateString ('default', { day : 'numeric', month : 'short', year : 'numeric' }) }` }  </span> ); 
                } else return ( <span style = {{ marginLeft: 'auto', marginRight: 'auto' }} > - </span>); 
                
        }},
        { field : 'balance', headerName : 'Points', minWidth:100,flex:0.8, renderCell : (cellParams) => ( <div style = {{ marginLeft : '12px', fontSize : '15px' }}>  <center> <span >{ cellParams.value }</span> </center>  </div> ) }
    ];
    
    const [ dialogOpen, setDialogOpen ] = useState (false);
    const [ transactionName, setTransactionName ] = useState ('');
    const handleClose = () => { setDialogOpen(false); };

    let history = useHistory();
    const accruePoints = () => {
        if (member) {
            var groupIds = [ ]; groupIds.push (member);
            var currentRequest = { requestCategory : 'Member', requestAudience : 'Group', requestType : 'Accrue', userCount : 1, groupIds : groupIds };
            history.push({ pathname : "reward-points", state : { currentRequest , createMode : true, groupIds : groupIds  }}); 
        }
    };
    const deductPoints = () => {
        if (member) {
            var groupIds = [ ]; groupIds.push (member);
            var currentRequest = { requestCategory : 'Member', requestAudience : 'Group', requestType : 'Deduct', userCount : 1, groupIds : groupIds };
            history.push({ pathname : "reward-points", state : { currentRequest , createMode : true, groupIds : groupIds }}); 
        }   
    }

    const classes = useStyles();

    return (
        <Fragment>
            <GridContainer> 
                <GridItem xs = {6} >
                    <Card className='card-header-body'>
                        <CardHeader icon>
                            <CardIcon color="primary" style = {{ marginTop : 0, background : '#FF9017' }} ><TransferWithinAStationIcon /></CardIcon>                      
                        </CardHeader>
                        <CardBody className='card-header-body'></CardBody>
                    </Card> 
                        <Card> 
                            <CardHeader color = "primary" icon > 
                                <h4 className = { classes.cardIconTitle } > Transaction History </h4>
                            </CardHeader>
                            
                            <CardBody>
                                <br/>
                                <GridContainer>
                                    <GridItem xs = {12} >
                                        <DataGrid 
                                            autoHeight
                                            className = { classes.root }
                                            columns={columns} 
                                            page = { txnPage }
                                            rows={ txnList } 
                                            rowCount = { txnRowCount }
                                            pageSize = { txnPageSize }
                                            fullWidth
                                            rowsPerPageOptions ={ [25, 50, 100 ]}
                                            loading = { txnLoading }

                                            paginationMode = 'server'
                                            onPageChange = { (gridChangeParams) => {setTxnPage (gridChangeParams)}  }
                                            onPageSizeChange = { (gridChangeParams) => { setTxnPage(0); setTxnPageSize(gridChangeParams); } }

                                            sortingMode = 'server'
                                            onSortModelChange = { params => { if (params.length > 0 ) { setTxnSortModel(params[0]); } } }

                                            filterMode = 'server'
                                            onFilterModelChange = { params => { if (params.items.length > 0) { setTxnFilterModel(params.items[0]); } } }
                                            
                                            onRowClick = { rowDetails => { setOpenTransaction (rowDetails.row.id); setTxnOpen(true); } }
                                            hideFooterSelectedRowCount
                                        />
                                        
                                </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>  
                </GridItem>
                <GridItem xs = {6} >  
                        <Card className='card-header-body'>
                            <CardHeader icon>
                                <CardIcon color="primary" style = {{ marginTop : 0, background : '#138DE8' }} ><TransferWithinAStationIcon /></CardIcon>                      
                            </CardHeader>
                            <CardBody className='card-header-body'></CardBody>
                        </Card> 
                        <Card> 
                            <h4 className = { classes.cardIconTitle } style = {{ marginLeft : 10 }} > Points Summary </h4>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs = {12} > <center> <b> <h2 style = {{ color : "#138DE8" }} > Reward Points : { totalPoints.toLocaleString() } </h2> </b> </center>  </GridItem>
                                    <GridItem xs = {12} >  
                                        <DataGrid 
                                            autoHeight
                                            className = { classes.root }
                                            /* showToolbar */ 
                                            columns={ pointsColumns } 
                                            page = { ptsPage }
                                            rows={ ptsList } 
                                            rowCount = { ptsRowCount }
                                            pageSize = { ptsPageSize }
                                            rowsPerPageOptions ={ [25, 50, 100 ]}
                                            loading = { ptsLoading }
                                            paginationMode = 'server'
                                            onPageChange = { (gridChangeParams) => {setPtsPage (gridChangeParams)}  }
                                            onPageSizeChange = { (gridChangeParams) => { setPtsPage(0); setPtsPageSize(gridChangeParams); } }
        
                                            sortingMode = 'server'
                                            onSortModelChange = { params => { if (params.length > 0) { setPtsSortModel(params[0]); } } }
        
                                            filterMode = 'server'
                                            onFilterModelChange = { params => { if (params.items.length > 0) { setPtsFilterModel(params.items[0]); } } }

                                            hideFooterSelectedRowCount
                                        />
                                        
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                             <CardActions style = {{ justifyContent : 'center' }} color = "primary" > 
{    members.includes("Action") ===true &&                               <Button variant = "contained" raised style = {{ color : 'floralwhite', background : '#138DE8' , textTransform:'None' }} onClick = { accruePoints }  > Accure Points </Button>
}                                
{members.includes("Action") ===true &&  <Button variant = "contained" raised style = {{ color : 'floralwhite', background : '#FF9017' ,textTransform:'None' }} onClick = { deductPoints } > Deduct Points </Button>
}                                {/* <AccrueDeductDialog open = { dialogOpen } dialogVariant = { true } setDialogOpen = { setDialogOpen } transactionName = { transactionName } /> */}
                            </CardActions> 
                        </Card>  
                </GridItem>
            </GridContainer>
            { txnOpen && openTransaction && <TransactionDescription open = { txnOpen } setOpen = { setTxnOpen } transactionId = { openTransaction } memberId = { memberId } creator = {'member'} />} 
        </Fragment>
    );
}