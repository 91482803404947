import React, { useState, Fragment, useEffect, useReducer, useCallback } from "react"
import { Card, Button, FormControl, InputLabel, Select, MenuItem, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, FormHelperText , Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow ,withStyles} from "@material-ui/core"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import HWGoogleMaps from "views/Google Maps/HWGoogleMaps"
import { HWInput } from "HWLibrary/HWInput"
import { themeGreen, whiteColor, roseColor } from "assets/jss/material-dashboard-pro-react.js";
import { primaryColor, rose } from "assets/jss/material-dashboard-pro-react"
import { cardTitle } from "assets/jss/material-dashboard-pro-react"
import CardHeader from "components/Card/CardHeader"
import CardIcon from "components/Card/CardIcon"
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import ContactsIcon from '@material-ui/icons/Contacts';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from "react-router-dom";
import './Merchant.css';
import EditIcon from '@material-ui/icons/Edit';
import merchantApiService from "./APIService"
import memberApiService from "views/Members/APIService"
import BlockUi from "react-block-ui"
import 'react-block-ui/style.css';
import { Loader, Types } from 'react-loaders';
import 'loaders.css/loaders.min.css';
import CreateIcon from '@material-ui/icons/Create';
import HWMessage from "HWLibrary/HWMessage";
import { FadeLoader } from "react-spinners";
import HWLocalStorage from "HWLibrary/HWLocalStorage";
import MultiSelectDropDown from "./MultiSelectDropDown"
import CardBody from "components/Card/CardBody"
import { ContactsOutlined } from "@material-ui/icons"
import ConfirmationDialog2 from "views/Utils/ConfirmationDialog2";

const useStyles = makeStyles(theme => ({
    ...styles,
    root : {
        '& :focus' : { borderBottomColor : '#138DE8',  borderColor : '#138DE8'},
        '& :before' : { borderBottomColor : '#138DE8', borderColor : '#138DE8' },
        '& :after' : { borderBottomColor :  '#138DE8', borderColor : '#138DE8' },
        '& .MuiSelect-select:focus' : { borderBottomColor : '#138DE8', borderColor : '#138DE8' },
        '& .MuiSelect-select:after' : { borderBottomColor :  '#138DE8', borderColor : '#138DE8' },
        '& .MuiSelect-select:before' : { borderBottomColor : '#138DE8', borderColor : '#138DE8' },
        '& .MuiSelect-root:focus' : { borderBottomColor : '#138DE8' },
        '& .MuiSelect-selectMenu' : { borderBottomColor : '#138DE8' },
        '& .MuiButton-containedPrimary' : { backgroundcolor: "#138DE8" },
   },
    underline: {
        '&::after': { borderBottom: '2px solid #138DE8' },
        '&::before': { borderBottom: '1px solid black' }
    },
    mobileCountryCode : { fontSize : 15, color : 'grey' },
    
    
}));

export const DropDown = (props) => {

    console.log("Value", props);
    const classes = useStyles ();
    const {values} = props;
    const [ targetValue, setTargetValue ] = useState (props.targetValue || '');

    return (
        <Fragment>
            <InputLabel id={`label-${props.labelCategory}-category`}  className = { classes.root } disabled = { props.disabled } > { props.labelCategory } </InputLabel>
            <Select
                name = { props.name }
                className = { classes.underline }
                autoWidth
                labelId="label-category"
                style = {{ width : props.minWidth || '106px', borderColor : '#138DE8' }}
                value = { props.targetValue || '' }
                disabled = { props.disabled }
                onChange =  { (e) => {  props.onChange (e); setTargetValue (e.target.value); } }
                onBlur = { e => { props.onBlur ? props.onBlur(e) : console.log ("Value", e) } }
                helperText = { props.helperText }
                error = { props.hasError }
            >
                { values.map ((item, idx) => <MenuItem value = {item} key = {idx} > { item } </MenuItem>) }
            </Select>
        </Fragment>
    );
};

export const ConfirmationDialog = (props) => {
    
    const useStyles = makeStyles(theme => ({
        ...styles,
        cancelButton: {  '& .MuiButton-textPrimary' : { backgroundcolor: "#138DE8" }, }
    }));
    const classes = useStyles ();
    const handleClose = () => props.setDialogOpen (false);
    const { handleClickAction, handleUpdate } = props;

    return (
        <Fragment>   
            <Dialog open = {props.dialogOpen} onClose = {handleClose}  >
                { props.loading && <> <h4> Please wait. While updating. <FadeLoader /> </h4>  </>  }
                {
                !props.loading &&    <>
                <DialogTitle> {props.dialogTitle} </DialogTitle>
                <DialogContent>  
                    <DialogContentText> 
                        {props.dialogMessage}
                    </DialogContentText>
                </DialogContent>

                <DialogActions  >
                    <Button   color="primary" className = { classes.cancelButton } variant= "contained" onClick = { handleClose }   > Cancel </Button>
                    <Button   color="primary"  variant= "contained"  onClick = { () =>  { 
                        if (props.handleClickAction) 
                            handleClickAction ();
                        else if (props.handleUpdate)
                            handleUpdate (props.merchant);
                        } }   > { props.buttonText } </Button>
                </DialogActions>
                </>
                }
            </Dialog>
        </Fragment>
    );
};


//todo : Imaplement valiudation on all fields, now code is working so move ahead,.


const StyledTableCell = withStyles ((theme) => ({
    head : { 
        backgroundColor : theme.palette.common.black, 
        color : theme.palette.common.white 
    }, 
    body : { fontSize : 14 }
 })) (TableCell);

 const StyledTableRow = withStyles ((theme) => ({ 
    root : { '&:nth-of-type(odd)' : { backgroundColor : theme.palette.action.hover } }, 
 })) (TableRow);


const Merchant = (props) => {

   
    var merchantarray=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    merchantarray= [...obj["Merchants"]];


    HWLocalStorage.save (0, "previousMerchantDetailsTab");

    if (props && props.location && props.location.params && props.location.params.state  && props.location.params.state.member ) {
        var currentMerchant = props.location.params.state.member;
        HWLocalStorage.save (JSON.stringify(currentMerchant), "merchantStore");
    }

    const [ merchant, setMerchant ] = useState (props && props.location && props.location.params && props.location.params.state  && props.location.params.state.member || JSON.parse(HWLocalStorage.load("merchantStore")) || {});
    const [ member, setMember ] = useState (props && props.location && props.location.params && props.location.params.state  && props.location.params.state.member || JSON.parse(HWLocalStorage.load("merchantStore")) || {});

    const classes = useStyles();

    const [markerShown, setMarkerShown] = useState(true);
    const [ searchKey, setSearchKey ] = useState ('');

    const handleSearchChange = (event) => setSearchKey (event.target.value);
    const searchLocation = () => {
        // search using APIs or some other functions which might be defined in HWmaps, 
        // using the maps library.
    };

    
    // Component related states
    const [ categoryValues, setCategoryValues ] = useState ( ["HLYM", "Dealers", "Merchants"] );
    const [ productCategoryValues, setProductCategoryValues ] = useState ([]);
    const [ branchValues, setBranchValues ] = useState ( ["Selangor", "Penang", "Genblu Café"] );
    const [ dialogOpen, setDialogOpen ] = useState (false);
   // const [ member, setMember ] = useState (props && props.location && props.location.state && props.location.state && props.location.state.member || {});
    
    const [ isLoading, setIsLoading ] = useState (false);
    const [ createMember, setCreateMember ] = useState ((props && props.location && props.location.state && props.location.state && props.location.state.isCreate) || props.isCreate );
    const [ canEdit, setCanEdit ] = useState (false);
    const [ updateLoading, setUpdateLoading ] = useState (false);
    const [ locationLoading, setLocationLoading ] = useState (false);
    const [ showEdit, setShowEdit ] = useState (true);

    const [ productCategories, setProductCategories ] = useState ([]);
    const [ defaultCategory, setDefaultCategory ] = useState ("");
    const [ currentObject, setCurrentObject ] = useState ({});
    const [ address, setAddress ] = useState ({});
    const [ userDetails, setUserDetails ] = useState([]);
    
    useEffect (() => {
        setIsLoading (true);
        merchantApiService.fetchProductCategories ()
        .then (response => {  
            if (! response || ! response.data) { HWMessage.show ("Product categories cannot be fetched at moment. Please try again later","error"); }
            var res = response.data.productCategoryList;
            if (res != null) setProductCategoryValues ([ ...res ]);
            HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { HWMessage.show ("Error while fetching product categories","error"); })
        .finally (() => setIsLoading (false));
    }, [  ]);

    useEffect (() => {
        setIsLoading (true);
        merchantApiService.fetchBranches()
        .then (response => { 
            if (! response || ! response.data) { return HWMessage.show ("Error while fetching categories","error"); }
            setBranchValues (response.data);
            HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { HWMessage.show ("Error while fetching branches","error"); })
        .finally (() => { setIsLoading (false); });
    }, []);

    useEffect (() => {
        setIsLoading (true);
        merchantApiService.fetchCategories()
        .then (response => {  
            if (! response || ! response.data) { return HWMessage.show ("Error while fetching merchant categories","error"); }
            setCategoryValues (response.data);
            HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { HWMessage.show ("Error while fetching categories","error"); })
        .finally (() => { setIsLoading (false); });
    }, []);

    const handleUpdateOpen = () => {
        setCurrentObject({ ...currentObject, "address" : address });
        console.log("Current object", currentObject);
        setDialogOpen (true); setShowEdit (true);
    };


    useEffect (() => {
        setIsLoading (true);
        merchantApiService.fetchMerchantUserList(member.id)
        .then (response => {  
            if (! response || ! response.data) { return HWMessage.show ("Error while fetching merchant user list","error"); }
            setUserDetails (response.data);
            HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { HWMessage.show ("Error while fetching merchant user list","error"); })
        .finally (() => { setIsLoading (false); });
    }, []);



    useEffect (() => {
        if (!createMember && member && member.id) {
            setIsLoading (true);
            merchantApiService.fetchMerchant (member.id)
            .then (response => {  
                if (!response || !response.data) 
                    { HWMessage.show("Error while fetching data","error");
                        return console.log("Error while fetching records"); }   
                setMember (response.data);
                setMerchant (response.data);

                setMember (prev => ({ ...prev, 'emailId' : prev.emailId ? prev.emailId : prev.emailAddress }));
                setMerchant (prev => ({ ...prev, 'address' : prev.address ? prev.address : {} }));
                setMember (prev => ({ ...prev, 'address' : prev.address ? prev.address : {} }));

                setProductCategories (response.data.productCategories);
                setDefaultCategory (response.data.defaultProductCategory);
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error"); })
            .finally(() => { setIsLoading (false); });
        }
    }, []);


    const populateProductCategories = () => {
        const productCategoryList = [];

        for (var i = 0; i < productCategories.length; i++) {
            console.log (productCategories);
            var matching = productCategoryValues.filter(x => x.categoryName === productCategories[i]);
            if (matching.length > 0) { productCategoryList.push (matching[0].id); }
        }
        //alert (productCategoryList);
        return productCategoryList;
    };
    const populateDefaultCategory = () => {
        var matching = productCategoryValues.filter(x => x.categoryName === defaultCategory);
        return matching[0].id;
    };
    
    const handleUpdate = useCallback (updateMerchant => {
        if (updateMerchant) {
            var okToSave = validateMerchant (updateMerchant);

            updateMerchant.productCategories = populateProductCategories ();
            updateMerchant.defaultProductCategory = populateDefaultCategory ();

            if (okToSave) {
                setUpdateLoading (true);
                merchantApiService.updateMerchant({ ...updateMerchant})
                .then (response => { 
                    if (!response || !response.data) {
                        HWMessage.show ("Merchant cannot be updated","error");
                        return console.log("Error while updating merchant");
                    }
                    HWMessage.show("Merchant details updated successfully","success");
                    setMember (response.data);
                    setMerchant (response.data);
                    setCanEdit(false);

                    setMember (prev => ({ ...prev, 'emailId' : prev.emailAddress }));

                })
                .catch (error => { 
                    HWMessage.show ("Error while updating merchant","error");
                    return console.log("Error while updating merchant.");
                })
                .finally (() => { setUpdateLoading (false); setDialogOpen (false);  });
            } else { HWMessage.show ("Merchant contains invalid values","error"); setDialogOpen (false); }
            setShowEdit (true);
        }
    });


    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});
    const validateMerchantInput = (field, value) => {
        console.log("Field assignmednt", field, value);
        switch (field) {
            case 'mobileNumber' : 
                var reg = new RegExp ("^\\d{4,}$");
                if (reg.test(value)) { 
                    setHasError({ ...hasError, 'mobileNumber' : false }); 
                    setErrorMessage ({ ...errorMessage, 'mobileNumber' : null });
                    return false;
                }
                else { 
                    setHasError ({ ...hasError, 'mobileNumber' : true }); 
                    setErrorMessage ({ ...errorMessage, 'mobileNumber' : 'Invalid mobile number' });
                    return true;
                }
            case 'entityName' : 
                if ( !value ||  value && value === '' ) { 
                    setHasError ({ ...hasError, 'entityName' : true }); 
                    setErrorMessage ({ ...errorMessage, 'entityName' : 'Entity name must be specified' });
                    return false;
                } 
                else {
                    setHasError ({ ...hasError, 'entityName' : false });
                    setErrorMessage ({ ...errorMessage, 'entityName' : null }); 
                    return true;
                }
                break;
            
            case 'emailId' : 
                
                const validateEmail = email => {
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(String(email).toLowerCase());
                }
                
                if (! value || value && value === '') {
                    setHasError ({ ...hasError, 'email' : true });
                    setErrorMessage ({ ...errorMessage, 'email' : 'Email Address must be provided' });
                    return false;
                } else if (! validateEmail (value)) {
                    setHasError ({ ...hasError, 'email' : true });
                    setErrorMessage ({ ...errorMessage, 'email' : 'Invalid email address' });
                    return false;
                }
                else {
                    setHasError ({ ...hasError, 'email' : false });
                    setErrorMessage ({ ...errorMessage, 'email' : null });
                    return true;
                }
                break;
            case 'productCategory' : 
                if (! value || value === null)  {
                    setHasError ({ ...hasError, 'productCategory' : true });
                    setErrorMessage ({ ...errorMessage, 'productCategory' : 'Product Category must be specified' });
                    return false;
                } 
                else {
                    setHasError ({ ...hasError, 'productCategory' : false });
                    setErrorMessage ({ ...errorMessage, 'productCategory' : null });
                    return true;
                }
                break;
            
            case 'branch' : 
                if (! value || value === null)  {
                    setHasError ({ ...hasError, 'branch' : true });
                    setErrorMessage ({ ...errorMessage, 'branch' : 'Product Category must be specified' });
                    return false;
                } 
                else {
                    setHasError ({ ...hasError, 'branch' : false });
                    setErrorMessage ({ ...errorMessage, 'branch' : null });
                    return true;
                }
                break;

            case 'dealerCode' : 
                if (! value || value === '' ) {
                    setHasError ({ ...hasError, 'dealerCode' : true });
                    setErrorMessage ( { ...errorMessage, 'dealerCode' : 'Dealer code is reqiuired' } );
                    return false;
                } 
                else {
                    setHasError ({ ...hasError, 'dealerCode' : false });
                    setErrorMessage ({ ...errorMessage, 'dealerCode' : null });
                    return true;
                }
                break;
            case 'category' : 
                console.log("UNder validation", value);
                if (!value || value && value === '' || value == null) {
                    setHasError ({ ...hasError, 'category' : true });
                    setErrorMessage ({ ...errorMessage, 'category' : 'Category must be specified' });
                    return false;
                }
                else {
                    setHasError ({ ...hasError, 'category' : false });
                    setErrorMessage ({ ...errorMessage, 'category' : null });
                    return true;
                } 
                break;
            case 'addressLine1' : 
                
                if (!value || value && value === '' || value == null) {
                    setHasError ({ ...hasError, 'addressLine1' : true });
                    setErrorMessage ({ ...errorMessage, 'addressLine1' : 'Address Line1 must be specified' });
                    return false;
                }
                else {
                    setHasError ({ ...hasError, 'addressLine1' : false });
                    setErrorMessage ({ ...errorMessage, 'addressLine1' : null });
                    return true;
                } 
                break;

            case 'addressLine2' : 
                // if (!value || value && value === '' || value == null) {
                //     setHasError ({ ...hasError, 'addressLine2' : true });
                //     setErrorMessage ({ ...errorMessage, 'addressLine2' : 'Address Line2 must be specified' });
                // }
                // else {
                setHasError ({ ...hasError, 'addressLine2' : false });
                setErrorMessage ({ ...errorMessage, 'addressLine2' : null });
                return true;
                //} 
                break;

            case 'city' : 
                if (!value || value && value === '' || value == null) {
                    setHasError ({ ...hasError, 'city' : true });
                    setErrorMessage ({ ...errorMessage, 'city' : 'City must be specified' });
                    return false;
                }
                else {
                    setHasError ({ ...hasError, 'city' : false });
                    setErrorMessage ({ ...errorMessage, 'city' : null });
                    return true;
                } 
                break;

            case 'state' : 
                console.log("UNder validation", value);
                if (!value || value && value === '' || value == null) {
                    setHasError ({ ...hasError, 'state' : true });
                    setErrorMessage ({ ...errorMessage, 'state' : 'Category must be specified' });
                    return false;
                }
                else {
                    setHasError ({ ...hasError, 'state' : false });
                    setErrorMessage ({ ...errorMessage, 'state' : null });
                    return true;
                } 
                break;

            case 'postalCode' : 
                console.log("UNder validation", value);
                if (!value || value && value === '' || value == null) {
                    setHasError ({ ...hasError, 'postalCode' : true });
                    setErrorMessage ({ ...errorMessage, 'postalCode' : 'Post Code must be specified' });
                    return false;
                }
                else {
                    setHasError ({ ...hasError, 'postalCode' : false });
                    setErrorMessage ({ ...errorMessage, 'postalCode' : null });
                    return true;
                } 
                break;
        }
        console.log("Error message", errorMessage);
        console.log("Has Error", hasError);
    };

    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    }
    const fetchCityStateWithPostCode = useCallback (postCode => {
        if (postCode) {
            setLocationLoading (true);
            merchantApiService.fetchCityStateByPostCode ({ postCode })
            .then (response => { 
                if (! response || !response.data) {  
                    HWMessage.show ("PostCode cannot be found. Please enter valid post code","error");
                    return console.log("Error while fetching post code");
                }
                if (createMember)  setAddress({ ...address, 'postalCode' : postCode, 'city' : response.data.city, 'state' : response.data.state });
                else  setMember ({ ...member, 'address' : { ...member.address, 'postalCode' : postCode, 'city' : response.data.city, 'state' : response.data.state } });
             })
            .catch (error => {  
                if (createMember) 
                        setAddress({ ...address, 'postalCode' : postCode });
                else 
                        setMember ({ ...member, 'address' : { ...member.address, 'postalCode' : postCode } });

                setHasError ({ ...hasError, 'postalCode' : true });
                setErrorMessage ({ ...errorMessage, 'postalCode' : 'Invalid post code' });

                HWMessage.show ("Error while fetching location", "error");
                return console.log("Error while fetching post code");
            })
            .finally (() => { setLocationLoading (false); });
        }
    });

    const handleTextFieldChanges = (event) => {
        console.log("Handling", event);
        let value = event.target.value;
    
        console.log("Event", event.target.value, event.target.name);
        if (value === undefined) { return; }
        if (typeof value === 'string') { value = value.trim(); }
        
        validateMerchantInput (event.target.name, event.target.value);
        switch (event.target.name) {
            case 'mobileNumber':
                if (createMember) {
                    currentObject.mobile = { countryCode : '+60', mobileNumber:  event.target.value}; 
                }
                else { 
                    member.mobile = { countryCode : '+60', mobileNumber:  event.target.value};
                    setMember (member); 
                } 
                break;
            case 'entityName': 
                if (createMember) currentObject.entityName = event.target.value; 
                else { 
                    member.entityName = event.target.value;
                    setMember (member); 
                }
                break;
            case 'emailId': 
                if (createMember) currentObject.emailId = event.target.value; 
                else { 
                    member.emailId = event.target.value;
                    member.emailAddress = event.target.value;
                    setMember (member); 
                }
                break;    
            
            case 'dealerCode' : 
                if (createMember) currentObject.dealerCode = event.target.value;
                else {
                    member.dealerCode = event.target.value;
                    setMember (member);
                }
                break;
            case 'addressLine1' :
                if (createMember) 
                    setAddress({ ...address, "addressLine1" : event.target.value });
                else 
                    setMember ({ ...member, 'address' : { ...member.address, 'addressLine1' : event.target.value } }); 
                break;

            case 'addressLine2' :       
                if (createMember) 
                    setAddress({ ...address, "addressLine2" : event.target.value });
                else 
                    setMember ({ ...member, 'address' : { ...member.address, 'addressLine2' : event.target.value } }); 
                break;
            case 'city' : 
                if (createMember) 
                    setAddress({ ...address, "city" : event.target.value });
                else 
                    setMember ({ ...member, 'address' : { ...member.address, 'city' : event.target.value } });
                break;
            case 'state' :
                if (createMember) 
                    setAddress({ ...address, "state" : event.target.value });
                else 
                    setMember ({ ...member, 'address' : { ...member.address, 'state' : event.target.value } });
                break;  
            case 'postalCode' : 
                fetchCityStateWithPostCode (event.target.value);
                break;  
        }
        console.log("Current object for now", member, currentObject);
    };

    const checkFieldErrors = () => {
        var errorList = Object.keys(hasError).filter(key => (hasError[key] && hasError[key] === true) ? true : false);
        if (errorList && errorList.length > 0) return false;
        return true;
    };

    const handleUpdateCancel = event => { setMember (merchant); setCanEdit (false); setShowEdit (true); };
    const validateMerchant = merchant => {
        console.log("Current fields", merchant);
        const fields = [ 'category', 'dealerCode', 'entityName', 'branch', 'mobileNumber', 'emailId' ];
        const addressFields = [ 'addressLine1', 'addressLine2', 'city', 'state', 'postalCode' ];

        if (! merchant) { HWMessage.show ("Merchant contains invalid values","error"); }

        var isMerchantValid = true;
        for (var field in fields) { 
            isMerchantValid = isMerchantValid && validateMerchantInput (fields[field], merchant[fields[field]]); 
            console.log("Field", fields[field], isMerchantValid);
        }
        for (var addressField in addressFields) { isMerchantValid = isMerchantValid && validateMerchantInput(addressFields[addressField], merchant.address[addressFields[addressField]]); 
            console.log("Field", fields[field], isMerchantValid);
        }
        return isMerchantValid;
    };
    const setupAccount = newMerchant => { 
        var ok = validateMerchant (newMerchant);
        const assignProductCategory = (obj, value) => {
            obj.productCategories = value;
            console.log (obj);
            var x = { ...obj, productCategories : value};
            return x;
        };

        var pushMerchant = { ...newMerchant };
        pushMerchant.productCategories = new Array ();

        const valueList = new Array (0);
        for (var i = 0; i < productCategories.length; i++) {
            console.log (productCategories);
            var matching = productCategoryValues.filter(x => x.categoryName === productCategories[i]);
            if (matching.length > 0) { 
                var value = matching[0].id;
                valueList.push (value);
            }
        }
        console.log (pushMerchant);
        assignProductCategory (pushMerchant, valueList);
        console.log (pushMerchant);
        pushMerchant.emailAddress = pushMerchant.emailId;

        if (ok) {props.setupAccount(pushMerchant);}
        else HWMessage.show ("Errors in multiple fields please rectify before setting up.","error");
    };
         
    return (
        <>
        <BlockUi 
                blocking = {isLoading} 
                message = "Loading Merchant Details" 
                keepInView = {true}
                loader = { <Loader active type = 'line-scale-pulse-out-rapid' color="#02a17c" /> }
        >
            <GridContainer>
                <GridItem xs={6}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose" style = {{ background : '#138DE8' }} > <InfoIcon /> </CardIcon>
                            <h4 className={classes.cardIconTitle}> Basic Information { (!createMember && showEdit === true && merchantarray.includes("Action")) && <IconButton  onClick = { () => {setCanEdit(!canEdit); setShowEdit (false); } }  > <CreateIcon style={{color:"#138de8"}} /> </IconButton>} </h4>
                        </CardHeader>

                        <CardBody>  
                        <div style={{ padding: "30px 30px 30px 30px" }}>
                            <GridContainer disabled = { !createMember && !canEdit } >
                                <GridItem xs={4} >
                                    {/* <HWInput label="Category" /> */}
                                    <FormControl className={classes.formControl}  >
                                        <DropDown 
                                            labelCategory = "Category" 
                                            name = 'category'
                                            values = { categoryValues.map (i => i.categoryName) } 
                                            disabled = { !createMember && !canEdit } 
                                            targetValue = { (createMember) ? currentObject.category : member.category }
                                            //onChange = { handleTextFieldChanges }
                                            onChange = { (event) => { 
                                                if (createMember) { setCurrentObject (prev => { prev.category = event.target.value; return { ...prev } }); } 
                                                else { setMember (prev => { prev.category = event.target.value; return { ...prev } }); } }
                                            }
                                            
                                            helperText = { fetchErrorMessage ('category') } 
                                            hasError = { checkError ('category') }
                                            required = {true}
                                        />
                                        <FormHelperText> { fetchErrorMessage ('category') }  </FormHelperText>
                                    </FormControl>
                                </GridItem>

                                <GridItem xs={4} disabled = { !createMember && !canEdit }>
                                    <HWInput 
                                        label="Dealer Code" 
                                        name = { 'dealerCode' }
                                        disabled = { !createMember && !canEdit }
                                        value = { (createMember) ? currentObject.dealerCode : member.dealerCode }
                                        style = {{ opacity : !createMember && !canEdit ? 0.2 : 1 }}  
                                        onBlur = { handleTextFieldChanges }
                                        hasError = { checkError ('dealerCode') } 
                                        helperText = { fetchErrorMessage ('dealerCode') } 
                                        required = {true}
                                    />
                                </GridItem>
                                <GridItem xs={4}>
                                    <HWInput 
                                        label="Entity Name" 
                                        name = {'entityName'}
                                        disabled = { !createMember && !canEdit }
                                        value = { createMember ? currentObject.entityName : member.entityName } 
                                        onBlur={(event) => { handleTextFieldChanges(event); }}  
                                        hasError = { checkError ('entityName') } 
                                        helperText = { fetchErrorMessage ('entityName') } 
                                        required = {true}
                                    />
                                    
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12}>
                                    { productCategoryValues.length > 0 && <MultiSelectDropDown 
                                        names = { productCategoryValues.map(i => i.categoryName) }
                                        defaultCategory = { defaultCategory }
                                        setDefaultCategory = { setDefaultCategory }
                                        values = { productCategories }
                                        setValues = { setProductCategories }
                                        hasError = { checkError ('productCategories') }
                                        errorMessage = { fetchErrorMessage('productCategories') }
                                        disabled = { ! createMember && !canEdit }
                                        label = { 'Product Category' }
                                        required = {true}
                                    />}
                                </GridItem>

                                <GridItem xs={6} style = {{ marginTop : 10 }} >
                                    <FormControl className={classes.formControl}>
                                        <DropDown 
                                            labelCategory = "Branch" 
                                            name = 'branch'
                                            values = { branchValues && branchValues.map (i => i.branchName) } 
                                            disabled = { !createMember && !canEdit }
                                            targetValue = { createMember ? currentObject.branch : member.branch }
                                            onChange = { (event) => { 
                                                    if (createMember) { setCurrentObject (prev => { prev.branch = event.target.value; return { ...prev } });  }
                                                    else {setMember (prev => { prev.branch = event.target.value; return { ...prev } });}
                                                }
                                            }
                                            onBlur = { (event) => { 
                                                if (createMember) currentObject.branch = event.target.value; 
                                                else {member.branch = event.target.value; setMember (member);}
                                                }
                                            }
                                           hasError = { checkError ('branch') } 
                                           helperText = { fetchErrorMessage ('branch') } 
                                           required = {true}
                                        />
                                    </FormControl>
                                </GridItem>

                                <GridItem xs={6} style = {{ marginTop : 9 }} >
                                    <HWInput 
                                        label="Contact Number" 
                                        name = { 'mobileNumber' }
                                        disabled = { !createMember && !canEdit }
                                        value = { createMember ? currentObject.mobileNumber : member && member.mobile && member.mobile.mobileNumber }
                                        onBlur = { (event) => handleTextFieldChanges(event) } 
                                        hasError = { checkError ('mobileNumber') } 
                                        helperText = { fetchErrorMessage ('mobileNumber') } 
                                        inputProps = {{ startAdornment : <span className = { classes.mobileCountryCode } > { createMember ? '(+60)-' : (member && member.mobile ) ? `${member.mobile.countryCode}-` : '+60-' } </span> }}
                                        required = {true}
                                    />
                                </GridItem>

                            </GridContainer>
                            <GridContainer>
                                <GridItem xs = {6} >
                                        <HWInput 
                                            label="Email Id" 
                                            disabled = { !createMember && !canEdit }
                                            value = { createMember ? currentObject.emailId ? currentObject.emailId : '' : member.emailAddress ? member.emailAddress : '' }
                                            name = "emailId"
                                            type = 'email'
                                            onBlur={(event) => { handleTextFieldChanges(event); }}
                                            hasError = { checkError ('email') } 
                                            helperText = { fetchErrorMessage ('email') }
                                            required = {true}
                                        />
                                </GridItem>
                                {
                                ! createMember && member && member.code &&
                                <GridItem xs = {6} >
                                        <HWInput 
                                            label = 'Merchant Code'
                                            disabled = { true }
                                            value = { member.code ? member.code : '' }
                                            name = 'merchantCode'
                                            required = {true}
                                        />    
                                </GridItem>
                                }
                            </GridContainer>
                        </div>
                        </CardBody>
                    </Card>
                </GridItem>

                <GridItem xs={6}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose" style = {{ background : '#FF9017' }} > <ContactsIcon /> </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Address Details
                            </h4>
                        </CardHeader>

                        <div style={{ padding: "30px 30px 30px 30px" }}>
                            
                            <BlockUi blocking = { locationLoading } message = "Loading City and States. Please wait" >
                                <GridContainer>
                                    <GridItem xs = {6} >
                                        { console.log("In between logging", member)  }
                                        <HWInput 
                                            label="Line 1" 
                                            name = { 'addressLine1' }
                                            disabled = { !createMember && !canEdit }
                                            value = { createMember ?  address.addressLine1 : (member && member.address && member.address.addressLine1 ? member.address.addressLine1 : '' ) } 
                                            onBlur={(event) => { handleTextFieldChanges(event); }} 
                                            hasError = { checkError ('addressLine1') } 
                                            helperText = { fetchErrorMessage ('addressLine1') }
                                            required = {true}
                                        />
                                    </GridItem>
                                    <GridItem xs = {6} >
                                        <HWInput 
                                            label="Line 2" 
                                            name = { 'addressLine2' }
                                            disabled = { !createMember && !canEdit }
                                            value = { createMember ? address.addressLine2 : (member && member.address && member.address.addressLine2 ? member.address.addressLine2 : '' ) }
                                            style = {{ opacity : !createMember && !canEdit ? 0.2 : 1 }}
                                            onBlur={(event) => { handleTextFieldChanges(event); }} 
                                            hasError = { checkError ('addressLine2') } 
                                            helperText = { fetchErrorMessage ('addressLine2') }      
                                            required = {true}                                  
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs = {12} >
                                        <HWInput 
                                            label="Post Code" 
                                            name = {'postalCode'}
                                            disabled = { !createMember && !canEdit }
                                            value = { createMember ? address.postalCode : (member && member.address && member.address.postalCode ? member.address.postalCode : '' ) }
                                            style = {{ opacity : !createMember && !canEdit ? 0.2 : 1 }}
                                            onBlur={(event) => { handleTextFieldChanges(event); }} 
                                            hasError = { checkError ('postalCode') } 
                                            helperText = { fetchErrorMessage ('postalCode') }
                                            required = {true}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs = {6} >
                                        <HWInput 
                                            label="City" 
                                            name = { 'city' }
                                            disabled = { true /* !createMember && !canEdit */ }
                                            value = { createMember ? address.city : (member && member.address && member.address.city ? member.address.city : '' ) }
                                            style = {{ opacity : !createMember && !canEdit ? 0.2 : 1 }}
                                            onBlur={(event) => { handleTextFieldChanges(event); }}
                                            hasError = { checkError ('city') } 
                                            helperText = { fetchErrorMessage ('city') }
                                            required = {true}
                                        />
                                    </GridItem>
                                    <GridItem xs = {6} >
                                        <HWInput 
                                            label="State"
                                            name = {'state'}
                                            disabled = { true /* !createMember && !canEdit */ }
                                            value = { createMember ? address.state : (member && member.address && member.address.state ? member.address.state : '' ) }
                                            style = {{ opacity : !createMember && !canEdit ? 0.2 : 1 }}
                                            onBlur={(event) => { handleTextFieldChanges(event); }} 
                                            hasError = { checkError ('state') } 
                                            helperText = { fetchErrorMessage ('state') }
                                            required = {true}
                                        />

                                        {/* <HWGoogleMaps 
                                            isMarkerShown = { true }
                                        /> */}
                                    </GridItem>
                                </GridContainer>
                                
                            </BlockUi>
                        </div>
                    </Card>
                </GridItem>

            </GridContainer>

            {
            (   createMember && 
                <div style={{ padding: "30px 30px 0px 30px", display: 'flex', justifyContent: 'center' }}>
                    <Button variant="raised" component="span" style={{ backgroundColor: '#FF9017', color: whiteColor, height: '40px', width: '90px' , textTransform:'None' }}
                        onClick={() => props.cancelCreate()}>
                        Cancel
                    </Button>
                    <Button 
                        variant="raised" 
                        component="span" 
                        style={{ backgroundColor: '#138DE8', color: whiteColor, height: '40px', width: 'auto', left: 30, textTransform : 'none' }}
                        onClick={(event) => { 
                                var validateSave = checkFieldErrors ();
                                if (validateSave === false) {  
                                    HWMessage.show("One or more fields contain errors. Please rectify them before saving","error");
                                    return console.log("One or more fields contains errors", hasError, errorMessage);
                                } else {
                                    HWMessage.show ("Validation Successful. Checking for mandatory fields","info");
                                    currentObject.address = address;
                                    
                                    var merchantCreate = currentObject;
                                    merchantCreate.address = address;
                                    merchantCreate.defaultProductCategory = populateDefaultCategory ();

                                    console.log ("dfgasjk", merchantCreate);

                                    setCurrentObject({...currentObject, address}); 
                                    setupAccount(merchantCreate);
                                }
                            }
                        }
                    >
                        Setup Account
                    </Button>
                </div> ) 
            }
            {
                canEdit === true &&
                    <>
                        <GridContainer justify = "center" style = {{ marginTop : '14px' }} >
                            <GridItem> <Button style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} variant = "contained" size = "large" onClick = { handleUpdateOpen }  > {'Update Merchant'} </Button> </GridItem>
                            <GridItem> <Button style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }}  variant = "contained" size = "large" onClick = { handleUpdateCancel } > Cancel </Button> </GridItem>                            
                        </GridContainer>

                        <ConfirmationDialog
                            dialogOpen = {dialogOpen} 
                            setDialogOpen = {setDialogOpen} 
                            dialogTitle = 'Confirm Updating Merchant Details' 
                            dialogMessage = {'Are you sure you want to update merchant details ?'} 
                            buttonText = {'Update'} 
                            handleUpdate = { handleUpdate }
                            merchant = {member}
                            loading =  { updateLoading }
                        />
                        
                    </>

            }
            <br/>

        { (!createMember || createMember === false) && <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="rose" icon>
                            <CardIcon color="rose" style = {{ background : '#138DE8' }} > <InfoIcon /> </CardIcon>
                            <h4 className={classes.cardIconTitle}> Sub-Merchant Details </h4>

                        </CardHeader>
                        <br/>
                        <CardBody>  

            <TableContainer component = {Paper} >
                <Table size = "small" >  
                    <TableHead>
                        <TableRow>
                        <StyledTableCell align = "center" > Sub Merchant Name </StyledTableCell>
                            <StyledTableCell align = "center" > Sub Merchant Code </StyledTableCell>
                            <StyledTableCell align = "center" > Email Address </StyledTableCell>
                            <StyledTableCell align = "center" > Category </StyledTableCell>
                            <StyledTableCell align = "center" > Role </StyledTableCell>


                        </TableRow>
                    </TableHead>

                    <TableBody>
                        { userDetails.map((i, idx) => (
                                <StyledTableRow key = {idx} > 
                                    <StyledTableCell align = "center" > <span >  {i.name} </span> </StyledTableCell>
                                    <StyledTableCell align = "center" > <span > { i.merchantUserCode } </span> </StyledTableCell>
                                    <StyledTableCell align = "center" > <span > { i.emailAddress } </span> </StyledTableCell>
                                    <StyledTableCell align = "center" > <span > { i.productCategory } </span> </StyledTableCell>
                                    <StyledTableCell align = "center" > <span > { i.roles.join(", ")} </span> </StyledTableCell>
                                    
                                </StyledTableRow>   
                        )) 
                        }
                    </TableBody>
                
            </Table>
       </TableContainer>
        </CardBody>
        </Card>
        </GridItem>
        </GridContainer>}
        </BlockUi>


     
    </>
    );
};

export default Merchant