import { Button, FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup, TextField ,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import CardHeader from 'components/Card/CardHeader';
import GridItem from 'components/Grid/GridItem';
import React, { useCallback, useEffect, useState } from 'react';
import { Fragment } from 'react';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import GridContainer from 'components/Grid/GridContainer';
import CardBody from 'components/Card/CardBody';
import Alert from '@material-ui/lab/Alert';
import CardIcon from 'components/Card/CardIcon';
import Card from 'components/Card/Card';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import voucherRequestApiService from '../Actions/ApiService';
import HWMessage from 'HWLibrary/HWMessage';
import { HWInput } from 'HWLibrary/HWInput';
import BlockUi from 'react-block-ui';
import voucherConfigApiService from './APIService';
import { FadeLoader } from 'react-spinners';
import memberApiService from 'views/Members/APIService';
import { Autocomplete } from '@material-ui/lab';
import CardFooter from 'components/Card/CardFooter';
import { useHistory } from 'react-router-dom';
import { DataGrid , GridToolbar} from '@material-ui/data-grid';
import userSegmentApiService from 'views/UserSegment/APIService';
import voucherApiService from 'views/Members/MemberRewards/ApiService';
import { useRef } from 'react';
import { Checkbox } from '@material-ui/core';
import SearchBar from 'views/Merchant/SearchBar';

const useStylesHead = makeStyles (styles);


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    applyButton : { textTransform : 'none', color : 'floralwhite', background : '#138DE8', margin: 2.5 , width : 200, height : 50, fontSize : 20 },
    cancelButton : { textTransform : 'none', color : 'floralwhite', background : '#FF9017',margin: 2.5 , width : 200, height : 50, fontSize : 20 },
    cardFooter : { justifyContent : 'center', justifyItems : 'center' },

}));

export function SearchUserDialog (props) {

    const classes = useStyles ();

    const [ selectedList, setSelectedList ] = useState ([]);
    const [ listLoading, setListLoading ] = useState (false);

    const [ rows, setRows ] = useState ([]);
    const [ page, setPage ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ rowCount, setRowCount ] = useState (0);
    const [ searchText, setSearchText ] = useState ('');
    const [ filterModel, setFilterModel ] = useState ('');
    const [ refreshState, setRefreshState ] = useState (false);
    const [ selectionModel, setSelectionModel ] = useState ([]);
    const [ uploadInProgress , setUploadInProgress ] = useState(false);
    const [ selectionModelNew, setSelectionModelNew ] = useState ({ rowIds : [] });
    const prevSelectionModel = useRef(selectionModel);
    const [ selectionModelMap, setSelectionModelMap ] = useState ({});
    const [ firstSortId, setFirstSortId] = useState();
    const [ lastSortId, setLastSortId ] = useState();
    const [ pageDelta, setPageDelta ] = useState(0);
    useEffect (() => {
        if (props && props.userType !== null) {
            var { userType } = props;
            console.log(userType);

            setListLoading (true);
            var paginationBookmark = (page) * pageSize;
            var fetchLimit = pageSize;

            if (userType === 'Member') {
                memberApiService.fetchActiveMembers ({ paginationBookmark, fetchLimit, searchText, filterModel, pageDelta, firstSortId, lastSortId })
                .then (response => {  
                    if (! response || ! response.data) { HWMessage.show ("List cannot be fetched at momeent" , "error"); }
                    setRowCount (response.data.totalMembers);
                    setRows (response.data.members);
                    setFirstSortId (response.data.firstSortId);
                    setLastSortId (response.data.lastSortId);
                    setSelectionModelMap ({  ...selectionModelMap,  [page] : selectionModelMap[page] || [] });
                    setTimeout(() => { setSelectionModel( selectionModelMap[ page ] || [] ); });
                }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            })
                .finally (() => { setListLoading (false);})
            } 
        }
    }, [ props.userType, refreshState, pageSize, searchText, page , pageSize ]);


    const handleExclusionInclusionClick = id => {

        console.log("Selections", selectionModel, selectionModelMap);

        var idx = selectionModel.indexOf (id);
        if (idx !== -1) {
            selectionModel.splice (idx, 1);
            setSelectionModel ([ ...selectionModel ]);
            var currentMap = selectionModelMap [ page ];
            var mapIdx = currentMap.indexOf(id);
            if (mapIdx !== -1) 
                currentMap.splice (mapIdx, 1);
            selectionModelMap [ page ] = currentMap;
            setSelectionModelMap ({ ...selectionModelMap });
        } else {
            selectionModel.push (id);
            setSelectionModel ([ ...selectionModel ]);
            
            var currentMap = selectionModelMap [ page ];
            currentMap.push (id);
            selectionModelMap [ page ] = currentMap;
            setSelectionModelMap ({ ...selectionModelMap });
        }
    };

    const columns = [
        
        { field : 'membershipNumber', headerName : 'Membership Number', filterable : true, sortable : true, width : 200 },
        { field : 'code', headerName : 'Merchant Code', filterable : true, sortable : true, width : 200 },
        { field : props && props.userType === 'Merchant' ? 'entityName' : 'name', headerName : props && props.userType === 'Merchant' ? 'Entity Name' : 'Name', filterable : true, sortable : true, width : 200 },
        { field : 'action', headerName : 'Action', renderCell : cellParams => selectionModel && <Checkbox  style = {{ color : '#138DE8' }} onClick = { e => handleExclusionInclusionClick (cellParams.row.id) } checked = { selectionModel.indexOf (cellParams.row.id) !== -1 } /> }

    ];

    const handleCheck = () => {  };
    const handleClose = event => { setSearchText(''); props.setOpen(prev => !prev); };


    const history = useHistory();
    const handleNext = event => {
      //  props.setOpen (false);

        // let selectedRows = rows.filter (i => selectionModel.indexOf(i.id) !== -1);
        let selectedRows = [] ;
       Object.values(selectionModelMap).forEach(i =>  { selectedRows.push(...i) }  ) ; 
       
        var req = props.currentRequest;
        req.groupIds = selectedRows;
        props.setCurrentRequest (req);
        history.push({ pathname : "voucher-reward", state : {  request : req , createMode : true }}); 

        //alert ( JSON.stringify (props.currentRequest));
    };

    return (
    <Dialog open = { props.open } onClose = { handleClose }  >
        <DialogTitle  > Select Members for the Group </DialogTitle>
        <DialogContent>  
     {props.currentRequest && props.currentRequest.voucherRequestType === "Group" && <GridItem>  
                    <SearchBar 
                        width = '250px' 
                        handleSearch = { setSearchText }
                        handleRefresh = { setRefreshState }
                        refreshState = { refreshState }
                        placeholder = "Search on all details"
                    /> 
                    <br/>
                </GridItem>}
            <DialogContentText> Select one or more users from the list to award. Please press next button once done </DialogContentText>
            <GridContainer>
                <GridItem xs = {12} md = {12} sm = {12} >  
                <DataGrid className={classes.root}
                    // checkboxSelection
                    loading = { listLoading }
                    page = { page }
                    pageSize = { pageSize }
                    rows = { rows }
                    rowCount = { rowCount }
                    columns = { columns.filter (i => (props.userType === 'Member' ? i.field !== 'code' : i.field !== 'membershipNumber')) }
                    pagination
                    paginationMode = 'server'
                    onPageChange = { changeParams => {setPage (changeParams); setPageDelta (changeParams-page); console.log("page before and now" , page, changeParams)} }
                    onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams); } }
                    filterMode = 'server'
                    onFilterModelChange = { params => { if (params.items.length > 0) setFilterModel(params.items[0]); } }
                    density = 'compact'
                    autoHeight
                    rowsPerPageOptions ={ [25, 50, 100 ]}
                    onPageChange={(newPage) => {
                        console.log(selectionModel, prevSelectionModel);
                        prevSelectionModel.current = selectionModel;
                        var existing = selectionModelMap[ newPage ] || [];
                        setSelectionModel (prev => [ ...existing ]);
                        setTimeout(() => { setSelectionModel (prev => [ ...existing ]); });
                        console.log("Restored", page, selectionModelMap, selectionModel);
                        setSelectionModelNew ({ rowIds : [ ...existing ] });
                        setPage(newPage);
                        setPageDelta (newPage-page);
                    }}
                />
                </GridItem>
            </GridContainer>
        </DialogContent>
        <DialogActions> 
            <center>
                <Button variant='contained' style = {{ background : '#FF9017', color : 'floralwhite', height : 40, width : 100 ,textTransform:'none' }} variant = 'contained' onClick = {e  => handleNext() } > Next </Button>
            </center>
        </DialogActions>
    </Dialog>
    );
};



function StatusDialog (props) {


    return (
        <React.Fragment>
        {
            props.raiseLoading && props.raiseLoading === true &&
            <FadeLoader />
        }
        {
            ! props.raiseLoading &&
            <Dialog open = {props.statusDialogOpen}  >
                <DialogTitle> Raising Request </DialogTitle>
                {
                props.createStatus &&
                <DialogContent>
                    { props.createStatus === true ? 'Request Raised for Vouchers' : 'Request being raised'}
                </DialogContent>
                }
                <DialogActions>  
                    <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { () => { props.resetRequest () ; props.setStatusDialogOpen (false); props.setCreateStatus (false);  } } > Reward More </Button>
                    <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} onClick = { () => { props.setStatusDialogOpen(false); props.setDialogOpen (false); } } > Home </Button>
                </DialogActions>
        </Dialog>
        }
        </React.Fragment>
    );
}


export default function VoucherAudienceSelection (props) {

    const history = useHistory ();

    const handleApply = e => {  
        history.push({ pathname : "voucher-reward", state : {  request : currentRequest , createMode : true }}); 
      }
     


    const handleCancel = () => { 
        history.goBack();
     };

    const [ segmentList, setSegmentList ] = useState ([]);
    const [ segmentLoading, setSegmentLoading ] = useState (false);
    const classes = useStyles ();
    const headClasses = useStylesHead();
    const [ check, setCheck ] = useState (false);

    const [ memberId, setMemberId ] = useState (props.id);
    const [ currentRequest ,setCurrentRequest ] = useState ({});
    useEffect (() => {
        setSegmentLoading (true);
        userSegmentApiService.fetchActiveSegmentList ()
        .then (response => { 
            if (! response || ! response.data) { return HWMessage.show ("Data cannot be fetched at moement" , "error"); }
            HWMessage.show("Request Successful" , "success");
            setSegmentList (response.data.userSegments);     
            console.log(response.data);
        }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() => setSegmentLoading (false));
}, [ ]);

    const [ fetchedMember, setFetchedMember ] = useState ({});

    // useEffect (() => {
    //     if (memberId) {
            
    //         voucherApiService.fetchRequestDetails (memberId)
    //         .then (response => { 
    //             if (! response || ! response.data) {
    //                 HWMessage.show("This member details cannot be fetched");
    //                 return console.log("Error while fetching member");
    //             }
    //             setFetchedMember(response.data);
    //          })
    //          .catch (error => {
    //              HWMessage.show ("Error while fetching member" + error);
    //              return console.log("Error while fetching member", error);
    //          });
    //     }
    // }, []);

    useEffect (() => {
           if(props && props.location && props.location.params && props.location.params.state && props.location.params.state && props.location.params.state.createMode && props.location.params.state.createMode === false)
           { console.log("sadasdas");
            voucherRequestApiService.fetchRequestDetails ( props && props.location && props.location.params && props.location.params.state && props.location.params.state.id )
            .then (response => { 
                if (! response || ! response.data) {
                    HWMessage.show("This member details cannot be fetched" ,"error" );
                    return console.log("Error while fetching member");
                }
                HWMessage.show("Request Successful" , "success");
                setCurrentRequest(response.data);
             })
             .catch (error => {
                HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");

                return console.log("Error while fetching member", error);
             });
        }
    }, []);

    const checkDisabled = e => {  
        if(currentRequest && currentRequest.voucherRequestType) 
        {
            if(currentRequest && currentRequest.voucherRequestType && currentRequest.voucherRequestType === "Segment" && currentRequest.memberSegmentId)
                 return false;
            else if (currentRequest && currentRequest.voucherRequestType && (currentRequest.voucherRequestType === "All" || (currentRequest.voucherRequestType === "Group" && currentRequest.memberGroup &&  currentRequest.memberGroup.length >0) )  )
                return false;
                
            else{
                return true ;
            }
               
        }
        else{
            return true;
        }
    }
    
    return (
        
        <Fragment>
        <Card>
          
            <CardBody>
                <GridContainer>
                        {  <GridItem xs = {12} md = {12} className = { classes.gridItem } >  
                        <GridContainer >
                            <GridItem xs = {12} md = {12} >
                                <FormControl component = "fieldset" >
                                    <GridContainer>
                                        <GridItem xs = {12} md = {12} sm = {12} > <FormLabel component = "legend" style = {{ marginBottom : 0 }}  > Member Options </FormLabel> </GridItem> 
                                        <GridItem  >  
                                            <RadioGroup style={{color :"#138DE8"}} row aria-label = "user-type" name = "userType"  > 
                                          <FormControlLabel value = "All"    onChange = { event => { 
                                                        if (event.target.checked)  {
                                                            setCurrentRequest(prev => ({ ... prev, voucherRequestType : 'All'})); 
                                                            console.log(currentRequest);
                                                        }    
                                                    }
                                                }
                                                control = { <Radio style={{color :"#138DE8"}} />  } label = { 'All Users' } />  

                                                <FormControlLabel value = "Member Group" 
                                                onChange = { event => { 
                                                    if (event.target.checked)  {
                                                       setCurrentRequest(prev => ({ ... prev, voucherRequestType : 'Group'})); 
                                                       setCheck(true);
                                                       console.log(currentRequest);


                                                    }    
                                                }
                                            }
                                                control = { <Radio style={{color :"#138DE8"}}/>  } label = { 'Member Group' } /> 

                                                <FormControlLabel value = "GroupId" 
                                                onChange = { event => { 
                                                    if (event.target.checked)  {
                                                        setCurrentRequest(prev => ({ ... prev, voucherRequestType : 'Segment'})); 
                                                        console.log(currentRequest);

                                                    }    
                                                }
                                            }
                                                control = { <Radio style={{color :"#138DE8"}}/>  } label = { 'Member Segment' } />
                                            </RadioGroup>    
                                        </GridItem> 
                                    </GridContainer>
                                </FormControl>  
                            </GridItem>
                            </GridContainer >
                            </GridItem>}
                            
                           { currentRequest && currentRequest.voucherRequestType && currentRequest.voucherRequestType === 'Segment' && <GridItem xs = {12} md = {12} className = { classes.gridItem } >  
                        <GridContainer >
                            <GridItem xs = {12} md = {6} >
                                <FormControl component = "fieldset" >
                                    <GridContainer>
                                        <GridItem  >  
                                        <Autocomplete 
                                    
                                    className = { classes.autoComplete }
                                    options = { segmentList } 
                                    getOptionLabel={(option) => {
                                        
                                        return (option.name && option.name !== null) ? option.name : '' ;
                                    }}
                                    renderOption={(option) => option.name}
                                    onChange = { (event, value, reason) => {
                                        if (reason === "select-option") {
                                            let list = segmentList;
                                            let userSegmentId = null;
                                            if ( list && list.length > 0) { list = list.filter(i => i.name === value.name); }
                                            if (list.length > 0) { userSegmentId = list[0].id; }

                                       if (userSegmentId !== null) { setCurrentRequest(prev => ({ ... prev, memberSegmentId : userSegmentId}));   
                                          setCurrentRequest (prev => ({ ...prev, memberCount : value.memberCount }) );

                                        }
                                        }
                                    } }
                                    disableClearable	
                                    style = {{ width : 300, marginTop : 25 }} 
                                    renderInput = { params => <TextField { ...params } label = { 'Segment Selector' } onChange = { event => { segmentList.shift(); segmentList.unshift(event.target.value); setSegmentList (segmentList); } }  variant = 'outlined' /> } 
                                />
                                
                            
                                        </GridItem> 
                                    </GridContainer>
                                </FormControl>  
                            </GridItem>
                            </GridContainer >
                            </GridItem>}
     
   
                </GridContainer>
                <SearchUserDialog 
                            open = { check } 
                            userType = {"Member"}
                            setOpen = { setCheck }
                           currentRequest = { currentRequest }
                          setCurrentRequest = { setCurrentRequest }
                    />

            </CardBody>
            <CardFooter className = { classes.cardFooter } >  
                            <center>
                                <Button disabled={ checkDisabled()} variant = 'contained' style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { handleApply } > Apply Audience </Button>
                                <Button variant = 'contained' style = {{ backgroundColor : '#ff9017', color : 'floralwhite', textTransform : 'none',marginLeft:10}} onClick = { handleCancel } > Cancel </Button>

                            </center>
                        </CardFooter>
        </Card>
    
</Fragment>
        
    );
};

