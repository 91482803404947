import React from 'react';
import { Button, TextField, Dialog, DialogContent, DialogActions, DialogContentText , DialogTitle } from '@material-ui/core';
import { useState } from 'react';
import memberApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import FormDialog3 from './OTPScreen';
import BlockUi from 'react-block-ui';


export default function FormDialog2 (props) {
  const [open, setOpen] = useState(true);
  const [open2 , setOpen2] = useState(false);
  const [mail , setMail] = useState("");
  const [isLoading ,setIsLoading ] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleChange = () => {
    setIsLoading(true);
    memberApiService.sendOtp (mail)
    .then (response => {
      setOpen(false);
      setOpen2(true);

    })
    .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
  })
    .finally (() => setIsLoading (false) );
}


const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const valid = re.test(String(email).toLowerCase())
  return valid ;
};


const check = () => {
  if(mail.length < 1 ||  validateEmail(mail) ===false ) {
    return true ;
  }
  else{
    return false;
  }
}
   

  return (
    <div >
      <BlockUi blocking = { isLoading } message = { 'Please wait while we fetch.' } >

      { open  && <>
        <Dialog open={open} onClose={handleClose}   fullWidth={true}      maxWidth={"md"}>
        <center> <DialogTitle style={{width : "400px"}}>Forgot Password</DialogTitle> </center> 
        <DialogContent>
          <DialogContentText>
            <TextField
            margin="dense"
            id="name"
            label="Email"
            type="text"
            fullWidth
            variant="standard"
            onBlur ={ e=> setMail(e.target.value)}
            error = { validateEmail() }
          />
          </DialogContentText>
         
         
        </DialogContent>
        <DialogActions>
          <Button  style={{ background: "#FF9017" , textTransform: "none" , color: "floralwhite" }}  onClick={handleClose}>Cancel</Button>
          <Button  style={{ background: check() ?  "#CFCFCF" : "#138DE8" , textTransform: "none" , color: check() ? "black" : "floralwhite" }} disabled={check()} onClick={e=>handleChange()}>Send Otp</Button>
        </DialogActions>
      </Dialog>
      </>
      
      }
   </BlockUi>

      {open2 && <FormDialog3 mail={mail}/> }
    </div>
  );
}