import { AppBar, Button, Card, CardActions, IconButton, makeStyles, Tab, Tabs } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState } from 'react';
import { Fragment } from 'react';
import SearchBar from 'views/Merchant/SearchBar';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import { HWInput } from 'HWLibrary/HWInput';
import { Link } from 'react-router-dom';
import {PeriodDialog} from './VOCVerification';
import { DirectActionDialog } from './VOCVerification';
import VoucherTypeApproval from './VoucherTypeApproval';
import RewardVouchers from './RewardVouchers';


const useStyles = makeStyles(theme => ({
    root: {
      overflow: 'hidden',
      minHeight: 48,
      WebkitOverflowScrolling: 'touch', 
      display: 'flex',
      backgroundColor: "lightblue", 
      color : "black", 
      fontWeight : '200'
    }
}));

export default function VoucherApproval (props) {
    const classes = useStyles ();
    const [ selectedTab, setSelectedTab ] = useState (0);
    const changeTab = (event, index) => setSelectedTab (index); 

    return (
        <Fragment>
            <VoucherTypeApproval  />
        </Fragment>
    );
}