import Card from 'components/Card/Card';
import React, { useState, useEffect } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import People from '@material-ui/icons/People';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import SearchBar from '../SearchBar';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { makeStyles, Button } from '@material-ui/core';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 
import merchantApiService from '../APIService';
import HWMessage from 'HWLibrary/HWMessage';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import CampaignAnalytics from './CampaignAnalytics';
import { CustomToolbar } from 'views/Members/Member';

const useStyle = makeStyles(themes => ({ ...styles,
    root: {
        '& .MuiButton-label' : { color : '#138DE8' }
    } }));

function CustomLoadingOverlay() {
    return (
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
          <BorderLinearProgress />
        </div>
    );
};

export default function CampaignParticipation (props) {

    const [ member, setMember ] = useState (props && props.location && props.location.params && props.location.params.state  && props.location.params.state.member || JSON.parse(HWLocalStorage.load("merchantStore")) || {});

    
    const classes = useStyle();
    const [ loading, setLoading ] = useState (false);
    const [ searchText, setSearchText ] = useState (null);
    const [ refreshState, setRefreshState ] = useState (false);
    const [ rows, setRows ] = useState ([]);
    const [ rowCount, setRowCount ] = useState (0);
    const [ page, setPage ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ filterModel, setFilterModel ] = useState (null);
    const [ sortModel, setSortModel ] = useState (null);
    const [ merchantLoading, setMerchantLoading ] = useState (false);
    const [ merchant, setMerchant ] = useState ({});
    const [ merchantId, setMerchantId ] = useState (member && member.id);
    const [ showAnalytics, setShowAnalytics ] = useState (false);
    const [ analyticsCampaign, setAnalyticsCampaign ] = useState (null);

    const openAnalytics = () =>{  HWMessage.show ("SHowing" + showAnalytics, "info"); }

    const columns = [
        { field: 'campaignCode', headerName: 'Campaign Code', flex : 3},
        { field: 'title', headerName: 'Campaign Title', flex : 3 },
        { field: 'type', headerName: 'Campaign Type', flex : 3}
    ];

    
    useEffect (() => {
        setLoading (true);
        var paginationBookmark = (page) * pageSize;
        var fetchLimit = pageSize;
        merchantApiService.fetchMerchantOnlyCampaigns ({ merchantId, sortModel, paginationBookmark, filterModel, fetchLimit })
        .then (response => { 
            if (! response || ! response.data) return HWMessage.show ("Error while fetching the data", "error");
            setRows (response.data.campaignList);
            setRowCount ( response.data.campaignCount );
         }).catch (error => { 
            HWMessage.show ( "Error while fetching campaign list", "error");
        }).finally (() => setLoading (false));
    }, [ page, pageSize, sortModel, filterModel  ]);

    useEffect (() => {
        setMerchantLoading (true);
        merchantApiService.fetchMerchant (merchantId)
        .then (response => {
            if ( ! response || ! response.data ) { return HWMessage.show ("Merchant Cannot be fetched at moment", "error"); }
            setMerchant ( response.data );
        })
        .catch (error => { HWMessage.show ("Merchant cannot be fetched at moment", "error"); })
        .finally (() => setMerchantLoading (false));
    }, [  ]);

    return (
        <>
            <Card id="top">
                <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                    <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                    <h4 className = { classes.cardIconTitle } > Merchant List </h4> 
                </CardHeader>

                <CardBody>
                    <GridContainer>
                        <GridItem style={{marginBottom:"10px"}}>
                                <SearchBar 
                                    width = '500px' 
                                    handleSearch = { setSearchText }
                                    handleRefresh = { setRefreshState }
                                    refreshState = { refreshState }
                                    placeholder = { 'Search by Merchant Code, Campaign Name, etc.' }
                                /> 
                        </GridItem>
                    </GridContainer>
                        <DataGrid
                                className={classes.root} 
                                autoHeight
                                showToolbar 
                                density="compact" 
                                loading = {loading} 
                                fullWidth
                                columns={columns} 
                                rows={rows} 
                                rowCount = { rowCount }
                                paginationMode = 'server'
                                page = { page }
                                pageSize = { pageSize }
                                components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 
                                onPageChange = { (gridChangeParams) => {  setPage (gridChangeParams); } }
                                onPageSizeChange = { (gridChangeParams) => {  setPage (0); setPageSize(gridChangeParams); } }
                                rowsPerPageOptions ={ [25, 50, 100 ]}

                                filterMode = 'server'
                                onFilterModelChange = { (params) => { if (params.items.length >0 ) setFilterModel (params.items[0]);  }  }
                                sortingMode = 'server'
                                onSortModelChange = { params => { if (params.length > 0 ) setSortModel (params[0]); }  }
                        />
                </CardBody>
            </Card> 
        </>
    )
};


