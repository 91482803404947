import * as React from 'react';
import { Button, TextField, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import HWMessage from 'HWLibrary/HWMessage';
import memberApiService from 'views/Members/APIService';
import HWUser from 'HWLibrary/HWUser';
import { useState } from 'react';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import { sha512 } from 'js-sha512';
import authService from 'HWLibrary/ApiService';


export default function FormDialog() {
  const [open, setOpen] = React.useState(true);
  const [oldPassword , setOldPassword] = useState("");
  const [newPassword , setNewPassword] = useState("");
  const [newPasswordRentered , setNewPasswordRentered] = useState("");
  const [rulesLoading , setRulesLoading] = useState(false);

  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleChange = () => {

    if(oldPassword === "" || newPassword=== "" || newPasswordRentered==="")
    {
        return HWMessage.show("Please fill all fields" , "error");
    }
    if(newPassword !== newPasswordRentered)
    {
        return HWMessage.show("Passwords Not matched" , "error");

    }

    console.log(oldPassword , newPassword , newPasswordRentered);

        setRulesLoading (true);
        memberApiService.changePassword({oldPassword : sha512(oldPassword) , newPassword :sha512(newPassword) , newPasswordReEntered: sha512(newPasswordRentered) }).then (response => {
            authService.onLogOut();
            HWMessage.show("Password Change Successful" , "success");
    
        })
            
        .catch (error => { setRulesLoading (false); HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() =>{ setRulesLoading (false) } );
  };


  const check = () => {
    if(( (newPassword.length < 3)) || ( (newPasswordRentered.length < 3)))
    {
      return true ; 
    }
    else{
      return false ;
    }

  }

  return (
    <div >
      <Button variant="outlined" onClick={handleClickOpen}>
        Change Password            
      </Button>
      <Dialog open={open} onClose={handleClose}   fullWidth={true}      maxWidth={"md"}>
      <center><DialogTitle style={{width : "400px"}}>Change Password</DialogTitle> </center>   
        <DialogContent>
          <DialogContentText>
            <TextField
            margin="dense"
            id="name"
            label="Old Password"
            type="password"
            fullWidth
            variant="standard"
            onBlur ={ e=> setOldPassword(e.target.value)}
          />
          </DialogContentText>
         

<DialogContentText>
            <TextField
            
            margin="dense"
            id="name"
            label="New Password"
            type="password"
            fullWidth
            helperText = {"Password should be more than 3 characters in length."}
            variant="standard"
            onBlur ={ e=> setNewPassword(e.target.value)}
            error = { newPassword !== "" && newPassword.length < 3 }
          />

          </DialogContentText>
          

<DialogContentText>
            <TextField
            
            margin="dense"
            id="name"
            label="Confirm New Password"
            type="password"
            fullWidth
            variant="standard"
            onBlur ={ e=> setNewPasswordRentered(e.target.value)}
            error = { newPasswordRentered !== "" && newPasswordRentered.length < 3 }
          />
          </DialogContentText>
         
        </DialogContent>
        <DialogActions>
          <Button style={{ background: "#FF9017" , textTransform: "none" , color: "floralwhite" }} onClick={handleClose}>Cancel</Button>
          <Button style={{  background:  check () ? "#CFCFCF" : "#138DE8" , textTransform: "none" , color: check() ? "black" : "floralwhite" }} disabled={check()}  onClick={e=> handleChange()}>Change Password</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}