

const AttributeList = [ "dateOfBirth", "birthday",  "createTime", "emailVerifiedStatus", "gender", "membershipStatus", "mobileVerifiedStatus", "state", "city" ,"location" , "chassisNumber" , "bikeModel", "registrationDate"];
const OperatorList = [ 'IsEqual', 'IsNotEqual', 'Contains', 'GreaterThanEqual', 'LessThan', 'LessThanEqual', 'GreaterThan', 'NotContains' ];
const AttributeValueList = {
    "dateOfBirth" : {  
        type : 'ranged',
        values : [],
    },
    "birthday":{
        type: 'ranged',
        values:[ ],
    },
    "createTime": {
        type: 'ranged',
        values: [ ],
    },
    "emailVerifiedStatus": {
        type: 'specific',
        values: [ 'Verified', 'NotVerified', 'Pending', 'LinkExpired' ],
    },
    "mobileVerifiedStatus": {
        type: 'specific',
        values : [ 'Verified', 'NotVerified' ]
    },
    "gender": {
        type: 'specific',
        values: [ 'Male', 'Female', 'Others' ] 
    },
    "membershipStatus": {
        type: 'specific',
        values: [ 'Active', 'Inactive' ]
    },
    "state": {
        type: 'specific',
        values: [ 'ContinueAsFan', 'ContinueAsOwner', 'BikeAdded', 'ProfileSetupComplete', 'MembershipNumberGenerated', 'CardAdded', 'SetupComplete' ]
    },
    "city": {
        type: 'single',
        values: [ ]
    },
    "location":{
        type: 'single',
        values: []
    },
    "chassisNumber":{
        type:"single",
        values:[]
    },
    "bikeModel":{
        type:"specific",
        values : localStorage.getItem("bikeModels")!==null  && localStorage.getItem("bikeModels")!==undefined ? JSON.parse(localStorage.getItem("bikeModels")).map(i=> i.bikeModelName) : ["None"]
    },
    "registrationDate" : {
        type:"ranged",
        values:[]
    }



};

export { AttributeList, OperatorList, AttributeValueList };