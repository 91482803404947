import Dashboard from "views/Dashboards/Dashboard.js";
import Members from "views/Members/Member";
import MemberCreate from "views/Members/MemberCreate";
import RedeemIcon from '@material-ui/icons/Redeem';
// @material-ui/icons
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import Looks3Icon from '@material-ui/icons/Looks3';
import AccountTreeIcon from '@material-ui/icons/AccountTreeOutlined'; 
import PeopleIcon from '@material-ui/icons/People';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import SettingsIcon from '@material-ui/icons/Settings';
import DashboardIcon from "@material-ui/icons/Dashboard";
import { Build, Flare, Group, Money, NotificationsActive, PlaylistAddCheck, Redeem, Storefront } from "@material-ui/icons";
import Merchants from "views/Merchant/Merchants";
import MerchantDetails from "views/Merchant/MerchantDetails";
import MerchantTabs from "views/Merchant/MerchantTabs";
import MerchantPermissions from "views/Merchant/MerchantPermissions";
import ManageMembership from "views/Members/ManageMembership";
import MemberTransactions from "views/Members/MemberTransactions";
import PointTransactions from "views/Points/PointTransactions";
import MemberRewards from "views/Members/MemberRewards/MemberRewards";
import MemberTabs from "views/Members/MemberTabs";
import VoucherReward from "views/Vouchers/VoucherReward";
import Actions from "views/Actions/Actions";
import RewardVouchers from "views/Actions/RewardVouchers";
import VoucherTypeApproval from "views/Actions/VoucherTypeApproval";
import ValidationForms from "views/Forms/ValidationForms";
import TransactionDetails from "views/Merchant/TransactionDetails";
import authService from "HWLibrary/ApiService";
import Campaigns from "views/Campaigns/Campaigns";
import TestPopover from "views/DescriptionPopup/TestPopup";
import LoginPage from "views/Pages/LoginPage";
import CreateCampaign from "views/Campaigns/CreateCampaign";
import FilterElement from "views/UserSegment/FilterElement";
import CreateUserSegment from "views/UserSegment/CreateUserSegment";
import UserSegments from "views/UserSegment/UserSegments";
import CampaignSelection from "views/Campaigns/CampaignSelection";
import PointsConfiguration from "views/PointsConfiguration/PointsConfiguration";
import AudienceSelection from "views/PointsConfiguration/AudienceSelection";
import PointsRule from "views/Points/PointRule";
import BikeModels from "views/Points/BikeModels";
import Points from "views/Points/Points";
import FileUploader from "views/ImageUploader/FileUploader";
import RewardPoints from "views/PointsConfiguration/RewardPoints";
import ActionPoints from "views/Actions/ActionPoints";
import Notification from "views/Notification/Notification";
import OpsPermissions from "views/Settings/OpsPermission"; 

import CreateNotification from "views/Notification/CreateNotification"; 
import Vouchers from "views/Vouchers/Vouchers";
import VoucherManagement from "views/Vouchers/VoucherManagement";
import VoucherHistory from "views/Vouchers/VoucherHistory";
import VoucherRule from "views/Vouchers/VoucherRule";
import VoucherAudienceSelection from "views/Vouchers/VoucherAudienceSelection";
import VoucherCategory from "views/Vouchers/VoucherCategory";
import Settings from "views/Settings/Settings";
import MembershipCard from "views/MembershipCard/MembershipCard";
import Form from "views/PaymentTesting/Form";
import BanksList from "views/PaymentTesting/BanksList";
import HWLocalStorage from "HWLibrary/HWLocalStorage";
import DataGridSelectionTester from "views/UserSegment/Testing";
import AutocompleteTextArea from "views/Utils/TextAreaAutoComplete";
import PaymentRedirectInterceptor from "views/PaymentTesting/PaymentRedirectionInterceptor";
import CampaignParticipation from "views/Merchant/CampaignParticipation/CampaignParticipation";
import New from "views/Settings/New";
import SegmentUsingCsv from "views/UserSegment/SegmentUsingCsv";
import MerchantSegmentUsingCsv from "views/merchantSegments/CreateSegmentUsingCsv";
import MerchantSegments from "views/merchantSegments/MerchantSegments";
import SegmentInfo from "views/merchantSegments/segmentInfo";
import Transactions from "views/Transactions/Transactions";
import PendingTransactionDetails from "views/Transactions/PendingTransactionDetails"

 var actions=[]
 var members=[]
 var campaigns=[]
 var cards=[]
 var merchants=[]
 var points=[]
 var settings=[]
 var usersegments=[]
 var notifications=[]
 var vouchers=[]

 var obj = JSON.parse(HWLocalStorage.load("Permissions"))
 console.log(HWLocalStorage.load("Permissions"));
 if(HWLocalStorage.load("Permissions")!==null)
 {
  actions= [...obj["Actions"]];
  members= [...obj["Members"]];
  campaigns= [...obj["Campaigns"]];
  cards= [...obj["Cards"]];
  merchants= [...obj["Merchants"]];
  notifications= [...obj["Notifications"]];
  points= [...obj["Points"]];
  settings= [...obj["Settings"]];
  usersegments= [...obj["UserSegments"]];
  vouchers= [...obj["Vouchers"]];
};

const dashRoutes = [
  { path: "/member-tabs",          name: "Members",               icon: Redeem,              component: MemberTabs,           layout: '/ops', hiddenFromSidebar:true }, 
  { path: "/members",              name: "Members",               icon: PeopleIcon,          component: Members,              layout: '/ops', hiddenFromSidebar: members.length === 0 ? true : false},
  { path: "/member-tabs",          name: "Members",               icon: Redeem,              component: MemberTabs,           layout: '/ops', hiddenFromSidebar:true },
  { path: "/actions",              name: "Actions",               icon: PlaylistAddCheck,    component: Actions,              layout: '/ops', hiddenFromSidebar: actions.length === 0 ? true : false },
  { path: "/campaigns",            name: "Campaigns",             icon: BurstModeIcon ,      component: Campaigns,            layout: '/ops', hiddenFromSidebar:campaigns.length === 0 ? true : false  },
  { path: "/points",               name: "Points",                icon: Looks3Icon,          component: Points,               layout: '/ops', hiddenFromSidebar:points.length === 0 ? true : false  },
  { path: "/notifications",        name: "Notifications",         icon: NotificationsActive, component: Notification,         layout: '/ops', hiddenFromSidebar:notifications.length === 0 ? true : false  },
  { path: "/member-details",       name: "Member Details",        icon: Storefront,          component: MemberCreate,         layout: '/ops' ,hiddenFromSidebar:true },
  { path: "/merchants",            name: "Merchants",             icon: Storefront,          component: Merchants,            layout: '/ops' ,hiddenFromSidebar: true },
  { path: "/merchant-details",     name: "Merchant Details",      icon: Storefront,          component: MerchantDetails,      layout: '/ops', hiddenFromSidebar:true },
  { path: "/merchant-tabs",        name : "Merchant",             icon : Storefront,         component : MerchantTabs,        layout: '/ops', hiddenFromSidebar : merchants.length === 0 ? true : false  },
  { path: "/merchant-permissions", name : "Merchant Permissions", icon : Storefront,         component : MerchantPermissions, layout: '/ops', hiddenFromSidebar : true },
  { path: "/manage-membership",    name: "Manage Membership",     icon: Storefront,          component: ManageMembership,     layout: '/ops', hiddenFromSidebar:true },
  { path: "/member-transactions",  name: "Member Transactions",   icon: Storefront,          component: MemberTransactions,   layout: '/ops', hiddenFromSidebar:true },
  { path: "/point-transactions",   name: "Point Transactions",    icon: Storefront,          component: PointTransactions,    layout: '/ops', hiddenFromSidebar:true },
  { path: "/merchant-rewards",     name: "Merchant Rewards",      icon: Storefront,          component: MemberRewards,        layout: '/ops', hiddenFromSidebar:true },
  { path: "/voucher-reward",       name: "Voucher Reward",        icon: Storefront,          component: VoucherReward,        layout: '/ops', hiddenFromSidebar:true },
  { path: "/reward-vouchers",      name: "Reward Vouchers",       icon: Storefront,          component: RewardVouchers,       layout: '/ops', hiddenFromSidebar:true },
  { path: "/voucher-approval",     name: "Voucher Approval",      icon: Storefront,          component: VoucherTypeApproval,  layout: '/ops/actions', hiddenFromSidebar:true },
  { path: "/validation-forms",     name: "Validation Forms",      icon: Storefront,          component: ValidationForms,      layout: '/ops', hiddenFromSidebar:true },
  { path: "/transaction-details",  name: "Transaction Details",   icon: Storefront,          component: TransactionDetails,   layout: '/ops', hiddenFromSidebar:true },
  { path: "/test-popup",           name: "Transaction Details",   icon: Storefront,          component: TestPopover,          layout: '/ops', hiddenFromSidebar:true },
  { path: "/login-page",           name: "Login Page",            icon:  VpnKeyIcon,         component: LoginPage,            layout: '/auth',  hiddenFromSidebar:true },
  { path: "/interceptor-page",     name: "Interceptor",           icon:  VpnKeyIcon,         component: PaymentRedirectInterceptor, layout: '/pay',  hiddenFromSidebar:true},
  { path: "/create-campaign",      name: "Create Campaign",       icon: Storefront,          component: CreateCampaign,       layout: '/ops',  hiddenFromSidebar:true },
  { path: "/filter-element",       name: "Filter Element",        icon: Storefront,          component: FilterElement,       layout: '/ops',  hiddenFromSidebar:true },
  { path: "/create-user-segment",  name: "Create or Update Segment",icon: Storefront,   component: CreateUserSegment,   layout: '/ops',  hiddenFromSidebar:true },
  { path: "/user-segments",        name: "Member Segments",          icon: AccountTreeIcon ,   component: UserSegments,        layout: '/ops',  hiddenFromSidebar: usersegments.length === 0 ? true : false },
  { path : "/merchant-segments",        name: "Merchant Segments",          icon: AccountTreeIcon ,   component: MerchantSegments,        layout: '/ops',  hiddenFromSidebar: usersegments.length === 0 ? true : false },
  { path: "/campaign-selection",   name: "Campaign Selection",     icon: Storefront,          component: CampaignSelection,   layout: '/ops',  hiddenFromSidebar:true },
  { path: "/points-config",        name: "Points Configuration",   icon: Storefront,          component: PointsConfiguration, layout: '/ops',  hiddenFromSidebar:true },
  { path: "/audience-selection",      name: "Audience Selection",  icon: Storefront,          component: AudienceSelection,   layout: '/ops',  hiddenFromSidebar:true },
  { path: "/points-rule",          name: "Points Rule",            icon: Storefront,          component: PointsRule,          layout: '/ops',  hiddenFromSidebar:true },
  { path: "/model-names",          name: "Model Names",            icon: Storefront,          component: BikeModels,          layout: '/ops',  hiddenFromSidebar:true },
  { path: "/file-uploader",        name: "File Uploader",          icon: Storefront,          component: FileUploader,        layout: '/ops',  hiddenFromSidebar:true },
  { path : "/reward-points",       name: "Reward Points",          icon : Storefront,         component: RewardPoints,        layout: '/ops',  hiddenFromSidebar: true },
  { path : "/action-points",       name: "Reward Points",          icon : Storefront,         component: ActionPoints,        layout: '/ops',  hiddenFromSidebar: true },
  { path : "/create-notification", name: "Create Notification",    icon : NotificationsActive,component: CreateNotification,  layout: '/ops',  hiddenFromSidebar: true },
  { path : "/vouchers",           name: "Vouchers",                icon : CreditCardIcon,         component: Vouchers,            layout: '/ops',  hiddenFromSidebar: vouchers.length === 0 ? true : false },
  { path : "/voucher-management", name: "Voucher Management",      icon : Storefront,         component: VoucherManagement,   layout: '/ops',  hiddenFromSidebar: true },
  { path : "/voucher-history",    name: "Voucher History",         icon : Storefront,         component: VoucherHistory,            layout: '/ops',  hiddenFromSidebar: true },
  { path : "/voucher-audience-selection",    name: "Voucher Audience Selection",         icon : Storefront,         component: VoucherAudienceSelection,            layout: '/ops',  hiddenFromSidebar: true },
  { path : "/voucher-rule",        name: "Voucher Rule",          icon : Storefront,         component: VoucherRule,            layout: '/ops',  hiddenFromSidebar: true },
  { path : "/settings",            name: "Settings",              icon : SettingsIcon,         component: Settings,            layout: '/ops',  hiddenFromSidebar: settings.length === 0 ? true : false },
  { path : "/cards",               name: "Membership Card",       icon : RedeemIcon,         component: MembershipCard,            layout: '/ops',  hiddenFromSidebar: cards.length === 0 ? true : false },
  { path : "/ops-permissions",     name: "Ops Permissions",       icon : RedeemIcon,         component: OpsPermissions,            layout: '/ops',  hiddenFromSidebar: true },
  { path : "/pay-banks",           name: "Payment Form",          icon : RedeemIcon,         component: BanksList,            layout: '/ops',  hiddenFromSidebar: false },
  { path : "/transactions",        name: "Transactions",          icon : CreditCardIcon,         component:Transactions,            layout: '/ops',  hiddenFromSidebar: false },
  { path : "/pending-transaction-details",       name: "Pending Transaction Details",          icon : Storefront,         component: PendingTransactionDetails,        layout: '/ops',  hiddenFromSidebar: true },
  { path : "/datagrid-test",       name: "DataGrid",              icon : RedeemIcon,         component: DataGridSelectionTester, layout: '/ops',  hiddenFromSidebar: true },
  { path : "/campaign-participation",       name: "Campaign Participation",              icon : RedeemIcon,         component: CampaignParticipation, layout: '/ops',  hiddenFromSidebar: true },
  { path : "/create-segment-csv",       name: "Create Segment using CSV",              icon : RedeemIcon,         component: SegmentUsingCsv, layout: '/ops',  hiddenFromSidebar: true },
  { path : "/create-merchant-segment-csv",       name: "Create Merchant Segment using CSV",              icon : RedeemIcon,         component: MerchantSegmentUsingCsv, layout: '/ops',  hiddenFromSidebar: true },
  { path : "/segment-info",       name: "Segment Information",              icon : RedeemIcon,         component: SegmentInfo, layout: '/ops',  hiddenFromSidebar: true },


  
  // { path : "/autocomplete-test",       name: "DataGrid",              icon : RedeemIcon,     component: AutocompleteTextArea, layout: '/ops',  hiddenFromSidebar: false }

  // { collapse: true, name: "Settings", icon: Settings, state: 'pageCollapse', layout: '/ops', hiddenFromSidebar : false, views: [
  //   { path: "/settings/configuration", name: "Configuration", icon: Build, mini: "CF", component: Dashboard, layout: '/ops', hiddenFromSidebar : true },
  //   { path: "/configuration/points",   name: "Points",        icon: Money, mini: "PS", component: Dashboard, layout: '/ops' },
  // ]},
  //   { name: 'Dashboards', icon: DashboardIcon, collapse: true, state: 'pageCollapse', hiddenFromSidebar :false, views: [
      
  //     { path : "/members/autocomplete-test", name: "DataGrid", icon : RedeemIcon, component: AutocompleteTextArea, layout: '/ops',  hiddenFromSidebar: false }, 
      

  //   { path: "/dashboards/system",    name: "System",    mini: "SY", component: Dashboard, layout: '/ops',hiddenFromSidebar:true },
  //   { path: "/dashboards/members",   name: "Members",   mini: "MB", component: Dashboard, layout: '/ops' ,hiddenFromSidebar:true },
  //   { path: "/dashboards/campaigns", name: "Campaigns", mini: "CM", component: Dashboard, layout: '/ops',hiddenFromSidebar:true  },
  //   { path: "/dashboards/points",    name: "Points",    mini: "PT", component: Dashboard, layout: '/ops',hiddenFromSidebar:true  },
  //   { path: "/dashboards/orders",    name: "Orders",    mini: "OR", component: Dashboard, layout: '/ops',hiddenFromSidebar:true  },
  // ]}
];

export default dashRoutes;