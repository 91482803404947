import { Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import CampaignSwitch from 'views/Campaigns/CampaignSwitch';
import DescriptionPopover from './DescriptionPopup';

export default function TestPopover (props) {

    const [ open, setOpen ] = useState (false);
    const [ description, setDescription ] = useState (null);
    const [ anchorEl, setAnchorEl ] = useState (null);

    const handleClick = event => { setOpen (true); setAnchorEl (event.currentTarget);}
    const handleSubmit = (description) => setDescription (description);

    return (
        <Fragment>
            <h1> {description} </h1>
            <Button onClick = { handleClick } > Show </Button>
            <DescriptionPopover  
                label = { 'Description' }
                description = { description }
                handleSubmit = { handleSubmit }
                setDescription = { setDescription }
                open = { open }
                anchorEl = { anchorEl }
                setAnchorEl = { setAnchorEl }
                placeholder = { 'Enter Description' }
                setOpen = { setOpen }
            />

            <CampaignSwitch 
                label = "Enable"
            />
        </Fragment>
    );
}