import { Button, InputAdornment, makeStyles , TextField } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from 'HWLibrary/HWInput';
import pointsApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import { useCallback } from 'react';
import BlockUi from 'react-block-ui';
import { DropDown } from 'views/Merchant/Merchant';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import { DropzoneDialog } from 'material-ui-dropzone';
import settingsApiService from './APIService';
import { Autocomplete } from '@material-ui/lab';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import memberApiService from 'views/Members/APIService';
import Edit from '@material-ui/icons/Edit';
import StatusDialog from 'views/Utils/StatusDialog';

const useStyles = makeStyles (theme => ({
    ...styles,
    saveButton :  { color: 'floralwhite', backgroundColor : '#138DE8' , margin: 2.5 },
    cancelButton : { color: 'floralwhite', backgroundColor : '#FF9017' , margin: 2.5},
    card : { width : 700 }
}));

export default function RuleForm (props) {

    var settings=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    settings= [...obj["Settings"]];

    const classes = useStyles();
    const [openSingle, setOpenSingle] = React.useState(false);

    const [ currentRule, setCurrentRule ] = useState ({});
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ productCategoryList, setProductCategoryList ] = useState ([]);
    const [ tierList, setTierList ] = useState ([]);
    const { createMode, ruleId } = props;
    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});
    const [ saveOpen, setSaveOpen ] = useState (false);
    const [ createOpen, setCreateOpen ] = useState (false);
    const [ membersAffected , setMembersAffected] = useState(-1);
    const [ edit , setEdit] = useState(false);
    const [ open2 , setOpen2] = useState(false);
    const [ open3 , setOpen3] = useState(false);

    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    }


    const isValid = rule => {
        let { validityStart, validityEnd } = rule;
        var startDate = new Date (validityStart);
        var endDate = new Date (validityEnd);
        var currentDate = new Date();
        
        return ! (endDate >= currentDate);
    }; 
    const isDisabled = false;


    useEffect (() => {
        if(props.createMode == false){
            setRuleLoading (true);
           
            settingsApiService.fetchSpecificMembershipCategorry (props.ruleId)
            .then (response => {  
                if (! response || ! response.data) { return HWMessage.show ("Specific Category Cannnot be fetched" ,"error"); }
                setCurrentRule (response.data);
                // var noneObject = { id : null, bikeQuantity : null, tierName : 'None' };
                // props.List.push(noneObject);
                HWMessage.show("Request Successful" , "success");

            })
            .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => setRuleLoading (false));
        }

        
       
    }, [])

   
console.log(currentRule);




    useEffect (() => {
        memberApiService.fetch({ filterModel : { columnField : "tier", value : currentRule.tierName , operatorValue: "equals"} ,  "paginationBookmark" : 0 , "fetchLimit" : 0  }) .then (response => {  
            if (! response || ! response.data) { HWMessage.show ("Error while fetching response","error"); }
            console.log(response.data);
            setMembersAffected (response.data.membersCount);
        }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
    }, [currentRule])

    



    const updateRule = useCallback (rule => {
        if (rule !== null) {
            let successStatus = null;
            setRuleLoading (true);
            if(rule && (rule.upgradeTier === null || !rule.upgradeTier ))
            {
                rule.upperTierId = null ;
            }
            if(rule && (rule.downgradeTier === null || !rule.downgradeTier))
            {
                rule.lowerTierId = null ;
            }

           
             
            settingsApiService.updateMembershipCategory (rule) 
            .then (response => {  
                if (! response || ! response.data) { HWMessage.show ("Error while fetching response","error"); }
                setCurrentRule (response.data);
                props.setRefreshState (prev=> !props.refreshState);
                setOpen3(true);

                successStatus = true;
                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);  
                if (successStatus === true)
                {                    
                    props.setDirectActionOpen(false) 
                    props.setRefreshState(prev=>!prev)

                }            });
                        }
    });
    const createRule = useCallback (rule => {
        if (rule !== null)  {
            let successStatus = null;
            setRuleLoading (true);
            if(rule && (rule.upgradeTier === null || !rule.upgradeTier ))
            {
                rule.upperTierId = null ;
            }
            if(rule && (rule.downgradeTier === null || !rule.downgradeTier))
            {
                rule.lowerTierId = null ;

            }

         


            settingsApiService.createMembershipCategory (rule)
            .then (response => {
                if (! response || ! response.data) { HWMessage.show ("Error while fetchinmg response" , "error"); }
                setCurrentRule (response.data);
                props.setRefreshState (prev=> !props.refreshState);
                successStatus = true;
                setOpen2(true);
                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);
                if (successStatus === true)
{                    
    props.setDirectActionOpen(false) 

}            });
        }
    });

    const getValue = () =>{
        return currentRule  && currentRule.validity && currentRule.validity.replace(/[^0-9\.]+/g, "");
    }
    

    const captureChanges = event => { 
        const field = event.target.name;
        const value = event.target.value;      
        console.log(value);
        switch(field)
        {
          
            case 'bikeQuantity':
                {
                   
                        setCurrentRule (prev => ({ ...prev, [field] : Math.abs(value) })); 
                        break; 
                    
                }

            case 'validity': 
            if(currentRule && currentRule.validityType === "Fixed" ) 
            {
                if(value!==undefined && value!=='' && value!==null ){
               
                setCurrentRule (prev => ({ ...prev, [field] : "P"+ Math.abs(value) + "Y" }));
                break;
                }
            } 
            else{
                if(value!==undefined && value!=='' && value!==null ){
                setCurrentRule (prev => ({ ...prev, [field] : "P"+ Math.abs(value) + "M" }));
                break;
                }
            }

            default :   setCurrentRule (prev => ({ ...prev, [field] : value }));
        }  
        console.log(currentRule);
        
    };


    const handleSave = () => {
        if (props.createMode === true) { createRule (currentRule); } 
        else if (props.createMode === false) { updateRule (currentRule); }
    };
    const handleCancel = () => { props.setOpen (prev => false);     props.setDirectActionOpen(false) 
    };

    const checkDisabled = () => {
        if(
            (currentRule && currentRule.tierName && currentRule.tierName!== '') &&
             (currentRule.bikeQuantity !=="" && currentRule.bikeQuantity!==undefined &&   currentRule.bikeQuantity>=0) && 
             ( currentRule.validityType && currentRule.validityType!=='') && 
        // ((currentRule.downgradeTier && currentRule.downgradeTier !=='') || (currentRule.upgradeTier && currentRule.upgradeTier!=='') || (currentRule.lowerTierId && currentRule.lowerTierId!=='') || ((currentRule.upperTierId && currentRule.upperTierId!=='')) ) 
         
         (currentRule.processFlag && currentRule.processFlag!=='')
        && currentRule.tierDescription && currentRule.tierDescription!=='' ){

            if( props.createMode === true && currentRule && currentRule.validityType && currentRule.validityType==="Fixed" && currentRule.validity && currentRule.validity.replace(/[^0-9\.]+/g, "") > 0) {
                return false;

            }
           

           else if( props.createMode === false && currentRule && currentRule.validityType && currentRule.validityType==="Fixed" && currentRule.validity &&  currentRule.validity.replace(/[^0-9\.]+/g, "") > 0) {
                return false;

            }
            else if (props.createMode=== true && currentRule && currentRule.validityType && currentRule.validityType==="Rolling" &&  currentRule.validity && currentRule.validity.replace(/[^0-9\.]+/g, "") > 0) {
                return false;

            }
            else if (props.createMode === false && currentRule && currentRule.validityType && currentRule.validityType==="Rolling" && currentRule.validity &&  currentRule.validity.replace(/[^0-9\.]+/g, "") > 0) {
                return false;

            }
            else if(  currentRule && currentRule.validityType && currentRule.validityType==="Never" ) {
                return false;

            }
            else{
                return true ; 
            }
        }
        else{
            return true ; 
        }
     }; 


    const fetchLowerTiers = bikeQuantity => { 
        var maxMin = -1;
        var ans = null;
        console.log ("ksjbhadjha", props.List, bikeQuantity);
        if (props.List && bikeQuantity) {
            for (var i = 0; i < props.List.length; i++) {
                console.log ("udhw,akwqd,wkdbwqwq", maxMin, props.List[i]);
                if (bikeQuantity > props.List[i].bikeQuantity) {
                    if (maxMin < props.List[i].bikeQuantity) {
                        console.log ("udhw,akwqd,wkdbwqwq", maxMin);
                        maxMin = props.List[i].bikeQuantity;
                        ans = props.List[i];
                    }   
                }
            }
        }
        var noneObject = { id : null, bikeQuantity : null, tierName : 'None' };

        if (ans) { return [ ans , noneObject   ]; }
        //return props.List.filter (i => i.bikeQuantity < bikeQuantity); 
        return [ ];
    };
    const fetchUpperTiers = bikeQuantity => { 
        var maxMin = 10000;
        var ans = null;
        console.log ("ksjbhadjha", props.List, bikeQuantity);
        if (props.List && bikeQuantity) {
            for (var i = 0; i < props.List.length; i++) {
                console.log ("udhw,akwqd,wkdbwqwq", maxMin, props.List[i]);
                if (bikeQuantity < props.List[i].bikeQuantity) {
                    if (maxMin > props.List[i].bikeQuantity) {
                        console.log ("udhw,akwqd,wkdbwqwq", maxMin);
                        maxMin = props.List[i].bikeQuantity;
                        ans = props.List[i];
                    }   
                }
            }
        }
        var noneObject = { id : null, bikeQuantity : null, tierName : 'None' };

        if (ans) { return [ ans , noneObject   ]; }
        //return props.List.filter (i => i.bikeQuantity < bikeQuantity); 
        return [ ];
         // return  props.List.filter (i => i.bikeQuantity > bikeQuantity); 
    };

    const setValue2 = (field) => {
        switch(field)
        {
            case "Yes" :        setCurrentRule(prev => {  prev.processFlag = "Applicable";  return { ...prev }; });   break;
            case "No" :        setCurrentRule(prev => {  prev.processFlag = "NotApplicable";  return { ...prev }; });    break;

        }
    }
    
   
    const getValue2 = () => {
        console.log(currentRule && currentRule.processFlag);

        switch(currentRule && currentRule.processFlag)
        {
            case "Applicable" :           return   "Yes" ; break;
            case "NotApplicable" :         return   "No" ; break;
            

        }
    }


    var upper =  fetchLowerTiers (currentRule && currentRule.bikeQuantity);
    if(upper.length === 0 )
         upper.push({id: null , tierName: 'None'});
    var lower =  fetchUpperTiers(currentRule && currentRule.bikeQuantity);
    if(lower.length === 0 )
         lower.push({id: null , tierName: 'None'});


    console.log(lower , upper);
        return (
        <>
             <BlockUi blocking = { ruleLoading } message = { 'Please wait while we fetch.' } >

                <Card className = { classes.card } >
{                  props.createMode === true &&  <CardHeader style={{ margin : 0 , padding : 5}}> <center> <h3>  Tier Configuration </h3>   </center>   </CardHeader>
}     
{          props.createMode === false &&      <CardHeader style={{ margin : 0 , padding : 5}}> <h3>  Tier Configuration   &nbsp; <a style={{cursor: "pointer"}}><Edit style={{color: "#138de8"}} onClick={e=>setEdit(!edit)} /></a></h3>   </CardHeader>
}
                    <CardBody>  
                        <GridContainer>
                        <GridItem xs = {6} md = {12} sm = {6} >  
                            <center>
                            <HWInput label = { 'Membership Tier' }  
                                                    name = { 'tierName' } 
                                                     hasError = { checkError ('tierName') } 
                                                    helperText = { fetchErrorMessage ('tierName') }
                                                    onBlur = { captureChanges }
                                                    hasError = { checkError ('tierName') }
                                                    disabled = {props.createMode === false ? true : false}
                                                    value={currentRule && currentRule.tierName}
                                                    required = {true}
                                                /> 

                            </center>
                               
                            </GridItem>

                            <GridItem xs = {6} md = {12} sm = {6} >  
                            <center>
                            <HWInput label = { 'Bike Qty.' }  
                                                    name = { 'bikeQuantity' } 
                                                    helperText = { currentRule && currentRule.bikeQuantity < 0 ? "Invalid Value" : "" }
                                                    onBlur = { captureChanges }
                                                    hasError = { currentRule && currentRule.bikeQuantity < 0 }
                                                    disabled = {props.createMode === false ? !edit : false}
                                                    value={currentRule && currentRule.bikeQuantity}
                                                    required = {true}
                                                /> 

                            </center>
                               
                            </GridItem>
                            
                            <GridItem xs = {6} md = {12} sm = {6} > <br/> </GridItem>

                            <GridItem xs = {6} md = {6} sm = {6} >  
                          { props.createMode === false && <Autocomplete
                                        value={ currentRule.downgradeTier ? currentRule.downgradeTier : 'None'   }
                                        className = { classes.autoComplete }
                                        options = { props.List.filter(i=>i.tierName !== currentRule.tierName ).map(i => i.tierName)  } 
                                        getOptionSelected = { i => console.log(i) }
                                        getOptionLabel={(option) => { return option; }}
                                        disabled = {props.createMode === false ? !edit : false}

                                        onChange = { (event, value, reason) => {
                                          //  alert(JSON.stringify(value) + " " +reason);
                                            if (reason === "select-option") {
                                                // let list = props.List.map(i => i.tierName);
                                                let tierId =  props.List.filter(i => i.tierName === value )   ;
                                                console.log(tierId);
                                                console.log (value);
                                                if (tierId.length !== 0) {  setCurrentRule (prev => ({ ...prev, lowerTierId : tierId[0].id })) }
                                                var matchingList = props.List.filter (i => i.tierName === value);
                                                var matchingOption = null;
                                                if (matchingList && matchingList.length > 0) 
                                                      matchingOption = matchingList[0].tierName;  
                                                if( (matchingOption !== undefined || matchingOption  !== '') )
                                                     setCurrentRule (prev => ({ ...prev, downgradeTier : matchingOption  }) )
                                            }
                                        } }
                                        disableClearable	
                                        style = {{ width : 300, marginTop : 25 }} 
                                        required = {true}
                                        renderInput = { params => <TextField { ...params } label = { 'Downgrade Path' }   variant = 'outlined' /> } 
                            />
                           }

                { props.createMode === true && currentRule.bikeQuantity>=0 && <Autocomplete
                                        value={ currentRule.downgradeTier ? currentRule.downgradeTier : 'None'   }
                                        className = { classes.autoComplete }
                                        options = { props.List.filter(i=>i.tierName !== currentRule.tierName ).map(i => i.tierName)  } 
                                        getOptionSelected = { i => console.log(i) }
                                        disabled = {props.createMode === false ? !edit : false}
                                        required = {true}
                                        getOptionLabel={(option) => { return option; }}
                                        onChange = { (event, value, reason) => {
                                          //  alert(JSON.stringify(value) + " " +reason);
                                            if (reason === "select-option") {
                                                // let list = props.List.map(i => i.tierName);
                                                let tierId =  props.List.filter(i => i.tierName === value )   ;
                                                console.log(tierId);
                                                console.log (value);
                                                if (tierId.length !== 0) {  setCurrentRule (prev => ({ ...prev, lowerTierId : tierId[0].id })) }
                                                var matchingList = props.List.filter (i => i.tierName === value);
                                                var matchingOption = null;
                                                if (matchingList && matchingList.length > 0) 
                                                      matchingOption = matchingList[0].tierName;  
                                                if( (matchingOption !== undefined || matchingOption  !== '') )
                                                     setCurrentRule (prev => ({ ...prev, downgradeTier : matchingOption  }) )
                                            }
                                        } }
                                        disableClearable	
                                        style = {{ width : 300, marginTop : 25 }} 
                                     renderInput = { params => <TextField { ...params } label = { 'Downgrade Path' }   variant = 'outlined' /> } 
                            />
                           }

                        
                                
                            </GridItem>
                            <GridItem xs = {6} md = {6} sm = {6} > 
                            
                            { props.createMode === false &&  <Autocomplete
                                        value={ currentRule.upgradeTier ? currentRule.upgradeTier : 'None'  }
                                        className = { classes.autoComplete }
                                     //   options = { props.upper.map(i => i.tierName) } 
                                     options = { props.List.filter(i=>i.tierName !== currentRule.tierName ).map(i => i.tierName)  } 

                                        // getOptionLabel={(option) => {
                                        //     return option.tierName;
                                        // }}
                                        disabled = {props.createMode === false ? !edit : false}
                                        required = {true}
                                        getOptionLabel={(option) => { return option; }}
                                        onChange = { (event, value, reason) => {
                                         //   alert(JSON.stringify(value) + " " +reason);
                                            if (reason === "select-option") {
                                                // let list = props.List.map(i => i.tierName);
                                                let tierId =  props.List.filter(i => i.tierName === value   )   ;
                                                console.log (tierId);

                                                console.log (value);
                                                 if (tierId.length !== 0 ) {  setCurrentRule (prev => ({ ...prev, upperTierId : tierId[0].id  })) }
                                                var matchingList = props.List.filter (i => i.tierName === value);
                                                var matchingOption = null;
                                                if (matchingList && matchingList.length > 0) 
                                                      matchingOption = matchingList[0].tierName;  
                                                if((matchingOption !== undefined || matchingOption  !== '') )
                                                     setCurrentRule (prev => ({ ...prev, upgradeTier : matchingOption  }) )
                                            }
                                        } }
                                        disableClearable	
                                        style = {{ width : 300, marginTop : 25 }} 
                                     renderInput = { params => <TextField { ...params } label = { 'Upgrade Path' }   variant = 'outlined' /> } 
                            />}


                            { props.createMode === true &&  currentRule.bikeQuantity>=0 && <Autocomplete
                                       value={ currentRule.upgradeTier ? currentRule.upgradeTier : 'None'  }
                                       className = { classes.autoComplete }
                                    //   options = { props.upper.map(i => i.tierName) } 
                                       options = {  props.List.map(i => i.tierName) } 

                                       // getOptionLabel={(option) => {
                                       //     return option.tierName;
                                       // }}
                                       disabled = {props.createMode === false ? !edit : false}
                                       required = {true}
                                       getOptionLabel={(option) => { return option; }}
                                       onChange = { (event, value, reason) => {
                                          // alert(JSON.stringify(value) + " " +reason);
                                           if (reason === "select-option") {
                                               // let list = props.List.map(i => i.tierName);
                                               let tierId =  props.List.filter(i => i.tierName === value   )   ;
                                               console.log (tierId);

                                               console.log (value);
                                                if (tierId.length !== 0 ) {  setCurrentRule (prev => ({ ...prev, upperTierId : tierId[0].id  })) }
                                               var matchingList = props.List.filter (i => i.tierName === value);
                                               var matchingOption = null;
                                               if (matchingList && matchingList.length > 0) 
                                                     matchingOption = matchingList[0].tierName;  
                                               if((matchingOption !== undefined || matchingOption  !== '') )
                                                    setCurrentRule (prev => ({ ...prev, upgradeTier : matchingOption  }) )
                                           }
                                       } }
                                       style = {{ width : 300, marginTop : 25 }} 
                                       disableClearable	
                                    renderInput = { params => <TextField { ...params } label = { 'Upgrade Path' }   variant = 'outlined' /> } 
                            />
                           }

                            

                            </GridItem>
                            
                            <GridItem xs = {6} md = {12} sm = {6} >  
                             <br/>
                            </GridItem>


                            <GridItem xs = {6} md = {6} sm = {6} >  
                           
                           <DropDown 
                                   labelCategory = "Process Flag" 
                                   name = "processFlag"
                                   values = { ['Yes' , 'No' ] }
                                   minWidth = {300}
                                   targetValue = { getValue2() }
                                   onChange = { e=> setValue2(e.target.value)  }
                                  
                                 //  onBlur = { captureChanges }
                                   helperText = { fetchErrorMessage ('processFlag') }
                                   hasError = { checkError ('processFlag') }
                                   disabled = {props.createMode === false ? !edit : false}
                                   required = {true}
                                   />  

                           </GridItem>
                       
                            <GridItem xs = {6} md = {12} sm = {6} >  
                             <br/>
                            </GridItem>


                                            <GridItem xs = {6} md = {6} sm = {6} > 
                                            <DropDown 
                                    labelCategory = "Points Type" 
                                    name = "validityType"
                                //    values = { tierList && tierList.map (i => i.tierName) }
                                    minWidth = {300}
                                    values= {['Fixed' , 'Rolling' , 'Never']}
                                    targetValue = { currentRule && currentRule.validityType }

                                    onChange = { e => { setCurrentRule(prev => {  prev.validityType = e.target.value; return { ...prev }; });  } }
                                    onBlur = { captureChanges }
                                    helperText = { fetchErrorMessage ('validityType') }
                                    hasError = { checkError ('validityType') }
                                    disabled = {props.createMode === false ? !edit : false}
                                    required = {true}
                                    />  

                         </GridItem>
    
                      {currentRule && currentRule.validityType === "Fixed" &&  <GridItem xs = {6} md = {6} sm = {6} > 
                                 <HWInput label = { 'Period' } 
                                                    name = { 'validity' }
                                                    helperText = { (currentRule && currentRule.validity  && currentRule.validity.replace(/[^0-9\.]+/g, "") > 0) || (currentRule && currentRule.validity === undefined )   ? "" : "Invalid Value" }
                                                    hasError = {  (currentRule && currentRule.validity  && currentRule.validity.replace(/[^0-9\.]+/g, "") > 0) || (currentRule && currentRule.validity === undefined )  ? false : true } 
                                                    onBlur = { captureChanges }
                                                    disabled = {props.createMode === false ? !edit : false}
                                                    endAdornment={<InputAdornment position="end">Years</InputAdornment>}
                                                    required = {true}
                                                    value={props.createMode === false ? getValue() === "Y" ? "" : getValue(): currentRule && currentRule.validity  && currentRule.validity.replace(/[^0-9\.]+/g, "")}
                                                />    

                         </GridItem>}
                         {currentRule && currentRule.validityType=== "Rolling" &&  <GridItem xs = {6} md = {6} sm = {6} > 
                                 <HWInput label = { 'Period' } 
                                                    name = { 'validity' }
                                                    helperText = { (currentRule && currentRule.validity  && currentRule.validity.replace(/[^0-9\.]+/g, "") > 0) || (currentRule && currentRule.validity === undefined )  ? "" : "Invalid Value" }
                                                    hasError = {  (currentRule && currentRule.validity  && currentRule.validity.replace(/[^0-9\.]+/g, "") > 0) || (currentRule && currentRule.validity === undefined ) ? false : true } 
                                                    onBlur = { captureChanges }
                                                    disabled = {props.createMode === false ? !edit : false}
                                                    endAdornment={<InputAdornment position="end">Months</InputAdornment>}
                                                    required = {true}
                                                    value={props.createMode === false ? getValue() === "M" ? "" : getValue()  :currentRule && currentRule.validity && currentRule.validity.replace(/[^0-9\.]+/g, "")}
                                                />    

                         </GridItem>}
    

    
                            
                       <GridItem xs = {12} md = {12} sm = {12} > 
                       
                                <GridContainer >

                                    <GridItem xs = {12} md = {12} sm = {12} >  
                              <center>

                                             <TextField 
                                               style = {{ marginTop : 20 }}
                                               disabled = {props.createMode === false ? !edit : false}
                                                required={true}
                                               multiline = { true }
                                               rows = { 10 }
                                               rowsMax = { 20 }
                                               value = { currentRule && currentRule.tierDescription }
                                               fullWidth
                                               variant = "outlined"
                                               label = {`Description` }
                                               placeholder = 'Please provide the description'
                                               name = { 'tierDescription' }
                                               InputLabelProps = {{ shrink : true }}
                                               inputProps = {{ maxLength : 100 }}
                                               onBlur = { captureChanges }
                                               error = { checkError ('tierDescription') }
                                               onChange =  { captureChanges  }
                                              helperText = { fetchErrorMessage ('tierDescription') }
                                            />
                              </center>
                            </GridItem>
                                </GridContainer> 
                            </GridItem>
                            <GridItem xs = {12} sm = {12} md = {12} >
                                {  <center> 
{  settings.includes("Action") === true && props.createMode === false && edit === true &&   <Button disabled={ checkDisabled() } onClick = { e => props.createMode === true ? setCreateOpen (true) : setSaveOpen (true) } raised variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} > { props.createMode === true ? 'Create Category' : 'Update Category' } </Button>
}                
{  settings.includes("Action") === true && props.createMode=== true && <Button disabled={ checkDisabled() } onClick = { e => props.createMode === true ? setCreateOpen (true) : setSaveOpen (true) } raised variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} > { props.createMode === true ? 'Create Category' : 'Update Category' } </Button>
}     
                    <Button variant = "contained" style = {{ backgroundColor : '#ff9017', color : 'floralwhite', textTransform : 'none' }} onClick = { handleCancel } raised className = { classes.cancelButton } > Cancel </Button>
                                    <ConfirmationDialog open = { createOpen } setOpen = { setCreateOpen } confirmingAction = { handleSave } dialogMessage = { `Are you sure you want to create this tier.` } dialogTitle = { 'Confirm Rule Creation' } confirmingAction = { handleSave } />
                                    <ConfirmationDialog open = { saveOpen } setOpen = { setSaveOpen } confirmingAction = { handleSave } dialogMessage = { `${membersAffected} members will be affected.Are you sure you want to update this tier. ` } dialogTitle = { 'Confirm Rule Updation' } confirmingAction = { handleSave } />
                                    <StatusDialog isLoading = {false} open= { open3 } setOpen={setOpen3}  sucessStatus={"success"} confirmingAction={e=>props.setOpen (false)} message={"Tier successfully created."} dialogTitle={"Status"}/> 
                                    <StatusDialog isLoading = {false} open= { open2 } setOpen={setOpen2}  sucessStatus={"success"} confirmingAction={e=>props.setOpen (false)} message={"Tier successfully updated."} dialogTitle={"Status"}/> 

                                </center>}

                            </GridItem>
                        </GridContainer>
                    </CardBody> 
                </Card>
            </BlockUi>
        </>
    );

};