import { Button,CardActionArea, CardActions, CardContent, CardMedia, Divider, FormControl, InputLabel, makeStyles, MenuItem, Select, Typography,IconButton,Tooltip } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import CampaignCard from './CampaignCard';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import Card from 'components/Card/Card';
import { HistoryTwoTone, People,Edit,Delete} from '@material-ui/icons';
import scanDisable from 'assets/img/qr_code_scanner_disabled.svg';
import scanEnable from 'assets/img/qr_code_scanner_enabled.svg';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { useCallback } from 'react';
import HWMessage from 'HWLibrary/HWMessage';
import campaignApiService from './APIService';
import { Redirect, useHistory,Link } from 'react-router-dom';
import HWGoogleMaps from 'views/Google Maps/HWGoogleMaps';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import SearchBar from 'views/Merchant/SearchBar';
import { CustomToolbar } from 'views/Members/Member';
import { CustomLoadingOverlay } from 'views/Members/Member';
import { DataGrid, GridOverlay, GridToolbar } from "@material-ui/data-grid"
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import CampaignSwitch from './CampaignSwitch';
import ConfirmationDialog2 from 'views/Utils/ConfirmationDialog2';


const useStyles = makeStyles(theme => ({
    ...styles,
    root: {
        '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300, alignItems: 'center' },
        '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
        '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
        '& .MuiCheckbox-root': { borderRadius: '30px', backgroundColor: 'rgba(0, 0, 0, 0, 0)' },
        '& .Mui-checked': {borderRadius: '3px', backgroundColor: 'rgba(0, 0, 0, 0, 0)', color: 'rgba(200, 0, 0, .1)'},
        '& .MuiDataGrid-rowCount' : { fontWeight : '100'},
        '& .MuiButton-label' : { color : '#138DE8' },
        '& .MuiDataGrid-row:hover' : {cursor:"pointer"}
  
      },
    media : { height : 120 },
    card : { borderRadius : 10 },
    gridItem : { marginTop : 5 },
    draftButton : { background : '#FF9017', color : 'white', marginRight : 5 },
    proceedButton : { background : '#138DE8', color : 'white' },
    cardFooter : { justifyContent : 'center' },
    createButton : { background : '#138DE8', color : 'floralwhite', textTransform : 'initial', fontSize : 24, height : 50, width : 300 },
}));

export default function Campaigns (props) {

    if (HWLocalStorage.load ("previousVouchersTab") !== null ) 
    HWLocalStorage.clear("previousVouchersTab");
    if(HWLocalStorage.load ("previousVouchersRuleTab") !== null)
     HWLocalStorage.clear("previousVouchersRuleTab");
     if (HWLocalStorage.load ("previousCardTab") !== null ) 
     HWLocalStorage.clear("previousCardTab");
     if(HWLocalStorage.load("previousComSettingsTab")!== null){
        HWLocalStorage.clear("previousComSettingsTab")
    }
    if(HWLocalStorage.load("previousRolesSettingsTab")!== null){
        HWLocalStorage.clear("previousRolesSettingsTab")
    }

    if(HWLocalStorage.load("previousSettingsTab")!== null){
        HWLocalStorage.clear("previousSettingsTab")
    }
    if(HWLocalStorage.load("previousMemberTab")!== null){
        HWLocalStorage.clear("previousMemberTab")
    }
    if(HWLocalStorage.load("previousActionsTab")!== null){
        HWLocalStorage.clear("previousActionsTab")
    }
    if(HWLocalStorage.load("previousMerchantDetailsTab")!==null)
    {
        HWLocalStorage.clear("previousMerchantDetailsTab");
    }

    if(HWLocalStorage.load("previousMerchantTab")!==null)
    {
        HWLocalStorage.clear("previousMerchantTab");
    }
    if(HWLocalStorage.load("previousPointsRuleTab")!== null)
    {
        HWLocalStorage.clear("previousPointsRuleTab");
    }
    if(HWLocalStorage.load("previousPointsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsTab");
    }
    if(HWLocalStorage.load("previousActionsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsTab");
    }
    if(HWLocalStorage.load("previousPointsActionsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsActionsTab");
    }

    var campaignarray=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    campaignarray= [...obj["Campaigns"]];

    const history = useHistory ();
    const classes = useStyles();
    const [ campaignList, setCampaignList ] = useState ([]);
    const [ campaignType, setCampaignType ] = useState ('All');
    const [ campaignCodes, setCampaignCodes ] = useState ([]);
    const [ searchText, setSearchText ] = useState ("");
    const [ refreshState, setRefreshState ] = useState (false);
    const [ page, setPage ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ rows, setRows ] = React.useState ([]);
    const [ rowCount, setRowCount ] = useState (0);
    const [loading, setLoading] = useState(false);
    const [ deleteOpen, setDeleteOpen ] = useState (false);
    const [campaignIdToDelete,setCampaignIdToDelete] = useState(null);
    const [ codeOpen, setCodeOpen ] = useState (false);
    const [campaignIdToShowCode,setCampaignIdToShowCode] = useState(null);

    var a=[];
    useEffect (() => {  
        setLoading (true);
        var paginationBookmark = ((page) * pageSize);
        var fetchLimit = pageSize;
       campaignApiService.fetchCampaigns ({ campaignType , searchText,paginationBookmark,fetchLimit })
       .then (response => {  
           if (! response || !response.data) { return HWMessage.show ("Error while fetching the response","error"); }
           HWMessage.show ("Campaigns Fetched Successfully" , "success");
           setCampaignList (response.data.campaignList);
           setRows(response.data.campaignList);
           setRowCount (response.data.campaignCounts);
           if(campaignType === 'All'){
               
               for(var i=0;i<response.data.campaignList.length;i++)
               {
                   a.push(response.data.campaignList[i].campaignCode);
               }
               setCampaignCodes(a);
           }
       })
       .catch (error => { return HWMessage.show ("Campaigns cannot be fetched"); })
       .finally(() =>{ setLoading (false);});
    }, [ campaignType , searchText , refreshState ,pageSize,page]);
    

    const columns = [
        { field: 'campaignCode', headerName: 'Campaign Code', minWidth:300, flex: 3,filterable :  false,sortable:false,renderCell : cellParams => <span onClick = { (event) => handleEdit(cellParams.row) } > <Link style = {{ color : '#138DE8', fontWeight: 400 }} > { cellParams.row.campaignCode } </Link>  </span> },
        { field: 'title', headerName: 'Campaign Title', minWidth:300, flex: 3,filterable :  false,sortable:false },
        { field: 'campaignSchedule',filterable :  false,sortable:false, headerName: 'Campaign Schedule', minWidth:200, flex: 2.5,renderCell : cellParams => {
            if (cellParams.row.campaignSchedule && cellParams.row.campaignSchedule.startDate !== null && cellParams.row.campaignSchedule.endDate !== null) {
                return <span> { formatDate(cellParams.row.campaignSchedule.startDate) } to { formatDate (cellParams.row.campaignSchedule.endDate) } </span>
            } else{ return <></>}
        } },
        { field: 'usersAllowed', headerName: 'Total Users', minWidth:130,flex : 1,filterable :  false,sortable:false },
        {field:'status',headerName: 'Status', minWidth:130,flex : 1,filterable :  false,sortable:false, renderCell: (cellparams) => { return cellparams.row.campaignType !=='Expired' ?   
        campaignarray.includes("Action") === true &&   
            <CampaignSwitch 
            buttonType = "status"
            label = { cellparams.row.status } 
            campaign = { cellparams.row } 
            campaignList = {campaignList} 
            handleStatusChange = { (campaignId, status) => handleStatusChange (campaignId, status) } 
            isLive = { isLive } 
            isDraft = { isDraft } 
            isExpired = { isExpired } />  
        : <span style={{marginLeft: 10}}>{ isExpired(cellparams.row) ? "Expired" :  isLive(cellparams.row) ? "Live" : "Draft" }</span> }},
        {field: 'priorityStatus',headerName: 'Priority Status',minWidth:130, flex : 1,filterable :  false,sortable:false , renderCell: (cellparams) => { return cellparams.row.campaignType !=='Expired' ?   
            campaignarray.includes("Action") === true &&   
            <CampaignSwitch 
            buttonType = "priorityStatus"
            label = { cellparams.row.priorityStatus } 
            campaign = { cellparams.row } 
            campaignList = { campaignList } 
            handleStatusChange = { (campaignId, priorityStatus) => handlePriorityStatusChange (campaignId, priorityStatus) } 
            isLive = { isLive } 
            isDraft = { isDraft } 
            isExpired = { isExpired } />  
                        : <span ></span>
            }},
        {field: 'campaignVisibility',headerName: 'Visibility Status',minWidth:130, flex : 1,filterable :  false,sortable:false , renderCell: (cellparams) => { return cellparams.row.campaignType !=='Expired' ?   
        campaignarray.includes("Action") === true &&   
        <CampaignSwitch 
        buttonType = "visibilityStatus" 
        campaign = { cellparams.row } 
        campaignList = { campaignList } 
        handleStatusChange = { (campaignId, visibilityStatus) => handleVisibilityStatusChange (campaignId, visibilityStatus) } 
        isLive = { isLive } 
        isDraft = { isDraft } 
        isExpired = { isExpired } />  
                    : <span ></span>
        }},
        {field: 'Code',headerName: '', filterable: false,sortable:false,minWidth:30,flex:0.5,renderHeader: () => <></>, renderCell : (cellParams) =><> <IconButton disabled = { isCodeDisabled(cellParams.row) }  onClick = { e => { setCampaignIdToShowCode(cellParams.row.id); setCodeOpen (true)}}> <img src = {isCodeDisabled(cellParams.row) === true ? scanDisable : scanEnable}/> </IconButton></> },
        { field: 'Edit', headerName : '', filterable: false,sortable:false,minWidth:30,flex:0.5,renderHeader: () => <></>, renderCell : (cellParams) => { return ( <IconButton title = "Edit Campaign" onClick = { (event) => handleEdit(cellParams.row) } > <Edit style = {{ color : '#138DE8' }} /> </IconButton>  )} },
       
    ];  

    if(campaignarray.includes("Action") === true){ 
        columns.push ({ field : 'Delete',headerName : '',filterable : false, sortable : false, minWidth:30,flex:0.5, renderHeader: () => <></>, renderCell : (cellParams) => <> <IconButton disabled = { isExpired (cellParams.row) }  onClick = { e => { setCampaignIdToDelete(cellParams.row.id); setDeleteOpen (true)}}> <Delete style={{color : isExpired(cellParams.row) === true ? '#D4D7D5' : '#FF9017'}} /> </IconButton> </>  });
    }

    // const SortableItem = SortableElement (({value}) => <GridItem xs = {6} md = {6} sm = {6} > <CampaignCard campaign = { value } campaignType = { campaignType } setCampaignList = { setCampaignList }  /> </GridItem> );
    // const SortableList = SortableContainer (({ items }) => { return ( <GridContainer> { campaignList.map ((value, idx) => { return <SortableItem key = { idx } index = { idx } value = { value } campaign = { value } /> }) } </GridContainer> ); }); 

    const reorderCampaigns = useCallback (campaignList => {
        var reorderList = campaignList.map  (i =>  ({ campaignId : i.id, sequenceNumber : i.sequenceNumber }));
        campaignApiService.reorderCampaigns (reorderList).then (response => {
            if (!response || !response.data) { return HWMessage.show ("Error while reordering" ,"error"); }
            HWMessage.show ("Successfully reordered","success");
        }).catch (error =>  HWMessage.show ("Campaigns cannot be reordered", "warning") );
    }, []);

    const handleDelete = (campaign) => {
        setLoading (true);
        campaignApiService.deleteCampaign (campaign.id)
        .then (response => {  
            if (! response || ! response.data) { HWMessage.show ("Campaign cannot be deleted" , "error"); }
            HWMessage.show ("Campaign Deleted Successfully" , "success");
            setCampaignList (prev => prev.filter(i => i.id !== campaign.id));
        })
        .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() => setLoading (false));
    };

    const handleEdit = (campaign) => { history.push ({ pathname : 'create-campaign', params : { state : { createMode : false, campaignId : campaign.id, campaignType : campaign.campaignType } } }); }; 

    const handleStatusChange = (campaignId, status) => {
        if (campaignList && campaignList.length === 0) { return; }
        setCampaignList (prev => {
            if (prev === null || prev.length === 0) { return [] }
            else {
                for (var i = 0; i < prev.length; i++) 
                    if (campaignId === prev[i].id) 
                        prev[i].status = status;
                const res = [ ...prev ];
                return prev;
            }
        });
    };

    const handlePriorityStatusChange = (campaignId, priorityStatus) => {
        if (campaignList && campaignList.length === 0) { return; }
        setCampaignList (prev => {
            if (prev === null || prev.length === 0) { return [] }
            else {
                for (var i = 0; i < prev.length; i++) 
                    if (campaignId === prev[i].id) 
                        prev[i].priorityStatus = priorityStatus;
                const res = [ ...prev ];
                return prev;
            }
        });
    };

    const handleVisibilityStatusChange = (campaignId, visibilityStatus) => {
        if (campaignList && campaignList.length === 0) { return; }
        setCampaignList (prev => {
            if (prev === null || prev.length === 0) { return [] }
            else {
                for (var i = 0; i < prev.length; i++) 
                    if (campaignId === prev[i].id) 
                        prev[i].campaignVisibility = visibilityStatus;
                const res = [ ...prev ];
                return prev;
            }
        });
    };



    const onSortEnd = ({ oldIndex, newIndex }) => { 
        let value = arrayMove (campaignList, oldIndex, newIndex); 
        setCampaignList ([ ...value ]); 

        reorderCampaigns (value);
    };

    const handleCampaignTypeChanges = event => {
        const name = event.target.name;
        const value = event.target.value;

        if (name === 'campaignType')
            setCampaignType (value);
    };

    const formatDate = (date) => {
        var dateObj = new Date (date);
        var formatObj = new Intl.DateTimeFormat('en', { year : 'numeric',  month : 'long', day : 'numeric'});
        return formatObj.format (dateObj);
    };

    const isExpired = (campaign) => {
        if(campaign.campaignType ==='Expired')
        {
            return true;
        }
        if (campaign.campaignSchedule) {
            var endDate = new Date (campaign.campaignSchedule.endDate);
            var currentDate = new Date ();
            currentDate.setHours (0);
            currentDate.setMinutes (0);
            currentDate.setSeconds (0);
            return (endDate < currentDate);
        }
        return false;
    };

    const isDraft = (campaign) => { return campaign.status === 'Draft'; };
    const isLive = (campaign) => {
        if (campaign.campaignSchedule) {
            var startDate = new Date (campaign.campaignSchedule.startDate);
            var endDate = new Date (campaign.campaignSchedule.endDate);
            
            var currentDate = new Date();
            return (currentDate >= startDate && endDate >= currentDate);
          }
          return false;
    };

    const isCodeDisabled = (campaign) => {
        if (isExpired (campaign)) return true; 
        let qrCodePermission = campaign && campaign.eventSchedule && campaign.eventSchedule.qrCodeScanPermission;
        
        if (qrCodePermission && qrCodePermission != null && qrCodePermission === 'Member')
            return false;
        return true;
    };

    const handleCreateClick = event => { history.push ({ pathname : 'create-campaign', params : { state : { createMode : true ,campaignCodes : campaignCodes } } }); };  

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card id="top" style={{margin:"0px"}}>
                    <CardHeader className = { classes.cardHeader } color = 'rose' icon >
                        <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        <h4 className = { classes.cardIconTitle } > Campaigns  </h4> 
                    </CardHeader>      
                    <CardBody>
                        <GridContainer> 
                            <GridItem xs = {12} md = {12} sm = {12}  >
                                <GridContainer style={{display: 'flex'}} >
                                    <GridItem>
                                    <SearchBar  
                                            width = '250px' 
                                            handleSearch = { setSearchText }
                                            handleRefresh = { setRefreshState }
                                            refreshState = { refreshState }
                                        />
                                    </GridItem>
                                    <GridItem >
                                        <FormControl variant = "outlined" className = { classes.formControl } >
                                            <InputLabel htmlFor = "campaignType"> Campaign Type </InputLabel>
                                            <Select
                                                style = {{ width : 300, float : 'right', height : 50 }}
                                                value = { campaignType }
                                                onChange = { handleCampaignTypeChanges }
                                                label = "Campaign Type"
                                                inputProps = {{ name : 'campaignType', id : "campaignType" }}
                                            >
                                                <MenuItem value = { 'All' } > All Campaigns (Except Drafted) </MenuItem>
                                                <MenuItem value = { 'Scheduled' } > Scheduled Campaigns </MenuItem>
                                                <MenuItem value = { 'Live' } > Live Campaigns </MenuItem>
                                                <MenuItem value = { 'Upcoming' } > Upcoming Campaigns </MenuItem>
                                                <MenuItem value = { 'Expired' } > Expired Campaigns </MenuItem>
                                                <MenuItem value = { 'Disabled' } > Disabled Campaigns </MenuItem>
                                                <MenuItem value = { 'Draft' } > Drafted Campaigns </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </GridItem>
                                    <GridItem style = {{ marginLeft:'auto'}}>
                                        {campaignarray.includes("Action") === true && <Button variant = "contained" className = { classes.createButton } onClick = { handleCreateClick }
                                        >
                                            Create Campaign
                                        </Button>}
                                    </GridItem>
                                </GridContainer>
                                <GridContainer style = {{paddingTop: 20}}>
                                    <GridItem xs = {12} sm = {12} md = {12}>
                                        { campaignList.length > 0 && 
                                        // <SortableList 
                                        //     items = { campaignList } 
                                        //     onSortEnd = { onSortEnd } 
                                        //     useDragHandle = { true }
                                        //     shouldUseDragHandle = { true }
                                        //     axis = "xy"
                                        // /> 
                                        <DataGrid 
                                            className={classes.root} 
                                            autoHeight
                                            showToolbar 
                                            density="compact" 
                                            loading = {loading} 
                                            fullWidth
                                            columns={columns} 
                                            rows={rows} 
                                            rowCount = { rowCount }
                                            paginationMode = 'server'
                                            page = { page }
                                            pageSize = { pageSize }
                                            components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 
                                            onPageChange = { (gridChangeParams) => { setPage (gridChangeParams); } }
                                            onPageSizeChange = { (gridChangeParams) => { setPage (0); setPageSize(gridChangeParams); } }
                                            rowsPerPageOptions ={ [25, 50, 100 ]}
                                            filterMode = 'server'
                                            sortingMode = 'server'
                                        /> 
                                        } 
                                        {
                                            (campaignList.length === 0 || campaignList === null) &&
                                            <center> <Typography variant = 'h5' color = 'textSecondary' > No Campaigns Available   </Typography>   </center>
                                        }
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card> 
                <ConfirmationDialog open = { deleteOpen } setOpen = { setDeleteOpen } confirmingAction = { (e) => handleDelete(campaignIdToDelete) } dialogMessage = { 'Confirm Delete' } dialogTitle = { 'Are you sure want to delete this campaign ?' }  />
                <ConfirmationDialog2 open = { codeOpen } setOpen = { setCodeOpen } confirmAction = { e => setCodeOpen (false) } dialogTitle = { 'QR Code Generation for Member Scan' } dialogMessage = { `Campaign ID for QR code Generation :  ${ campaignIdToShowCode } ` } /> 
            </GridItem>
        </GridContainer>
    );
};
