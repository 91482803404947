import React, { useCallback, useEffect, useState } from "react"
import { DataGrid, GridOverlay, GridToolbar } from "@material-ui/data-grid"
import { makeStyles, Dialog, DialogContent, DialogTitle, LinearProgress, DialogActions, Button, TextField, IconButton, DialogContentText, Tooltip } from "@material-ui/core"
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import './Merchant.css'
import MerchantCreate from "./Merchant";
import { Link, Redirect, useHistory } from "react-router-dom";
import Merchant from "./Merchant";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Card from 'components/Card/Card';
import merchantApiService from "./APIService";
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Delete, Edit, SettingsBackupRestoreTwoTone, VerifiedUser } from "@material-ui/icons";
import { ClipLoader, FadeLoader } from "react-spinners";
import { Fragment } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import HWLocalStorage from "HWLibrary/HWLocalStorage";
import SearchBar from "./SearchBar";
import DeleteIcon from '@material-ui/icons/Delete';
import HWMessage from "HWLibrary/HWMessage";
import CardBody from "components/Card/CardBody";
import People from "@material-ui/icons/People";
import CampaignSwitch from "./MerchantSwitch";
import BlockUi from "react-block-ui";
import { CustomToolbar } from "views/Members/Member";
const ConfirmationDialog = (props) => {
    const handleClose = () => props.setDialogOpen (false);
    const handleDelete = (event) => { props.handleDelete(); handleClose(); }
   
    return (
        <Fragment>
            <Dialog  open = {props.dialogOpen} onClose = {handleClose}  >
                <DialogTitle> {props.dialogTitle} </DialogTitle>
                <DialogContent>  
                    <DialogContentText> 
                        <span> {props.dialogMessage} </span>
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick = { handleClose } variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' , margin: 5 }}  > Cancel </Button>
                    <Button onClick = { handleDelete } variant="outlined"  style = {{  color : 'Grey' }}  > { props.buttonText } </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export const StatusDialog = (props) => {

    const handleClose = () => { props.showPopup(false); }
    return (
    <Fragment>   
         
            <Dialog onClose = { handleClose } open = { props.open } >
                <DialogTitle> {props.dialogTitle} </DialogTitle>
                {
                    <DialogContent dividers > 
                        { 
                            props.createLoading && 
                            <center> <ClipLoader loading = {true} /> </center>
                        }
                        {
                        !props.createLoading && 
                        <>    
                            <center>{ props.createStatus ? <CheckCircleIcon style = {{ color : '#2FBF12' }} /> : <CancelIcon style = {{ color : '#BF1231' }} /> } </center>
                            <center> {props.dialogMessage}  </center> 
                        </>
                        }
                    </DialogContent>
                }
                <DialogActions> 
                    <Button autoFocus onClick={handleClose} color="primary">
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
    </Fragment>
    );
};

const Merchants = () => {
    var merchantarray=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    merchantarray= [...obj["Merchants"]];

    const [ deleteMerchantId, setDeleteMerchantId ] = useState (null);
    const [ refreshState, setRefreshState ] = useState (false);

    const columns = [
        { field: 'entityName', headerName: 'Entity Name', flex : 2},
        { field: 'code', headerName: 'Merchant Code', flex : 2, renderCell: cellParams => (<span title = "View Merchant" onClick = { (event) => {  openMerchant(cellParams.row)} } style = {{ color : '#138DE8', cursor : 'cursor' }} > { cellParams.row.code } </span>) },
        { field: 'emailAddress',filterable :  false , headerName: 'Email ID', flex : 2 },
        { field: 'category', headerName: 'Category', flex : 2 },
        // { field: 'status', headerName: 'Status', flex : 2, renderCell : (cellParams) =>  (  <span style = {{ color : (cellParams.value === 'Active') ? 'green' : 'orange', fontWeight : 400 }}> {cellParams.value === 'Active' ? "Activated" : "Activation Pending" } </span> ) },
        { field : 'createTime', filterable: false , headerName : 'Create Date', flex : 2, renderCell : cellParams => {
            var date = cellParams.row.createTime;
            var dateString = date.split('T');
            var d = new Date (dateString).toLocaleDateString();

            return <span> {d} </span>
        } },
    ];    
   
    if(merchantarray.includes("Action")===true){ 
        columns.push(  { field : 'status',  filterable :  false ,    headerName : 'Status',     flex : 2, renderCell : cellParams => <> {console.log("cellParams",cellParams)}
        { cellParams.value !== "Pending"  &&  <CampaignSwitch idx = { cellParams.id }  status={cellParams.value  } checked = { cellParams.value === 'Active' ? true : false } rule={cellParams.row}  
        onChange = { (idx, action) =>   setRows(prev =>  {
        prev.map((item,index) => {
            if (item.id == idx) {
            item.status =  action
            return item
            }
         })
         return [...prev]
        }
        )} /> }
         <span> {cellParams.value }</span></> } );

        columns.push({ field: 'Edit', headerName : '', filterable: false,sortable:false,  flex : 1, renderCell : (cellParams) => { return ( <IconButton title = "Edit Merchant" onClick = { (event) => openMerchant(cellParams.row) } > <Edit style = {{ color : '#138DE8' }} /> </IconButton>  )} });
        columns.push({ field: 'Delete', headerName : '',filterable: false ,sortable:false, flex : 1, renderCell : (cellParams) => { return (<IconButton disabled = {cellParams.row.erasable !== "Erasable"} title = "Delete Merchant"  onClick = { event => {setDeleteOpen (true); setDeleteMerchantId (cellParams.row.id);} } > { <DeleteIcon style = {{ color : cellParams.row.erasable === "Erasable" ? '#FF9017' : '#D4D7D5' }} />  }  </IconButton>  )} });
    }
    const [ rows, setRows ] = React.useState ([]);
    const [showCreate, setShowCreate] = React.useState(false);
    const [rowClicked, setRowClicked] = React.useState(false);
    const [ rowCount, setRowCount ] = React.useState (0);
    const [popupShown, showPopup] = React.useState(false);
    const [ clickedMember, setClickedMember ] = useState ({});
    const [ isLoading, setIsLoading ] = useState (false);
    
    const [ page, setPage ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ filterModel, setFilterModel ] = useState ({});
    const [ sortModel, setSortModel ] = useState ({});

    const [ createStatus, setCreateStatus ] = useState (false);
    const [ createMerchant, setCreateMerchant ] = useState ({});
    const [ isCreateLoading, setIsCreateLoading ] = useState (false);

    const [ searchText, setSearchText ] = useState ('');
    const [ deleteLoading, setDeleteLoading ] = useState (false);
    const [ deleteOpen, setDeleteOpen ] = useState (false);
    const [ errorMessage, setErrorMessage ] = useState (null);

    const useStyle = makeStyles(themes => ({
        ...styles,
        searchBar : { marginBottom:10 },
        root: {
            
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiCheckbox-root': { borderRadius: '30px', backgroundColor: 'rgba(0, 0, 0, 0, 0)' },
            '& .Mui-checked': { borderRadius: '3px', backgroundColor: 'rgba(0, 0, 0, 0, 0)', color: 'rgba(0, 0, 255, .8)' },
            '& .MuiButton-textPrimary': { color: '#138DE8' },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : '#F7F5F5' },
            '& .MuiButton-label' : { color : '#138DE8' },
            '& .MuiIconButton-label' : { color : '#138DE8' },
            '& .MuiButton-label' : { color :  '#138DE8' },
            '& .MuiButton-textPrimary' : { color : '#138DE8' },
            '& .MuiDataGrid-mainGridContainer' : { color : '#138DE8' },
            '& span.MuiButton-label' : { color : '#138DE8' },
            '& .MuiDataGridColumnsPanel-switch' : { color : '#138DE8' },
            '& .MuiFormControlLabel-root' : { color : '#138DE8' },
            '& .MuiDataGrid-cell':  { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
        }
    }));

    const classes = useStyle();

    const history = useHistory ();
    const openMerchant = merchantDetail => {
        history.push ({pathname : "merchant-details", params : { state: { isCreate: false, member : merchantDetail } }});
    };

    const deleteMerchant = useCallback ((id) => {
        setDeleteLoading (true);
        merchantApiService.deleteMerchant (id)
        .then (response => {  
            if (! response || ! response.data) { HWMessage.show ("Error while deleting merchant","error"); }
            HWMessage.show ("Successfully deleted merchant","success");
            setRows (rows.filter (i => i.id !== id));
            setRowCount (rowCount - 1);
        })
        .catch (error => { HWMessage.show ("Error while deleting merchant" + error.message ,"error"); })
        .finally (() => setDeleteLoading (false));
    });

    const handleDelete = () => {
        if (!deleteMerchantId) {HWMessage.show ("Merchant to be deleted must be specified","error"); return; }
        HWMessage.show ("Deletion in progress","info");
        deleteMerchant (deleteMerchantId);
    };

    const setupAccount = (merchant) => { 
        setShowCreate(false); 
        showPopup(true); 
        if (merchant) {
            setIsCreateLoading (true);
            merchantApiService.createMerchant(merchant)
            .then ( response => {
                if (!response || !response.data) { 
                    console.log("Error while creating merchant", merchant); 
                    HWMessage.show ("Merchant cannot be created","error");
                    setCreateStatus (false); 
                }
                HWMessage.show("Creating successfully", "success");
                setCreateStatus (true);
                setCreateMerchant (response.data);
            })
            .catch (error => { console.log("Error while setting up merchant", error.response); setErrorMessage (error.response.data.message); HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error"); setCreateStatus (false);  })
            .finally (() => { setIsCreateLoading (false); });
        }  
    };

    const cancelCreate = () => { 
        setShowCreate(false); 
    };

    const dismiss = () => { showPopup(true); };

    useEffect (() => {
        
        setIsLoading (true);
        var paginationBookmark = ((page) * pageSize);
        var fetchLimit = pageSize;

        merchantApiService.fetchMerchants (fetchLimit, paginationBookmark, filterModel, sortModel, searchText)
        .then (response => {
            setIsLoading (false);
            if (!response || !response.data)
                {return HWMessage.show("No relevant data can be fetched","error");}
            console.log("Printing ::", response);

            setRowCount (response.data.merchantCounts);
            setRows (response.data.merchantList);
            HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { setIsLoading (false); HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error"); })
        .finally (() => { 
            var my_element = document.getElementById("top");
            if(my_element!== null || my_element!== undefined || my_element!== '')
            {
                // my_element.scrollIntoView({ }); 

            }
        });
    }, [ page, pageSize, filterModel, sortModel, searchText, refreshState]);   
    
    
    function CustomLoadingOverlay() {
        return (
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
    };

    const handleRefresh = (refresh) => {
        setFilterModel (prev => {});
        setSortModel (prev => {});
        setRefreshState (prev => refresh);
    };

    return (
        <>
            {popupShown && 
            <>
                <StatusDialog  
                    dialogTitle = { isCreateLoading ? 'Setting up Merchant' : `Merchant Creation ${ createStatus ? 'Successful' : 'Failed' }` }
                    dialogMessage = { (createStatus) ? `Merchant was created successfully with Merchant Code : ${ createMerchant.code || 'NA' }` : errorMessage }
                    open = { popupShown }
                    showPopup = { showPopup }
                    createStatus = {createStatus}
                    createLoading = { isCreateLoading }
                    
                />
            </>
            }
            {rowClicked ? (<> </> /* <Redirect to={{ pathname: "merchant-details",  state: { isCreate: false, member : clickedMember } }} > </Redirect> */) : (
                <>
                    <Dialog
                        open={showCreate}
                        fullWidth
                    >
                        <DialogTitle>
                            Create Merchant
                            <i class="fa fa-times" aria-hidden="true" style={{ cursor: 'pointer', float: 'right' }} onClick={() => setShowCreate(false)}></i>
                        </DialogTitle>
                        <DialogContent>
                            <Merchant 
                                isCreate={true} 
                                cancelCreate={() => cancelCreate()} 
                                setupAccount = { setupAccount } 
                            />
                        </DialogContent>
                    </Dialog>
                    <BlockUi blocking = { isLoading } message = { 'Please wait while we fetch.' } >
                    <Card id="top" style={{margin:"0px"}}>
                        <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                            <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                            <h4 className = { classes.cardIconTitle } > Merchant List </h4> 
                        </CardHeader>
                        <CardBody>
                            <GridContainer style = {{ justifyContent : 'space-between' }} >
                                <GridItem className = {classes.searchBar}>
                                        <SearchBar 
                                            width = '500px' 
                                            handleSearch = { setSearchText }
                                            handleRefresh = { handleRefresh }
                                            refreshState = { refreshState }
                                            placeholder = { 'Search by Merchant Code, Name, etc.' }
                                        /> 
                                </GridItem>
                                <GridItem>
                                   {merchantarray.includes("Action")===true && <Fab size = 'medium' style = {{ background :  '#FF9017', backgroundColor : '#FF9017', marginTop: 8}} backgroundColor = '#FF9017' color = '#FF9017' variant = "extended" onClick={() => setShowCreate(true)}  >
                                            <AddIcon style={{ color: 'white' }} /> <span style = {{ color : 'white' }} > Add Merchant </span>
                                    </Fab>}
                                </GridItem>
                            </GridContainer>
                                    <DataGrid 
                                            className={classes.root} 
                                            autoHeight
                                            showToolbar 
                                            density="compact" 
                                            loading = {isLoading} 
                                            fullWidth
                                            columns={columns} 
                                            rows={rows} 
                                            rowCount = { rowCount }
                                            paginationMode = 'server'
                                            page = { page }
                                            pageSize = { pageSize }
                                            components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 
                                            onPageChange = { (gridChangeParams) => { setPage (gridChangeParams); } }
                                            onPageSizeChange = { (gridChangeParams) => { setPage (0); setPageSize(gridChangeParams); } }
                                            rowsPerPageOptions ={ [25, 50, 100 ]}

                                            filterMode = 'server'
                                            onFilterModelChange = { (params) => { if (params.items.length > 0) setFilterModel (prev => ({ ...params.items[0]}) );  }  }
                                            sortingMode = 'server'
                                            onSortModelChange = { params => { if (params.length > 0) setSortModel (params[0]); }  }
                                    /> 
                    </CardBody>
                </Card>    
                </BlockUi>
                    <ConfirmationDialog 
                        dialogOpen = { deleteOpen } 
                        setDialogOpen = { setDeleteOpen }
                        dialogTitle = { "Do you want to delete this merchant" }
                        dialogMessage = { "Are you sure you want to delete this merchant. This action cannot be reversed." }
                        buttonText = { 'Delete Merchant' }
                        handleDelete = { handleDelete }
                    />
                    
                </>
            )}
        </>
    )
}

export default Merchants;