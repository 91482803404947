import { makeStyles} from '@material-ui/core';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Fab } from '@material-ui/core';
import RuleForm from './SubscriptionRuleForm';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { Button,  IconButton , Popover } from '@material-ui/core';
import { DataGrid , GridToolbar} from '@material-ui/data-grid'
import { MoreVert, People, Save } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import BlockUi from 'react-block-ui';
import HWMessage from 'HWLibrary/HWMessage';
import { useHistory } from 'react-router-dom';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 
import { GridAddIcon } from '@material-ui/data-grid';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import settingsApiService from './APIService';
import { HWInput } from 'HWLibrary/HWInput';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import SearchBar from 'views/Merchant/SearchBar';
import { useAsyncDebounce } from 'react-table';
import { CustomToolbar } from 'views/Members/Member';


const useStyles = makeStyles(theme => ({
    ...styles,
    cardFooter : { justifyContent : 'center', justifyItems : 'center' },
    cardHeader : { background : '#138DE8'  },
    cardIcon : { background : '#138DE8' },
    bottomGrid : { marginTop : 10 },
    '& .MuiFormLabel-root' : { marginBottom : 0 },
    editIcon : { color : '#138DE8' }
}));

function RuleDialog (props) {
    
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ ruleDescription, setRuleDescription ] = useState ({});

    console.log (props);

    const handleClose = event => {props.setOpen (false);props.setDirectActionOpen(false) ;}
    return (
        <>
            <Dialog open = { props.open } onClose = { handleClose }  >
                <RuleForm refreshState = { props.refreshState } setRefreshState = { props.setRefreshState } rules = { props.rules } setRules = { props.setRules } createMode = { props.createMode } ruleId = { props.ruleId } open = { props.open } setOpen = { props.setOpen }   setDirectActionOpen = {props.setDirectActionOpen}
   />
            </Dialog>
        </>
    );
}



function ActionPopover (props) {
    return ( 

                <div>
                <Popover
                    open={ props.open }
                    anchorEl={ props.anchorEl}
                    onClose={  (event) =>  props.handleClose (false)  }
                >
                    <div >
                        <div style = {{ margin : '20px' }} > 
                            <Button variant='contained' style = {{ backgroundColor : '#138DE8', color : 'floralwhite', right : '5px' ,textTransform:'None' }} onClick = {event => props.handleEdit() }  > Edit </Button> 
                            <Button variant='contained' style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform:'None' }} onClick = { event => props.handleDelete() } >  Delete </Button> 
                        </div> 
                    </div>
                </Popover>
            </div>
    
    );
};



export default function Subscription (props) {

    HWLocalStorage.save (3, "previousSettingsTab");

    if(HWLocalStorage.load("previousComSettingsTab")!== null){
        HWLocalStorage.clear("previousComSettingsTab")
    }
    if(HWLocalStorage.load("previousRolesSettingsTab")!== null){
        HWLocalStorage.clear("previousRolesSettingsTab")
    }
    var settings=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    settings= [...obj["Settings"]];



   const classes = useStyles();
   const [ rows, setRows ] = useState ([]);
   const [ page, setPage ] = useState (0);
   const [ pageSize, setPageSize ] = useState (100);
   const [ filterModel, setFilterModel ] = useState (null);
   const [ rowCount, setRowCount ] = useState (0);
   const [ anchorEl, setAnchorEl ] = useState (null);
   const [ directActionOpen , setDirectActionOpen ] = useState(false);
   const [ refreshState, setRefreshState ] = useState (false);
   const [ selectedRuleId, setSelectedRuleId ] = useState ();
   const [ ruleOpen, setRuleOpen ] = useState (false);
   const [ createMode, setCreateMode ] = useState (false);
   const [ deleteItemState , setDeleteItemState ] = useState('');
   const [ rulesLoading , setRulesLoading ] = useState(false);
   const [ isLoading , setIsLoading ] = useState(false);
   const [searchText ,setSearchText] = useState("");
    const [loading, setLoading ] = useState(false);
   const [controlDate , setControlDate]  = useState(null);
   const [edit , setEdit] = useState(false);
   const openRule = () => { setRuleOpen (true); };
   const addRule = (event) => { setCreateMode (prev => true); setRuleOpen (prev => true); };


   useEffect (() => {
    setLoading (true);
    settingsApiService.getControlDate ()
    .then (response => {
        if (!response || !response.data){
            HWMessage.show("Data can't be fetched at the moment" , "error");
            return console.log("No relevant data can be fetched");
        }
        setControlDate (response.data.controlDate);
    
    })
    .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
})
    .finally (() => { setLoading (false);
        
    });
}, [ ]);




const updateControlDate = (controlDate) => {
    setLoading (true);

    settingsApiService.updateControlDate (controlDate)
    .then (response => {
        if (!response || !response.data){
            HWMessage.show("Data can't be fetched at the moment" , "error");
            return console.log("No relevant data can be fetched");
        }
        setControlDate (response.data.controlDate);
    
    })
    .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
})
    .finally (() => { setLoading (false);
        
    });

}



   useEffect (() => {
    setIsLoading (true);
    var paginationBookmark = ((page) * pageSize);
    var fetchLimit = pageSize;
    settingsApiService.fetchSubscriptionList ({fetchLimit, paginationBookmark , filterModel , searchText , sortModel})
    .then (response => {
        if (!response || !response.data)
           {    HWMessage.show("Data cannot be fetched at the moment" ,"error");
                return console.log("No relevant data can be fetched");}
      setRows (response.data);
     setRowCount(response.data.count);
       HWMessage.show("Request Successful" , "success");
    })
    .catch (error => { console.log("Error while fetching data", error); HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
})
    .finally (() => { setIsLoading (false); var my_element = document.getElementById("top");
    if(my_element!== null || my_element!== undefined || my_element!== '')
    {
        // my_element.scrollIntoView({ }); 

    }   

});
}, [ page, pageSize ,  refreshState , filterModel , searchText , sortModel ]);





const checkPriorDelete = deleteObject => {
    // Check for any existing rules which may be applicable for the time being
    return true;
};
const handleEdit = (id) =>{
    setCreateMode (prev => false); setRuleOpen (prev => true);

} 


   const deleteRule = useCallback (id => {
       if (id != null) {
           setRulesLoading (true);
           settingsApiService.deleteSpecificSubscription (id) 
           .then (response => {
               if (!response || !response.data) { return HWMessage.show ("Error while deleteing the rule","error"); }
               
                setRowCount (rowCount - 1);
               setRows (prev => { prev = prev.filter (i => i.id !== id); return [ ...prev ] });
               setRefreshState(prev=>!prev);
            HWMessage.show("Request Successful" , "success");
           })
           .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
           .finally (() => { setRulesLoading (false); setCreateOpen(false); setDirectActionOpen(false); });
       }
   });

const handleDelete = (id) => {

        deleteRule (id)
}


const [ createOpen, setCreateOpen ] = useState (false);

   const [ sortModel, setSortModel ] = useState ({});

    const columns = [
       { field : 'tierName',          headerName : 'Membership Tier',    filterable : true,  sortable : true, flex : 2},
       { field : 'fee',          headerName : 'Fee',          filterable : false,  sortable : true, flex : 1.5 },
       { field : 'durationInMonths',          headerName : 'Period',          filterable : false,  sortable : true, flex : 1.5},
       { field : 'Validity',          headerName : 'Validity',          filterable : false,  sortable : true, flex : 3 , renderCell : cellParams => {  
        var { validityStartDate, validityEndDate } = cellParams.row;
        var formattedStart = new Date (validityStartDate).toLocaleDateString();
        var formattedEnd = new Date (validityEndDate).toLocaleDateString();

        return <span  > { `${ formattedStart } - ${ formattedEnd }` } </span>;
    } },
       { field : 'processingCondition',          headerName : 'Process Flag',          filterable : true,  sortable : true, flex : 2.5 },

       { field : 'createTime', headerName : 'Create Date', filterable: false ,  width : 170, renderCell : cellParams => {
        var date = new Date(cellParams.row.createTime);
        var d = date.toLocaleDateString();

        return <span> {d} </span>
    } },

    ];

    if(settings.includes("Action") === true){
        columns.push(       { field : 'Edit',             headerName : '',             filterable : false, sortable : false, flex : 1.5,  renderCell : cellParams => <> <IconButton onClick = { (event) =>{  setDeleteItemState(cellParams.row) ; setSelectedRuleId(cellParams.row.id); handleEdit(event) } } > <Edit style={{color: '#138DE8' }} className = { classes.actionIcon } /> </IconButton>  </>  }
        )
        columns.push(       { field : 'Delete',             headerName : '',             filterable : false, sortable : false, flex : 1.5,  renderCell : cellParams => <> <IconButton onClick = { (event) =>{  setDeleteItemState(cellParams.row) ; setSelectedRuleId(cellParams.row.id) ; setCreateOpen(true) } } > <Delete style={{color: '#FF9017' }} className = { classes.actionIcon } /> </IconButton>  </>  }
        )


    }
  
    const useDataGridStyles = makeStyles(theme => ({
        root: {
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiButton-label' : { color : '#138DE8' }
        }
      }));
      const dataGridClasses = useDataGridStyles();

    function CustomLoadingOverlay() {
        return (
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
      }

   
   const history = useHistory ();
   var newControlDate;
   if(controlDate !== undefined && controlDate !==null )
   {newControlDate = controlDate.split("T")[0];}

    return (
        <Fragment>
            <BlockUi blocking = { rulesLoading } message = { 'Please wait while we fetch.' } >
                <Card id="top">
                    <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                        <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        <h4 className = { classes.cardIconTitle } > 
                           Subscription
                        </h4> 
                    </CardHeader>
                    <CardBody>  
                        <GridContainer style={{justifyContent:'space-between'}}>
                            <GridItem xs = {6} md = {3} sm = {6} > 
                            <div style={{display: "flex" }}>
                            <HWInput type = 'date'  disabled= {!edit}  name = {'controlDate'} label = { 'Control Date' } labelProps = {{ shrink : true }}  value = {newControlDate}  onBlur={e=> { setControlDate(e.target.value); }} />  
                            {       settings.includes("Action") === true && edit === false && <a style={{cursor: "pointer" , marginLeft: 10 , position: "relative" , top: 20}}><Edit style={{color: "#138de8"}} onClick={e=>setEdit(!edit)} /></a>
                            }             
                            {      settings.includes("Action") === true && edit === true && <a style={{cursor: "pointer" , marginLeft: 10, position: "relative" , top: 20 }}><Save style={{color: "#138de8"}} onClick={e=>{setEdit(!edit); updateControlDate(newControlDate);} } /></a>
                            } 
                            </div>
                            </GridItem>
                            <GridItem>
                              { settings.includes("Action") === true &&  <Fab onClick = { addRule } variant = 'extended' aria-label="add" style = {{ background : '#FF9017', color : 'white' }}  > <GridAddIcon  /> Add Subscription</Fab>}
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem className = { classes.bottomGrid } xs = {12} md = {12} >
                                <center>
                                    <DataGrid 
                                        className = { dataGridClasses.root }
                                        page = { page }
                                        pageSize = { pageSize }
                                        rows = { rows }
                                        rowCount = { rowCount }
                                        columns = { columns }
                                        showToolbar
                                        pagination
                                        paginationMode = 'server'
                                        onPageChange = { changeParams => setPage (changeParams) }
                                        onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams); } }
                                        sortingMode="server" 
                                        onSortModelChange = { params => {  
                                          if (params.length > 0) 
                                              setSortModel (params[0]);
                                        } }
                                      
                                        filterMode = 'server'
                                        onFilterModelChange = { (params) => {
                                          if (params.items.length > 0) {
                                            setFilterModel (prev => ({ ...params.items[0] })); 
                                          }
                                        }}
                                        density = 'compact'
                                        autoHeight
                                        rowsPerPageOptions ={ [25, 50, 100 ]}
                                        components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 
                                        disableExtendRowFullWidth = { false }
                                    />
                                    </center>
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
                <RuleDialog     
                 ruleId = { selectedRuleId }
                 open = { ruleOpen }     
                 setOpen = { setRuleOpen } 
                 createMode = { createMode } 
                 rules = { rows }
                 // setRules = { setRows }
                 refreshState = { refreshState } 
                 setRefreshState = { setRefreshState }
                 setDirectActionOpen = {setDirectActionOpen}

            />

        <ActionPopover 
        open = { directActionOpen } 
        handleClose = { setDirectActionOpen } 
        anchorEl = { anchorEl } 
        setAnchorEl = { setAnchorEl }
        handleEdit = { e => handleEdit(e) } 
        handleDelete = { e => setCreateOpen(true)} 
         /> 
                             <ConfirmationDialog open = { createOpen } setOpen = { setCreateOpen }  dialogMessage = { 'Are you sure you want to delete this subscription' } dialogTitle = { 'Confirm subscription Deletion' } confirmingAction = { e => handleDelete(deleteItemState) } />

            </BlockUi>
            
        </Fragment>
    );
};