import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
// @material-ui/icons
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssessmentOutlinedIcon from '@material-ui/icons/Launch';
import { InputLabel } from "@material-ui/core";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
// import Button from "components/CustomButtons/Button.js";
import { FormControl, MenuItem , Select } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import authService from "HWLibrary/ApiService";
import HWMessage from "HWLibrary/HWMessage";
import { Button, ButtonBase , Tooltip } from "@material-ui/core";
import { ShowChart } from "@material-ui/icons";
import Launch from "@material-ui/icons/Launch";
const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const [openNotification, setOpenNotification] = React.useState(null);
  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  // For logging out 
  const handleLogOut = () => {
    HWMessage.show ("Logging out");
    authService.onLogOut();
    HWMessage.show ("You have logged out.");
  }

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleRedirections = (event) => {
    let urlToOpen;
    switch (event?.target?.value) {
      case 10: urlToOpen = "https://search-hummingwave-dev-pysqkknayqcftzvyd3gsbwfokq.ap-south-1.es.amazonaws.com/_plugin/kibana/app/dashboards#/view/a813f020-fa87-11eb-877b-25f3ee5f07e6?_g=(filters:!(),refreshInterval:(pause:!t,value:5000),time:(from:'2021-08-01',to:'2021-08-31'))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:YL-Points,viewMode:view)" ; break;
      case 20: urlToOpen = "https://lms-store.hummingwave.com/login/";  break;
      case 30: urlToOpen = "https://aws.amazon.com/console/";  break;
      case 40: urlToOpen = "https://gbr.yamaha-motor.com.my/auth/login";  break;
      case 50: urlToOpen = "https://genblu-support.freshdesk.com/support/login";  break;
      case 60: urlToOpen = "https://analytics.google.com/analytics/web/#/p274713188/reports/dashboard?r=firebase-overview";  break;
      case 70: urlToOpen = "http://webhub.m3tech.my/webpanel/hlyimpreporting/index.asp";  break;
    }
    window.open(urlToOpen);
  }

  const [open , setOpen] = useState(false);
  
  const classes = useStyles();
  const { rtlActive } = props;
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true
  });
  return (
    
    <div className={wrapper} >
      {/* <CustomInput
        rtlActive={rtlActive}
        formControlProps={{
          className: classes.top + " " + classes.search
        }}
        inputProps={{
          placeholder: rtlActive ? "بحث" : "Search",
          inputProps: {
            "aria-label": rtlActive ? "بحث" : "Search",
            className: classes.searchInput
          }
        }}
      />
      <Button
        color="white"
        aria-label="edit"
        justIcon
        round
        className={searchButton}
      >
        <Search className={classes.headerLinksSvg + " " + classes.searchIcon} />
      </Button> */}

      
      {/* <div  className={managerClasses}>
        <Button
          // color="#138DE8"
          justIcon
          
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
          
        >
          <span style = {{ right : 25, position : "absolute", textTransform: 'none' }} > Logout  &nbsp; </span> 
          <ExitToAppIcon
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
        </Button>
      </div> */}

          {/* <Button onClick={ () => HWMessage.show("Analytics Not Available At Moment", "warning") } variant = "contained" style = {{ textTransform : 'none', fontSize: 16, backgroundColor : '#FF9017', color : 'floralwhite', marginRight : 10 }}  >
            Analytics Dashboard &nbsp; &nbsp; <ShowChart />
          </Button> */}
          <div style={{display: "flex" }}>
                 <Button variant = "text" style={{ textTransform: "None" ,fontSize: 14, color: "black" , height: "30px", borderRight: `1px solid grey`, borderRadius: 0}} onClick={ e=>setOpen(!open) }> Third Party Dashboards <Launch/></Button>
                 <br/>
                 {open && <Select 
                      open={open}
                      onClose={e=>setOpen(false)}
                      id="Select"
                      onChange={e=> {handleRedirections(e)} /*  e.target.value === 10 ? window.open("https://search-hummingwave-dev-pysqkknayqcftzvyd3gsbwfokq.ap-south-1.es.amazonaws.com/_plugin/kibana/app/dashboards#/view/a813f020-fa87-11eb-877b-25f3ee5f07e6?_g=(filters:!(),refreshInterval:(pause:!t,value:5000),time:(from:'2021-08-01',to:'2021-08-31'))&_a=(description:'',filters:!(),fullScreenMode:!f,options:(hidePanelTitles:!f,useMargins:!t),query:(language:kuery,query:''),timeRestore:!f,title:YL-Points,viewMode:view)" ) : window.open("https://lms-store.hummingwave.com/login/" ) */}
                    >                      
                      <MenuItem value={10}>Analytics Dashboard</MenuItem>
                      <MenuItem value={20}>WooCommerce Dashboard</MenuItem>
                      <MenuItem value={30}>AWS</MenuItem>
                      <MenuItem value={40}>Member Web App</MenuItem>
                      <MenuItem value={50}>Freshdesk</MenuItem>
                      <MenuItem value={60}>Firebase Google analytics</MenuItem>
                      <MenuItem value={70}>XOX Tech Portal</MenuItem>
                      {/* <MenuItem value={30}>FreshDesk Dashboard</MenuItem> */}
                    </Select>}
           
      <Tooltip title = "Logout from Application" >
        <Button onClick={ handleLogOut } variant = "text" style = {{ textTransform : 'none', fontSize: 14, color : 'black', height: "30px" }}  >
          Logout <ExitToAppIcon /> 
        </Button>
      </Tooltip>
      </div>
      {/* <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <Person
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {rtlActive ? "الملف الشخصي" : "Profile"}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الملف الشخصي" : "Profile"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الإعدادات" : "Settings"}
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleLogOut}
                      className={dropdownItem}
                    >
                      {rtlActive ? "الخروج" : "Log out"}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div> */}
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool
};
