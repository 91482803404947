import axios from 'axios';
import appConfig from '../../HWLibrary/HWConfig';
import authService from 'HWLibrary/ApiService';
import HWMessage from 'HWLibrary/HWMessage';

class PointsRequestApiService {

    constructor () {
        if (!PointsRequestApiService.instance)  PointsRequestApiService.instance = this;
        return PointsRequestApiService.instance;
    }
    defaultAuthConfig = () => { 
        const accessToken = authService.getCachedAccessToken();
        return { headers : { Authorization : `Bearer ${accessToken}` } };
    };
    fetchRequests = ({ fetchLimit, paginationBookmark, fetchType, searchText, sortModel, filterModel }) => { 
        console.log("Params", fetchLimit, paginationBookmark);
        if (fetchLimit === null) fetchLimit = 100;
        if (paginationBookmark === null) paginationBookmark = 0;
        if (fetchType === null) fetchType = 'All';

        var searchType, searchText, searchField;
        if (filterModel) {
            searchField = filterModel.columnField;

            searchType = filterModel.operatorValue;
            searchText = filterModel.value;
        }  
        if (searchText) searchText = searchText;

        var sortField, sortOrder = null;
        if (sortModel) {
            if (sortModel.field && sortModel.sort) {
                sortField = sortModel.field;
                sortOrder = sortModel.sort === "asc" ? "Ascending" : "Descending";
            } 
        }  
        const params = { fetchLimit, paginationBookmark, fetchType : 'All', searchText, searchType, searchField, sortField, sortModel };
        const url = `${appConfig.serverBaseUrl}/points/requests`
        const config = { ...this.defaultAuthConfig(), params };
        console.log("uRL, COfig", url, config);
        return authService.axiosService().get(url, config);
    };

    fetchRequest = (id) => {  
        const url = `${appConfig.serverBaseUrl}/points/requests/${id}`
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
    };
    deleteRequest = (id) => { 
        if (id != null) {
            const url = `${appConfig.serverBaseUrl}/points/requests/${id}/delete`;
            const config = { ...this.defaultAuthConfig() };
            return authService.axiosService().put (url, {}, config);
        } else { HWMessage.show ("Error while deleting merchant please specify ID"); }
    };
    createRequest = (request) => { 
        const url = `${appConfig.serverBaseUrl}/points/requests`;
        const config = {...this.defaultAuthConfig()};
        const data = { ... request};

        return axios.post (url, data, config);
    };
    updateRequest = (request) => { 
        const url = `${appConfig.serverBaseUrl}/points/requests/${request.id}`;
        const config = {...this.defaultAuthConfig()};
        const data = { ...request };

        return axios.put (url, data, config);
    }; 
};

const pointsRequestsApiService = new PointsRequestApiService();
export default pointsRequestsApiService;