
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";

import LoyaltyIcon from '@material-ui/icons/Loyalty';
import { Button, ButtonBase, Card, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, IconButton, List, ListItem, ListItemText, makeStyles, Tooltip } from '@material-ui/core';
import CardIcon from 'components/Card/CardIcon';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Typography from '@material-ui/core/Typography';

import HistoryIcon from '@material-ui/icons/History';
import MemberCard from './MemberCard';
import memberApiService from './APIService';
import BlockUi from 'react-block-ui';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import { HWInput } from 'HWLibrary/HWInput';
import HWMessage from 'HWLibrary/HWMessage';


const useStyles = makeStyles(styles);

export default function ManageMembership (props) {

    HWLocalStorage.save (1, "previousMemberTab");

    const classes = useStyles();

    const [ memberId, setMemberId ] = useState (props && props.location && props.location.params && props.location.params.state && props.location.params.state.id || JSON.parse(HWLocalStorage.load("memberStore")));
    const [ member, setMember ] = useState (null);
    const [ isLoading, setIsLoading ] = useState (false);
    
    const [ merchant, setMerchant ] = useState ({  });

    const memberDetails = [ 'mobileNumber', 'name', 'dob', 'email', 'gender' ];
    const membershipDetails = ['membershipNumber', 'membershipStatus', 'tier'];
    const keyMap = { 'mobileNumber' : 'Mobile Number', 'name' : 'Name', 'dob' : 'Date of Birth', 'email' : 'Email', 'gender' : 'Gender', 'membershipNumber' : 'Account ID', 'membershipStatus' : 'Membership Status', 'tier' : 'Tier' };
    const [ cardList, setCardList ] = useState([]);


    if (props && props.location && props.location.params && props.location.params.state && props.location.params.state.id) {
        var value = props && props.location && props.location.params && props.location.params.state && props.location.params.state.id;
        HWLocalStorage.save(JSON.stringify(value), "memberStore");
    } 

    const createMemberViewModel = (member) => {
        if (member != null) {
            return {
                mobileNumber : member.mobile && (`(${member.mobile.countryCode})-${member.mobile.mobileNumber}`),
                name : member.name,
                dateOfBirth : new Date(member.dateOfBirth).toLocaleDateString(),
                email : member.email,
                gender : member.gender ? member?.gender?.charAt(0).toUpperCase() + member?.gender?.slice(1) : "-",
                membershipNumber : member.membershipNumber,
                membershipStatus : member.membershipStatus ? member.membershipStatus : "Active",
                tier : member.tier.charAt(0).toUpperCase() + member.tier.substring(1)
            };
        }
    };

    useEffect (() => {
        if (memberId) {
            setIsLoading (true);
            memberApiService.fetchMemberDetails (memberId)
              .then (response => {
                if (!response || !response.data) 
                  return HWMessage.show("Error while fetching member details","error");
                
                setMember (response.data);
                setCardList (response.data.cards);
                setMerchant (createMemberViewModel(response.data));
                HWMessage.show("Request Successful" , "success");
              })
              .catch (error => { 
                console.log("hello",error)
                HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            })
              .finally (() => setIsLoading (false));
          }
    }, [memberId]);

    console.log("memberDetailsOnManageMembership",merchant);

    return (
        <Fragment>
            <BlockUi blocking = { isLoading } message = "Loading Membership Details" tag = "div" >
                <GridContainer>
                    <GridItem xs = {6} > 
                        <GridContainer>
                            <GridItem xs = {12} style={{marginBottom:'15px'}}> 
                                <Card className='card-header-body'>
                                    <CardHeader icon>
                                        <CardIcon color="primary" style = {{ marginTop : 0, background : '#138DE8' }} ><LoyaltyIcon /></CardIcon>                      
                                    </CardHeader>
                                    <CardBody className='card-header-body'></CardBody>
                                </Card>  
                                
                                <Card> 
                                    <h4 className = { classes.cardIconTitle } style = {{ marginLeft : 10 }} > Member Details </h4>
                                    <CardBody>
                                            <List dense>
                                                { /*
                                                Object.keys(merchant)
                                                    .filter(item => memberDetails.indexOf(item) !== -1)
                                                    .map (key => (
                                                        <>
                                                        <ListItem>
                                                            <ListItemText primary = { keyMap[key] } >  
                                                            </ListItemText>
                                                            { merchant[key] }
                                                        </ListItem>
                                                        <Divider />
                                                        </>
                                                    ))
                                                    */
                                                }

                                                <GridContainer>
                                                    <GridItem xs = {12} > <HWInput label = { 'Mobile Number' } value = { merchant.mobileNumber } disabled /> </GridItem>
                                                    <GridItem xs = {12} > <HWInput label = { 'Name' } value = { merchant.name } disabled /> </GridItem>
                                                    <GridItem xs = {6} > <HWInput label = { 'Email Address' } value = { merchant.email } disabled /> </GridItem>
                                                    <GridItem xs = {6} > <HWInput label = { 'Gender' } value = { merchant.gender } disabled /> </GridItem>
                                                </GridContainer>
                                            
                                            </List>
                                    </CardBody>
                                </Card>  
                            </GridItem>
                            
                            <GridItem xs = {12} style={{marginBottom:'15px'}}>   
                                <Card className='card-header-body'>
                                    <CardHeader icon>
                                        <CardIcon color="primary" style = {{ marginTop : 0, background : '#FF9017' }} ><LoyaltyIcon /></CardIcon>                      
                                    </CardHeader>
                                    <CardBody className='card-header-body'></CardBody>
                                </Card>                   
                                <Card> 
                                    <h4 className = { classes.cardIconTitle } style = {{ marginLeft : 10 }} > Membership Details </h4>
                                    <CardBody>
                                            <List dense>
                                                { /*
                                                Object.keys(merchant)
                                                    .filter(item => membershipDetails.indexOf(item) !== -1)
                                                    .map (key => (
                                                        <>
                                                        <ListItem>
                                                            { <ListItemText primary = { keyMap[key] } > </ListItemText>
                                                            { merchant[key] } }
                                                            
                                                        </ListItem>
                                                        </>
                                                    ))   
                                                */ }
                                                <GridContainer>
                                                    <GridItem xs = {12} > <HWInput label = { 'Membership Number' } value = { merchant.membershipNumber } disabled /> </GridItem>
                                                    <GridItem xs = {6} > <HWInput label = { 'Membership Status' } value = { merchant.membershipStatus } disabled /> </GridItem>
                                                    <GridItem xs = {6} > <HWInput label = { 'Tier' } value = { merchant.tier } disabled /> </GridItem>
                                                </GridContainer>
                                            </List>
                                            { false && <Button 
                                                fullWidth 
                                                variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }}                                                color = "primary"
                                                size = "large"
                                                disabled = {true}
                                            > 
                                                Activate / Deactivate Account 
                                            </Button>}

                                            { false && <FormGroup row style = {{ justifyContent : 'center' }}> 
                                                <FormControlLabel 
                                                    control = {  
                                                        
                                                        <Checkbox 
                                                            checked = {true}
                                                            color = "primary"
                                                        />
                                                        
                                                    }
                                                    label = "Exempt from Memebership Rules"
                                                />
                                                <IconButton  > <HistoryIcon color = "primary" /> </IconButton>
                                            </FormGroup>}
                                    </CardBody>
                                </Card>  
                            </GridItem>

                            <GridItem xs = {12} > 
                                { false && <> <Tooltip title = "Extend Membership for this member" >
                                    <Button  
                                            variant = "contained"
                                            style = {{ backgroundColor : '#00AF00', color : '#000000', width : '497px' , textTransform:'None'}}
                                            size = "large"
                                            disabled = {true}
                                        > 
                                            Extend Membership
                                    </Button>  
                                </Tooltip>                                               

                                <Tooltip title = "History for changes in extensions">               
                                    <IconButton  > <HistoryIcon color = "primary" /> </IconButton>
                                </Tooltip>  </>
                                }
                            </GridItem>
                            <GridItem> 
                            { false && <> <Tooltip title = "Change Memberships for this member">
                                <Button  
                                    variant = "contained"
                                    style = {{ backgroundColor : '#990000', color : '#FFFFFF', width : '497px' ,textTransform:'None' }}
                                    size = "large"
                                    disabled = {true}
                                > 
                                    Upgrade / Downgrade Membership
                                </Button> 
                            </Tooltip>   
                            <Tooltip title = "History for changes in memberships">               
                                <IconButton  > <HistoryIcon color = "primary" /> </IconButton>
                            </Tooltip> </>
                            }
                            </GridItem>
                        </GridContainer>
                    </GridItem>

                    <GridItem xs = {6} >

                    <Card className='card-header-body'>
                        <CardHeader icon>
                            <CardIcon color="primary" style = {{ marginTop : 0, background : '#FF9017' }} ><LoyaltyIcon /></CardIcon>                      
                        </CardHeader>
                        <CardBody className='card-header-body'></CardBody>
                    </Card>        

                    <Card> 
                        <h4 className = { classes.cardIconTitle } style = {{ marginLeft: 10 }} > Member Cards Details </h4>
                        <CardBody>
                            <MemberCard cards = { cardList } />     
                        </CardBody>   
                    </Card>
                    </GridItem>
                </GridContainer>
            </BlockUi>
        </Fragment>
    );
};