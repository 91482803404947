import axios from 'axios';
import authService from 'HWLibrary/ApiService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import HWMessage from 'HWLibrary/HWMessage';
import appConfig from '../../HWLibrary/HWConfig';

class VoucherRequestApiService {

    constructor () {
        if (! VoucherRequestApiService.instance) { VoucherRequestApiService.instance = this; }
        return VoucherRequestApiService.instance;
    } 

    defaultAuthConfig = () => {
        const accessToken =  authService.getCachedAccessToken();
        return { headers: { Authorization: `Bearer ${accessToken}` } }
    };

    fetch = (request) => {
        var approvalStatus = null, startDate = null, endDate = null;
        var paginationBookmark = 0, fetchLimit = 0;
        if (request) {
            approvalStatus = request.approvalStatus;
            startDate = request.startDate;
            endDate = request.endDate;
        }
        var searchField, searchText, searchType = null;
        if (request && request.filterModel) {
            searchField = request.filterModel.columnField;
            searchText = request.filterModel.value;
            searchType = request.filterModel.operatorValue;
        } 
        if (request && request.searchText)  { searchText = request.searchText; } 

        var sortField = null, sortOrder = null;
        if (request && request.sortModel) {
                console.log("Current sort model",  request.sortModel);
                if (request.sortModel.field && request.sortModel.sort) {
                sortField = request.sortModel.field;
                sortOrder = request.sortModel.sort === "asc" ? "Ascending" : "Descending";
            } 
        }  

        var fetchLimit, paginationBookmark;
        if (request) {  
            if (request.fetchLimit != null) { fetchLimit = request.fetchLimit; }
            if (request.paginationBookmark != null) { paginationBookmark = request.paginationBookmark; }
        }

        const queryParams = (request) ? { params : { startDate, endDate, approvalStatus, fetchLimit, paginationBookmark, sortField, sortOrder, searchField, searchText, searchType  } } : {};
        const url = appConfig.serverBaseUrl + '/vouchers/requests';
        const config = { ...this.defaultAuthConfig(), ...queryParams };
        console.log("Config", config);
        return authService.axiosService().get(url, config);
    };

    fetchRequestDetails = (request) => {
        console.log(request);
       
        const url =  `${appConfig.serverBaseUrl}/vouchers/requests/${request.id}`;
        const config = { ...this.defaultAuthConfig()};
        return authService.axiosService().get(url, config);
    };

    updateRequest = (id, updatedRequest) => {
        if (id && updatedRequest) {
            const url =  `${appConfig.serverBaseUrl}/vouchers/requests/${id}`;
            const config = { ...this.defaultAuthConfig()};
            return authService.axiosService().put(url, updatedRequest, config);
        }
    };

    updateRequestVoucher = ( updatedRequest) => {
        if (updatedRequest) {
            const url =  `${appConfig.serverBaseUrl}/vouchers/requests/${updatedRequest.id}`;
            const config = { ...this.defaultAuthConfig()};
            return authService.axiosService().put(url, updatedRequest, config);
        }
    };
    createVoucherRequest = (request) => {
        if (request) {
            request.memberGroup = request.groupIds;
            const url = `${appConfig.serverBaseUrl}/vouchers/requests`;
            const config = { ...this.defaultAuthConfig() };
            return authService.axiosService().post (url, request, config);
        } else {
            HWMessage.show ("Request must be specified for the request to be raised");
            return console.log("Request found empty", request); 
        }
    };

    deleteRule = rule => {
        if (rule !== null) { rule.status = 'Inactive' };
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/vouchers/requests/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

    fetchStats = () => {
        const url =  `${appConfig.serverBaseUrl}/vouchers/requests/statistics`;
        const config = { ...this.defaultAuthConfig()};
        return authService.axiosService().get(url, config);
    };

    
};

class VOCRequestApiService {
    constructor () {
        if (! VOCRequestApiService.instance) { VOCRequestApiService.instance = this; }
        return VOCRequestApiService.instance;
    } 

    defaultAuthConfig = () => {
        const accessToken =  authService.getCachedAccessToken();
        return { headers: { Authorization: `Bearer ${accessToken}` } }
    };

    fetch = (request) => {
        var approvalStatus = null, startDate = null, endDate = null;
        var status = request.status;
        var paginationBookmark = 0, fetchLimit = 0;
        if (request) {
            approvalStatus = request.approvalStatus;
            startDate = request.startDate;
            endDate = request.endDate;
        }

        var searchField, searchText, searchType = null;
        if (request && request.filterModel) {
            searchField = request.filterModel.columnField;
            searchText = request.filterModel.value;
            searchType = request.filterModel.operatorValue;

            if (searchField === 'membershipNumber' || searchField === 'icNumber') searchField = "member." + searchField;
            if (searchField == 'ownerName') searchField = "member.name";
            if (searchField === "registrationDate") searchField = "registeredDate";
        } 
        if (request && request.searchText)  { searchText = request.searchText;} 

        var sortField, sortOrder = null;
        if (request && request.sortModel) {
            sortField = request.sortModel.field;
            sortOrder = request.sortModel.sort;
            if (sortField === 'membershipNumber' || sortField === 'icNumber') sortField = "member." + sortField;
            if (sortField == 'ownerName') sortField = "member.name";
            if (sortField === "registrationDate") sortField = "registeredDate";
            sortOrder = (sortOrder === 'asc') ? 'Ascending' : 'Descending';
        }
        var fetchLimit, paginationBookmark;
        if (request) {  
            if (request.fetchLimit != null) { fetchLimit = request.fetchLimit; }
            if (request.paginationBookmark != null) { paginationBookmark = request.paginationBookmark; }
        }

        const queryParams = (request) ? { params : { startDate, endDate, approvalStatus, fetchLimit, paginationBookmark, sortField, sortOrder, searchField, searchText, searchType, status  } } : {};
        const url = appConfig.serverBaseUrl + '/bike/statistics';
        const config = { ...this.defaultAuthConfig(), ...queryParams };
        return authService.axiosService().get(url, config);
    };

    fetchCountStatistics = (startDate, endDate) => {
        const queryParams = { params : { startDate, endDate } };
        const url = `${ appConfig.serverBaseUrl }/bike/statistics/counts`;
        const config = { ...this.defaultAuthConfig (), ...queryParams };

        return authService.axiosService().get (url, config);
    };    
};


class PointsRequestApiService {

    constructor () {
        if (! PointsRequestApiService.instance) { PointsRequestApiService.instance = this; }
        return PointsRequestApiService.instance;
    } 

    defaultAuthConfig = () => {
        const accessToken =  authService.getCachedAccessToken();
        return { headers: { Authorization: `Bearer ${accessToken}` } }
    };

    fetchRequest = (request) => {
        var fetchLimit, paginationBookmark, fetchType, requestType;
        if (request) {  
            if (request.fetchType != null) { fetchType = request.fetchType }
            if (request.requestType != null) { requestType = request.requestType; }
            if (request.fetchLimit != null) { fetchLimit = request.fetchLimit; }
            if (request.paginationBookmark != null) { paginationBookmark = request.paginationBookmark; }
        }

        var searchField, searchText, searchType;
        if (request && request.filterModel) {
            searchField = request.filterModel.columnField;
            searchText = request.filterModel.value;
            searchType = request.filterModel.operatorValue;
            // if (searchField === 'refNumber' || searchField === 'refNumber') searchField = "voucher." + searchField;
            // if (searchField == 'code') searchField = "voucher.code";
            // if (searchField === "approvalStatus") searchField = "approvalStatus";
            // if (searchField == 'userCount') searchField = "voucher.userCount";
            // if (searchField == 'voucherPerUser') searchField = "voucher.voucherPerUser";

        } 
        if (request && request.searchText)  { searchText = request.searchText;} 
        
        var sortField, sortOrder = null;
        if (request && request.sortModel) {
            sortField = request.sortModel.field;
            sortOrder = request.sortModel.sort;
            sortOrder = (sortOrder === 'asc') ? 'Ascending' : 'Descending';
        }
    
        const queryParams = (request) ? { params : { fetchType, requestType, fetchLimit, paginationBookmark, searchText, searchField, sortField, sortOrder, searchType } } : {};
        const url =  `${appConfig.serverBaseUrl}/points/requests`;
        const config = { ...this.defaultAuthConfig(),...queryParams};
        return authService.axiosService().get(url, config);
    };

    updateRequest = (updatedRequest) => {
        const url =  `${appConfig.serverBaseUrl}/points/requests/${updatedRequest.id}`;
        const config = { ...this.defaultAuthConfig()};
        return authService.axiosService().put(url, updatedRequest, config);
    };

    fetchRequestDetails = (request) => {
        var requestId = null;
        if (request) requestId = request.id;
        const url =  `${appConfig.serverBaseUrl}/points/requests/${requestId}`;
        const config = { ...this.defaultAuthConfig()};
        return authService.axiosService().get(url, config);
    };

    fetchStats = (requestType) => {
        const url =  `${appConfig.serverBaseUrl}/points/requests/statistics`;
        const config = { ...this.defaultAuthConfig(), params : { requestType } };
        return authService.axiosService().get(url, config);
    };
};

const pointsRequestApiService = new PointsRequestApiService();
const voucherRequestApiService = new VoucherRequestApiService();
const vocRequestApiService = new VOCRequestApiService();
export default voucherRequestApiService;
export {pointsRequestApiService};
export { vocRequestApiService };
