import { Button, Card, CardActions, IconButton, makeStyles } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState } from 'react';
import { Fragment } from 'react';
import SearchBar from 'views/Merchant/SearchBar';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import { HWInput } from 'HWLibrary/HWInput';
import { Link } from 'react-router-dom';
import {PeriodDialog} from './VOCVerification';
import { DirectActionDialog } from './VOCVerification';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 


const useStyles = makeStyles (theme => ({
    
    cardFooter : { justifyContent : 'center' },
    cardHeader : { height : '100px' },   
    formControl: { margin: theme.spacing(1), minWidth: 120 }, 
}));



const rows = [
    { id: "1", empId: "M921", date: "10/12/2021", extensionDuration : '2yrs', accountId : '102883' },
    { id: "1", empId: "M921", date: "10/12/2021", extensionDuration : '2yrs', accountId : '102883' },
    { id: "1", empId: "M921", date: "10/12/2021", extensionDuration : '2yrs', accountId : '102883' },
    { id: "1", empId: "M921", date: "10/12/2021", extensionDuration : '2yrs', accountId : '102883' },
    { id: "1", empId: "M921", date: "10/12/2021", extensionDuration : '2yrs', accountId : '102883' },
    { id: "1", empId: "M921", date: "10/12/2021", extensionDuration : '2yrs', accountId : '102883' },
];


export default function MembershipApproval (props) {

    const classes = useStyles ();

    const [ isLoading, setIsLoading ] = useState (false);
    const [ periodDialog, openPeriodDialog ] = useState (false);
    const [ currentDataCard, setCurrentDataCard ] = useState ( {index : 0, title : 'REQUESTS PENDING', counts : '60' });
    const [ dataList, setDataList ] = useState ([
        { index : 0,  title : 'MEMBERSHIP REQUESTS RAISED', counts : '20' },
        { index : 1, title : 'REQUESTS APPROVED', counts : '20' }, 
        { index : 2, title : 'REQUESTS REJECTED', counts : '20' }
    ]);
    const changeData = (idx) => {  
        var temp = currentDataCard;
        setCurrentDataCard(dataList [idx]);
        dataList [idx] = dataList [temp.index];
        dataList [temp.index] = temp;
        setDataList (dataList);
    };

    const [ startDate, setStartDate ] = useState ();
    const [ endDate, setEndDate ] = useState ();
    const [ directActionOpen, setDirectActionOpen ] = useState (false);
    const [ anchorEl, setAnchorEl ] = useState (null);
    const [ approvalMemberId, setApprovalMemberId ] = useState ('');

    const columns = [
        { field: 'empId', headerName: 'Employee ID', width: 200 },
        { field: 'accountId', headerName: 'Account ID', width: 200 },
        { field: 'extensionDuration', headerName: 'Extension Duration', width: 200 },
        { field: 'date', headerName: 'Date', width: 200 },
        { field: '', headerName : '', width : 200, renderCell : (cellParams) => (<IconButton onClick = { (event) => { setApprovalMemberId (cellParams.row.id); setDirectActionOpen(true); setAnchorEl (event.currentTarget); } } > <MoreVertIcon /> </IconButton>)}
    ];


    const approvalAction = (approvalMemberId) => {
        // Api calls here btw. 
        setDirectActionOpen (false);
    };
    const rejectAction = (approvalMemberId) => {
        // api calls here for VOC approval or reject
        setDirectActionOpen (false); 
    };  


    function CustomLoadingOverlay() {
        return (
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
      }

    return (
        <Fragment> 
            <div style = {{ display : 'flex', float : 'right' }}> <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { openPeriodDialog } >  <FilterListOutlinedIcon /> Filter Request Results   </Button> </div>
            <br />
            <GridContainer>
                { dataList.map ((dataType, idx) =>   (
                    <GridItem xs = {4} key = { idx } >
                    <Card > 
                        <CardHeader className = { classes.cardHeader } > <center> <b> <h1 style = {{ fontSize : '4em', fontWeight : '500' }} > { dataType.counts } </h1> </b>  </center>  </CardHeader>
                        <CardBody> 
                            <GridContainer> 
                                <GridItem xs = {12} > <center> <span style = {{ fontWeight : '500' }} > { dataType.title } </span> </center>  </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardActions className = { classes.cardFooter } >  <Link onClick = { (event) => changeData (idx) } style = {{ color : '#0000ff' }} > See Users </Link>   </CardActions>
                    </Card>
                    </GridItem>
                )) 
                }
            </GridContainer>

            <br />

            <GridContainer justify = "space-between" alignItems = "center" > 
                <GridItem> <b> <span style = {{ fontWeight : '500px', fontSize : '2em' }} > { currentDataCard.title } : { currentDataCard.counts } </span> </b> </GridItem>
                <GridItem>  
                    <SearchBar 
                        width = '300px' 
                        //handleSearch = { setSearchText }
                        //handleRefresh = { setRefreshState }
                        //refreshState = { refreshState }
                    /> 
                </GridItem>
            </GridContainer>
            
            <DataGrid
                autoHeight
                showToolbar 
                density="compact" 
                loading = {isLoading} 

                components={{ Toolbar: GridToolbar }}
                columns={columns} 
                rows={rows} 
                //rowCount = { rowCount }
                paginationMode = 'server'
                //page = { page }
                components={{ Toolbar: GridToolbar, LoadingOverlay: CustomLoadingOverlay }} 

                //onRowClick={(rowParams) => { setClickedMember (rowParams.row); setRowClicked(true); }} 
                //onPageChange = { (gridChangeParams) => { console.log("Changing page", gridChangeParams); setPage (gridChangeParams.page); setPageSize (gridChangeParams.pageSize);  } }
                //onPageSizeChange = { (gridChangeParams) => { console.log("Changing page", gridChangeParams); setPage (1); setPageSize(gridChangeParams.pageSize); } }
                rowsPerPageOptions ={ [25, 50, 100 ]}
                // onFilterModelChange = { (params) => {
                //     console.log(params); 
                //     if (params.filterModel.items[0].value) 
                //         setFilterModel (params.filterModel.items[0]); 
                // } }

                // sortingMode = 'server'
                // onSortModelChange = { params => { 
                //     console.log("Sorting model", params);  
                //     if (params.sortModel) 
                //         setSortModel (params.sortModel[0]);
                // } }


                
            />

            <PeriodDialog 
                periodDialog = { periodDialog } 
                openPeriodDialog = { openPeriodDialog } 
                startDate = { startDate } 
                endDate = { endDate } 
            />

            <DirectActionDialog 
                
                open = { directActionOpen }
                dialogTitle = { 'Direct Voucher Approval' }
                dialogContent = { 'Do you want to approve or reject the Voucher Request' }
                approvalAction = { approvalAction }
                rejectAction = { rejectAction }
                setDirectActionOpen = { setDirectActionOpen }
                anchorEl = { anchorEl }
            />

        </Fragment> 
    );   
}