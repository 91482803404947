import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { green } from '@material-ui/core/colors';
import { FormHelperText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width : '100%',
    textAlign : 'center'
  },
  checkedBox : {
    color: "#138DE8",
    "&$checked": {
      color: "#138DE8"
    }
  },
  multiSelect : {
    textAlign : 'center',
    justifyContent : 'center'
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent : 'center'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  "& .MuiChip-deleteIcon": { color: "white" }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}

export default function MultiSelectDropDown(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [personName, setPersonName] = React.useState([]);
  const [ names, setNames ] = useState (props.names);

  const handleChange = (event) => { 
    var values = event.target.value;
    props.setValues(values); 
    if (values.length == 1) 
      props.setDefaultCategory (values[0]);
  };
  
  return (
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-chip-label"> { props.label } </InputLabel>
        <Select
          className = { classes.multiSelect }
          multiple
          fullWidth
          
          disabled = { props.disabled }
          value={props.values}
          
          onChange={ handleChange }
          input={<Input error = { props.hasError } id="select-multiple-chip" multiline = { false } style = {{  textAlign : 'center', justifyContent : 'center' }} /> }
          
          
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip
                  className={classes.chip}
                  label={value}
                  onDelete={(e) => { props.setValues((prev) => prev.filter((i) => i !== value) ); }}
                  onClick={(e) => props.setDefaultCategory(value)}
                  icon={ props.defaultCategory === value && ( <CheckIcon style={{ color: "white", background: "#FF9017", borderRadius: 15 }} /> ) }
                  deleteIcon={ props.defaultCategory !== value ? ( <CloseIcon style={{ color: "grey", background: "white", borderRadius: 14 }} /> ) : ( <></> ) }
                  style={{ background: "#138DE8", color: "white" }}
                  onMouseDown={(event) => { event.stopPropagation(); }}
                  disabled = { props.disabled }
                />
              ))}
            </div>
          )}
          MenuProps={ MenuProps }
        >
          {names.map((name) => ( <MenuItem key={name} value={name} style={getStyles(name, props.values, theme)} > { <Checkbox color = 'default' className = { classes.checkedBox } checked={props.values.indexOf(name) > -1} /> } {name} </MenuItem> ))}
        </Select>
        <FormHelperText style = {{ color : 'red' }} > { props.hasError === true ? props.errorMessage : '' } </FormHelperText>
      </FormControl>
    
  );
}
