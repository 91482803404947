import { Button, Fab, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import HWMessage from 'HWLibrary/HWMessage';
import React, { useState, useEffect, useCallback } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import AddIcon from '@material-ui/icons/Add';
import { Edit } from '@material-ui/icons';
import merchantApiService from './APIService';
import BlockUi from 'react-block-ui';
import { HWInput } from 'HWLibrary/HWInput';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';

const useStyles = makeStyles (theme => ({
    ...styles,
    tableHead : { fontWeight : 500, color : '#138DE8', fontSize : 18 },
    textField : { textAlign : 'center' },
    editIcon :  { color : '#138DE8' },
    bikeModelLink : { textDecoration : 'underline', fontWeight : 500 }
}));

function SingleColumnTable (props) {

    var merchantarray=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    merchantarray= [...obj["Merchants"]];

    
    const classes = useStyles();    
    const { createNewRow,  handleAdd, handleSave, 
        handleCancel, captureTextChanges, identifier, name, rows, columnName, 
        canEdit, setCanEdit, handleUpdate, initiateBulkUpdate , setBranchToUpdate } = props;



        console.log("CanEdit",canEdit);
        console.log("name" , name);
        console.log("rows" , rows);
        console.log("columnName" , columnName);

    const [ allow, setAllow ] = useState (false);
    const [ loading, setLoading ] = useState (false);
    const [ updateList, setUpdateList ] = useState ([]);

    const captureWrapper = ( event, idx ) => {
        console.log("Hello");
        console.log(event);
        console.log(idx);
        captureTextChanges (event, idx);
        setAllow (false);

        setBranchToUpdate(prev=> { 
            var list = prev ? [ ...prev ] : [];
            return [...list, rows[idx]] });
    };
    const updateWrapper = ( value ) => {
        setLoading (true);
        handleUpdate(value);
    };
    
    console.log("Props", props); 
        
    return (
        <>
            <TableContainer component = { Paper } >
                <Table className = { classes.table } >
                    <TableHead>

                        <TableRow > 
                            <TableCell align = 'center' > 
                                <span className = { classes.tableHead } > { columnName }  </span>  
                                { merchantarray.includes("Action")===true && createNewRow === false && canEdit !== name && <IconButton onClick = { e => {  setCanEdit (prev => name); console.log("Present name", canEdit, name); } } > <Edit className = { classes.editIcon } /> </IconButton>}
                                {merchantarray.includes("Action")===true &&  createNewRow === false && canEdit !== name && <Fab variant="extended" aria-label="add" style = {{ float : 'right' , background : '#FF9017', color : 'white' }} onClick = { handleAdd }  >   <AddIcon />  Add Rule </Fab>} 

                            </TableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { 
                            rows && rows.map ((row, idx) => (
                                    <TableRow key = { row.id } >
                                        <TableCell align = 'center' >
                                            { 
                                                (createNewRow === true && idx === rows.length - 1) && <TextField onBlur = { e => captureTextChanges(e, idx) } name = { name } inputProps = {{  style : { textAlign : 'center' } }} variant = 'outlined' value = { row[identifier] } /> ||
                                                ( canEdit === name && <TextField onChange = { e => { captureWrapper (e, idx); } } onBlur = { e => { updateWrapper ( rows [ idx ] ) } }  name = { name } inputProps = {{ style : { textAlign : 'center' } }} variant = 'outlined' value = { row[identifier] } />) || 
                                                ( <span> { row[identifier] } </span>)
                                            }  
                                        </TableCell>    
                                    </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <GridContainer>
                <GridItem>
                    <center>
                        { (createNewRow === true || canEdit === name) && <Button variant = "raised" style = {{ color : 'floralwhite', backgroundColor : '#138DE8' , textTransform:'None' }} onClick = { e => { 
                            if (canEdit === name && allow === false) {  initiateBulkUpdate (); /*handleUpdate ();*/ } 
                            else if (createNewRow === true) { handleSave (rows[rows.length - 1]); }
                            }   } >  { canEdit === name ? 'Update' : 'Save' }  
                        </Button>
                        }
                        { (createNewRow === true || canEdit === name ) && <Button variant = "raised" style =  {{ color : 'floralwhite', backgroundColor : '#FF9017' , textTransform:'None' }} onClick = { handleCancel } > Cancel </Button>}
                    </center>
                </GridItem>
            </GridContainer>
        </>
    );
};

export default function MerchantConfiguration (props) {
    HWLocalStorage.save (2, "previousMerchantTab")
    if(HWLocalStorage.load("previousMerchantDetailsTab")!==null) { HWLocalStorage.clear("previousMerchantDetailsTab"); }
    const classes = useStyles ();

  


    const [ categoriesLoading, setCategoriesLoading ] = useState (false);
    const [ productLoading, setProductLoading ] = useState (false);
    const [ branchLoading, setBranchLoading ] = useState (false);

    const [ branchList, setBranchList ] = useState ([ ]);
    const [ productList, setProductList ]  = useState ([ ]);
    const [ categoryList, setCategoryList ] = useState ([ ]);
    const [ createNewRowBranch, setCreateNewRowBranch ] = useState (false);
    const [ createNewRowCategory, setCreateNewRowCategory ] = useState (false);
    const [ createNewRowProduct, setCreateNewRowProduct ] = useState (false);
    const [ canEdit, setCanEdit ] = useState ('none');
    const [ branchRefreshState, setBranchRefreshState ] = useState (false);
    const [ productRefreshState, setProductRefreshState ] = useState (false);
    const [ categoryRefreshState, setCategoryRefreshState ] = useState (false);
    const [ refreshState, setRefreshState ] = useState (false);
    const [ branchToUpdate, setBranchToUpdate ] = useState ([]);
    const [ productToUpdate, setProductToUpdate ] = useState ([]);
    const [ categoryToUpdate, setCategoryToUpdate ] = useState ([]);


    useEffect (() => {
        setBranchLoading (true);
        merchantApiService.fetchBranches ()
        .then (response => {
            if (! response || ! response.data) { return HWMessage.show ("Branches cannot be fetched","error"); }
            setBranchList ([  ... response.data ]);
            HWMessage.show("Request Successful" , "success");
        }).catch (error => { return HWMessage.show ("Error while fetching branches","error"); })
        .finally (() => setBranchLoading (false));
    }, [ branchRefreshState , refreshState]);

    useEffect (() => {
        setCategoriesLoading (true);
        merchantApiService.fetchCategories () 
        .then (response => {  
            if (! response || ! response.data) { return HWMessage.show ("Error while fetching categories","error"); }
            setCategoryList (response.data);
            HWMessage.show("Request Successful" , "success");
        }) 
        .catch (error => { HWMessage.show ("Error while fetching categories","error"); })
        .finally (() => setCategoriesLoading (false));
    }, [ categoryRefreshState  ]);

    useEffect (() => {
        setProductLoading (true);
        merchantApiService.fetchProductCategories ()
        .then (response => {  
            if (! response || ! response.data) { return HWMessage.show ("Product Categories cannot be fetched","error"); }
            setProductList (response.data.productCategoryList); 
            HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error"); })
        .finally (() => setProductLoading (false));
    }, [ productRefreshState ]);

    const handleAddBranch = e => {
        setCanEdit ('none');
        setCreateNewRowBranch (true);
        branchList.push ({});
        setBranchList (branchList);
    };
    const handleAddCategory = e => {
        setCanEdit ('none');
        setCreateNewRowCategory (true);
        categoryList.push ({});
        setCategoryList (categoryList);
    };
    const handleAddProduct = e => {
        setCanEdit ('none');
        setCreateNewRowProduct (true);
        productList.push ({});
        setProductList (productList);
    };

    const handleSaveBranch = branch => {
        setCreateNewRowBranch (false);
        console.log("Branch", branch);
        var filter = branchList.filter (i => i.branchName === branch.branchName);
        if (filter.length > 1) { HWMessage.show ("Branch with this name already exists. Please use another name","error");  branchList.pop()}
        // if (branchList.indexOf(branch.branchName) !== branchList.length - 1) { HWMessage.show ("Branch with this name already exists. Please use another name","error"); branchList.pop() }
        else {
            addBranch (branch);
            return HWMessage.show ("Branch added successfully");
        }
    };
    const handleSaveProduct = product => {
        setCreateNewRowProduct (false);
        var filter = productList.filter (i => i.categoryName === product.categoryName);
        if (filter.length > 1) { HWMessage.show ("Branch with this name already exists. Please use another name","error");  productList.pop()}
        // if (productList.indexOf(product.categoryName) !== productList.length - 1) { HWMessage.show("Product catgegory with this name already exists. Please rename the field or cancel","error");productList.pop(); }
        else {
            addProduct (product);
            return HWMessage.show ("Product added successfully");
        }
    };
    const handleSaveCategory = category => {
        setCreateNewRowCategory (false);
        // if (categoryList.indexOf(category.categoryName) !==  categoryList.length - 1) { HWMessage.show ("Categriy already exists please rename the field or cancel the added category","error"); categoryList.pop(); } 
        var filter = categoryList.filter (i => i.categoryName === category.categoryName);
        if (filter.length > 1) { HWMessage.show ("Branch with this name already exists. Please use another name","error");  categoryList.pop()}
        else {
            addCategory (category);
            console.log("Hi thiser", categoryList);
            return HWMessage.show ("Category added successfully","success");
        }
    };
    const handleBranchCancel = () => {
        if (createNewRowBranch === false && canEdit !== 'branch') { HWMessage.show ("Nothing to cancel","error"); }
        else if (canEdit === 'branch') { setCanEdit ('none'); } 
        else {
            setCreateNewRowBranch (false);
            branchList.pop();
            setBranchList (branchList);
            return HWMessage.show ("Branch addition cancelled","info");
        }
    };
    const handleCategoryCancel = () => {
        if (createNewRowCategory === false && canEdit !== 'category') { HWMessage.show ("Nothing to cancel","info"); }
        else if (canEdit === 'category') { setCanEdit ('none'); } 
        else {
            setCreateNewRowCategory (false);
            categoryList.pop();
            setCategoryList (categoryList);
            return HWMessage.show ("Category addition cancelled","info");
        }
    };

    const handleProductCancel = () => {
        if (createNewRowProduct === false && canEdit !== 'product') { return HWMessage.show ("Nothing to cancel","info"); }
        else if (canEdit === 'product') { setCanEdit ('none'); } 
        else {
            setCreateNewRowProduct (false);
            setCanEdit ('none');
            productList.pop();
            setProductList (productList);
            return HWMessage.show ("Product addition cancelled","info");
        }
    };

    const addBranch = useCallback (branch => { 
        merchantApiService.createBranch (branch)
        .then (response => {  
            if (! response || ! response.data) { return HWMessage.show ("Unknown error occured","error"); }
            HWMessage.show ("Branch added successfully");
            setBranchList (prev => { prev[prev.length - 1].id = response.data.id; return prev;});
        })
        .catch (error => {console.log("error", error);   HWMessage.show ("Branch could not be added","error"); })
        .finally (() => {   setBranchRefreshState(!branchRefreshState); setRefreshState(!refreshState); })
    }, []);

    const addProduct = useCallback (product => {
        setProductLoading (true);
        merchantApiService.createProductCategory (product)
        .then (response => { 
            if (! response || ! response.data) { return HWMessage.show ("Product cannot be added at moement","error"); }
            HWMessage.show ("Product added successfully","success");
            setProductList (prev => { prev[prev.length - 1].id = response.data.id; return prev; });
        })
        .catch (error => {  setProductRefreshState(!productRefreshState);HWMessage.show ("Error while adding product category","error"); console.log (error); })
        .finally (() => { setProductLoading (false) });
    }, []);

    const addCategory = useCallback (category => {
        setCategoriesLoading (true);
        merchantApiService.createCategory (category) 
        .then (response => { 
            if (! response || ! response.data) { return HWMessage.show ("Merchant Category cannot be added at moement","error"); }
            HWMessage.show ("Merchant Category added successfully"); 
            setCategoryList (prev => { prev[prev.length - 1].id = response.data.id; return prev; });
        })
        .catch (error => { setCategoryRefreshState(!categoryRefreshState) ; HWMessage.show ("Error while adding merchant category","error"); })
        .finally (() => { setCategoriesLoading (false) });
    }, []);

    const validateFieldValues = useCallback((field, value, list) => {
        let matching = [];
        console.log(list, field ,value);
        switch (field) {
            case 'branch' : matching = list.filter ( i => i.branchName === value.branchName ); break;
            case 'category' :  matching = list.filter ( i => i.categoryName === value.categoryName ); break;
            case 'product' : matching = list.filter ( i => i.categoryName === value.categoryName ); break;
        }
        if (matching.length > 0) {
            if (matching.length > 1)  return false;
            else if (value.id !== matching[ 0 ].id)  return false; 
        } else return true;  
    }, [ ]);

    const updateBranch = useCallback ((branch, branchList) => {  
        var x = validateFieldValues ('branch', branch, branchList);
        console.log(x);
        if (x === false) {
            HWMessage.show ("One or more value coincides with given value", "error");
            setBranchRefreshState (!branchRefreshState);
            setRefreshState (prev => !prev );
        } else {
            setBranchLoading(true);
            merchantApiService.updateBranchDetails (branch)
            .then (response => {  
                if (! response || ! response.data) { return HWMessage.show ("Erorr while updating the response","error"); }
                HWMessage.show ("Updated Successfully","success");
            })
            .catch (error => { setBranchRefreshState (!branchRefreshState); setRefreshState (! refreshState); HWMessage.show ("Error while updating branch", "error");  })
            .finally (() => setBranchLoading(false));
        }
    }, [ branchList ]);    

    const updateProduct = useCallback ((product, productList) => {
        var x = validateFieldValues ('product', product, productList);
        if (x === false) {
            HWMessage.show ("One or more value coincides with given value", "error");
            setProductRefreshState (!productRefreshState);
            setRefreshState (prev => !prev );
        } else {
            setProductLoading (true);
            merchantApiService.updateProductCategory (product)
            .then (response => {
                if (! response || ! response.data) {  return HWMessage.show ("Error while fetching response","error"); }
                HWMessage.show ("Updated Successfully","success");
            })
            .catch (error => { setProductRefreshState (!productRefreshState); setRefreshState (! refreshState);  HWMessage.show ("Error while updating product categories","error"); })
            .finally (() => { setProductLoading (false); })
        }
    }, [productList]);       

    const updateCategory = useCallback ((category, categoryList) => {
        var x = validateFieldValues ('category', category , categoryList);
        if (x === false) {
            HWMessage.show ("One or more value coincides with given value", "error");
            setCategoryRefreshState(!categoryRefreshState);
            setRefreshState (prev => !prev );
        } else {
            setCategoriesLoading (true);
            merchantApiService.updateCategoryDetails (category)
            .then (response => {  
                if (! response || ! response.data) { return HWMessage.show ("Response cannot be fetched","error"); }
                HWMessage.show ("Updated Successfully","success");
            }).catch (error => { setCategoryRefreshState(!categoryRefreshState)  ; setRefreshState (! refreshState);  HWMessage.show ("Error while updating categories","error") })
            .finally (() => { setCategoriesLoading (false) });
        }
    }, []);     
    const updateBranches = (branch) => {
        var list = [ ...branchList ];
        var existingValue = branchToUpdate == null ? [] : [ ...branchToUpdate, branch ];
        setBranchToUpdate ( [ ...existingValue ] );
        // updateBranch (branch, list); 
        return true;
    };
    

    const updateProducts = (product) => {
        // if (! product) return;
        // var list = [ ...productList ];
        // console.log("Updating list", list);

        //     setCanEdit ('none');
        //     updateProduct (product , list);
        //     return true;
        var list = [ ...productList ];
        var existingValue = productToUpdate == null ? [] : [ ...productToUpdate, product ];
        setProductToUpdate ( [ ...existingValue ] );
        // updateBranch (branch, list); 
        return true;
    };


    const updateCategories = (category) => {
        // console.log("Updating Category", category);
        // var list = [ ...categoryList ];
        // console.log("Updating list", list);
        // setCanEdit ('none');
        // updateCategory (category,list);
        // return true;
        var list = [ ...categoryList ];
        var existingValue = categoryToUpdate == null ? [] : [ ...categoryToUpdate, category ];
        setCategoryToUpdate ( [ ...existingValue ] );
        // updateBranch (branch, list); 
        return true;
    };


    const startCategoryUpdate = () => {
        setCanEdit ('none');
        var list = [ ...categoryList ];
        

        if (categoryToUpdate != null) {
            // updateBranch ( branchUpdate, list );
            categoryToUpdate.forEach(i => updateCategory (i, list) );
            setCategoryToUpdate ( null );
        } else { HWMessage.show ("Please Update some categories to continue"); }
    };


    const startBranchUpdate = () => {
        setCanEdit ('none');
        var list = [ ...branchList ];
      
        if (branchToUpdate != null) {
            // updateBranch ( branchUpdate, list );
            branchToUpdate.forEach(i => updateBranch (i, list) );
            setBranchToUpdate ( null );
        } else { HWMessage.show ("Please Update some categories to continue"); }
    };


    const startProductUpdate = () => {
        setCanEdit ('none');
        var list = [ ...productList ];
        

        if (productToUpdate != null) {
            // updateBranch ( branchUpdate, list );
            productToUpdate.forEach(i => updateProduct (i, list) );
            setProductToUpdate ( null );
        } else { HWMessage.show ("Please Update some categories to continue"); }
    };


    const captureTextChanges = (e, idx) => {
        const field = e.target.name;
        const value = e.target.value;
        console.log (field, value);
        switch (field) {
            case 'branch': setBranchList (prev => { prev[idx].branchName = value; return [ ...prev]; }); break;
            case 'category': setCategoryList (prev => { prev[idx].categoryName = value;   return [ ...prev ]; }); break;
            case 'product': setProductList (prev => { prev[idx].categoryName = value;  return [ ...prev ]; }); break;
        }   
    };

    console.log("BranchList changed or notx",branchList);
    return (
        <>
            
                <GridContainer>
                    <GridItem xs = {4} md = {4} sm = {4} >
                        <BlockUi blocking = { branchLoading } message = { 'Loading Branches' } >
                            <SingleColumnTable 
                                createNewRow = { createNewRowBranch } 
                                handleAdd = { handleAddBranch } 
                                handleSave = { handleSaveBranch } 
                                handleCancel = { handleBranchCancel }
                                captureTextChanges = { captureTextChanges }
                                identifier = { 'branchName' }
                                name = { 'branch' }
                                rows = { branchList }
                                columnName = { 'Branch' }
                                canEdit = { canEdit }
                                setCanEdit = { setCanEdit }
                                handleUpdate = { updateBranches }
                                loading = {branchLoading}
                                setBranchToUpdate = {setBranchToUpdate}
                                initiateBulkUpdate = { startBranchUpdate }
                            /> 
                        </BlockUi>
                    </GridItem>
                    
                    <GridItem xs = {4} sm = {4} md = {4} >
                        <BlockUi blocking = { categoriesLoading } message = { 'Loading Categories' } >
                            <SingleColumnTable 
                                createNewRow = { createNewRowCategory } 
                                handleAdd = { handleAddCategory } 
                                handleSave = { handleSaveCategory } 
                                handleCancel = { handleCategoryCancel }
                                captureTextChanges = { captureTextChanges }
                                identifier = { 'categoryName' }
                                name = { 'category' }
                                rows = { categoryList }
                                columnName = { 'Category' }
                                canEdit = { canEdit }
                                setCanEdit = { setCanEdit }
                                handleUpdate = { updateCategories }
                                loading = { categoriesLoading }
                                setBranchToUpdate = {setCategoryToUpdate}
                                initiateBulkUpdate = { startCategoryUpdate }
                            /> 
                        </BlockUi>
                    </GridItem>
                    <GridItem xs = {4} sm = {4} md = {4} >
                        <BlockUi blocking = { productLoading } message = { 'Loading Products' } >
                            <SingleColumnTable 
                                createNewRow = { createNewRowProduct } 
                                handleAdd = { handleAddProduct } 
                                handleSave = { handleSaveProduct } 
                                handleCancel = { handleProductCancel }
                                captureTextChanges = { captureTextChanges }
                                identifier = { 'categoryName' }
                                name = { 'product' }
                                rows = { productList }
                                columnName = { 'Product' }
                                canEdit = { canEdit }
                                setCanEdit = { setCanEdit }
                                handleUpdate = { updateProducts }
                                loading={ productLoading }
                                setBranchToUpdate = {setProductToUpdate}
                                initiateBulkUpdate = { startProductUpdate }
                            /> 
                        </BlockUi>
                    </GridItem>
                </GridContainer>
            
        </>
    );
};