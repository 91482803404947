import React, {Component,useEffect, useState } from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import { Fab, IconButton } from '@material-ui/core';
import HWMessage from 'HWLibrary/HWMessage';
import imageApiService from './APIService';
import Button from '@material-ui/core/Button';


export default class FileUploader extends Component {
/*
    const [ uploadLoading, setUploadLoading ] = useState (false);
    const [ maxSelectsAllowed, setMaxSelectsAllowed ] = useState (this.props && this.props.maxSelections || 3);
    const [ multiSelectsAllowed, setMultiSelectsAllowed ] = useState (this.props && this.props);
    const allowedExtensions = [ 'png', 'jpg', 'jpeg' ];
    const [ folderName, setFolderName ] = useState (this.props && this.props.folderName || "/");
    const [file, setFile] = useState([]);

    

    const uploadSingleFile = (file, fileName, fileExtension) => {
        if (!file || file === null) { return HWMessage.show("File is invalid to be uploaded. Please try with other files"); }
        setUploadLoading (true);
        imageApiService.fetchS3PresignedUrl(fileName, fileExtension).then (response => {
            if (!response || ! response.data) { return HWMessage.show ("Error while fetching the pre-signed URL"); }  
            let preSignedUrl = response.data.preSignedURL;
            if (! preSignedUrl || preSignedUrl != null) { return HWMessage.show ("Error while fetching pre-signed url"); }
            imageApiService.uploadFile (file, preSignedUrl)
            .then (response => {})
            .catch (error => {  });
        }).catch (error => {  })
    };


     /* const handleImageChanges = event => { 
        event.preventDefault();

        console.log ("target",  event.target.files );
        console.log ("targetfiles" , event.target.files );

        setUploadLoading (false);
        let files = event.target.files;
    
        if (files.length === 1) {
            setFile(event.target.files)
            let file = files[0];
            let fileName = file.name;
            let fileExtension = fileName.split.pop();

            if (allowedExtensions.indexOf(fileExtension) !== -1) {
                return HWMessage.show ("Please upload allowed file type only");
            }
            uploadSingleFile (file, fileName, fileExtension);
        } 
        else {
            //On the server side you will get all the images from 'images' key of request object/variable
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
            formData.append(`images[${i}]`, files[i])
            let file = files[i];
            let fileName = file.name;
            let fileExtension = fileName.split.pop();

            if (allowedExtensions.indexOf(fileExtension) !== -1) {
                return HWMessage.show ("Please upload allowed file type only");
            }
            uploadSingleFile (file, fileName, fileExtension);
        }
    } 
    };



*/


 
    constructor(props) {
        super(props)
        this.state = {
            file: [null],
            fileObj : [],
            fileArray : [],
            currentCampaign : {}, 

        }
        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
        this.uploadFiles = this.uploadFiles.bind(this)
    }

    uploadMultipleFiles(e) {
        this.state.fileObj.push(e.target.files)
        for (let i = 0; i < this.state.fileObj[0].length; i++) {
            this.state.fileArray.push(URL.createObjectURL(this.state.fileObj[0][i]))
        }
        this.setState({ file: this.state.fileArray })
    }

    async uploadFiles(e) {
        e.preventDefault()
        console.log(this.state.file)
        var url= this.state.file;
        console.log("mULTIPLE" , this.state.currentCampaign);
       await this.setState({currentCampaign : {...this.props.currentCampaign , contentImageUrls : url }});
        this.props.setCurrentCampaign (this.state.currentCampaign);
    }

    render() {
        return (
            <form>
                <div  >
                    {(this.state.fileArray || []).map(url => (
                        <img style = {{ height: 100 ,  width:100 , margin:10 }} src={url} alt="..." />
                    ))}
                </div>

                <div >
                <input type="file" style = {{display:"none"}} id="upload-pic" accept="image/*"  onChange={this.uploadMultipleFiles} multiple />
                <label htmlFor = "upload-pic" >
                <Fab  component="span" aria-label="add">
                    <PublishIcon />
                </Fab>
               </label>
               <br/>
               <br/>
               <Button variant="contained" style={{backgroundColor :"#138DE8" , color : "floralwhite"}} component="span" onClick={this.uploadFiles}>
               Upload
               </Button>
            
                </div>
            </form >
        )
    }
}