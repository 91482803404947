import React, { useState, Fragment } from 'react';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { People, SubdirectoryArrowRightSharp } from '@material-ui/icons';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import CardFooter from 'components/Card/CardFooter';
import { Button, Checkbox, makeStyles, TextField, Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from 'HWLibrary/HWInput';
import HWMessage from 'HWLibrary/HWMessage';
import { useHistory  } from "react-router-dom";
import { DropzoneDialog } from 'material-ui-dropzone';
import {v4 as uuidv4} from 'uuid'
import userSegmentApiService from './APIService';
import { DropDown } from 'views/Merchant/Merchant';
import BlockUi from 'react-block-ui';
import cloudfrontConfig from 'HWLibrary/HWCloudfrontConfig';

const useStyles = makeStyles (theme => ({
    ...styles,
    card : { borderRadius : 10 },
    gridItem : { marginTop : 5 },
    draftButton : { background : '#FF9017', color : 'white', marginRight : 5 },
    proceedButton : { background : '#138DE8', color : 'white' },
    cardFooter : { justifyContent : 'center' }
}));

export default function SegmentUsingCsv (props) {


    const classes = useStyles ();
    const history = useHistory ();

    const [currentRule, setCurrentRule] = useState({});
    const [openSingle ,setOpenSingle] = useState(false);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [ didFileUploadFail , setDidFileUploadFail ] = useState( false );
    const [ keyFileName ,setKeyFileName ] = useState("");
    const [ruleLoading ,setRuleLoading] = useState(false);

    const handleCancel = () => { props.setOpen (prev => false); };

    const  uploadBulkFile = (file , fileName) => {
    setDidFileUploadFail (false);
    userSegmentApiService.fetchS3PresignedUrl(fileName).then(response => {
        setDidFileUploadFail (false);
        console.log(response.data.presignedUrl);

        setKeyFileName (response.data.keyName);     
        multibulkPartUpload(file, response.data.presignedUrl);

    }).catch((reason) => {
        setDidFileUploadFail (true);
        setUploadInProgress(true);
        setUploadInProgress(false);
    } )
}

const multibulkPartUpload = (file, preSignedURL) => {
    userSegmentApiService.uploadFile(file, preSignedURL).then(response => {

    setDidFileUploadFail (false);
    setUploadInProgress(false);

        }).catch((reason) => {
    setDidFileUploadFail (false);
    setUploadInProgress(false);

})
}

const submitForm = () => {
    setRuleLoading (true);
    currentRule.type= "Member";
    userSegmentApiService.submitForm (currentRule)
    .then (response => {
        if (! response || ! response.data) { HWMessage.show ("Error while fetchinmg response" , "error"); }
        HWMessage.show("Request Successful" , "success");
        setRuleLoading (false);
        history.push ({ pathname : '/ops/user-segments' })
    }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    setRuleLoading (false);})
    .finally (() => { 
        // setRuleLoading (false);
        // history.push ({ pathname : '/ops/user-segments' })
    });
}


console.log("Current Rule" , currentRule);


    return (
        <Fragment>
            <Card>
                <CardHeader className = { classes.cardHeader } color = 'rose' icon >
                    <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        <h4 className = { classes.cardIconTitle } > Create Segment Using CSV  </h4> 
                </CardHeader>     
                <CardBody>
                <BlockUi blocking = { uploadInProgress || ruleLoading } message = { 'Please wait.' } > 

                             
                             
                                    <GridItem xs = {4} md = {4} sm = {4}> 
                                        <HWInput label = { 'Segment Name' } name="Name" labelProps = {{ shrink: true }}  
                                         onBlur = {e=> {console.log(e.target.value); setCurrentRule (prev => ({ ...prev, ["segmentName"] : e.target.value  }))}} 
                                        />
                                    </GridItem>
                              
                                    <GridItem xs = {6} md = {6} sm = {6} > 
                                        {/* <HWInput label = { 'Segment Parameter' } name="Parameter" labelProps = {{ shrink: true }} 
                                        onBlur = {e=> {console.log(e.target.value) ; setCurrentRule (prev => ({ ...prev, ["segmentParameter"] : e.target.value  }))}} 

                                        /> */}

                                    <DropDown 
                                    labelCategory = "Segment Parameter" 
                                    name = "Parameter"
                                    minWidth = {300}
                                    values = {['MembershipNumber','ChassisNumber']}
                                    targetValue = { currentRule && currentRule.segmentParameter }
                                    onChange = { e=> setCurrentRule (prev => ({ ...prev, ["segmentParameter"] : e.target.value  }))  }
                                    required = {true}
                                    />  
                                    </GridItem>
                                    <br/>
                            
                                <center>
                                    <GridItem xs = {6} md = {6} sm = {6}> 
                                       
                                    <Button 
                                     raised 
                                     variant = "contained" 
                                     style = {{ backgroundColor : '#138de8', color : 'floralwhite', textTransform : 'none' }} 
                                     onClick={() => setOpenSingle(true)}> 
                                     Upload CSV 
                                    </Button>
                                    <br/>

                                    <DropzoneDialog 
                                    clearOnUnmount={ false }
                                     initialFiles = {[]}
                                     acceptedFiles={[".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}
                                     cancelButtonText={"cancel"}
                                    submitButtonText={"submit"}
                                    maxFileSize={5000000}
                                    open={openSingle}
                                    filesLimit={1}
                                    onClose={() => setOpenSingle(false)}
                                    onDelete = {(deletedFile) =>   setCurrentRule (prev => ({ ...prev, csvFileName : ''  })) } 

                                    onSave={(bannerImageFile) => {                    
                                    console.log('Banner Image File:', bannerImageFile);
                           
                                        setUploadInProgress(true);
                                        let myuuid = uuidv4();

                                        let file = bannerImageFile[0];
                                        let fileName = file.name ;
                                        let fileNameUrl = fileName.substring(0, fileName.indexOf("."))
                                        if (file != null) {
                                        let fileName = file.name
                                        let fileExtension = fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1)
                                        uploadBulkFile(file , myuuid );
                                        }
                                        setCurrentRule (prev => ({ ...prev, csvFileName : `${cloudfrontConfig.cloudfrontBaseUrl}/segment-csv/${ myuuid }`
                                    })) ;
                                        setOpenSingle(false);
                            }}
                           
                            fullWidth ={true} />

                                    </GridItem>

                                   <br/>
                                    <GridItem xs = {6} md = {6} sm = {6}> 
                                    <Button 
                                     raised 
                                     dsiabled = {(currentRule.csvFileName!="" || currentRule.csvFileName!==undefined || currentRule.csvFileName!==null) && (currentRule.segmentParameter!="" || currentRule.segmentParameter!==undefined || currentRule.segmentParameter!==null) && (currentRule.segmentName!="" || currentRule.segmentName!==undefined || currentRule.segmentName!==null)}
                                     variant = "contained" 
                                     style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' , marginRight: "5px" }} 
                                     onClick={() => submitForm()}> 
                                     Create Segment  
                                    </Button>

                                    <Button 
                                     raised 
                                     variant = "contained" 
                                     style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} 
                                     onClick={() => history.push ({ pathname : '/ops/user-segments' })
                                    }> 
                                     Cancel 
                                    </Button>
                                    </GridItem>
                                    
                                    </center>   
                   </BlockUi>
                </CardBody>
                <CardFooter>
                </CardFooter>
            </Card>     
        </Fragment>
    );
}