import React ,  { useEffect,  } from 'react'; 
 import { GoogleMap, Marker, withGoogleMap, withScriptjs,panTo } from "react-google-maps"
 import { compose, withProps,defaultProps } from "recompose";
 import { Component } from 'react';
 import Autocomplete from 'react-google-autocomplete';
 import google from 'google-map-react';
 




const HWGoogleMaps = 
compose(
  
  withProps({
  googleMapURL     : "https://maps.googleapis.com/maps/api/js?key=AIzaSyADUBIkqzAu4Z0v65kVuCGy-UbxNSZIHFE&libraries=geometry,drawing,places",
 loadingElement   : <div style={{ height: `100%` }} />,
  containerElement : <div style={{ height: `400px` }} /> ,
  mapElement       : <div style={{ height: `100%` }} /> 
  
  
  }),
  withScriptjs,
  withGoogleMap)((props) => 
  



  
  
  
  <GoogleMap
  
    defaultZoom={12}
    defaultCenter={{lat: props.lat,lng:props.lng}}
    center={{lat: props.lat,lng:props.lng}}
    
    // onClick={  (mapProps )  => {

      
      
    //     console.log(mapProps)
    //     console.log(mapProps.jb.x, mapProps.jb.y);
    //     if (mapProps && mapProps.jb )
    //       props.setLocation ({ lat :  mapProps.jb.x, lng :  mapProps.jb.y });

    //     }  }
     >

    {/* <Autocomplete
       style={{
        width: '100%',
        height: '40px',
        paddingLeft: '16px',
        marginTop: '2px',
        marginBottom: '100px'
       }}
       onPlaceSelected={ place => { 
         console.log ("jhgdaskjhdkas", place , place.geometry.location.lat(), place.geometry.location.lng());
         props.setLocation (prev => ({ lat : place.geometry.location.lat(), lng : place.geometry.location.lng() }));
        } 
       }
       types={['(regions)']}
    /> */}
    <Marker 
        position={{lat: props.lat,lng:props.lng}}  
        draggable={true}
        visible={true}
        //onDragend={this.moveMarker.bind(this)}
        onDragEnd={(e)=> {props.getDetailedAddress(e.latLng.lat(),e.latLng.lng()); console.log(e.latLng.lat(),e.latLng.lng())}}
        
        
    />
    
  </GoogleMap>
  
  
  
  )   

  
  
export default HWGoogleMaps;



// // import {Map,GoogleApiWrapper,InfoWindow , Marker} from 'google-maps-react';


// // export class HWGoogleMaps extends Component {

// //     onMarkerClick = (props, marker, e) => { console.log(props, marker, e); 
// //     } ;
 
// //     onMapClicked = (props) => { console.log(props) };
 
// //     render() {
// //       return (
// //         <Map google={this.props.google} 
// //             defaultZoom={16}
// //             initialCenter={{
// //                 lat: this.props.location.lat,
// //                 lng: this.props.location.lng
// //             }}
// //             center={{
// //                 lat: this.props.location.lat,
// //                 lng: this.props.location.lng
// //               }}
// //             onClick={this.onMapClicked}

// //         >
   
// //    <Marker 
// //        position={{lat: this.props.location.lat, lng: this.props.location.lng}}
// //         draggable={true}  
// //         visible={true}
// //         onDragend={this.moveMarker.bind(this)}
        
// //     />
 
   
// //         </Map>
// //       );
// //     }
// //   }
   
// //   export default GoogleApiWrapper({
// //     apiKey: ("AIzaSyAJBQTsOOip1dDYTD6LEl796Aw8UEdnavk")
// //   })(HWGoogleMaps);


// export default function HWGoogleMaps(props) {
// let map;
// let marker;
// let geocoder;
// let responseDiv;
// let response;

// function initMap() {
//   map = new google.maps.Map(document.getElementById("map"), {
//     zoom: 8,
//     center: { lat: props.lan, lng: props.lng },
//     mapTypeControl: false,
//   });
//   geocoder = new google.maps.Geocoder();

//   const inputText = document.createElement("input");

//   inputText.type = "text";
//   inputText.placeholder = "Enter a location";

//   const submitButton = document.createElement("input");

//   submitButton.type = "button";
//   submitButton.value = "Geocode";
//   submitButton.classList.add("button", "button-primary");

//   const clearButton = document.createElement("input");

//   clearButton.type = "button";
//   clearButton.value = "Clear";
//   clearButton.classList.add("button", "button-secondary");
//   response = document.createElement("pre");
//   response.id = "response";
//   response.innerText = "";
//   responseDiv = document.createElement("div");
//   responseDiv.id = "response-container";
//   responseDiv.appendChild(response);

//   const instructionsElement = document.createElement("p");

//   instructionsElement.id = "instructions";
//   instructionsElement.innerHTML =
//     "<strong>Instructions</strong>: Enter an address in the textbox to geocode or click on the map to reverse geocode.";
//   map.controls[google.maps.ControlPosition.TOP_LEFT].push(inputText);
//   map.controls[google.maps.ControlPosition.TOP_LEFT].push(submitButton);
//   map.controls[google.maps.ControlPosition.TOP_LEFT].push(clearButton);
//   map.controls[google.maps.ControlPosition.LEFT_TOP].push(instructionsElement);
//   map.controls[google.maps.ControlPosition.LEFT_TOP].push(responseDiv);
//   marker = new google.maps.Marker({
//     map,
//   });
//   map.addListener("click", (e) => {
//     geocode({ location: e.latLng });
//   });
//   submitButton.addEventListener("click", () =>
//     geocode({ address: inputText.value })
//   );
//   clearButton.addEventListener("click", () => {
//     clear();
//   });
//   clear();
// }

// function clear() {
//   marker.setMap(null);
//   responseDiv.style.display = "none";
// }

// function geocode(request) {
//   clear();
//   geocoder
//     .geocode(request)
//     .then((result) => {
//       const { results } = result;

//       map.setCenter(results[0].geometry.location);
//       marker.setPosition(results[0].geometry.location);
//       props.setLocation({ latitude : results[0].geometry.location.lat, longitude : results[0].geometry.location.lng, detailedAddress : results[0].formatted_address })
//       marker.setMap(map);
//       responseDiv.style.display = "block";
//       response.innerText = JSON.stringify(result, null, 2);
//       return results;
//     })
//     .catch((e) => {
//       alert("Geocode was not successful for the following reason: " + e);
//     });
// }

// window.initMap = initMap;
// }
