import React from 'react';
import { Button, TextField, Dialog, DialogContent, DialogActions, DialogContentText , DialogTitle } from '@material-ui/core';
import { useState } from 'react';
import HWMessage from 'HWLibrary/HWMessage';
import memberApiService from './APIService';
import FormDialog4 from './ConfirmPsd';
import BlockUi from 'react-block-ui';


export default function FormDialog3 (props) {
  const [open, setOpen] = useState(true);
  const [open3 , setOpen3] = useState(false);
  const [otp , setOtp] = useState("");
  const [isLoading ,setIsLoading ] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleChange = () => {
    setIsLoading(true);
    memberApiService.checkOtp (otp, props.mail)
    .then (response => {
        setOpen(false);
        setOpen3(true);
      HWMessage.show("Request Successful" , "success");

    })
    .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
  })
    .finally (() => setIsLoading (false));
}


const check = () => {

    if(otp.length < 1)
    {
        return true;
    }
    else{
        return false;
    }
}
   

  return (
    <div >
   <BlockUi blocking = { isLoading } message = { 'Please wait while we fetch.' } >
    { open &&  <Dialog open={open} onClose={handleClose}   fullWidth={true}      maxWidth={"md"}>
        <DialogTitle style={{width : "400px"}}>Enter OTP</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter OTP sent to your mail.
            <TextField
            margin="dense"
            id="name"
            label="Enter OTP"
            type="text"
            fullWidth
            variant="standard"
            onBlur ={ e=> setOtp(e.target.value)}
            error = { otp !== "" && (otp.length < 1 || otp.length > 6) }
          />
          </DialogContentText>
         
         
        </DialogContent>
        <DialogActions>
          <Button style={{ background: "#FF9017" , textTransform: "none" , color: "floralwhite" }} onClick={handleClose}>Cancel</Button>
          <Button style={{ background: check() ?  "#CFCFCF" : "#138DE8" , textTransform: "none" , color: check() ? "black" : "floralwhite" }}  disabled={check()} onClick={handleChange}>Confirm</Button>
        </DialogActions>
      </Dialog>}
      </BlockUi>
      {
          open3 && <FormDialog4 mail={props.mail}/>
      }
    </div>
  );
}