import { Button, makeStyles , TextField ,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles} from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from 'HWLibrary/HWInput';
import pointsApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import { useCallback } from 'react';
import BlockUi from 'react-block-ui';
import { DropDown } from 'views/Merchant/Merchant';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import { DropzoneDialog } from 'material-ui-dropzone';
import { People } from '@material-ui/icons';
import settingsApiService from './APIService';
import Typography from '@material-ui/core/Typography';
import { DataGrid , GridToolbar} from '@material-ui/data-grid';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import { useHistory } from 'react-router-dom';
import Edit from '@material-ui/icons/Edit';
const useStyles = makeStyles (theme => ({
    ...styles,
    saveButton :  { color: 'floralwhite', backgroundColor : '#138DE8' , margin: 2.5 },
    cancelButton : { color: 'floralwhite', backgroundColor : '#FF9017' , margin: 2.5},
    includeButton : {color: 'floralwhite', backgroundColor : '#138DE8' , height: 30 , marginTop: '13px' },
    RemoveButton : {color: 'floralwhite', backgroundColor : '#138DE8' , height: 30 ,  } , 
    card : { width : 700 }
}));

const StyledTableRow = withStyles ((theme) => ({ 
    root : { '&:nth-of-type(odd)' : { backgroundColor : theme.palette.action.hover } }, 
 })) (TableRow);

 const StyledTableCell = withStyles ((theme) => ({
    head : { 
        backgroundColor : theme.palette.common.black, 
        color : theme.palette.common.white 
    }, 
    body : { fontSize : 14 }
 })) (TableCell);



export function SearchUserDialog (props) {

    

    const classes = useStyles ();

    const [ selectedList, setSelectedList ] = useState ([]);
    const [ listLoading, setListLoading ] = useState (false);

    const [ rows, setRows ] = useState ([]);
    const [ page, setPage ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ rowCount, setRowCount ] = useState (0);
    const [ searchText, setSearchText ] = useState ('');
    const [ filterModel, setFilterModel ] = useState ('');
    const [ refreshState, setRefreshState ] = useState (false);
    const [ selectionModel, setSelectionModel ] = useState ([]);
    const [ ruleLoading, setRuleLoading ] = useState (false);
    
    // const rows2 = [
        
    //     { 'Id' : 'I1231231231312', 'Name' : 'Member Name', 'Email' : "asdasd@gmail.com" },
    // ];
   
    useEffect (() => {
                    console.log(props);
                  
                    setRows (props.MembersList);
                    setRowCount (props.MembersList.length);
                    // setRowCount (rows2.length);
                    // setRows (rows2);
                
    });

    const columns = [
        
        { field : 'id', headerName : 'Id', filterable : true, sortable : true, width : 200 },
        { field : 'name', headerName : 'Member Name', filterable : true, sortable : true, width : 200 },

        { field : 'emailAddress', headerName : 'Email', filterable : true, sortable : true, width : 200 } 
    ];

    const handleClose = event => {         props.setOpen(false)
    };


    const updateRole = (roleId , userIds) => { 
        if (roleId !== null) {
            let successStatus = null;
            setRuleLoading (true);
            settingsApiService.includeUsers (roleId , userIds) 
            .then (response => {  
                if (! response || ! response.data) { HWMessage.show ("Error while fetching response"); }
                // setCurrentRule (response.data);
               
                successStatus = true;
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);  
                if (successStatus === true)
                   { props.setOpen (false);
                    props.setRefreshState(prev=>!prev)

                    }
            });
        }
    }

    const handleNext = event => {
     //   props.setOpen(false)
        console.log(selectionModel);

        let selectedRows = rows.filter (i => selectionModel.indexOf(i.id) !== -1);

        var req = props.currentRequest;
        req.userIds = selectedRows;
        console.log("req", req);
        props.setCurrentRequest (req);
      //  history.push({ pathname : "voucher-reward", state : {  request : req , createMode : true }}); 

        //alert ( JSON.stringify (props.currentRequest));

        console.log(props.currentRequest, rows);
        updateRole(props.id , props.currentRequest.userIds.map(i=>i.id));
        props.open2(false);
    };

   // console.log("For selections", props, props.userType);

   const useDataGridStyles = makeStyles(theme => ({
    root: {
        '& .MuiDataGrid-colCellTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
        '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
        '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
        '& .MuiButton-label' : { color : '#138DE8' }
    }
  }));

const dataGridClasses = useDataGridStyles();

    return (
    <Dialog open = { props.open } onClose = { handleClose }  >
        <DialogTitle  > Select Members for the Role </DialogTitle>
        <DialogContent>  
            <DialogContentText> Select one or more users from the list for the Role. Please Press next button once done </DialogContentText>
            <GridContainer>
                <GridItem xs = {12} md = {12} sm = {12} >  
                <DataGrid 
                                        className = { dataGridClasses.root }
                                        checkboxSelection
                    loading = { listLoading }
                    page = { page }
                    pageSize = { pageSize }
                    rows = { rows }
                    rowCount = { rowCount }
                    columns = { columns }
                    pagination
                    paginationMode = 'server'
                    onPageChange = { changeParams => setPage (changeParams) }
                    onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams); } }

                    filterMode = 'server'
                    //onFilterModelChange = { params => { if (params.filterModel.items[0].value) setFilterModel(params.filterModel.items[0]); } }
                    density = 'compact'
                    autoHeight
                    rowsPerPageOptions ={ [25, 50, 100 ]}
                    onRowSelected = { gridParam => {
                        if (gridParam.isSelected === false) {  
                            console.log ("GHSDKSADSA", gridParam);
                            
                            props.setCurrentRequest (prev => {
                                prev.groupIds = prev.groupIds && prev.groupIds.filter (i => gridParam.data.id !== i);
                                return { ...prev };
                            })
                        }
                    } }
                    onSelectionChange = { newSelection => {  
                        console.log (newSelection);
                        setSelectionModel (newSelection.rowIds);     
                        props.setCurrentRequest(prev => { 
                            let groupIds = prev.groupIds || [];
                            for (var i = 0; i < newSelection.rowIds.length; i++)
                                if (groupIds.indexOf(newSelection.rowIds[i]) === -1) 
                                    groupIds.push (newSelection.rowIds[i]);
                            props.currentRequest.userIds = [ ...groupIds ];
                            console.log ("Prev", prev.groupIds);
                            console.log("New Selection Row IDS:" , newSelection.rowIds )
                        return {...prev};
                     });
                    }}
                    selectionModel = { selectionModel }
                
                />
                </GridItem>
            </GridContainer>
        </DialogContent>
        <DialogActions> 
            <center>
                <Button variant="contained" style = {{ background : '#FF9017', color : 'floralwhite', height : 40, width : 100 , textTransform:"None"}} disabled={(props && props.currentRequest && props.currentRequest.userIds && props.currentRequest.userIds.length > 0) ? false : true } variant = 'contained' onClick = { handleNext } > Include </Button>
            </center>
        </DialogActions>
    </Dialog>
    );
};




export default function RuleForm (props) {


    var settings=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    settings= [...obj["Settings"]];


    const history = useHistory ();

    const classes = useStyles();
    const [openSingle, setOpenSingle] = React.useState(false);

    const [ currentRule, setCurrentRule ] = useState ({});
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ productCategoryList, setProductCategoryList ] = useState ([]);
    const [ userList, setUserList ] = useState ([]);
    const [ userListCount, setUserListCount ] = useState (0);
    const { createMode, ruleId } = props;
    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});
    const [ saveOpen, setSaveOpen ] = useState (false);
    const [ createOpen, setCreateOpen ] = useState (false);
    const [ openGrid, setOpenGrid ] = useState (false);
    const [ MembersList, setMembersList ] = useState ([]);
    const [ membersListCount, setMembersListCount ] = useState (0);
    const [ edit , setEdit] = useState(false);

    
    

    const handleRemove = (userId , roleId) => {
        console.log(userId , roleId)
        if(props.createMode == false){
            setRuleLoading (true);
            settingsApiService.exludeUser(roleId  , userId)
            .then (response => {  
                if (! response || ! response.data) { return HWMessage.show ("Specific Permission be fetched"); }
                console.log(userId,userListCount,userList,userList.filter(i=>i.id !== userId));
                setUserList( userList.filter(i=>i.id !== userId));
                console.log(userId,userListCount,userList);
                setUserListCount(prev=>prev-1);
                 console.log(userId,userListCount,userList);
                 HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => setRuleLoading (false));
        }
    }

   



    useEffect (() => {
        console.log(props.rules.userList);
        if(props.createMode === false){
           
            setUserList (props && props.rules && props.rules.userList ); 
            setUserListCount(props && props.rules && props.rules.userList && props.rules.userList.length);
            HWMessage.show("Request Successful" , "success");
            }
            
       
    }, [])

    useEffect (() => {
        console.log(props);
        if(props.createMode === false){
            setRuleLoading (true);
            settingsApiService.fetchAllMembers()
            .then (response => {  
                if (! response || ! response.data) { return HWMessage.show ("Specific Permission cannot be fetched" , "error"); }
                let dummyUserList = [];

                var result = [];
                for (var i = 0; i < response.data.userList.length; i++) {
                    var matching = userList.filter (j => j.id === response.data.userList[i].id);
                    console.log (matching);
                    if (matching.length === 0) 
                        result.push (response.data.userList[i]);
                }           
                if(userList.length === response.data.userList.length)
                {
                   dummyUserList = [];
                }
                else
               { dummyUserList = result ; }
            
            setMembersList (dummyUserList); 
            setMembersListCount(dummyUserList.length);
            HWMessage.show("Request Successful", "success");
            })
            .catch (error => { return ;
        })
            .finally (() => setRuleLoading (false));
        }
       
    }, [userList , ])

    console.log(userList);

    const updateRule = useCallback (rule => {
        if (rule !== null) {
            let successStatus = null;
            setRuleLoading (true);
            settingsApiService.updateSpecificPermission (rule) 
            .then (response => {  
                if (! response || ! response.data) { HWMessage.show ("Error while fetching response","error"); }
                setCurrentRule (response.data);
               
                successStatus = true;
                HWMessage.show("Request Successful" , "success");
                history.goBack();
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);  
                if (successStatus === true)
                    props.setOpen (false);
            });
        }
    });
    const createRule = useCallback (rule => {
        if (rule !== null)  {
            let successStatus = null;
            setRuleLoading (true);
            settingsApiService.createSpecificPermission (rule)
            .then (response => {
                if (! response || ! response.data) { HWMessage.show ("Error while fetchinmg response" ,"error"); }
                setCurrentRule (response.data);
                props.setRefreshState (prev => !prev);
                successStatus = true;
                history.goBack();

                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);
                if (successStatus === true)
                    props.setOpen (false);
            });
        }
    });


    console.log(props);
    

    const captureChanges = event => { 
        const field = event.target.name;
        const value = event.target.value;

       setCurrentRule (prev => ({ ...prev, [field] : value }));
    };

    
    const handleSave = () => {
        if (props.createMode === true) { createRule (currentRule); } 
        else if (props.createMode === false) { updateRule (currentRule); }
    };
    const handleCancel = () => { props.setOpen (prev => false); };

    return (
        <>
                <Card className = { classes.card } >  
                    <CardHeader style={{ margin : 0 , padding : 0 , display: 'flex' , justifyContent: "space-around"}}>   <h3>  Employees  &nbsp; <a style={{cursor: "pointer"}}><Edit style={{color: "#138de8"}} onClick={e=>setEdit(!edit)} /></a>  </h3>  { settings.includes('Action') === true &&  edit === true && <Button onClick = { e=> setOpenGrid(true) } raised variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} > Include Users  </Button> }  </CardHeader>
    
                    {/* <div style={{ borderTop: "2px solid rgba(0,0,0,0.2) " , opacity : 0.1 }}></div> */}
                    <CardBody>  
                        <GridContainer>
                          
                        {/* { userListCount > 0 && userList.map((user, idx) => (

                           
                            <GridItem xs = {6} md = {12} sm = {6} style={{  display: 'flex' , justifyContent: "space-around" , margin: 5 }}>  
                          <People/>
                          <Typography variant="button" display="block" gutterBottom>
                          {user.name}
                           </Typography>
                             <Typography variant="caption" display="block" gutterBottom>
                             {user.emailAddress}
                                </Typography>

                        
                                
                            </GridItem>
                           
                        ))} */}
                        

                        <TableContainer component = {Paper} >
                     <Table size = "small" >  
                    <TableHead>
                   
                        <TableRow>
                          
                        <StyledTableCell align = "center" > Email </StyledTableCell>
                            <StyledTableCell align = "center" > Name </StyledTableCell>
                            <StyledTableCell align = "center" > Action </StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        { userListCount > 0 && userList.map((user, idx) => (
                             <StyledTableRow key = {idx} > 
                                 <StyledTableCell align = "center" > <span >  {user.emailAddress} </span> </StyledTableCell>
                                 <StyledTableCell align = "center" ><span>   {user.name} </span>  </StyledTableCell>

                                 <StyledTableCell align = "center" > 
                                   
{  settings.includes('Action') === true && ((props.role!=="Admin") || (props.role==="Admin" && userListCount>1))  && edit === true && <Button onClick = { e => handleRemove(user.id,props.rules.id ) } raised variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} > Remove   </Button> 
}
                                    
                                </StyledTableCell>
                             </StyledTableRow>   
                        )) 
                        }

                        {(userListCount === undefined || userListCount === 0 )  && 
                        <center> 
                           <GridItem xs = {12} md = {12} sm = {12} >  
                           <b>  <h3>No users attached to this role yet</h3>  </b>
                           </GridItem>
                           </center>
                       }
                       
                    </TableBody>
                   
                </Table>
                      
                     
                </TableContainer>
                


                <GridItem xs = {12} sm = {12} md = {12} >
                                {  <center>
                                    {/* <Button onClick = { e => props.createMode === true ? setCreateOpen (true) : setSaveOpen (true) } raised className = { classes.saveButton } > { props.createMode === true ? 'Save Role' : 'Update Role' } </Button> */}
                                    <Button onClick = { handleCancel } raised variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} > Cancel </Button>
                                    <ConfirmationDialog open = { createOpen } setOpen = { setCreateOpen } confirmingAction = { handleSave } dialogMessage = { 'Are you sure you want to create this role' } dialogTitle = { 'Confirm Rule Creation' } confirmingAction = { handleSave } />
                                    <ConfirmationDialog open = { saveOpen } setOpen = { setSaveOpen } confirmingAction = { handleSave } dialogMessage = { 'Are you sure want to update the role. ' } dialogTitle = { 'Confirm Rule Updation' } confirmingAction = { handleSave } />
                                </center>}
                            </GridItem>
            
                                
                            
                        </GridContainer>
                    </CardBody> 
                </Card>
                <SearchUserDialog 
                            open = { openGrid } 
                            open2 = { props.setOpen } 
                            id={props.rules.id}
                            setOpen = { setOpenGrid }
                            currentRequest = { currentRule }
                            setCurrentRequest = { setCurrentRule }
                            MembersList = {MembersList}
                           // MembersList = {userList}
                           roleName={props.roleName}
  
                            
                 

                    />

        </>
    );

};