import { AppBar, makeStyles, Tab, Tabs } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import AppNotification from "./AppNotification";
import Email from "./Email";
import SMS from "./SMS";
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'hidden',
        minHeight: 48,
        WebkitOverflowScrolling: 'touch', 
        display: 'flex',
        backgroundColor: "white",
        '& .MuiTabs-indicator' : { backgroundColor : '#FF9017' },
        "& .MuiButtonBase-root": {textTransform: "none"},
        color: "black",
        borderRadius: '6px'
      }
}));

export default function Communication (props) {



   
    if(HWLocalStorage.load("previousRolesSettingsTab")!== null){
        HWLocalStorage.clear("previousRolesSettingsTab")
    }
 
    const classes = useStyles ();
    const [ selectedTab, setSelectedTab ] = useState (JSON.parse(HWLocalStorage.load ("previousComSettingsTab")) || 0);;
    const changeTab = (event, idx) =>  { setSelectedTab (idx); }

    HWLocalStorage.save(0,"previousSettingsTab");

    return (
        <Fragment>
            <Tabs variant  = 'fullWidth' className = { classes.root } value = { selectedTab } onChange = { changeTab }  >
                <Tab label = "In App Notification" />
                <Tab label = "E-Mail" />
                <Tab label = "SMS" />
            </Tabs>
            {
                {
                    0 : <AppNotification />,
                    1 : <Email />,
                    2 : <SMS />

                } [selectedTab]
            }

        </Fragment>
    );
};

