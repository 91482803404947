import axios from 'axios';
import authService from 'HWLibrary/ApiService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import HWMessage from 'HWLibrary/HWMessage';
import appConfig from '../../HWLibrary/HWConfig';
class UserSegmentApiService {

  constructor() {
    if (! UserSegmentApiService.instance) { UserSegmentApiService.instance = this }
    return UserSegmentApiService.instance;
  };

  defaultAuthConfig = () => {
    const accessToken =  authService.getCachedAccessToken();
    return { headers: { Authorization: `Bearer ${accessToken}` } };
  };

  fetchUserSegments = () => {
    const url = `${ appConfig.serverBaseUrl }/members/segments`;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().get (url, config);
  };
  fetchUserSegment = (segmentId) => {
    const url = `${ appConfig.serverBaseUrl }/members/segments/${ segmentId }`;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().get (url, config);
  };
  fetchSegmentList = (searchText,paginationBookmark,fetchLimit) => {
    const url = `${ appConfig.serverBaseUrl }/members/segments`;
    const config = { ...this.defaultAuthConfig(), params: {searchText,paginationBookmark,fetchLimit} };
    return authService.axiosService().get (url, config);
  };
  fetchActiveSegmentList = (searchText) => {
    const url = `${ appConfig.serverBaseUrl }/members/active-segments`;
    const config = { ...this.defaultAuthConfig(), params: {searchText} };
    return authService.axiosService().get (url, config);
  };

  fetchSegmentMemberList = (query, includedMembers, excludedMembers, paginationBookmark, fetchLimit,usersUploadedByCSV,segmentParameter,segmentType,segmentId, firstSortId, lastSortId, pageDelta) => {

    const queryParams = { params : { fetchQueryList: JSON.stringify(query), includedMembers: JSON.stringify(includedMembers),  excludedMembers : JSON.stringify(excludedMembers), paginationBookmark, fetchLimit, usersUploadedByCSV : JSON.stringify(usersUploadedByCSV), segmentParameter:segmentParameter, segmentType : segmentType, segmentId : segmentId, firstSortId: firstSortId, lastSortId : lastSortId, pageDelta : pageDelta}}
    const url = `${ appConfig.serverBaseUrl }/members/query`;
    const config = { ...this.defaultAuthConfig(), ...queryParams };
    return authService.axiosService().get (url, config);
  };

  fetchSegmentMembers = (segmentId, paginationBookmark, fetchLimit, firstSortId, lastSortId, pageDelta) => {
    console.log("segmentId: ", segmentId, paginationBookmark, fetchLimit, firstSortId, lastSortId, pageDelta )
    const url = `${ appConfig.serverBaseUrl }/members/segments/${ segmentId }/users`;

    paginationBookmark = paginationBookmark || 0;
    fetchLimit = fetchLimit || 100;

    const config = { ...this.defaultAuthConfig(), params : { paginationBookmark, fetchLimit, firstSortId, lastSortId, pageDelta } };
    return authService.axiosService().get(url, config);
  };

  fetchAwardedVouchers = (request) => {
    const queryParams = request !== undefined ? {params: {voucherRequestId : request.voucherRequestId, fetchLimit : request.fetchLimit , paginationBookmark : request.paginationBookmark, audienceType : request.audienceType, segmentId : request.segmentId, userGroupIds : request.userGroupIds, firstSortId : request.firstSortId?request.firstSortId:null, lastSortId : request.lastSortId?request.lastSortId:null, pageDelta : request.pageDelta?request.pageDelta:null}} : {};
    const url = `${ appConfig.serverBaseUrl }/vouchers/vouchers-awarded`;
    const config = { ...this.defaultAuthConfig(), ...queryParams };
    return authService.axiosService().get (url, config);
  };

  fetchOtherMembers ({ query, userSegmentId, paginationBookmark, fetchLimit, searchText, filterModel, firstSortId, lastSortId, pageDelta }) {
    const url = `${ appConfig.serverBaseUrl }/members/query-exclude`;
    if(query!==null || query!== undefined){
      query = JSON.stringify(query);
    }
    const config = { ...this.defaultAuthConfig(), params : {  query , userSegmentId, paginationBookmark, fetchLimit, firstSortId, lastSortId, pageDelta } };
    return authService.axiosService().get (url, config);
  }

  fetchBikeModels = () => {
    const url = `${ appConfig.serverBaseUrl }/bike/models`;
    const config = { headers: { 'x-api-key': '4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45' }};
    return authService.axiosService().get(url, config);
  };

  
  updateUserSegment = (updatedSegment) => {
    if (updatedSegment === null ) { return HWMessage.show ("Segment need to be specified for updating"); }
    const request = { ...updatedSegment };
    const url = `${ appConfig.serverBaseUrl }/members/segments/${ updatedSegment.id }`
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().put (url, request, config);
  };
  createUserSegment = (createSegment) => {
    const request = { ...createSegment };
    const url = `${ appConfig.serverBaseUrl }/members/segments`;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().post (url, request, config);
  };

  reorderCampaigns = (campaignList) => {
    if (!campaignList || campaignList.length === 0) { return HWMessage.show ("Campaign List must be specified for this request"); }
    const request = { campaignList }
    const url = appConfig.serverBaseUrl + "/campaigns";
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().put (url, request, config);
  };

  fetchCampaign = (campaignId) => {
    if (! campaignId) { return HWMessage.show ("Campaign ID is invalid"); }
    const url = `${ appConfig.serverBaseUrl }/campaigns/${ campaignId }`;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().get (url, config);
  };

  deleteUserSegment = (segmentId) => {
    const url = `${ appConfig.serverBaseUrl }/user-segments/${ segmentId }`;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().delete (url, config);
};

  filterApply = (query) => {
    const queryParams = { params : query !== null ? JSON.stringify(query) : null };
    const url = `${ appConfig.serverBaseUrl }/members/query`;
    const config = { ...this.defaultAuthConfig(), ...queryParams };
    return authService.axiosService().get (url, config);
  };


  fetchS3PresignedUrl = (name) => {
    const request = {fileName : name}
    const url = `${ appConfig.serverBaseUrl }/members/segments/upload-csv`;
    const config = { ...this.defaultAuthConfig()  };
    return authService.axiosService().post (url, request , config);

  }

  uploadFile(fileToUpload, presignedUrl) {
    let config = { headers: { "content-type": fileToUpload.type } }
    return axios.put(presignedUrl, fileToUpload, config);
  }

  submitForm = (request) => {
    const url = `${ appConfig.serverBaseUrl }/members/segments/csvcreate`;
    const config = { ...this.defaultAuthConfig()  };
    return authService.axiosService().post (url, request , config);

  }

}

const userSegmentApiService = new UserSegmentApiService();
export default userSegmentApiService;