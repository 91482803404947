import { Button, Card, Dialog, Divider, List, ListItem, ListItemText, makeStyles, Paper, Typography } from '@material-ui/core';
import { VerifiedUser } from '@material-ui/icons';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useCallback, useEffect, useState } from 'react';
import { Fragment } from 'react';
import Voucher from './Voucher';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import QRcode from 'qrcode.react';
import VoucherRewards from '../../Vouchers/Rewards';
import voucherApiService from './ApiService';
import { ClipLoader, FadeLoader } from 'react-spinners';
import MuiAlert from '@material-ui/lab/Alert';
import HWMessage from 'HWLibrary/HWMessage';
import BlockUi from 'react-block-ui';

const useStyles = makeStyles (styles);

function AwardVouchers (props) {
    return (
        <Dialog open = {props.open}  maxWidth = 'md' scroll = "body" >
            <VoucherRewards memberId = {props.memberId} rewardType = 'single' dialogVariant = { props.dialogVariant } setDialogOpen = { props.setDialogOpen }  />
        </Dialog>
    );
};

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AvailableRewards (props) {
    var members=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    members= [...obj["Members"]];


    const classes = useStyles ();

 //   const [ voucherMember, setVoucherMember ] = useState ({});
 //   const [ type, setType ] = useState (props && props.type);
    const [ voucherList, setVoucherList ] = useState (props && props.voucherList);
    const [ dialogOpen, setDialogOpen ] = useState (false);
    const [ memberId, setMemberId ] = useState (props && props.memberId);

    const [ selectedVoucherModel, setSelectedVoucherModel ] = useState (null);
    const [ voucherLoading, setVoucherLoading ] = useState (false);
    const [ listLoading, setListLoading ] = useState (false);

    const [ voucherId, setVoucherId ] = useState(null);
    const [ voucherViewModel, setVoucherViewModel ] = useState (null);
    const [ coupons, setCoupons ] = useState ([]);
    const [ selectedVoucherCode, setSelectedVoucherCode ] = useState (null);

    const detailNameMap = { 
        'voucherCode' : 'Voucher Code', 
        'awardedOn' : 'Awarded Date', 
        'chassisNumber' : 'Chassis Number', 
        'expiryDate' : 'Expiry Date', 
        'voucherType' : 'Voucher Type', 
        'maxReduction' : 'Maximum Discount', 
        'minAmountRequired' : 'Minimum Transaction Value', 
        'status' : 'Status' , 
        'redeemedOn' : 'Redeemed On', 
        'validTill' : 'Expiry Date',
        'voucherCode' : 'Voucher Code',
        'engineNumber' : 'Engine Number',
        'modelName' : 'Bike Model Name',
        'plateNumber' : 'Plate Number',
        "rewardedDate" : 'Rewarded Date',
        'voucherValue' : 'Voucher Value',
        'percentDeduction' : 'Discount',
        'amountReductionCeiling' : 'Maximum Discount Value',
        'redeemedDate': 'Redeemed Date'
    };




    

    const getVoucherViewModel = (model) => { 

    console.log ("jksadkjasas",model, model.voucherValue);    
    return ({
        id : model.id,
        awardedOn : new Date(model.awardedOn).toLocaleDateString(),
        maxReduction : `RM ${model.maxReduction}`, 
        minAmountRequired : `RM ${model.minAmountRequired}`,
        status : model.status,
        expiryDate : new Date (Date.parse(model.expiryDate)).toLocaleDateString(), 
        redeemedOn : new Date(model.redeemedOn).toLocaleDateString(),
        redeemedDate : new Date (model.redeemedDate).toLocaleDateString(),
        validTill : new Date(model.validTill).toLocaleDateString(),
        description : model.description,
        voucherCode :  model.voucherCode,
        chassisNumber :  model.chassisNumber,
        engineNumber : model.engineNumber,
        modelName :  model.modelName,
        plateNumber : model.plateNumber,
        rewardedDate :  new Date(model.rewardedDate).toLocaleDateString(),
        voucherCategory : model.categoryName,
        voucherType : model.voucherType,
        voucherValue : `RM ${model.voucherValue}`,
        percentDeduction : `${model.percentDeduction} %`,
        amountReductionCeiling : `RM ${model.amountReductionCeiling}`,
        
    })};


    // const combineLists = (voucherList, couponList) => {

    //     var combine = [];
    //     console.log("Voucher and coupons", voucherList, couponList);
    //     if (couponList && couponList.length > 0)
    //         for (var i = 0; i < couponList.length; i++) { 
    //             if (couponList[i].status === type)
    //                 combine.push(couponList[i]); 
    //         }
    //     if (voucherList && voucherList.length > 0) 
    //         for (var j = 0; j < voucherList.length; j++) { 
    //             combine.push(voucherList[j]); 
    //         }
    //     console.log("Combine", combine);
    //     return combine;
    // };


    // useEffect (() => {
    //     if (memberId && type) {
    //         setListLoading(true);
    //         voucherApiService.fetch ({ memberId, type }) 
    //         .then (response => {
    //             if (!response || !response.data) { HWMessage.show("Cannot fetch data at the moment" , "error") }                
    //             setVoucherList (response.data.voucherList);
    //             HWMessage.show("Request Successful" , "success");
    //         }).catch(error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");        })
    //         .finally(() => { setListLoading(false); });
    //     }
    // }, [memberId, type]);

    // useEffect (() => {
    //     if (memberId) {
    //         setListLoading (true);
    //         voucherApiService.fetchCoupons({memberId})
    //         .then (response => {  
    //             if (!response || !response.data) { console.log("Response cannot be fetched at moment"); }
    //             HWMessage.show("Response connot be fetched at the moment" , "error");
    //             setCoupons (response.data.eServiceCoupons);
    //             HWMessage.show("Request Successful" , "success");
    //         })
    //         .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    //     })
    //         .finally (() => {  setListLoading(false); });
    //     }
    // }, [memberId]);

    // useEffect (() => {  setVoucherList (combineLists (voucherList, coupons));  }, [ coupons ]);
    
    useEffect (() => { 
        if (voucherId && selectedVoucherModel.chassisNumber === undefined) {
            setVoucherLoading (true);
            voucherApiService.fetchVoucherDetails (voucherId) 
            .then(response => {
                setVoucherLoading(true);
                if (!response || !response.data ) { return HWMessage.show("Response cannot be fetched at the moment","error") }
                setSelectedVoucherModel (response.data);
                console.log("Ressssssss", selectedVoucherModel, response.data, getVoucherViewModel(response.data));
                setVoucherViewModel (getVoucherViewModel({ ...response.data }));
                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => { setVoucherLoading(false) });
        }
    }, [voucherId]);

    useEffect (() => {
        if (selectedVoucherCode) {
            setVoucherLoading (true);
            voucherApiService.fetchCouponDetails (selectedVoucherCode) 
            .then (response => {  
                if (!response || !response.data) { HWMessage.show ("This coupon cannot be found","error"); return; };
                setSelectedVoucherModel (response.data);
                setVoucherViewModel (getVoucherViewModel (response.data));
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");        })
            .finally (() => { setVoucherLoading (false); })
        }
    }, [ selectedVoucherCode ]);

    useEffect(()=>{

    },[voucherList])

    
    return (
        <Fragment>
            <BlockUi blocking = { listLoading || voucherLoading } message = "Loading Voucher List"  >
            <GridContainer>
                <GridItem xs = {7} style = {{ minHeight : '300px' }} >  
                    
                    <Card className='card-header-body'>
                        <CardHeader icon>
                            <CardIcon color="primary" style = {{ marginTop : 0, background : '#FF9017' }} > <VerifiedUser /> </CardIcon>                      
                        </CardHeader>
                        <CardBody className='card-header-body'></CardBody>
                    </Card> 
                            <Card> 
                                <CardHeader>
                                    <GridContainer justify = "space-between" style = {{ marginTop : '10px' }} >  
                                        <GridItem xs = {6} >  <h4 className={classes.cardIconTitle} style = {{ marginTop : '5px' }} > Voucher List </h4> </GridItem>
{ members.includes("Action") == true && <GridItem xs = {6} > <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite' ,textTransform:'None' }} onClick = { () => setDialogOpen (true) } > Award More Vouchers </Button> </GridItem>
}                                    </GridContainer>
                                </CardHeader>
                                
                                <CardBody style = {{ minHeight : '300px' }} >
                                { listLoading && <center> <FadeLoader height = {20} margin = {20} radius = {20} width = {20}  /> </center> }
                                {   ! listLoading && <> 
                                    {
                                        voucherList  && voucherList.length > 0 &&
                                        <List style = {{ marginLeft : -25 }} >
                                            { voucherList.map ((voucher, idx) =>  <ListItem style = {{ marginLeft : '34px' }} key = { voucher.id } 
                                            onClick = { () => {  
                                                if (voucherList[idx].chassisNumber && voucherList[idx].chassisNumber !== null) { console.log ("Selecting", voucherList, voucherList[idx], idx); setVoucherId (voucherList[idx].id); setSelectedVoucherModel(voucher); setSelectedVoucherCode(voucherList[idx].voucherCode);   }
                                                else { setVoucherId(voucher.id); setSelectedVoucherModel (voucher); }
                                                } } >  
                                            <Voucher selected = { voucherId === voucher.id  } voucher = {voucher} />  </ListItem> )} 
                                        </List> 
                                    }
                                    </>
                                }
                                </CardBody>
                            </Card>
                            
                    </GridItem>
                

                { 
                    <GridItem xs = {5} > 
                        <Card className='card-header-body'>
                            <CardHeader icon>
                                <CardIcon color="primary" style = {{ marginTop : 0, background : '#138DE8' }} > <VerifiedUser /> </CardIcon>                      
                            </CardHeader>
                            <CardBody className='card-header-body'></CardBody>
                        </Card> 
                        <Card>
                            <h4 className={classes.cardIconTitle} style = {{ marginLeft : 10 }} > Voucher Details </h4>
                            <CardBody style = {{ minHeight : '100px' }} >
                                { console.log("tagging", selectedVoucherModel) }
                                { selectedVoucherModel === null && <Alert severity="warning">Select a voucher to show detail</Alert>  }
                                { selectedVoucherModel != null && voucherLoading && <center> <ClipLoader size = {45} /> </center> }
                                {   
                                    selectedVoucherModel && voucherViewModel &&
                                    selectedVoucherModel != null && !voucherLoading &&  
                                    <GridContainer  >
                                        <GridItem xs = {12} style = {{  marginLeft : 140, alignContent : 'center', justifyContent :  'center' }} > { selectedVoucherModel.status === 'Available' && <QRcode value = {selectedVoucherCode !== null ? selectedVoucherCode : selectedVoucherModel } />} </GridItem>
                                        <GridItem xs = {12} >  
                                            <List  dense >  
                                                { 
                                                Object.keys(voucherViewModel).map(detail => ( 
                                                  
                                                    
                                                    (selectedVoucherModel[detail] === 0 ? selectedVoucherModel[detail] + "" : selectedVoucherModel[detail])    && 
                                                    <>  
                                                    { voucherViewModel[detail] && detailNameMap[detail] && 
                                                        <>
                                                            <ListItem > 
                                                                <ListItemText primary = { detailNameMap[detail] } ></ListItemText> 
                                                                {voucherViewModel[detail]} 
                                                            </ListItem> 
                                                            <Divider /> 
                                                        </>
                                                        }
                                                    </> 
                                                    ) 
                                                ) 
                                                } 
                                            </List>
                                    </GridItem>
                                </GridContainer>
                            }
                            
                           </CardBody>     
                            
                        </Card>
                    </GridItem>
                }
            </GridContainer>
            <AwardVouchers memberId = { memberId } open = { dialogOpen } dialogVariant = { true } setDialogOpen = { setDialogOpen }  />
            </BlockUi>
        </Fragment>
    );
    
};