import { FormControlLabel, makeStyles, Switch, withStyles } from '@material-ui/core';
import HWMessage from 'HWLibrary/HWMessage';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import campaignApiService from './APIService';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Call } from '@material-ui/icons';
import merchantApiService from './APIService';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 47,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
      
    },
    '&$focusVisible $thumb': {
      color: '#ffffff',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 30,
    height: 24,
    backgroundColor: '#ffffff'
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: "#e72517",
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});


  const useStyles = makeStyles (theme => ({
    dangerColor : { color: '#EF516E' },
    safeColor : { color: '#51EF69' },
    labelRoot : { '& .MuiFormControlLabel-root' : {color : '#138DE8'} }
  }));

export default function CampaignSwitch (props) {
    const classes = useStyles();
    const [rulesLoading, setRulesLoading ] = useState(false);

    const Call = useCallback ((id,s) => {
        if (id != null) {
            setRulesLoading (true);
            merchantApiService.toggleMerchant (id , s) 
            .then (response => {
                if (!response || !response.data) { return HWMessage.show ("Error while updating the merchant"); }
              })
            .catch (error => { return HWMessage.show ("Error while updating the merchant"); })
            .finally (() => { setRulesLoading (false)  ; 
           });
        }
    });



    const handleCheck = e => {        
        var status = e.target.checked === true ? 'Active' : 'Inactive';
        console.log("hello",e.target.checked)
        props.onChange (props.idx, status);
        if(status === 'Active')
        {
         Call(props.rule,"active");
        }
        else{

         Call(props.rule,"inactive")
        }
    };

    return (
        <>
        <FormControlLabel
            control = { <IOSSwitch checked={ props.checked } onClick = { e => { console.log("clicked", e.target.name, e.target.value, e.target.checked); handleCheck (e); } } onChange= { e =>  handleCheck } name = 'check'  /> }
            className = { classes.labelRoot }
        />
        </>
    );
};  