import { Button, CardHeader, TextField, CardActions, Checkbox } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState, useEffect, Fragment, useRef } from 'react';
import FilterElement from './FilterElement';
import Card from 'components/Card/Card';
import { Link } from 'react-router-dom';
import { classes } from 'istanbul-lib-coverage';
import { makeStyles } from '@material-ui/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { IconButton,Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Redirect, useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import userSegmentApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import BlockUi from 'react-block-ui';
import memberApiService from 'views/Members/APIService';
import { HWInput } from 'HWLibrary/HWInput';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import StatusDialog from 'views/Utils/StatusDialog';

const useStyles = makeStyles (theme => ({
 // card : { borderRadius : 15 },
 // dialogBox: { minWidth : 1000 },
 // '& .MuiDialog-paper': { minWidth: 1500, width: 1000 },
 // '& .MuiDialogContent-root': { width: 1000 },
 // addButton : { color : 'floralwhite', background : '#138DE8', marginTop: 20, height : 40, width : 165 }
}));

export function SearchUserDialog (props) {
 const classes = useStyles ();
 const {mode} = props;

 const [ selectedList, setSelectedList ] = useState ([]);
 const [ listLoading, setListLoading ] = useState (false);

 const [ rows, setRows ] = useState ([]);
 const [ page, setPage ] = useState (0);
 const [ pageSize, setPageSize ] = useState (100);
 const [ rowCount, setRowCount ] = useState (0);
 const [ searchText, setSearchText ] = useState ('');
 const [ filterModel, setFilterModel ] = useState ('');
 const [ refreshState, setRefreshState ] = useState (false);
 // const [ selectionModel, setSelectionModel ] = useState ([]);
 const [ open3 , setOpen3] = useState(false);
 const [ open2 , setOpen2] = useState(false);
 const [ firstSortId, setFirstSortId] = useState();
 const [ lastSortId, setLastSortId ] = useState();
 const [ pageDelta, setPageDelta ] = useState(0);
 
 const [selectionModel, setSelectionModel] = useState([]);
 const prevSelectionModel = useRef(selectionModel);
 const [ selectionModelMap, setSelectionModelMap ] = useState ({});

 
 const handleExclusionInclusionClick = id => {
 var idx = selectionModel.indexOf (id);
 if (idx !== -1) {
 selectionModel.splice (idx, 1);
 setSelectionModel ([ ...selectionModel ]);
 var currentMap = selectionModelMap [ page ];
 var mapIdx = currentMap.indexOf(id);
 if (mapIdx !== -1) 
 currentMap.splice (mapIdx, 1);
 selectionModelMap [ page ] = currentMap;
 setSelectionModelMap ({ ...selectionModelMap });
 } else {
 selectionModel.push (id);
 setSelectionModel ([ ...selectionModel ]);
 
 var currentMap = selectionModelMap [ page ];
 currentMap.push (id);
 selectionModelMap [ page ] = currentMap;
 setSelectionModelMap ({ ...selectionModelMap });
 }
 };
 
 
 const columns = [
 { field : 'membershipNumber', headerName : 'Membership Number', filterable : true, sortable : true, flex: 1 },
 { field : 'emailAddress', headerName : 'Email',filterable : true, sortable : true, flex: 1, renderCell : cellParams => { return cellParams.row.email !== null ? cellParams.row.email : cellParams.row.emailAddress } },
 { field : 'tier', headerName : 'Tier',filterable : true, sortable : true, flex: 1, renderCell: cellParams => { return cellParams.row.tier ? cellParams.row.tier.tierName : null }},
 { field : 'gender', headerName : 'Gender',filterable : true, sortable : true, flex: 1 },
 { field : 'action', headerName : 'Action', renderCell : cellParams => selectionModel && <Checkbox style = {{ color : '#138DE8' }} onClick = { e => handleExclusionInclusionClick (cellParams.row.id) } checked = { selectionModel.indexOf (cellParams.row.id) !== -1 } /> }
 ];

 const history = useHistory();
 const handleClose = event => { props.setOpen(prev => !prev); };

 const handleClick = e => {
 if (props && props.mode === 'exclude') {
 var exlcudedList = [];
 Object.values (selectionModelMap).forEach (i => exlcudedList.push (...i));
 props.setUserSegment (prev => {
 if(prev && prev.excludedMembers)
 {
 prev.excludedMembers = [ ...prev.excludedMembers , ...exlcudedList ];

 } 
 else{
 prev.excludedMembers = [ ...exlcudedList ];

 }
 return { ...prev };
 });
 } else if (props && props.mode === 'include') {
 var includedList = [];
 Object.values (selectionModelMap).forEach (i => includedList.push(...i));
 props.setUserSegment (prev => { 
 if(prev && prev.includedMembers)
 {
 prev.includedMembers = [ ...prev.includedMembers , ...includedList ];

 } 
 else{
 prev.includedMembers = [ ...includedList ];
 }
 return { ...prev };
 });
 }
 props.applyFilter (props.userSegment.fetchQuery, props.userSegment.includedMembers, props.userSegment.excludedMembers,props.userSegment.usersUploadedByCSV,props.userSegment.segmentParameter);
 props.setOpen (false);
 };

 useEffect (() => {
 if (props && props.mode === 'exclude') {
 setListLoading (true);
 var paginationBookmark = (page) * pageSize;
 var fetchLimit = pageSize;
 userSegmentApiService.fetchSegmentMemberList (props.userSegment.fetchQuery, props.userSegment.includedMembers, props.userSegment.excludedMembers, paginationBookmark, fetchLimit,props.userSegment.usersUploadedByCSV,props.userSegment.segmentParameter,props.userSegment.segmentType,props.userSegment.id, firstSortId, lastSortId, pageDelta )
 .then (response => { 
 if (! response || ! response.data) { HWMessage.show ("List cannot be fetched at momeent",'error'); }
 setFirstSortId (response.data.firstSortId);
 setLastSortId (response.data.lastSortId);
 setRowCount (response.data.totalMembers);
 setRows (response.data.members);

 setSelectionModelMap ({ ...selectionModelMap, [page] : selectionModelMap[page] || [] });
 HWMessage.show("Request Successful" , "success");
 
 }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
 })
 .finally (() => { setListLoading (false); });
 } 
 }, [ page, pageSize, searchText ]);
 
 useEffect (() => {
 if (props && props.mode === 'include') {
 setListLoading (true);
 var paginationBookmark = (page) * pageSize;
 var fetchLimit = pageSize;
 var userSegmentId = props && props.userSegment.id;
 var query = props && props.userSegment && props.userSegment.fetchQuery;
 userSegmentApiService.fetchOtherMembers ({ query , userSegmentId, paginationBookmark, fetchLimit, searchText, filterModel, firstSortId, lastSortId, pageDelta })
 .then (response => { 
 if (! response || ! response.data) { HWMessage.show ("List cannot be fetched at momeent","error"); }
 setFirstSortId (response.data.firstSortId);
 setLastSortId (response.data.lastSortId);
 setRowCount (response.data.totalMembers);
 setRows (response.data.members);

 setSelectionModelMap ({ ...selectionModelMap, [page] : selectionModelMap[page] || [] });
 HWMessage.show("Request Successful" , "success");
 }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
 })
 .finally (() => { setListLoading (false);});
 }
 }, [ searchText, filterModel, page, pageSize ]);
 
 return (
 <Dialog open = { props.open } onClose = { handleClose } className = { classes.dialogBox } style = {{ minWidth: 1000 }} fullWidth = {true} maxWidth = { 'xl' } >
 <BlockUi blocking = { listLoading } message = { 'Please Wait.Fetching matching users' } >
 <DialogTitle style = {{ width : 1000 }} > Select Members of the segment </DialogTitle>
 <DialogContent style = {{ width : 1000 }} > 
 <DialogContentText > Select one or more users from the list </DialogContentText>
 <GridContainer>
 <GridItem xs = {12} md = {12} lg = {12} sm = {12} > 
 <DataGrid 
 loading = { listLoading }
 page = { page }
 pageSize = { pageSize }
 rows = { rows }
 rowCount = { rowCount }
 columns = { columns }
 fullWidth
 
 pagination
 paginationMode = 'server'
 onPageChange = { changeParams => {setPage (changeParams) ; setPageDelta (changeParams-page)}}
 onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams)} }

 filterMode = 'server'
 density = 'compact'
 autoHeight
 rowsPerPageOptions = { [ 25, 50, 100 ] }
 disableExtendRowFullWidth = { false }
 />
 </GridItem>
 </GridContainer>
 <GridContainer style = {{ justifyContent : 'center' }} >
 <GridItem >
 <center> <Button style = {{ background : '#138DE8', marginTop : 6, height : 38, width : 150, color : 'floralwhite' }} onClick = { handleClick } > {(props.mode === 'exclude') ? 'Exclude' : (props.mode === 'include') ? 'Include' : '' } </Button> </center>
 </GridItem>
 </GridContainer>
 </DialogContent>
 </BlockUi>
 </Dialog>
 );
};

export default function CreateUserSegment (props) {


 if (HWLocalStorage.load ("previousVouchersTab") !== null ) 
 HWLocalStorage.clear("previousVouchersTab");
if(HWLocalStorage.load ("previousVouchersRuleTab") !== null)
 HWLocalStorage.clear("previousVouchersRuleTab");
 if (HWLocalStorage.load ("previousCardTab") !== null ) 
 HWLocalStorage.clear("previousCardTab");
 if(HWLocalStorage.load("previousComSettingsTab")!== null){
 HWLocalStorage.clear("previousComSettingsTab")
 }
 if(HWLocalStorage.load("previousRolesSettingsTab")!== null){
 HWLocalStorage.clear("previousRolesSettingsTab")
 }

 if(HWLocalStorage.load("previousSettingsTab")!== null){
 HWLocalStorage.clear("previousSettingsTab")
 }
 if(HWLocalStorage.load("previousMemberTab")!== null){
 HWLocalStorage.clear("previousMemberTab")
 }
 if(HWLocalStorage.load("previousActionsTab")!== null){
 HWLocalStorage.clear("previousActionsTab")
 }
 if(HWLocalStorage.load("previousMerchantDetailsTab")!==null)
 {
 HWLocalStorage.clear("previousMerchantDetailsTab");
 }

 if(HWLocalStorage.load("previousMerchantTab")!==null)
 {
 HWLocalStorage.clear("previousMerchantTab");
 }
 if(HWLocalStorage.load("previousPointsRuleTab")!== null)
 {
 HWLocalStorage.clear("previousPointsRuleTab");
 }
 if(HWLocalStorage.load("previousPointsTab")!== null)
 {
 HWLocalStorage.clear("previousPointsTab");
 }
 if(HWLocalStorage.load("previousActionsTab")!== null)
 {
 HWLocalStorage.clear("previousPointsTab");
 }
 if(HWLocalStorage.load("previousPointsActionsTab")!== null)
 {
 HWLocalStorage.clear("previousPointsActionsTab");
 }
 if ((props && props.location && props.location.params && props.location.params.state && props.location.params.state.createMode) && (HWLocalStorage.load("segmentIdStore")!== null)) {
	HWLocalStorage.clear("segmentIdStore")
  } 

 if (props && props.location && props.location.params && props.location.params.state && props.location.params.state.segmentId) {
	var value = props && props.location && props.location.params && props.location.params.state && props.location.params.state.segmentId;
	HWLocalStorage.save(JSON.stringify(value), "segmentIdStore");
  } 

 var seg=[]
 var obj = JSON.parse(HWLocalStorage.load("Permissions"))
 seg= [...obj["UserSegments"]];
 const classes = useStyles ();

 const [ check, setCheck ] = useState (false);
 const [ userAttributeList, setAttributeList ] = useState ([ 'Name', 'Gender', 'Date of Birth', 'IC Number' ]);
 const [ valuesList, setValuesList ] = useState ( );
 const [ operatorList, setOperatorList ] = useState ([ 'is' , 'gte' , 'lte' , 'lt' , 'gt' , 'contains' , 'ne' ]);
 const [ matchingUserCounts, setMatchingUserCounts ] = useState (0);
 const [ loading, setLoading ] = useState (false);
 const [ segmentId, setSegmentId ] = useState (props && props.location && props.location.params && props.location.params && props.location.params.state && props.location.params.state.segmentId || JSON.parse(HWLocalStorage.load("segmentIdStore")));
 const [ userSegment, setUserSegment ] = useState ({});
 const [ updateLoading, setUpdateLoading ] = useState (false);
 const [ mode, setMode ] = useState (null);
 const [ saveOpen, setSaveOpen ] = useState (false);
 const [ open3 , setOpen3] = useState(false);
 const [ open2 , setOpen2] = useState(false);

 useEffect (() => {
 if (segmentId !== null) {
 setLoading (true);
 userSegmentApiService.fetchUserSegment (segmentId)
 .then (response => { 
 if (! response || ! response.data) { return HWMessage.show ("Unable to fetch user segment","error"); }
 setUserSegment (response.data);
 setMatchingUserCounts (response.data.memberCount);
 HWMessage.show("Request Successful" , "success");
 })
 .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
 })
 .finally (() => setLoading (false));
 }
 }, [ segmentId ]);

 const convertCase = value => {
 const result = value.replace(/([A-Z])/g, " $1");
 const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
 return finalResult;
 };

 const convertOperator = value => {
 switch (value) {
 case 'IsEqual' : return 'is';
 case 'IsNotEqual' : return 'is not';
 case 'Contains': return 'contains';
 case 'GreaterThanEqual' : return 'greater than';
 case 'LessThan' : return 'less than';
 case 'LessThanEqual' : return 'less than or equal';
 case 'GreaterThan' : return 'greater than';
 case 'NotContains' : return 'does not contain';
 }
 };

 function parseQuery(queryList){
 var queryElementsStrings = [];
 if (queryList != null && queryList.length != 0) {
 for (var i = 0; i < queryList.length; i++) {
 if (queryList[i] !== null && queryList[i].values && queryList[i].attribute) {
 
 var joinedValues = queryList[i].attribute === "birthday" ? queryList[i].values.join(' AND ') : queryList[i].values.join(' OR ');
 var joinedQuery = `${ convertCase(queryList[i].attribute === "state" ? "status" : queryList[i].attribute === "location" ? "state" : queryList[i].attribute ) } ${ queryList[i].attribute === "birthday" || queryList[i].attribute === "registrationDate" || queryList[i].attribute === "chassisNumber" ? "BETWEEN" : convertOperator(queryList[i].operator) } ${ joinedValues }`;
 queryElementsStrings.push (joinedQuery);
 }
 }
 var result = queryElementsStrings.join (' AND ');
 return result; 
 }
 };
 const addFilter = () => { 
 setUserSegment (prev => { 
 let queryList = prev.fetchQuery && prev.fetchQuery.length > 0 ? [ ...prev.fetchQuery ] : [];
 queryList.push ({ attribute: '', operator: '', values: [ ] });
 let newObj = { ...prev, fetchQuery: [ ...queryList ] };
 return newObj;
 });
 };
 const removeFilter = (idx) => {
 setUserSegment (prev => {
 let queryList = prev.fetchQuery && prev.fetchQuery.length > 0 ? [ ...prev.fetchQuery ] : [];
 queryList.splice (idx, 1);
 return { ...prev, fetchQuery: [ ...queryList ] };
 });
 };

 const applyFilter = (query, includedMembers, excludedMembers,usersUploadedByCSV,segmentParameter) => {
 setUpdateLoading (true);
 var paginationBookmark = 0;
 var fetchLimit = 0;
 userSegmentApiService.fetchSegmentMemberList (query, includedMembers, excludedMembers, paginationBookmark, fetchLimit,usersUploadedByCSV,segmentParameter,null,null)
 .then (response => { 
 if (!response || !response.data) {
 HWMessage.show ("Error while applying Filter","error");
 return console.log("Error while applying Filter");
 }
 HWMessage.show("Filter applied successfully","success");
 setMatchingUserCounts(response.data.totalMembers);
 })
 .catch (error => { 
 HWMessage.show ("Error while updating current Segment","error");
 return console.log("Error while updating current Segment");
 }).finally (() => setUpdateLoading (false));
 };

 const handleUsers = variant => { 
 setMode (prev => variant);
 setCheck (prev => true); 
 };
 const finalQuery = parseQuery (userSegment.fetchQuery);
 const history = useHistory();
 const handleSegmentSave = () => { 
 if (userSegment.name === null || userSegment.name === '') 
 return HWMessage.show ("Please Specify the segment name before saving","warning");

 var success = null;
 if (segmentId === null) {
 setLoading (true);
 userSegmentApiService.createUserSegment (userSegment) 
 .then (response => { 
 if (! response || ! response.data) { return HWMessage.show ("Error while fetching response","error"); }
 HWMessage.show ("Created Successfully","success");
 success = true;
 setOpen2(true);
 }).catch (error => { 
 success = false;
 HWMessage.show ("User Segment cannot be created","error");
 }).finally (() => { 
 setLoading (false);
 
 
 });
 } 
 else {
 setLoading (true);
 userSegmentApiService.updateUserSegment (userSegment) 
 .then (response => {
 if (! response || ! response.data) { return HWMessage.show ("Error while updating segment","error"); }
 success = true;
 setOpen3(true);

 HWMessage.show ("Updated Successfully","success");
 }).catch (error => { 
 HWMessage.show ("Error while updating segment","error") 
 success = false;
 })
 .finally (() => { setLoading(false); });
 }
 };

 const handleTextChanges = event => {
 setUserSegment (prev => { prev.name = event.target.value; return { ...prev } });
 };
 const handleCancel = event => { history.goBack (); };
 const changeValue = (idx, value) => { setUserSegment (prev => { prev.fetchQuery[idx].values = value; let t = { ...prev }; return t; }); }; 
 const changeAttribute = (idx, attribute) => { setUserSegment (prev => { prev.fetchQuery[idx].attribute = attribute; return { ...prev }; }); };
 const changeOperator = (idx, operator) => { setUserSegment (prev => { prev.fetchQuery[idx].operator = operator; return {...prev}; }); };
 const fetchCounts = () => { 
 var res = matchingUserCounts;
 if (userSegment) {
 if (userSegment.includedMembers) { res += userSegment.includedMembers.length; }
 if (userSegment.excludedMembers) { res -= userSegment.excludedMembers.length; }
 }
 return res;
 };
 return (
 <Fragment>
 <Card>
 <BlockUi blocking = { loading } message = { 'Loading Segment' } >
 <GridContainer> 
 
 <GridItem xs = {7} md = {7} lg = {7} >
 <GridItem xs = {12} md = {12} lg = {12} >
 <center><h2> { (userSegment === null) ? 'New Member Segment Creation' : userSegment.name } </h2> </center> 
 <GridContainer> 
 {
 userSegment.fetchQuery && userSegment.fetchQuery.length > 0 && userSegment.fetchQuery.map((filter, idx) => {
 return ( <GridItem xs = {12} md = {12} lg = {12} style = {{ marginTop : 10, borderRadius : 15 }} > 
 <FilterElement 
 attribute = { filter.attribute } 
 values = { filter.values } 
 operator = { filter.operator } 
 filterType = { filter.filterType }
 setAttribute = { (value) => changeAttribute(idx, value) }
 setOperator = { operator => changeOperator (idx, operator) }
 setValue = { value => changeValue (idx, value) }
 removeFilter = { () => removeFilter(idx) }
 operatorList = { operatorList }
 attributeList = { userAttributeList }
 valuesList = { valuesList }
 /> 
 </GridItem> )
 })
 }
 <GridItem xs = {12} md = {12} lg = {12} style={{display: 'flex', justifyContent : 'center' , margin : 10 }} > 
{ seg.includes("Action") ===true && <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }}onClick = { addFilter } > Add Filter </Button> 
} </GridItem>
 </GridContainer>
 </GridItem>

 <GridItem xs = {12} md = {12} lg = {12} style={{display: 'flex', justifyContent : 'center',marginTop:20 }} > 
 <center> 
{ seg.includes("Action") ===true && <Button variant='contained' style = {{ color : 'floralwhite', background : '#FF9017', height : 50, width: 260, fontSize: 19 , textTransform:'None' }} onClick = { e => applyFilter(userSegment.fetchQuery, userSegment.includedMembers, userSegment.excludedMembers,null,null) } > Apply Filter </Button> 
} </center>
 </GridItem>

 </GridItem>
 
 <GridItem xs = {5} md = {5} lg = {5} >

 <GridItem xs = {12} md = {12} lg = {12} >
 <GridContainer>
 <GridItem xs = {12} md = {12} lg = {12} style = {{ height : 120 ,marginBottom: 30 , paddingBottom: 30 }} >
 <Card className = { classes.card } >
 <CardBody> 
 <h4> User group rules </h4> 
 { finalQuery !=='' && finalQuery !== null && finalQuery }
 <div style={{display: "inline-block"}}></div> 
 </CardBody>
 </Card>
 
 </GridItem>
 <GridItem xs = {12} md = {12} lg = {12} style = {{ height : 210 , marginBottom: 5 }} >
 <Card className = { classes.card } >
 <CardBody> 
 <h4> User Counts </h4>
 <h2> Matching Users : { matchingUserCounts } </h2>
 <center>
{ seg.includes("Action") ===true && <Button style={{ margin:4 , color:"floralwhite" , backgroundColor:"#138DE8" , textTransform:'None'}} variant = 'contained' onClick = { e => { handleUsers('exclude'); } } > See Users </Button> 
} 
{ seg.includes("Action") ===true && <Button style={{ margin:4 , color:"floralwhite" , backgroundColor:"#FF9017", textTransform:'None'}} variant = 'contained' onClick = {e => { handleUsers('include'); } } > Include Users </Button> 
} </center> 
 </CardBody>
 </Card>
 </GridItem>

 <GridItem xs = {12} md = {12} lg = {12} >
 <Card className = { classes.card } >
 <CardBody> 
 <h4> Save Segment Details </h4>
 <div style = {{ marginTop : 22 }} >
 <HWInput 
 fullWidth 
 variant = 'outlined' 
 label = 'Segment Name' 
 name = 'segmentName' 
 onBlur = { e => { handleTextChanges(e); } } 
 value = { userSegment && userSegment.name } 
 required = {true}
 />
 </div>
 </CardBody>
 <CardActions style = {{ justifyContent : 'center' }} > 
 <Button style = {{ color : 'floralwhite', background : '#FF9017' , textTransform:'None'}} onClick={ e => setSaveOpen (true) } > { (segmentId === null) ? 'Create' : 'Update' } </Button>
 <Button style = {{ color : 'floralwhite', background : '#138DE8', textTransform:"none"}} onClick = { handleCancel } > Cancel </Button>
 <ConfirmationDialog open = { saveOpen } setOpen = { setSaveOpen } dialogTitle = { 'Confirm Saving Member Segment' } dialogMessage = { 'Are you sure you want to save this user segment ?' } confirmingAction = { handleSegmentSave } />
 <StatusDialog isLoading = {false} open= { open3 } setOpen={setOpen3} sucessStatus={"success"} confirmingAction={e=>history.goBack()} message={"Member Segment successfully updated."} dialogTitle={"Status"}/> 
 <StatusDialog isLoading = {false} open= { open2 } setOpen={setOpen2} sucessStatus={"success"} confirmingAction={e=>history.goBack()} message={"Member Segment successfully created."} dialogTitle={"Status"}/> 

 </CardActions>
 </Card>
 </GridItem>
 </GridContainer>
 </GridItem>

 </GridItem>
 
 </GridContainer>

 { check === true && <SearchUserDialog 
 open= {check} 
 setOpen={setCheck} 
 mode = { mode } 
 setUserSegment = { setUserSegment }
 userSegment = { userSegment }
 applyFilter = { applyFilter }
 matchingUserCounts = { matchingUserCounts }
 setMatchingUserCounts = { setMatchingUserCounts }
 />}
 
 </BlockUi>
 </Card>
 </Fragment>
 );
}; 