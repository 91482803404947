import React from "react"
import { Divider } from "@material-ui/core";
import "../../assets/css/StyleSheet.css"
import "./Merchant.css"
import { Row, Col } from "react-bootstrap";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

const TransactionCell = (props) => {

    let title = props.title ? props.title : ""
    let data = props.data ? props.data : ""
    let isHeaderCell = props.isHeaderCell ? props.isHeaderCell : false
   
    let transactionType =  props.transactionType ? props.transactionType : ""
    let header = transactionType == "DEBIT" ? "Points Deducted" : "Points Accrued"
    let amount =  props.amount ? props.amount : ""
    let headerCellClass = transactionType == "DEBIT" ? "header-cell-red-p" : "header-cell-green-p"  


    return (
        <>
            {!isHeaderCell && <div className="transaction-cell-div">
                <p className="sub-header-light"> {title}</p>
                <p className="content-a1" >{data}</p>
                <Divider />
            </div>}
            {isHeaderCell &&
                <GridContainer className="transaction-cell-div">
                    <GridItem xs = {6}>  <p  className = {headerCellClass}>{header}</p> </GridItem>
                    <GridItem xs = {6}>
                        <div style = {{float: "right"}}>  
                    {transactionType == "DEBIT" ? <RemoveIcon style = {{width: 60, height: 60, color: "#ff4500"}} /> : <AddIcon style = {{width: 60, height: 60, color: "#3FA340"}} />}
                    <p className = {headerCellClass} style = {{float: "right"}}>{amount}</p>
                    </div>
                     </GridItem>
                    <Divider />
                </GridContainer>}
        </>
    )
}

export default TransactionCell;
