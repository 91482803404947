import { Button, makeStyles , TextField } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from 'HWLibrary/HWInput';
import pointsApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import { useCallback } from 'react';
import BlockUi from 'react-block-ui';
import { DropDown } from 'views/Merchant/Merchant';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import { DropzoneDialog } from 'material-ui-dropzone';
import ColorPicker from "material-ui-color-picker";
import membershipCardApiService from './APIService';
import {v4 as uuidv4} from 'uuid';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import StatusDialog from 'views/Utils/StatusDialog';
import cloudfrontConfig from 'HWLibrary/HWCloudfrontConfig';
import { ContactlessOutlined } from '@material-ui/icons';
const useStyles = makeStyles (theme => ({
    ...styles,
    saveButton :  { color: 'floralwhite', backgroundColor : '#138DE8' , margin: 2.5 },
    cancelButton : { color: 'floralwhite', backgroundColor : '#FF9017' , margin: 2.5},
    card : { width : 700 },
    dropZone: { height: '100%', fullWidth: 'true', },
    previewContainer: { container: 'true', width: '100%', height: '100%', },
    preview: { width: '100%', height: '100%', item: 'true', xs: '12', },
    previewImg: { height: '100%', width: '100%', },
}));

export default function RuleForm (props) {

    var cards=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    cards= [...obj["Cards"]];

    console.log(props);
    const classes = useStyles();
    const [openSingle, setOpenSingle] = React.useState(false);
    const [openMultiple, setOpenMultiple] = React.useState(false);
    const [ uploadInProgress , setUploadInProgress ] = useState(false);
    const [ didFileUploadFail , setDidFileUploadFail ] = useState( false );
    const [ keyFileName ,setKeyFileName ] = useState("");
    const [ currentRule, setCurrentRule ] = useState ({});
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ productCategoryList, setProductCategoryList ] = useState ([]);
    const [ tierList, setTierList ] = useState ([]);
    const { createMode, ruleId } = props;
    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});
    const [ saveOpen, setSaveOpen ] = useState (false);
    const [ createOpen, setCreateOpen ] = useState (false);
    const [ fontColor , setFontColor] = useState("#000000");
    const [ open2, setOpen2 ] = useState (false);

    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    }

    const  uploadBulkFile = (file , fileName) => {

        setDidFileUploadFail (false);
        membershipCardApiService.fetchS3PresignedUrl(fileName).then(response => {
            setDidFileUploadFail (false);
            console.log(response.data.presignedUrl);

            setKeyFileName (response.data.keyName);     
            multibulkPartUpload(file, response.data.presignedUrl);

        }).catch((reason) => {
            setDidFileUploadFail (true);
            setUploadInProgress(true);
            setUploadInProgress(false);
        } )
}

  const multibulkPartUpload = (file, preSignedURL) => {
    membershipCardApiService.uploadFile(file, preSignedURL).then(response => {

        setDidFileUploadFail (false);
        setUploadInProgress(false);

    }).catch((reason) => {
        setDidFileUploadFail (false);
        setUploadInProgress(false);

    })
}

    const checkDisabled = () => {
        if(currentRule && currentRule.backgroundImage && currentRule.name && currentRule.name!=='' && currentRule.fontColor && currentRule.fontColor!==''  &&  currentRule.fontType && currentRule.fontType!=='')
        {
        return false;
        }
        else{
        return true;
        }
    }
 
    const isDisabled = false;

    const updateRule = useCallback (rule => {
        if (rule !== null) {
            let successStatus = null;
            setRuleLoading (true);
            membershipCardApiService.updateCard (rule) 
            .then (response => {  
                if (! response || ! response.data) { HWMessage.show ("Error while fetching response","error"); }
                setCurrentRule (response.data);
               
                successStatus = true;
                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show ("Rule could not be updated","error"); successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);  
                if (successStatus === true)
                    props.setOpen (false);
            });
        }
    });
    const createRule = useCallback (rule => {
        if (rule !== null)  {
            //rule.tierId= props.tierId;
            rule.fontSize = 32 ;
            rule.tierId =props.tierId;
                        let successStatus = null;
            setRuleLoading (true);
            membershipCardApiService.createCard (rule)
            .then (response => {
                if (! response || ! response.data) { HWMessage.show ("Error while fetchinmg response","error"); }
                setCurrentRule (response.data);
                props.setRefreshState (prev => !prev);
                successStatus = true;
                setOpen2(true);
                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show ("Error while creating rule","error"); successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);
                if (successStatus === true)
                    console.log('sa');
            });
        }
    });

    useEffect(() => { 
        setFontColor("#000000"); 
        setCurrentRule(prev => {  prev.fontColor = "#000000" ;  return { ...prev }; });
    }, []);

    const captureChanges = event => { 
        const field = event.target.name;
        const value = event.target.value;

       console.log(currentRule , field, value);

        setCurrentRule (prev => ({ ...prev, [field] : value })); 
    };
    const handleSave = () => {
        if (props.createMode === true) { createRule (currentRule); } 
        else if (props.createMode === false) { updateRule (currentRule); }
    };
    const handleCancel = () => { props.setOpen (prev => false); };

    return (
        <>
          <BlockUi blocking = { ruleLoading } message = { 'Creating' } >

                <Card className = { classes.card } >
                    <CardHeader style={{ margin : 0 , padding : 0}}> <center> <h3>  Add Card </h3>   </center>   </CardHeader>
                    <CardBody>  
                        <GridContainer>
                        <GridItem xs = {6} md = {12} sm = {6} >  
                                        <center>
                                            <br/>
                                        <Button variant="contained" color="primary" onClick={() => setOpenSingle(true)}> Add Background Image                        </Button>
                            <DropzoneDialog 
                            dropzoneClass={classes.dropZone}
                            previewGridClasses={{
                               container: classes.previewContainer,
                               item: classes.preview,
                               image: classes.previewImg,
                            }}
                            clearOnUnmount={ false }
                            acceptedFiles={['image/*']}
                            cancelButtonText={"cancel"}
                            submitButtonText={"submit"}
                            maxFileSize={5000000}
                            dropzoneText = { "  Please upload card image "}
                            dropzoneParagraphClass = {"abc"}
                            open={openSingle}
                            filesLimit={1}
                            onClose={() => setOpenSingle(false)}
                            onSave={(bannerImageFile) => {
                            console.log('Banner Image File:', bannerImageFile);
                                setUploadInProgress(true);
                                let myuuid = uuidv4();
                                let file = bannerImageFile[0];
                                let fileName = file.name.replaceAll(' ','') ;
                                let fileNameUrl = fileName.substring(0, fileName.indexOf("."))
                                if (file != null) {
                                  let fileName = file.name.replaceAll(' ','');
                                  let fileExtension = fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1)
                                  uploadBulkFile(file , myuuid  + fileName.replaceAll(' ','') );
                                }
                                console.log("cloudfrontconfignew",cloudfrontConfig.cloudfrontBaseUrl);
                
                               setCurrentRule (prev => ({ ...prev, backgroundImage : `${cloudfrontConfig.cloudfrontBaseUrl}/card-images/${ myuuid + fileName.replaceAll(' ','') }`  })) ;
                            setOpenSingle(false);
                            }}
                            showPreviews={true}
                            showFileNamesInPreview={true}
                            fullWidth ={true}
                            
                        />

                            </center>

                          
                            
                            <GridItem xs = {6} md = {6} sm = {6} >  
                                            <br/>
                            </GridItem>
                            <HWInput label = { 'Card Name' }  
                                      
                                                    name = { 'name' } 
                                                     hasError = { currentRule && currentRule.name === '' ? true : false  } 
                                                    helperText = { currentRule && currentRule.name === '' ? "Please enter Card name" : "" }
                                                    onBlur = { captureChanges }
                                                    value={currentRule && currentRule.name}
                                                    required = {true}
                                                /> 

                            </GridItem>

                            <GridItem xs = {6} md = {12} sm = {6} > 
                            <ColorPicker
                                name="color"
                                defaultValue={"Font Color"}
                                onChange={(e) => {setFontColor(e); setCurrentRule(prev => {  prev.fontColor = fontColor ;  return { ...prev }; })}}
                                onBlur = { e => { setCurrentRule(prev => {  prev.fontColor = fontColor ;  return { ...prev }; });  } }
                                value={currentRule && currentRule.fontColor}
                                hasError = { currentRule && currentRule.fontColor === '' ? true : false  } 
                                helperText = { currentRule && currentRule.fontColor === '' ? "Please enter Font color" : "" }
                            />


                    </GridItem>
                     <GridItem xs = {6} md = {12} sm = {6} >  
                                            <br/>
                                            </GridItem>


                            

                                        {/*    <GridItem xs = {6} md = {6} sm = {6} > 
                                            <DropDown 
                                    labelCategory = "Font Size" 
                                    name = "fontsize"
                                   // values = { tierList && tierList.map (i => i.tierName) }
                                    minWidth = {300}
                                    values= {[28 , 30 , 32]}
                                    targetValue = { currentRule && currentRule.fontSize }
                                    onChange = { e => { setCurrentRule(prev => {  prev.fontSize = e.target.value;  return { ...prev }; });  } }
                                //    onBlur = { captureChanges }
                                    
                                    disabled = { isDisabled }
                                    hasError = { currentRule && currentRule.fontSize === '' ? true : false  } 
                                    helperText = { currentRule && currentRule.fontSize === '' ? "Please enter Font Size" : "" }
                                />   */}

                         {/* </GridItem> */}
                                            <GridItem xs = {6} md = {6} sm = {6} > 
                                            <DropDown 
                                    labelCategory = "Font Type" 
                                    name = "fonttype"
                                  //  values = { tierList && tierList.map (i => i.tierName) }
                                    minWidth = {300}
                                    values= {['Roboto' , 'Verdana' , 'Arial']}
                                    targetValue = { currentRule && currentRule.fontType }
                                    onChange = { e => { setCurrentRule(prev => {  prev.fontType = e.target.value;  return { ...prev }; });  } }
                                  //  onBlur = { captureChanges }
                                  hasError = { currentRule && currentRule.fontType === '' ? true : false  } 
                                  helperText = { currentRule && currentRule.fontType === '' ? "Please enter Font type" : "" }
                                    disabled = { isDisabled }
                                    
                                />  

                         </GridItem>
                         <GridItem xs = {6} md = {12} sm = {6} >  
                         <br/>
                         </GridItem>

                    

                    <GridItem xs = {6} md = {12} sm = {6} >  
                         <br/>
                          <br/> </GridItem>
                            <GridItem xs = {12} sm = {12} md = {12} >
                                {  <center>
{                      cards.includes("Action") === true  &&    <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} disabled={checkDisabled()} onClick = { e => props.createMode === true ? setCreateOpen (true) : setSaveOpen (true) } raised > {  'Create' } </Button>
}                                    <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} onClick = { handleCancel } raised  > Cancel </Button>
                                    <ConfirmationDialog open = { createOpen } setOpen = { setCreateOpen } confirmingAction = { handleSave } dialogMessage = { 'Are you sure you want to create this card' } dialogTitle = { 'Confirm card Creation' } confirmingAction = { handleSave } />
                                    <ConfirmationDialog open = { saveOpen } setOpen = { setSaveOpen } confirmingAction = { handleSave } dialogMessage = { 'Are you sure want to update the card.' } dialogTitle = { 'Confirm Rule Updation' } confirmingAction = { handleSave } />
                                    <StatusDialog isLoading = {false} open= { open2} setOpen={setOpen2}  sucessStatus={"success"} confirmingAction={e=>props.setOpen (false)} message={"Card successfully created."} dialogTitle={"Status"}/> 

                              
                                </center>}
                            </GridItem>
                        </GridContainer>
                    </CardBody> 
                </Card>
                </BlockUi>
        </>
    );

};