import { Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles, CardActions , Dialog, DialogActions, DialogContent, DialogTitle , IconButton , Divider} from '@material-ui/core';

import { People, PeopleAlt, TripOriginRounded } from '@material-ui/icons';
import GridContainer from 'components/Grid/GridContainer';
import React, { useState, useEffect, Fragment } from 'react';
import { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardFooter from 'components/Card/CardFooter';
import CardBody from 'components/Card/CardBody';
import { Button } from '@material-ui/core';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { HWInput } from 'HWLibrary/HWInput';
import HWMessage from 'HWLibrary/HWMessage';
import pointsRequestsApiService from './APIService';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import Edit from '@material-ui/icons/Edit';
import StatusDialog from 'views/Utils/StatusDialog';
import merchantApiService from 'views/Merchant/APIService';
import memberApiService from 'views/Members/APIService';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { DataGrid } from '@material-ui/data-grid';
import pointsApiService from 'views/Points/APIService';
import SearchBar from 'views/Merchant/SearchBar';

const useStylesHead = makeStyles (styles);


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    
}));

const helperTextStyles = makeStyles(theme => ({
    root: {
      margin: 4,
      '&$error': {
        color: 'white'
      }
    },
    error: {} //<--this is required to make it work
  }));


  export function SearchUserDialog (props) {

    const classes = useStyles ();

    const [ selectedList, setSelectedList ] = useState ([]);
    const [ listLoading, setListLoading ] = useState (false);
    const [ audienceList , setAudienceList] = useState(props && props.audienceList)
    const [ rows, setRows ] = useState ([]);
    const [ page, setPage ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ rowCount, setRowCount ] = useState (0);
    const [ searchText, setSearchText ] = useState ('');
    const [ refreshState, setRefreshState ] = useState (false);
    const [ firstSortId, setFirstSortId] = useState();
    const [ lastSortId, setLastSortId ] = useState();
    const [ pageDelta, setPageDelta ] = useState(0);
   
    useEffect (() => {
        var requestId        = null;
        var audienceType     = null;
        var audienceCategory = null;
        var segmentId        = null;
        var paginationBookmark = ((page) * pageSize);
        var fetchLimit = pageSize;
        if(props.locationParams && props.locationParams.pointsRequest){
            requestId        = props.locationParams && props.locationParams.pointsRequest && props.locationParams.pointsRequest.id;
            audienceType     = props.locationParams && props.locationParams.pointsRequest && props.locationParams.pointsRequest.requestAudience;
            audienceCategory = props.locationParams && props.locationParams.pointsRequest && props.locationParams.pointsRequest.requestCategory;
            segmentId        = props.locationParams && props.locationParams.pointsRequest && props.locationParams.pointsRequest.segmentId;}
            
        if(props.locationParams && props.locationParams.currentRequest){
            requestId        = props.locationParams && props.locationParams.currentRequest && props.locationParams.currentRequest.id;
            audienceType     = props.locationParams && props.locationParams.currentRequest && props.locationParams.currentRequest.requestAudience;
            audienceCategory = props.locationParams && props.locationParams.currentRequest && props.locationParams.currentRequest.requestCategory;
            segmentId        = props.locationParams && props.locationParams.currentRequest && props.locationParams.currentRequest.segmentId;}

        else{
            requestId = props.locationParams.requestId;
        }
            
            if(requestId === null || requestId === undefined)
        {
            requestId = props.locationParams && props.locationParams.requestId;
        }
        if(props.locationParams && props.locationParams.pointsRequest && props.locationParams.pointsRequest.requestCategory === "Merchant")
        {
            
           
            pointsApiService.fetchPointsRequestAwarded ({requestId, audienceType, audienceCategory, fetchLimit, paginationBookmark, segmentId , searchText})
            .then (response => {
                if (!response || !response.data)
                    {return HWMessage.show("No relevant data can be fetched","error");}
                    setRowCount (response.data.totalMembers|| 0); 
                    setRows(response.data.merchants||[]);
            })
            .catch (error => {
                setRowCount (props.currentRequest && props.currentRequest.groupIds && props.currentRequest.groupIds.length||0); 
                setRows(props.currentRequest && props.currentRequest.groupIds||[])
                 });
        }
        else if(props.locationParams && props.locationParams.currentRequest && props.locationParams.currentRequest.requestCategory === "Merchant")
            {
                
                pointsApiService.fetchPointsRequestAwarded ({requestId, audienceType, audienceCategory, fetchLimit, paginationBookmark, segmentId})
                .then (response => {
                    if (!response || !response.data)
                        {return HWMessage.show("No relevant data can be fetched","error");}
                        setRowCount (response.data.totalMembers|| 0); 
                          setRows(response.data.merchants||[]);
                })
                .catch (error => {
                    setRowCount (props.currentRequest && props.currentRequest.groupIds && props.currentRequest.groupIds.length||0); 
                    setRows(props.currentRequest && props.currentRequest.groupIds||[])                
                     });
            }
        else{
            pointsApiService.fetchPointsRequestAwarded ({requestId, audienceType, audienceCategory, fetchLimit, paginationBookmark, segmentId, firstSortId, lastSortId, pageDelta})
            .then (response => {
                setFirstSortId (response.data.firstSortId);
                setLastSortId (response.data.lastSortId);
                setRowCount (response.data.totalMembers||0); 
                setRows(response.data.members||response.data.merchants|| [])  
            })
            .catch (error => { 
                setRowCount (props.currentRequest && props.currentRequest.groupIds && props.currentRequest.groupIds.length); 
                setRows(props.currentRequest && props.currentRequest.groupIds && props.currentRequest.groupIds|| []) 
             })
            .finally(() => console.log("sadasd"));
        }
    }, [ page , pageSize   ]);

    if(props.currentRequest && props.currentRequest.requestCategory==="Merchant"||props.locationParams && props.locationParams.pointsRequest && props.locationParams.pointsRequest.requestCategory === "Merchant" )
    {
        var columns = [
           
            { field : 'entityName', headerName : 'Name', filterable : false, sortable : false, width : 200 },
            { field : 'code', headerName : 'Code', filterable : false, sortable : false, width : 200 },
            
            
        ];
    }
    else{
        var columns = [
            { field : 'name', headerName : 'Name', filterable : false, sortable : false, width : 200 },
            { field : 'membershipNumber', headerName : 'Membership Number', filterable : false, sortable : false, width : 200 },
             
        ];
    }
    

    const handleClose = event => { props.setOpen(prev => !prev); };


    const history = useHistory();
   

    if(props.locationParams.currentRequest){
    return (
    <Dialog open = { props.open } onClose = { handleClose }  >
        <DialogTitle  >  {props.locationParams && props.locationParams.currentRequest && props.locationParams.currentRequest.requestCategory === "Merchant" ? 'Selected Merchants' : "Selected Members"} </DialogTitle>

        <DialogContent>  
  
            <GridContainer>
                <GridItem xs = {12} md = {12} sm = {12} >  
                <DataGrid className={classes.root}
                    loading = { listLoading }
                    page = { page }
                    pageSize = { pageSize }
                    rows = { rows }
                    rowCount = { rowCount }
                    columns = { columns }
                    pagination
                    paginationMode = 'server'
                    onPageChange = { changeParams => {setPage (changeParams); setPageDelta (changeParams-page)}}
                    onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams);} }
                    filterMode = 'server'
                    density = 'compact'
                    autoHeight
                    rowsPerPageOptions ={ [25, 50, 100 ]}
                   
                
                />
                </GridItem>
            </GridContainer>
        </DialogContent>
        <DialogActions> 
        </DialogActions>
    </Dialog>
    );}
    else {
    return (
        <Dialog open = { props.open } onClose = { handleClose }  >
            <DialogTitle  >  {props.locationParams && props.locationParams.pointsRequest && props.locationParams.pointsRequest.requestCategory === "Merchant" ? 'Selected Merchants' : "Selected Members"} </DialogTitle>
            <DialogContent>  
            <GridItem>  
                    <SearchBar 
                        width = '250px' 
                        handleSearch = { setSearchText }
                        handleRefresh = { setRefreshState }
                        refreshState = { refreshState }
                        placeholder = "Search on all details"
                    /> 
                    <br/>
                </GridItem>
                <GridContainer>
                    <GridItem xs = {12} md = {12} sm = {12} >  
                    <DataGrid className={classes.root}
                        loading = { listLoading }
                        page = { page }
                        pageSize = { pageSize }
                        rows = { rows }
                        rowCount = { rowCount }
                        columns = { columns }
                        pagination
                        paginationMode = 'server'
                        onPageChange = { changeParams => {setPage (changeParams); setPageDelta (changeParams-page)} }
                        onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams); } }
                        filterMode = 'server'
                        density = 'compact'
                        autoHeight
                        rowsPerPageOptions ={ [25, 50, 100 ]}
                       
                    
                    />
                    </GridItem>
                </GridContainer>
            </DialogContent>
            <DialogActions> 
            </DialogActions>
        </Dialog>
        );}
};



export default function RewardPoints (props) {

    var action = [];
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    action= [...obj["Actions"]]
    const classes = useStyles ();
    
    const getFromLocation = () => {
        if (props && props.location && props.location && props.location.state) {
            return props.location.state;
        } else return {};
    };
    let locationParams = getFromLocation();
    
    const { memberList, merchantList, userType,  } = locationParams;
    const incomingRequest = props && props.location && props.location.params && props.location.params.state && props.location.params.state.pointsRequest;

    const [ currentRequest, setCurrentRequest ] = useState (locationParams && locationParams.currentRequest !== null ? locationParams.currentRequest : {});

    const getSelectedList = () => {
        // if(currentRequest.requestCategory === "Merchant")
        // {
            
        // }
        // else{

        // }
        return (currentRequest && currentRequest.groupIds && currentRequest.groupIds.length > 0   ? currentRequest.groupIds : [] );
    };



    const [ pointsRequest, setPointsRequest ] = useState ({ });
    const [ requestLoading, setRequestLoading ] = useState (false);
    const [ creatorMode, setCreatorMode ] = useState ( locationParams && (locationParams.creatorMode !== undefined ? locationParams.creatorMode : locationParams.createMode) );
    const [ previous, setPreviousPage ] = useState (locationParams && locationParams.prev);
    const [ requestId, setRequestId ] = useState (creatorMode === true ? null : locationParams && locationParams.requestId);
    const [ statusOpen, setStatusOpen ] = useState (false);
    const [ listOpen, setListOpen ] = useState (false);
    const [ audienceList, setAudienceList ] = useState ([]);
    const [ rewardConfirmationOpen, setRewardConfirmationOpen ] = useState (false);
    const [ open3 , setOpen3] = useState(false);
    const [ open2 , setOpen2] = useState(false);
    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});
    const [ approveConfirmation, setApproveConfirmation ] = useState (false);
    const [ rejectConfirmation, setRejectConfirmation ] = useState (false);
    const [ updateConfirmation, setUpdateConfirmation ] = useState (false);
    const [edit, setEdit] = useState(false);
    const [totalMembersCount, setTotalMembersCount] = useState(0);
    const [totalMerchantCount, setTotalMerchantCount] = useState(0);
    const [check , setCheck ]  = useState(false);
    const headClasses = useStylesHead();
    const history = useHistory ();
    const [ firstSortId, setFirstSortId] = useState();
    const [ lastSortId, setLastSortId ] = useState();
    const [ pageDelta, setPageDelta ] = useState(0);


    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    };


    const handleBack = () => {  
        var objectToBeCached = null;
        if (currentRequest.approvalStatus === 'Pending') { objectToBeCached = {index : 0, title : 'REQUESTS PENDING', type : 'Pending' } }
        else if (currentRequest.approvalStatus === 'Approved') { objectToBeCached = { index : 1, title : 'REQUESTS APPROVED', type : 'Approved' } }
        else if (currentRequest.approvalStatus === 'Rejected') { objectToBeCached = { index : 2, title : 'REQUESTS REJECTED', type : 'Rejected' } }

        HWLocalStorage.save (objectToBeCached, "pointActionDataCategory");
        history.goBack(); 
    }


    const raiseRequest = useCallback (request => { 
        if (! request) { HWMessage.show ("Request is invalid","error"); }

        var sendRequest = { ...request };
        if (currentRequest && currentRequest.requestAudience === 'Group')
           sendRequest.groupIds = request.groupIds.map (i => i.id);
        if (currentRequest && currentRequest.requestAudience === 'Segment' )
            sendRequest.segmentId = currentRequest.segmentId;
        
        setRequestLoading (true);
        pointsRequestsApiService.createRequest (sendRequest)
        .then (response => {
            if (! response || ! response.data) { return HWMessage.show ("Error while raising request","error"); }
            setOpen2(true);
            HWMessage.show ("Request raised successfully","success");

        }).catch (error => { HWMessage.show ("Error while creating the request","error") })
        .finally (() => setRequestLoading (false));
    }, []);

    const updateRequest = useCallback ((request, action) => { 
        if (! request) { HWMessage.show ("Invalid Request Encountered","error"); }
        setRequestLoading (true);

        let sendRequest = {...request};
        sendRequest.approvalStatus = action;
        if (action) sendRequest.requestStatus = action;
        pointsRequestsApiService.updateRequest (sendRequest)
        .then (response => {  
            if (! response || ! response.data) { return HWMessage.show ("Error while raising request","error"); }
            setOpen3(true);
            HWMessage.show ("Updated Successfully","success");
        }).catch (error => { 
            HWMessage.show((error && error.response && error.response.data && error.response.data.message ||  "Error while updating request"), "error"); 
        })
        .finally (() => setRequestLoading (false));
    }, []);

    useEffect(() => {
          if(creatorMode  === false)
            if(locationParams && locationParams.pointsRequest){
            HWLocalStorage.save(locationParams.pointsRequest.id, 'RequestId');
            }
            if(locationParams && locationParams.currentRequest){
                HWLocalStorage.save(locationParams.currentRequest.id, 'RequestId');
            }
    }, [])

    useEffect (() => {
        if (creatorMode  === false){
            setRequestLoading (true);
            pointsRequestsApiService.fetchRequest (HWLocalStorage.load('RequestId'))
            .then (response => { 
                if (! response || ! response.data) { return HWMessage.show ("Data cannot be fetched at moement","error"); }
                setCurrentRequest (response.data);     
                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => setRequestLoading (false));
        }
        if (creatorMode ===false &&  incomingRequest && incomingRequest !== null) {
            setRequestLoading (true);
            pointsRequestsApiService.fetchRequest (incomingRequest.id)
            .then (response => { 
                if (! response || ! response.data) { return HWMessage.show ("Data cannot be fetched at moement","error"); }
                setCurrentRequest (response.data);     
                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => setRequestLoading (false));
        }
    }, [ ]);


    useEffect (() => {
        if(locationParams && locationParams.currentRequest && locationParams.currentRequest.requestCategory === "Merchant")
        {
            merchantApiService.fetchActiveMerchants ()
            .then (response => {
                if (!response || !response.data)
                    {return HWMessage.show("No relevant data can be fetched","error");}
                   
                      setAudienceList(response.data.merchantsList);
                      setTotalMerchantCount (response.data.merchantsCount);
            })
            .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error"); });
        }
        else{
            memberApiService.fetchActiveMembers ({})
            .then (response => {
                if (!response || !response.data)
                    return console.log("No relevant data can be fetched");
                      
                   setAudienceList(response.data.members)
                   setTotalMembersCount (response.data.totalMembers); 
                
            })
            .catch (error => { console.log("Error while fetching data", error); })
            .finally(() => console.log("sadasd"));
        }
       
    }, [ ]);  


    useEffect (() => {
        if (locationParams.creatorMode === true ) {

            setRequestLoading (true);
            pointsRequestsApiService.fetchRequest (locationParams.requestId)
            .then (response => { 
                if (! response || ! response.data) { return HWMessage.show ("Data cannot be fetched at moement"); }
                setCurrentRequest (response.data);     
                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show ("Error while fetching the data"); })
            .finally (() => setRequestLoading (false));
        }
    }, [ ]);

    const handleReward = () => { 
        setStatusOpen (true); 
        let fieldList = [ 'pointsPerUser', 'description' ];
        let isValid = true;
        for (var i = 0; i < fieldList.length; i++) {
            isValid = isValid && validateValues (fieldList[i], currentRequest[fieldList[i]]);
            if (isValid === false) 
                return HWMessage.show ("Errors in some field","error");
        }
        raiseRequest (currentRequest);
    };

    const handleResolve = (request, action) => {
        updateRequest (request, action); 
    };

    const handleCancel = event => {  
        history.push ({ pathname : '/ops/points' });
    };

    const validateValues = (field, value) => {
        switch (field) {
            case 'pointsPerUser' : 
                if (!value || value && value < 0 || value == null) {
                    setHasError ({ ...hasError, [ field ] : true });
                    setErrorMessage ({ ...errorMessage, [ field ] : 'Invalid value or No values specified' });
                    return false;
                }
            break;
            case 'description' : 
                if (!value || value === null ||  value === '') {
                    setHasError ({ ...hasError, [ field ] : true });
                    setErrorMessage ({ ...errorMessage, [ field ] : 'Invalid value or No values specified' });
                    return false;
                }
            break;
        }
        setHasError ({ ...hasError, [field] : false });
        setErrorMessage ({ ...errorMessage, [field] : null });
        return true;
    };

    const captureChanges = event => {
        const field = event.target.name;
        const value = event.target.value;


        validateValues (field, value);
        switch (field) {
            case 'description' : setCurrentRequest (prev => ({ ...prev, [ field ] : value }));break;
            case 'pointsPerUser' : setCurrentRequest (prev => ({ ...prev, [ field ] : parseInt(value) })); break;
            case 'rejectionReason' : setCurrentRequest(prev => ({ ...prev, [ field ] : value })); break; 
        }
    };

  //  const getStatus = currentRequest.approvalStatus;
    const getText = (type) => {
        if(type === "Accrue")
        return "Actual Points Awarded"
        else 
        return "Actual Points Deducted"
    }
    const getInfoText = () => {        
        if (currentRequest && currentRequest.requestCategory !== null && currentRequest.groupIds && currentRequest.groupIds != null ) {
            if (currentRequest.requestCategory === 'Merchant') { return currentRequest.requestAudience === 'All' ? `All Merchants Selected` : currentRequest.requestAudience === 'Segment'  ? `${ currentRequest.memberCount } Merchants Selected` :`${ currentRequest.groupIds.length } Merchants Selected`; }
            else if (currentRequest.requestCategory === 'Member')  {
                 return currentRequest.requestAudience === 'All' ? `${ totalMembersCount } Users Selected` : currentRequest.requestAudience === 'Segment'  ? `${ currentRequest.memberCount } Users Selected` : `${ currentRequest.groupIds.length } Users Selected`; }
            }  
         else {
            if (currentRequest && currentRequest.requestCategory && currentRequest.requestAudience!== undefined ) {
                return `${ currentRequest.requestAudience === 'Segment' ? currentRequest.memberCount : (currentRequest.requestAudience === 'All' ? 'All' : '') } ${ currentRequest.requestCategory } Selected`;
            } 
        }
    };

    return (
        <Fragment>
            <GridContainer>
                <GridItem xs = {12} md = {12} sm = {12} >  
                          <Card>  
                          <CardHeader className = { classes.cardHeader } color = 'rose' icon >
                        <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        <h4 className = { classes.cardIconTitle } > Audience Selection </h4>
                    </CardHeader>
{          props.createMode === true &&                  <h4 className = { classes.cardIconTitle } style = {{ marginLeft : 10 }} > Reward Points Summary </h4>
}         
{props.createMode === false &&   edit ===true && <h4 className = { classes.cardIconTitle } style = {{ marginLeft : 10 }} > Reward Points Summary  &nbsp; <a style={{cursor: "pointer"}}><Edit style={{color: "#138de8"}} onClick={e=>setEdit(!edit)} /></a> </h4>
}


                   <CardBody>
                        <GridContainer>
                            <GridItem xs = {12} md = {12} sm = {12} >
                          

                        {/* <GridItem xs = {12}>
                            <GridContainer>
                                <GridItem xs = {2} > 
                                 </GridItem>
                                <GridItem xs = {8}  > <h4 className = { headClasses.cardIconTitle } >
                                {currentRequest &&       currentRequest.requestType !=='Returning' &&  <center> <span  onClick = { e => { console.log(listOpen); setListOpen (!listOpen)}  } variant = 'contained' > { typeof(currentRequest && currentRequest.userCount) === 'undefined' ? getInfoText () :(currentRequest && currentRequest.userCount ) + " members Selected"  || getInfoText ()}  </span>  </center>}       
                                 {currentRequest &&  currentRequest.requestType ==='Returning' &&  <center> <span   onClick = { e => { console.log(listOpen); setListOpen (!listOpen)}  } variant = 'contained' > { "1 merchant Selected" }  </span>  </center>}                                     </h4> 

                                     </GridItem>
                            </GridContainer>
                        </GridItem> */}

                            <GridContainer> 
                        <GridItem xs = {12} > 
                            <GridContainer>
                                <GridItem xs = {12} >
                                    <h4 className = { headClasses.cardIconTitle } > Reward Points </h4>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs = {12}>
                            <GridContainer>
                                <GridItem xs = {2} > <IconButton onClick = { () => {
                                    if (props.dialogVariant)
                                        props.setDialogOpen (false);
                                    else 
                                        handleBack ()   
                                } } > <KeyboardBackspaceIcon /> </IconButton>
                                 </GridItem>
                                <GridItem xs = {8}  > <h4 className = { headClasses.cardIconTitle } >
                                     {/* {  requestDetail.requestType === 'Single' ? `Member Name : ${ membershipNumber }` : `See List of selected members : ${ userCount }` } 
                                     { requestDetail.requestType !== 'Single' && <IconButton  onClick = { (e) => setCheck (!check) } >  <KeyboardArrowDownIcon /> </IconButton> } */}
                            <span>See List of selected users : </span>
                            { currentRequest &&  currentRequest.requestType !=='Returning' ? (currentRequest && currentRequest.userCount === undefined ? getInfoText () :(currentRequest && currentRequest.groupIds &&  currentRequest.groupIds.length ||  currentRequest.userCount ) + " users Selected"  || getInfoText ()) : null }
                            { currentRequest &&  currentRequest.requestType ==='Returning'  ?  "1 merchant Selected" : null } 
                            <IconButton  onClick = { (e) => {setListOpen(!listOpen) ; setCheck(!check); } } >  <KeyboardArrowDownIcon /> </IconButton>
                          {/* {currentRequest &&  currentRequest.requestType !=='Returning' &&  <center> <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { e => setListOpen(!listOpen)} variant = 'contained' > { typeof(currentRequest && currentRequest.userCount) === 'undefined' ? getInfoText () :(currentRequest && currentRequest.userCount ) + " members Selected"  || getInfoText ()}  </Button>  </center>}       
                          {currentRequest &&  currentRequest.requestType ==='Returning' &&  <center> <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} onClick = { e => setListOpen(!listOpen)} variant = 'contained' > { "1 merchant Selected" }  </Button>  </center> }
                             */}
                             {/* { 
                                    listOpen && <List component = "div" disablePadding >
                                        {
                                            audienceList && audienceList.map (i => (<ListItem  >
                                                <ListItemIcon> <PeopleAlt /> </ListItemIcon>
                                                <ListItemText>  
                                                    <GridContainer justify = "space-between" >
                                                        <GridItem> { (currentRequest && currentRequest.requestCategory === "Member") ? i.code : i.membershipNumber } </GridItem>
                                                        <GridItem> { (currentRequest && currentRequest.requestCategory === 'Merchant') ? i.entityName : i.name } </GridItem>
                                                    </GridContainer>
                                                </ListItemText>
                                            </ListItem>))
                                        } 
                                    </List>
                                } */}
                         




                                     </h4> 

                                     </GridItem>
                            </GridContainer>
                                                        <Divider/>       

                        </GridItem>
                    </GridContainer>
                    
                            </GridItem >

                            <GridItem  xs = {12} sm = {12} md = {12} >   
                            </GridItem>
                            <br/>
                            <br/>
                        <GridItem xs = {12} md = {6} sm = {12} >  
                        {currentRequest && currentRequest.requestType !=="Returning" &&    <HWInput disabled={(currentRequest && currentRequest.requestType=== "Returning" || currentRequest && (currentRequest.approvalStatus === "Approved" || currentRequest.approvalStatus === "Rejected" )  )? true : false} type = 'number' value = {( currentRequest && currentRequest.pointsPerUser) } label = { 'Points Per User' }  name = 'pointsPerUser' onBlur = { captureChanges } helperText = { fetchErrorMessage('pointsPerUser') } hasError = { checkError ('pointsPerUser') } />}     
                        {currentRequest && currentRequest.requestType ==="Returning" &&    <HWInput disabled={(currentRequest && currentRequest.requestType=== "Returning" || currentRequest && (currentRequest.approvalStatus === "Approved" || currentRequest.approvalStatus === "Rejected" ) ) ? true : false} type = 'number' value = {( currentRequest && currentRequest.pointsReturned) } label = { 'Points Per User' }  name = 'pointsPerUser' onBlur = { captureChanges } helperText = { fetchErrorMessage('pointsPerUser') } hasError = { checkError ('pointsPerUser') } />} 
                         </GridItem>
                         <GridItem xs = {12} md = {6} sm = {12} > 
                        {currentRequest && currentRequest.requestType && currentRequest.requestType === "Returning" &&  <HWInput disabled={currentRequest &&  currentRequest.requestType=== "Returning" ? true : false} type = 'number' disabled value = {1 } label = { 'Total Users' } name = 'totalUsers'  />}
                        {currentRequest && currentRequest.requestType !=="Returning" && currentRequest.requestAudience!=='All' && <HWInput disabled={currentRequest && currentRequest.requestType=== "Returning" ? true : false} type = 'number' disabled value = { (currentRequest && currentRequest.groupIds &&  currentRequest.groupIds.length) || ( currentRequest && currentRequest.memberCount) || (currentRequest && currentRequest.userCount )  } label = { 'Total Users' } name = 'totalUsers'  />}
                         </GridItem>

                         <GridItem xs = {12} md = {6} sm = {12} >  
                         
                         {/* {currentRequest && currentRequest.approvalStatus !=="Pending" &&    <HWInput disabled={true} type = 'number' value = {( currentRequest && currentRequest.pointsPerUser) } label = { 'Points Per User' }  name = 'pointsPerUser' onBlur = { captureChanges } helperText = { fetchErrorMessage('pointsPerUser') } hasError = { checkError ('pointsPerUser') } />}      */}
                         {currentRequest && currentRequest.approvalStatus !=="Pending" && locationParams.createMode === false &&   <HWInput disabled={true}  value = {( currentRequest && currentRequest.resolverEmpName) } label = { 'Resolver Name' }  name = 'resolver Name'  />}     
                         {currentRequest && currentRequest.approvalStatus !=="Pending" &&  locationParams.createMode === false &&  <HWInput disabled={true}  value = {( currentRequest && new Date(currentRequest.resolvedDate).toLocaleDateString()) } label = { 'Resolved Date' }  name = 'resolved Date' />}     

                         </GridItem>

                        
                        <GridItem xs = {12} md = {12} sm = {12} > 
                        <HWInput required={true} disabled={(currentRequest && currentRequest.requestType=== "Returning") || (currentRequest && (currentRequest.approvalStatus === "Rejected" || currentRequest.approvalStatus === "Approved"))? true : false} type = 'text'  value = { currentRequest && currentRequest.description  } label = { 'Description' } name = 'description' onBlur = { captureChanges } helperText = { fetchErrorMessage ('description') } hasError = { checkError ('description') } /> 
                        </GridItem > 
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        {currentRequest && currentRequest.requestAudience   &&       <HWInput required={true} disabled={currentRequest &&  currentRequest.requestType=== "Returning" ? true : false} value = { currentRequest && currentRequest.requestAudience  } disabled  label = { 'Request Audience' } name = 'requestAudience'  /> } 
                        </GridItem > 
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        {currentRequest && currentRequest.pointsReturned &&  <HWInput  required={true} type = 'number'  disabled  value = { currentRequest && currentRequest.pointsReturned  } label = { 'Points Returned' } name = 'pointsReturned'  />                         }
                        {currentRequest  && currentRequest.requestType!=="Returning" && <HWInput required={true} type = 'number'  disabled  value = { (currentRequest && currentRequest.groupIds &&  currentRequest.groupIds.length * currentRequest.pointsPerUser) || ( currentRequest &&  currentRequest.initialUserCount && currentRequest.initialUserCount * currentRequest.pointsPerUser) || ( currentRequest && currentRequest.memberCount * currentRequest.pointsPerUser) || (currentRequest && currentRequest.userCount  * currentRequest.pointsPerUser)  ||( currentRequest.requestCategory !== 'Merchant' ?  currentRequest.pointsPerUser * totalMembersCount : currentRequest.pointsPerUser * totalMerchantCount) || 0 } label = { 'Total Points' } name = 'totalPoints'  /> }
                        </GridItem > 
                        <GridItem xs = {12} md = {6} sm = {12} >  
                        {currentRequest && currentRequest.refNumber &&  <HWInput required={true} type = 'number' disabled  value = { currentRequest && currentRequest.refNumber } label = { 'Reference No' } name = 'referenceNo'  />}
                        </GridItem>
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        {currentRequest && currentRequest.raisedByMerchantName && <HWInput required={true} type = 'text' disabled  value = { (currentRequest && currentRequest.raisedByMerchantName )  } label = { 'Raised By Merchant' } name = 'raisedByMerchantId'  />}
                        {currentRequest && currentRequest.raisedByEmpId && <HWInput required={true} type = 'text' disabled  value = { (currentRequest && currentRequest.raisedByEmpId )  } label = { 'Raised By Emp' } name = 'raisedByEmpId'  />}
                        </GridItem>
                        <GridItem xs = {12} md = {6} sm = {12} >  
                        {currentRequest && currentRequest.raisedDate &&  <HWInput required={true} disabled  value = { currentRequest && currentRequest.raisedDate &&  new Date(currentRequest.raisedDate).toLocaleDateString() } label = { 'Raised Date' } name = 'raisedDate'  />}
                         </GridItem>
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        {currentRequest && currentRequest.userSegmentId &&  <HWInput required={true} disabled  value = { currentRequest && currentRequest.userSegmentId   } label = { 'Segment ID' } name = 'userSegmentId'  />}
                        </GridItem>
                        <GridItem xs = {12} md = {6} sm = {12} >  
                        <HWInput required={true} disabled  value = { currentRequest && currentRequest.requestType } label = { 'Request Type' } name = 'requestType'  />
                        </GridItem>
                        <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.requestCategory } label = { 'Request Category' } name = 'requestCategory'  />
                        </GridItem>

                      {  locationParams &&  locationParams.creatorMode === false && currentRequest && currentRequest.approvalStatus === "Rejected" && <GridItem xs = {12} md = {6} sm = {12} > 
                        <HWInput disabled required={true}  value = { currentRequest && currentRequest.rejectionReason } label = { 'Reject Reason' } name = 'reason'  />
                        </GridItem>}
                        


                        
                        {locationParams &&  locationParams.creatorMode === false && currentRequest && currentRequest.rejectionReason && <GridItem xs = {12} md = {6} sm = {12} > 
                       { locationParams &&  locationParams.creatorMode === true && <HWInput disabled required={true} value = { currentRequest && currentRequest.rejectionReason } label = { 'Rejection Reason' } name = 'reason' 
                         onBlur = { captureChanges } 
                         />}
                        </GridItem>}

                      { creatorMode === false &&  currentRequest && currentRequest.approvalStatus === 'Pending'  &&  <GridItem xs = {12} md = {12} sm = {12} >  
                                <HWInput  onBlur = { captureChanges }  value = { currentRequest && currentRequest.rejectionReason } label = { 'Rejection Reason' } name = 'rejectionReason'  />
                                </GridItem>
                                }

                 
                     
                        </GridContainer>

                        {currentRequest && currentRequest.approvalStatus === 'Approved' && locationParams &&  locationParams.creatorMode === false &&
                    <Alert severity = { currentRequest.approvalStatus === 'Approved' ? 'success' : 'error' } >  
                        <AlertTitle> <h5> <b>  Request { currentRequest.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } </b> </h5>  </AlertTitle> 
                        <h4> { currentRequest.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } By : { currentRequest.resolverEmpName } </h4>
                        <h4> { currentRequest.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } At : { new Date(currentRequest.resolvedDate).toLocaleDateString() } </h4>
                         <h4> {getText(currentRequest.requestType)} : {currentRequest.totalPoints} </h4>
                        {currentRequest.approvalStatus === 'Rejected' && <h4> {  'Rejected' } Reason : { currentRequest.rejectionReason} </h4>}

                        
                    </Alert>
                }   

{currentRequest && currentRequest.approvalStatus === 'Rejected' && locationParams &&  locationParams.creatorMode === false &&
                    <Alert severity = { currentRequest.approvalStatus === 'Approved' ? 'success' : 'error' } >  
                        <AlertTitle> <h5> <b>  Request { currentRequest.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } </b> </h5>  </AlertTitle> 
                        <h4> { currentRequest.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } By : { currentRequest.resolverEmpName } </h4>
                        <h4> { currentRequest.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } At : { new Date(currentRequest.resolvedDate).toLocaleDateString() } </h4>
                        {currentRequest.approvalStatus === 'Rejected' && <h4> {  'Rejected' } Reason : { currentRequest.rejectionReason} </h4>}
                        
                    </Alert>
                }   


                    </CardBody>
                     <CardActions style = {{ justifyContent : 'center' }} color = "primary">
                    
                     <CardFooter className = { classes.cardFooter } >  
                        <GridContainer >
                         

                            { 
                                creatorMode === false &&  currentRequest && currentRequest.approvalStatus === 'Pending'  && <GridItem > 
                                    
                                    <center> 
                                { action.includes('Action')  === true &&  <Button raised variant = "outlined" style = {{ background : '#138DE8' , color: 'floralwhite' , margin: 2.5 , textTransform:'None' }} onClick = { e => setApproveConfirmation (true) } > Approve </Button>  }                                  
                                { action.includes('Action')  === true &&  <Button raised variant = "outlined" style = {{ background : '#FF9017' ,color: 'floralwhite' , margin: 2.5, textTransform:'None'}} onClick = { e => setRejectConfirmation(true) } > Reject </Button> }                                    
                                        <ConfirmationDialog open = { approveConfirmation } setOpen = { setApproveConfirmation } confirmingAction = { e => handleResolve (currentRequest, 'Approved')  } dialogMessage = { 'Are you sure you want to Approve the request' } dialogTitle = { 'Confirm Request Approval' } />
                                        <ConfirmationDialog open = { rejectConfirmation } setOpen = { setRejectConfirmation } confirmingAction = { e => handleResolve (currentRequest, 'Rejected')  } dialogMessage = { 'Are you sure you want to Reject the request' } dialogTitle = { 'Confirm Request Rejection' } />
                                        <StatusDialog isLoading = {false} open= { open3 } setOpen={setOpen3}  sucessStatus={"success"} confirmingAction={e=> history.push ({ pathname : '/ops/actions' })} message={"Request successful."} dialogTitle={"Status"}/> 
                                    <StatusDialog isLoading = {false} open= { open2 } setOpen={setOpen2}  sucessStatus={"success"} confirmingAction={e=> history.push ({ pathname : '/ops/actions' })} message={"Request successful."} dialogTitle={"Status"}/> 

                                    </center>
                                </GridItem> 
                            } 
                            
                            {
                              creatorMode === true && previous !== 'points' &&  !incomingRequest && <GridItem > 
                                    <center> 
{                     action.includes('Action')  === true &&  <Button raised variant = "outlined" style = {{ background : '#138DE8' , color: 'floralwhite' , margin: 2.5 , textTransform:'None'}} onClick = {  e => setRewardConfirmationOpen(true) } >  { currentRequest && currentRequest.requestType  }  </Button> 
}                                      
{   action.includes('Action')  === true &&  <Button raised variant = "outlined" style = {{ background : '#FF9017' ,color: 'floralwhite' , margin: 2.5, textTransform:'None'}} onClick = { handleCancel } > Cancel </Button>
}                                        <ConfirmationDialog open = { rewardConfirmationOpen } setOpen = { setRewardConfirmationOpen } confirmingAction = { handleReward } dialogMessage = { 'Are you sure you want to raise the request' } dialogTitle = { 'Confirm Request Raise' } />
                                <StatusDialog isLoading = {false} open= { open3 } setOpen={setOpen3}  sucessStatus={"success"} confirmingAction={e=> history.push ({ pathname : '/ops/actions' })} message={"Request successful."} dialogTitle={"Status"}/> 
                                <StatusDialog isLoading = {false} open= { open2 } setOpen={setOpen2}  sucessStatus={"success"} confirmingAction={e=> history.push ({ pathname : '/ops/actions' })} message={"Request successful."} dialogTitle={"Status"}/> 

                                    </center>
                                </GridItem>
                            }

                            
                            { creatorMode === true  && (currentRequest && currentRequest.approvalStatus === 'Pending') && <GridItem > 
                                {/* <GridItem xs = {12} md = {12} sm = {12} >  
                                <HWInput  onBlur = { captureChanges }  value = { currentRequest && currentRequest.rejectionReason } label = { 'Rejection Reason' } name = 'rejectionReason'  />
                                </GridItem>
                                <br/> */}
                                <center> 
                                    <Button raised variant = "outlined" style = {{ background : '#138DE8' , color: 'floralwhite' , margin: 2.5, textTransform: 'None' }} onClick = { e => setUpdateConfirmation (true) } > Update </Button> 
                                    <Button raised variant = "outlined" style = {{ background : '#FF9017' ,color: 'floralwhite' , margin: 2.5,  textTransform: 'None' }} onClick = { e =>  handleCancel (true) } > Cancel </Button>
                                    <ConfirmationDialog open = { updateConfirmation } setOpen = { setUpdateConfirmation } confirmingAction = { e => handleResolve (currentRequest, 'Pending')  } dialogMessage = { 'Are you sure you want to Update this request ?' } dialogTitle = { 'Confirm Updating Points Request' } />
                                </center>
                            </GridItem> 
                            } 

                        <SearchUserDialog 
                            open = { check } 
                            setOpen = { setCheck }
                            locationParams = {locationParams}
                            audienceList = {audienceList}
                            currentRequest = {currentRequest}
                    />


                  </GridContainer>
                    </CardFooter>
                    </CardActions>
                    </Card>  
                </GridItem>
            </GridContainer>
        </Fragment>
    );

};
