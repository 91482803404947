import { Button, makeStyles , TextField } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from 'HWLibrary/HWInput';
import pointsApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import { useCallback } from 'react';
import BlockUi from 'react-block-ui';
import { DropDown } from 'views/Merchant/Merchant';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import { DropzoneDialog } from 'material-ui-dropzone';
import settingsApiService from 'views/Settings/APIService';
import membershipCardApiService from './APIService';
import { jsPDF } from "jspdf";
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import StatusDialog from 'views/Utils/StatusDialog';
import {v4 as uuidv4} from 'uuid';
import cloudfrontConfig from 'HWLibrary/HWCloudfrontConfig';

const useStyles = makeStyles (theme => ({
    ...styles,
    saveButton :   { textTransform : 'none', color : 'floralwhite', background : '#138DE8', width : 300, height : 50, fontSize : 20},
    cancelButton : { textTransform : 'none', color : 'floralwhite', background : '#FF9017', width : 300, height : 50, fontSize : 20},
    card :         { width : 700 }
}));

export default function RuleForm (props) {

    var cards=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    cards= [...obj["Cards"]];

    const classes = useStyles();
    const [openSingle, setOpenSingle] = useState(false);
    const [physicalPrefix, setPhysicalPrefix] = React.useState(-1);
    const [ currentRule, setCurrentRule ] = useState ({createUsingCSV: false});
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ productCategoryList, setProductCategoryList ] = useState ([]);
    const [ tierList, setTierList ] = useState ([]);
    const { createMode, ruleId, usingCSV } = props;
    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});
    const [ saveOpen, setSaveOpen ] = useState (false);
    const [ createOpen, setCreateOpen ] = useState (false);
    const [ designsList, setDesignsList ] = useState ([]);
    const [ open2, setOpen2 ] = useState (false);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [ didFileUploadFail , setDidFileUploadFail ] = useState( false );
    const [ keyFileName ,setKeyFileName ] = useState("");

    const  uploadBulkFile = (file , fileName) => {
        setUploadInProgress(true);
        setDidFileUploadFail (false);
        membershipCardApiService.fetchS3PresignedUrl(fileName).then(response => {
            setDidFileUploadFail (false);
            console.log(response.data.presignedUrl);
    
            setKeyFileName (response.data.keyName);     
            multibulkPartUpload(file, response.data.presignedUrl);
    
        }).catch((reason) => {
            setDidFileUploadFail (true);
            setUploadInProgress(true);
            setUploadInProgress(false);
        } )
    }

    const multibulkPartUpload = (file, preSignedURL) => {
        membershipCardApiService.uploadFile(file, preSignedURL).then(response => {
    
        setDidFileUploadFail (false);
        setUploadInProgress(false);
    
            }).catch((reason) => {
        setDidFileUploadFail (false);
        setUploadInProgress(false);
    
    })
    }
    
    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    }

    const isDisabled = false;

    const updateRule = useCallback (rule => {
        if (rule !== null) {
            let successStatus = null;
            setRuleLoading (true);
            pointsApiService.updateCardGenRule (rule) 
            .then (response => {  
                if (! response || ! response.data) { HWMessage.show ("Error while fetching response","error"); }
                setCurrentRule (response.data);
                successStatus = true;
                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show ("Rule could not be updated","error"); successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);  
                if (successStatus === true)
                    props.setOpen (false);
            });
        }
    });



    useEffect (() => {
        setRuleLoading (true);
        membershipCardApiService.fetchCards ()
        .then (response => {  
            if (! response || ! response.data) { return HWMessage.show ("Cards Cannnot be fetched","error"); }
            setDesignsList (response.data); 
            HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        ; })
        .finally (() => setRuleLoading (false));
}, []);


    const checkDisabled = () =>{

        if((currentRule && ((currentRule.csvUrl && currentRule.csvUrl!==null)||(currentRule.cardCount && currentRule.cardCount!==''  || currentRule.cardCount > 1) &&  currentRule.physicalPrefix && currentRule.physicalPrefix!=="" && currentRule.physicalPrefix.length===2)) && currentRule.cardDesign && currentRule.cardDesign!=='' )
        {
        return false;
        }
        else{
        return true;
        }
    }


    const downloadExcel = useCallback((url,designURL) => {
        window.open(url);
        var link = document.createElement('a');
        link.href =  designURL;
        link.download = "Card Image";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // var pdf = new jsPDF();
        // var img = new Image;
        // img.onload = function() {
        //     try{
        //         pdf.addImage(this, 10, 10);

        //     }catch(err){
        //         HWMessage.show("Something went wrong.", "error");
        //     }
        //     pdf.save("test.pdf");
        // };
        // img.src = designURL;  
    });

    
    const createRule = useCallback (rule => {
        if (rule !== null)  {
            let successStatus = null;
            setRuleLoading (true);
            membershipCardApiService.createCardGenRule (rule)
            .then (response => {
                if (! response || ! response.data) { HWMessage.show ("Error while fetching response" , "error"); }
                setCurrentRule (response.data);
                props.setRefreshState (prev => !prev);
                successStatus = true;
                HWMessage.show("Request Successful" , "success");
                
                downloadExcel(response.data.documentUrl , rule.designURL);
                setOpen2(true);

    //         }).catch (error => { HWMessage.show ("Error while creating rule","error"); successStatus = false; })
    //         .finally (() => { 
    //             setRuleLoading (false);
    //             if (successStatus === true)
    //                console.log("as");
    //         });
    //     }
    // });
            }).catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
     })
        .finally (() => { setRuleLoading (false);
            if (successStatus === true)
                    console.log("as");
         });}
});

    

    const captureChanges = event => { 
        const field = event.target.name;
        const value = event.target.value;

        console.log(currentRule);
        
        switch(field){
            case 'cardCount' : setCurrentRule (prev => ({ ...prev, [field] : +value }));  break;
            default : setCurrentRule (prev => ({ ...prev, [field] : value }));  
        }
        


    };
    const handleSave = () => {
        if (props.createMode === true) { createRule (currentRule); } 
        else if (props.createMode === false) { updateRule (currentRule); }
    };
    const handleCancel = () => { props.setOpen (prev => false); };

    return (
        <>         <BlockUi blocking = { ruleLoading || (usingCSV?uploadInProgress:false)} message = { 'Creating' } >

                <Card className = { classes.card } >

                    <CardHeader style={{ margin : 0 , padding : 0}}> <center> <h3>  Card Number Generation </h3>   </center>   </CardHeader>
                    <CardBody>  
                        <GridContainer>
                        {!usingCSV && <GridItem xs = {6} md = {12} sm = {6} >  
                            <center>
                            <HWInput label = { 'Card Count' }  
                                                    name = { 'cardCount' } 
                                                     hasError = {(currentRule && currentRule.cardCount && +currentRule.cardCount < 1) || currentRule.cardCount=='' ? true : false} 
                                                    helperText = { (currentRule && currentRule.cardCount && +currentRule.cardCount < 1) || currentRule.cardCount=='' ? "Count should be greater than 0" : "" }
                                                    onBlur = { captureChanges }
                                                    value= {currentRule && currentRule.cardCount }
                                                    required = {true}
                                                /> 

                            </center>
                               
                            </GridItem>}

                        

                           
                           {!usingCSV && <GridItem xs = {6} md = {12} sm = {6}> 
                            <HWInput label = { 'Physical Number to start with ' } type="number" required = {true}  value = { currentRule && currentRule.physicalPrefix  } name = "PhysicalPrefix"  hasError={currentRule && currentRule.physicalPrefix && (currentRule.physicalPrefix.length!==2 || currentRule.physicalPrefix<=0 ) ? true : false} helperText={currentRule && currentRule.physicalPrefix && (currentRule.physicalPrefix.length!==2 || currentRule.physicalPrefix<=0 || currentRule.physicalPrefix=='' ) ? "Invalid Value" : "" } onBlur = { e => setCurrentRule(prev=>({...prev, physicalPrefix : e.target.value}) ) }  />  

                            </GridItem>}
                            <GridItem xs = {6} md = {12} sm = {6} >  
                             <br/>
                            </GridItem>

                            <GridItem xs = {6} md = {6} sm = {6} >  
                            <DropDown 
                              labelCategory = "Card Design" 
                              name = "cardDesign"
                            //  values = { tierList && tierList.map (i => i.tierName) }
                              minWidth = {300}
                              values= {designsList.map(i=>i.name)}
                              targetValue = { currentRule && currentRule.cardDesign }
                              onChange = { e => { setCurrentRule(prev => {  prev.cardDesign = e.target.value;prev.designId = designsList.filter (i => i.name === e.target.value)[0].id;prev.designURL=designsList.filter (i => i.name === e.target.value)[0].backgroundImage; return { ...prev }; });  } }
                              onBlur = { captureChanges }
                              helperText = { fetchErrorMessage ('carddesign') }
                              hasError = { checkError ('carddesign') }
                              disabled = { isDisabled }
                              required = {true} 
                                />  

<GridItem xs = {6} md = {12} sm = {6} >  
                             <br/>
                            </GridItem>
                                
                            </GridItem>
                            {usingCSV && <GridItem>
                                <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} 
                                // disabled={checkDisabled()} 
                                onClick={() => setOpenSingle(true)} raised  > {  'Upload CSV' }
                                </Button>
                            </GridItem>}
                               
                            <DropzoneDialog 
                                    clearOnUnmount={ false }
                                     initialFiles = {[]}
                                     acceptedFiles={[".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}
                                     cancelButtonText={"cancel"}
                                    submitButtonText={"submit"}
                                    maxFileSize={5000000}
                                    open={openSingle}
                                    filesLimit={1}
                                    onClose={() => setOpenSingle(false)}
                                    onDelete = {(deletedFile) =>   setCurrentRule (prev => ({ ...prev, csvUrl : ''  })) } 

                                    onSave={(bannerImageFile) => {                    
                                    console.log('Banner Image File:', bannerImageFile);
                           
                                        // setUploadInProgress(true);
                                        let myuuid = uuidv4();

                                        let file = bannerImageFile[0];
                                        let fileName = file.name ;
                                        let fileNameUrl = fileName.substring(0, fileName.indexOf("."))
                                        if (file != null) {
                                        let fileName = file.name
                                        let fileExtension = fileName.slice((Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1)
                                        uploadBulkFile(file , myuuid );
                                        }
                                        setCurrentRule (prev => ({ ...prev, csvUrl : `${cloudfrontConfig.cloudfrontBaseUrl}/card-block-excel/${ myuuid }`, physicalPrefix: '', createUsingCSV : true
                                    })) ;
                                        setOpenSingle(false);
                            }}
                           
                            fullWidth ={true} />
                            <GridItem xs = {12} sm = {12} md = {12} >
                                {<center>
                                        {cards.includes("Action") === true &&
                                        <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} disabled={checkDisabled() || (usingCSV?uploadInProgress:false)} onClick = { e => props.createMode === true ? setCreateOpen (true) : setSaveOpen (true) } raised  > {  'Create Block' } </Button>
                                        }                                    
                                        <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} onClick = { handleCancel } raised  > Cancel </Button>
                                
                                    <ConfirmationDialog open = { createOpen } setOpen = { setCreateOpen } confirmingAction = { handleSave } dialogMessage = { 'Are you sure you want to generate this block' } dialogTitle = { 'Confirm Card Generation' } confirmingAction = { handleSave } />
                                    <StatusDialog isLoading = {false} open= { open2} setOpen={setOpen2}  sucessStatus={"success"} confirmingAction={e=>props.setOpen (false)} message={"CardBlock successfully created."} dialogTitle={"Status"}/> 

                                </center>}
                            </GridItem>
                        </GridContainer>
                    </CardBody> 
                </Card>
                </BlockUi>
        </>
    );

};