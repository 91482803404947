import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import FormDialog2 from "../Members/ForgotPassword";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import HWMessage from "HWLibrary/HWMessage";
import authService from "HWLibrary/ApiService";
import { HWInput } from "HWLibrary/HWInput";
import { Link, useHistory } from "react-router-dom";
import { blue } from "@material-ui/core/colors";
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [ email, setEmail ] = useState ('');
  const [ password, setPassword ] = useState ('');
  const [ psd, setPsd ] = useState (false);
  const [ errorMessage, setErrorMessage ] = useState (null);
  const [ passwordErrorMessage, setPasswordErrorMessage ] = useState (null);
  const [ open , setOpen ] = useState (false);

  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  
  const history = useHistory();
  const handleLogin = event => { 
    HWMessage.show ("Logging In", "warning"); 
    authService.logInCall(email, password);
  };

  const checkValidEmail = (value) =>{
    if (value == null || value == '') {
      setErrorMessage ("Please enter email. Email cannot be empty");
      return false;
    }
    else if (value != null) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const valid = re.test(String(value).toLowerCase());

      if (valid === false) setErrorMessage("Invalid Email Input. Please Enter correct email");
      else if (valid === true) { setErrorMessage (null); }
      return valid;
    }
  };

  const checkValidPassword = (value) => {
    if (value === null || value === '') 
      setPasswordErrorMessage ("Please Enter Password. Password cannot be empty");
    else 
      setPasswordErrorMessage (null);
  };



  const handleClick = event => { 
   setPsd(!psd);
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                
                style = {{ backgroundColor : '#138DE8' }}
              >
                <h4 className={classes.cardTitle} style = {{ fontWeight : 500 }} > Log in</h4>
                
              </CardHeader>
              <CardBody>
                <HWInput
                  labelText="Email..."
                  id="email"
                  name = "email"
                  value = { email }
                  formControlProps={{ fullWidth: true }}
                  onBlur = { e => { checkValidEmail (e.target.value); setEmail (e.target.value); } }
                  inputProps={{ endAdornment: ( <InputAdornment position="end"> <Email style = {{ color : '#138DE8'  }} className={classes.inputAdornmentIcon} /> </InputAdornment> ) }}
                  placeholder = { 'Email Address' }
                  hasError = { errorMessage != null }
                  helperText = { errorMessage }
                />
                <HWInput
                  labelText="Password"
                  id="password"
                  name = "password"
                  value = { password }
                  onBlur = { e => { checkValidPassword (e.target.value); setPassword (e.target.value); } }
                  formControlProps={{ fullWidth: true }}
                  // inputProps={{ endAdornment: ( <InputAdornment position="end"> <Icon className={classes.inputAdornmentIcon}> lock_outline </Icon> </InputAdornment> ), type: "password", autoComplete: "off" }}
                  placeholder = { 'Password' }
                  inputProps={{ endAdornment: ( <InputAdornment position="end"> { psd===true ?  <VisibilityIcon  onClick={handleClick} style = {{ color : '#FF9017'  }} /> : <VisibilityOffIcon onClick={handleClick} style = {{ color : '#138DE8' }} /> } </InputAdornment> ), type: psd===true ? "text" : "password", autoComplete: "off" }}
                  hasError = { passwordErrorMessage !== null }
                  helperText = { passwordErrorMessage }
                />

                <div style={{display:"flex" , fontSize : "12"}}> 
                <Link style={{color: "black"}} onClick={e=> setOpen(true) }> Forgot Password ?</Link>

                </div>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="rose" style = {{ color : 'floralwhite', backgroundColor : '#FF9017', borderRadius : 4, height : 40 }} simple size="lg" block onClick = { handleLogin } disabled = { (errorMessage !== null || passwordErrorMessage !== null) || (email === '' || password == '')  } >
                  <b> Let{"'"}s Go </b> 
                </Button>
              </CardFooter>
            </Card>
          </form>

          {open && <FormDialog2/>}
        </GridItem>
      </GridContainer>
    </div>
  );
}



// import React from 'react';
// import { Button, TextField, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
// import { useState } from 'react';
// export default function FormDialog2 (props) {
//   const [open, setOpen] = useState(true);
//   const [mail , setMail] = useState("");
  

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };


//   const handleChange = () => {

   
//   };

//   return (
//     <div >
//       <Button variant="outlined" onClick={handleClickOpen}>
//         Forgot Password            
//       </Button>
//       <Dialog open={open} onClose={handleClose}   fullWidth={true}      maxWidth={"md"}>
//         <DialogTitle style={{width : "400px"}}>Forgot Password</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             <TextField
//             margin="dense"
//             id="name"
//             label="Email"
//             type="text"
//             fullWidth
//             variant="standard"
//             onBlur ={ e=> setMail(e.target.value)}
//           />
//           </DialogContentText>
         
         
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Cancel</Button>
//           <Button onClick={handleChange}>Send Otp</Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }