import { Avatar, Card, Chip, Container, Divider, IconButton, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import CardHeader from 'components/Card/CardHeader';
import GridItem from 'components/Grid/GridItem';
import React, { useCallback, useState } from 'react';
import { Fragment } from 'react';
import AddIcon from '@material-ui/icons/Add';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import GridContainer from 'components/Grid/GridContainer';
import CardBody from 'components/Card/CardBody';
import RuleForm from './GenCardRuleForm';
import QRCode from 'qrcode.react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import jsPDF from 'jspdf';
import { Brightness1, Delete, FontDownload, MoreVert, Opacity, ShoppingCartOutlined, Star } from '@material-ui/icons';
import { Dialog } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import "./Card.css"
import styled from "styled-components";
import GetAppIcon from '@material-ui/icons/GetApp';
import membershipCardApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import  { useEffect } from 'react';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import BlockUi from 'react-block-ui';
import { HWInput } from 'HWLibrary/HWInput';
import Edit from '@material-ui/icons/Edit';
import { Button, makeStyles , TextField } from '@material-ui/core';
import { columnsTotalWidthSelector } from '@material-ui/data-grid';
import settingsApiService from 'views/Settings/APIService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import html2canvas from 'html2canvas';
const useStyles = makeStyles (theme => ({
    ...styles,
    saveButton :  { color: 'floralwhite', backgroundColor : '#138DE8' , margin: 2.5 ,  },
    cancelButton :  { color: 'floralwhite', backgroundColor : '#FF9017' , margin: 2.5 ,  },

}));


function RuleDialog (props) {
    
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ ruleDescription, setRuleDescription ] = useState ({});
 
    console.log (props);

    const handleClose = event => props.setOpen (false);
    return (
        <>
            <Dialog open = { props.open } onClose = { handleClose }  >
                <RuleForm refreshState = { props.refreshState } tierId={props.tierId} setRefreshState = { props.setRefreshState } rules = { props.rules } setRules = { props.setRules } createMode = { props.createMode } ruleId = { props.ruleId } open = { props.open } setOpen = { props.setOpen }  />
            </Dialog>
        </>
    );
}



export default function Cards (props) {

 
    HWLocalStorage.save (0, "previousCardTab")


    var cards=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    cards= [...obj["Cards"]];

    const classes = useStyles();


    const Info = styled.div`
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 71px;
    left: 0%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    cursor: pointer;
  `;
  
  const GridContainers = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    
    &:hover ${Info}{
      opacity: 1;
    }

    
  `;
  
  
  const Image2 = styled.img`
    height: 100%;
    z-index: 2;
   
  `;
  
  const Icon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    transition: all 0.5s ease;
    &:hover {
      transform: scale(1.1);
      opacity: 1;

    }
    z-index: 999;
  `;

  const Card = styled.div`
  &:hover {
    opacity: 0.7;
  }
`;
const [ ruleLoading, setRuleLoading ] = useState (false);
const [ ruleOpen, setRuleOpen ] = useState (false);
const [ createMode, setCreateMode ] = useState (false);
const [ showOptions, setShowOptions ] = useState (false);
const [ dropdownOpen, setDropdownOpen ] = useState (false);
const [anchorEl, setAnchorEl] = React.useState(null);
const [currentRule, setCurrentRule] = React.useState([]);
const [ createOpen , setCreateOpen ] = useState (false);
const [ tierId , setTierId] = useState ("");
const [ refreshState , setRefreshState] = useState ("");
const [ deleteState , setDeleteState] = useState ({});
const [ VirtualPrefix , setVirtualPreFix] = useState ("-1");
const [ edit , setEdit] = useState (false);
const [ tierCardMap, setTierCardMap ] = useState ({});
const [ refreshState2 , setRefreshState2] = useState (false);
const [ page, setPage ] = useState (1);
const [ pageSize, setPageSize ] = useState (100);
const [ filterModel, setFilterModel ] = useState (null);
const [ rowCount, setRowCount ] = useState (0);

  useEffect (() => {
        setRuleLoading (true);
        membershipCardApiService.fetchCards ()
        .then (response => {  
            if (! response || ! response.data) { return HWMessage.show ("Cards Cannnot be fetched","error"); }
            
            
            setCurrentRule (response.data); 
            var mp = {};
            var res = response.data.map(i => { mp[i.tierId] = []; return i; });

            response.data.map (i => {
                if (mp[tierId] != null) 
                    {mp[tierId] = Array.of(i);
                    console.log ("miififififipmpmpmp", response.data,  mp);}

                else 
                   {             

                        mp[i.tierId].push (i);
                        console.log ("mpmelelelepmpmp", response.data,  mp);
                    }
                return i;
             });

             console.log ("mpmpmpmp", response.data,  mp);
            setTierCardMap (prev => ({ ...mp }));

            HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        ; })
        .finally (() => setRuleLoading (false));
}, [refreshState ]);






useEffect (() => {
 
    setRuleLoading (true);
    membershipCardApiService.fetchVirtualPrefix ()
    .then (response => {  
        if (! response || ! response.data) { return HWMessage.show ("Cards Cannnot be fetched","error"); }

       setVirtualPreFix (response.data.virtualPrefix); 
        HWMessage.show("Request Successful" , "success");
    })
    .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    ; })
    .finally (() => setRuleLoading (false));
}, [refreshState2])


const handlePrefixSave = useCallback (id => { 
    console.log(id);
    if (id != null) {
        setRuleLoading (true);
        membershipCardApiService.updateVirtualPrefix(id) 
        .then (response => {
            if (!response || !response.data) { return HWMessage.show ("Error while updating the card" , "error");  }
            HWMessage.show("Request Successful" , "success");
            console.log (response.data);
            setRefreshState(prev=>!prev);
           
        })
        .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
     })
        .finally (() => { setRuleLoading (false); setEdit(false); });
    }

 } );

const makeDefault = useCallback (id => {
    console.log(id);
    if (id != null) {
        setRuleLoading (true);
        membershipCardApiService.makeCardDefault (id) 
        .then (response => {
            if (!response || !response.data) { return HWMessage.show ("Error while updating the card" , "error");  }
            HWMessage.show("Request Successful" , "success");
            console.log (response.data);
            setRefreshState(prev=>!prev);
           
        })
        .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
     })
        .finally (() => { setRuleLoading (false) });
    }
});

const handleDelete = useCallback (id => {
    console.log(id);
    if (id != null) {
        setRuleLoading (true);
        membershipCardApiService.deleteCard (id) 
        .then (response => {
            if (!response || !response.data) { return HWMessage.show ("Error while deleteing the card" , "error");  }
            HWMessage.show("Request Successful" , "success");
            console.log (response.data);
            setRefreshState(prev=>!prev);

        
        })
        .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
     })
        .finally (() => { setRuleLoading (false) });
    }
});

    console.log("Cards printing", props);
    console.log("Atomic cards prinitig", props.cards);
    const [ cardList, setCardList ] = useState (props.cards);
    const [ Id, setId ] = useState ("");
    const [ list, setList ] = useState ([]);

    
    
    console.log("Carding list ", cardList);

    const generateCardNumberView = cardNumber => {
        return Array.from(cardNumber).map((i, idx) => i += (idx % 4 === 3) ? ' ' : '' ).join('');
    };
    const addRule = (event) => { setCreateMode (prev => true); setRuleOpen (prev => true); };

    var tierList=[];
    useEffect (() => {
        var paginationBookmark = ((page - 1) * pageSize);
        var fetchLimit = pageSize;
        settingsApiService.fetchMembershipCategory (fetchLimit, paginationBookmark)
        .then (response => {
            if (!response || !response.data){
                HWMessage.show("Data can't be fetched at the moment" , "error");
                return console.log("No relevant data can be fetched");
            }
            console.log("Printing ::", response);
           
            HWMessage.show("Request Successful" , "success");
            
            tierList = response.data.tiers.map(i => ({ id : i.id, tierName : i.tierName}));

            console.log(tierList);
            setList(tierList);
        })
        .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() => {  });
    }, [ page, pageSize ,  refreshState ]);

      const handleDefaultClick = (event,card) => {
            console.log(deleteState);    
            makeDefault(card);

      };

  
 

      var names = currentRule.filter( j => j.tierName);
      names = names.map(function(value) {
        return value.tierName;
      });
      var less = list.map(function(value) {
        return value.tierName;
      });

     var diff = less.filter(x => !names.includes(x) );

      var obj=[];
      for(var p=0;p<list.length;p++){
        for(var o=0; o < diff.length;o++){
            if(list[p].tierName === diff[o])
            {
              obj.push(list[p]);
            }
        }
      }

      console.log(obj);
     
       //names - list of all card designs(objects-id,tierName)
       //list- list of all tiers(objects-backgroundImage,defaultStatus,fontColor,fontSize,fontType,name,status,tierId ,tierName)
     
    //    const downloadResource = (url, callback) => {
    //     fetch(url, {
    //         headers: new Headers({
    //           Origin: window.location.origin
    //         }),
    //         mode: 'no-cors',
    //       })
    //       .then(response => response.blob())
    //       .then(blob => {
    //         let blobUrl = window.URL.createObjectURL(blob);
    //         callback(blob,blobUrl)
    //       })
    //       .catch(e => console.error(e));
    //   };
      
    

        const handleDownloadClick = async (event,card) => {
         console.log(card);    
        var link = document.createElement('a');
        link.setAttribute("id","para1");

        link.href =  card.backgroundImage;
        link.download = card.name;
        document.body.appendChild(link);
       
        // html2canvas(document.getElementById("para1"), {
        //     useCORS: true
        //   }).then(canvas => {
        //     let pdf = new jsPDF('p', 'mm', 'a4', true);
        //     var finalWidth = pdf.internal.pageSize.getWidth();
        //     var finalHeight = pdf.internal.pageSize.getHeight();
        //     pdf.addImage(
        //       canvas.toDataURL('image/jpeg'),
        //       'JPEG',
        //       5,
        //       5,
        //       finalWidth ,
        //       finalHeight 
              
        //     );
        //     pdf.save("a.pdf", {
        //         returnPromise: true
        //       }).finally(() => {
        //         return true;
        //       });

        //   });
        link.click();
        document.body.removeChild(link);
       


    //     // downloadResource(card.backgroundImage, (imgData, url) => {
    //     //     try{ pdf.addImage(imgData, 10, 10); }
    //     //     catch(err){
    //     //            HWMessage.show("Something went wrong.","error");
    //     //           }
    //     //     pdf.save("test.pdf");
    //     // });
    //    let pdf = new jsPDF();
    //     let img = new Image();   
       
    //     img.onload =  function() {
    //         try{
                
    //            pdf.addImage(this, 10, 10);
    //         }catch(err){
    //        HWMessage.show("Something went wrong.","error");
    //       }
    //     pdf.save("test.pdf");
    //     };
    //      img.crossOrigin = 'anonymous'        // img.src = card.backgroundImage; 
    //      img.src = card.backgroundImage;

    //      img.src = card.backgroundImage.replace(/^https:\/\//i, 'http://');
   
   
    };

    const handleDeleteClick = (event,card) => {
        console.log(deleteState);   
        setDeleteState(card);
        setCreateOpen(true)
       // handleDelete(deleteState);
    };
    return (
        
        <Fragment>
         <BlockUi blocking = { ruleLoading } message = { 'Please wait while we fetch.' } >

            <br/>
            <GridItem style={{display: "flex" }}  xs = {12} md = {4} sm = {12} > 
            <HWInput label = { 'Virtual Number to start with ' } required = {true}  type='number' value = { VirtualPrefix  } name = "VirtualPrefix"   disabled={!edit}  onBlur = { e => setVirtualPreFix(e.target.value)} hasError={ VirtualPrefix.length !== 2  || VirtualPrefix<=0 ? true : false} helperText={ VirtualPrefix.length !== 2 || VirtualPrefix<10   || VirtualPrefix=='' ? "Invalid Value" : "" }  />  
            {cards.includes("Action") === true && !edit && <Edit style={{color:"#138DE8"}} onClick={e=>setEdit(!edit)}/>}
            {edit &&  <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} disabled={VirtualPrefix.length !== 2 ? true : false} onClick = { e => handlePrefixSave(VirtualPrefix) } raised  > {  'Save Prefix' } </Button>}
            {edit &&  <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} onClick = { e => {setEdit(!edit); setRefreshState2(!refreshState2)} } raised  > {  'Cancel' } </Button>}
            </GridItem>
            <br/>


         { 
         Object.keys(tierCardMap).map(i => (
            <> 
               {/* {JSON.stringify(tierCardMap[i])} */}
                <span style={{color: "#138DE8 " , fontWeight : 800 , fontSize: 20 , paddingTop: 10 } }> { currentRule.filter((j,idx)=> j.tierId === i ).length === 0 ? null : currentRule.filter( j => j.tierId === i )[0].tierName } <IconButton disabled={cards.includes("Action") === true ? false: true } onClick = { e=> { setId(i) ;addRule()} } style={{ padding : 2}}> <AddIcon  style={{ color:'#138DE8' }}/>   </IconButton>  </span>
                <GridContainer  >
               { tierCardMap[i] &&  tierCardMap[i].map((card , idx) =>   
               <GridItem key={idx} xs={6} md = {6} style = {{ marginTop : 10 }} >  
                    <center style={{ position:"relative" , right: 80 }}> <h5><b>{card.name}</b></h5> </center>
                    <Card id="account_360_view_wrapper" raised style = {{ backgroundImage :  `url(${card.backgroundImage})` , backgroundSize : 'cover', height : '240px', width : '440px',  borderRadius : 15 }} > 
                        <CardHeader>

                            <GridContainers justify = "space-end">
                            <Info>
                               {cards.includes("Action") === true  &&  card &&  card.defaultStatus && card.defaultStatus !=="DefaultDesign" && <Icon>
                                <Star  onClick={e=>{setDeleteState(card);handleDefaultClick(e,card); } }  />
                                </Icon>}
                                <Icon >
                                
                                <GetAppIcon onClick={e=>{handleDownloadClick(e,card);} } />
                                </Icon>
                                { cards.includes("Action") === true  &&  card &&  card.defaultStatus &&  card.defaultStatus !== "DefaultDesign" && <Icon>
                                <Delete onClick={e=>{handleDeleteClick(e,card)}}/>
                                </Icon>}
                            
                                </Info>
                            </GridContainers>
                            
                        </CardHeader>
                        <CardBody > 
                            <GridContainer>
                                <GridItem xs= {8} style = {{ marginTop : 160 }} > <b style = {{ fontSize : card.fontSize , marginTop : 44, color : card.fontColor , font: card.fontType }} >  { generateCardNumberView ("123123123123") } </b>   </GridItem>
                                <GridItem xs= {4} style = {{ marginTop : 70 }} >  <QRCode bgColor = 'transparent'   fgColor = 'floralwhite' value = { "123123123123" } />  </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                    <GridItem xs = {12} md = {6} style={{position: 'relative', left: 120 }} > 
                    { card &&  card.defaultStatus && card.defaultStatus==="DefaultDesign" && <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 40 , margin: 5}} label = { "Default Card"} /> }
                    </GridItem>
                </GridItem>
                )
            }
            </GridContainer> 
            <Divider style={{marginBottom:20}}/>
            {/* <div style={{ borderTop: "2px solid lightgrey ", marginTop: 13 }}></div> */}
            </>
         ))


         
         
         }


         
         {
        obj.map(i => (
            <> 
{ cards.includes("Action") === true  &&                <span style={{color: "#138DE8 " , fontWeight : 800 , fontSize: 20 , paddingTop: 10 } }> { i.tierName } <IconButton onClick = { e=> { setId(i.id) ;addRule()} } style={{ padding : 2}}> <AddIcon  style={{ color:'#138DE8' }}/>   </IconButton>  </span>
}               
           {cards.includes("Action") === true &&  <Divider style={{marginBottom:20}}/>}
           </>
         ))


         
         
         }
         
        
            <ConfirmationDialog open = { createOpen } setOpen = { setCreateOpen }  dialogMessage = { 'Are you sure you want to delete this Card' } dialogTitle = { 'Confirm Card Deletion' } confirmingAction = { e => handleDelete (deleteState) } />

            
            

            <br/>
         
            <RuleDialog     
               // ruleId = { selectedRuleId }
                open = { ruleOpen }     
                setOpen = { setRuleOpen } 
                createMode = { createMode } 
                setCreateMode = {setCreateMode}
                tierId={Id}
               // rules = { rows }
              //  setRules = { setRows }
                refreshState = { refreshState } 
                 setRefreshState = { setRefreshState }
            />
            </BlockUi>
        </Fragment>
        
    );
};