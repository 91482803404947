import authService from "HWLibrary/ApiService";

class ImageApiService {
    constructor () {
        if (!ImageApiService.instance) 
            ImageApiService.instance = this;
        return ImageApiService.instance;
    };

    defaultAuthConfig = () => { 
        const accessToken = authService.getCachedAccessToken();
        return { headers : { Authorization : `Bearer ${accessToken}` } };
    };

    fetchS3PresignedUrl = (fileName, fileExtension) => {

    };
};

const imageApiService = new ImageApiService();
export default imageApiService;