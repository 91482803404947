import { Avatar, Card, Chip, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import CardHeader from 'components/Card/CardHeader';
import GridItem from 'components/Grid/GridItem';
import React, { useState } from 'react';
import { Fragment } from 'react';

import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import GridContainer from 'components/Grid/GridContainer';
import CardBody from 'components/Card/CardBody';

import QRCode from "react-qr-code";

import { Brightness1 } from '@material-ui/icons';


export default function MemberCard (props) {

    console.log("Cards printing", props);
    console.log("Atomic cards prinitig", props.cards);
    const [ cardList, setCardList ] = useState (props.cards);
    console.log("Carding list ", cardList);

    const generateCardNumberView = cardNumber => {
        return Array.from(cardNumber).map((i, idx) => i += (idx % 4 === 3) ? ' ' : '' ).join('');
    };

    return (
        <Fragment>
                <List>
                    {
                    props && props.cards && props.cards.map (card => {
                        console.log("Particular card ", card);
                        return (
                            <ListItem>
                                <GridContainer style = {{ marginLeft : 5 }} >
                                    <GridItem xs = {12} style = {{ marginBottom : '10px' }} > 
                                        <Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" }} label = {card.defaultStatus === "OtherCard"  ? "Other Card" : "Default Card"} />  &nbsp;
                                        <Chip style = {{ color : 'floralwhite', backgroundColor : '#138DE8' }} label =  {card.cardType === "Virtual"  ? "Virtual Card" : "Physical Card"} />  
                                    </GridItem>
                                    <GridItem xs = {12} >  
                                        <Card raised style = {{ backgroundImage : `url(${card.backgroundImage})`, backgroundSize : 'cover', height : '240px', width : '440px',  borderRadius : 15 }} > 
                                            <CardHeader>  
                                                {/* <GridContainer justify = "space-between">
                                                    <GridItem>  <img src='/Group 192.svg' style = {{ height : '45px', width : '100px', background : 'transparent', backgroundColor : 'transparent', filter : 'brightness(0) invert(1)' }} /> </GridItem>
                                                    <GridItem>  <img src='/yamaha13.png' style = {{ height : '45px', width : '100px', background : 'transparent', backgroundColor : 'transparent', filter : 'brightness(0) invert(1)' }} /> </GridItem>
                                                </GridContainer> */}
                                                
                                            </CardHeader>
                                            <CardBody > 
                                            <GridContainer>
                                <GridItem xs= {8} style = {{ marginTop : 160 }} > <b style = {{ fontSize : card.fontSize , marginTop : 44, color : card.fontColor , font: card.fontType }} >  { generateCardNumberView (card.cardNumber) } </b>   </GridItem>
                                <GridItem xs= {4} style = {{ marginTop : 60 }} >  <QRCode value={card.cardNumber}
                                                   size={120}
                                                   bgColor={"transparent"}
                                                   fgColor={"white"}
                                                   renderAs={"svg"}
                                                  />
                                                    </GridItem>
                            </GridContainer>
                                                
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            </ListItem>
                        );
                    })
                    }
                </List>            
        </Fragment>
    );
};