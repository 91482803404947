import React from "react"
import { Button, makeStyles } from "@material-ui/core"
import { themeGreen, whiteColor   } from "assets/jss/material-dashboard-pro-react.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    ...styles,

    success : { display: 'flex', alignSelf: 'center', backgroundColor: themeGreen , color: whiteColor, height: '40px', width: '100px'},
    failure : { display: 'flex', alignSelf: 'center', backgroundColor: '#f44336', color: whiteColor, height: '40px', width: '100px'},
    top: {
        color: '#138DE8',
       // animationDuration: '550ms',
       // position: 'absolute',
       display: 'flex', 
       
      },
      circle: {
        strokeLinecap: 'round',
      },
   
}));

const StatusDialog = (props) => {
    
    const classes = useStyles();

    const handleClose = event => {
        props.setOpen (false)  };
    

    return (
      
        <Dialog open = { props.open } onClose = { handleClose }  >
            <DialogTitle> <center> { props.dialogTitle } </center> </DialogTitle>
            <DialogContent> 
                {props.isLoading === true && <DialogContentText> <center><CircularProgress className={classes.top}   classes={{circle: classes.circle,}} size={40} thickness={4}/> </center>    </DialogContentText>}
                 {props.isLoading === false  && <DialogContentText> <center> {props.message}  </center> </DialogContentText>}
                 <center>
                <DialogActions> 
                 
                {props.isLoading === false &&  props.sucessStatus === 'success' &&   <Button  component="span" raised variant = "contained" style = {{ backgroundColor : '#138de8', color : 'floralwhite', textTransform : 'none' }}
                 onClick={e =>  props.confirmingAction()  }>
                 Proceed
             </Button>  }
             
             {props.isLoading === false  &&  props.sucessStatus === 'failure' && <Button variant="raised" component="span" className = { classes.failure } 
                 onClick={e => props.confirmingAction() }>
                Cancel
            </Button>}
                </DialogActions>
                </center>
            </DialogContent>
        </Dialog>

    
    )
}

export default StatusDialog;