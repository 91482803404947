import React, { useState } from "react";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import { TextArea } from "semantic-ui-react";
import { TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import { autoDiscover } from "dropzone";


export default function AutocompleteTextArea (props) {

    const Item = ({ entity: { name, placeholder } }) => ( <div style = {{width: 300}}>{`${name}: ${placeholder}`}</div> );
    const Loading = ({ data }) => <div>Loading</div>;
    const useStyles = makeStyles(styles);
    // var placeholders = [
    //     { name: "Member Name", placeholder: "<Member_Name>" },
    //     { name: "Member Class", placeholder: "<Member_Class>" },
    //     { name: "Membership Number", placeholder: "<Membership_Number>" },
    //     { name: "Transaction Name", placeholder: "<Transaction_Name>" },
    //     { name: "Transaction Number", placeholder: "<Transaction_Number>" },
    //     { name: "Transaction ID", placeholder: "<Transaction_ID>" }
    // ];


    const [ state, setState ] = useState ('');
    const classes = useStyles();
  
    const {
      formControlProps,
      labelText,
      id,
      labelProps,
      inputProps,
      error,
      white,
      inputRootCustomClasses,
      success,
      helperText,
      required
    } = props;
    var placeholders = props && props.placeholders;

    const labelClasses = classNames({
      [" " + classes.labelRootError]: error,
      [" " + classes.labelRootSuccess]: success && !error
    });
    var formControlClasses;
    if (formControlProps !== undefined) {
      formControlClasses = classNames(
        formControlProps.className,
        classes.formControl
      );
    } else {
      formControlClasses = classes.formControl;
    }

    return (
      <div>
      {labelText !== undefined ? (
          <InputLabel
          className={classes.labelRoot + " " + labelClasses}
          htmlFor={id}
          style={{fontSize:'10.5px'}}
          {...labelProps}
          >
          {labelText}
        </InputLabel>
      ) : null}
          <ReactTextareaAutocomplete
            loadingComponent={Loading}
            style={{
              fontSize: "14px",
              lineHeight: "20px",
              padding: 5,
              width: "100%",
              position: "absolute",
              // left: "-100px",
              resize: 'none'
          
            }}
            containerStyle={{
              marginTop: 5,
              width: 680,
              height: 100,
              margin: "5px auto",
              zIndex: 999

            }}

            name =  "Places"
            disabled = { props.disabled }
            onChange = { props.onChange }
            value={props.value}
            minChar={0}
            trigger={{
              "<": {
                dataProvider: (token) => {
                  return placeholders
                    .slice(0, 100)
                    .map(({ name, placeholder }) => ({ name, placeholder }));
                },
                component: Item,
                output: (item, trigger) => item.placeholder
              }
            }}
          />
          { state }
        </div>
    );
};


