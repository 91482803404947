import axios from 'axios';
import appConfig from '../../HWLibrary/HWConfig';
import authService from 'HWLibrary/ApiService';


// There is caching enabled in this module, therefore 
// the service caches the instances for saving time to initialise one.
class MembershipCardApiService {

    constructor () {
        if (!MembershipCardApiService.instance) 
        MembershipCardApiService.instance = this;
        return MembershipCardApiService.instance;
    }

    defaultAuthConfig = () => { 
        const accessToken = authService.getCachedAccessToken();
        return { headers : { Authorization : `Bearer ${accessToken}` } };
    };
  

    fetchS3PresignedUrl = (name) => {
      const url = `${ appConfig.serverBaseUrl }/presigned-url/card-images`;
      const config = { ...this.defaultAuthConfig() , params : { fileName : name } };
      return authService.axiosService().get (url, config);
  
    }
    uploadImage = (fileName) => {
      const url = `${ appConfig.serverBaseUrl }/presigned-url/card-images`;
      const config = { params : { fileName: fileName } };
      return authService.axiosService().get (url, config);
    };
    uploadFile(fileToUpload, presignedUrl) {
      let config = { headers: { "content-type": fileToUpload.type } }
      return axios.put(presignedUrl, fileToUpload, config);
    }
  
    
  

    
    fetchCards = (id) => {
      const url = `${ appConfig.serverBaseUrl }/cards/design`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().get(url, config);
     
    }

    fetchCardsList = (fetchLimit, paginationBookmark, firstSortId, lastSortId, pageDelta) => {

      const url = `${ appConfig.serverBaseUrl }/cards/design/list`;
      const config = { ...this.defaultAuthConfig(),  params : { paginationBookmark, fetchLimit, firstSortId, lastSortId, pageDelta } };
      return authService.axiosService().get(url, config);
     
    }

    createCardGenRule= (rule) => {
        console.log(rule);
        const url = `${ appConfig.serverBaseUrl }/cards/blocks`;
        const config = { ...this.defaultAuthConfig() };
        const request = { ...rule };
  
        return authService.axiosService ().post (url, request, config);
      };


    
      fetchCardGenRule = (request) => {
        var searchField, searchType, searchText = null;
        if (request && request.filterModel) {
          searchField = request.filterModel.columnField;
          if (searchField === "tier") 
              searchField = "tier.tierName";
    
          searchType = request.filterModel.operatorValue;
          searchText = request.filterModel.value;
        }  
    
        if (request && request.searchText)
          searchText = request.searchText;
    
        var sortField, sortOrder = null;
        if (request && request.sortModel) {
            console.log("Current sort model",  request.sortModel);
            if (request.sortModel.field && request.sortModel.sort) {
              sortField = request.sortModel.field;
              sortOrder = request.sortModel.sort === "asc" ? "Ascending" : "Descending";
            } 
        }  
        const queryParams = request !== undefined ? { params: { searchText, searchField, searchType, sortField, sortOrder, fetchLimit: request.fetchLimit, paginationBookmark: request.paginationBookmark  } } : {};

        const url = `${ appConfig.serverBaseUrl }/cards/blocks`;
        const config = { ...this.defaultAuthConfig(), ...queryParams };
        
        return authService.axiosService ().get (url, config);
      };


      updateCardGenRule = rule => {
        const request = { ...rule };
        const url = `${ appConfig.serverBaseUrl }/vouchers/config/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

      createCard= (rule) => {
        const url = `${ appConfig.serverBaseUrl }/cards/design`;
        const config = { ...this.defaultAuthConfig() };
        const request = { ...rule };
  
        return authService.axiosService ().post (url, request, config);
      };


      deleteCard = rule => {
        const request = { ...rule };
        request.status="InActive";
        const url = `${ appConfig.serverBaseUrl }/cards/design/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };

      makeCardDefault = rule => {
        const request = { ...rule };
        request.defaultStatus="DefaultDesign";
        const url = `${ appConfig.serverBaseUrl }/cards/design/${ rule.id }`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      };
     
      fetchVirtualPrefix = () =>{
        const url = `${ appConfig.serverBaseUrl }/cards/configs`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
      }
       updateVirtualPrefix = (rule) =>{
        const request = {"virtualPrefix" : `${rule}`}
        console.log(request);
        const url = `${ appConfig.serverBaseUrl }/cards/configs`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().put (url, request, config);
      }
      
     downloadExcel = (url) => {
      const config = null;
      return authService.axiosService().get(url, config);

     }

     uploadFile(fileToUpload, presignedUrl) {
      let config = { headers: { "content-type": fileToUpload.type } }
      return axios.put(presignedUrl, fileToUpload, config);
    }

    fetchS3PresignedUrl = (name) => {
      const request = {fileName : name}
      const url = `${ appConfig.serverBaseUrl }/cards/upload-csv`;
      const config = { ...this.defaultAuthConfig()  };
      return authService.axiosService().post (url, request , config);
    }
     
};

// Here singleton pattern being used for creating a merchant service
// The merchantApiService is that singleton object that does this. 
const membershipCardApiService = new MembershipCardApiService();
export default membershipCardApiService;