import HWMessage from "HWLibrary/HWMessage";
import HWUser from "HWLibrary/HWUser";
import React, { useCallback } from 'react';
import { Redirect, Route, Router, Switch } from "react-router-dom";
import AuthLayout from 'layouts/Auth';
import OpsLayout from 'layouts/Ops';
import PaymentRedirectInterceptor from "views/PaymentTesting/PaymentRedirectionInterceptor";

const App = ({browserHistory}) => {

  // Protects the main Ops functionality behind an auth wall.
  const ProtectedRoute = useCallback(({ component: Component, ...rest }) => {
    return (
      <Route {...rest} render={(props) => HWUser.isLoggedIn() === true ?
        <Component {...props} /> : <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />} />
    );
  }, []);

  return(
    <>
      <HWMessage />
      <Router history={browserHistory}>
        <Switch>
          {/* Routing logic: all /ops paths are protected. The  default page is /ops/dashboard. */}
          <Route          path = "/pay" component = { PaymentRedirectInterceptor } />   
          <Route          path="/auth" component={AuthLayout} />
          <ProtectedRoute path="/ops"  component={OpsLayout} />
          <Redirect from="/" to="/ops/dashboard" />
        </Switch>
      </Router>
    </>
  );
}

export default App;