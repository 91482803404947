import { Button,  IconButton, makeStyles, Popover } from '@material-ui/core';
import { DataGrid , GridToolbar} from '@material-ui/data-grid';
import { MoreVert, People } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState, useEffect, Fragment } from 'react';
import { DirectActionDialog } from 'views/Actions/VOCVerification';
import SearchBar from 'views/Merchant/SearchBar';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import BlockUi from 'react-block-ui';
import HWMessage from 'HWLibrary/HWMessage';
import { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import pendingTransactionsApiService from 'views/Transactions/ApiService';
import { green, red } from '@material-ui/core/colors';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import { Checkbox } from '@material-ui/core';
import { CustomToolbar } from 'views/Members/Member';

 const useStyle = makeStyles (theme => ({
     ...styles,
     triggerButton : { background : '#138DE8', color : 'floralwhite', textTransform : 'initial', fontSize : 22, height : 50, width : 300 },
     bottomGrid : { marginTop : 10 },
     actionIcon : { color : '#138DE8' },
     editButton : { background : '#138DE8', color : 'floralwhite' , marginLeft : 10 , textTransform: 'None'},
     deleteButton : { background : '#FF9017', color : 'floralwhite' ,marginRight : 10 , textTransform:'None'}
 }));



export default function TransactionsPending (props) {
 

     const classes = useStyle ();
     const [ requestsLoading, setRequestsLoading ] = useState (false);
     const [ refreshState, setRefreshState ] = useState (false);
     const [ selectedId, setSelectedId ]  = useState("");
     const [ rows, setRows ] = useState ([]);
     const [ page, setPage ] = useState (0);
     const [ pageSize, setPageSize ] = useState (100);
     const [ rowCount, setRowCount ] = useState (0);
     const [ searchText, setSearchText ] = useState ('');
     const [ actionOpen, setActionOpen ] = useState (false);
     const [ selectionModel, setSelectionModel ] = useState ([]);
     const [ selectionModelMap, setSelectionModelMap ] = useState ({});
     const [ filterModel, setFilterModel ] = useState ('');
     const [ sortModel, setSortModel ] = useState ({});

     const columns = [
         
        { field : 'sellerOrderNumber',         headerName : 'Seller Order Number',        filterable : false, sortable : true, flex : 1.5, renderCell : cellParams => <span style={{color: "#138DE8"}} onClick = { event => handleEdit (event, cellParams.row) } > {cellParams.row.sellerOrderNumber} </span> },
        { field : 'fpxTransactionId',                        headerName : 'Fpx Transaction Id',    filterable : false,  sortable : false, flex : 1.5 }, 
        { field : 'sellerExchangeId',          headerName : 'Seller Exchange Id',     filterable : false,  sortable : true, flex : 1.2 },
        { field : 'transactionAmount',         headerName : 'Transaction Amount',              filterable : true,  sortable : true, flex : 1.2 },
        { field : 'buyerEmail',                headerName : 'Buyer Email',              filterable : true,  sortable : true, flex : 1},
        { field : 'debitAuthCode',             headerName : 'Debit Auth Code',              filterable : true,  sortable : true, flex : 1},
        { field : 'action',                    headerName : 'Action', renderCell : cellParams => selectionModel && <Checkbox  style = {{ color : '#138DE8' }} onClick = { e => handleExclusionInclusionClick (cellParams.row.sellerOrderNumber) } checked = { selectionModel.indexOf (cellParams.row.sellerOrderNumber) !== -1 } /> }

    ];

    useEffect (() => {
        var paginationBookmark = (page) * pageSize;
        var fetchLimit = pageSize;
         setRequestsLoading (true);
        pendingTransactionsApiService.fetchRequests ({ searchText,paginationBookmark, fetchLimit })
        .then (response => { 
            if (! response || ! response.data) { return HWMessage.show ("Data cannot be fetched","error"); }   
                  
            setRowCount (response && response.data && response.data.totalPendingTransactions);
            setRows (response && response.data && response.data.pendingTransactions);
            setSelectionModelMap ({  ...selectionModelMap,  [page] : selectionModelMap[page] || [] });
            HWMessage.show("Request Successful" , "success");
        }).catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
    .finally (() => setRequestsLoading (false));
    }, [ refreshState, pageSize, page, searchText ]); 

    const handleTrigger = (sellerOrderNumbers,aeRequestForAll)  =>{
        setRequestsLoading (true);
        pendingTransactionsApiService.updateRequestForAE ({sellerOrderNumbers,aeRequestForAll})
            .then (response => { 
                if (! response || ! response.data) { return HWMessage.show ("Error while raising request","error"); }   
                HWMessage.show("Request raised successfully","success");
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
            .finally (() => setRequestsLoading (false));
    }

    const history = useHistory ();
   
    const openRequest = id => {
        if (id !== null) {
            history.push ({ pathname: "pending-transaction-details", state: { requestId: id , creatorMode : true, prev : 'transactions' } });
        }
    };
    
    const handleRefresh = (refresh) => {
        setFilterModel (prev => {});
        setSortModel (prev => {});
        setRefreshState (prev => refresh);
    };


    const handleEdit = (e,row)  => {
        console.log("code came here on through seller order number ")
        const id = row.sellerOrderNumber;
        openRequest (id);
    };

    const handleExclusionInclusionClick = id => {

        console.log("Selections", selectionModel, selectionModelMap);

        var idx = selectionModel.indexOf (id);
        if (idx !== -1) {
            selectionModel.splice (idx, 1);
            setSelectionModel ([ ...selectionModel ]);
            let currentMap = selectionModelMap [ page ];
            var mapIdx = currentMap.indexOf(id);
            if (mapIdx !== -1) 
                currentMap.splice (mapIdx, 1);
            selectionModelMap [ page ] = currentMap;
            setSelectionModelMap ({ ...selectionModelMap });
        } else {
            selectionModel.push (id);
            setSelectionModel ([ ...selectionModel ]);
            console.log("page",page);
            
            let currentMap = selectionModelMap [ page ];
            console.log("currentMap",currentMap);
                currentMap.push (id);
            
            selectionModelMap [ page ] = currentMap;
            setSelectionModelMap ({ ...selectionModelMap });
        }
    };
    function CustomLoadingOverlay() {
        return (
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
      }

      const useDataGridStyles = makeStyles(theme => ({
        root: {
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiButton-label' : { color : '#138DE8' }
        }
      }));
      const dataGridClasses = useDataGridStyles();

    
     return (
         <Fragment>
             <BlockUi>
                 <Card id="top">
                     <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                         <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                         <h4 className = { classes.cardIconTitle } > Transactions
                         </h4> 
                     </CardHeader>
                     <CardBody>  
                        <GridContainer style = {{ justifyContent : 'space-between' }} >
                            <GridItem>
                                     <SearchBar  
                                        handleSearch = { setSearchText }
                                         handleRefresh = { handleRefresh }
                                        refreshState = { refreshState }
                                        placeholder = { 'Search By Seller Order Number, Debit Auth Code etc' }
                                     />
                            </GridItem>
                             <GridItem> 
                                 <Button onClick = {e=> handleTrigger(null,true) } variant = 'contained' className = { classes.triggerButton }> Trigger AE Request for All </Button>
                             </GridItem>
                        </GridContainer>
                        <GridContainer>
                              <GridItem className = { classes.bottomGrid } xs = {12} md = {12}>
                                     <DataGrid 
                                         className = { dataGridClasses.root }
                                         showToolbar
                                         page = { page }
                                         pageSize = { pageSize }
                                         rows = { rows }
                                         rowCount = { rowCount }
                                         columns = { columns }
                                         pagination
                                         paginationMode = 'server'
                                         onPageChange = { changeParams => setPage (changeParams) }
                                         onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams); } }
                                         sortingMode="server" 
                                            onSortModelChange = { params => { 
                                            if (params.length > 0) 
                                                setSortModel (params[0]);
                                            } }
                                        
                                            filterMode = 'server'
                                            onFilterModelChange = { (params) => {
                                            if (params.items.length > 0) {
                                                setFilterModel (prev => ({ ...params.items[0] })); 
                                            }
                                            } }  

                                         density = 'compact'
                                         autoHeight
                                         rowsPerPageOptions ={ [25, 50, 100 ]}
                                         loading = { requestsLoading }
                                         components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 

                                         disableExtendRowFullWidth = { false }
                                         hideFooterSelectedRowCount
                                     />
                             </GridItem>
                             <GridItem xs = {12} md = {12} > 
                                 <center>
                                     <GridContainer>
                                         <GridItem xs = {12} md = {12} style={{marginTop:20}} > <Button  onClick = {e=> handleTrigger(selectionModel,false) }variant = 'contained'> Trigger AE Request </Button> </GridItem> 
                                    </GridContainer>
                                 </center>
                             </GridItem>
                         </GridContainer>
                     </CardBody>
                
                 </Card>
                 <ConfirmationDialog 
            dialogTitle = { 'Confirm Request Deletion' } 
            dialogMessage = { 'Are you sure you want to delete the request. ' } 
            confirmingAction = { e => console.log("hwekk") } open = { actionOpen } setOpen = { setActionOpen } 
        /> 
             </BlockUi>
         </Fragment>
     );
 };




