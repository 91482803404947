import axios from 'axios';
import authService from 'HWLibrary/ApiService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import HWMessage from 'HWLibrary/HWMessage';
import appConfig from '../../HWLibrary/HWConfig';
class MerchantSegmentApiService {

  constructor() {
    if (! MerchantSegmentApiService.instance) { MerchantSegmentApiService.instance = this }
    return MerchantSegmentApiService.instance;
  };

  defaultAuthConfig = () => {
    const accessToken =  authService.getCachedAccessToken();
    return { headers: { Authorization: `Bearer ${accessToken}` } };
  };

  fetchUserSegments = () => {
    const url = `${ appConfig.serverBaseUrl }/merchant-segments`;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().get (url, config);
  };

  fetchUserSegment = (segmentId) => {
    const url = `${ appConfig.serverBaseUrl }/merchant-segments`;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().get (url, {segmentId:segmentId} ,config);
  };


  fetchS3PresignedUrl = (name) => {
    const request = {fileName : name}
    const url = `${ appConfig.serverBaseUrl }/members/segments/upload-csv`;
    const config = { ...this.defaultAuthConfig()  };
    return authService.axiosService().post (url, request , config);

  }

  uploadFile(fileToUpload, presignedUrl) {
    let config = { headers: { "content-type": fileToUpload.type } }
    return axios.put(presignedUrl, fileToUpload, config);
  }

  submitForm = (request) => {
    const url = `${ appConfig.serverBaseUrl }/members/segments/csvcreate`;
    const config = { ...this.defaultAuthConfig()  };
    return authService.axiosService().post (url, request , config);

  }

  updateUserSegment = (updatedSegment) => {
    if (updatedSegment === null ) { return HWMessage.show ("Segment need to be specified for updating"); }
    const request = { ...updatedSegment };
    const url = `${ appConfig.serverBaseUrl }/merchant-segments`
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().put (url, request, config);
  };

  fetchSegmentList = (searchText,segmentId,fetchLimit,paginationBookmark) => {
    const url = `${ appConfig.serverBaseUrl }/merchant-segments`;
    const config = { ...this.defaultAuthConfig(), params: {searchText:searchText, segmentId : segmentId,fetchLimit: fetchLimit,paginationBookmark:paginationBookmark} };
    return authService.axiosService().get (url, config);
  };



  fetchSegmentMemberList = (query, includedMembers, excludedMembers, paginationBookmark, fetchLimit, firstSortId, lastSortId, pageDelta ) => {
    console.log("query",query);

    const queryParams = { params : { fetchQueryList: JSON.stringify(query), includedMembers: JSON.stringify(includedMembers),  excludedMembers : JSON.stringify(excludedMembers), paginationBookmark, fetchLimit, firstSortId: firstSortId, lastSortId : lastSortId, pageDelta : pageDelta } }
    const url = `${ appConfig.serverBaseUrl }/members/query`;
    const config = { ...this.defaultAuthConfig(), ...queryParams };
    return authService.axiosService().get (url, config);
  };

  updateUserSegment = (updatedSegment) => {
    if (updatedSegment === null ) { return HWMessage.show ("Segment need to be specified for updating"); }
    const request = { ...updatedSegment };
    const url = `${ appConfig.serverBaseUrl }/merchant-segments?`
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().put (url, request, config);
  };
  
}

const merchantApiService = new MerchantSegmentApiService();
export default merchantApiService;