import axios from 'axios';
import authService from 'HWLibrary/ApiService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import HWMessage from 'HWLibrary/HWMessage';
import appConfig from '../../HWLibrary/HWConfig';
class CampaignApiService {

  constructor() {
    if (! CampaignApiService.instance) { CampaignApiService.instance = this }
    return CampaignApiService.instance;
  }

  defaultAuthConfig = () => {
    const accessToken =  authService.getCachedAccessToken();
    return { headers: { Authorization: `Bearer ${accessToken}` } };
  }

  fetchCampaigns = ({ campaignType, searchText ,  paginationBookmark, fetchLimit }) => {
    
    if (! campaignType || campaignType === null ) { return HWMessage.show ("Campaign Type must be specified for this request"); }

    const url = appConfig.serverBaseUrl + "/campaigns";
    const config = {...this.defaultAuthConfig(), params: { campaignType, searchText ,  paginationBookmark, fetchLimit  } };
    return authService.axiosService().get(url, config);
  };

  reorderCampaigns = (campaignList) => {
    if (!campaignList || campaignList.length === 0) { return HWMessage.show ("Campaign List must be specified for this request"); }
    const request = { campaignSequenceList : campaignList }
    const url = appConfig.serverBaseUrl + "/campaigns/reorder";
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().put (url, request, config);
  };

  fetchCampaign = (campaignId) => {
    if (! campaignId) { return HWMessage.show ("Campaign ID is invalid"); }
    const url = `${ appConfig.serverBaseUrl }/campaigns/${ campaignId }`;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().get (url, config);
  };
  deleteCampaign = (campaignId) => {
    if (! campaignId) { return HWMessage.show ("Campaign ID is ijnvalid or not provided"); }
    const url = `${ appConfig.serverBaseUrl }/campaigns/${ campaignId }/delete`;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().put(url, {}, config);
  };  
  enableCampaign = (campaignId) => {
    if (! campaignId) { return HWMessage.show ("Cmapaign ID is invalid or not provided") }
    const url = `${ appConfig.serverBaseUrl }/campaigns/${ campaignId }/enable`;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().put (url, {}, config);
  };
  disableCampaign = (campaignId) => {
    if (! campaignId) { return HWMessage.show ("Campaign ID is invalid or not provided"); }
    const url = `${ appConfig.serverBaseUrl }/campaigns/${ campaignId }/disable`;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().put (url, {}, config);
  };
  changeCampaignPriorityStatus = (campaignId, priorityStatus) => {
    if (! campaignId) { return HWMessage.show ("Campaign ID is invalid or not provided"); }
    const url = `${ appConfig.serverBaseUrl }/campaigns/${ campaignId }/${priorityStatus}`;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().put (url, {}, config);
  }

  changeCampaignVisibilityStatus = (campaignId,visibilityStatus) => {
    if (! campaignId) { return HWMessage.show ("Campaign ID is invalid or not provided"); }
    const url = `${ appConfig.serverBaseUrl }/campaigns/${ campaignId }/${visibilityStatus}`;
    const config = { ...this.defaultAuthConfig() };
    return authService.axiosService().put (url, {}, config);
  }
  createCampaign = (campaign) => {
    if (! campaign) { return HWMessage.show ("Campaign is invalid"); }
    else {
      const url = `${ appConfig.serverBaseUrl }/campaigns`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().post (url, campaign, config);
    }
  };

  updateCampaign = (campaign) => {
    if (! campaign) { return HWMessage.show ("Campaign is Invalid") }
    else {
      const url = `${ appConfig.serverBaseUrl }/campaigns/${campaign.id}`;
      const config = { ...this.defaultAuthConfig() };
      return authService.axiosService().put (url, campaign, config);
    }
  };


  fetchS3PresignedUrl = (name) => {
    const url = `${ appConfig.serverBaseUrl }/presigned-url/campaign-images`;
    const config = { ...this.defaultAuthConfig() , params : { fileName : name } };
    return authService.axiosService().get (url, config);

  }

  uploadFile(fileToUpload, presignedUrl) {
    let config = { headers: { "content-type": fileToUpload.type } }
    return axios.put(presignedUrl, fileToUpload, config);
  }


  uploadImage = (fileName) => {
    const url = `${ appConfig.serverBaseUrl }/presigned-url/campaign-images`;
    const config = { params : { fileName } };
    return authService.axiosService().get (url, config);
  };
};


const campaignApiService = new CampaignApiService();
export default campaignApiService;