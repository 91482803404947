import axios from 'axios';
import authService from 'HWLibrary/ApiService';
import appConfig from '../../../HWLibrary/HWConfig';

class RoleApiService {

    constructor () {
        if (! RoleApiService.instance) 
            RoleApiService.instance = this;
        return RoleApiService.instance;
    }

    defaultAuthConfig = () => { 
        const accessToken = authService.getCachedAccessToken();
        return { headers : { Authorization : `Bearer ${accessToken}` } };
    };

    fetchRoles = () => { 
        const url = `${appConfig.serverBaseUrl}/roles`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get(url, config);
    };

    fetchRole = (id) => { 
        const url = `${appConfig.serverBaseUrl}/roles/${id}`;
        const config = { ...this.defaultAuthConfig() };
        return authService.axiosService().get (url, config);
    };
    createRole = (saveRole) => { 
        const url = `${appConfig.serverBaseUrl}/roles`;
        const config = { ...this.defaultAuthConfig() };
        const data = { ...saveRole }; 
        return authService.axiosService().post (url, data, config);
    };
    deleteRole = (id) => {  
        const url = `${appConfig.serverBaseUrl}/roles/${id}`;
        const config = {...this.defaultAuthConfig()};
        const data = { id, patchMap : {'documentStatus' : 'Inactive'} };
        return authService.axiosService().delete (url, config);
    };
    updateRole = (currentObject) => { 
        var id = currentObject.id;
        const url = `${appConfig.serverBaseUrl}/roles/${ id }`;
        const config = {...this.defaultAuthConfig()};
        const data = { id, patchMap : { 'permissionList' : currentObject.permissionList } };
        return authService.axiosService().put (url, data, config);
    };
};

const roleApiService = new RoleApiService();
export default roleApiService;