import { Button, CardHeader, TextField, CardActions, Checkbox } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState, useEffect, Fragment, useRef } from 'react';
import Card from 'components/Card/Card';
import { makeStyles } from '@material-ui/styles';
import {  useHistory } from 'react-router-dom';
import merchantApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import BlockUi from 'react-block-ui';
import { HWInput } from 'HWLibrary/HWInput';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import StatusDialog from 'views/Utils/StatusDialog';

const useStyles = makeStyles (theme => ({
   
}));


export default function SegmentInfo (props) {


    if (HWLocalStorage.load ("previousVouchersTab") !== null ) 
    HWLocalStorage.clear("previousVouchersTab");
    if(HWLocalStorage.load ("previousVouchersRuleTab") !== null)
     HWLocalStorage.clear("previousVouchersRuleTab");
     if (HWLocalStorage.load ("previousCardTab") !== null ) 
     HWLocalStorage.clear("previousCardTab");
     if(HWLocalStorage.load("previousComSettingsTab")!== null){
        HWLocalStorage.clear("previousComSettingsTab")
    }
    if(HWLocalStorage.load("previousRolesSettingsTab")!== null){
        HWLocalStorage.clear("previousRolesSettingsTab")
    }

    if(HWLocalStorage.load("previousSettingsTab")!== null){
        HWLocalStorage.clear("previousSettingsTab")
    }
    if(HWLocalStorage.load("previousMemberTab")!== null){
        HWLocalStorage.clear("previousMemberTab")
    }
    if(HWLocalStorage.load("previousActionsTab")!== null){
        HWLocalStorage.clear("previousActionsTab")
    }
    if(HWLocalStorage.load("previousMerchantDetailsTab")!==null)
    {
        HWLocalStorage.clear("previousMerchantDetailsTab");
    }

    if(HWLocalStorage.load("previousMerchantTab")!==null)
    {
        HWLocalStorage.clear("previousMerchantTab");
    }
    if(HWLocalStorage.load("previousPointsRuleTab")!== null)
    {
        HWLocalStorage.clear("previousPointsRuleTab");
    }
    if(HWLocalStorage.load("previousPointsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsTab");
    }
    if(HWLocalStorage.load("previousActionsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsTab");
    }
    if(HWLocalStorage.load("previousPointsActionsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsActionsTab");
    }

    var seg=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    seg= [...obj["UserSegments"]];

    console.log (props);
    const classes = useStyles ();
    const [ loading, setLoading ] = useState (false);
    const [ segmentName, setSegmentName ] = useState (props && props.location && props.location.params && props.location.params && props.location.params.state && props.location.params.state.segmentName || null);
    const [ count, setCount ] = useState (props && props.location && props.location.params && props.location.params && props.location.params.state && props.location.params.state.count || null);
    const [ id, setId ] = useState (props && props.location && props.location.params && props.location.params && props.location.params.state && props.location.params.state.id || null);

    const [ userSegment, setUserSegment ] = useState ({});
    const [ saveOpen, setSaveOpen ] = useState (false);
    const [ open3 , setOpen3] = useState(false);
    const [ open2 , setOpen2] = useState(false);

    // useEffect (() => {
    //     if (segmentId !== null)  {
    //         setLoading (true);
    //         merchantApiService.fetchUserSegment (segmentId)
    //         .then (response => {  
    //             if (! response || ! response.data) { return HWMessage.show ("Unable to fetch user segment","error"); }
    //             setUserSegment (response.data);
    //             HWMessage.show("Request Successful" , "success");
    //         })
    //         .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    //     })
    //         .finally (() => setLoading (false));
    //     }
    // }, [ segmentId ]);

  
    const history = useHistory();
    const handleSegmentSave = () => {  
        if (userSegment.name === null || userSegment.name === '') 
            return HWMessage.show ("Please Specify the segment name before saving","warning");
        var success = null;
            setLoading (true);
            merchantApiService.updateUserSegment ({id,segmentName}) 
            .then (response => {
                if (! response || ! response.data) { return HWMessage.show ("Error while updating segment","error"); }
                success = true;
                setOpen3(true);

                HWMessage.show ("Updated Successfully","success");
            }).catch (error => { 
                HWMessage.show ("Error while updating segment","error") 
                success = false;
            })
            .finally (() => { setLoading(false); });
        
    };

    const handleTextChanges = event => {
        console.log(event.target.value);
        setSegmentName (event.target.value);
    };
    const handleCancel = event => { history.goBack (); };
   
    return (
        <Fragment>
            
                <BlockUi blocking = { loading } message = { 'Loading Segment' } >
                    <GridContainer>  
                        <GridItem xs = {6} md = {6} lg = {6} >
                            <Card className = { classes.card } >

                            <CardBody> 
                                    <GridItem xs = {12} md = {12} lg = {12} >
                                        <Card className = { classes.card } >
                                            <CardBody>  
                                                <h4> Update Segment Details </h4>
                                                <div style = {{ marginTop : 22 }} >
                                                    <HWInput 
                                                        fullWidth 
                                                        variant = 'outlined' 
                                                        label = 'Merchant Count' 
                                                        name = 'merchantCount' 
                                                        disabled = { true } 
                                                        value = { count } 
                                                        required = {true}
                                                    />
                                                </div>

                                                <div style = {{ marginTop : 22 }} >
                                                    <HWInput 
                                                        fullWidth 
                                                        variant = 'outlined' 
                                                        label = 'Segment Name' 
                                                        name = 'segmentName' 
                                                        onBlur = { e => { handleTextChanges(e); } } 
                                                        value = { segmentName } 
                                                        required = {true}
                                                    />
                                                </div>
                                            </CardBody>
                                            <CardActions style = {{ justifyContent : 'center' }} >  
                                                <Button style = {{ color : 'floralwhite', background : '#FF9017' , textTransform:'None'}} onClick={ e => setSaveOpen (true) } > {  'Update' } </Button>
                                                <Button style = {{ color : 'floralwhite', background : '#138DE8', textTransform:"none"}} onClick = { handleCancel } > Cancel </Button>
                                                <ConfirmationDialog open = { saveOpen } setOpen = { setSaveOpen } dialogTitle = { 'Confirm Saving Merchant Segment' } dialogMessage = { 'Are you sure you want to save this Merchant segment ?' } confirmingAction = { handleSegmentSave } />
                                                <StatusDialog isLoading = {false} open= { open3 } setOpen={setOpen3}  sucessStatus={"success"} confirmingAction={e=>history.goBack()} message={"Merchant Segment successfully updated."} dialogTitle={"Status"}/> 
                                                <StatusDialog isLoading = {false} open= { open2 } setOpen={setOpen2}  sucessStatus={"success"} confirmingAction={e=>history.goBack()} message={"Merchant Segment successfully created."} dialogTitle={"Status"}/> 
                                            </CardActions>
                                        </Card>
                                    </GridItem>
                                </CardBody>
                                </Card>
                                </GridItem>
                            </GridContainer>  
                </BlockUi>
           
        </Fragment>
    );
};  