import { Box, Button, Card, CardActions, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import CardHeader from 'components/Card/CardHeader';
import GridItem from 'components/Grid/GridItem';
import React, { useCallback, useEffect, useState } from 'react';
import { Fragment } from 'react';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import GridContainer from 'components/Grid/GridContainer';
import CardBody from 'components/Card/CardBody';
import Alert from '@material-ui/lab/Alert';
import CardIcon from 'components/Card/CardIcon';

import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import voucherRequestApiService from 'views/Actions/ApiService';
import HWMessage from 'HWLibrary/HWMessage';
import { HWInput } from 'HWLibrary/HWInput';
import BlockUi from 'react-block-ui';
import voucherConfigApiService from './APIService';
import { FadeLoader } from 'react-spinners';
import memberApiService from 'views/Members/APIService';

const useStylesHead = makeStyles (styles);


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));


function StatusDialog (props) {


    return (
        <React.Fragment>
        {
            props.raiseLoading && props.raiseLoading === true &&
            <FadeLoader />
        }
        {
            ! props.raiseLoading &&
            <Dialog open = {props.statusDialogOpen}  >
                <DialogTitle> Raising Request </DialogTitle>
                {
                props.createStatus &&
                <DialogContent>
                    { props.createStatus === true ? 'Request Raised for Vouchers' : 'Request being raised'}
                </DialogContent>
                }
                <DialogActions>  
                    <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { () => { props.resetRequest () ; props.setStatusDialogOpen (false); props.setCreateStatus (false);  } } > Reward More </Button>
                    <Button variant = "contained" style = {{ backgroundColor : '#ff9017', color : 'floralwhite', textTransform : 'none' }} onClick = { () => { props.setStatusDialogOpen(false); props.setDialogOpen (false); } } > Home </Button>
                </DialogActions>
        </Dialog>
        }
        </React.Fragment>
    );
}


export default function VoucherRewards (props) {

    const classes = useStyles ();
    const headClasses = useStylesHead();
    
    const handleChange = (event) => handleTextFieldChanges (event);

    const [ memberId, setMemberId ] = useState (props.memberId);
    const [ transactionType, setTransactionType ] = useState (props && props.transactionType || 'single');
    
    
    const [ reason, setReason ] = useState ('');
    const [ voucherCode, setVoucherCode ] = useState ('');
    
    const [ detailedReason, setDetailedReson ] = useState ('');
    const [ amount, setAmount ] = useState (-1);

    const [ raiseLoading, setRaiseLoading ] = useState (false);
    const [ createStatus, setCreateStatus ] = useState (false);
    const [ statusDialogOpen, setStatusDialogOpen ] = useState (false);

    const reasons = [ 'None', 'Purchase of Goods', 'Purchase of Bike', 'Campaign Reward', 'Campaign Provision', 'Seasonal Reward', 'Others' ];
    const [voucherCodes, setVoucherCodes] = useState ([  ]);
    const [ loading, setLoading ] = useState (false);
    const [ request, setRequest ] = useState ({  });

    
    const [ fetchedMember, setFetchedMember ] = useState ({});

    const resetRequest = () => setRequest ({ voucherPerUser : '', code : 'None', reason : 'None' });

    useEffect (() => {
        if (memberId) {
            
            memberApiService.fetchMemberDetails (memberId)
            .then (response => { 
                if (! response || ! response.data) {
                    HWMessage.show("This member details cannot be fetched" , "error");
                    return console.log("Error while fetching member");
                }
                HWMessage.show("Request Successful" , "success");
                setFetchedMember(response.data);
                
             })
             .catch (error => {
                HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");

                 return console.log("Error while fetching member", error);
             })
             .finally (() => { 
                var my_element = document.getElementById("top");
                if(my_element!== null || my_element!== undefined || my_element!== '')
                {
                    // my_element.scrollIntoView({ }); 
    
                }   
            
            });
        }
    }, []);


    useEffect (() => {
        setLoading (true);
        voucherConfigApiService.fetchCodes()
        .then (response => {  
            if (! response || ! response.data) {
                HWMessage.show ("Unable to fetch voucher codes at moment");
                return console.log("Error while fetching voucher codes");
            }
            var codes = response.data.voucherConfigModelList.map(i => i.code);
            codes.unshift ('None');
            setVoucherCodes (codes);
        })
        .catch (error => {  })
        .finally (() => setLoading (false));
    }, []);

    console.log("Getting the codes", voucherCodes);

    const handleTextFieldChanges = (event) => {
        console.log("Event", event);
        switch (event.target.name) {
            case 'code' : setRequest (previous => ({ ...previous, 'code' : event.target.value })); break;
            case 'voucherPerUser' :  setRequest (previous => ({ ...previous, 'voucherPerUser' : parseInt(event.target.value > 0 ? event.target.value : 0) }));   break;
            case 'reason' : setRequest (previous => ({ ...previous, 'reason' : event.target.value })); break;
            default : HWMessage.show ("Illegal field" , "error"); break;
        }
        console.log("Getting request", request);
    }

    const validateRequest = (request) => {
        console.log("Request ", request);
        var okRequest = true;
        if (!request) { HWMessage.show ("Request is empty, it must contain valid parameters" , "error"); okRequest = false; }
        else if (!request.code || request.code === null) { HWMessage.show("Request must contain valid voucher code", "error"); okRequest = false; } 
        else if (!request.voucherPerUser || request.voucherPerUser === null || request.voucherPerUser === 0) { HWMessage.show ('Vouchers Per User must be specified', "error"); okRequest = false; }
        else if (!request.reason || request.reason === null) { HWMessage.show("Select at least one of the reason for the request" , "error"); okRequest = false; }
        
        return okRequest;
    };
    const createVoucherRequest = useCallback((request) => {
        var isValidRequest = validateRequest (request);
        console.log("Raising request", isValidRequest);

        if (request != null && isValidRequest) {
            setRaiseLoading (true);
            voucherRequestApiService.createVoucherRequest ({...request, memberId : memberId, voucherRequestType : 'Single'}) 
            .then (response => {
                if (! response || !response.data) {
                    HWMessage.show ("Voucher Creation failed" , "error");
                    return console.log("Error while creating voucher request");
                }
                setCreateStatus (true);
                setRequest (null);
                HWMessage.show("Request raised successfully" , "success");
            })
            .catch (error => { 
                HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");

                return console.log("Error while raising request ", error);
            })
            .finally (() => setRaiseLoading (false));
        }
    }, []);

    

    return (
        <Fragment>
            <BlockUi blocking = { loading || raiseLoading } message = { loading === true ? "Loading Categories. Please Wait..." : raiseLoading === true ? "Raising Request for Voucher" : "" } tag = "div"  >
                
                <Card className='card-header-body' id="top">
                    <CardHeader icon>
                      <CardIcon color="primary" style = {{ marginTop : 0, background : '#138DE8' }} > <TransferWithinAStationIcon /></CardIcon>                      
                    </CardHeader>
                    <CardBody className='card-header-body'></CardBody>
                </Card>

                <Card>
                    <CardHeader color = "primary" icon > 
                        <GridContainer> 
                            <GridItem xs = {12} > 
                                <GridContainer>
                                    <GridItem xs = {12} >
                                        
                                        <h4 className = { headClasses.cardIconTitle } > Award Vouchers </h4>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs = {12}>
                                <GridContainer>
                                    <GridItem xs = {2} > <IconButton onClick = { () => {
                                        if (props.dialogVariant)
                                            props.setDialogOpen (false);
                                    } } > <KeyboardBackspaceIcon /> </IconButton> </GridItem>
                                    <GridItem xs = {8}  > <h4 className = { headClasses.cardIconTitle } > { transactionType === 'single' ? `Membership Number : ${ fetchedMember && fetchedMember.membershipNumber }` : `See List of selected merchants : ${ 1 }` } </h4> </GridItem>
                                    { transactionType !== 'single' && <GridItem xs = {2} > <IconButton> <KeyboardArrowDownIcon /> </IconButton> </GridItem>}
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <Divider />

                    <CardBody>
                        <GridContainer >
                            <GridItem xs = {12} > 
                                <center style =  {{ marginLeft : '257px' }} >     
                                    <HWInput
                                        type = { 'number' }
                                        label = { 'Vouchers to be rewarded' }
                                        placeholder = { 'Enter Number of Vouchers' }
                                        name = {'voucherPerUser'}
                                        //value = {(amount >= 0) ? amount : ''}
                                        value = { (request && request.voucherPerUser) }
                                        //onChange = { (event) => setAmount (event.target.value) }
                                        onBlur = { event => handleTextFieldChanges(event) }
                                        //onChange = { event => handleTextFieldChanges(event) }
                                        style = {{ width : '400px' }}
                                        required = {true}
                                    /> 
                                </center>   
                            </GridItem>
                            {/* <GridItem xs = {12} > 
                                <center> 
                                    <FormControl className = {classes.formControl} >
                                        <InputLabel  > Voucher Code </InputLabel>
                                        <Select
                                            value = { voucherCodes }
                                            onChange = { handleChange }
                                        >
                                            { voucherCodes.map (reason => (<MenuItem value = {reason} > { reason } </MenuItem>) )  }
                                        </Select>
                                    </FormControl>
                                </center>   
                            </GridItem> */}

                            {
                            voucherCodes && voucherCodes.length > 0 && 
                            <GridItem xs = {12} > 
                                <center> 
                                    <FormControl className = {classes.formControl} style = {{ width : '400px' }} >
                                        <InputLabel  > Voucher Codes </InputLabel>
                                        <Select
                                            name = {'code'}
                                            value = { request && request.code ? request.code : 'None' }
                                            onChange = { (event) => handleTextFieldChanges (event) }
                                            onBlur = { event => handleTextFieldChanges (event) }
                                            required = {true}
                                        >
                                            { voucherCodes.map (code => (<MenuItem value = {code} > { code } </MenuItem>) )  }
                                            
                                        </Select>
                                    </FormControl>
                                </center>   
                            </GridItem>
                            }

                            <GridItem xs = {12} > 
                                <center> 
                                    <FormControl className = {classes.formControl} style = {{ width : '400px' }} >
                                        <InputLabel  > Reason </InputLabel>
                                        <Select
                                            name = {'reason'}
                                            value = { request && request.reason ? request.reason : 'None' }
                                            onBlur = { event => handleTextFieldChanges (event) }
                                            onChange = { event => handleTextFieldChanges (event) }
                                            required = {true}
                                        >
                                            { reasons.map (reason => (<MenuItem value = {reason} > { reason } </MenuItem>) )  }
                                        </Select>
                                    </FormControl>
                                </center>   
                            </GridItem>
                            <GridItem xs = {12} style = {{ marginLeft : '100px', marginRight : '100px' }} >  
                                <TextField 
                                    placeholder = { 'Detailed reasoning for rewarding the voucher' }
                                    multiline = { true }
                                    rows = { 10 }
                                    rowsMax = { 20 }
                                    fullWidth
                                    variant = "outlined"
                                    label = { 'Detailed reasoning for rewarding the voucher' }
                                />
                            </GridItem>

                            <GridItem xs = {12} style = {{ marginTop : '15px' }} >
                                <Collapse in = { amount > 0 }>
                                    <Alert  style = {{ marginLeft : '100px', marginRight : '100px' }} fullWidth severity="info">
                                        Total Vouchers to be rewarded : { request && request.voucherPerUser }
                                    </Alert>
                                </Collapse>
                            </GridItem>
                        </GridContainer>
                    </CardBody>                
                    <CardActions style = {{ justifyContent : 'center' }} >
                        <Button onClick = { () => {  
                            var validRequest = validateRequest (request);
                            
                            if (validRequest) {
                                createVoucherRequest(request); 
                                setStatusDialogOpen(true); } 
                            }
                            } 
                            size = "large" 
                            variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }}
                        > 
                            Reward 
                        </Button>
                        <Button size = "large" variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} onClick = { () => {
                            if (props.dialogVariant) 
                                props.setDialogOpen (false);
                        } } > Cancel </Button>
                    </CardActions>
                </Card>
            </BlockUi>

            <StatusDialog 
                raiseLoading = {raiseLoading} 
                statusDialogOpen = { statusDialogOpen } 
                setStatusDialogOpen = {setStatusDialogOpen} 
                setDialogOpen = { props.setDialogOpen }
                createStatus = { createStatus }
                setCreateStatus = { setCreateStatus }
                resetRequest = { resetRequest }
            />

        </Fragment>
    );
};