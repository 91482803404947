import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup, TextField } from '@material-ui/core';
import { People } from '@material-ui/icons';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState, useEffect,Fragment } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { getNameOfDeclaration } from 'typescript';
import { DataGrid } from '@material-ui/data-grid';
import { Redirect, useHistory } from 'react-router-dom';
import memberApiService from 'views/Members/APIService';
import HWMessage from 'HWLibrary/HWMessage';
import merchantApiService from 'views/Merchant/APIService';
import { Autocomplete } from '@material-ui/lab';
import userSegmentApiService from 'views/UserSegment/APIService';
import "./AudienceSelection.css";
import { useRef } from 'react';
import { Checkbox } from '@material-ui/core';
import SearchBar from 'views/Merchant/SearchBar';

const useStyles = makeStyles (theme => ({
    ...styles,
    cardFooter : { justifyContent : 'center', justifyItems : 'center' },
    applyButton : { textTransform : 'none', color : 'floralwhite', background : '#138DE8', margin: 2.5 , width : 200, height : 50, fontSize : 20 },
    cancelButton : { textTransform : 'none', color : 'floralwhite', background : '#FF9017',margin: 2.5 , width : 200, height : 50, fontSize : 20 },

    cardHeader : { background : '#138DE8' },
    cardIcon : { background : '#138DE8' },
    bottomGrid : { marginTop : 20 },
    '& .MuiFormLabel-root' : { marginBottom : 0 },
    redirectButton : { color : 'floralwhite', beckground : '#FF9017' }
}));

const AudienceEnum = Object.freeze({ member : 'Member', merchant : 'Merchant' });

export function SearchUserDialog (props) {

    const classes = useStyles ();

    const [ selectedList, setSelectedList ] = useState ([]);
    const [ listLoading, setListLoading ] = useState (false);

    const [ rows, setRows ] = useState ([]);
    const [ page, setPage ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ rowCount, setRowCount ] = useState (0);
    const [ searchText, setSearchText ] = useState ('');
    const [ filterModel, setFilterModel ] = useState ('');
    const [ refreshState, setRefreshState ] = useState (false);
    const [ selectionModel, setSelectionModel ] = useState ([]);
    const [ allRows, setAllRows ] = useState ([]);
    const [ selectionModelNew, setSelectionModelNew ] = useState ({ rowIds : [] });
    const prevSelectionModel = useRef(selectionModel);
    const [ selectionModelMap, setSelectionModelMap ] = useState ({});
    const [ firstSortId, setFirstSortId] = useState();
    const [ lastSortId, setLastSortId ] = useState();
    const [ pageDelta, setPageDelta ] = useState(0);

    const appendRows = (list) => {
        let result = [];
        console.log ("Listing", list);
        
    }

    useEffect (() => {
        if (props && props.userType !== null) {
            var { userType } = props;
            console.log(userType);
           // mnhgfz if (userType != 'Member' && userType != 'Merchant') { return HWMessage.show ("Invalid user type selected"); }

            setListLoading (true);
            var paginationBookmark = (page) * pageSize;
            var fetchLimit = pageSize;

            if (userType === 'Member') {
                memberApiService.fetchActiveMembers ({ paginationBookmark, fetchLimit, searchText, filterModel, pageDelta, firstSortId, lastSortId })
                .then (response => {  
                    if (! response || ! response.data) { HWMessage.show ("List cannot be fetched at momeent"); }
                    setRowCount (response.data.totalMembers);
                    setFirstSortId (response.data.firstSortId);
                    setLastSortId (response.data.lastSortId);
                    setRows (response.data.members);

                    let result = allRows;
                    for (var i = 0; i < response.data.members.length; i++) 
                        if (allRows.indexOf(response.data.members[i]) === -1) 
                            result.push (response.data.members[i]);
                    setAllRows (result);
                    setSelectionModelMap ({  ...selectionModelMap,  [page] : selectionModelMap[page] || [] });
                    setTimeout(() => { setSelectionModel( selectionModelMap[ page ] || [] ); });
                    HWMessage.show("Request Successful" , "success");
                }).catch (error => { HWMessage.show ("Members cannot be loaded at moment"); })
                .finally (() => { setListLoading (false);})
            } 
            else if (userType === 'Merchant') {
                merchantApiService.fetchActiveMerchants (fetchLimit, paginationBookmark, filterModel, null, searchText)
                .then (response => {  
                    if (! response || ! response.data) { return HWMessage.show ("Error while fetching the response"); }
                    setRowCount (response.data.merchantsCount);
                    setRows (response.data.merchantsList)

                    let result = allRows;
                    for (var i = 0; i < response.data.merchantsList.length; i++) 
                        if (allRows.indexOf(response.data.merchantsList[i]) === -1) 
                            result.push (response.data.merchantsList[i]);
                        setAllRows (result);
                        setSelectionModelMap ({  ...selectionModelMap,  [page] : selectionModelMap[page] || [] });
                        setTimeout(() => { setSelectionModel( selectionModelMap[ page ] || [] ); });     
                        HWMessage.show("Request Successful" , "success");
                }).catch (error => { return HWMessage.show ("Error while fetching response"); })
                .finally (() => { setListLoading (false); })
            }
        }
    }, [ props.userType, refreshState, pageSize, searchText, page, pageSize ]);


    const handleExclusionInclusionClick = id => {

        console.log("Selections", selectionModel, selectionModelMap);

        var idx = selectionModel.indexOf (id);
        if (idx !== -1) {
            selectionModel.splice (idx, 1);
            setSelectionModel ([ ...selectionModel ]);
            let currentMap = selectionModelMap [ page ];
            var mapIdx = currentMap.indexOf(id);
            if (mapIdx !== -1) 
                currentMap.splice (mapIdx, 1);
            selectionModelMap [ page ] = currentMap;
            setSelectionModelMap ({ ...selectionModelMap });
        } else {
            selectionModel.push (id);
            setSelectionModel ([ ...selectionModel ]);
            console.log("page",page);
            
            let currentMap = selectionModelMap [ page ];
            console.log("currentMap",currentMap);
                currentMap.push (id);
            
            selectionModelMap [ page ] = currentMap;
            setSelectionModelMap ({ ...selectionModelMap });
        }
    };
    console.log("Selection model",selectionModel);
    const columns = [
        
        { field : 'membershipNumber', headerName : 'Membership Number', filterable : true, sortable : true, width : 200 },
        { field : 'code', headerName : 'Merchant Code', filterable : true, sortable : true, width : 200 },
        { field : props && props.userType === 'Merchant' ? 'entityName' : 'name', headerName : props && props.userType === 'Merchant' ? 'Entity Name' : 'Name', filterable : true, sortable : true, width : 200 },
        { field : 'action', headerName : 'Action', renderCell : cellParams => selectionModel && <Checkbox  style = {{ color : '#138DE8' }} onClick = { e => handleExclusionInclusionClick (cellParams.row.id) } checked = { selectionModel.indexOf (cellParams.row.id) !== -1 } /> }

    ];

    const handleCheck = () => {  };
    const handleClose = event => { setSearchText('');props.setOpen(prev => !prev); };


    const history = useHistory();
    const handleNext = event => {
        props.setOpen (false);
        console.log("next", selectionModel, props.currentRequest);

        // let selectedRows = allRows.filter (i => props.currentRequest.groupIds.indexOf(i.id) !== -1);
         var selectedUniqueIds = [];
         var selectedRowsUnique = [];

       

        var req = props.currentRequest;
       
         
        let selectedRows = [] ;
        Object.values(selectionModelMap).forEach(i =>  { selectedRows.push(...i) }  ) ; 
        for (var i = 0; i < selectedRows.length; i++) {
            for (var j = 0; j < allRows.length; j++) {
                if (allRows[j].id === selectedRows[i] && selectedUniqueIds.indexOf(selectedRows[i]) === -1) {
                    selectedUniqueIds.push (selectedRows[i]);
                    selectedRowsUnique.push (allRows[j]);
                }
            }
        }
        req.groupIds = selectedRowsUnique;

        console.log("req", req);
        props.setCurrentRequest (req);
        console.log(props.currentRequest, rows);
        history.push({ pathname : "reward-points", state : { currentRequest : props.currentRequest, createMode : true, groupIds : selectedRowsUnique, prev : 'audience-selection' }}); 
    };

    console.log("For selections", props, props.userType);
    console.log(columns.filter (i => (props.userType === 'Member' ? i !== 'code' : i !== 'membershipNumber'))); 

    return (
    <Dialog open = { props.open } onClose = { handleClose }  >
        <DialogTitle  > Select Members for the Group </DialogTitle>
        <DialogContent>  
        <GridItem>  
                    <SearchBar 
                        width = '250px' 
                        handleSearch = { setSearchText }
                        handleRefresh = { setRefreshState }
                        refreshState = { refreshState }
                        placeholder = "Search on all details"
                    /> 
                    <br/>
                </GridItem>
            <DialogContentText> Select one or more users from the list to award. Please press next button once done </DialogContentText>
            <GridContainer>
                <GridItem xs = {12} md = {12} sm = {12} >  
                <DataGrid className={classes.root}
                    
                    loading = { listLoading }
                    page = { page }
                    pageSize = { pageSize }
                    rows = { rows }
                    rowCount = { rowCount }
                    columns = { columns.filter (i => (props.userType === 'Member' ? i.field !== 'code' : i.field !== 'membershipNumber')) }
                    pagination
                    paginationMode = 'server'
                    onPageChange = { changeParams => {setPage (changeParams); setPageDelta (changeParams-page)} }
                    onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams); } }

                    filterMode = 'server'
                    //onFilterModelChange = { params => { if (params.filterModel.items[0].value) setFilterModel(params.filterModel.items[0]); } }
                    density = 'compact'
                    autoHeight
                    rowsPerPageOptions = { [ 10, 25, 50, 100 ] }
                    disableExtendRowFullWidth = { false }
                    // onRowSelected = { gridParam => {
                    //     if (gridParam.isSelected === false) {  
                    //         console.log ("GHSDKSADSA", gridParam);
                    //         props.setCurrentRequest (prev => {
                    //             prev.groupIds = prev.groupIds.filter (i => gridParam.data.id !== i);
                    //             return { ...prev };
                    //         })
                    //     }
                    // } }
                    // onSelectionChange = { newSelection => {  
                    //     console.log (newSelection);
                    //     setSelectionModel (newSelection.rowIds);     
                    //     props.setCurrentRequest(prev => { 
                    //         let groupIds = prev.groupIds || [];
                    //         for (var i = 0; i < newSelection.rowIds.length; i++)
                    //             if (groupIds.indexOf(newSelection.rowIds[i]) === -1) 
                    //                 groupIds.push (newSelection.rowIds[i]);
                    //         prev.groupIds = [ ...groupIds ];
                    //         console.log ("Prev", prev.groupIds);
                    //         console.log("New Selection Row IDS:" , newSelection.rowIds )
                    //     return {...prev};
                    //  });
                    // }}
                    onPageChange={(newPage) => {
                        console.log(selectionModel, prevSelectionModel);
                        prevSelectionModel.current = selectionModel;
                        var existing = selectionModelMap[ newPage ] || [];
                        setSelectionModel (prev => [ ...existing ]);
                        setTimeout(() => { setSelectionModel (prev => [ ...existing ]); });
                        console.log("Restored", page, selectionModelMap, selectionModel);
                        setSelectionModelNew ({ rowIds : [ ...existing ] });
                        setPage(newPage);
                        setPageDelta (newPage-page);
                    }}
                    // selectionModel = { selectionModel }
                />
                </GridItem>
            </GridContainer>
        </DialogContent>
        <DialogActions> 
            <center>
                <Button style = {{ background : '#FF9017', color : 'floralwhite', height : 40, width : 100 }} variant = 'contained' onClick = { handleNext } > Next </Button>
            </center>
        </DialogActions>
    </Dialog>
    );
};

export default function AudienceSelection (props) {
    const classes = useStyles();
    const history = useHistory();

    const getLocationParams = () => {
        console.log("Props", props.location.state);
        if (props && props.location && props.location.state)  { return props.location.state; }
        else return {};
    };
    const locationParams = getLocationParams ();

    const [ currentRequest, setCurrentRequest ] = useState (locationParams.currentRequest !== null ? locationParams.currentRequest : {});
    const [ requestAudience, setRequestAudience ] = useState ();
    const [ requestCategory, setRequestCategory ] = useState ();
    const [ check, setCheck ] = useState (false);
    
    const [ segmentLoading, setSegmentLoading ] = useState (false);
    const [ segmentList, setSegmentList ] = useState ([]);

    useEffect (() => {
        setSegmentLoading (true);
        userSegmentApiService.fetchActiveSegmentList ()
        .then (response => { 
            if (! response || ! response.data) { return HWMessage.show ("Data cannot be fetched at moement","error"); }
            setSegmentList (response.data.userSegments);     
            console.log(response.data);
        }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() => setSegmentLoading (false));
}, [ ]);


    const getOptionsForCategory = requestCategory => {
        const allOptions = [ 'All', 'Group', 'Segment' ];
        return requestCategory !== null ? requestCategory === AudienceEnum.member ? allOptions : allOptions.filter(i => i !== 'Segment') : [];
    };
    const getDisplayName = (audienceOption, requestCategory) => {
        const addendum = `${ requestCategory === AudienceEnum.member ? 'Member' : 'Merchant' }`;
        switch (audienceOption) {
            case 'All' : return `All ${addendum}s`;
            case 'Group' : return `${addendum} Group`;
            case 'Segment' : return `${addendum} Segment`;
        }
    };

    const changeRequestCategory = event => {
            console.log("Event", event, event.target.value); 
            
            var value = event.target.value; 
            setCurrentRequest (prev => ({ ...prev, requestCategory : value, groupIds : [] })); 
            setRequestCategory (prev => value); 
    };
    const changeRequestAudience = event => { 
        console.log("Event", event, event.target.value); 
        var value = event.target.value;  
        setCurrentRequest (prev => ({ ...prev, requestAudience : value }));
        setRequestAudience (event.target.value); 
    };
     
    const checkAudience = (currentRequest) => {

        if(currentRequest && currentRequest.requestAudience && currentRequest.requestAudience === 'Segment' && currentRequest.segmentId) 
        { history.push({ pathname : "reward-points", state : { currentRequest : currentRequest, createMode : true, prev : 'audience-selection' }}) }
        else if (currentRequest && currentRequest.requestAudience && (currentRequest.requestAudience === 'Group' || currentRequest.requestAudience === 'All' ))
        { history.push({ pathname : "reward-points", state : { currentRequest : currentRequest, createMode : true, prev : 'audience-selection' }}) }
        else {
            HWMessage.show ("Select Segment","warning");
        }
    }



    const handleApply = e => {  
        if (requestAudience === 'Group') { setCheck (prev => true); }
        else if (requestAudience == "All")  {  history.push({ pathname : "reward-points", state : { currentRequest : currentRequest, createMode : true, prev : 'audience-selection' }});  }
        else { checkAudience(currentRequest); }
    }
    const handleCancel = () => { history.goBack(); };
    return (
        <Fragment>
            <GridContainer >
                <GridItem xs = {12} sm = {12} md = {12} >
                    <Card>
                        <CardHeader className = { classes.cardHeader } color = 'rose' icon >
                            <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        {  locationParams.currentRequest.requestType === "Accrue"  ?  <h4 className = { classes.cardIconTitle } > Reward Points </h4> : <h4 className = { classes.cardIconTitle } > Deduct Points </h4>}    
                                     </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem>
                                    <FormControl component = "fieldset" >
                                            <FormLabel component = "legend" > Category Selection  </FormLabel>
                                            <RadioGroup row aria-label = "user-type" name = "userType" value = { currentRequest.requestCategory } onChange = { changeRequestCategory } > 
                                                <FormControlLabel value = {AudienceEnum.member} control = { <Radio  style={{color :"#138DE8"}} />  } label = { 'Member' } />  
                                                <FormControlLabel value = { AudienceEnum.merchant } control = { <Radio  style={{color :"#138DE8"}}/>  } label = { 'Merchant' } />  
                                            </RadioGroup>
                                    </FormControl>  
                                </GridItem >
                                { currentRequest && currentRequest.requestAudience !== null &&  <GridItem className = { classes.bottomGrid } xs = {12} md = {12} sm = {12} >  
                                    
                                        <FormControl component = "fieldset" style = {{ marginTop : -14 }} >
{            currentRequest.requestCategory && <FormLabel component = "legend" > {locationParams && locationParams.currentRequest && locationParams.currentRequest.requestType &&  locationParams.currentRequest.requestType === "Deduct" ? "Deduct Points" : " Reward Points" }  </FormLabel>
}                                            <RadioGroup  aria-label = "request-category" name = "requestCategory" value = { currentRequest.requestAudience } onChange = { changeRequestAudience } > 
                                                { 
                                                    requestCategory && getOptionsForCategory(requestCategory) && 
                                                    getOptionsForCategory (requestCategory).map(i => (<FormControlLabel value = { i } control = { <Radio style={{color :"#138DE8"}}/>  } label = { getDisplayName (i, requestCategory) } />  ))
                                                }
                                            </RadioGroup>
                                        </FormControl>  
                                    </GridItem>
                                }
                                <GridItem>  
                                    {
                                        currentRequest && currentRequest.requestAudience === 'Segment' && currentRequest.requestCategory === 'Member' &&
                                        <Autocomplete 
                                            className = { classes.autoComplete }
                                            options = { segmentList } 
                                            getOptionLabel={(option) => {
                                                
                                                return (option.name && option.name !== null) ? option.name : '' ;
                                            }}
                                            renderOption={(option) => option.name}
                                            onChange = { (event, value, reason) => {
                                                if (reason === "select-option") {
                                                    console.log("sadasdasdasdasdsadsad", value);
                                                    let list = segmentList;
                                                    let userSegmentId = null;
                                                    if ( list && list.length > 0) { list = list.filter(i => i.name === value.name); }
                                                    if (list.length > 0) { userSegmentId = list[0].id; }

                                                    if (userSegmentId !== null) {  setCurrentRequest (prev => ({ ...prev, segmentId : userSegmentId })); 
                                                    setCurrentRequest (prev => ({ ...prev, memberCount : value.memberCount }) );

                                                }
                                                }
                                            } }
                                            style = {{ width : 300, marginTop : 25 }} 
                                            renderInput = { params => <TextField { ...params } label = { 'Segment Selector' } onChange = { event => { segmentList.shift(); segmentList.unshift(event.target.value); setSegmentList (segmentList); } }  variant = 'outlined' /> } 
                                        />
                                    }
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter className = { classes.cardFooter } >  
                            <center>
                                <Button variant = 'contained' className = { classes.applyButton } onClick = { handleApply } > Apply  </Button>
                                <Button variant = 'contained' className = { classes.cancelButton } onClick = { handleCancel } > Cancel </Button>

                            </center>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>

            <SearchUserDialog 
                open = { check } 
                setOpen = { setCheck }
                userType = { currentRequest.requestCategory } 
                currentRequest = { currentRequest }
                setCurrentRequest = { setCurrentRequest }
            />
            {/* <SearchSegmentDialog 
                open = { checkSegment }
                userType = { requestCategory }
                setOpen = { setCheckSegment }
            /> */}
        </Fragment>
    );
};