import { makeStyles, TextField} from '@material-ui/core';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Fab } from '@material-ui/core';
import RuleForm from './MembershipCategoryRuleForm';

import React, { useState, useEffect, Fragment, useCallback } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { Button,  IconButton , Popover } from '@material-ui/core';
import { DataGrid , GridToolbar} from '@material-ui/data-grid'
import { Edit, MoreVert, People, Save } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import BlockUi from 'react-block-ui';
import HWMessage from 'HWLibrary/HWMessage';
import { useHistory } from 'react-router-dom';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 
import { GridAddIcon } from '@material-ui/data-grid';
import settingsApiService from './APIService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import SearchBar from 'views/Merchant/SearchBar';
import { Autocomplete } from '@material-ui/lab';
import { CustomToolbar } from 'views/Members/Member';
const useStyles = makeStyles(theme => ({
    ...styles,
    cardFooter : { justifyContent : 'center', justifyItems : 'center' },
    cardHeader : { background : '#138DE8'  },
    cardIcon : { background : '#138DE8' },
    bottomGrid : { marginTop : 15 },
    '& .MuiFormLabel-root' : { marginBottom : 0 },
    editIcon : { color : '#138DE8' }
}));

function RuleDialog (props) {
    
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ ruleDescription, setRuleDescription ] = useState ({});

    const handleClose = event => {props.setOpen (false) ; props.setDirectActionOpen(false) };
    return (
        <>
            <Dialog open = { props.open } onClose = { handleClose }  >
                <RuleForm refreshState = { props.refreshState } setRefreshState = { props.setRefreshState } rules = { props.rules } setRules = { props.setRules } createMode = { props.createMode } ruleId = { props.ruleId } open = { props.open } setOpen = { props.setOpen } setDirectActionOpen = {props.setDirectActionOpen} List ={props.List} />
            </Dialog>
        </>
    );
}



function ActionPopover (props) {
    return ( 

                <div>
                <Popover
                    open={ props.open }
                    anchorEl={ props.anchorEl}
                    onClose={  (event) =>  props.handleClose (false)  }
                >
                    <div >
                        <div style = {{ margin : '20px' }} > 
                            <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' , margin: 5 }} onClick = {event => props.handleEdit() }  > Edit </Button> 
                            <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} onClick = { event => props.handleDelete() } >  Delete </Button> 
                        </div> 
                    </div>
                </Popover>
            </div>
    
    );
};



export default function MembershipCategory (props) {

    var settings=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    settings= [...obj["Settings"]];
    if(HWLocalStorage.load("previousComSettingsTab")!== null){
        HWLocalStorage.clear("previousComSettingsTab")
    }
    if(HWLocalStorage.load("previousRolesSettingsTab")!== null){
        HWLocalStorage.clear("previousRolesSettingsTab")
    }
    HWLocalStorage.save (2, "previousSettingsTab")

    const [ sortModel, setSortModel ] = useState ({});

   const classes = useStyles();
   const [ rows, setRows ] = useState ([]);
   const [ page, setPage ] = useState (0);
   const [ pageSize, setPageSize ] = useState (100);
   const [ filterModel, setFilterModel ] = useState (null);
   const [ rowCount, setRowCount ] = useState (0);
   const [ openDirectAction, setOpenDirectAction ] = useState (false);
   const [ selectedRequest, setSelectedRequest ] = useState (null);
   const [ searchText, setSearchText ] = useState ('');
   const [creatorMode , setCreatorMode ] = useState(false);
   const [ anchorEl, setAnchorEl ] = useState (null);
   const [ actionOpen, setActionOpen ] = useState (false);
   const [loading, setLoading ] = useState(false);
   const [ directActionOpen , setDirectActionOpen ] = useState(false);
   const [ refreshState, setRefreshState ] = useState (false);
   const [ selectedRuleId, setSelectedRuleId ] = useState ();
   const [ ruleOpen, setRuleOpen ] = useState (false);
   const [ createMode, setCreateMode ] = useState (false);
   const [ List, setList ] = useState ([]);
   const [ currentRule, setCurrentRule] = useState ({});
    const [edit, setEdit] = useState(false);


   const openRule = () => { setRuleOpen (true); };
   const addRule = (event) => { setCreateMode (prev => true); setRuleOpen (prev => true); };
   const handleClick = (event,id) => { openRule(); };


const checkPriorDelete = deleteObject => {
    // Check for any existing rules which may be applicable for the time being
    return true;
};

  
const [ deleteItemState , setDeleteItemState ] = useState('');

   const deleteRule = useCallback (id => {
       if (id != null) {
        setLoading (true);
        settingsApiService.deleteSpecificMembershipCategorry (id) 
           .then (response => {
               if (!response || !response.data) { return HWMessage.show ("Error while deleteing the rule","error"); }
               setRowCount (rowCount - 1);
               setRows (prev => { prev = prev.filter (i => i.id !== id); return [ ...prev ] });
               setRefreshState(prev=>!prev);
               HWMessage.show("Request Successful" , "success");
           })
           .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
           .finally (() => { setLoading (false) });
       }
   });

const handleDelete = (id) => {

        deleteRule (id)
}

var tierList= [];

useEffect (() => {
    setLoading (true);
    settingsApiService.getControlTier ()
    .then (response => {
        if (!response || !response.data){
            HWMessage.show("Data can't be fetched at the moment" , "error");
            return console.log("No relevant data can be fetched");
        }
        setCurrentRule (response.data);
    
    })
    .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
})
    .finally (() => { setLoading (false);
        
    });
}, [ ]);


const updateTier = () => {

    setLoading (true);
    settingsApiService.updateControlTier (currentRule.tierId)
    .then (response => {
        if (!response || !response.data){
            HWMessage.show("Data can't be fetched at the moment" , "error");
            return console.log("No relevant data can be fetched");
        }
        setCurrentRule (response.data);
    
    })
    .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
})
    .finally (() => { setLoading (false);
        
    });

}


useEffect (() => {
    setLoading (true);
    var paginationBookmark = ((page) * pageSize);
    var fetchLimit = pageSize;
   
    settingsApiService.fetchMembershipCategory ({fetchLimit, paginationBookmark, filterModel , sortModel , searchText})
    .then (response => {
        if (!response || !response.data){
            HWMessage.show("Data can't be fetched at the moment" , "error");
            return console.log("No relevant data can be fetched");
        }
        setRows (response.data.tiers);
        setRowCount(response.data.totalTiers);
        HWMessage.show("Request Successful" , "success");
        
        tierList = response.data.tiers.map(i => ({ id : i.id, tierName : i.tierName, bikeQuantity : i.bikeQuantity }));
        ObjectInsertion(tierList);
        setList(tierList);


    })
    .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
})
    .finally (() => { setLoading (false);
        var my_element = document.getElementById("top");
        if(my_element!== null || my_element!== undefined || my_element!== '')
            {
                // my_element.scrollIntoView({ }); 

            }
    });
}, [ page, pageSize ,  refreshState , filterModel , sortModel , searchText ]);


const ObjectInsertion = (tierList) => {
    var present = false; 
    for(var i=0 ; i<tierList.length ;i++)
    {
        if(tierList[i].tierName === "None")
        {
                present = true ;
        }
    }

    var noneObject = { id : null, bikeQuantity : null, tierName : 'None' };

    if(present === true)
    {

    }
    else{
        tierList.push(noneObject);
    }

}



const handleEdit = (id) =>{
    setCreateMode (prev => false); 
    setRuleOpen (prev => true);
} 


    const columns = [
       { field : 'tierName',          headerName : 'Membership Tier',    filterable : true,  sortable : true, flex : 2.5},
       { field : 'lowerTier',          headerName : 'Downgrade Path',          filterable : true,  sortable : true, flex : 2.5,  renderCell : cellParams => { return <span>{cellParams && cellParams.row && cellParams.row.lowerTier && cellParams.row.lowerTier.tierName ? cellParams.row.lowerTier.tierName : ""} </span>}   },
        { field : 'upperTier',          headerName : 'Upgrade Path',          filterable : true,  sortable : true, flex : 2.5 , renderCell : cellParams =>  {  return <span>{cellParams && cellParams.row && cellParams.row.upperTier && cellParams.row.upperTier.tierName ? cellParams.row.upperTier.tierName : ""} </span>}  },

       { field : 'bikeQuantity',      headerName : 'Bike Quantity',     filterable : true,  sortable : true, flex :2 },
       { field : 'processFlag',      headerName : 'ProcessFlag',     filterable : true,  sortable : true, flex :2 },
       { field : 'createTime', headerName : ' Create Date', filterable : false , width : 170, renderCell : cellParams => {
        var date = cellParams.row.createTime;
        var dateString = date.split('T');
        var d = new Date (dateString).toLocaleDateString();

        return <span> {d} </span>
    } },

    ];

    if(settings.includes("Action") === true){
        columns.push(       { field: 'Action', filterable :  false ,sortable:false, headerName : '', flex : 2, renderCell : (cellParams) => ( <>{ <IconButton style={{color : "#138de8"}} onClick = { (event)  => { setCreateMode (prev => false); setRuleOpen(true); setDeleteItemState(cellParams.row.id); setSelectedRuleId(prev => { return cellParams.row.id}); } } > <Edit /> </IconButton> } </> )}
        )
    }

      const useDataGridStyles = makeStyles(theme => ({
    root: {
        '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
        '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
        '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
        '& .MuiButton-label' : { color : '#138DE8' }
    }
  }));

const dataGridClasses = useDataGridStyles();

    function CustomLoadingOverlay() {
        return (
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
      }

   
   const history = useHistory ();

    return (
        <Fragment>
            <BlockUi blocking = { loading } message = { 'Please wait while we fetch.' } >
                <Card id="top">
                    <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                        <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        <h4 className = { classes.cardIconTitle } > 
                            Membership Category
                        </h4> 
                    </CardHeader>
                    <CardBody>  
                        <GridContainer style = {{ justifyContent : 'space-between' }} >
                                <GridItem style = {{display:'flex', justifyContent : 'space-between' }} >  
                                    <Autocomplete
                                                value={ currentRule.tierName ? currentRule.tierName : 'None'  }
                                                    className = { classes.autoComplete }
                                                    disabled = { edit === true ? false : true}
                                                    options = { List.map(i => i.tierName)} 
                                                    getOptionLabel={(option) => { return option; }}
                                                    onChange = { (event, value, reason) => {
                                                        if (reason === "select-option") {
                                                            let tierId = List.filter(i => i.tierName === value   )   ;
                                                            
                                                            if (tierId.length !== 0 ) {  setCurrentRule (prev => ({ ...prev, tierId : tierId[0].id  })) }
                                                            var matchingList = List.filter (i => i.tierName === value);
                                                            var matchingOption = null;
                                                            if (matchingList && matchingList.length > 0) 
                                                                matchingOption = matchingList[0].tierName;  
                                                            if((matchingOption !== undefined || matchingOption  !== '') )
                                                                setCurrentRule (prev => ({ ...prev, TierName : matchingOption  }) )
                                                        }
                                                    } }
                                                    disableClearable	
                                                    style = {{ width : 300 }} 
                                                    renderInput = { params => <TextField { ...params } label = { 'Control Tier' }   variant = 'outlined' /> } 
                                        />
        {       settings.includes("Action") === true && edit === false && <a style={{cursor: "pointer" , margin: 5 , position: "relative" , top: 9}}><Edit style={{color: "#138de8"}} onClick={e=>setEdit(!edit)} /></a>
        }             
        {         settings.includes("Action") === true && edit === true && <a style={{cursor: "pointer" , margin: 5 , position: "relative" , top: 9}}><Save style={{color: "#138de8"}} onClick={e=>{setEdit(!edit); updateTier();} } /></a>
        }    
                            </GridItem>
                            <GridItem>
                               { settings.includes("Action") === true &&  <Fab onClick = { addRule } variant = 'extended' aria-label="add" style = {{  background : '#FF9017', color : 'white' }}  > <GridAddIcon  /> Add Tier</Fab>}
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem className = { classes.bottomGrid } xs = {12} md = {12} >
                                    <DataGrid 
                                        className = { dataGridClasses.root }
                                        page = { page }
                                        pageSize = { pageSize }
                                        rows = { rows }
                                        rowCount = { rowCount }
                                         columns = { columns }
                                        showToolbar
                                        pagination
                                        paginationMode = 'server'
                                        onPageChange = { changeParams => setPage (changeParams) }
                                        onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams); } }
                                        sortingMode="server" 
                                        onSortModelChange = { params => { 
                                          if (params.length > 0) 
                                              setSortModel (params[0]);
                                        } }
                                      
                                        filterMode = 'server'
                                        onFilterModelChange = { (params) => {
                                          if (params.items.length > 0) {
                                            setFilterModel (prev => ({ ...params.items[0] })); 
                                          }
                                        } }
                                        density = 'compact'
                                        autoHeight
                                        rowsPerPageOptions ={ [25, 50, 100 ]}
                                        components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }}
                                        disableExtendRowFullWidth = { false }
                                    />
                            </GridItem>
                        </GridContainer>

                    </CardBody>
               
                </Card>
                <RuleDialog     
               ruleId = { selectedRuleId }
               open = { ruleOpen }     
               setOpen = { setRuleOpen } 
               createMode = { createMode } 
               rules = { rows }
             //  setRules = { setRows }
               refreshState = { refreshState } 
               setRefreshState = { setRefreshState }
               setDirectActionOpen = {setDirectActionOpen}
               List ={ List }
               setList = {setList}
            />
            </BlockUi>
            
        </Fragment>
    );
};