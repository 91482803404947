import { Box, Button, Card, CardActions, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import CardHeader from 'components/Card/CardHeader';
import GridItem from 'components/Grid/GridItem';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import GridContainer from 'components/Grid/GridContainer';
import CardBody from 'components/Card/CardBody';
import Alert from '@material-ui/lab/Alert';
import CardIcon from 'components/Card/CardIcon';

import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router';
import {pointsRequestApiService} from './ApiService';
import HWMessage from 'HWLibrary/HWMessage';
import { FadeLoader } from 'react-spinners';
import { AlertTitle } from '@material-ui/lab';
import { useCallback } from 'react';
import DescriptionPopover from 'views/DescriptionPopup/DescriptionPopup';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import pointsApiService from 'views/Points/APIService';

const useStylesHead = makeStyles (styles);


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));


function StatusDialog (props) {

    return (
        <Dialog open = {props.statusDialogOpen}  >
            <DialogTitle> Raising Request </DialogTitle>
            <DialogContent>
                Raising Request for Points
            </DialogContent>
            <DialogActions>  
                <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { () => { props.setStatusDialogOpen (false);  } } > Reward More </Button>
                <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} onClick = { () => { props.setStatusDialogOpen(false); props.setDialogOpen (false); } } > Home </Button>
            </DialogActions>
        </Dialog>
    );
}


export default function ActionPoints(props) {

    
    const classes = useStyles ();
    const headClasses = useStylesHead();

   if (props && props.location && props.location.params && props.location.params.state) {
      var  value  =  props && props.location && props.location.params && props.location.params.state && props.location.params.state.pointDetail;
      HWLocalStorage.save (JSON.stringify(value), "pointsCategory");
   }
   


    //const [ voucherRequestType, setVoucherRequestType ] = useState (props && props.voucherRequestType);
    const [ memberList, setMemberList ] = useState ([]);
    const [ detailedReason, setDetailedReson ] = useState ('');
    const [ amount, setAmount ] = useState (4);

    const [ raiseLoading, setRaiseLoading ] = useState (false);
    const [ fetchLoading, setFetchLoading ] = useState (false);
    const [ statusDialogOpen, setStatusDialogOpen ] = useState (false);
    const [ canEdit, setCanEdit ] = useState (false);

    const [pointsDetail, setPointDetail] = useState (props && props.location && props.location.params && props.location.params.state && props.location.params.state.pointDetail || JSON.parse (HWLocalStorage.load ("pointsCategory")));
    const [ requestDetail, setRequestDetail ] = useState ({});

    const [ selectedVoucher, setSelectedVoucher ] = useState ({});
    const [ savedLoading, setSavedLoading ] = useState (false);

    const [ rejectionDescription, setRejectionDescription ] = useState ();
    const [ descriptionAnchorEl, setDescriptionAnchorEl ] = useState (null);
    const [ descriptionOpen, setDescriptionOpen ] = useState (false);

    const history = useHistory();
    const handleBack = () => {  
        var objectToBeCached = null;
        if (pointsDetail.approvalStatus === 'Pending') { objectToBeCached = {index : 0, title : 'REQUESTS PENDING', type : 'Pending' } }
        else if (pointsDetail.approvalStatus === 'Approved') { objectToBeCached = { index : 1, title : 'REQUESTS APPROVED', type : 'Approved' } }
        else if (pointsDetail.approvalStatus === 'Rejected') { objectToBeCached = { index : 2, title : 'REQUESTS REJECTED', type : 'Rejected' } }

        HWLocalStorage.save (objectToBeCached, "pointsCategory");
        history.goBack(); 
    }



    const updateRequest = useCallback ((pointsDetail) => {   
        setSavedLoading (true);
        pointsRequestApiService.updateRequest(pointsDetail)
        .then (response => { 
            if (!response || !response.data) {
                HWMessage.show("Error while saving the request " ,"error");
                return  console.log("Error while fetching the response for saving.");
            }
            setPointDetail (response.data);
            HWMessage.show("Request Successful" , "success");
         })
        .catch (error => { 
            HWMessage.show ("Request could not be resolved","error");
            return console.log("Error while fetching response");
         })
        .finally (() => { setSavedLoading (false); })
    }, [ ]);

    const approvalAction = () => {
        console.log(pointsDetail); 
        if (pointsDetail.approvalStatus !== 'Pending') {  
            HWMessage.show ("Request is already resolved" ,"info");
        } else {
            pointsDetail.requestStatus = 'Approved';
            updateRequest (pointsDetail);
        }
    };

    const rejectAction = () => {
        console.log(pointsDetail); 
        if (pointsDetail.approvalStatus !== 'Pending') {  
            HWMessage.show ("Request is already resolved","info");
            return;
        }
        pointsDetail.requestStatus = 'Rejected';
        updateRequest (pointsDetail);
    };  

  
   
    useEffect (() => {
        if (pointsDetail && pointsDetail.id) {
            setFetchLoading (true);
            pointsRequestApiService.fetchRequestDetails ( pointsDetail )
            .then (response => { 
                if (!response || !response.data) { 
                    HWMessage.show("Response cannot be fetched at moment" , "error"); 
                    return console.log("Response cannot be fetched at moment"); 
                }   
                setRequestDetail (response.data);
                console.log(response.data);
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");

        })
            .finally (() => { setFetchLoading (false); });
        } 
    }, [ ]);

    

    const handleRejectClick = event => {  setDescriptionOpen (true); setDescriptionAnchorEl (event.currentTarget); };

    return (
        <Fragment>
            { fetchLoading && <center style = {{ marginTop : '100px' }} > <FadeLoader color={'#138De8'} margin = {20} radius = {15} width = {20} height = {20}  /> </center> }
            { !fetchLoading && <>
                <Card className='card-header-body'>
                <CardHeader icon>
                    <CardIcon color="primary" style = {{ marginTop : 0,  background : '#138DE8' }} ><AddCircleIcon /></CardIcon>
                </CardHeader>
                <CardBody className='card-header-body'></CardBody>
                </Card>
                <Card>
                <CardHeader color = "primary" icon > 
                    <GridContainer> 
                        <GridItem xs = {12} > 
                            <GridContainer>
                                <GridItem xs = {12} >
                                    <h4 className = { headClasses.cardIconTitle } > Reward Points </h4>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs = {12}>
                            <GridContainer>
                                <GridItem xs = {2} > <IconButton onClick = { () => {
                                    if (props.dialogVariant)
                                        props.setDialogOpen (false);
                                    else 
                                        handleBack ()   
                                } } > <KeyboardBackspaceIcon /> </IconButton> </GridItem>
                                <GridItem xs = {8}  > <h4 className = { headClasses.cardIconTitle } > {  requestDetail.requestType === 'returning' ? `Raised By Merchant ID : ${ requestDetail.raisedByMerchantId }` : `See List of selected ${ requestDetail.requestCategory } : ${ requestDetail.groupIds }` } </h4> </GridItem>
                                { requestDetail.requestType !== 'returning' && <GridItem xs = {2} > <IconButton >  <KeyboardArrowDownIcon /> </IconButton> </GridItem> }
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </CardHeader>
                <Divider />

                { 
                requestDetail &&
                <CardBody>
                    <GridContainer justify = "space-between" >
                        <GridItem  > <h4> <b> Request Raised By : </b> { pointsDetail.id }  </h4>    </GridItem>
                        <GridItem > <h4> <b> Time : </b>   { new Date(pointsDetail.raisedDate).toLocaleString() } </h4>  </GridItem>
                    </GridContainer>

                    <Divider />

                    <GridContainer style = {{ marginTop : '15px' }} >
                        <GridItem xs = {12} > 
                            <GridContainer justify = "center" > 
                                    <GridItem style = {{ marginTop : '10px' }} > <InputLabel> Points Per User </InputLabel> </GridItem>
                                    <GridItem> 
                                        <TextField
                                            style = {{ width : '500px' }}
                                            disabled = { !canEdit }
                                            value = {(pointsDetail.pointsPerUser >= 0) ? pointsDetail.pointsPerUser : ''}
                                            onChange = { (event) => setAmount (event.target.value) }
                                        /> 
                                    </GridItem>
                                    { requestDetail.approvalStatus === 'Pending' && <GridItem > <IconButton onClick = { () => setCanEdit (!canEdit) } > <EditIcon /> </IconButton> </GridItem>}
                            </GridContainer>
                        </GridItem>
                        
                        <GridItem xs = {12} style = {{ marginTop : '15px' }} > 
                            <GridContainer justify = "center" > 
                                    <GridItem style = {{ marginTop : '15px' }} > <InputLabel> Description </InputLabel> </GridItem>
                                    <GridItem> 
                                        <TextField  
                                            style = {{ width : '500px' }}
                                            value = { pointsDetail.description }
                                            disabled
                                        /> 
                                    </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs = {12} style = {{ marginLeft : '100px', marginRight : '100px', marginTop : '15px' }} >  
                            <TextField 
                                multiline = { true }
                                rows = { 10 }
                                rowsMax = { 20 }
                                value = { (pointsDetail && pointsDetail.reason) }
                                fullWidth
                                variant = "outlined"
                                label = 'Detailed Reasoning for rewards'
                                defaultValue = "Summary of rewards"
                                InputLabelProps = {{ shrink : true }}
                            />
                        </GridItem>

                        <GridItem xs = {12} style = {{ marginTop : '15px' }} >
                            <Collapse in = { amount > 0 } >
                                <Alert  style = {{ marginLeft : '100px', marginRight : '100px' }} fullWidth severity="info">
                                    
                                    <GridContainer style = {{ marginLeft : '125px' }} >  
                                        
                                            <GridItem xs = {12}  >  
                                                    <GridContainer justify = "space-between"  > 
                                                        <GridItem> <b> Total Points </b>  </GridItem>
                                                        <GridItem> {  (pointsDetail.totalPoints) } </GridItem>
                                                    </GridContainer>
                                            </GridItem>
                                    </GridContainer>
                                   
                                </Alert>
                            </Collapse>
                        </GridItem>

                        
                        
                    </GridContainer>
                </CardBody> 
                }

                { 
                pointsDetail && pointsDetail.approvalStatus === 'Pending' &&
                <CardActions style = {{ justifyContent : 'center' }} >
                    {/* <Button onClick = { () => { setStatusDialogOpen(true); } } size = "large" variant = "outlined" style = {{ backgroundColor : 'green', color :'floralwhite' }} >  </Button>
                    <Button size = "large" variant = "outlined" style = {{ backgroundColor : 'red',  color : 'floralwhite' }} onClick = { () => {
                        if (props.dialogVariant) 
                            props.setDialogOpen (false);
                    } } > Cancel </Button> */}

                    <Button variant = "contained" onClick = { () => {                 
                       approvalAction(); 
                    } } 
                        style = {{ backgroundColor : '#138DE8', color : 'floralwhite' , textTransform : 'None' }}  
                    > 
                        Approve 
                    </Button>

                    <Button variant = "contained" onClick = { rejectAction }
                        style = {{ backgroundColor : '#FF9017', color : 'floralwhite' , textTransform : 'None'}} 
                    > 
                        Reject 
                    </Button>
                </CardActions>
                }

                {
                pointsDetail && pointsDetail.approvalStatus !== 'Pending' &&
                    <Alert severity = { pointsDetail.approvalStatus === 'Approved' ? 'success' : 'error' } >  
                        <AlertTitle> <h5> <b>  Request { pointsDetail.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } </b> </h5>  </AlertTitle> 
                        <h4> { pointsDetail.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } By : { pointsDetail.id } </h4>
                        <h4> { pointsDetail.approvalStatus === 'Approved' ? 'Approved' : 'Rejected' } At : { new Date(pointsDetail.resolvedDate).toLocaleDateString() } </h4>
                    </Alert>
                }   
            </Card>

            <StatusDialog 
                raiseLoading = {raiseLoading} 
                statusDialogOpen = { statusDialogOpen } 
                setStatusDialogOpen = {setStatusDialogOpen} 
                setDialogOpen = { props.setDialogOpen }
            />

        
            </>
            }           
        </Fragment>
    );
};





