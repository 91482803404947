import React, { useState } from "react";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  group: {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius
    }
  }
}));

export default function WeekDaySelector (props) {
  const classes = useStyles();
  const { days, handleDaysChange, name } = props;

  const handleFormat = (event, days) => {  
    handleDaysChange (typeof(days) === 'object' ? Object.values(days) :  days);
  };

  console.log("...", typeof(days) === 'object' ? Object.values(days) :  days);
  return (
    <ToggleButtonGroup value={ typeof(days) === 'object' ? Object.values(days) :  days } name = { name } onChange={handleFormat} aria-label="text formatting" className={classes.group} >
      <ToggleButton value="MONDAY" aria-label="bold" className={classes.toggleButton} > <span> Mon </span> </ToggleButton>
      <ToggleButton value="TUESDAY" aria-label="bold" className={classes.toggleButton} > <span> Tue </span> </ToggleButton>
      <ToggleButton value="WEDNESDAY" aria-label="bold" className={classes.toggleButton} > <span> Wed </span> </ToggleButton>
      <ToggleButton value="THURSDAY" aria-label="bold" className={classes.toggleButton} > <span> Thu </span> </ToggleButton>
      <ToggleButton value="FRIDAY" aria-label="bold" className={classes.toggleButton} > <span> Fri </span> </ToggleButton>
      <ToggleButton value="SATURDAY" aria-label="bold" className={classes.toggleButton} > <span> Sat </span> </ToggleButton>
      <ToggleButton value="SUNDAY" aria-label="bold" className={classes.toggleButton} > <span> Sun </span> </ToggleButton>
    </ToggleButtonGroup>
  );
}
