import { Button, TextField } from '@material-ui/core';
import React from 'react'
import notificationAPIService from 'views/Notification/APIService';
import { useState , useEffect } from 'react';
import HWMessage from 'HWLibrary/HWMessage';
import { HWInput } from 'HWLibrary/HWInput';


const New = () => {

    const [rows, setRows] = useState([]);
    const [initialState , setInitialState] = useState([]);
    const [ page, setPage] = useState(1);
  
        useEffect (() => {
            var paginationBookmark = 25;
            var fetchLimit = 25;
            
            notificationAPIService.fetchNotifications (fetchLimit, paginationBookmark)
            .then (response => {
            
                setRows (response.data.notifications);
               // setInitialState(response.data.notifications);
               
                setInitialState(JSON.parse(JSON.stringify(response.data.notifications) ) );            
            HWMessage.show("Request Successful" , "success");
            })
            .catch (error => {HWMessage.show (error.response.data.message || "Request could not be resolved", "error"); })
    

    }, [ page ]);



    const handleInputChange = (e ,idx) =>  {
      //  console.log(e.target.value);
        // setRows(prev=>({...prev , prev[idx].body : e.target.value })); 
        const newIds = rows.slice() //copy the array
        newIds[idx].body = e.target.value ;
        setRows(newIds);
         console.log(rows);
         console.log(initialState);

    }

    const handleReset = (e ,idx) =>  {
        setRows(initialState);
    }

    return (
        <div>
            {rows.map((i,idx) =>  
                     <>
                     <HWInput label = { 'Reactivates after expiry date' }  
                     name = { 'adred' }
                     value={i.body}
                     onBlur = { e => handleInputChange(e,idx)  }

                 /> 
                 <br/>
                 <Button onClick={e=>handleReset(e,idx)}>
                    Reset value
                 </Button>
                 </>

            )}
        </div>
    )
}

export default New
