import Card from 'components/Card/Card';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import BlockUi from 'react-block-ui';
import { DataGrid , GridToolbar} from '@material-ui/data-grid';
import { Box, Dialog, DialogTitle, FormControl, makeStyles, Modal, Paper , Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardIcon from 'components/Card/CardIcon';
import { People } from '@material-ui/icons';
import pointsApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 
import voucherConfigApiService from './APIService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import SearchBar from 'views/Merchant/SearchBar';
import { HWInput } from 'HWLibrary/HWInput';
import { CustomToolbar } from 'views/Members/Member';

const useStyles = makeStyles(() => ({
    paper: { minWidth: "300px" },
  }));


function DetailsPopup (props) {
    console.log(props);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');
  
      const classes = useStyles ();

    return ( 
        <Dialog  classes={{ paper: classes.paper}}  onClose={e=> props.setOpen(false)} open={props.open} fullWidth={true} maxWidth={"sm"}>
                <center><DialogTitle>Voucher History</DialogTitle></center>     

      
                <div style={{margin: 5 }}>
                <HWInput  label = "Reference Number" value = {props  && props.details && props.details.referenceNumber }  disabled = { true } />   
               <HWInput  label = "Id" value = {props  && props.details && props.details.id }  disabled = { true } />   
               <HWInput label = "Config Id" value = {props && props.details &&  props.details.configId }  disabled = { true } />   
               <HWInput label = "Description" value = { props && props.details && props.details.description }  disabled = { true } />   
               <HWInput label = "Total Members" value = {props && props.details  &&  props.details.totalMembers }  disabled = { true } />   
               <HWInput label = "Voucher Code" value = { props && props.details && props.details.voucherCode }  disabled = { true } />   
               <HWInput label = "Voucher Per User" value = { props && props.details &&props.details.voucherPerUser }  disabled = { true } />   
              <center> <Button  onClick = { e => props.setOpen(false) } raised variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} > Close </Button> </center>
               </div>
             </Dialog>
    
    );
};


export default function VoucherHistory (props) {

    HWLocalStorage.save (2, "previousVouchersTab");


    const [ rows, setRows ] = useState ([]);
    const [ rowCount, setRowCount ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);
    const [ page, setPage ] = useState (0);
    const [ filterModel, setFilterModel ] = useState (null);
    const [ sortModel, setSortModel ] = useState (null);
    const [ searchText, setSearchText ] = useState ('');
    const [ loading, setIsLoading ] = useState (false);
    const [ refreshState, setRefreshState ] = useState (false);
    const [ open , setOpen ] = useState(false);
    const [ details  , setDetails ] = useState();
     const getTextColor = action => (action === 'minus' ? '#21781C' : '#D83653');

    const useStyles = makeStyles (theme => ({
        ...styles,
        root : {
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiCheckbox-root': { borderRadius: '30px', backgroundColor: 'rgba(0, 0, 0, 0, 0)' },
            '& .Mui-checked': {borderRadius: '3px', backgroundColor: 'rgba(0, 0, 0, 0, 0)', color: 'rgba(200, 0, 0, .1)'},
            '& .MuiDataGrid-rowCount' : { fontWeight : '100', value : (loading) ? 0 : rowCount },
            '& .MuiButton-label' : { color : '#138DE8' }    
        }
    }));

    useEffect (() => {
        setIsLoading (true);
        var paginationBookmark = ((page) * pageSize);
        var fetchLimit = pageSize;
        
        voucherConfigApiService.fetchVoucherHistory ({fetchLimit, paginationBookmark, sortModel , filterModel , searchText})
        .then (response => {
            if (!response || !response.data) {

                HWMessage.show ("Request could not be resolved", "error");
                return console.log("No relevant data can be fetched");
            }
            setRowCount(response.data.recordCount);
           setRows (response.data.historyList);
          
          HWMessage.show("Request Successful" , "success");
        })
        .catch (error => {  HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() => { setIsLoading (false);
        
            var my_element = document.getElementById("top");
            if(my_element!== null || my_element!== undefined || my_element!== '')
            {
                // my_element.scrollIntoView({ }); 

            }   
        });
    }, [ page, pageSize ,  refreshState , sortModel , filterModel , searchText ]);
    
    
    const classes = useStyles();
    const columns = [
        { field: 'date', headerName: 'Date', minWidth:120,flex:1.5,filterable: false ,  renderCell : cellParams => { 
            var d = new Date(cellParams.row.rewardTime); 
            return (
            <Link>
                <Paper style = {{ backgroundColor : '#138DE8' }}> 
                    <GridContainer onClick={e => {setDetails(cellParams.row); setOpen(true); } }> 
                        <GridItem xs = {12} style = {{ marginBottom : '-32px', color : 'floralwhite' }} > <center> <b> { d.toLocaleDateString('default', { day : 'numeric' }) } </b>  </center>  </GridItem> 
                        <GridItem xs = {12} style = {{ color : 'floralwhite' }} > <center> <b>   { d.toLocaleDateString('default', { month : 'short' }) } </b> </center>  </GridItem> 
                    </GridContainer> 
                </Paper>
            </Link>); 
            }}, 
        { field: 'description', headerName: 'Description', minWidth:120,flex:1.5,   renderCell : cellParams => {
            return <span >  {  cellParams.row.description }  </span>
        } },
        { field: 'totalMembers', headerName: 'User Count', filterable: false , minWidth:120,flex:1.5, renderCell : cellParams => {
            return cellParams.row.pointAction && <span style = {{ color : getTextColor (cellParams.row.pointAction) }} > <b> { cellParams.row.pointAction === 'plus' ? '-' : '+' } { cellParams.row.pointsRewarded || cellParams.row.pointsDeducted } </b> </span>
        } },
        { field: 'voucherPerUser', headerName: 'Voucher Per User',filterable: false ,  minWidth:120,flex:1.5},

        { field: 'voucherCode', headerName: 'Voucher Code',minWidth:120,flex:1.5 },
        { field: 'referenceNumber', headerName: 'Reference Number', minWidth:120,flex:1.5 , renderCell : cellParams => {
            return <Link  style = {{ color : '#138DE8'  }} onClick={e => {setDetails(cellParams.row); setOpen(true); }} >  {  cellParams.row.referenceNumber }  </Link>
        } }

        
    ];

const useDataGridStyles = makeStyles(theme => ({
        root: {
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiButton-label' : { color : '#138DE8' }
        }
      }));
      const dataGridClasses = useDataGridStyles();
   
    function CustomLoadingOverlay() {
        return (
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
      }

    return (
        <>
            <BlockUi blocking = { loading } message = { 'Please wait while we fetch.' } >
                <Card id="top">
                    <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                         <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                         <h4 className = { classes.cardIconTitle } > 
                             Voucher History
                         </h4> 
                     </CardHeader>
                    <CardBody> 
                    <GridContainer style = {{ justifyContent : 'start',marginBottom:"10px"}} >
                    <GridItem>  
                        <SearchBar 
                            width = '300px' 
                            handleSearch = { setSearchText }
                            handleRefresh = { setRefreshState }
                            refreshState = { refreshState }
                        /> 
                    </GridItem>
                    </GridContainer>
                        <DataGrid 
                            className = { dataGridClasses.root }
                            rows = { rows }
                            columns = { columns }
                            page = { page }
                            rowCount = { rowCount }
                            pageSize= { pageSize }
                            autoHeight
                            showToolbar
                            pagination
                            paginationMode = 'server'
                            onPageChange = { gridParams => setPage (gridParams) }
                            onPageSizeChange = { gridParams => { setPage (0); setPageSize (gridParams); } }
                            sortingMode="server" 
                            onSortModelChange = { params => { 
                              if (params.length > 0) 
                                  setSortModel (params[0]);
                            } }
                          
                            filterMode = 'server'
                            onFilterModelChange = { (params) => {
                              if (params.items.length > 0) {
                                setFilterModel (prev => ({ ...params.items[0] })); 
                              }
                            } }
                            rowsPerPageOptions ={ [25, 50, 100 ]}
                            loading = { loading }
                            components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 

                        />
                    </CardBody>
                </Card>
             <DetailsPopup details={details} open={open} setOpen={setOpen}></DetailsPopup>
            </BlockUi>
        </>
    );
}