import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import sha512 from 'js-sha512';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import HWUser from 'HWLibrary/HWUser';
import HWMessage from 'HWLibrary/HWMessage';
import appConfig from 'HWLibrary/HWConfig';
import jwt from 'jsonwebtoken';
import LoginService from 'views/LoginService/LoginService';

const shared = axios.create();

// An instance of this class provides: 
//  1. login authentication service
//  2. refresh of access tokens using the refresh token.
//  3. logout
// The class is structured as a singleton.
export class AuthService {
  axiosService = () => { return axios; }

  constructor() {
      console.log("instant1");
      console.log("yy",appConfig.serverBaseUrl + "/auth/refresh-token");
    if (!AuthService.instance) { AuthService.instance = this }
    // Instantiate the interceptor.
    console.log("instant2",axios);
    createAuthRefreshInterceptor(axios, this.refreshAuthLogic, { statusCodes: [ 403 ] });
    console.log("instant3");
    return AuthService.instance;
  }

  refreshAuthLogic = (failedRequest) => {
      console.log("refreshauthlogic");
      const url    = appConfig.serverBaseUrl + "/auth/refresh-token";
      console.log("authrefreshurlnew",url);
      const data   = { refreshToken: this.getCachedRefreshToken() };
      const apiKey = "4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45";
      const config = { skipAuthRefresh: true, headers: { "x-api-key": `${apiKey}` } }
            
      // Create an new instance of axios with axios.create(). Using the axios (or axiosService) instance triggers the refreshAuthLogic again.
      return axios.create().post(url, data, config).then(tokenRefreshResponse => {
          console.log ("Saving ", tokenRefreshResponse);
          this.saveTokensLocal(tokenRefreshResponse.data.accessToken, null);
          failedRequest.response.config.headers['Authorization'] = 'Bearer ' + this.getCachedAccessToken();
          return Promise.resolve();
      }).catch(error => {
          HWMessage.show("Please log in again.");
          this.onLogOut();
          return Promise.resolve();
      });
  }
  
  logInCall =  (emailId, password) => {
      const url = appConfig.serverBaseUrl + "/ops/sign-in";
      const config = { headers: { "content-type": "application/json" } }
      let data = { credentialType : "EmailAddress", credentials : { emailAddress: emailId, hashedPassword: sha512(password) } }
      return axios.post(url, data, config).then(logInResponse => {
          if (!logInResponse.data) {
              return Promise.reject({ message: "Something went wrong please try again." });
          }
          HWMessage.show ("Logged In Successfully", "success");
          if (logInResponse.data) { this.saveTokensLocal(logInResponse.data.accessToken, logInResponse.data.refreshToken); }
          
          let result = this.profileFetchCall ().then (response => {
            if (! response || ! response.data) { throw new Error ("Profile cannot be fetched"); }
            HWUser.updateUserDetails (response.data);
            window.location.reload ();
        }).catch (error => { HWMessage.show ("Error while fetching profile" ) });
      }).catch (error => {
          HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Cannot Login At Moment", "error");
      });
  }

  parseToken = (token) => { return jwt.decode (token); };

  profileFetchCall = () => {
    const accessToken =  this.getCachedAccessToken();
    const { sub } = this.parseToken (this.getCachedRefreshToken());
    const url = `${ appConfig.serverBaseUrl }/ops/users/${ sub }`;
    const config = { headers: { Authorization: `Bearer ${accessToken}` } };

    return axios.get (url, config);
  };

  onLogOut = () => {
      HWUser.resetUser();
      this.clearTokens();
      window.location.reload ();
  };

  //  Tokens Local Cache
  accessToCachekenKey  = "access_token";
  refreshToCachekenKey = "refresh_token";

  getCachedAccessToken  = () => { return HWLocalStorage.load(this.accessToCachekenKey);  }
  getCachedRefreshToken = () => { return HWLocalStorage.load(this.refreshToCachekenKey); }
 
  saveTokensLocal = (accessToken, refreshToken) => {
      if (accessToken)  { HWLocalStorage.save(accessToken,  this.accessToCachekenKey);  }
      if (refreshToken) { HWLocalStorage.save(refreshToken, this.refreshToCachekenKey); }
  }

  clearTokens = () => {
      HWLocalStorage.clear(this.accessToCachekenKey);
      HWLocalStorage.clear(this.refreshToCachekenKey)
  }


  // Correct it. 
  isLoggedIn = () => {
      var refreshTokenValue = this.refreshToCachekenKey;
      return refreshTokenValue != null;
  }
}

const authService = new AuthService();
export default authService;
