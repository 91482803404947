import React, { useEffect, useState } from 'react';
import './MemberStyles.css'
import { Card, makeStyles, FormControl, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent, Button, ButtonGroup, TextField, ListItem, ListItemIcon, List, Chip, CircularProgress } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from "HWLibrary/HWInput";
import { Add, Edit } from '@material-ui/icons';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { IconButton, Tooltip } from "@material-ui/core";
import CardIcon from 'components/Card/CardIcon';
import { VerifiedUser, Home, Motorcycle, CardMembership } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader';
import CardBody from "components/Card/CardBody.js";
import BikeDetailsCard from './BikeDetailsCard';
import memberApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import moment from 'moment';
import { useReducer } from 'react';
import ManageMembership from './ManageMembership';
import MemberTransactions from './MemberTransactions';
import MemberRewards from './MemberRewards/MemberRewards';
import MemberRequests from './MemberRequests';
import { ClipLoader, FadeLoader } from 'react-spinners';
import settingsApiService from 'views/Settings/APIService';
import { ConfirmationDialog } from './../Merchant/Merchant';
import { StatusDialog } from "./../Merchant/Merchants";
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import WorkIcon from '@material-ui/icons/Work';
import HomeIcon from '@material-ui/icons/Home';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import { useHistory } from 'react-router-dom';
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { DropDown } from 'views/Merchant/Merchant';

const defaultUseStyles = makeStyles (styles);



const MemberCreate = (props) => {

  var members=[]
  var obj = JSON.parse(HWLocalStorage.load("Permissions"))
  console.log(obj);
  members= [...obj["Members"]];

  const defaultClasses = defaultUseStyles ();

  HWLocalStorage.save (0, "previousMemberTab");

  const [memberDetails, setMember] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [ dialogOpen, setDialogOpen ] = useState (false);
  const [ dialogOpen2, setDialogOpen2 ] = useState (false);

  const [ statusDialogOpen, setStatusDialogOpen ] = useState (false);
  const [ updateStatus, setUpdateStatus ] = useState ('Success');
  const [ tempMember, setTempMember ] = useState({});
  const [ refreshState, setRefreshState ] = useState (false);
  const [ updateLoading, setUpdateLoading ] = useState (false);
  const [ bikeFetch, setBikeFetch ] = useState (false);
  const [ showEdit,  setShowEdit ] = useState (true);

  const [ showEdit2,  setShowEdit2 ] = useState (true);
  const [disabled2, setDisabled2] = useState(true);

  const [ emailButtonDisabled , setEmailButtonDisabled] = useState(false);
  const [ currentRule, setCurrentRule ] = useState ({});

  if (props && props.location && props.location.params && props.location.params.state && props.location.params.state.id) {
    var value = props && props.location && props.location.params && props.location.params.state && props.location.params.state.id;
    HWLocalStorage.save(JSON.stringify(value), "memberStore");
  } 
  const [ memberId, setMemberId ] = useState (props && props.location && props.location.params && props.location.params.state && props.location.params.state.id || JSON.parse(HWLocalStorage.load("memberStore")));
  console.log("Logging Member Id", memberId, props);

  const updateMemberDetails = () => {
    console.log("Starting to update", memberDetails);
    
    if (memberDetails) {
      memberDetails.memberId = memberDetails.id;
      memberDetails.currentTier = memberDetails.currentTier ? memberDetails.currentTier : memberDetails.tier;
      memberDetails.gender = memberDetails && memberDetails.gender && memberDetails.gender;
      console.log("genderDetails",memberDetails.gender);
    
      setUpdateLoading (true);
      memberApiService.saveMemberDetails (memberDetails)
      .then (response => {  
        if (!response || !response.data)
          return console.log("Error while fetching records");
          // TODO : Update the current model using the update response.
        
        setMember (response.data);
        setUpdateStatus ('Success');
        setDisabled (! disabled);
        HWMessage.show("Details updated successfully","success");
        
      })
      .catch (error => { setUpdateStatus ('Failed'); 
        console.log("Error while updating member", error);
        HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error"); })
      .finally (() => { setUpdateLoading(false); setDialogOpen(false); });
    }
    setShowEdit (true);
  };

  const updateMembershipDetails = (id) => {
    setUpdateLoading (true);
    console.log(currentRule);
    
    memberApiService.updateMembershipDetails (id,currentRule)
    .then (response => {  
      if (!response || !response.data)
        return console.log("Error while fetching records");    
      setUpdateStatus ('Success');
      setDisabled2 (! disabled2);
      HWMessage.show("Details updated successfully","success");
      setRefreshState(!refreshState);
    })
    .catch (error => { setUpdateStatus ('Failed'); console.log("Error while updating member", error); })
    .finally (() => { setUpdateLoading(false); setDialogOpen2(false);setShowEdit2 (true); });
  }
  

  const toggleMembership = (memberId) => {
    setUpdateLoading (true);
    var status;
    if(memberDetails.cards.length > 0){
      if(memberDetails.membershipStatus === "Active")
      {
        status  = "Suspended"
      }
      else{
        status  = "Active"
      }
      memberApiService.toggleMembershipDetails (memberId,status)
      .then (response => {  
        if (!response || !response.data)
          return console.log("Error while fetching records");    
        setUpdateStatus ('Success');
        setDisabled2 (! disabled2);
        HWMessage.show("Details updated successfully","success");
        setRefreshState(!refreshState);

      })
      .catch (error => { setUpdateStatus ('Failed'); console.log("Error while updating member", error); })
      .finally (() => { setUpdateLoading(false); setDialogOpen2(false);setShowEdit2 (true); });
    }
    else{
      memberApiService.activateMember(memberId)
      .then (response => {  
        if (!response || !response.data)
          return console.log("Error while fetching records");    
        setUpdateStatus ('Success');
        setDisabled2 (! disabled2);
        HWMessage.show(response && response.data && response.data.message);
        setRefreshState(!refreshState);

      })
      .catch (error => { setUpdateStatus ('Failed'); console.log("Error while updating member", error); })
      .finally (() => { setUpdateLoading(false); setDialogOpen2(false);setShowEdit2 (true); });
    }
  }


  var history = useHistory();
  const redirectMembersList = () =>  history.push ('/ops/members'); 
  useEffect (() => {

    console.log("Current Member", memberDetails);
    if (memberId) {
      setLoading (true);
      memberApiService.fetchMemberDetails (memberId)
        .then (response => {
          if (!response || !response.data) {
            HWMessage.show("Cannot be fetched at moment","error");
            return ;
          }
          setMember (response.data);
          setTempMember (response.data);
          dispatchBikeChanges({ type: 'set', payload: response.data.bikes });
          HWMessage.show("Request Successful" , "success");
        })
        .catch (error => {  
          if (error.response && error.response.status && error.response.status === 404) { 
            redirectMembersList ();
            HWMessage.show ("This member cannot be found","error");  
            return;
          }
          HWMessage.show ("This member cannot be found","error");  
          return console.log("Error while fetching details", error, error.response); 
        })
        .finally (() => setLoading (false));
    } else {
      HWMessage.show ("Member ID cannot be found", "error");
      return;
    }
    setShowEdit (true);
  }, [ memberId, refreshState ]);

  useEffect (() => { if (loading && loading === false) setBikeFetch (true); }, []);

  const useFieldsInErrorReducer = () => {
    const [state, setState] = useState({ fieldsInError: new Map() });

    const dispatch = (action) => {
      if (action !== undefined) {
        switch (action.type) {
          case 'setError':
            console.log( "Action error", action, state);
            setState(previous => {
              let newMap = new Map(previous.fieldsInError);
              newMap.set(action.field, action.error);
              return { fieldsInError: newMap };
            });
            console.log( "Action error", action, state);
            break;
          case 'resetError':
            
            setState(previous => {
              let newMap = new Map(previous.fieldsInError);
              newMap.delete(action.field);
              return { fieldsInError: newMap };
            });
            
            break;
        }
      }
    };

    // Indicates to the React component if there is an error in the field.
    const hasError = field => { return state.fieldsInError.has(field) };

    // Returns an error message to the field's React component.
    const errorMessage = field => { return state.fieldsInError.get(field) };
    return [dispatch, hasError, errorMessage];
  };
  
  const [dispatchErrorHandler, hasError, errorMessage] = useFieldsInErrorReducer();

  // This custom hook is used to keep the state and mutate it upon the return of server async calls.
  const useMemberReducer = (initialState) => {
    const [ state, setState ] = useState (initialState);
    const dispatch = (action) => {
      console.log("Action initiated", action);
      switch (action && action.type) {
        case 'set': setMember(previous => ({ ...previous, ...action.value })); break;
        
        case 'save':
          // Check if the value has changed.
          let value = action.value;
          if (typeof value === 'string') { value = value.trim(); }
          if (hasError(action.field)) { return; }

          var field = action.field;
          var idx = action.index;
          if (action.field.includes('address.')) {
            console.log("Insikde in here");
            field = action.field.split ('.')[1];
            var parentField = 'addresses';
            setMember(previous =>  { 
              console.log("Settung noe ", 
              { ...previous, 'addresses' : { ...(previous.addresses), [idx] : { ...previous.addresses[idx], [field]: value}} }  ); 

              if (idx >= previous.addresses.length) { return previous; }
              else {
                console.log("Change in progress ", previous, previous.addresses[idx].field, value);
                previous.addresses[idx][field] = value;

                console.log("Changed ", previous, previous.addresses[idx].field);
                return previous;
              }
              return { ...previous, 'addresses' : { ...(previous.addresses), [idx] : { ...previous.addresses[idx], [field]: value}} } }  /* { ...previous, [parentField] : { ...(previous.addresses) , [idx] : { ...(previous.addresses[idx]), [field]: value} } } }*/ );
            state.dispatchErrorHandler({ type: 'resetError', field: field });
          }
          else {
            console.log("While setting", memberDetails);
            setMember(previous =>  { console.log("Previous ", previous); console.log("pREVIOUSING ", ({ ...previous, [action.field]: value, member: { ...(previous.member || {}), [action.field]: value }  }));  return ({ ...previous, [action.field]: value, member: { ...(previous.member || {}), [action.field]: value }  })});
            state.dispatchErrorHandler({ type: 'resetError', field: action.field });
          }
          break;
      }
    };
    return [state, dispatch];
  };

  const [ state, dispatch ] = useMemberReducer ({ memberDetails: { memberDetails }, dispatchErrorHandler: dispatchErrorHandler }); 
  
  const [bikeViewModel, dispatchBikeChanges] = useReducer(bikeReducer, { model: [], viewModel: [] });
  const [loading, setLoading] = useState(false);
  const [ bikeLoading, setBikeLoading ] = useState (false);

  function bikeReducer(state1, action) {
    console.log("Bike Reducer called");

    switch (action && action.type) {
      case 'set':
        const model = action.payload;
        console.log("model", model)
        const viewModel = model.map(bike => {
          return {
            id: bike.id,
            customerId: bike.assignedToUserId,
            plateNumber: bike.plateNumber,
            icNumber: bike.icNumber,
            status: bikeStatusViewModel(bike.status, bike.vocVerificationMode),
            bikeStatusAdd : bike.status,
            vocVerificationMode: bike.vocVerificationMode,
            engineNumber: bike.engineNumber,
            chassisNumber: bike.chassisNumber,
            modelNumber: bike.modelNumber,
            registrationDate: bike.registrationDate, 
            vocCopyFileNames : bike.vocCopyFileNames,
            user : bikeUserViewModel (bike.status, memberDetails.name, bike.assignedUsername),
            approvedOrRejectedBy: bike.approvedOrRejectedBy,
            approvedDate: bike.approvedDate,
            rejectedReason: bike.rejectedReason


          }
        });
        return { model, viewModel };
      default: break;
    }
  }

  function bikeUserViewModel (bikeStatus, name, assignedUsername) {
    console.log("Assigning users", bikeStatus, name, assignedUsername);
    switch (bikeStatus) {
      case 'Assigned' : return assignedUsername;
      default : return name;
    }
  }

  function bikeStatusViewModel(bikeStatus, vocVerificationMode) {
    switch (bikeStatus) {
      case 'Verified': 
        switch (vocVerificationMode) {
          case 'Jpj' : return 'Approved';
          case 'Manual' : return 'Approved';
        }  
      case 'Assigned' : 
        switch (vocVerificationMode) {
          case 'Jpj' : return 'Assigned';
          case 'Manual': return 'Assigned';
        }  
      case 'Rejected': return 'Rejected';
      case 'PreApproved' : return 'Pre Approved';
      case 'PreOwned' : return 'Pre Owned';
      case 'Pending': 
        switch (vocVerificationMode) {
          case 'PreApproved' : return 'Pre Approved';
          case 'Manual' : return 'Manual Check';
          case 'PreOwned' : return 'Pre Owned';
          default : return 'Pending';
        }
      default: return ''
    }
  }

  const clearErrors = () => {
    const fieldList = [ 'mobileNumber', 'name', 'username', 'tier', 'dob', 'email', 'gender', 'addressLine1', 'addressLine2', 'city', 'state', 'postCode' ];
    for (var i = 0; i <  fieldList.length; i++) {
      dispatchErrorHandler({ type: 'resetError', field: fieldList[i]}); 
    }
  };
   
  const handleTextfieldChanges = (event, idx) => {
    
    let value = event.target.value;
    if (typeof value === 'string') { value = value.trim(); }

    console.log("Printintintintint", event.target.name, value, event);

    switch (event.target.name) {  
      case 'mobileNumber':
        let pattern = new RegExp("^\\d{5,16}$");
        if (!pattern.test(value)) {          
          dispatchErrorHandler({ type: 'setError', field: 'mobileNumber', error: "Please enter a valid mobile number" });
        } else {

          dispatch({ type: 'save', field: 'mobileNumber', value: value });
          dispatchErrorHandler({ type: 'resetError', field: event.target.name }); 
          setMember ({ ...memberDetails, mobile : { ...(memberDetails.mobile && memberDetails.mobile) , mobileNumber : value } });
        }
        break;

      case 'name': 
        if (value === '') {dispatchErrorHandler({ type: 'setError', field: 'name', error: "Please enter your full name" });} 
        else { dispatch({ type: 'save', field: 'name', value: value }); dispatchErrorHandler({ type: 'resetError', field: event.target.name });  }
        break;

      case 'username': 
        if (value === '') {dispatchErrorHandler({ type: 'setError', field: 'username', error: "Please enter username" });} 
        else { dispatch({ type: 'save', field: 'username', value: value }); dispatchErrorHandler({ type: 'resetError', field: event.target.name });  }
        break;
  
      case 'tier' : 
        if (value === '') { dispatchErrorHandler({ type: 'setError', field: 'currentTier', error: "Please enter full tier for member" }); }
        else { dispatch({ type : 'save', field : 'currentTier', value : value }); }
        break;

      case 'dob':
        const dateFormat = 'DD/MM/YYYY';
        const toDateFormat = moment(new Date(value)).format(dateFormat);
        setMember((oldValue => {
          let newValue = oldValue !== undefined ? oldValue : {};
          newValue[event.target.name] = toDateFormat;
          return newValue;
        }))
        if (value === '') {
          dispatchErrorHandler({ type: 'setError', field: 'dob', error: "Please enter your date of birth" });
        } else if (value !== undefined && !moment(toDateFormat, dateFormat, true).isValid()) {
          dispatchErrorHandler({ type: 'setError', field: 'dob', error: "Please enter a valid date of birth" });
        } else {
          dispatch({ type: 'save', field: 'dob', value: value });
        }
        break;

      case 'email': 
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const valid = re.test(String(value).toLowerCase())
      console.log(valid);
     
      if (valid === false ) {     
        dispatchErrorHandler({ type: 'setError', field: 'email', error: "Please enter valid email address. " });

        dispatch({ type: 'save', field: 'email', value: value });     
      }
      if (value === '' || valid === false ) {
        dispatch({ type: 'save', field: 'email', value: value });
        dispatchErrorHandler({ type: 'setError', field: 'email', error: "Please enter member email address. " });
      }
      else {
        dispatch({ type: 'save', field: 'email', value: value });
        dispatchErrorHandler({ type: 'resetError', field: event.target.name });
      }
      break;

      case 'gender': 
      console.log("Value Gender", value);
      if (value === '') { dispatchErrorHandler({ type: 'setError', field: 'gender', error: "Please enter your gender" }); } 
      else { dispatch({ type: 'save', field: 'gender', value: value }); dispatchErrorHandler({ type: 'resetError', field: event.target.name }); }
      break;

      case 'addressLine1' :
        if (value === '') {
          dispatchErrorHandler ({ type : 'setError', field : 'addressLine1', error : 'Please Enter Address Line 1' });
        }
          
        else {
          dispatch ({ type : 'save', field : 'address.addressLine1', value : value, index : idx });
          
        }
        break;

      case 'addressLine2' : 
        if (value === '') 
          {dispatchErrorHandler ({ type : 'setError', field : 'addressLine2', error : 'Please Enter Address Line 2' });}
        else 
          {dispatch ({ type : 'save', field : 'address.addressLine2', value : value, index : idx }); }
        break;

      case 'city' : 
        if (value === '') 
          {dispatchErrorHandler ({ type : 'setError', field : 'city', error : 'Please Enter Address City' });}
        else 
          {dispatch ({ type : 'save', field : 'address.city', value : value, index : idx }); }
        break;
        
      case 'state' : 
        if (value === '') 
          {dispatchErrorHandler ({ type : 'setError', field : 'state', error : 'Please Enter State' });}
        else 
          {dispatch ({ type : 'save', field : 'address.state', value : value, index : idx }); }
        break;

      case 'postCode' : 
      
        var postCodePattern = new RegExp('^\\d{6}$');
        if (value === '') 
          {dispatchErrorHandler ({ type : 'setError', field : 'postCode', error : 'Please Enter Post Code' }); }
        else if (!postCodePattern.test(value))
          {dispatchErrorHandler ({ type : 'setError', field : 'postCode', error : 'Please Enter Valid Post Code' });}
        else 
          {dispatch ({ type : 'save', field : 'address.postCode', value : value, index : idx });}
        break;
    }
    
  }

  const useStyles = makeStyles((theme) => ({
    /* Styles applied to the root element. */
    root: {
      overflow: 'hidden',
      minHeight: 48,
      WebkitOverflowScrolling: 'touch', // Add iOS momentum scrolling.
      display: 'flex',
      backgroundColor: "black",
    },
    container: {
      overflowY: 'scroll',
      height: '262px'
    },
    gender: {
      width: '100%',
      backgroundColor: 'white',
      fontSize : '14px', 
      marginTop : 4
    }
  }));


  const getQualifiedName = (name) =>  { console.log("memmememem", name, `${name.substring(0, 1).toUpperCase()}${name.substring(1)}`); return `${name.substring(0, 1).toUpperCase()}${name.substring(1)}`;};
  // const handleUpdateOpen2 = () => { 
  //   clearErrors();
  //   setDialogOpen2 (true); setShowEdit2 (true);  
  // }
  const handleUpdateOpen = () => { 
    clearErrors();
    setDialogOpen (true); setShowEdit (true);  
    setDialogOpen2 (true); setShowEdit2 (true);  
  }
  const handleCancel = (event) => {  setRefreshState(! refreshState); setShowEdit (true); setShowEdit2 (true); setDisabled (true); setDisabled2 (true);  };
  //const handleCancel2 = (event) => {  setRefreshState(! refreshState); setShowEdit2 (true); setDisabled2 (true);  };

  const isDisabled = (fieldName) => {
    console.log("Member details", memberDetails, bikeViewModel);

    const checkBikeApproved = bikeViewModel => {
      if (! bikeViewModel || bikeViewModel === null || (bikeViewModel && bikeViewModel === null) ) { return false; }
      else if ( bikeViewModel && bikeViewModel.model !== null) {
        for (var i = 0; i < bikeViewModel.model.length; i++) {
          if (bikeViewModel.model[i] !== null) {
            if (bikeViewModel.model[i].status === 'Verified') { return true; }
          }
        }
        return false;
      }
      return false;
    };

    if (disabled === true) { return true; }
    else if (disabled === false) {
      if (memberDetails !== null) {
        if (checkBikeApproved(bikeViewModel)) { console.log("Returning", memberDetails.icNumber); return fieldName !== 'email' }
        else { return false; }
      }
    }
  };

  const dateFormatter = (memberSince) => { 
    var result;
    if(memberSince){
    var year = memberSince.substring(0,4);
    var month = memberSince.substring(5,7);
    var day = memberSince.substring(8,10);
    result = day+"/"+month+"/"+year;
    console.log("memberSince",result);}
    return result;
  };
  const createMobileNumber = (mobile) => {  };  

  const classes = useStyles();


  console.log("Lgging thissing", memberDetails, );

  const assignAdornment = (value, verificationStatus) => {  
    if (verificationStatus == null) { return <></>; }
    else if (verificationStatus != null) { 
      if (verificationStatus === 'Verified') 
      { return <Tooltip title = "Verification Completed">
        <IconButton style = {{ paddingBottom : 2 }} > <CheckIcon style = {{ color : 'green' }} /> </IconButton>
      </Tooltip>; }
      else 
      { return  <Tooltip title = "Verification Incomplete" style = {{ color : '#FF9017' }} >
          <IconButton style = {{ paddingBottom : 2 }} > <ErrorIcon style = {{ color : '#FF9017'  }} /> </IconButton>   
        </Tooltip>;
      }
        
    }
  };

  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
  const handleDateChange = (date) => { setSelectedDate(date);};

  const updateInfo = () => {
      updateMemberDetails();
      updateMembershipDetails(memberDetails && memberDetails.id)
  }

  return (
    <>
      { loading && <center style = {{ marginTop : '100px' }} > <CircularProgress size = {60} thickness = { 5 } /> { /* <FadeLoader margin = {20} radius = {20} width = {20} height = {20}  /> */} </center> }
      { !loading &&  
      <div>

        <GridContainer>
          <GridItem xs = {6} >
            <GridContainer>
              <GridItem xs = {12} >
                  <Card className='card-header-body'>
                    <CardHeader icon>
                      <CardIcon color="primary" style = {{ marginTop : 0, background : '#FF9017' }} ><VerifiedUser /></CardIcon>                      
                    </CardHeader>
                    <CardBody className='card-header-body'></CardBody>
                  </Card>

                  <Card className="memberDetails_body">
                    <h4 className = { defaultClasses.cardIconTitle } >
                          Member Details
                          { members.includes("Action") === true && showEdit === true && <Tooltip title="Edit Member">
                          <IconButton aria-label="new media" onClick={() => {setDisabled(! disabled); setShowEdit (false); }}> <Edit width="20" height='20' style = {{ color : '#138DE8' }} /></IconButton>
                          </Tooltip>}
                        </h4>

                    <CardBody>
                      <GridContainer className="memberDetails_card" >
                        
                      </GridContainer>

                    
                      <HWInput inputProps = {{ endAdornment : assignAdornment(memberDetails ? memberDetails.member : null, memberDetails.mobileVerifiedStatus ) }} 
                      label="Mobile Number" 
                      name="mobileNumber" 
                      value={ (memberDetails && memberDetails.mobile && memberDetails.mobile.mobileNumber) ? (memberDetails.mobile.mobileNumber) : null } 
                      //type='number' 
                      disabled={isDisabled ('mobileNumber')} 
                      onBlur={(event) => handleTextfieldChanges(event)} hasError={hasError('mobileNumber')} helperText={errorMessage('mobileNumber')} />
                      <GridContainer>
                      <GridItem xs = {12} > <HWInput label="Sign Up Type" name="credentialType" value={memberDetails ? memberDetails.credentialType : ""} disabled={true} onBlur={(event) => handleTextfieldChanges(event)} hasError={hasError('username')} helperText={errorMessage('username')} /> </GridItem> 
                      
                        <GridItem xs = {6} > <HWInput label="Full Name" name="name" value={memberDetails ? memberDetails.name : ""} disabled={ isDisabled ('name') } onBlur={(event) => handleTextfieldChanges(event)} hasError={hasError('name')} helperText={errorMessage('name')} /> </GridItem> 
                        <GridItem xs = {6} > <HWInput label="User Name" name="username" value={memberDetails ? memberDetails.username : ""} disabled={true} onBlur={(event) => handleTextfieldChanges(event)} hasError={hasError('username')} helperText={errorMessage('username')} /> </GridItem> 
                        


                      
                        <GridItem xs = {12} > <HWInput inputProps = {{ endAdornment :  memberDetails && assignAdornment(memberDetails.email, memberDetails.emailVerifiedStatus) }} label="Email" type="email" name="email" value={memberDetails ? memberDetails.email : ""} disabled={ isDisabled ('email') } onBlur={(event) => handleTextfieldChanges(event)} hasError={hasError('email')} helperText={errorMessage('email')} /> </GridItem>
                        
                        
                        <GridItem xs = {6} > <HWInput label = "Date of Birth" labelProps = {{ shrink : true }} type='date' name="dob" value={memberDetails.dateOfBirth} disabled={ isDisabled ('date') } onBlur={(event) => handleTextfieldChanges(event)} hasError={hasError('dob')} helperText={errorMessage('dob')} /> </GridItem> 
                        <GridItem xs = {6} >
                          <FormControl disabled = {disabled} className={classes.gender}  >
                            <InputLabel disabled = {disabled} className={classes.gender} name="gender" hasError={hasError('gender')} helperText={errorMessage('gender')}>Gender</InputLabel>
                            <Select name = "gender" disabled={ isDisabled ('gender') } labelId="gender" value={ memberDetails && memberDetails.gender ? getQualifiedName(memberDetails.gender) : "Others" } onChange={(event) => {console.log("Thisssing", event); handleTextfieldChanges(event);  }}>
                              <MenuItem value="Female">Female</MenuItem>
                              <MenuItem value="Male">Male</MenuItem>
                              <MenuItem value="Others">Others</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                      { memberDetails.icNumber && memberDetails.icNumber !== null && <HWInput disabled = {true} label = {'IC Number'} type = {'text'} value = { memberDetails && memberDetails.icNumber ? memberDetails.icNumber : '' } />}
                    </CardBody>
                </Card>
              </GridItem>

              <GridItem xs = {12} style = {{ marginTop : 15 }} >

                  <Card className='card-header-body'>
                    <CardHeader icon>
                      <CardIcon color="primary" style = {{ marginTop : 0, background : '#138DE8' }} ><Home /></CardIcon>                      
                    </CardHeader>
                    <CardBody className='card-header-body'></CardBody>
                  </Card>

                  <Card className="memberDetails_body">
                    <h4 className = { defaultClasses.cardIconTitle } > Address Details </h4>                  
                    <CardBody style = {{ marginTop : 20 }} >
                      <GridContainer >
                          { (! memberDetails  || (memberDetails && (! memberDetails.addresses)) || (memberDetails && (memberDetails.addresses) && (!memberDetails.addresses.length === 0 ))) &&<h3> No addresses have been added yet </h3> }
                          { memberDetails && memberDetails.addresses 
                              && memberDetails.addresses.map((address, idx) => 
                              <GridItem > 
                                <Card  raised style = {{ borderRadius : 5, backgroundColor : '#F3EFF9', marginBottom: 20 }} > 
                                  <CardHeader> 
                                      <center> <Chip label = { address.defaultStatus == null ? 'Default Address' : address.defaultStatus === 'defaultAddress' ? 'Default Address' : address.defaultStatus } />   </center>
                                    </CardHeader> 
                                  <CardBody> 
                                      <HWInput label="Address Type" name = 'addressType' value={address.addressType} disabled={ isDisabled ('addressType') } hasError={hasError('addressType')} helperText={errorMessage('addressType')} onBlur={(event) => handleTextfieldChanges(event, idx)} /> 
                                      <GridContainer>
                                        <GridItem xs = {6} > <HWInput required={true} label="Address Line 1" name = 'addressLine1' value={address.addressLine1} disabled={ true } hasError={hasError('addressLine1')} helperText={errorMessage('addressLine1')} onBlur={(event) => handleTextfieldChanges(event, idx)} /> </GridItem> 
                                        <GridItem xs = {6} > <HWInput  required={true} label="Address Line 2" name = 'addressLine2' value={address.addressLine2} disabled={ true } hasError={hasError('addressLine2')} helperText={errorMessage('addressLine2')} onBlur={(event) => handleTextfieldChanges(event, idx)} /> </GridItem> 
                                        <GridItem xs = {12} > <HWInput required={true} label="Postcode" name = 'postCode' onBlur={(event) => handleTextfieldChanges(event, idx)} value={address.postCode} disabled={ true } hasError={hasError('postCode')} helperText={errorMessage('postCode')} /> </GridItem> 
                                        <GridItem xs = {6} > <HWInput required={true} label="City" name = 'city' onBlur={(event) => handleTextfieldChanges(event, idx)}  value={address.city} disabled={ true } hasError={hasError('city')} helperText={errorMessage('city')} /> </GridItem> 
                                        <GridItem xs = {6} > <HWInput required={true} label="State" name = 'state' onBlur={(event, idx) => handleTextfieldChanges(event, idx)}  value={address.state} disabled={ true } hasError={hasError('state')} helperText={errorMessage('state')} /> </GridItem> 
                                      </GridContainer>
                                      
                                  </CardBody>
                                </Card>
                              </GridItem>
                              )
                          }
                        </GridContainer>
                    </CardBody>
                </Card>
              </GridItem>

            </GridContainer>
          </GridItem>
          <GridItem xs = {6} >
            <GridContainer>
              <GridItem xs = {12} >  
                  <Card className='card-header-body'>
                    <CardHeader icon>
                      <CardIcon color="primary" style = {{ marginTop : 0, background : '#138DE8' }} ><CardMembership /></CardIcon>                      
                    </CardHeader>
                    <CardBody className='card-header-body'></CardBody>
                  </Card>

                <Card className="memberDetails_body">
                  <h4 className = { defaultClasses.cardIconTitle } > Membership Details  { members.includes("Action") === true && showEdit2 === true && <Tooltip title="Edit Membership Details">
                          <IconButton aria-label="new media" onClick={() => {setDisabled2(! disabled2); setShowEdit2 (false); }}> <Edit width="20" height='20' style = {{ color : '#138DE8' }} /></IconButton>
                          </Tooltip>}
                         </h4>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs = {6} > <HWInput required={true} label="Membership Number" value={memberDetails && memberDetails.membershipNumber} disabled={true} /> </GridItem>
                      <GridItem xs = {6} > <HWInput required={true} label="Member Since" value={memberDetails && memberDetails.memberSince && dateFormatter(memberDetails.memberSince)} disabled={true} /> </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs = {6} > <HWInput required={true} label="Membership Status" value={memberDetails && memberDetails.membershipStatus} disabled={ true } hasError={hasError('membership_status')} helperText={errorMessage('membership_status')} /> </GridItem>
                      <GridItem xs = {6} > 
                      <HWInput required={true} name = 'tier' label="Tier" value={currentRule && currentRule.tierName ? currentRule && currentRule.tierName : memberDetails && memberDetails.tier ? getQualifiedName(memberDetails.tier) : "NA" }  hasError={hasError('tier')} helperText={errorMessage('tier')} onBlur={(event) => setCurrentRule (prev => ({ ...prev, tierName : event.target.value })) }  /> 
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs = {6} > <HWInput required={true} label = "Member State" value = { memberDetails.state } disabled = {true}  /> </GridItem>
                      <GridItem xs = {6} > 
                      <HWInput  
                          type = {'date'}
                          name = { 'validityStartDate' } 
                          value = { currentRule && currentRule.date ? currentRule && currentRule.date  : memberDetails && memberDetails.validityEndDate } 
                          onBlur = { e => setCurrentRule (prev => ({ ...prev, date : e.target.value })) } 
                          label = { 'Membership Expiry' } 
                          labelProps = {{ shrink: true }} 
                          inputProps={{min : new Date().toISOString().split('T')[0]}} 
                          disabled = { showEdit2 }
                          required={true}
                          /> 
                      </GridItem> 
                    </GridContainer>
                    <GridContainer>

                    <GridItem xs = {12} >
                      <center>
                        <Button onClick={e=>toggleMembership(memberDetails.id)} raised variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} > {memberDetails && memberDetails.cards && memberDetails.cards.length == 0? "Activate Membership" : memberDetails.membershipStatus  && memberDetails.membershipStatus==="Active" ? "Suspend Membership" : "Resume Membership"} </Button>
                      </center> 
                     </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>

              <GridItem xs = {12} style = {{ marginTop : 15 }} >  

                <Card className='card-header-body'>
                    <CardHeader icon>
                      <CardIcon color="primary" style = {{ marginTop : 0, background : '#FF9017' }} ><Motorcycle /></CardIcon>                      
                    </CardHeader>
                    <CardBody className='card-header-body'></CardBody>
                </Card>                            
                <Card className="memberDetails_body">
                  <h4 className = { defaultClasses.cardIconTitle } > Bike Details </h4>
                  <CardBody>
                    { bikeLoading && <center> <ClipLoader size = {60} /> </center> }
                    {
                      !bikeLoading && memberDetails &&
                        <>
                        { ! bikeViewModel || (bikeViewModel && !bikeViewModel.viewModel) || ( bikeViewModel && bikeViewModel.viewModel && bikeViewModel.viewModel.length === 0 ) && <h3> No Bikes Available for this member </h3> }
                        <GridContainer className={classes.container}>
                          {bikeViewModel && bikeViewModel.viewModel && bikeViewModel.viewModel.map((bike) => <BikeDetailsCard memberName={memberDetails.name} bikeDetails={bike} dispatchBikeChanges={dispatchBikeChanges} dispatchErrorHandler={dispatchErrorHandler} errorMessage = { errorMessage } hasError = {hasError} setRefreshState = { setRefreshState } refreshState = { refreshState } > </BikeDetailsCard> )} 
                        </GridContainer>
                        </>
                    }
                  </CardBody>
                </Card>
              </GridItem>

            </GridContainer>
          </GridItem>

          { (!disabled || !disabled2 ) &&
          <GridContainer justify = "center" >
            <GridItem style = {{ marginTop : '12px' }} >
              <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { handleUpdateOpen } > Update </Button>
              <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' ,marginLeft: 10 }} onClick = { handleCancel } > Cancel </Button>
            </GridItem>
          </GridContainer>    
          }

{/* { !disabled2 &&
          <GridContainer justify = "center" >
            <ButtonGroup size = "large" style = {{ marginTop : '12px' }} >
              <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { handleUpdateOpen2 } > Update </Button>
              <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} onClick = { handleCancel2 } > Cancel </Button>
            </ButtonGroup>
          </GridContainer>     
          } */}

          <ConfirmationDialog 
            dialogOpen = {dialogOpen} 
            setDialogOpen = {setDialogOpen} 
            dialogTitle = 'Confirm Updating Member Details' 
            dialogMessage = {'Are you sure you want to update member details ?'} 
            buttonText = {'Update'} 
            handleClickAction = {updateInfo} 
          />  

        {/* <ConfirmationDialog 
            dialogOpen = {dialogOpen2} 
            setDialogOpen = {setDialogOpen2} 
            dialogTitle = 'Confirm Updating Membership Details' 
            dialogMessage = {'Are you sure you want to update member details ?'} 
            buttonText = {'Update'} 
            handleClickAction = { updateMembershipDetails(memberDetails && memberDetails.id) }
          />   */}

          <StatusDialog 
            dialogTitle = { 'Update Member Details' }
            dialogMessage = { 'Are you confirm ? You want to update member details' }
            open = { statusDialogOpen }
            showPopup = { setStatusDialogOpen }
            createStatus = { updateStatus }
            createLoading = { updateLoading }
          />
        </GridContainer>
      </div>
      }
    </>
  )
}

export default MemberCreate;