import { Button, makeStyles } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from 'HWLibrary/HWInput';
import pointsApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import { useCallback } from 'react';
import BlockUi from 'react-block-ui';
import { DropDown } from 'views/Merchant/Merchant';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import StatusDialog from 'views/Utils/StatusDialog';
import Edit from '@material-ui/icons/Edit';
const useStyles = makeStyles (theme => ({
    ...styles,
    saveButton :  { color: 'floralwhite', backgroundColor : '#138DE8' , margin: 2.5 , textTransform: 'None' },
    cancelButton : { color: 'floralwhite', backgroundColor : '#FF9017' , margin: 2.5, textTransform:'None'},
    card : { width : 700 }
}));

export default function RuleForm (props) {

    var point=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    point= [...obj["Points"]];

    const classes = useStyles();

    const [ currentRule, setCurrentRule ] = useState ({});
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ productCategoryList, setProductCategoryList ] = useState ([]);
    const [ tierList, setTierList ] = useState ([]);
    const { createMode, ruleId } = props;
    const [ hasError, setHasError ] = useState ({});
    const [ errorMessage, setErrorMessage ] = useState ({});
    const [ saveOpen, setSaveOpen ] = useState (false);
    const [ createOpen, setCreateOpen ] = useState (false);
    const [ open3 , setOpen3] = useState(false);
    const [ open2 , setOpen2] = useState(false);
    const [ edit , setEdit] = useState(false);

    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    }

    useEffect (() => {
        setRuleLoading (true);
        pointsApiService.fetchProductCategories ()
        .then (response => {  
            if (! response || ! response.data) { return HWMessage.show ("Response cannot be fetched","error"); }
            setProductCategoryList (response.data.productCategoryList);
            
        })
        .catch (error => {  });

        pointsApiService.fetchTiers () 
        .then (response => {  
            if (! response || ! response.data) { return HWMessage.show ("Response cannot be fetched","error") }
            setTierList (response.data.tiers);
        })
        .catch (error => { HWMessage.show ("Error while fetching tiers","error"); })
        .finally (() => setRuleLoading (false));
        
        if (ruleId && ruleId !== null) {
            setRuleLoading (true);
            pointsApiService.fetchPointsRule (ruleId)
            .then (response => {  
                if (! response || ! response.data) { return HWMessage.show ("Error while fetching response","error"); }
                setCurrentRule (response.data);
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { HWMessage.show ("Error while fetching Rule","error"); })
            .finally (() => { setRuleLoading (false) });
        }
    }, []);


    const isValid = rule => {
        let { validityStart, validityEnd } = rule;
        var startDate = new Date (validityStart);
        var endDate = new Date (validityEnd);
        var currentDate = new Date();
        
        return ! (endDate >= currentDate);
    }; 
    const isDisabled = false;

    const updateRule = useCallback (rule => {
        if (rule !== null) {
            let successStatus = null;
            setRuleLoading (true);
            pointsApiService.updateRule (rule) 
            .then (response => {  
                if (! response || ! response.data) { HWMessage.show ("Error while fetching response","error"); }
                setCurrentRule (response.data);
                props.setRules (prev => {  
                    for (var i = 0; i < prev.length; i++) {
                        if (prev[i].id === rule.id) {
                            prev[i].multiplier = response.data.multiplier;
                            prev[i].valueCeiling = response.data.valueCeiling;
                        }
                    }
                    return [ ...prev ];
                    HWMessage.show("Request Successful" , "success");
                })
                successStatus = true;
                setOpen2(true);

            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);  
                props.setCreateMode(prev=>!prev) ; 
            });
        }
    });
    const createRule = useCallback (rule => {
        if (rule !== null)  {
            let successStatus = null;
            setRuleLoading (true);
            pointsApiService.createRule (rule)
            .then (response => {
                if (! response || ! response.data) { HWMessage.show ("Error while fetchinmg response","error"); }
                setCurrentRule (response.data);
                props.setRefreshState (prev => !prev);
                successStatus = true;
                setOpen3(true);

                HWMessage.show("Request Successful" , "success");
            }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
            successStatus = false; })
            .finally (() => { 
                setRuleLoading (false);
                props.setCreateMode(prev=>false) ; 
            });
        }
    });

    const validateValues = (field, value) => {
        switch (field) {
            case 'earningCategory' : 
                if (value === null || value === '') { 
                    setHasError ({ ...hasError, [ field ] : true });
                    setErrorMessage ({ ...errorMessage, [ field ] : value });
                    return false;
                }
                break;

            case 'membershipTier': 
                if (value === null || value === '') {
                    setHasError ({ ...hasError, [ field ] : true });
                    setErrorMessage ({ ...errorMessage, [ field ]: 'Invalid Value' });
                    return false;
                }
                break;

            case 'multiplier' : 
                var parsedValue = parseInt (value);
                if (parsedValue < 0 || parsedValue === null) {
                    setHasError ({ ...hasError, [ field ]: true });
                    setErrorMessage ({ ...errorMessage, [ field ]: 'Invalid Value' });
                    return false;
                }
                break;

            case 'valueCeiling': 
                var parsedValue = parseInt (value);
                if (parsedValue === null || parsedValue < 0) {
                    setHasError ({ ...hasError, [ field ]: true });
                    setErrorMessage ({ ...errorMessage, [field] : 'Invalid Value' });
                    return false;
                }
                break;

            case 'validityStart': 
                if (value === null || value === '') {
                    setHasError ({ ...hasError, [ field ]: true });
                    setErrorMessage ({ ...errorMessage, [ field ]: 'Invalid Value' });
                    return false;
                }
                break;
            case 'validityEnd': 
                var currentDate = new Date() ;
                var validityStart = new Date(currentRule.validityStart) ;
                var maxDate = (currentDate > validityStart ? currentDate : validityStart  ); 
                if (value === null || value === '' || value < maxDate) {
                    setHasError ({ ...hasError, [ field ] : true });
                    setErrorMessage ({ ...errorMessage, [ field ] : 'Invalid Validty' });
                    return false;
                }
                break;
        }
        setHasError ({ ...hasError, [ field ]: false });
        setErrorMessage ({ ...errorMessage, [ field ]: null });
        return true;
    };

    const captureChanges = event => { 
        const field = event.target.name;
        const value = event.target.value;

        console.log(currentRule , field, value);
        let check = validateValues (field, value);

        
        if ( [ 'membershipTier', 'earningCategory', 'multiplier', 'valueCeiling' , 'validityStart' , 'validityEnd' , 'description' ].indexOf (field) === -1 ) {
            setCurrentRule (prev => ({ ...prev, [ field ] : value } )); 
        } else {
            let id = null;
            if (field === 'multiplier') {
                setCurrentRule (prev => ({ ...prev, [ field ] : parseFloat (value) }));
            } else if (field === 'valueCeiling') { setCurrentRule (prev => ({ ...prev, [field] : parseInt (value) })); }
              else if (field === 'description' ) { setCurrentRule (prev => ({ ...prev, [field] : value })); }
              else if (field === 'validityStart' ) { setCurrentRule (prev => ({ ...prev, [field] : value })); }
              else if (field === 'validityEnd' ) { setCurrentRule (prev => ({ ...prev, [field] : value })); }
        }
    };
    const handleSave = () => {
        if (props.createMode === true) { createRule (currentRule); } 
        else if (props.createMode === false) { updateRule (currentRule); }
    };
    const handleCancel = () => { props.setOpen(false); };

    return (
        <>
            <BlockUi blocking = { ruleLoading } message = { 'Loading Rules' } > 
                <Card className = { classes.card } >
{ ! (ruleId && ruleId !== null) &&    <CardHeader> <h4>   Create New Earning Rule  </h4>  </CardHeader>
}                 
{ (ruleId && ruleId !== null) &&  <CardHeader> <h4> Update Rule  &nbsp; <a><Edit style={{color: "#138de8"}} onClick={e=>setEdit(!edit)} /></a> </h4>  </CardHeader>
}
                    
                    <CardBody>  
                        <GridContainer>
                            <GridItem xs = {12} md = {6} sm = {12} >  
                                { productCategoryList && <DropDown 
                                    labelCategory = "Earning Category" 
                                    name = "earningCategory"
                                    minWidth = {300}
                                    values = { productCategoryList && productCategoryList.map (i => i.categoryName) }
                                    targetValue = { currentRule.categoryName && currentRule.categoryName  }
                                    onChange = { e => { setCurrentRule (prev => { prev.categoryName = e.target.value; prev.earningCategoryId = productCategoryList.filter(i => i.categoryName === e.target.value)[0].id; return {  ...prev };  }); } }
                                    onBlur = { captureChanges  }
                                    helperText = { fetchErrorMessage ('category') }
                                    hasError = { checkError ('category') }
                                    disabled = { isDisabled || (props.createMode === false ? !edit : false) }
                                />  }
                            </GridItem>
                            <GridItem xs = {12} md = {6} sm = {12} >  
                                <DropDown 
                                    labelCategory = "Membership Tier" 
                                    name = "membershipTier"
                                    values = { tierList && tierList.map (i => i.tierName) }
                                    minWidth = {300}
                                    targetValue = { currentRule && currentRule.tierName }
                                    onChange = { e => { setCurrentRule(prev => {  prev.tierName = e.target.value; prev.membershipTierId = tierList.filter (i => i.tierName === e.target.value)[0].id; return { ...prev }; });  } }
                                    onBlur = { captureChanges }
                                    helperText = { fetchErrorMessage ('membershipTier') }
                                    hasError = { checkError ('membershipTier') }
                                    disabled = { isDisabled || (props.createMode === false ? !edit : false) }
                                />  
                            </GridItem>
                            <GridItem xs = {12} md = { 6 } sm = {12} >  
                                <HWInput required = {true} disabled = { props.createMode === false ? !edit : false } hasError = { checkError ('multiplier') } helperText = { fetchErrorMessage ('multiplier') } name = { 'multiplier' } type = { 'number' } value = { currentRule.multiplier } onBlur = { captureChanges } label = { 'Multiplier' } /> 
                            </GridItem>
                            <GridItem xs = {12} md = {6} sm = {12} > <HWInput disabled = { props.createMode === false ? !edit : false } hasError = { checkError ('valueCeiling') } helperText = { fetchErrorMessage ('valueCeiling') } name = { 'valueCeiling' } type = { 'number' } value = { currentRule.valueCeiling } onBlur = { captureChanges } label = { 'Maximum Value' } /> </GridItem>
                            <GridItem xs = {12} md = {12} sm = {12} > <HWInput disabled = { props.createMode === false ? !edit : false } hasError = { checkError ('description') } helperText = { fetchErrorMessage ('description') }  name = { 'description' }  value = { currentRule.description } onBlur = { captureChanges } label = { 'Description' } /> </GridItem>
                            <GridItem xs = {12} md = {12} sm = {12} > 
                                <GridContainer >
                                    <GridItem xs = { 6 } md = {6} sm = {6} > 
                                        <HWInput type = {'date'} name = { 'validityStart' } 
                                            value = {currentRule && currentRule.validityStart } onBlur = { captureChanges } 
                                            hasError = { checkError ('validityStart') } 
                                            helperText = { fetchErrorMessage ('validityStart') } 
                                            label = { 'Validity Start' } 
                                            required = {true}
                                            labelProps = {{ shrink: true }} 
                                            inputProps={{min : new Date().toISOString().split('T')[0]}} 
                                            disabled={props.createMode === true ? false : edit===false ? true : new Date(currentRule.validityStart) < new Date() ? true : false  }
                                        /> 
                                    </GridItem> 
                                    <GridItem xs = { 6 } md = {6} sm = {6} >
                                         <HWInput 
                                        // disabled = { isDisabled || (props.createMode=== true && ! currentRule.validityStart) || (props.createMode === false && edit === false) } 
                                        type = { 'date' } 
                                        name = { 'validityEnd' }  
                                        value = {currentRule &&  currentRule.validityEnd} 
                                        disabled={props.createMode === true ? (isDisabled || (! currentRule.validityStart) ) : edit===false ? true : new Date(currentRule.validityEnd) < new Date() ? true : false  }
                                        onBlur = { captureChanges } 
                                        hasError = { checkError ('validityEnd') } 
                                        helperText = { fetchErrorMessage ('validityEnd') } 
                                        label = { 'Validity End' } 
                                        labelProps = {{ shrink: true }}
                                        inputProps={{min : new Date().toISOString().split('T')[0]}}
                                        required = {true}
                                    /> 
                                    </GridItem> 
                                </GridContainer> 
                            </GridItem>
                            <GridItem xs = {12} sm = {12} md = {12} >
                                {  <center>
{                     point.includes('Action')  === true   &&  ! (ruleId && ruleId !== null)  &&        <Button onClick = { e => props.createMode === true ? setCreateOpen (true) : setSaveOpen (true) } raised variant="contained" className = { classes.saveButton } > { 'Create Rule' } </Button>
}                                  
{                     point.includes('Action')  === true   &&   (ruleId && ruleId !== null) &&    edit === true  &&   <Button onClick = { e => props.createMode === true ? setCreateOpen (true) : setSaveOpen (true) } raised variant="contained" className = { classes.saveButton } > { 'Update Rule' } </Button>
}                                  
                                    <Button variant="contained" onClick = { e=> {props.setOpen(false) } } raised className = { classes.cancelButton } > Cancel </Button>
                                    <ConfirmationDialog open = { createOpen } setOpen = { setCreateOpen } confirmingAction = { handleSave } dialogMessage = { 'Are you sure you want to create this rule' } dialogTitle = { 'Confirm Rule Creation' } confirmingAction = { handleSave } />
                                    <ConfirmationDialog open = { saveOpen } setOpen = { setSaveOpen } confirmingAction = { handleSave } dialogMessage = { 'Are you sure want to update the rule. This will affect multiple wards systems and validity' } dialogTitle = { 'Confirm Rule Updation' } confirmingAction = { handleSave } />
                                    <StatusDialog isLoading = {false} open= { open3 } setOpen={setOpen3}  sucessStatus={"success"} confirmingAction={e=>props.setOpen (false)} message={"Rule successfully created."} dialogTitle={"Status"}/> 
                                <StatusDialog isLoading = {false} open= { open2 } setOpen={setOpen2}  sucessStatus={"success"} confirmingAction={e=>props.setOpen (false)} message={"Rule successfully updated."} dialogTitle={"Status"}/> 

                                </center>}
                            </GridItem>
                        </GridContainer>
                    </CardBody> 
                </Card>
            </BlockUi>
        </>
    );

};