import { AppBar, Button, ButtonGroup, Card, CardActionArea, CardActions, CardMedia, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, IconButton, InputLabel, List, ListItem, ListItemText, makeStyles, Menu, MenuItem, Paper, Popover, Select, setRef, Tab, Tabs, TextField, Tooltip, Typography,DialogContentText } from '@material-ui/core';
import { DataGrid, GridToolbar, setRowCountStateUpdate } from '@material-ui/data-grid';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 

import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState, useCallback, useEffect } from 'react';
import { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import  SearchBar  from './../Merchant/SearchBar';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import { HWInput } from 'HWLibrary/HWInput';
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle';

import DescriptionIcon from '@material-ui/icons/Description';
import CardIcon from 'components/Card/CardIcon';
import { Edit, VerifiedUser } from '@material-ui/icons';
import { vocRequestApiService } from './ApiService';
import HWMessage from 'HWLibrary/HWMessage';
import memberApiService from 'views/Members/APIService';
import BlockUi from 'react-block-ui';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import DescriptionPopover from 'views/DescriptionPopup/DescriptionPopup';
import { getElement } from 'dropzone';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import cloudfrontConfig from 'HWLibrary/HWCloudfrontConfig';
import {CustomToolbar} from '../Members/Member.js'
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";



const useStyles = makeStyles (theme => ({
    
    cardFooter : { justifyContent : 'center' },
    cardHeader : { height : '50px' },   
    formControl: { margin: theme.spacing(1), minWidth: 120 }, 

    root: { '& .MuiButton-label' : { color : '#138DE8' } },
    newPosOfDialog: {
        position: "absolute",
        top: "50%",
        left: "70%",
        transform: "translate(-50%, -50%)",
      }
}));
const useBriefStyles = makeStyles ( theme => ({ ...styles }));

export function DirectActionDialog (props) {
    const [ rejectionDescription, setRejectionDescription ] = useState ('');
    const [ descriptionOpen, setDescriptionOpen ] = useState (false);
    const [ descriptionAnchorEl, setDescriptionAnchorEl ] = useState (null);

    const handleRejectionSubmit = () => { props.rejectAction(); props.setDirectActionOpen (false); };
    const handleRejectClick = event => {  setDescriptionOpen (true); setDescriptionAnchorEl (event.currentTarget); };

    return (
        <Fragment>
            <div>
                <Popover
                    open={ props.open }
                    anchorEl={ props.anchorEl}
                    onClose={  (event) =>  props.setDirectActionOpen (false)  }
                    //anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    //transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <div >
                        <div style = {{ margin : '20px'}} > 
                            <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', right : '5px' , textTransform: 'None'}} onClick = { e => props.approvalAction() } > Approve </Button> 
                            <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite' , textTransform: "None"}} onClick = { handleRejectClick } > Reject </Button> 
                        </div> 
                    </div>
                </Popover>
            </div>
            <DescriptionPopover
                label = { 'Reason for Rejection' }
                description = { rejectionDescription }
                handleSubmit = { handleRejectionSubmit }
                setDescription = { setRejectionDescription }
                open = { descriptionOpen }
                anchorEl = { descriptionAnchorEl }
                setAnchorEl = { setDescriptionAnchorEl }
                placeholder = { 'Enter Reason' }
                setOpen = { setDescriptionOpen }
                reason = {props && props.reason}
                setReason = {props && props.setReason}
            />   
        </Fragment>
    );
};

export function EnlargedImageDialog (props) {
    const classes = useStyles();

    return (
        <Dialog  classes={{
            paper: classes.newPosOfDialog
          }} open = { props.open } disableBackdropClick = {false} onClose = { (event) => { props.setOpen (false) } } >
            <DialogTitle> { props.imageName } </DialogTitle>
            <DialogContent>
                
                        <img src={ props.openedImage } height="700" width="700"/>
                    
                
               
            </DialogContent>
        </Dialog>
    );
};

export function VOCBriefDialog (props) {
    var action=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    action= [...obj["Actions"]];

    const classes = useBriefStyles();

    const createViewModel = (model) => {
        console.log("Model", model);
        if (model) {
            var modelStatus = model.status;
            var verificationMode = model.vocVerificationMode;
            if (verificationMode) verificationMode = verificationMode.charAt(0).toUpperCase() + verificationMode.slice(1);
            if (modelStatus) modelStatus = modelStatus.charAt(0).toUpperCase() + modelStatus.slice(1);

            if(modelStatus === "Pending"){
                return {
                    'plateNumber' : model.plateNumber,
                    'icNumber' : model.icNumber,
                    'engineNumber' : model.engineNumber,
                    'chassisNumber' : model.chassisNumber,
                    'modelNumber' : model.modelNumber,
                    'vocVerificationMode' : verificationMode,
                    'status' : modelStatus, 
                    'registrationDate' : model.registrationDate ? model.registrationDate : null
                    
                }
            }

            else{
                return {
                    'plateNumber' : model.plateNumber,
                    'icNumber' : model.icNumber,
                    'engineNumber' : model.engineNumber,
                    'chassisNumber' : model.chassisNumber,
                    'modelNumber' : model.modelNumber,
                    'vocVerificationMode' : verificationMode,
                    'status' : modelStatus, 
                    'registrationDate' : model.registrationDate ? model.registrationDate : null,
                    // 'rejectedReason' : model.rejectedReason ? model.rejectedReason :null ,
                    // 'approvedDate' : model.approvedDate ? model.approvedDate :null ,
                    // 'approvedOrRejectedBy' : model.approvedOrRejectedBy ? model.approvedOrRejectedBy :null ,
    
                };
            } 
            }
         
        return null;
    };
    var updateDetail = props.memberDetails;
    const [enlargedImage, setEnlargedImage] = useState (false);
    const [ imageChosen, setImageChosen ] = useState (-1);
    const [ currentVOC, setCurrentVOC ] = useState (props.memberDetails); 
    const [ imageName, setImageName ] = useState (null);

    const enlargeImage = (imageLink, name) => {setEnlargedImage (true); setImageChosen(imageLink); setImageName (name)};
    const [ canEdit, setCanEdit ] = useState (false);
    const [ showEdit,  setShowEdit ] = useState (true);
    const [ rejectionDescription, setRejectionDescription ] = useState ();
    const [ descriptionOpen, setDescriptionOpen ] = useState ();
    const [ descriptionAnchorEl, setDescriptionAnchorEl  ] = useState (null);
    const [ viewModel, setViewModel ] = useState ( createViewModel (props.memberDetails) );
    
    const handleRejectionSubmit = event => {  updateDetail.rejectedReason = rejectionDescription;  props.onRejectAction (updateDetail);}
    const handleRejectClick = event => {  setDescriptionOpen (true); setDescriptionAnchorEl (event.currentTarget); setShowEdit (true); };
    
    useEffect (() => { 
        var view = createViewModel (props.memberDetails);
        var details = props.memberDetails;
        setViewModel(view); 
        setCurrentVOC (details);
    }, [ props.memberDetails ]);

    const handleChange = (event) => { updateDetail[event.target.name] = event.target.value; };
    const getStatusText = (status) => {
        switch (status) {
            case 'Verified' : return '#16AD4B'; 
            case 'Rejected' : return '#AD163F';
            case 'Pending' : return '#EA9C1A';
        }
    };
    return (
        <Fragment>
            <Dialog open = { props.open }   maxWidth = "xl" disableBackdropClick = {false} onClose = { (event) => { setCanEdit(false); props.setOpen (false) } }  >
                <DialogTitle> 
                    <Typography style = {{ color : getStatusText (currentVOC.status) }} variant="h5" component="h2" gutterBottom >
                        VOC Verification : <b> {viewModel.status} </b>
                    </Typography>
                    
                </DialogTitle>
                <DialogContent style = {{ width : '1000px' }} >
                    <GridContainer>
                        <GridItem xs = {6} >
                            <Card>
                                <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                                <div style={{display:"flex" }}>
                                <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <VerifiedUser /> </CardIcon>
                                    <h4 className = { classes.cardIconTitle } > Verify VOC Details </h4> 
                                    <Tooltip title="Edit Member">
                                        <IconButton aria-label="new media" > 
                                            { (currentVOC.status === 'Pending' || currentVOC.status === 'PreApproved' || currentVOC.status === 'PreOwned') && showEdit &&   action.includes("Action") === true &&  <Edit style={{color:"#138de8"}} width="20" height='20' onClick = { (event) => { setCanEdit (!canEdit); } } />  }
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                   
                                </CardHeader>
                                <CardBody>
                                    
                                    <List dense >
                                        {
                                        Object.keys(viewModel)
                                            .filter(item => Object.keys(viewModel).indexOf(item) !== -1)
                                            .map (key => (
                                                <>
                                                <ListItem  >
                                                    <GridContainer> 
                                                        <GridItem xs = {6} style = {{ marginTop : '15px' }} > <ListItemText primary = { props.modelMap[key] } > </ListItemText> </GridItem>
                                                        <GridItem xs = {6} > 
                                                            <HWInput 
                                                                name = { key } 
                                                                type = { key === 'registrationDate' ? 'date' : 'text' }
                                                                value = { !canEdit ? viewModel[key] : updateDetail[key] } 
                                                                disabled = { !canEdit } 
                                                                onBlur = { handleChange } 
                                                                onChange = { handleChange } 
                                                                required={true}
                                                            />    
                                                        </GridItem>
                                                    </GridContainer>
                                                    
                                                </ListItem>
                                                <Divider />
                                                </>
                                            ))
                                        }
                                    </List>
                                </CardBody>
                                { props.memberDetails && (props.memberDetails.status === 'Pending' || props.memberDetails.status === 'PreApproved' || props.memberDetails.status === 'PreOwned') &&
                                <CardActions style = {{ marginLeft : '130px' }}> 
                                    <center>
                                        {action.includes("Action") === true &&  <Button variant = 'contained' style = {{ color : 'floralwhite', backgroundColor : '#FF9017', textTransform: 'None' }} onClick = { handleRejectClick } > Reject </Button>}                                      
                                        {action.includes("Action") === true &&  <Button variant = 'contained' style = {{ color : 'floralwhite', backgroundColor : '#138DE8' , textTransform:'None',marginLeft:10 }} onClick = {  () => {props.approvalAction(updateDetail); setShowEdit (true); } } > Approve </Button>}
                                        {action.includes("Action") === true &&  <Button variant = 'contained' style = {{ color : 'floralwhite', backgroundColor : '#138DE8' , textTransform:'None',marginLeft:10 }} onClick = {  () => {props.reverificationAction(updateDetail); setShowEdit (true); } } > Check </Button>}                                      
                                    </center>
                                </CardActions>
                                }
                            </Card>
                        </GridItem>
                        <GridItem xs = {6} >
                            <h2> VOC Uploaded </h2>                        

                            <GridContainer  > 
                            { 
                            props.memberDetails.vocCopyFileNames && 
                            props.memberDetails.vocCopyFileNames.map((imageName, idx) => (
                                <Fragment>
                                    
                                    <GridItem xs = {6} style = {{ marginBottom : '10px', width : '150px' }} >
                                        <Paper elevation = {5} style = {{ width : 'fit-content' }} >
                                            <img 
                                                name = "hello"
                                                src = { `${cloudfrontConfig.cloudfrontBaseUrl}/VOC-images/${imageName}`} 
                                                style = {{ height : '150px', width : '150px' }} 
                                                onClick = { () => {enlargeImage (`${cloudfrontConfig.cloudfrontBaseUrl}/VOC-images/${imageName}`, imageName) } } 
                                            />
                                        </Paper>
                                    </GridItem>
                                </Fragment>
                            ) )
                            }
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                    {enlargedImage && <Lightbox image={imageChosen} onClose = { () => { setEnlargedImage(false) } }/> }
                    {/* <EnlargedImageDialog openedImage = { imageChosen } imageName = { imageName } open = { enlargedImage } setOpen = { setEnlargedImage } /> */}
                </DialogContent>
            </Dialog>
            <DescriptionPopover
                label = { 'Reason for Rejection' }
                description = { rejectionDescription }
                handleSubmit = { handleRejectionSubmit }
                setDescription = { setRejectionDescription }
                open = { descriptionOpen }
                anchorEl = { descriptionAnchorEl }
                setAnchorEl = { setDescriptionAnchorEl }
                placeholder = { 'Enter Reason' }
                setOpen = { setDescriptionOpen }
                reason = {props.reason}
                setReason={props.setReason}
            />   
        </Fragment>
    );
}

export function PeriodDialog (props) {

    const classes = useStyles ();

    const [ startDate, setStartDate ] = useState (null);
    const [ endDate, setEndDate ] = useState (null);
    

    const handleStartDate = (event) => { 
        setStartDate (event.target.value);
    };
    const handleEndDate = (event) => { 
        setEndDate (event.target.value);
    };

    const handleApplyConfirm = () => {

        var startDt = new Date(startDate);
        var endDt = new Date (endDate);

        if (startDate && !endDate) {
            var d = new Date(); 
            var isoString = d.toISOString();
            setEndDate(isoString);
        }

        if (startDt > endDt) { 
            HWMessage.show ("Start date must be before or equal to end date"); 
            return console.log("Start date must be less or equal to end date", startDate, endDate);
        }
        props.setStartDate (startDate);
        props.setEndDate (endDate);

        props.openPeriodDialog (false);
    };

    const handleCancel = () => props.openPeriodDialog (false); 
    const [ periodOptions, setPeriodOptions ] = useState (['Last 7 Days', 'Last 15 Days', 'Last 30 days', 'Last 60 Days', 'Last 3 Months', 'Last 6 Months', 'Last 1 year', 'Last 2 year', 'All Time']);
    const [ chosenPeriod, setChosenPeriod ] = useState ('All Time');

    const handlePeriodChoice = (event) => {
        var currDate = new Date();
        var isoString = currDate.toISOString();
        var endDateString = isoString.split('T')[0];
        setEndDate(endDateString);
        switch (event.target.value) {
            case 'All Time' : setEndDate (null); setStartDate(null); break;
            case 'Last 7 Days' : 
                var d = new Date(); d.setDate(d.getDate() - 7);
                var startDateString = d.toISOString().split('T')[0];
                setStartDate (startDateString);
                break;
            case 'Last 15 Days' : 
                var d = new Date (); d.setDate(d.getDate() - 15);
                var startDateString = d.toISOString().split('T')[0];
                setStartDate (startDateString);
                break;

            case 'Last 30 days' : 
                var d = new Date(); d.setDate (d.getDate() - 30);
                var startDateString = d.toISOString().split('T')[0];
                setStartDate (startDateString);
                break;
            
            case 'Last 60 Days' : 
                var d = new Date (); d.setDate (d.getDate() - 60);
                var startDateString = d.toISOString().split('T')[0];
                setStartDate (startDateString);
                break;
            
            case 'Last 3 Months' : 
                var d = new Date(); d.setMonth(d.getMonth() - 3);
                var startDateString = d.toISOString().split('T')[0];
                setStartDate (startDateString);
                break;
            
            case 'Last 6 Months' : 
                var d = new Date(); d.setMonth(d.getMonth() - 6);
                var startDateString = d.toISOString().split('T')[0];
                setStartDate (startDateString);
                break;    
            
            case 'Last 1 year' : 
                var d = new Date(); d.setFullYear(d.getFullYear() - 1);
                var startDateString = d.toISOString().split('T')[0];
                setStartDate (startDateString);
                break; 
            
            case 'Last 2 year' : 
                var d = new Date(); d.setFullYear(d.getFullYear() - 2);
                var startDateString = d.toISOString().split('T')[0];
                setStartDate (startDateString);
                break; 
        }
        setChosenPeriod (event.target.value)
    };

    return (
        <Fragment>
            <Dialog open = { props.periodDialog } disableBackdropClick = {false} onClose = { (event) => { props.openPeriodDialog (false) } } >
                <DialogTitle> Choose Time Period to Filter </DialogTitle>
                <DialogContent> 
                    <GridContainer>
                        <GridItem xs = {12} >
                            <GridContainer>
                                <GridItem xs = {6} >  
                                    <InputLabel style = {{ marginBottom : '-16px' }} >Start Date</InputLabel>
                                    <HWInput required={true}type = "date" name = "startDate" value = { startDate } onBlur = { event => handleStartDate(event) } />
                                </GridItem>

                                <GridItem xs = {6} > 
                                    <InputLabel style = {{ marginBottom : '-16px' }} >End Date</InputLabel>
                                    <HWInput required={true} type = "date"  name = "endDate" value = { endDate } onBlur = { event => handleEndDate(event) } />
                                </GridItem>
                            </GridContainer>
                        </GridItem>

                        <GridItem xs = {12} >  <center> <h2> <b> OR  </b> </h2>  </center> </GridItem>
                        
                        <GridItem  xs = {12}  > 
                            <FormControl className = { classes.formControl } fullWidth >
                                <InputLabel > Choose Another Time Period </InputLabel>
                                <Select 
                                    value = { chosenPeriod }
                                    onChange = { handlePeriodChoice }
                                >
                                    { periodOptions.map (period => (<MenuItem value = {period} > {period} </MenuItem> )) }
                                </Select>
                                <FormHelperText> Select Different Time Periods </FormHelperText>
                            </FormControl>
                        </GridItem>
                    </GridContainer>

                </DialogContent>
                <DialogActions> 
                    <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { handleCancel } > Cancel </Button>
                    <Button variant = "contained" style = {{ backgroundColor : '#FF9017', color : 'floralwhite', textTransform : 'none' }} onClick = { handleApplyConfirm } > Apply  </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default function VOCVerification (props) {
    var action=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    action= [...obj["Actions"]];

    const classes = useStyles ();
   
    if(HWLocalStorage.load("previousPointsActionsTab")!== null)
    {
        HWLocalStorage.clear("previousPointsActionsTab");
    }
    HWLocalStorage.save (0, "previousActionsTab");

    const [ isLoading, setIsLoading ] = useState (false);
    const [ periodDialog, openPeriodDialog ] = useState (false);
    const [ currentDataCard, setCurrentDataCard ] = useState ( {index : -1, title : 'VOC PENDING', type : 'pending' });
    const [ dataList, setDataList ] = useState ([
        { index : 0,  title : 'VOC REQUESTS RAISED', type : 'all' },
        { index : 1, title : 'REQUESTS APPROVED', type : 'approved' }, 
        { index : 2, title : 'REQUESTS REJECTED', type : 'rejected' }
    ]);
    const [ startDate, setStartDate ] = useState ();
    const [ endDate, setEndDate ] = useState ();

    const [ directActionOpen, setDirectActionOpen ] = useState (false);
    const [ anchorEl, setAnchorEl ] = useState (null);
    const [ reqList, setReqList ] = useState ({});
    const [ countsMap, setCountsMap ] = useState ({});
    const [ rows, setRows ] = useState ([]);
    const [ updateLoading, setUpdateLoading ] = useState (false);
    const [ refreshState, setRefreshState ] = useState (false);
    const [ page, setPage ] = useState (0);
    const [ pageSize, setPageSize ] = useState (100);

    const modelMap = { "modelNumber" : "Model", "plateNumber" : "Plate Number", 'icNumber' : 'IC Number', "engineNumber" : "Engine Number", "chassisNumber" : "Chassis Number", "registrationDate" : "Registration Date", 'vocVerificationMode' : 'VOC Verification Mode', 'status' : 'Verification Status' };
    const [ memberDetails, setMemberDetails ] = useState ({ });
    const [ searchText, setSearchText ] = useState ('');

    const [ memberDialogOpen, setMemberDialogOpen ] = useState (false);
    const  [ rowCount, setRowCount ] = useState (0);
    const [ filterModel, setFilterModel ] = useState ({});
    const [ sortModel, setSortModel ] = useState ({});
    const [ countLoading, setCountLoading ] = useState (false);
    const [ forcefulPrompt, setForcefulPrompt ] = useState (false);
    const [ reason , setReason ] = useState("")
    const [notAllowedToApprove,setNotAllowedToApprove] = useState(false)
    const [notAlowedToApproveMsg,setNotAllowedToApproveMsg] = useState('')

    const history = useHistory();
    const openMemberDetails = useCallback((id) => {
        if(id === null || id === "" || id === undefined)
        {
            HWMessage.show("Member not available for this plate number" , "error");
            return;
        }
        HWLocalStorage.clear("previousMemberTab");
        history.push({pathname : "member-tabs", params : {state: { id }}});
    }, []);


    const getTextColor = status => {
        switch (status) {
            case 'PreApproved' :
            case 'PreOwned':    
            case 'Pending' : return '#FF9017';
            case 'Verified' : return '#1f7a1f';
            case 'Rejected' : return '#cc0000';
        }
    };

    const getStatusName = (verificationMode, status) => {
        switch (status) {
            case 'Pending': 
                switch (verificationMode) {
                    case 'Manual' : return 'Manual Check';
                    case 'Jpj' : return 'Automatic Approval'
                }
            case 'PreApproved': return 'Pre Approved Check';
            case 'PreOwned':  return 'Pre Owned Check';
            case 'Assigned': return 'Assigned';
            case 'Verified': return 'Verified';
            case 'Rejected': return 'Rejected';
            case 'Removed': return 'Removed';
        }
    };
    const columns = [
        { field: 'plateNumber', headerName: 'Plate Number', flex : 2,minWidth:150, renderCell : (cellParams) => ( <span onClick = { () => { openMemberDetails(cellParams.row.memberId); } } > <Link style = {{ color : '#138DE8', fontWeight : 400  }} > { cellParams.value } </Link>  </span> ) },
        { field: 'status',filterable :  true , headerName: 'Status',  flex : 2,minWidth:120, renderCell : cellParams => 
            <span style = {{ color : getTextColor(cellParams.row.status) }}>  
                
                    {/* { cellParams.row.status[0].toUpperCase() }{ cellParams.row.status.slice(1) }  */}
                    { getStatusName (cellParams.row.vocVerificationMode, cellParams.row.status) }
                
            </span> },
        { field: 'membershipNumber', filterable :  false ,  headerName: 'Membership Number',minWidth:120,  flex : 2 },
        { field: 'ownerName', headerName: 'Name', minWidth:200, flex : 2 },
        { field: 'icNumber', filterable :  false ,  headerName: 'IC Number', minWidth:120, flex : 2 },
        { field: 'VOC', filterable :  false  , headerName: 'VOC',  flex : 2, minWidth:40,sortable : false, renderCell : (cellParams) =>  (  <IconButton title = "View VOC Copy and Bike Details" onClick = { () => { console.log("Cell Pareams", cellParams.row); setMemberDetails (cellParams.row);  setMemberDialogOpen (true);} } > <DescriptionIcon color = '#138DE8' style = {{ color : '#138DE8' }} /> </IconButton>  ) },
        { field: 'registrationDate', filterable :  false ,  headerName: 'Registration Date',  flex : 2,minWidth:100, renderCell : cellParams => <span> { cellParams.row.registrationDate ? new Date(cellParams.row.registrationDate).toLocaleDateString() : null } </span> },
        { field : 'createTime', filterable :  false  , headerName: 'Added Date', flex: 2, minWidth:120,renderCell : cellParams => (<span> { cellParams.value ? new Date(cellParams.value).toLocaleDateString() : null } </span>) },
        { field : 'updateTime', filterable :  false ,headerName: 'Updated Date', flex: 2,minWidth:120, renderCell : cellParams => (<span> { cellParams.value ? new Date(cellParams.value).toLocaleDateString() : null } </span>) },
        { field : 'approvedOrRejectedBy', filterable :  false ,headerName: 'Updated By', flex: 1,minWidth:120, renderCell : cellParams => (<span> { cellParams.row.approvedOrRejectedBy != undefined ? cellParams.row.approvedOrRejectedBy: null } </span>) },
        { field: 'rejectedReason', filterable :  false ,  headerName: 'Reject Reason',  flex : 1,minWidth:200, renderCell : cellParams => <span> { cellParams.row.rejectedReason != undefined ? cellParams.row.rejectedReason : null } </span> }

    ];

    if(action.includes("Action") === true){
        columns.push( { field: 'Action', filterable :  false ,  headerName : '',  flex : 2,minWidth:50, renderCell : (cellParams) => ( <>{ (cellParams.row.status === 'Pending' || cellParams.row.status === 'PreApproved' || cellParams.row.status === 'PreOwned') && <IconButton title = "Approve / Reject VOC" onClick = { (event)  => { setMemberDetails (cellParams.row); setDirectActionOpen(true); setAnchorEl (event.currentTarget); } } > <MoreVertIcon style={{color:"#138de8"}} /> </IconButton> } </> )}
        )
    }

    useEffect (() => {
        setIsLoading (true);
        setCountLoading (true);
        vocRequestApiService.fetchCountStatistics (startDate, endDate)
        .then (response => {
            if (! response || ! response.data) { return HWMessage.show ("Counts cannot be fetched at moment", "error"); }
            setCountsMap ({ 'pending' : response.data.pendingRequests, 'approved' : response.data.approvedRequests, 'rejected' : response.data.rejectedRequests, 'all' : response.data.totalRequests });
            HWMessage.show("Request Successful" , "success");
        }).catch (error => {
            return HWMessage.show (error.response.data.message || "Error while fetching counts", "error");
        }).finally (() => {
            setIsLoading (false);
            setCountLoading (false);
        });
    }, [ refreshState, startDate, endDate, currentDataCard, sortModel, filterModel ]);
    
    useEffect (() => {
        setIsLoading (true);
        var paginationBookmark = (page) * pageSize;
        var fetchLimit = pageSize;
        var fetchStatus = `${ currentDataCard.type.charAt(0).toUpperCase() }${ currentDataCard.type.slice (1) }`
        if((searchText[0] === "M" || searchText === "Manual" || searchText === "Manual Check" ) && fetchStatus === "Pending"){
            setSearchText("Pending");
        }
        vocRequestApiService.fetch ({ fetchLimit, paginationBookmark, startDate, endDate, searchText, status : fetchStatus, filterModel, sortModel }).
        then (response => {
            if (!response || !response.data) {
                HWMessage.show ("Requests cannot be fetched at moment");
                return console.log("Responses cannot be fetched at moment");
            }
            HWMessage.show ("Requests fetched successfully", "success");
            
            setRowCount (response.data.matchingCount); 
            setRows (response.data.matchingBikes);
        }).catch (error => { 
            HWMessage.show ("Error while fetching requests"); 
            return console.log("Error while fetching requests"); 
        }).finally (() => { setIsLoading (false);
            var my_element = document.getElementById("top");
            if(my_element!== null || my_element!== undefined || my_element!== '')
            {
                // my_element.scrollIntoView({ }); 

            }
        });
    }, [ refreshState, startDate, endDate, searchText, page, pageSize, currentDataCard, sortModel, filterModel ]);

    const updateBikeDetails = useCallback ((updateRequest) => {
        var updateObject = (updateRequest ? updateRequest : memberDetails);
        if (updateObject) {
            setUpdateLoading (true);
            setIsLoading (true);
            memberApiService.saveBikeDetails (updateObject)
            .then (response => { 
                if (! response || !response.data) { 
                    HWMessage.show ("Unable to fetch response status");
                    return console.log("Cannot fetch response");
                 }
                 HWMessage.show ("Bike updated successfully", "success");
                 setMemberDetails (response.data);
                 setRowCount (rowCount - 1);
                 setRows (prev => { prev = prev.filter (i => i.id !== updateObject.id); return [ ...prev ]; })
                 
             })
            .catch (error => {  
                if (error.response.status === 406) {
                    setForcefulPrompt (true);
                }else if(error.response.status === 409) {
                    setNotAllowedToApprove(true);
                    setNotAllowedToApproveMsg(error.response.data?.message)
                }
                HWMessage.show ("Error while updating bike detail", "error");
                return console.log("Error while updating");
            })
            .finally (() => { setUpdateLoading(false); setIsLoading (false); });
        }
    });

    const reverifyBikeDetails = useCallback ((bikeId) => {
        setUpdateLoading (true);
            setIsLoading (true);
            memberApiService.reverifyBikeDetails ({bikeId: bikeId})
            .then (response => { 
                if (! response || !response.data) { 
                    HWMessage.show ("Unable to fetch response status");
                    return console.log("Cannot fetch response");
                 }
                 HWMessage.show ("Bike verified successfully", "success");
                 setMemberDetails (response.data);
                //  setRowCount (rowCount - 1);
                //  setRows (prev => { prev = prev.filter (i => i.id !== bikeId); return [ ...prev ]; })
                 
             })
            .catch (error => {  
                if (error.response?.status === 406) {
                    setForcefulPrompt (true);
                }else if(error.response.status === 409) {
                    setNotAllowedToApprove(true);
                    setNotAllowedToApproveMsg(error.response.data?.message)
                }
                HWMessage.show ("No match found", "error");
                return console.log("No match found");
            })
            .finally (() => { setUpdateLoading(false); setIsLoading (false); });
    })

    const approvalAction = (updateRequest) => {
        console.log("Current request", updateRequest, memberDetails);
        if (memberDetails.status !== 'Pending' && memberDetails.status != 'PreApproved' && memberDetails.status != 'PreOwned') {
            HWMessage.show ("Cannot update already approved VOC");
            return console.log("Details cannot be updated ");
        }
        if (! memberDetails.registrationDate) {  
            HWMessage.show ("Cannot update bike details without registration date");
            return console.log("Details cannot be updated without reg date");
        }
        memberDetails.status = 'Verified';
        
        updateBikeDetails (memberDetails);
        setRefreshState (!refreshState);
        setMemberDialogOpen (false);
        setDirectActionOpen (false);
    };
    const reverificationAction = (updateRequest) =>{
        reverifyBikeDetails(updateRequest.id)
    }
    const rejectAction = (updateRequest, description) => {
        if (memberDetails.status !== 'Pending' && memberDetails.status != 'PreApproved' && memberDetails.status != 'PreOwned') {
            HWMessage.show ("Cannot update already approved VOC", "error"); 
            return console.log("Details cannot be updated");
        }
        if (updateRequest) {
            var tReq = updateRequest;
            tReq.status = 'Rejected';
            tReq.rejectedReason = reason;
            updateBikeDetails (tReq);
        } else {  
            var tReq = memberDetails;
            tReq.status = 'Rejected';
            tReq.rejectedReason = reason;
            updateBikeDetails (tReq);
        }
        
        setRefreshState (!refreshState);
        setMemberDialogOpen (false);
        setDirectActionOpen (false); 
    };  

    const changeData = (idx) => {  
        var temp = currentDataCard;
        setCurrentDataCard(dataList [idx]);

        if (temp.index != -1) {
            dataList [idx] = dataList [temp.index];
            dataList [temp.index] = temp;
        } 
        else 
            dataList [idx] = currentDataCard;

        setDataList (dataList);
    };

    const useDataGridStyles = makeStyles(theme => ({
        filterButton: {display:'flex',flexDirection:"row-reverse",alignItems:"center"},
        root: {
            '& .MuiDataGrid-columnHeaderTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiButton-label' : { color : '#138DE8' }
        }
      }));
    
    const dataGridClasses = useDataGridStyles();

    function CustomLoadingOverlay() {
        return (
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
    };

    const handleRefresh = (refreshState) => {
        setRefreshState (refreshState);
        setSortModel ({});
        setFilterModel ({});
    };
    const forcefulUpdate = bikeDetails => {
        memberDetails.updateType = 'Forceful';
        updateBikeDetails (memberDetails);
    };

    return (
        <Fragment> 
            <BlockUi blocking = { isLoading } message = "Loading VOC Requests" tag = "div">
                <GridContainer  id="top">
                    { dataList.map ((dataType, idx) =>   (
                        <GridItem xs = {3} key = { idx } >
                        <Tooltip title = { dataType.title } >
                            <Card > 
                                <CardHeader> 
                                    <GridContainer> 
                                        <GridItem xs = {8} > <span style = {{ fontSize : '1em',fontWeight : '500' }} > { dataType.title } </span>  </GridItem>
                                        <GridItem xs = {4} >  
                                            {! countLoading && <Link onClick = { (event) => changeData (idx) } style = {{ fontSize : '1.5em', fontWeight : '500', color : '#138DE8' }}  >  { countsMap[dataType.type] }  </Link> }
                                           { countLoading && <CircularProgress /> }
                                        </GridItem>
                                    </GridContainer>
                                </CardHeader>
                            </Card>
                        </Tooltip>
                        </GridItem>
                    )) 
                    }
                    <GridItem xs = {3} className={dataGridClasses.filterButton} >
                    <div style = {{ display : 'flex', flexDirection:"row-reverse" }}> <Button variant = "contained" style = {{ backgroundColor : '#138DE8', color : 'floralwhite', textTransform : 'none' }} onClick = { openPeriodDialog } >  <FilterListOutlinedIcon /> Filter VOC Results   </Button> </div>
                    </GridItem>
                </GridContainer>

                <br />

                <GridContainer justify = "space-between" alignItems = "center" > 
                    <GridItem> <b> <span style = {{ fontWeight : '500px', fontSize : '2em' }} > { currentDataCard.title } : { ! countLoading && <a href = "#top" style = {{ color : '#138DE8', textDecoration : 'underline' }} >  { countsMap[currentDataCard.type] } </a> } { countLoading && <CircularProgress size = {25} /> } </span> </b> </GridItem>
                    <GridItem style={{margin: "0px 10px 10px 0px"}}>  
                        <SearchBar 
                            width = '300px' 
                            handleSearch = { setSearchText }
                            handleRefresh = { setRefreshState }
                            refreshState = { refreshState }
                            placeholder = "Search using Plate Number, Status, etc"
                        /> 
                    </GridItem>
                </GridContainer>
                    
                <Card >
                    <CardBody>
                        <DataGrid 
                            className = { dataGridClasses.root }
                            autoHeight
                            showToolbar 
                            density="compact" 
                            loading = {isLoading} 
                            fullWidth
                            columns={columns} 
                            rows={ rows } 
                            page = { page }
                            rowCount = { rowCount }
                            pageSize = { pageSize }
                            components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 
                            pagination
                            paginationMode = 'server'
                            onPageChange = { (gridChangeParams) => { setPage (gridChangeParams);  } }
                            onPageSizeChange = { (gridChangeParams) => { setPage (0); setPageSize(gridChangeParams); } }
                            sortingMode = "server" 
                            onSortModelChange = { params => { if (params.length > 0)  {setSortModel (params[0]);}} }
                            filterMode = 'server'
                            onFilterModelChange = { (params) => { if (params.items.length > 0) { setFilterModel (prev => ({ ...params.items[0] }));  } } }
                        />
                    </CardBody>
                </Card>    

                <PeriodDialog 
                    periodDialog = { periodDialog } 
                    openPeriodDialog = { openPeriodDialog } 
                    startDate = { startDate } 
                    endDate = { endDate } 
                    setStartDate = { setStartDate }
                    setEndDate = { setEndDate }
                />

                <DirectActionDialog 
                    open = { directActionOpen }
                    dialogTitle = { 'Direct VOC Approval' }
                    dialogContent = { 'Do you want to approve or reject the VOC' }
                    approvalAction = { approvalAction }
                    rejectAction = { rejectAction }
                    setDirectActionOpen = { setDirectActionOpen }
                    anchorEl = { anchorEl }
                     reason = {reason}
                    setReason= {setReason}
                />

                <VOCBriefDialog 
                    open = { memberDialogOpen }  
                    setOpen = { setMemberDialogOpen }
                    memberDetails = { memberDetails }
                    modelMap = { modelMap }
                    onRejectAction = { rejectAction }
                    approvalAction = { approvalAction }
                    reverificationAction = { reverificationAction}
                    reason = {reason}
                    setReason= {setReason}
                />
                <ConfirmationDialog
                    confirmingAction = { forcefulUpdate }
                    open = { forcefulPrompt } 
                    setOpen = { setForcefulPrompt }
                    dialogMessage = { 'This bike is already verified for other members do you still want to approve for this member ?' }
                    dialogTitle = { 'Conflict while updating this bike for this member.' }
                />
                <ConfirmationDialog
                    open = { notAllowedToApprove } 
                    setOpen = { setNotAllowedToApprove }
                    dialogMessage = {notAlowedToApproveMsg }
                    dialogTitle = { 'Conflict while updating this bike for this member.' }
                />   
            </BlockUi>
        </Fragment>
    );
}