
import { ButtonGroup, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, makeStyles, TextField } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import roleApiService from 'views/Merchant/Roles/APIService';
import BlockUi from 'react-block-ui';
import Loader from 'react-loaders';
import { useHistory } from 'react-router-dom';
import StatusDialog from 'views/Utils/StatusDialog';
import EditIcon from '@material-ui/icons/Edit';
import HWMessage from 'HWLibrary/HWMessage';
import { HWInput } from 'HWLibrary/HWInput';
import settingsApiService from './APIService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
}));

const ConfirmationDialog = (props) => {

    console.log("Confirmation Dialog", props.currentObject);
    const handleClose = () => props.setDialogOpen (false);


    const validateNewRole = roleObject => {
        console.log("Create object", roleObject);
        return (roleObject.roleName !== null && roleObject.roleName !== ''); 
    };

    const handleUpdate = () => {         
        if (props.createNewRole) {
            if (props.rule !== null)  {
                let successStatus = null;
                settingsApiService.createRole (props.rule)
                .then (response => {
                    if (! response || ! response.data) { HWMessage.show ("Error while fetchinmg response" ,"error"); }
                    props.setRefreshState (prev => !prev);
                    successStatus = true;
                    HWMessage.show("Request Successful" , "success");
                }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
                successStatus = false; })
                .finally (() => { 
                    if (successStatus === true)
                        {props.setOpen (false);
                     props.setDirectActionOpen(false) ;}
                });
            }
        }
      
    }

    return (
        <Fragment>
            <Dialog open = {props.dialogOpen} onClose = {handleClose}  >
                <DialogTitle> {props.dialogTitle} </DialogTitle>
                <DialogContent>  
                    <DialogContentText> 
                        {props.dialogMessage}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick = { handleClose } varaint = "contained" color = "primary" > Cancel </Button>
                    <Button onClick = { handleUpdate } variant = "contained" color = "primary" > { props.buttonText } </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default function OpsPermissions (props) {

  var settings=[]
  var obj = JSON.parse(HWLocalStorage.load("Permissions"))
  console.log(obj);
  settings= [...obj["Settings"]];


  const history = useHistory ();

    const classes = useStyles ();
    const [ currentObject, setCurrentObject ] = useState ( {});
    const [createNewRole, setCreateNewRole] = useState ((props && props.location && props.location.params && props.location.params.state && props.location.params.state.createNewRole) ? true : false);
    const [ roleNameErrorMessage, setRoleNameErrorMessage ] = useState (null);
    const [ createMode, setCreateMode ] = useState (props && props.location && props.location.params && props.location.params.state && props.location.params.state.createMode || false);
    const [ open2 , setOpen2] = useState(false);
    const [ open3 , setOpen3] = useState(false);
    const validateNewRole = createObject => { console.log("Validations", createObject); return (createObject.roleName !== null && createObject.roleName !== '');  };
    const roleHirerachy = [ 'View', 'Action' ];
    const createRoleList = (permitName) => {
        var result = [];
        for (var i = 0; i < roleHirerachy.length; i++) {
            result.push (roleHirerachy[i]);
            if (roleHirerachy[i] === permitName) break;
        }
        return result;
    }; 
   
    const checkShow = () => {
      if(currentObject && currentObject.roleName  && currentObject.roleName.length >=3)
      {
        console.log("Inside show",currentObject && currentObject.roleName )
        for(var i=0 ; i<format.permissions.length ;i++ )
        {
          console.log(format, format.permissions ,format.permissions[i] );
          if(format && format.permissions && format.permissions[i].permissionAllowed &&  format.permissions[i].permissionAllowed.length > 0)
             return true;
        }
        return false;
        }

      }
  

    console.log(props);

    const defaultState = [
        {
          permissionAllowed: [
            "View"
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Members"
        },
        {
          permissionAllowed: [
            "View",
            "Action"
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Actions"
        },
        {
          permissionAllowed: [
            "View",
            "Action"
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Campaigns"
        },
        {
          permissionAllowed: [
            "View",
            "Action"
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Points"
        },
        {
          permissionAllowed: [
            "View",
            "Action"
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Notifications"
        },
        {
          permissionAllowed: [
            "View",
            "Action"
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Merchants"
        },
        {
          permissionAllowed: [
            "View",
            "Action"
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "UserSegments"
        },
        {
          permissionAllowed: [
            "View",
            "Action"
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Vouchers"
        },
        {
          permissionAllowed: [
            "View",
            "Action"
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Settings"
        },
        {
          permissionAllowed: [
            "View",
            "Action"
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Cards"
        }
    ];

    var permissionObject = {
        roleName : "" ,
        permissions : 
        [
        {
          permissionAllowed: [
            
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Members"
        },
        {
          permissionAllowed: [
           
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Actions"
        },
        {
          permissionAllowed: [
           
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Campaigns"
        },
        {
          permissionAllowed: [
           
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Points"
        },
        {
          permissionAllowed: [
           
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Notifications"
        },
        {
          permissionAllowed: [
          
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Merchants"
        },
        {
          permissionAllowed: [
          
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "UserSegments"
        },
        {
          permissionAllowed: [
          
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Vouchers"
        },
        {
          permissionAllowed: [
           
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Settings"
        },
        {
          permissionAllowed: [
           
          ],
          permissionPossible: [
            "View",
            "Action"
          ],
          permitName: "Cards"
        }
    ]

}



     // This object, currentObject would be loaded from DB and would be available for 
     // updating and reupoloading to DB, coming from DB it would only render what values would be 
     // assigned to it. 
     
   // const [ expandSubItems, setExpandSubItems ] = useState ( Array(permissions.length).fill(false));

    const [ dialogOpen, setDialogOpen ] = useState (false);
    const accessLevels = ['VIEW', /*'EDIT',*/ 'ACTION'];
    const [ access, setAccess ] = useState ([]);
    const [ newRoleName, setNewRoleName ] = useState ('');
   
    const [ isLoading, setIsLoading ] = useState (false);
    const [ canEdit, setCanEdit ] = useState (false);
    const [ showEdit, setShowEdit ] = useState (true);

     const handleUpdateOpen = (rule) => {
           if(props && props.location && props.location.params && props.location.params.state && props.location.params.state.createNewRole === true)
           { if (rule !== null)  {
                let successStatus = null;
                settingsApiService.createRole (rule)
                .then (response => {
                    if (! response || ! response.data) { HWMessage.show ("Error while fetchinmg response","error"); }
                //    props.setRefreshState (prev => !prev);
                    successStatus = true;
                    setOpen2(true);

                    HWMessage.show("Request Successful" , "success");

                    
                }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
                successStatus = false; })
                .finally (() => { 
                    if (successStatus === true)
                        { 
                            // props.setOpen (false);
                  //   props.setDirectActionOpen(false) ;
                }
                });
            }}
            else{
                if (rule !== null)  {
                    let successStatus = null;

                    settingsApiService.updateRole (rule)
                    .then (response => {
                        if (! response || ! response.data) { HWMessage.show ("Error while fetchinmg response","error"); }
                    //    props.setRefreshState (prev => !prev);
                        successStatus = true;
                        setOpen3(true);

                        HWMessage.show("Request Successful" , "success"); 
                       
                    }).catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
                    successStatus = false;
})
                    .finally (() => { 
                        if (successStatus === true)
                            { 
                              setShowEdit(false);
                                // props.setOpen (false);
                      //   props.setDirectActionOpen(false) ;
                    }
                    });
                }}
                return;
            };
    
    

    useEffect (() => {

        if(props && props.location && props.location.params && props.location.params.state && props.location.params.state.createMode == false){
            console.log("sdasd");
            setIsLoading (true);
            settingsApiService.fetchSpecificRoles(props && props.location && props.location.params && props.location.params.state && props.location.params.state.roleId)
            .then (response => {  
                if (! response || ! response.data) { return HWMessage.show ("Specific Role Cannnot be fetched","error"); }
                setFormat (response.data); 
                HWMessage.show("Request Successful" , "success");
            })
            .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
          })
            .finally (() => setIsLoading (false));
        }
       
    }, [])  

    console.log("Current Object" , currentObject );

   // const [ itemLevelAccess, setItemLevelAccess ] = useState (Array(permissions.length).fill([]));

   const [ format , setFormat ] = useState(permissionObject)
    
    const toggleEdit = event => {setCanEdit (! canEdit); setShowEdit (false); }

    const handleItemLevelAccess = (event, newAccess, idx) => {
        console.log(newAccess , idx) ;


        if( props && props.location && props.location.params && props.location.params.state && props.location.params.state.createMode !==false && newAccess === 'View' && format && format.permissions && format.permissions[idx] && format.permissions[idx].permissionAllowed &&   format.permissions[idx].permissionAllowed.includes("View") === false){

       //  console.log(permissionObject, permissionObject.permissions , permissionObject.permissions[idx] , permissionObject.permissions[idx].permissionAllowed )
         setFormat(prev=> ({...prev , roleName: currentObject && currentObject.roleName , ...prev.permissions[idx].permissionAllowed = ["View"]   }));
    }
      else  if( props && props.location && props.location.params && props.location.params.state && props.location.params.state.createMode ===false && newAccess === 'View' && format && format.permissions && format.permissions[idx] && format.permissions[idx].permissionAllowed &&   format.permissions[idx].permissionAllowed.includes("View") === false){

        //  console.log(permissionObject, permissionObject.permissions , permissionObject.permissions[idx] , permissionObject.permissions[idx].permissionAllowed )
          setFormat(prev=> ({...prev  , ...prev.permissions[idx].permissionAllowed = ["View"]   }));
     }

    else if (props && props.location && props.location.params && props.location.params.state && props.location.params.state.createMode !==false && newAccess === 'View' &&  format && format.permissions && format.permissions[idx] && format.permissions[idx].permissionAllowed && format.permissions[idx].permissionAllowed.includes("View") !== false )
    {
        console.log("unshift");
        setFormat(prev=> ({...prev , roleName: currentObject && currentObject.roleName , ...prev.permissions[idx].permissionAllowed.pop("View")  }));

    }
    else if ( props && props.location && props.location.params && props.location.params.state && props.location.params.state.createMode ===false && newAccess === 'View' &&  format && format.permissions && format.permissions[idx] && format.permissions[idx].permissionAllowed && format.permissions[idx].permissionAllowed.includes("View") !== false )
    {
        console.log("unshift");
        setFormat(prev=> ({...prev  , ...prev.permissions[idx].permissionAllowed.pop("View")  }));

    }
    else if( props && props.location && props.location.params && props.location.params.state && props.location.params.state.createMode !==false && newAccess === 'Action' && format && format.permissions && format.permissions[idx] && format.permissions[idx].permissionAllowed && format.permissions[idx].permissionAllowed.includes("Action") === false ){
      //  console.log(permissionObject, permissionObject.permissions , permissionObject.permissions[idx] , permissionObject.permissions[idx].permissionAllowed )
        console.log("asdasdas");
        setFormat(prev=> ({...prev , roleName: currentObject && currentObject.roleName  ,  ...prev.permissions[idx].permissionAllowed = ["View" , "Action"]   }));
    }
    else if( props && props.location && props.location.params && props.location.params.state && props.location.params.state.createMode === false &&  newAccess === 'Action' && format && format.permissions && format.permissions[idx] && format.permissions[idx].permissionAllowed && format.permissions[idx].permissionAllowed.includes("Action") === false ){
        //  console.log(permissionObject, permissionObject.permissions , permissionObject.permissions[idx] , permissionObject.permissions[idx].permissionAllowed )
          console.log("asdasdas");
          setFormat(prev=> ({...prev ,   ...prev.permissions[idx].permissionAllowed = ["View" , "Action"]   }));
      }
  
    else if( props && props.location && props.location.params && props.location.params.state && props.location.params.state.createMode !==false && newAccess === 'Action' &&  format && format.permissions && format.permissions[idx] && format.permissions[idx].permissionAllowed && format.permissions[idx].permissionAllowed.includes("Action") !== false ){
        console.log("sds");

        setFormat(prev=> ({...prev , roleName: currentObject && currentObject.roleName  ,  ...prev.permissions[idx].permissionAllowed = []  }));

    }
    else if( props && props.location && props.location.params && props.location.params.state && props.location.params.state.createMode ===false && newAccess === 'Action' &&  format && format.permissions && format.permissions[idx] && format.permissions[idx].permissionAllowed && format.permissions[idx].permissionAllowed.includes("Action") !== false ){
        console.log("sds");

        setFormat(prev=> ({...prev , ...prev.permissions[idx].permissionAllowed = []  }));

    }
        console.log( format) ;

    };

    
    return (
        <Fragment >
            <BlockUi 
                 blocking = {isLoading} 
                 message = "Loading User Permission"                  
                 loader = { <Loader active type = 'line-scale-pulse-out-rapid' color="#02a17c" /> }
            >
                <List  style={{overflow:'hidden'}}
                    component = "nav"
                    subheader = {  (!createNewRole && 
                        <ListSubheader component = "div"  > 
                                <h3> 
                                    <b> { props && props.location && props.location.params && props.location.params.state && props.location.params.state.createMode == false ? format && format.roleName  : `${ currentObject.roleName } Permissions`} </b> 
                                    { settings.includes("Action") === true && format.roleName!=="Admin" && showEdit && <IconButton onClick = { toggleEdit } style = {{ color : '#138DE8' }}  > <EditIcon color = '#138DE8' /> </IconButton> }
                                </h3> 
                        </ListSubheader>) || 
                         <>
                            <GridContainer>
                                <GridItem display = "inline" >  
                                <h3 > <b> Job Role </b> </h3>
                                </GridItem>
                                <GridItem style = {{ marginTop : '11px' }}>
                                    <HWInput 
                                        autoFocus 
                                        margin = "dense" 
                                        id = "roleName"
                                        placeholder = "Enter Job Role"
                                        value={props && props.location && props.location.params && props.location.params.state && props.location.params.state.createMode === false ? format && format.roleName : currentObject && currentObject.roleName}
                                        onBlur = { (event) => { 
                                                setCurrentObject(prev=>({...prev, roleName :  event.target.value}));  
                                            }
                                        }
                                        
                                        hasError = {createNewRole === true && currentObject && currentObject.roleName &&  currentObject.roleName.length <3 ? true : false }
                                        helperText= {createNewRole === true && currentObject && currentObject.roleName && currentObject.roleName.length <3 ? "RoleName should be more than 2 character" : "" }
                                        required = {true}
                                      
                                    /> 
                                </GridItem>
                            </GridContainer>                            
                            <br />
                        </>
                    }
                >
                    { defaultState.map ((item, idx) => {  
                        return (
                        <>
                                <ListItem key = {idx} /*button onClick = { () =>  handleClick (idx) } */ >  
                                    <ListItemText secondary = { `Permits for ${item.permitName} ` } > <b> <h5> { item.permitName } </h5> </b> </ListItemText> 
                                    <ToggleButtonGroup  exclusive={true} value= {format && format.permissions && format.permissions[idx] && format.permissions[idx].permissionAllowed} variant = "contained" 
                                        onChange = {(e, newAccess) => { 
                                            console.log(e,newAccess );        
                                            handleItemLevelAccess(e, newAccess, idx);
                                          //  console.log(format,JSON.stringify(permissionObject));
                                         }
                                          

                                        } 
                                    >
                                        <ToggleButton  disabled={ createNewRole ===false ? !canEdit : currentObject && currentObject.roleName ? false :true} value = "View"   > View </ToggleButton>
                                        <ToggleButton  disabled={ createNewRole ===false ? !canEdit : currentObject && currentObject.roleName ? false :true} value = "Action" > Action </ToggleButton>
                                    </ToggleButtonGroup>
                                </ListItem> 
                                
                                <Divider />
                        </>
                        
                        );
                    }) 
                    }
                </List>

                <br/>
               {(canEdit || createNewRole === true) && <GridContainer justify = "center" >
{        createNewRole  === false &&   <GridItem>  <Button   variant = "contained" size = "large" onClick = { e => handleUpdateOpen(format) } style = {{ color : 'floralwhite', backgroundColor : '#138DE8' , textTransform:'None'}} > { (createNewRole && `Create Role`) ||  ((!createNewRole) && `Update Permissions`)} </Button> </GridItem>
}
        { createNewRole === true && checkShow() && <GridItem>  <Button  variant = "contained" size = "large" onClick = { e => handleUpdateOpen(format) } style = {{ color : 'floralwhite', backgroundColor : '#138DE8' , textTransform:'None' }} > { (createNewRole && `Create Role`) ||  ((!createNewRole) && `Update Permissions`)} </Button> </GridItem>
}
                    <GridItem> {  <Button variant = "contained" size = "large" onClick = { event => { history.goBack() } } style = {{ color : 'floralwhite', backgroundColor : '#FF9017' , textTransform:'None' }} > Cancel </Button> } </GridItem>
                </GridContainer>}
                <ConfirmationDialog 
                    dialogOpen = {dialogOpen} 
                    setDialogOpen = {setDialogOpen} 
                    createNewRole = { createNewRole }
                    setCreateNewRole = {setCreateNewRole}
                    dialogTitle = { createNewRole ? 'Confirm Creating New Role' : 'Confirm Updating Permissions' } 
                    dialogMessage = { createNewRole ? `Do you want to create new role ${ newRoleName } with given Permissions ? ( Role permission can be changed but takes effect immediately )` : `Are you sure about updating permissions for ${props.roleName} ? This would take effect immediately across all the devices and relevant users.` } 
                    buttonText = { createNewRole ? "Create" : "Update" } 
                    currentObject = {currentObject}
                    setCurrentObject = {setCurrentObject}
                    isLoading = { isLoading }
                    rule={format}
                    setIsLoading = { setIsLoading }
                />
                  <StatusDialog isLoading = {false} open= { open3 } setOpen={setOpen3}  sucessStatus={"success"} confirmingAction={e=>history.goBack()} message={"Role successfully updated."} dialogTitle={"Status"}/> 
                  <StatusDialog isLoading = {false} open= { open2 } setOpen={setOpen2}  sucessStatus={"success"} confirmingAction={e=>history.goBack()} message={"Role successfully created."} dialogTitle={"Status"}/> 

        </BlockUi>
        </Fragment>
    );
};