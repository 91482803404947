import { AppBar, makeStyles, Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState, Fragment } from 'react';
import TransactionsPending from 'views/Transactions/TransactionsPending';
import HWGoogleMaps from 'views/Google Maps/HWGoogleMaps';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
const useStyles = makeStyles (theme => ({
    root : {
        overflow: 'hidden',
        minHeight: 48,
        WebkitOverflowScrolling: 'touch', 
        display: 'flex',
        backgroundColor: "white",
        '& .MuiTabs-indicator' : { backgroundColor : '#FF9017' },
        "& .MuiButtonBase-root": {textTransform: "none"},
        color: "black",
        borderRadius: '6px'
    }
}));

export default function Transactions (props) {

    const classes = useStyles();
    if (HWLocalStorage.load ("previousVouchersTab") !== null ) 
    HWLocalStorage.clear("previousVouchersTab");
if(HWLocalStorage.load ("previousVouchersRuleTab") !== null)
     HWLocalStorage.clear("previousVouchersRuleTab");
     if (HWLocalStorage.load ("previousCardTab") !== null ) 
     HWLocalStorage.clear("previousCardTab");
     if(HWLocalStorage.load("previousComSettingsTab")!== null){
        HWLocalStorage.clear("previousComSettingsTab")
    }
    if(HWLocalStorage.load("previousRolesSettingsTab")!== null){
        HWLocalStorage.clear("previousRolesSettingsTab")
    }

    if(HWLocalStorage.load("previousSettingsTab")!== null){
        HWLocalStorage.clear("previousSettingsTab")
    }
    if(HWLocalStorage.load("previousMemberTab")!== null){
        HWLocalStorage.clear("previousMemberTab")
    }
    if(HWLocalStorage.load("previousActionsTab")!== null){
        HWLocalStorage.clear("previousActionsTab")
    }
    if(HWLocalStorage.load("previousMerchantDetailsTab")!==null)
    {
        HWLocalStorage.clear("previousMerchantDetailsTab");
    }

    if(HWLocalStorage.load("previousMerchantTab")!==null)
    {
        HWLocalStorage.clear("previousMerchantTab");
    }
  //  if (! HWLocalStorage.load ("previousPointsTab") ) HWLocalStorage.save (0, "previousPointsTab");

    const [currentTab, setCurrentTab ]= useState (JSON.parse(HWLocalStorage.load ("previousPointsTab")) || 0);
    const changeTab = (event, idx) => { setCurrentTab (idx); }

    return (
        <Fragment>
                <Tabs variant = 'scrollable' className = { classes.root } value = { currentTab } onChange = { changeTab } >
                    <Tab label = "Transactions Pending" />
                </Tabs>
            {
                {
                    0 : <TransactionsPending/>,
   //                 1 : <PointsRule />,
   //                 2 : <PointsHistory />
                } [ currentTab ]
            }
         </Fragment>
    );
    
};