import { FormControlLabel, makeStyles, Switch} from '@material-ui/core';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Fab } from '@material-ui/core';
import RuleForm from './UsersRuleForm';
import React, { useState, useEffect, Fragment, useCallback } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { Button,  IconButton , Popover } from '@material-ui/core';
import { DataGrid , GridToolbar} from '@material-ui/data-grid'
import { FormatListNumberedRtlTwoTone, MoreVert, People } from '@material-ui/icons';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardIcon from 'components/Card/CardIcon';
import BlockUi from 'react-block-ui';
import HWMessage from 'HWLibrary/HWMessage';
import { useHistory } from 'react-router-dom';
import BorderLinearProgress from 'views/Members/BorderLinearProgress'; 
import { GridAddIcon } from '@material-ui/data-grid';
import settingsApiService from './APIService';
import CampaignSwitch from "./Switch";
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import SearchBar from 'views/Merchant/SearchBar';
import { CustomToolbar } from 'views/Members/Member';
const useStyles = makeStyles(theme => ({
    ...styles,
    cardFooter : { justifyContent : 'center', justifyItems : 'center' },
    cardHeader : { background : '#138DE8'  },
    cardIcon : { background : '#138DE8' },
    bottomGrid : { marginTop : 5 },
    '& .MuiFormLabel-root' : { marginBottom : 0 },
    editIcon : { color : '#138DE8' }
}));

function RuleDialog (props) {
    
    const [ ruleLoading, setRuleLoading ] = useState (false);
    const [ ruleDescription, setRuleDescription ] = useState ({});
    const handleClose = event => {props.setOpen (false);props.setDirectActionOpen(false) ;}
    return (
        <>
            <Dialog open = { props.open } onClose = { handleClose }  >
                <RuleForm refreshState = { props.refreshState } setRefreshState = { props.setRefreshState } rules = { props.rules } setRules = { props.setRules } createMode = { props.createMode } ruleId = { props.ruleId } open = { props.open } setOpen = { props.setOpen }  setDirectActionOpen = {props.setDirectActionOpen}  List={props.roles}/>
            </Dialog>
        </>
    );
}



function ActionPopover (props) {
    return ( 

                <div>
                <Popover
                    open={ props.open }
                    anchorEl={ props.anchorEl}
                    onClose={  (event) =>  props.handleClose (false)  }
                >
                    <div >
                        <div style = {{ margin : '20px' }} > 
                            <Button variant='contained' style = {{ backgroundColor : '#138DE8', color : 'floralwhite', right : '5px' , textTransform:'none' }} onClick = {event => props.handleEdit() }  > Edit </Button> 
                            <Button  variant='contained' style = {{ backgroundColor : '#FF9017', color : 'floralwhite' , textTransform:'None' }} onClick = { event => props.handleDelete() } >  Delete </Button> 
                        </div> 
                    </div>
                </Popover>
            </div>
    
    );
};



export default function Users (props) {



    var settings=[]
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    settings= [...obj["Settings"]];
    HWLocalStorage.save (1, "previousRolesSettingsTab")




   const classes = useStyles();
   const [ rows, setRows ] = useState ([]);
   const [ page, setPage ] = useState (1);
   const [ pageSize, setPageSize ] = useState (100);
   const [ filterModel, setFilterModel ] = useState (null);
   const [ rowCount, setRowCount ] = useState (0);
   const [ openDirectAction, setOpenDirectAction ] = useState (false);
   const [ selectedRequest, setSelectedRequest ] = useState (null);
   const [ searchText, setSearchText ] = useState ('');
   const [creatorMode , setCreatorMode ] = useState(false);
   const [ anchorEl, setAnchorEl ] = useState (null);
   const [ actionOpen, setActionOpen ] = useState (false);
   const [loading, setLoading ] = useState(false);
   const [ directActionOpen , setDirectActionOpen ] = useState(false);
   const [ refreshState, setRefreshState ] = useState (false);
   const [ selectedRuleId, setSelectedRuleId ] = useState ();
   const [ ruleOpen, setRuleOpen ] = useState (false);
   const [ createMode, setCreateMode ] = useState (false);
   const [ rulesLoading, setRulesLoading ] = useState (false);
   const [ isLoading , setIsLoading ] = useState (false);
   const [ roles , setRoles ] = useState (false);

   const openRule = () => { setRuleOpen (true); };
   const addRule = (event) => { setCreateMode (prev => true); setRuleOpen (prev => true); };
   const editRule = (event) => { setCreateMode (prev => false); setRuleOpen (prev => true); };


const checkPriorDelete = deleteObject => {
    // Check for any existing rules which may be applicable for the time being
    return true;
};

const [ deleteItemState , setDeleteItemState ] = useState('');

   const deleteRule = useCallback (id => {
       if (id != null) {
           setRulesLoading (true);
           settingsApiService.deleteUser (id , "Deleted") 
           .then (response => {
               if (!response || !response.data) { return HWMessage.show ("Error while deleteing the rule" ,"error"); }               
               setRowCount (rowCount - 1);
              setRows (prev => { prev = prev.filter (i => i.id !== id); return [ ...prev ] });
              HWMessage.show("Request Successful" , "success");
           })
           .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
        })
           .finally (() => { setRulesLoading (false)  ; setDirectActionOpen(false) ;
          });
       }
   });


   useEffect (() => {
    if(props.createMode == false){
        setIsLoading (true);
        settingsApiService.fetchSpecificRoles (props.ruleId)
        .then (response => {  
            if (! response || ! response.data) { return HWMessage.show ("Specific Role Cannnot be fetched","error"); }
            setRoles (response.data); 
            HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() => setIsLoading (false));
    }
   
}, [])

var tierList= [];

useEffect (() => {
 
        setIsLoading (true);
        var paginationBookmark = ((page - 1) * pageSize);
        var fetchLimit = pageSize;
        
        settingsApiService.fetchRoles ()
        .then (response => {  
            if (! response || ! response.data) { return HWMessage.show ("Specific Role Cannnot be fetched","error"); }
            
            tierList = response.data.responseList.map(i => ({ id : i.id, roleName : i.roleName }));
            setRoles(tierList);
            HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { return HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() => {setIsLoading (false); setCreateOpen(false); var my_element = document.getElementById("top");
        if(my_element!== null || my_element!== undefined || my_element!== '')
        {
            // my_element.scrollIntoView({ }); 

        }   
    
    });
    
   
}, [pageSize , page  ])



const handleDelete = (id) => {

        deleteRule (id)
}


const handleEdit = (id) =>{
} 
   const [ sortModel, setSortModel ] = useState ({});
   const label = { inputProps: { 'aria-label': 'Status' } };

    const columns = [
       { field : 'name',          headerName : 'Name',    filterable : true,  sortable : true, flex : 2.5},
       { field : 'emailAddress',          headerName : 'Email',          filterable : FormatListNumberedRtlTwoTone,  sortable : true, flex : 2.5 },
       { field : 'roles',          headerName : 'Role',          filterable : true,  sortable : true, flex : 2.5 },
     
       
    ];

    if(settings.includes("Action") === true){
        columns.push(  { field : 'status',      headerName : 'Status',     filterable : false ,  sortable : false, flex : 3, renderCell : cellParams => <>  <CampaignSwitch idx = { cellParams.id }  status={ cellParams.row.status } checked = { cellParams.row.status === 'Active' ? true : false } rule={cellParams.row} 
        onChange = { (idx, action) =>   setRows(prev =>  {
            prev.map((item,index) => {
                if (item.id == idx) {
                item.status =  action
                return item
                }
             })
             return [...prev]
            }
            )}        
            />  {<span>{cellParams.row.status}</span>}
        </> } );
        columns.push(       { field : 'Edit',             headerName : '',             filterable : false, sortable : false, flex : 2,  renderCell : cellParams => <> <IconButton onClick = { (event) =>{  setDeleteItemState(cellParams.row) ; setSelectedRuleId(cellParams.row.id);  editRule(event)  } } > <Edit style={{color: '#138DE8' }} className = { classes.actionIcon } /> </IconButton>  </>  }
        )
        columns.push(       { field : 'Delete',             headerName : '',             filterable : false, sortable : false, flex : 2,  renderCell : cellParams => <> <IconButton onClick = { (event) =>{  setDeleteItemState(cellParams.row) ; setSelectedRuleId(cellParams.row.id) ; setCreateOpen(true) } } > <Delete style={{color: '#FF9017' }} className = { classes.actionIcon } /> </IconButton>  </>  }
        )

       
    }
  
    useEffect (() => {
        setIsLoading (true);
        var paginationBookmark = ((page - 1) * pageSize);
        var fetchLimit = pageSize;
        
        settingsApiService.fetchUsers ({fetchLimit , paginationBookmark , searchText , sortModel , filterModel  })
        .then (response => {
            if (!response || !response.data){
                HWMessage.show("Error while fetching the response" , "error")
                return console.log("No relevant data can be fetched");
            }
          setRows (response.data.userList);
          setRowCount(response.data.count);
          HWMessage.show("Request Successful" , "success");
        })
        .catch (error => { HWMessage.show (error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
    })
        .finally (() => { setIsLoading (false); setDirectActionOpen(false) });
    }, [ page, pageSize ,  refreshState , sortModel , searchText , filterModel , refreshState ]);

    const [ createOpen, setCreateOpen ] = useState (false);

    const useDataGridStyles = makeStyles(theme => ({
        root: {
            '& .MuiDataGrid-colCellTitle': { color: 'primary', fontFamily: 'roboto', fontWeight: 300 },
            '& .MuiDataGrid-cell':         { color: 'primary', fontFamily: 'roboto', fontSize: '14px', fontWeight: 300 },
            '& .MuiDataGrid-row:nth-child(even)' : { backgroundColor : 'white' },
            '& .MuiButton-label' : { color : '#138DE8' }
        }
      }));
      const dataGridClasses = useDataGridStyles();

    function CustomLoadingOverlay() {
        return (
          <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <BorderLinearProgress />
            </div>
        );
      }

   
   const history = useHistory ();

    return (
        <Fragment>
            <BlockUi blocking = { isLoading } message = { 'Please wait while we fetch.' } >
                <Card id="top">
                    <CardHeader className = { classes.cardHeader } color = 'rose' icon > 
                        <CardIcon style = {{ background : '#138DE8' }} color = 'primary'> <People /> </CardIcon>
                        <h4 className = { classes.cardIconTitle } > Users</h4> 
                    </CardHeader>
                    <CardBody>  
                        <GridContainer style = {{ justifyContent : 'space-between' }} >
                                <GridItem>
                                            <SearchBar 
                                            width = '300px' 
                                            handleSearch = { setSearchText }
                                            handleRefresh = { setRefreshState }
                                            refreshState = { refreshState }
                                        /> 
                                </GridItem>
                                <GridItem>
                                { settings.includes("Action") === true && <Fab onClick = { addRule } variant = 'extended' aria-label="add" style = {{ background : '#FF9017', color : 'white' }}  > <GridAddIcon  /> Add User</Fab>}
                                </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem className = { classes.bottomGrid } xs = {12} md = {12} >
                                <center>
                                    <DataGrid 
                                        className = { dataGridClasses.root }
                                        page = { page }
                                        pageSize = { pageSize }
                                        rows = { rows }
                                        rowCount = { rowCount }
                                        columns = { columns }
                                        showToolbar
                                        pagination
                                        paginationMode = 'server'
                                        onPageChange = { changeParams => setPage (changeParams) }
                                        onPageSizeChange = { changeParams => { setPage (0); setPageSize (changeParams); } }
                                        sortingMode="server" 
                                        onSortModelChange = { params => {  
                                          if (params.length > 0) 
                                              setSortModel (params[0]);
                                        } }
                                      
                                        filterMode = 'server'
                                        onFilterModelChange = { (params) => {
                                          if (params.items.length > 0) {
                                            setFilterModel (prev => ({ ...params.items[0] })); 
                                          }
                                        } }
                                        density = 'compact'
                                        autoHeight
                                        rowsPerPageOptions ={ [25, 50, 100 ]}
                                        components={{ Toolbar: CustomToolbar, LoadingOverlay: CustomLoadingOverlay }} 
                                        disableExtendRowFullWidth = { false }
                                    />
                                    </center>
                            </GridItem>
                        </GridContainer>

                    </CardBody>
               
                </Card>
                <RuleDialog     
                ruleId = { selectedRuleId }
                open = { ruleOpen }     
                setOpen = { setRuleOpen } 
                createMode = { createMode } 
                rules = { rows }
              //  setRules = { setRows }
                refreshState = { refreshState } 
                setRefreshState = { setRefreshState }
                setDirectActionOpen = {setDirectActionOpen}
                roles= {roles}
            />
            <ActionPopover
                open = { directActionOpen }
                handleClose = { setDirectActionOpen }
                anchorEl = { anchorEl } 
                setAnchorEl = { setAnchorEl }
                handleEdit = {e => editRule(e) } 
                handleDelete = { e => setCreateOpen(true)} 

                     />
                 <ConfirmationDialog open = { createOpen } setOpen = { setCreateOpen }  dialogMessage = { 'Are you sure you want to delete this user' } dialogTitle = { 'Confirm user Deletion' } confirmingAction = { e => handleDelete(deleteItemState) } />

            </BlockUi>
            
        </Fragment>
    );
};