import { Button, Card, FormControl, FormControlLabel, FormLabel, IconButton, InputLabel, makeStyles, MenuItem, Radio, RadioGroup, Select, TextField, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useCallback } from 'react';
import Badge from '@material-ui/core/Badge';
import { green } from '@material-ui/core/colors';
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from 'react-router-dom';
import { AttributeList, OperatorList, AttributeValueList } from './QueryAttributes';


const useStyles = makeStyles (theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    cardBody : { borderRadius : 15, },
    root:  {
        zindex : 999 ,
        right: 5,
        position: "absolute",
    },
    radioButton : {
        color : '#138DE8',
        '&$checked': { color: '#138DE8', }, 
    },
    select : { '& .MuiSelect-outlined': { borderColor: '#138DE8' } },
    badgeColor: { '& .MuiBadge-colorPrimary': { backgroundColor: "#FF9017"} }
}));

export function SelectDropDown (props) {
    const classes = useStyles ();
    const handleChange = event => { props.onChange (event); };
    
    if(props.deletable === "yes" && props.idx > 0 ) {
        return (
            <Fragment>
                 
                <FormControl variant="outlined" className={classes.formControl}>
                    
                    <InputLabel> {props.label} </InputLabel>
                    
                    <Badge  className={classes.badgeColor} onClick = { e => { props.onDelete() } } color="primary"  badgeContent={"X"}> 
                    <Select  style = {{ width : 180 ,padding : 0 ,height: 50 }} value={ props.value } onClick = { e => e.stopPropagation() } onChange={ handleChange } label = { props.label } > 
                        { props.values && props.values.map (value => (<MenuItem value = { value } > { value } </MenuItem> ) ) }
                    </Select>
                    </Badge>
                </FormControl>
            </Fragment>
        );
    }
    else
    {
        return (
            <Fragment>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel> {props.label} </InputLabel>
                    
                    <Select className = { classes.select } style = {{ width : 180,padding : 0 ,height: 50 }} value={ props.value } onChange={handleChange} label = { props.label } >
                       { props.values && props.values.map (value => (<MenuItem value = { value } > { value === "state" ? "status" : value === "location" ? "state": value } </MenuItem> ) ) }
                        </Select>
                </FormControl>
            </Fragment>
        );
    }
   
}

export default function FilterElement (props) {

    console.log ("Propsiung", props);

    const classes = useStyles ();
    const [ attribute, setAttribute ] = useState (props && props.attribute);    // Rather use parent's set attribute
    const [ values, setValues ] = useState (['None']);            // OR Filter seperated values
    const [ operator, setOperator ] = useState ();
    const [ filterType, setFilterType ] = useState (props && props.filterType);

    const [ operatorList, setOperatorList ] = useState (OperatorList);
    const [ attributeList, setAttributeList ] = useState (AttributeList);
    const [ valuesList, setValuesList ] = useState (props && props.attribute ? AttributeValueList[props.attribute] ? AttributeValueList[props.attribute].values : [] : []);
    const [ addValue, setAddValue ] = useState (false); 
    const [ showParsedQuery , setShowParsedQuery ] = useState (false); 
    const [ filterLoading, setFilterLoading ] = useState (false);
    const [ valuesLoading, setValuesLoading ] = useState (false);
    const [ rangeValue , setRangeValue ] = useState("");

    const selectAttribute = props && props.selectAttribute;
    const selectValue = props && props.selectValue;
    const selectOperator = props && props.selectOperator;
    const removeFilter = props && props.removeFilter;
    const removeOrFilter = props && props.removeOrFilter;
    const changeQueryFilterType = props && props.changeQueryFilterType;
    const addFilter = props && props.addFilter;
    const addOrFilter = props && props.addOrFilter;

    const changeFilterType = event => {
        console.log("Changing filter", event.target.name, event.target.value);
    };
    const handleAttributeChange = event => {
        console.log("Event for attribute change", event);
        // if(event.target.value === "birthday"){
        //     setAttribute("dateOfBirth")
        //     setValuesList (AttributeValueList[event.target.value].values);
        //     props.setAttribute ("dateOfBirth");
        //     return
        // }
        // if(event.target.value === "status")
        // {
        //     setAttribute("state")
        //     setValuesList (AttributeValueList[event.target.value].values);
        //     props.setAttribute ("state");
        //     return
        // }
        // if(event.target.value === "state")
        // {
        //     setAttribute("location")
        //     setValuesList (AttributeValueList[event.target.value].values);
        //     props.setAttribute ("location");
        //     return
        // }
        setAttribute (event.target.value);
        setValuesList (AttributeValueList[event.target.value].values);
        props.setAttribute (event.target.value);
    };
    const handleOperatorChange = event => {
        setOperator (event.target.value);
        props.setOperator (event.target.value);
    };
    const handleValueChange =  (event, idx) => { 
        let previousValues = [ ...props.values ];
        previousValues[idx] = event.target.value;
        props.setValue (previousValues);
    };


    const handleValueChange3 =  (event, idx) => {
        let previousValues = [ ...props.values ];
        previousValues[idx] =  event.target.value; 
        props.setValue (previousValues);
    };

    const handleValueChange2 =  (event, idx) => {
        setRangeValue(prev=> prev + event.target.value)
        let previousValues = [ ...props.values ];
        previousValues[idx] =  rangeValue; 
        props.setValue (previousValues);
    };

    
    const addNewValue = value => {  
        let previousValues = [ ...props.values ];
        previousValues.push(value); 
        props.setValue (previousValues); 
    };
    const removeValue = (idx) => {  values.splice (idx, 1); props.setValue (values); };

    return (
        <Fragment>
            <GridContainer>
                <GridItem xs = {12} md = {12} >  
                    <Card className = { classes.cardBody } >  
                        <CardBody >
                            <GridContainer>  
                                <GridItem xs = {12} md = {12} >  
                                    <FormControl component = "fieldset" >
                                        <div style={{margin: 0, padding: 0 , height: 50 ,width: 600, position : 'inline'}}> 
                                            <FormLabel component = "legend" > Target User  
                                            <IconButton style={{ float: "right" , top : -20}} onClick={ () => props.removeFilter() } ><CancelIcon style={{ color: '#138DE8'}}></CancelIcon>   </IconButton></FormLabel>
                                        </div>
                                       <text> Segment Attributes</text>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs = {12} >
                                    <GridContainer>
                                        <GridItem xs = {4} md = {4} > <SelectDropDown label = { 'Attribute' } value = { props.attribute } values = { attributeList } onChange = { handleAttributeChange } /> </GridItem>
                                        <GridItem xs = {4} md = {4} >  <SelectDropDown label = {'Operator'} value = {  props.operator} values = { props.attribute === "birthday" ||  props.attribute === "registrationDate" || props.attribute === "chassisNumber" ? ['Between'] : operatorList } onChange = { handleOperatorChange } /> </GridItem>
                                        {
                                            AttributeValueList[props.attribute] && AttributeValueList[props.attribute].type === 'specific' &&
                                            <GridItem style={{ position: "relative"}}> 
                                                { props.values.map ((value, idx) => (<>  
                                                    <SelectDropDown 
                                                        deletable = {"yes"} 
                                                        idx= {idx} 
                                                        label = { 'Value' } 
                                                        style={{"z-index": 0 , margin : 5}} 
                                                        values = {  props.attribute === "bikeModel" ? JSON.parse(localStorage.getItem("bikeModels")).map(i=> i.bikeModelName) : valuesList || [ 'None' ] } 
                                                        value = { value } 
                                                        onDelete = { e => {  removeValue (idx); } } 
                                                        onChange = { e => handleValueChange(e, idx) } 
                                                        required = {true}
                                                    />
                                                </>) ) 
                                                }
                                                <IconButton onClick = { event => addNewValue('None') } > <AddIcon /> </IconButton>
                                            </GridItem>
                                        }
                                        {
                                            AttributeValueList[props.attribute] && AttributeValueList[props.attribute].type === 'ranged' &&
                                            <GridItem style={{ position: "relative"}}> 
                                                { props.values.map ((value, idx) => (<>  
                                                    <Badge  className={classes.badgeColor}  onClick = { e => {   removeValue (idx); } } color="primary"  badgeContent={ props.values.length > 1 ? 'X' : null }>
                                                        <TextField 
                                                            type = 'date'
                                                            label = { 'Value' } 
                                                            //style={{"z-index": 0 }} 
                                                            value = { value } 
                                                            onChange = { e => handleValueChange(e, idx) } 
                                                            onClick = { e => e.stopPropagation() }
                                                            InputLabelProps={{ shrink: true, }}
                                                            variant = 'outlined'
                                                            required = {true}
                                                        />
                                                    </Badge>
                                                </>) ) 
                                                }
                                                { (!(AttributeValueList[props.attribute] ==="birthday" || AttributeValueList[props.attribute] ==="registrationDate" ) && props.values.length < 2) && <IconButton onClick = { event => addNewValue('None') } > <AddIcon /> </IconButton>}   
                                            </GridItem>
                                        }                        

                                    

                                        {
                                            AttributeValueList[props.attribute] && AttributeValueList[props.attribute].type === 'single' &&
                                            <GridItem style={{ position: "relative"}}> 
                                                { props.values.map ((value, idx) => (<>  
                                                    <Badge  className={classes.badgeColor}  onClick = { e => {   removeValue (idx); } } color="primary"  badgeContent={ props.values.length > 1 ? 'X' : null }>
                                                        <TextField 
                                                            label = { 'Value' } 
                                                            style={{"z-index": 0 , margin : 5}}  
                                                            value = { value } 
                                                            onChange = { e => handleValueChange(e, idx) } 
                                                            onClick = { e => e.stopPropagation() }
                                                            InputLabelProps={{ shrink: true, }}
                                                            variant = 'outlined'
                                                            required = {true}
                                                        />
                                                    </Badge>
                                                </>) ) 
                                                }
                                                { ((AttributeValueList[props.attribute] !=="chassisNumber") ) && <IconButton onClick = { event => addNewValue('None') } > <AddIcon /> </IconButton>}   

                                            </GridItem>
                                        }

                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </Fragment>
    );
};